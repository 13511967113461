<div class="row wrapper">
  <div class="col-4 title">
    <span style="color: red; font-size: 20px">*</span> LinkedIn UUID
  </div>
  <div class="col-8 work-field">
    @if (!uuid && !isEdit) {
    <pd-add-value (addValue)="addValue()"></pd-add-value>
    }
    @if (isEdit || uuid) {
    <div class="md-form" (document:focusout)="changeEdit()">
      <input [(ngModel)]="uuid" mdbActive type="text" required #inputUUID="ngModel">
      @if (inputUUID.invalid && inputUUID.touched && !isUuidExist) {
      <span style="color: red">Enter linkedin UUID</span>
      }
      @if (inputUUID.invalid && inputUUID.touched && isUuidExist) {
      <span style="color: red">Person with this UUID has already exists</span>
      }

    </div>
    }
  </div>
</div>