<div class="right">
    @if (isAllowedWithPhoneNumberOnlyFlagFilter) {
    <div class="filter-option email-only-filter" bootstrapTooltip="With phone number" placement="bottom">
        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isWithPhoneNumberOnlyFilter">With phone number
        </mat-checkbox>
    </div>
    }

    @if (isAllowedSameBuildingFlagFilter) {
    <div class="filter-option email-only-filter" bootstrapTooltip="Same Building filter" placement="bottom">
        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isSameBuildingFilter">SB filter
        </mat-checkbox>
    </div>
    }

    @if (isAllowedContactByEmailOnlyFlagFilter) {
    <div class="filter-option email-only-filter">
        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isContactByEmailOnlyFilter">Contact by email only
        </mat-checkbox>
    </div>
    }

    @if (isListDeals && !isMasterLeadDeals) {
    <div class="stage">
        <pd-custom-select-grouped-data #stageFilter (loadDataEvent)="getStageFilterData()"
            (selectedItemEvent)="onSelectedStageItem()" [displaySearch]="false"
            (autoCompleteEvent)="getStageAutocomplete($event)" [dataLabel]="stageLabel" [startLabel]="stageLabel"
            [isMultiple]="true">
        </pd-custom-select-grouped-data>
    </div>
    }

    @if (isAllowedToCompaniesFilter) {
    <div class="filter-option filter-select"
        [ngClass]="{'same-building-color-attention': isSameBuildingColorAttention}">
        <pd-custom-chips-select-filter #companyFilter (loadDataEvent)="setCompaniesFilterData($event)"
            (autoCompleteEvent)="onCompaniesAutocompleteRequest($event)" (sendData)="getCompanies($event)"
            [dataLabel]="companiesLabel" [filterChips]="storageCompanies"></pd-custom-chips-select-filter>
    </div>
    }

    @if (isAllowedToPositionsFilter) {
    <div class="filter-option filter-select">
        <pd-custom-chips-select-filter #positionFilter [isExceptOnly]="isExceptOnlyPositions"
            (loadDataEvent)="setPositionsFilterData($event)"
            (autoCompleteEvent)="onPositionsAutocompleteRequest($event)" (sendData)="getPositions($event)"
            [dataLabel]="positionsLabel" [filterChips]="storagePositions">
        </pd-custom-chips-select-filter>
    </div>
    }

    @if (isAllowedToLocationsFilter) {
    <div class="filter-option filter-select"
        [ngClass]="{'same-building-color-attention': isSameBuildingColorAttention}">
        <pd-custom-chips-select-filter #locationFilter [isExceptOnly]="isExceptOnlyLocations"
            (loadDataEvent)="setLocationsFilterData($event)"
            (autoCompleteEvent)="onLocationsAutocompleteRequest($event)" (sendData)="getLocations($event)"
            [dataLabel]="locationsLabel" [filterChips]="storageLocations">
        </pd-custom-chips-select-filter>
    </div>
    }

    @if (isAllowedToGroupSelect) {
    <div class="gender-select">
        <mat-form-field class="gender-select-global limitedWidth">
            <mat-select class="genderSelector" panelClass="gender-panel-class-inject" [(ngModel)]="selectedGroupId"
                bootstrapTooltip="Groups" placement="bottom">
                @for (group of groupList; track group) {
                <mat-option [value]="group.id" (click)="changeGroup(group.id)">
                    {{group.name}}
                </mat-option>
                }
            </mat-select>
        </mat-form-field>
    </div>
    }

    @if (isAllowedToGenderSelect) {
    <div class="gender-select">
        <mat-form-field class="gender-select-global limitedWidth">
            <mat-select class="genderSelector" panelClass="gender-panel-class-inject" bootstrapTooltip="Genders"
                placement="bottom" [(ngModel)]="selectedGenderId">
                @for (gender of genderList; track gender) {
                <mat-option [value]="gender.id" (click)="changeGender(gender.id)">
                    {{gender.name}}
                </mat-option>
                }
            </mat-select>
        </mat-form-field>
    </div>
    }

    @if (!isMasterLeadDeals) {
    <div class="filterList">
        <button type="button" class="buttons" (click)="filterDashboard()" bootstrapTooltip="Filter" placement="bottom"
            mdbRippleRadius>
            <i class="material-icons">filter_list</i>
        </button>
    </div>
    }

    @if (isAllowedToUserSelect) {
    <div class="selectUser">
        <mat-form-field class="owner-select-global">
            <mat-select [value]="eaAllowedUsersList[0].id" bootstrapTooltip="Users"
                panelClass="owner-panel-class-inject owner-panel-class-inject--align-right" placement="bottom">
                @for (user of eaAllowedUsersList; track user) {
                <mat-option [value]="user.id" (click)="changeUser(user)">
                    {{ user.name }}
                </mat-option>
                }
            </mat-select>
        </mat-form-field>
    </div>
    }

    @if (isAllowedToUsersMultiSelect) {
    <div class="users">
        <pd-custom-select-without-lazy #userFilter (loadDataEvent)="getUsersFilterData()"
            (selectedItemEvent)="onUserSelectedItem()" (autoCompleteEvent)="getUsersAutocomplete($event)"
            [dataLabel]="usersLabel" [isUserMode]="true" [startLabel]="usersLabel" [isMultiple]="true">
        </pd-custom-select-without-lazy>
    </div>
    }
</div>