import { Component, OnInit, Inject } from '@angular/core';
import { Deal } from 'src/app/core/models/Deal';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AvatarBuilderComponent } from '../../shared/avatar-builder/avatar-builder.component';

@Component({
  selector: 'pd-basket-client-card-modal',
  templateUrl: './basket-client-card-modal.component.html',
  styleUrls: ['./basket-client-card-modal.component.scss']
})

export class BasketClientCardModalComponent implements OnInit {
  onClose: any;

  public deal: Deal;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AvatarBuilderComponent>,) { 
      this.onClose = data.onClose;
      this.deal = data.deal;      
    }

  ngOnInit() {
  }

  public close() {
    this.onClose();
    this.dialogRef.close();
  }
}
