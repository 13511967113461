import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AvatarDealEffectsService } from "./avatar-deal-effects.service.";
import { AvatarDealStoreService } from "./avatar-deal-store.service";
import { Avatar } from "../../models/avatar-models/Avatar";
import { AvatarBuilderData } from "../../models/avatar-models/AvatarBuilderData";
import { AvatarDealApiService } from "./avatar-deal-api.service";

@Injectable()
export class AvatarDealFacadeService {

    constructor(
        private avatarDealEffectsService: AvatarDealEffectsService,
        private avatarDealStoreService: AvatarDealStoreService,
        private avatarDealApiService: AvatarDealApiService,
    ) {
    }

    public loadAvatarsByDealId(dealId: number): Promise<Avatar[]> {
        return this.avatarDealEffectsService.loadClientCardAvatarsByDealId(dealId).toPromise();
    }

    public loadSeasonalProposalAvatarsByDealId(dealId: number): Promise<Avatar[]> {
        return this.avatarDealEffectsService.loadSeasonalProposalAvatarsByDealId(dealId).toPromise();
    }
    
    public loadStylesAvailableAvatarsByDealId(dealId: number): Promise<Avatar[]> {
        return this.avatarDealEffectsService.loadStylesAvailableAvatarsByDealId(dealId).toPromise();
    }

    public loadCustomAvatarsByDealId(dealId: number): Promise<Avatar[]> {
        return this.avatarDealEffectsService.loadCustomAvatarsByDealId(dealId).toPromise();
    }

    public getAvatars(): Observable<Avatar[]> {
        return this.avatarDealStoreService.getClientCardAvatars();
    }

    public getSeasonalProposalAvatars(): Observable<Avatar[]> {
        return this.avatarDealStoreService.getSeasonalProposalAvatars();
    }

    public getStylesAvailableAvatars(): Observable<Avatar[]> {
        return this.avatarDealStoreService.getStylesAvailableAvatars();
    }

    public getCustomAvatars(): Observable<Avatar[]> {
        return this.avatarDealStoreService.getCustomAvatars();
    }

    public addToSeasonalProposalAvatars(avatar: Avatar): void {
        const avatars = this.avatarDealStoreService.getSeasonalProposalAvatarsSnapshot();
        avatars.push(avatar);
        this.avatarDealStoreService.setSeasonalProposalAvatars(avatars);
    }

    public addToStylesAvailableAvatars(avatar: Avatar): void {
        const avatars = this.avatarDealStoreService.getStylesAvailableAvatarsSnapshot();
        avatars.push(avatar);
        this.avatarDealStoreService.setStylesAvailableAvatars(avatars);
    }

    public loadAvatarDealSettingsBuilder(dealId: number): Promise<AvatarBuilderData> {
        return this.avatarDealApiService.getAvatarDealSettingsBuilder(dealId).toPromise();
    }

    public createCustomAvatar(dealId: number, avatarBuilderData: AvatarBuilderData): Promise<number> {
        return this.avatarDealEffectsService.createCustomAvatar(dealId, avatarBuilderData).toPromise();
    }

    public updateAvatarDealSettings(dealId: number, avatarBuilderData: AvatarBuilderData): Promise<boolean> {
        return this.avatarDealApiService.updateAvatarDealSettings(dealId, avatarBuilderData).toPromise();
    }

    public updateAvatar(avatarId: number, avatarBuilderData: AvatarBuilderData): Promise<boolean> {
        return this.avatarDealEffectsService.updateClientCardAvatar(avatarId, avatarBuilderData).toPromise();
    }

    public updateCustomAvatar(avatarId: number, avatarBuilderData: AvatarBuilderData): Promise<boolean> {
        return this.avatarDealEffectsService.updateCustomAvatar(avatarId, avatarBuilderData).toPromise();
    }

    public deleteAvatar(avatarId: number): Promise<boolean> {
        return this.avatarDealEffectsService.deleteClientCardAvatar(avatarId).toPromise();
    }

    public deleteCustomAvatar(avatarId: number): Promise<boolean> {
        return this.avatarDealEffectsService.deleteCustomAvatar(avatarId).toPromise();
    }

    public downloadDealAvatar(dealId: number, avatarId: number): Promise<string> {
        return this.avatarDealApiService.downloadDealAvatar(dealId, avatarId).toPromise();
    }

    public downloadSeasonalProposalAvatarsByDealId(dealId: number): Promise<Blob> {
        return this.avatarDealApiService.downloadSeasonalProposalAvatarsByDealId(dealId).toPromise();
    }

    public downloadStylesAvailableAvatarsByDealId(dealId: number): Promise<Blob> {
        return this.avatarDealApiService.downloadStylesAvailableAvatarsByDealId(dealId).toPromise();
    }

    public downloadAllClientCardAvatarsByDealId(dealId: number): Promise<Blob> {
        return this.avatarDealApiService.downloadAllClientCardAvatarsByDealId(dealId).toPromise();
    }

    public downloadAllCustomAvatarsByDealId(dealId: number): Promise<Blob> {
        return this.avatarDealApiService.downloadAllCustomAvatarsByDealId(dealId).toPromise();
    }
}