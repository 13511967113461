import { Component, ViewChild, Input, ElementRef, Inject } from '@angular/core';
import { CustomSelectFilterComponent } from '../custom-select-filter/custom-select-filter.component';
import { ConfirmPersonDataType } from 'src/app/core/enums/ConfirmPersonDataType';
import { DealDetails } from 'src/app/core/models/DealDetails';
import { UtilsHelper } from 'src/app/core/utils/utils.helper';
import { FilterType } from 'src/app/core/enums/FilterType.enum';
import { DealService } from 'src/app/core/services/deals.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'pd-custom-address-confirm',
  templateUrl: './custom-address-confirm.component.html',
  styleUrls: ['./custom-address-confirm.component.scss']
})
export class CustomAddressConfirmComponent {
  closeModal: any;
  saveDate: any;

  initPersonDataType: ConfirmPersonDataType;
  currentPersonDataType: ConfirmPersonDataType;
  confirmPersonDataType = ConfirmPersonDataType;
  confirmationPersonDataTypes;

  confirmAction: any;

  dealsPerson: DealDetails;
  prevDealsPerson: DealDetails = new DealDetails();
  isSkipHomeAddress = false;
  isShowValidationMessages = false;
  addressLabel = "Please, write the address"
  public addressValidationTooltip = UtilsHelper.addressValidationMessage;

  isAddressModal = false;
  isCompanyModal = false;
  isLocationModal = false;

  @ViewChild("companyFilter") customSelectCompany: CustomSelectFilterComponent
  @ViewChild("companyInput") companyInput: ElementRef
  public isCompanyEdit = false
  public isSelectedCompany = false

  @ViewChild("locationFilter") customSelectLocation: CustomSelectFilterComponent;
  @ViewChild("locationInput") locationInput: ElementRef;
  public isLocationEdit = false;
  public isSelectedLocation = false

  selectedCompanyAddress: string;
  selectedHomeAddress: string;
  selectedLocation: string;
  selectedCompany: string;

  savedLocation: string;
  savedCompany: string;

  filterType = FilterType

  modalTitle: string;
  stillTitle: string;

  constructor(private dealService: DealService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<CustomAddressConfirmComponent>) {

    this.closeModal = data.closeModal;
    this.saveDate = data.saveDate;
    this.dealsPerson = data.dealsPerson;
    this.confirmAction = data.confirmCallback;
    this.initPersonDataType = (this.data.initPersonDataType) ? this.data.initPersonDataType : ConfirmPersonDataType.Address;
    this.currentPersonDataType = this.initPersonDataType;
    this.openAddressConfirmModal();
  }

  private openAddressConfirmModal() {
    this.isSkipHomeAddress = false;

    this.confirmationPersonDataTypes = [
      { order: 1, dataType: ConfirmPersonDataType.Address, isConfirm: false },
      { order: 2, dataType: ConfirmPersonDataType.Company, isConfirm: false },
      { order: 3, dataType: ConfirmPersonDataType.Location, isConfirm: false }
    ]

    this.selectedCompanyAddress = this.dealsPerson.companyAddress;
    this.selectedHomeAddress = this.dealsPerson.homeAddress;
    this.selectedCompany = this.dealsPerson.company;
    this.selectedLocation = this.dealsPerson.location;

    this.savedLocation = "";
    this.savedCompany = "";

    this.setModalState();
    this.sortConfirmationPerson();
  }

  isShowYesNo() {
    return (!this.isAddressModal && this.initPersonDataType != this.confirmPersonDataType.Address && this.currentPersonDataType == this.confirmPersonDataType.Address)
      || (!this.isCompanyModal && this.initPersonDataType != this.confirmPersonDataType.Company && this.currentPersonDataType == this.confirmPersonDataType.Company)
      || (!this.isLocationModal && this.initPersonDataType != this.confirmPersonDataType.Location && this.currentPersonDataType == this.confirmPersonDataType.Location)
  }

  isShowStill() {
    return (!this.isCompanyModal && this.initPersonDataType != this.confirmPersonDataType.Company && this.currentPersonDataType == this.confirmPersonDataType.Company) ||
      (!this.isLocationModal && this.initPersonDataType != this.confirmPersonDataType.Location && this.currentPersonDataType == this.confirmPersonDataType.Location)
  }

  onClickNo() {
    switch (this.currentPersonDataType) {
      case ConfirmPersonDataType.Address:
        this.isAddressModal = !this.isAddressModal;
        break;
      case ConfirmPersonDataType.Company:
        this.isCompanyModal = !this.isCompanyModal;
        break;
      case ConfirmPersonDataType.Location:
        this.isLocationModal = !this.isLocationModal;
        break;
    }
  }

  setModalState() {
    this.isAddressModal = false;
    this.isCompanyModal = false;
    this.isLocationModal = false;

    switch (this.currentPersonDataType) {
      case ConfirmPersonDataType.Address:
        this.modalTitle = "Confirm addresses";
        this.stillTitle = "";
        this.isAddressModal = true;
        break;
      case ConfirmPersonDataType.Company:
        this.modalTitle = "Confirm company";
        if (this.dealsPerson.company) {
          this.stillTitle = "Does the client work in " + this.dealsPerson.company.slice(0, 30) + ((this.dealsPerson.company.length > 30) ? '...' : '') + "?";
        }

        this.isCompanyModal = this.currentPersonDataType == this.initPersonDataType || !this.dealsPerson.company;
        break;
      case ConfirmPersonDataType.Location:
        this.modalTitle = "Confirm location";
        if (this.dealsPerson.location) {
          this.stillTitle = "Is he still in " + this.dealsPerson.location.slice(0, 30) + ((this.dealsPerson.location.length > 30) ? '...' : '') + "?";
        }

        this.isLocationModal = this.currentPersonDataType == this.initPersonDataType || !this.dealsPerson.location;
        break;
    }
  }

  setNextModalState(isSameValue: boolean) {
    if (!this.validation()) return;

    let currentConfirmationPersonDataType = this.confirmationPersonDataTypes.find(f => f.dataType == this.currentPersonDataType);
    currentConfirmationPersonDataType.isConfirm = true;

    switch (this.currentPersonDataType) {
      case ConfirmPersonDataType.Company:
        if (isSameValue) {
          this.savedCompany = this.dealsPerson.company;
        }
        else {
          this.savedCompany = this.selectedCompany;
        }

        break;
      case ConfirmPersonDataType.Location:
        if (isSameValue) {
          this.savedLocation = this.dealsPerson.location;
        }
        else {
          this.savedLocation = this.selectedLocation;
        }
        break;
    }

    currentConfirmationPersonDataType = this.confirmationPersonDataTypes.find(f => !f.isConfirm);

    if (currentConfirmationPersonDataType) {
      this.currentPersonDataType = currentConfirmationPersonDataType.dataType;
      this.setModalState();
    }
    else {
      this.dealsPerson.companyAddress = this.selectedCompanyAddress;
      if (!this.isSkipHomeAddress) {
        this.dealsPerson.homeAddress = this.selectedHomeAddress
      }
      this.dealsPerson.company = this.savedCompany;
      this.dealsPerson.location = this.savedLocation;

      this.confirm(true);
    }
  }

  setPrevModalState() {
    this.confirmationPersonDataTypes.reverse();
    let currentConfirmationPersonDataType = this.confirmationPersonDataTypes.find(f => f.isConfirm);
    this.confirmationPersonDataTypes.reverse();

    if (currentConfirmationPersonDataType) {
      currentConfirmationPersonDataType.isConfirm = false;
      this.currentPersonDataType = currentConfirmationPersonDataType.dataType;
      this.setModalState();
    }
  }

  public confirm(isConfirm) {
    if (isConfirm) {
      this.ok();
    } else {
      this.cancel()
    }

    this.dealsPerson = null;
    this.dialogRef.close();
  }

  private sortConfirmationPerson() {
    let currentConfirmationPersonDataType = this.confirmationPersonDataTypes.find(f => f.dataType == this.currentPersonDataType);
    currentConfirmationPersonDataType.order = 0;
    this.confirmationPersonDataTypes = this.confirmationPersonDataTypes.sort((a, b) => a.order - b.order);
  }

  private validation() {
    this.isShowValidationMessages = true;

    return !this.isAddressModal || (this.homeAddressValidation() && this.companyAddressValidation());
  }

  public companyAddressValidation() {
    return this.dealsPerson && (this.checkAddressIsValid(this.selectedCompanyAddress));
  }

  public homeAddressValidation() {
    return this.dealsPerson && (this.isSkipHomeAddress || this.checkAddressIsValid(this.selectedHomeAddress));
  }

  private checkAddressIsValid(address: string) {
    return UtilsHelper.checkAddressIsValid(address);
  }

  ok() {
    this.saveDate(this.dealsPerson);
    if (this.confirmAction) {
      this.confirmAction();
    }
  }

  cancel() {
    this.closeModal();
  }

  saveEdit() {
    switch (this.currentPersonDataType) {
      case ConfirmPersonDataType.Address:
        break;
      case ConfirmPersonDataType.Company:
        let company = this.companyInput.nativeElement.value;
        this.isCompanyEdit = false;
        this.emitCompanyChangeValue(company);
        break;
      case ConfirmPersonDataType.Location:
        let location = this.locationInput.nativeElement.value;
        this.isLocationEdit = false;
        this.emitLocationChangeValue(location)
        break;
    }
  }

  cancelEdit() {
    switch (this.currentPersonDataType) {
      case ConfirmPersonDataType.Address:
        break;
      case ConfirmPersonDataType.Company:
        this.isCompanyEdit = false
        break;
      case ConfirmPersonDataType.Location:
        this.isLocationEdit = false
        break;
    }
  }

  public get isShowAddCompanyValue(): boolean {
    return !this.isSelectedCompany && !this.isCompanyEdit;
  }

  addCompanyValue() {
    this.isCompanyEdit = true;
  }

  changeCompanyValue() {
    this.emitCompanyChangeValue(null)
  }

  onCompanyChanged(isSelectedItem: boolean) {
    this.isSelectedCompany = isSelectedItem
    this.emitCompanyChangeValue(null)
  }

  emitCompanyChangeValue(company: string) {
    if (company === null) {
      if (this.customSelectCompany.localChecked.length > 0) {
        this.selectedCompany = this.customSelectCompany.localChecked[0].name;
      } else {
        //this.dealsPerson.company = '';
      }
    } else {
      this.selectedCompany = company;
    }
  }

  getCompaniesAutocomplete(event) {
    let isNotleter: number = event.isNotLetter;
    this.dealService.getFilterDataAutocomplete(FilterType.Company, event.searchString, event.skip, event.take).then(response => {
      if (this.customSelectCompany && isNotleter == this.customSelectCompany.isNotLetter) {
        this.customSelectCompany.setData(response);
      }
    });
  }

  getCompaniesFilterData(event) {
    let isNotleter: number = event.isNotLetter;
    this.dealService.getFilterData(FilterType.Company, event.skip, event.take).then(response => {
      if (this.customSelectCompany && isNotleter == this.customSelectCompany.isNotLetter) {
        this.customSelectCompany.setData(response);
      }
    });
  }

  addLocationValue() {
    this.isLocationEdit = true;
  }

  changeLocationValue() {
    this.emitLocationChangeValue(null)
  }

  emitLocationChangeValue(location: string) {
    if (location === null) {
      if (this.customSelectLocation.localChecked.length > 0) {
        this.selectedLocation = this.customSelectLocation.localChecked[0].name;
      } else {
        //this.dealsPerson.location = '';
      }
    } else {
      this.selectedLocation = location;
    }
  }

  onLocationsChanged(isSelectedItem: boolean) {
    this.isSelectedLocation = isSelectedItem
    this.emitLocationChangeValue(null)
  }

  getLocationsFilterData(event) {
    let isNotleter: number = event.isNotLetter;
    this.dealService.getFilterData(FilterType.Location, event.skip, event.take).then(response => {
      if (this.customSelectLocation && isNotleter == this.customSelectLocation.isNotLetter) {
        this.customSelectLocation.setData(response);
      }
    });
  }

  getLocationsAutocomplete(event) {
    let isNotleter: number = event.isNotLetter;
    this.dealService.getFilterDataAutocomplete(FilterType.Location, event.searchString, event.skip, event.take).then(response => {
      if (this.customSelectLocation && isNotleter == this.customSelectLocation.isNotLetter) {
        this.customSelectLocation.setData(response);
      }
    });
  }
}