import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';

@Injectable()
export class CsvUtil {
  constructor() {
  }

  public static saveFile(data, fileName: string, fields: string[], headers: string[]) {
    if (!fields) {
      fields = fields || Object.keys(data[0]);
    }
    var replacer = function (key, value) { return value === null ? '' : value };
    var csv = data.map(function (row) {
      return fields.map(function (fieldName) {
        return JSON.stringify(row[fieldName], replacer)
      }).join(',')
    });
    csv.unshift((headers || fields).join(','));
    let parsedResponse = csv.join('\r\n');
    let blob = new Blob([parsedResponse], { type: 'text/csv' });
    saveAs(blob, fileName + ".csv");
  }

  normalize(str) {
    if (str) {
      return str.replace(/\n/ig, '')  //remove \n
    }
    return str;
  }
}
