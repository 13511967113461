import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'pd-field-phones',
  templateUrl: './field-phones.component.html',
  styleUrls: ['./field-phones.component.scss']
})
export class FieldPhonesComponent implements OnInit {

  public phones = [
  ];
  @Output() phonesChange = new EventEmitter<Object>();
  public isEdit = false;
  public defaultPhone = [];

  constructor() { }

  ngOnInit() {
    this.defaultPhone = this.deepClone(this.phones);
  }

  /**
    * Add value
    * open edit
    * @memberof FieldPhonesComponent
    */

  public addValue() {
    this.isEdit = true;
    this.phones.push({ value: '', label: 'Work', isPrimary: true });
  }

  /**
    * Save value
    * @param {any} value phones value
    * @memberof FieldPhonesComponent
    */

  public saveValue(value) {
    this.phones = value;
    if (!this.phones[0]) {
      this.isEdit = false;
    }
    this.defaultPhone = this.deepClone(this.phones);
    this.phonesChange.emit(this.phones);
  }

  /**
    * Cancel edit
    * @memberof FieldPhonesComponent
    */

  public cancelEdit() {
    this.isEdit = false;

  }

  /**
    * Go to edit
    * open edit
    * @memberof FieldPhonesComponent
    */

  public goToEdit() {
    this.isEdit = true;
  }

  public cancel() {
    this.isEdit = false;
    this.phones = this.deepClone(this.defaultPhone);
  }

  /**
    * Deep clone
    * @param {Object[]} array phones array
    * @memberof FieldPhonesComponent
    */

  public deepClone(array: Object[]) {
    const newArray: any = [];
    array.forEach((item) => {
      newArray.push(Object.assign({}, item));
    });
    return newArray;
  }

}
