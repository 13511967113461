import { StageFeatureStoreService } from './../../core/services/stores/stage-feature-store.service';
import { PipelineStoreService } from './../../core/services/stores/pipeline-store.service';
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import * as stages from '../../../assets/stages_new_prospespects';
import { User } from "src/app/core/models/UserModel";
import { Pipeline } from "src/app/core/models/Pipeline";
import { UserContextService } from "src/app/core/services/user-context.service";
import { RolesEnum } from "src/app/core/enums/RolesEnum";
import { UserStoreService } from 'src/app/core/services/stores/user-store.service';
import { GlobalConstants } from 'src/app/core/global-constants';
import { Stage } from 'src/app/core/models/Stage';

@Component({
  selector: 'pd-left-deals-panel',
  templateUrl: './left-deals-panel.component.html',
  styleUrls: ['./left-deals-panel.component.scss']
})

export class LeftDealsPanelComponent implements OnInit {
  @Input() public isMasterLeadDeals = false;
  @Input() public isListDeals = false;
  @Input() public isFilterPerformed = false;
  @Input() public isMoreDeals = false;
  @Input() public isListDealRender = false;

  @Output() onRoutToPipelineClicked = new EventEmitter();
  @Output() onRoutToListClicked = new EventEmitter();
  @Output() onRoutToNewDealClicked = new EventEmitter();
  @Output() onLoadingMoreDealsClicked = new EventEmitter();
  @Output() onMoveDealsClicked = new EventEmitter();
  @Output() onExportLeadsClicked = new EventEmitter();
  @Output() onExportFileClicked = new EventEmitter();
  @Output() onExportEmailsClicked = new EventEmitter();
  @Output() onExportLookupsClicked = new EventEmitter();
  @Output() onFindEmailsClicked = new EventEmitter();
  @Output() onSelectedPipelineChanged = new EventEmitter<number>();

  public selectedPipelineId: number;
  public PIPELINES = stages.Pipelines;
  public currentUser: User;
  public allPipelines: Pipeline[];

  public isAdmin = false;
  public isMasterLeadAccount = false;
  public isAdminAccount = false;
  public isClothierAccount = false;
  public isMissedMtg = false;
  public isDirectMail = false;
  public isConferenceAccount = false;
  public isEventAccount = false;
  public isMyDeals = false;
  public isCampaignDeals = false;
  public isEaAccount = false;
  public isEaCallerAccount = false;
  public isRecruiterAccount = false;
  public isBbAccount = false;

  public isVisibleExportEmail: boolean;
  public isEnableExportEmail: boolean;
  public isVisibleExportLookup: boolean;
  public isEnableExportLookup: boolean;
  public isVisibleEmailFinder: boolean;
  public isEnableEmailFinder: boolean;
  public isTransferEnabled: boolean;
  public isEnableExportFile: boolean;
  public isVisibleExportLeads: boolean;
  public isStagesFittableForExport: boolean;
  public ALL_STAGES = GlobalConstants.ALL_STAGES

  constructor(
    private userContextService: UserContextService,
    private pipelineStoreService: PipelineStoreService,
    private userStoreService: UserStoreService,
    private stageFeatureStoreService: StageFeatureStoreService) {
  }

  ngOnInit() {
    this.initializeStore();
  }

  private initializeStore() {
    this.currentUser = this.userContextService.user.value;
    this.isAdmin = this.currentUser && this.currentUser.is_admin
    this.isMasterLeadAccount = this.currentUser && this.currentUser.role_id === RolesEnum.MasterLead
    this.isAdminAccount = this.currentUser && this.currentUser.role_id === RolesEnum.Admin
    this.isClothierAccount = this.currentUser && this.currentUser.role_id === RolesEnum.Clothier
    this.isDirectMail = this.currentUser && this.currentUser.role_id === RolesEnum.DirectMailAccount
    this.isConferenceAccount = this.currentUser && this.currentUser.role_id === RolesEnum.Conference
    this.isEventAccount = this.currentUser && this.currentUser.role_id === RolesEnum.EventAccount
    this.isEaAccount = this.currentUser && this.currentUser.role_id === RolesEnum.EA;
    this.isEaCallerAccount = this.currentUser && this.currentUser.role_id === RolesEnum.EaCaller;
    this.isRecruiterAccount = this.currentUser && this.currentUser.role_id === RolesEnum.Recruiter
    this.isBbAccount = this.currentUser && this.currentUser.role_id === RolesEnum.BB

    this.isCampaignDeals = this.currentUser && this.currentUser.role_id === RolesEnum.CampaignAccount
    this.isMissedMtg = this.currentUser && this.currentUser.role_id === RolesEnum.MissedAccount


    this.userStoreService.selectedUsersFilter.subscribe((usersFilter: User[]) => {
      if (!usersFilter || usersFilter.length == 0) {
        return;
      }
      this.allPipelines = this.getAvaliablePipelines(usersFilter);
      this.isEnableExportFile = this.getIsEnableExport(this.currentUser, usersFilter);
      this.isVisibleExportLeads = this.getIsVisibleExportLeads(this.currentUser, usersFilter);
      this.isVisibleExportEmail = this.getIsVisibleEmailExport(this.currentUser, usersFilter);
      this.isVisibleExportLookup = this.getIsVisibleLookupExport(this.currentUser, usersFilter);
      this.isVisibleEmailFinder = this.getIsVisibleEmailFinder(this.currentUser, usersFilter);
      this.stageFeatureStoreService.getCurrentStages.subscribe((currentStages: Stage[]) => {
        if (!currentStages) {
          return;
        }
        this.isTransferEnabled = this.getIsEnabledTransferForSelectedStages(usersFilter[0] ? usersFilter[0] : this.currentUser, currentStages);
        this.isEnableEmailFinder = this.getIsEnableEmailFinder(this.currentUser, currentStages, usersFilter);
        this.isEnableExportLookup = this.getIsEnableLookupExport(this.currentUser, currentStages, usersFilter);
        this.isTransferEnabled = this.getIsEnabledMoveReferrals(this.currentUser, usersFilter, this.selectedPipelineId);
        this.isEnableExportEmail = this.getIsEnableEmailExport(this.currentUser, currentStages, usersFilter);
        this.isStagesFittableForExport = this.getIsStagesFilterFittableForExport(this.currentUser, usersFilter, currentStages);
      });
    });
    this.pipelineStoreService.currentPipeline.subscribe((pipeline: Pipeline) => {
      if (!pipeline) return;
      this.selectedPipelineId = pipeline.id;
    });
  }

  private getIsStagesFilterFittableForExport(currentUser?: User, usersFilter?: User[], selectedStages?: Stage[]): boolean {
    if (currentUser && usersFilter && usersFilter.length > 0 &&
      (currentUser.role_id === RolesEnum.CampaignAccount || (currentUser.is_admin && usersFilter[0].role_id === RolesEnum.CampaignAccount))
      && selectedStages && selectedStages.length > 0 && selectedStages.every(s => stages.CAMPAIGN_EXPORT_STAGES.some(stageId => stageId === s.id))) {
      return true;
    }
    if (currentUser && usersFilter && usersFilter.length > 0 &&
      (currentUser.role_id === RolesEnum.DirectMailAccount || (currentUser.is_admin && usersFilter[0].role_id === RolesEnum.DirectMailAccount))
      && selectedStages && selectedStages.length > 0 && selectedStages.every(s => stages.DIRECTMAIL_EXPORT_STAGES.some(stageId => stageId === s.id))) {
      return true;
    }
    return false;
  };

  private getIsEnabledTransferForSelectedStages = (user: User, selectedStagesFilter: Stage[]): boolean => {
    if (RolesEnum[user.role_id] == RolesEnum[RolesEnum.Admin]) {
      let isAllProspectStagesSelected = selectedStagesFilter.filter(stage => stages.PROSPECTS_BY_ROLES[RolesEnum[user.role_id].toString()].includes(stage.name)).length ===
        stages.PROSPECTS_BY_ROLES[RolesEnum[user.role_id].toString()].length;
      return selectedStagesFilter && selectedStagesFilter.length > 0 && selectedStagesFilter.every(s => s.pipeline_id == stages.NewProspectsPipeline.id && (s.id != this.ALL_STAGES.Meeting
        && s.id != this.ALL_STAGES.ClientSaved && s.id != this.ALL_STAGES.SM1)) && !isAllProspectStagesSelected;
    }
    return selectedStagesFilter && selectedStagesFilter.length > 0 && selectedStagesFilter.every(s => s.pipeline_id == stages.NewProspectsPipeline.id && (s.id != this.ALL_STAGES.Meeting
      && s.id != this.ALL_STAGES.ClientSaved && s.id != this.ALL_STAGES.SM1));
  };

  private getIsVisibleEmailExport(currentUser: User, selectedUsersFilter: User[]): boolean {
    return currentUser && (currentUser.role_id === RolesEnum.EmailMarketing
      || (currentUser.is_admin && selectedUsersFilter && selectedUsersFilter.length > 0 && selectedUsersFilter[0].role_id == RolesEnum.EmailMarketing));
  }

  private getIsVisibleLookupExport(currentUser: User, selectedUsersFilter: User[]): boolean {
    return currentUser && (currentUser.is_admin && currentUser.role_id !== RolesEnum.EA && currentUser.role_id !== RolesEnum.EaCaller && selectedUsersFilter && selectedUsersFilter.length > 0 && selectedUsersFilter[0].role_id == RolesEnum.MasterLead);
  }

  private getIsVisibleEmailFinder(currentUser: User, selectedUsersFilter: User[]): boolean {
    return currentUser && (currentUser.role_id === RolesEnum.EmailMarketing
      || (currentUser.is_admin && selectedUsersFilter && selectedUsersFilter.length > 0 && selectedUsersFilter[0].role_id == RolesEnum.EmailMarketing));
  }

  private getIsVisibleExportLeads(currentUser: User, selectedUsersFilter: User[]): boolean {
    return currentUser &&
      (currentUser.role_id === RolesEnum.MasterLead
        || (currentUser.role_id === RolesEnum.Admin && selectedUsersFilter && selectedUsersFilter.length > 0)
        || (currentUser.role_id === RolesEnum.Clothier && selectedUsersFilter && selectedUsersFilter.length == 1 && (selectedUsersFilter[0].id == currentUser.id))
      );
  }
  private getIsEnableExport(currentUser: User, selectedUsersFilter: User[]): boolean {
    if (currentUser && (currentUser.role_id === RolesEnum.CampaignAccount || currentUser.role_id === RolesEnum.DirectMailAccount)) {
      return true;
    }
    if (selectedUsersFilter && selectedUsersFilter.length > 0 && (selectedUsersFilter[0].role_id === RolesEnum.CampaignAccount || selectedUsersFilter[0].role_id === RolesEnum.DirectMailAccount)) {
      return true;
    }
    return false;

  }

  private getIsEnableEmailExport(currentUser: User, selectedStages: Stage[], selectedUsersFilter: User[]): boolean {
    return currentUser && (currentUser.role_id === RolesEnum.EmailMarketing ||
      (currentUser.is_admin && selectedUsersFilter && selectedUsersFilter.length > 0 && selectedUsersFilter[0].role_id == RolesEnum.EmailMarketing))
      && selectedStages && selectedStages.length === 1 && selectedStages.every(st => st.id == this.ALL_STAGES.EmailFound);
  }

  private getIsEnabledMoveReferrals(currentUser: User, selectedUsersFilter: User[], currentPipelineId: number): boolean {
    return currentUser && (currentUser.role_id === RolesEnum.Clothier ||
      (currentUser.is_admin && selectedUsersFilter && selectedUsersFilter.length > 0 && selectedUsersFilter[0].role_id == RolesEnum.Clothier)) && currentPipelineId === stages.Pipelines.NewProspects;
  }

  private getIsEnableEmailFinder(currentUser: User, selectedStages: Stage[], selectedUsersFilter: User[]): boolean {
    return currentUser && (currentUser.role_id === RolesEnum.EmailMarketing ||
      (currentUser.is_admin && selectedUsersFilter && selectedUsersFilter.length > 0 && selectedUsersFilter[0].role_id == RolesEnum.EmailMarketing))
      && selectedStages && selectedStages.length === 1 && selectedStages.every(st => st.id == this.ALL_STAGES.Cold);
  }

  private getIsEnableLookupExport(currentUser: User, selectedStages: Stage[], selectedUsersFilter: User[]): boolean {
    return currentUser && (currentUser.is_admin && currentUser.role_id !== RolesEnum.EA && currentUser.role_id !== RolesEnum.EaCaller && selectedUsersFilter && selectedUsersFilter.length > 0 && selectedUsersFilter[0].role_id == RolesEnum.MasterLead)
      && selectedStages && selectedStages.length === 1 && selectedStages.every(st => st.id == this.ALL_STAGES.ColdLOOKUP || st.id == this.ALL_STAGES.LinkedinLOOKUP);
  }

  private getAvaliablePipelines(usersFilter): Pipeline[] {
    const allFeatureItems = stages.ALL_PIPELINES;
    const currentUser = this.currentUser;
    if (((currentUser && currentUser.role_id) || (usersFilter && usersFilter.length > 0 && usersFilter[0])) && allFeatureItems && allFeatureItems.length > 0) {
      if (currentUser.is_admin && currentUser.role_id !== RolesEnum.EA && currentUser.role_id !== RolesEnum.EaCaller) {
        if (usersFilter && usersFilter.length !== 0 && (usersFilter[0].role_id === RolesEnum.Admin || usersFilter[0].role_id === RolesEnum.MasterLead))
          return allFeatureItems;
        else if (!usersFilter || usersFilter.length == 0 || usersFilter[0].is_admin)
          if (currentUser.role_id === RolesEnum.MasterLead)
            return allFeatureItems;
          else
            return allFeatureItems.filter(p => p.id !== stages.Pipelines.ClothierMeetingConfirm);
        else if (usersFilter && usersFilter[0] && (usersFilter[0].role_id === RolesEnum.Clothier || usersFilter[0].role_id === RolesEnum.MasterLead))
          return allFeatureItems
      }
      if ((currentUser.role_id === RolesEnum.EaCaller) || (usersFilter && usersFilter.length > 0 && (usersFilter[0].role_id === RolesEnum.EaCaller))) {
        return allFeatureItems.filter(p => p.id === stages.ClientsPipeline.id || p.id === stages.ClothierContactClientsPipeline.id);
      } else if ((currentUser.role_id === RolesEnum.EA) || (usersFilter && usersFilter.length > 0 && (usersFilter[0].role_id === RolesEnum.EA))) {
        return allFeatureItems.filter(p => p.id === stages.OpenOrders.id || p.id === stages.ClothierContactClientsPipeline.id);
      } else if (usersFilter && usersFilter.length > 0 && usersFilter[0].role_id === RolesEnum.Clothier) {
        if (currentUser.role_id === RolesEnum.Clothier)
          return allFeatureItems
        else if (currentUser.is_admin)
          return allFeatureItems
      } else if (usersFilter && usersFilter.length > 0 && usersFilter[0].role_id === RolesEnum.BB) {
        return allFeatureItems.filter(p => p.id === stages.NewProspectsPipeline.id);
      } else if (usersFilter && usersFilter.length > 0 && usersFilter[0].role_id === RolesEnum.User) {
        return allFeatureItems.filter(p => p.id !== stages.ClothierMeetingConfirmPipeline.id);
      } else if (usersFilter && usersFilter.length > 0 && usersFilter[0].role_id === RolesEnum.EmailMarketing) {
        return allFeatureItems.filter(p => p.id === stages.NewProspectsPipeline.id);
      } else if (usersFilter && usersFilter.length > 0 && usersFilter[0].role_id === RolesEnum.Recruiter) {
        return allFeatureItems.filter(p => p.id === stages.NewProspectsPipeline.id);
      } else {
        return allFeatureItems.filter(p => p.id === stages.ClientsPipeline.id || p.id === stages.OpenOrders.id || p.id === stages.NewProspectsPipeline.id);
      }
    }
    return [];
  }
  //////////////////////////////// VIEW *ngIF START //////////////////////////////

  public get isShowRoutToPipeline(): boolean {
    return !this.isEaAccount && !this.isEaCallerAccount && (this.isListDeals || this.isMasterLeadDeals);
  }

  public get isShowRoutToList(): boolean {
    return !this.isEaAccount && !this.isEaCallerAccount && (!this.isListDeals || this.isMasterLeadDeals);
  }

  public get isShowRoutToNewDeal(): boolean {
    return !this.isEaAccount && !this.isEaCallerAccount && !this.isBbAccount && this.selectedPipelineId === this.PIPELINES.NewProspects;
  }

  public get isShowPipelineSelect(): boolean {
    return !this.isListDeals;
  }

  public get isShowMoveDeals(): boolean {
    return this.isListDeals && !this.isEaAccount && !this.isEaCallerAccount && !this.isRecruiterAccount;
  }

  public get isShowExportLeads(): boolean {
    return this.isListDeals && this.isVisibleExportLeads;
  }

  public get isShowExportFile(): boolean {
    return this.isListDeals && this.isEnableExportFile;
  }

  public get isShowExportEmail(): boolean {
    return this.isListDeals && this.isVisibleExportEmail;
  }

  public get isShowLookupExport(): boolean {
    return this.isListDeals && this.isVisibleExportLookup;
  }

  public get isShowEmailFinder(): boolean {
    return this.isListDeals && this.isVisibleEmailFinder;
  }

  public get isShowSameBuildingCompanies(): boolean {
    return this.isMasterLeadAccount || this.isAdminAccount || this.isClothierAccount;
  }

  //////////////////////////////// VIEW *ngIF END ////////////////////////////////

  //////////////////////////////// VIEW disabled START //////////////////////////////

  public get isPipelineSelectDisabled(): boolean {
    return this.isMissedMtg || this.isDirectMail || this.isCampaignDeals || this.isConferenceAccount || this.isEventAccount;
  }

  public isMoveDealsDisabled(): boolean {
    return !this.isListDeals || !this.isListDealRender || !this.isFilterPerformed;
  }

  public get isExportFileDisabled(): boolean {
    return !this.isStagesFittableForExport || !this.isListDeals || !this.isListDealRender || !this.isFilterPerformed;
  }

  public get isExportEmailDisabled(): boolean {
    return !this.isEnableExportEmail || !this.isListDeals || !this.isListDealRender || !this.isFilterPerformed;
  }

  public get isExportLookupDisabled(): boolean {
    return !this.isEnableExportLookup || !this.isListDeals || !this.isListDealRender || !this.isFilterPerformed;
  }

  public get isEmailFinderDisabled(): boolean {
    return !this.isEnableEmailFinder || !this.isListDeals || !this.isListDealRender || !this.isFilterPerformed;
  }

  //////////////////////////////// VIEW disabled END //////////////////////////////
}
