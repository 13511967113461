import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorHandlerService } from './error-handler.service';
import { firstValueFrom } from 'rxjs';
import { GlobalConstants } from '../../global-constants';
import { environment } from '../../../../environments/environment';

@Injectable()
export class HttpRestService {
  private static readonly DefaultUrl: string = environment.apiUrl;
  private static readonly DefaultHeader = {
    'Content-Type': 'application/json;charset=UTF-8',
    Accept: 'application/json'
  };

  constructor(
    private http: HttpClient,
    private errorHandler: ErrorHandlerService) { }

  public get(request: IRequest): Promise<IResponse> {
    request.method = 'GET';
    return this.request(request);
  }

  public post(request: IRequest): Promise<IResponse> {
    request.method = 'POST';
    return this.request(request);
  }

  public put(request: IRequest): Promise<IResponse> {
    request.method = 'PUT';
    return this.request(request);
  }

  public delete(request: IRequest): Promise<IResponse> {
    request.method = 'DELETE';
    return this.request(request);
  }

  public upload(req: IRequest): Promise<IResponse> {
    req.method = 'POST';
    const options = this.getDefault(req);
    return firstValueFrom(this.http.request(options.method, options.url, {
      body: options.body,
      headers: options.headers,
    }));
  }

  public getUrl(action: string): string {
    return `${HttpRestService.DefaultUrl}${action}`;
  }

  private async request(req: IRequest): Promise<IResponse> {
    return this.tryGetData(this.getRequestOptions(req)).catch(async (response: Response) => {
      if (!req.noErrorHandling) {
        if (response.status === 401) {
          if (await this.tryRefreshToken()) {
            return await this.tryGetData(this.getRequestOptions(req));
          }
        }
        if(response.status === 400) {
          return response;
        }
        const result = this.errorHandler.handle(response as any, req.expectedErrors);
        if (result) {
          return {
            error: result
          }
        }
      }
    }) as Promise<IResponse>;
  }

  private getRequestOptions(req: IRequest) {
    let headers = new HttpHeaders(HttpRestService.DefaultHeader);
    return this.getDefaultReqOptions(req, headers);
  }

  private getDefaultReqOptions(req: IRequest, headers: HttpHeaders) {
    Date.prototype.toJSON = function () { this.setHours(this.getHours() + this.getTimezoneOffset() * -1 / 60); return this.toISOString() }
    req.data = typeof req.data == "string" ? req.data: JSON.stringify(req.data);
    return this.getDefault(req, headers);
  }

  private getDefault(req: IRequest, headers?: HttpHeaders) {
    if (!headers) {
      headers = new HttpHeaders();
    }

    const request = new HttpRequest(req.method as any, req.directUrl ? req.directUrl : this.getUrl(req.url), req.data, {
      headers,
      responseType: req.responseType ? req.responseType : 'json'
    });
    return request;
  }

  private tryGetData(options: HttpRequest<any>): Promise<any> {
    return firstValueFrom(this.http.request(options.method, options.url, {
      body: options.body,
      headers: options.headers,
      responseType: options.responseType,
    }))
  }

  private async tryRefreshToken(): Promise<boolean> {
    const refreshRequest = {
      url: "/account/refresh",
      method: 'POST',
      data: { refreshToken: localStorage.getItem(GlobalConstants.refreshTokenKey) }
    };
    let newJwt = null;
    try
    {
      newJwt = await this.tryGetData(this.getRequestOptions(refreshRequest));
    }
    catch (e) {
      return;
    }

    if (!newJwt) {
      return;
    }

    localStorage.setItem(GlobalConstants.jwtSessionKey, newJwt);

    return true;
  }
}
