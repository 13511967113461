import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'pd-field-city',
  templateUrl: './field-city.component.html',
  styleUrls: ['./field-city.component.scss']
})
export class FieldCityComponent implements OnInit {

  public city: string;
  @Output() cityChange = new EventEmitter<string>();
  public isEdit = false;

  constructor() { }

  ngOnInit() {
  }

  /**
    * Change edit
    * in deal
    * @memberof FieldCityComponent
    */

  public changeEdit() {
    this.isEdit = false;
    this.cityChange.emit(this.city);
  }

  /**
    * Add value
    * open edit
    * @memberof FieldCityComponent
    */

  public addValue() {
    this.isEdit = true;
  }

}
