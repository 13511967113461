<modal #modal title="Upload EA follow-up screenshot" [loadingIndicator]="isUploadLoading" primaryBtnTextKey="Upload"
  secondaryBtnTextKey="Close" [displayLaunchButton]="false"
  [isPrimaryBtnDisabled]="isUploadErrorValidation || isUploadLoading || !fileInput.value"
  (secondaryClick)="confirm(false)" (onCompleteAction)="confirm(false)" (primaryClick)="upload()">
  <div class="row">
    <div class="upload-form">
      <form action="" method="post" enctype="multipart/form-data">
        <input class="input" type="file" #fileInput ng2FileSelect [uploader]="uploader" [accept]="fileAccept" />
      </form>
    </div>
  </div>
  <div class="row alert-row">
    @if (isUploadErrorValidation) {
    <div class="alert alert-danger">
      Wrong format of the file, please try again
    </div>
    }
    @if (isUploadSuccess) {
    <div class="alert alert-success">
      Images uploaded
    </div>
    }
  </div>
</modal>