<div class="background" #clientExportCanvas>
  <div class="row header">
    <div class="col-9 title">
      <span>Product Card </span>
    </div>
  </div>

  <div class="wrapper">
    <mat-accordion [multi]="true">
      <div>
        @for (cardType of modeler3dCardTypes; track cardType) {
        <pd-3d-modeler-product-line [modeler3dCardType]='cardType'
          [modeler3dCards]='fabricCardByProduct[cardType.typeId]'></pd-3d-modeler-product-line>
        }
      </div>
    </mat-accordion>
  </div>
</div>