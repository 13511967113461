import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { HttpRestService } from './http-client/http-rest-client.service';
import { BaseService } from './base.service';
import { ColdImportModel } from '../models/ColdImportModel';

/**
 * Deal service
 * contains methods connected with deals
 * @export
 * @class HotlistService
 */
@Injectable()
export class HotlistService extends BaseService {

    constructor(private restService: HttpRestService) {
        super();
    }

    public async getHotlist(id: number): Promise<ColdImportModel[]> {
        return await this.handleResponse(this.restService.get({ url: '/test/hotlist/' + id }));
    }

    public async getHotlistByToken(token: string): Promise<ColdImportModel[]> {
        return await this.handleResponse(this.restService.get({ url: '/test/hotlist-token/' + token }));
    }

    public async generateHotlistToken(id: number): Promise<string> {
        return await this.handleResponse(this.restService.get({ url: '/test/hotlist-generate-token/' + id }));
    }

    public async getHotlistPdf(id: number): Promise<Blob> {
        return await this.handleResponse(this.restService.get({ url: '/upload/hotlist/get-pdf/' + id }));
    }

    public async getMeetingPrepPdf(dealId: any, dealTitle: any): Promise<Blob> {
        return await this.handleResponse(this.restService.get({
            url: '/upload/meetingPrep/get-pdf/' + dealId + "/" + dealTitle
        }));
    }

    public getHotlistPdfObservable(id: number): Observable<any> {
        return from(this.restService.get({ url: '/upload/hotlist/get-pdf/' + id }));
    }

    public async importReferralList(currentUserId: number, personWhoReferredId: number, referrals: any): Promise<any> {
        return this.handleResponse(this.restService.post({
            url: '/test/hotlist/import-referral',
            data: {
                CurrentUserId: currentUserId,
                PersonWhoReferredId: personWhoReferredId,
                Referrals: referrals
            }
        }));
    }

    public async importReferralListByToken(token: string, referrals: any): Promise<any> {
        return this.handleResponse(this.restService.post({
            url: '/test/hotlist/import-referral-token',
            data: {
                Token: token,
                Referrals: referrals
            }
        }));
    }

    public async getMeetingDeals(ownerId: number, start: number, limit: number): Promise<any> {
        const url = `/test/deals/dealsForUploadHotlist?ownerId=${ownerId}&start=${start}&limit=${limit}`;
        return await this.handleResponse(this.restService.get({ url: url }));
    }

    public async uploadHotlist(file: any, dealId: any, currentUserId: any): Promise<any> {
        const formData: FormData = new FormData();
        formData.append('file', file, file.name);
        formData.append('dealId', dealId);
        formData.append('currentUserId', currentUserId);

        return this.handleResponse(this.restService.upload({
            url: '/test/hotlist/upload',
            data: formData
        }));
    }

    public async uploadHotlistPdf(file: any, dealId: any, currentUserId: any): Promise<any> {
        let formData: FormData = new FormData();
        formData.append('file', file, file.name);
        formData.append('dealId', dealId);
        formData.append('currentUserId', currentUserId);

        return this.handleResponse(this.restService.upload({
            url: '/upload/hotlist/upload-pdf',
            data: formData
        }));
    }
}
