@if (isSpinner) {
<div class="spinner">
    <mat-spinner [diameter]="100"></mat-spinner>
</div>
}

<div class="col col-xl-6 center">

    <div class="row">
        <div class="col">
            <div class="header">
                <h2>New Payment</h2>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col">
            <div>
                <div class="input-row">
                    <div class="label">Client Name:<span class="star">*</span></div>
                    <input type="text" [formControl]="clientNameControl" [(ngModel)]="clientName" onCopy="return false"
                        onDrag="return false" onDrop="return false" onPaste="return false" autocomplete=off
                        [ngClass]="{'input-error': isShowClientNameValidationMessage }" (input)="validation()"
                        (blur)="validation()" (keydown)="onClientNameKeydown($event)">
                </div>
                @if (isShowClientNameValidationMessage) {
                <div class="alert-row">
                    <div class="alert alert-danger">
                        Required. More than 4 letters
                    </div>
                </div>
                }
            </div>
            <div>
                <div class="input-row">
                    <div class="label">Client’s Email:<span class="star">*</span></div>
                    <input type="email" name="email" ngModel required email [formControl]="emailControl"
                        [(ngModel)]="clientEmail" onDrag="return false" onDrop="return false" autocomplete=off
                        [ngClass]="{'input-error': isShowEmailValidationMessage }" (input)="validation()"
                        (blur)="validation()" (keydown)="onEmailKeydown($event)">
                </div>
                @if (isShowEmailValidationMessage) {
                <div class="alert-row">
                    @if (emailControl.errors?.required) {
                    <div class="alert alert-danger">
                        Email is required.
                    </div>
                    }
                    @if (!emailControl.errors?.required && emailControl.errors.cyrillic) {
                    <div class="alert alert-danger">
                        Cyrillic letters are not allowed.
                    </div>
                    }
                    @if (!emailControl.errors?.required && !emailControl.errors.cyrillic && (emailControl.errors.pattern
                    || emailControl.errors)) {
                    <div class="alert alert-danger">
                        Invalid email format.
                    </div>
                    }
                </div>
                }
            </div>
            <div>
                <div class="input-row" [ngClass]="{'input-select-error': isShowProvinceValidationMessage }">
                    <div class="label">Province:<span class="star">*</span></div>
                    <mat-form-field class="input-select province-select-global">
                        <mat-select [(value)]="selectedProvince" [formControl]="provinceControl"
                            (closed)="validation()">
                            @for (province of provincies; track province) {
                            <mat-option [value]="province">
                                {{ province.name }} ({{ PaymentCurrencies[province.paymentCurrency] }})
                            </mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                </div>
                @if (isShowProvinceValidationMessage) {
                <div class="alert-row">
                    <div class="alert alert-danger">
                        Required
                    </div>
                </div>
                }
            </div>

            <div>
                <div class="input-row">
                    <div class="label">Amount@if (selectedProvince) {
                        <span> in {{
                            PaymentCurrencies[selectedProvince.paymentCurrency]
                            }}</span>
                        }:<span class="star">*</span>
                    </div>
                    <input #amountInput [(ngModel)]="amount" type="text" (input)="onAmountInput($event);validation()"
                        (blur)="validation()" onCopy="return false" onDrag="return false" onDrop="return false"
                        onPaste="return false" autocomplete=off [formControl]="amountControl"
                        [ngClass]="{'input-error': isShowAmountValidationMessage }">
                </div>
                @if (isShowAmountValidationMessage) {
                <div class="alert-row">
                    <div class="alert alert-danger">
                        Required. 10000.00 format
                    </div>
                </div>
                }
            </div>

            <div>
                <div class="input-row">
                    <div class="label">Comment:</div>
                    <textarea [formControl]="commentsControl" rows="10" cols="45" name="comment"
                        [(ngModel)]="comment"></textarea>
                </div>
            </div>

            <button class="btn btn-success waves-light ml-0" [disabled]='isDisablePayment'
                (click)="onPayment()">Payment</button>
            <button class="btn btn-danger waves-light" (click)="onClear()">Clear</button>
        </div>
    </div>

    <div class="row" #targetScroll>
        <div class="col">
            @if (isPay && paymentSystemCheck(PaymentSystems.BrainTree)) {
            <div class="payment-container">
                @if (!isPaid) {
                @if (isBrainTreeSpinner) {
                <div class="spinner">
                    <mat-spinner [diameter]="100"></mat-spinner>
                </div>
                }
                <form id="cardForm">
                    <div class="panel">
                        <header class="panel__header">
                            <h1>Card Payment</h1>
                        </header>
                        <div class="panel__content">
                            <div class="textfield--float-label">
                                <label class="hosted-field--label" for="card-number"><span class="icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                            viewBox="0 0 24 24">
                                            <path d="M0 0h24v24H0z" fill="none" />
                                            <path
                                                d="M20 4H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2zm0 14H4v-6h16v6zm0-10H4V6h16v2z" />
                                        </svg></span> Card Number
                                </label>
                                <div id="card-number" class="hosted-field"></div>
                            </div>
                            <div class="textfield--float-label">
                                <label class="hosted-field--label" for="expiration-date">
                                    <span class="icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                            viewBox="0 0 24 24">
                                            <path
                                                d="M9 11H7v2h2v-2zm4 0h-2v2h2v-2zm4 0h-2v2h2v-2zm2-7h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V9h14v11z" />
                                        </svg>
                                    </span>
                                    Expiration Date</label>
                                <div id="expiration-date" class="hosted-field"></div>
                            </div>
                        </div>
                        <footer class="panel__footer">
                            <input type="button" value="Pay" (click)="tokenizeUserDetails();" class="pay-button" />
                        </footer>
                    </div>
                </form>
                }
                @if (error) {
                <div style="color: red;">{{error}}</div>
                }
            </div>
            }

            <!-- <div *ngIf="isPay && selectedPaymentSystem === PaymentSystems.Bambora && bamboraUrl">
                <iframe frameborder="0" width="100%" height="850px" [src]='bamboraUrl ? bamboraUrl : null'>
                </iframe>
            </div> -->

            <div class="payment-container">
                <ng-container *ngTemplateOutlet="transactionMessageTemplate"></ng-container>
            </div>
        </div>
    </div>
</div>

<pd-stripe-modal #stripeModal (paymentSuccess)="onStripePaymentSuccess()"
    (statusUpdated)="onStripeStatusUpdated($event)" (cancelBtn)="isPay = false"></pd-stripe-modal>

<ng-template #transactionMessageTemplate>
    @if (paymentResponse) {
    <div class="payment-responce-container">
        @if (paymentResponse.transactionMessage === 'Success') {
        <div>
            <img src="../../assets/success-transaction.png" class="transaction-icon">
        </div>
        }
        @if (paymentResponse.transactionMessage !== 'Success') {
        <div>
            <img src="../../assets/error-transaction.png" class="transaction-icon">
        </div>
        }
        Transaction message: {{paymentResponse.transactionMessage}}
    </div>
    }
</ng-template>