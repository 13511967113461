import { Deal, DealWithContact, Email, PersonShortInfo, Phone } from "../models/Deal";
import { DealDetails } from "../models/DealDetails";
import { DealDetailsDto } from "../models/dto/DealDetailsDto";
import { DealWithContactDto } from "../models/dto/DealWithContactDto";
import { FilteredDealDto } from "../models/dto/FilteredDealDto";
import { PersonContactInfo } from "../models/dto/PersonContactInfo";
import { PersonShortInfoDto } from "../models/dto/PersonShortInfoDto";

export class Mapper {
    public static transformDealDetailsToClient(response: DealDetailsDto) {
        const viewModel = new DealDetails();

        viewModel.id = response.dealId;
        viewModel.name = response.title;
        viewModel.firstName = response.firstName;
        viewModel.lastName = response.lastName;
        viewModel.birthday = response.birthday;
        viewModel.company = response.company;
        viewModel.companyAddress = response.companyAddress;
        viewModel.homeAddress = response.homeAddress;
        viewModel.companyId = response.companyId;
        viewModel.position = response.position;
        viewModel.positionId = response.positionId;
        viewModel.phone = response.phones.map(x => new Phone(x));
        viewModel.email = response.emails.map(x => new Email(x));
        viewModel.linkedin_URL = response.linkedInURL;
        viewModel.stage_id = response.stageId;
        viewModel.previous_labeled_stage_id = response.previousLabeledStageId;
        viewModel.personId = response.personId;
        viewModel.notes = response.notes;
        viewModel.personWhoReferred = response.personWhoReferred;
        viewModel.personWhoReferredId = response.personWhoReferredId;
        viewModel.pipeline_id = response.pipelineId;
        viewModel.user_id = response.ownerId;
        viewModel.location = response.location;
        viewModel.locationId = response.locationId;
        viewModel.genderId = response.genderId;
        viewModel.isNotUseAsCommonConnection = response.isNotUseAsCommonConnection;
        viewModel.isDontSendPromotionalEmails = response.isDontSendPromotionalEmails;
        viewModel.isDontSendPartnerEmails = response.isDontSendPartnerEmails;
        viewModel.isDontSendWhatsapp = response.isDontSendWhatsapp;
        viewModel.isDontSendSms = response.isDontSendSms;
        viewModel.isPartnerRepliedToBDayEmail = response.isPartnerRepliedToBDayEmail;
        viewModel.clientId = response.clientId;
        viewModel.clientGroupId = response.clientGroupId;
        viewModel.called = response.called;
        viewModel.dateOfCall = response.dateOfCall;
        viewModel.isOpenOrderNotified = response.isOpenOrderNotified;
        viewModel.isDeliveryEmailSent = response.isDeliveryEmailSent;
        viewModel.isIncreasedRecentlyContactedRotting = response.isIncreasedRecentlyContactedRotting;
        viewModel.isForClothier = response.isForClothier;
        viewModel.isOrderShipped = response.isOrderShipped;
        viewModel.bookedById = response.bookedById;
        viewModel.bookedByName = response.bookedByName;
        viewModel.trackingLink = response.trackingLink;
        viewModel.preferToContactByEmail = response.preferToContactByEmail;
        viewModel.clientRating = response.clientRating;
        viewModel.timeZone = response.timeZone;
        viewModel.increasingRottenTimeCounter = response.increasingRottenTimeCounter;
        viewModel.maxIncreasingRottenTimeCounter = response.maxIncreasingRottenTimeCounter;
        viewModel.reselectRottenTimeCounter = response.reselectRottenTimeCounter;
        viewModel.isRotting = response.isRotting;
        viewModel.meetingDateUtc = response.meetingDateUtc;
        viewModel.latitude = response.latitude;
        viewModel.longitude = response.longitude;
        viewModel.files_count = response.filesCount;
        viewModel.files_count_pdf = response.filesCountPdf;
        viewModel.wardrobe_image_count = response.wardrobeImageCount;
        viewModel.client_lifetime_spend = response.clientLifetimeSpend;
        viewModel.last_export_deal_date = response.lastExportDealDate;

        viewModel.partnerFirstName = response.partnerFirstName;
        viewModel.partnerLastName = response.partnerLastName;
        viewModel.partnerEmail = response.partnerEmail;
        viewModel.partnerPhone = response.partnerPhone;
        viewModel.partnerToken = response.partnerToken;

        return viewModel;
    }

    public static transformDealDetailsToServer(request: DealDetails) {
        const modelDto: DealDetailsDto = new DealDetailsDto();

        modelDto.dealId = request.id;
        modelDto.title = request.name;
        modelDto.company = request.company;
        modelDto.companyAddress = request.companyAddress;
        modelDto.homeAddress = request.homeAddress;
        modelDto.position = request.position;
        modelDto.positionId = request.positionId;
        modelDto.emails = request.email.map(x => new PersonContactInfo(x));
        modelDto.phones = request.phone.map(x => new PersonContactInfo(x));
        modelDto.linkedInURL = request.linkedin_URL;
        modelDto.stageId = request.stage_id;
        modelDto.personId = request.personId;
        modelDto.notes = request.notes;
        modelDto.personWhoReferred = request.personWhoReferred;
        modelDto.personWhoReferredId = request.personWhoReferredId;
        modelDto.ownerId = request.user_id;
        modelDto.pipelineId = request.pipeline_id;
        modelDto.location = request.location;
        modelDto.locationId = request.locationId;
        modelDto.genderId = request.genderId;
        modelDto.clientGroupId = request.clientGroupId;
        modelDto.isNotUseAsCommonConnection = request.isNotUseAsCommonConnection,
        modelDto.isDontSendPromotionalEmails = request.isDontSendPromotionalEmails,
        modelDto.isDontSendPartnerEmails = request.isDontSendPartnerEmails,
        modelDto.isDontSendWhatsapp = request.isDontSendWhatsapp,
        modelDto.isDontSendSms = request.isDontSendSms,
        modelDto.isDontSendWhatsapp = request.isDontSendWhatsapp,
        modelDto.isDontSendSms = request.isDontSendSms,
        modelDto.isPartnerRepliedToBDayEmail = request.isPartnerRepliedToBDayEmail;
        modelDto.called = request.called;
        modelDto.dateOfCall = request.dateOfCall;
        modelDto.isOpenOrderNotified = request.isOpenOrderNotified;
        modelDto.isDeliveryEmailSent = request.isDeliveryEmailSent;
        modelDto.preferToContactByEmail = request.preferToContactByEmail;
        modelDto.clientRating = request.clientRating;
        modelDto.timeZone = request.timeZone;
        modelDto.increasingRottenTimeCounter = request.increasingRottenTimeCounter;
        modelDto.maxIncreasingRottenTimeCounter = request.maxIncreasingRottenTimeCounter;
        modelDto.reselectRottenTimeCounter = request.reselectRottenTimeCounter;
        modelDto.latitude = request.latitude;
        modelDto.longitude = request.longitude;

        return modelDto;
    }

    public static transformFilteredDealToClient(response: FilteredDealDto) {
        const viewModel = new Deal();

        viewModel.id = response.dealId;
        viewModel.is_rotting = response.isRotting;
        viewModel.title = response.title;
        viewModel.company = response.company;
        viewModel.companyId = response.companyId;
        viewModel.companyAddress = response.companyAddress;
        viewModel.homeAddress = response.homeAddress;
        viewModel.position = response.position;
        viewModel.positionId = response.positionId;
        viewModel.phone = response.phones?.map(x => new Phone(x));
        viewModel.email = response.emails?.map(x => new Email(x));
        viewModel.linkedin_URL = response.linkedInURL;
        viewModel.stage_id = response.stageId;
        viewModel.previous_labeled_stage_id = response.previousLabeledStageId;
        viewModel.files_count = response.filesCount;
        viewModel.files_count_pdf = response.filesCountPdf;
        viewModel.wardrobe_image_count = response.wardrobeImageCount;
        viewModel.is_recently_requested_hotlist = response.isRecentlyRequestedHotlist;
        viewModel.last_request_hotlist_date = response.lastRequestHotlistDate;
        viewModel.last_seasonal_check_email_sent_date = response.lastSeasonalCheckEmailSentDate;
        viewModel.participants_count = response.participantsCount;
        viewModel.same_building_count = response.sameBuildingCount;
        viewModel.personId = response.personId;
        viewModel.notes = response.notes;
        viewModel.personWhoReferred = response.personWhoReferred;
        viewModel.personWhoReferredId = response.personWhoReferredId;
        viewModel.stage_order_nr = response.stageOrderNumber;
        viewModel.user_id = response.ownerId;
        viewModel.pipeline_id = response.pipelineId;
        viewModel.person_id = new PersonShortInfo(response.person);
        viewModel.stage_name = response.stageName;
        viewModel.location = response.location;
        viewModel.locationId = response.locationId;
        viewModel.user_name = response.userName;
        viewModel.genderId = response.genderId;
        viewModel.called = response.called;
        viewModel.dateOfCall = response.dateOfCall;
        viewModel.isOpenOrderNotified = response.isOpenOrderNotified;
        viewModel.isDeliveryEmailSent = response.isDeliveryEmailSent;
        viewModel.birthday = response.birthday;
        viewModel.meeting_date_utc = response.meetingDateUtc;
        viewModel.latitude = response.latitude;
        viewModel.longitude = response.longitude;

        viewModel.isIncreasedRecentlyContactedRotting = response.isIncreasedRecentlyContactedRotting;
        viewModel.isForClothier = response.isForClothier;
        viewModel.isOrderShipped = response.isOrderShipped;
        viewModel.bookedById = response.bookedById;
        viewModel.trackingLink = response.trackingLink;
        viewModel.preferToContactByEmail = response.preferToContactByEmail;
        viewModel.clientRating = response.clientRating;
        viewModel.increasingRottenTimeCounter = response.increasingRottenTimeCounter;
        viewModel.maxIncreasingRottenTimeCounter = response.maxIncreasingRottenTimeCounter;
        viewModel.reselectRottenTimeCounter = response.reselectRottenTimeCounter;
        viewModel.noteList = response.noteList;
        viewModel.participantList = response.participantList;
        viewModel.sameBuildingList = response.sameBuildingList;
        viewModel.viewOrder = response.viewOrder;
        viewModel.rottenTime = response.rottenTime;
        viewModel.modified = response.modified;
        viewModel.client_lifetime_spend = response.clientLifetimeSpend;
        viewModel.last_export_deal_date = response.lastExportDealDate;
        viewModel.isNotesExist = response.isNotesExist;
        return viewModel;
    }

    public static transformFilteredDealToServer(request: Deal) {
        const modelDto: FilteredDealDto = new FilteredDealDto();

        modelDto.dealId = request.id;
        modelDto.isRotting = request.is_rotting;
        modelDto.title = request.title;
        modelDto.company = request.company;
        modelDto.companyId = request.companyId;
        modelDto.position = request.position;
        modelDto.positionId = request.positionId;
        modelDto.emails = request.email.map(x => new PersonContactInfo(x));
        modelDto.phones = request.phone.map(x => new PersonContactInfo(x));
        modelDto.linkedInURL = request.linkedin_URL;
        modelDto.stageId = request.stage_id;
        modelDto.filesCount = request.files_count;
        modelDto.filesCountPdf = request.files_count_pdf;
        modelDto.wardrobeImageCount = request.wardrobe_image_count;
        modelDto.isRecentlyRequestedHotlist = request.is_recently_requested_hotlist;
        modelDto.lastRequestHotlistDate = request.last_request_hotlist_date;
        modelDto.lastSeasonalCheckEmailSentDate = request.last_seasonal_check_email_sent_date;
        modelDto.participantsCount = request.participants_count;
        modelDto.sameBuildingCount = request.same_building_count;
        modelDto.personId = request.personId;
        modelDto.notes = request.notes;
        modelDto.personWhoReferred = request.personWhoReferred;
        modelDto.personWhoReferredId = request.personWhoReferredId;
        modelDto.stageOrderNumber = request.stage_order_nr;
        modelDto.ownerId = request.user_id;
        modelDto.pipelineId = request.pipeline_id;
        modelDto.person = new PersonShortInfoDto(request.person_id);
        modelDto.stageName = request.stage_name;
        modelDto.location = request.location;
        modelDto.locationId = request.locationId;
        modelDto.userName = request.user_name;
        modelDto.genderId = request.genderId;
        modelDto.called = request.called;
        modelDto.dateOfCall = request.dateOfCall;
        modelDto.isOpenOrderNotified = request.isOpenOrderNotified;
        modelDto.isDeliveryEmailSent = request.isDeliveryEmailSent;
        modelDto.isIncreasedRecentlyContactedRotting = request.isIncreasedRecentlyContactedRotting;
        modelDto.isForClothier = request.isForClothier;
        modelDto.isOrderShipped = request.isOrderShipped;
        modelDto.bookedById = request.bookedById;
        modelDto.trackingLink = request.trackingLink;
        modelDto.preferToContactByEmail = request.preferToContactByEmail;
        modelDto.clientRating = request.clientRating;
        modelDto.increasingRottenTimeCounter = request.increasingRottenTimeCounter;
        modelDto.reselectRottenTimeCounter = request.reselectRottenTimeCounter;
        modelDto.viewOrder = request.viewOrder;
        modelDto.rottenTime = request.rottenTime;
        modelDto.modified = request.modified;

        return modelDto;
    }

    public static transformDealWithContactToClient(response: DealWithContactDto) {
        const viewModel: DealWithContact = new DealWithContact();

        viewModel.id = response.dealId;
        viewModel.title = response.title;
        viewModel.label = response.label;
        viewModel.value = response.value;

        return viewModel;
    }
}
