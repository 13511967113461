import { Component, QueryList, ViewChildren } from '@angular/core';
import { Avatar } from '../core/models/avatar-models/Avatar';
import { AvatarBuilderService } from '../core/services/avatar/avatar-builder.service';
import { Modeler3dProductService } from '../core/services/3d-modeler-product.service';
import { saveAs } from 'file-saver';
import { FabricImage } from '../core/models/ClientCardConfig';
import { AvatarFacadeService } from '../core/services/avatar/avatar-facade.service';
import { take, takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../core/base.component';
import { Avatar2Collection } from '../core/models/avatar-models/Avatar2Collection';
import { AvatarCollection } from '../core/models/avatar-models/AvatarCollection';
import { combineLatest } from 'rxjs';
import { AvatarElementTypes } from '../core/enums/avatar/AvatarElementTypes';
import { UploadImageService } from '../core/services/upload-image.service';
import { AvatarBuilderData } from '../core/models/avatar-models/AvatarBuilderData';
import { AvatarSeasonalProposalExpansionPanelComponent } from './avatar-seasonal-proposal-expansion-panel/avatar-seasonal-proposal-expansion-panel.component';
import { UtilsHelper } from '../core/utils/utils.helper';
import { ImageUtil } from '../core/utils/image.util';
import { MatDialog } from '@angular/material/dialog';
import { AvatarBuilderComponent } from '../shared/avatar-builder/avatar-builder.component';

@Component({
  selector: 'pd-avatar-seasonal-proposal',
  templateUrl: './avatar-seasonal-proposal.component.html',
  styleUrls: ['./avatar-seasonal-proposal.component.scss'],
})

export class AvatarSeasonalProposalComponent extends BaseComponent {
  @ViewChildren('expansionPanels') expansionPanels: QueryList<AvatarSeasonalProposalExpansionPanelComponent>;

  public avatar2Collections: Avatar2Collection[];
  public avatarCollections: AvatarCollection[];
  public avatars: Avatar[];

  public fabricImages: FabricImage[];

  public existingAvatarCollection: AvatarCollection;
  public existingAvatarId: number = -1;
  public existingAvatarName: string = 'All avatars';

  public storedCollectionsExpanding: any[] = [];

  public isSpinner: boolean = false;
  public isLoadCompleted: boolean = false;
  public isAvatarRenderCompleted: boolean = false;

  constructor(
    private avatarBuilderService: AvatarBuilderService
    , private modeler3dProductService: Modeler3dProductService
    , private avatarFacadeService: AvatarFacadeService
    , private uploadImageService: UploadImageService
    , private dialog: MatDialog
  ) {
    super();

    this.isSpinner = true;

    this.existingAvatarCollection = new AvatarCollection(
      {
        id: this.existingAvatarId,
        collectionName: this.existingAvatarName,
        isGroupedView: true
      }
    );

    combineLatest(
      this.avatarFacadeService.loadAvatar2Collections()
      , this.avatarFacadeService.loadAvatarCollections()
      , this.avatarFacadeService.loadAllNonDealIdAvatars()
    ).pipe(take(1)).subscribe((data) => {
      this.isLoadCompleted = true
    })

    this.avatarFacadeService.getAllAvatar2Collections()
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (avatar2Collections: Avatar2Collection[]) => {

        if (!this.avatar2Collections) {
          this.avatar2Collections = new Array<Avatar2Collection>();
        }

        let prevAvatar2Collections = this.avatar2Collections;

        console.log("avatar2Collections this.avatar2Collections", this.avatar2Collections);
        console.log("avatar2Collections avatar2Collections", avatar2Collections);

        let toAdd = avatar2Collections.filter(f => !prevAvatar2Collections.some(s => s.id == f.id));
        let toRemove = prevAvatar2Collections.filter(f => !avatar2Collections.some(s => s.id == f.id));
        let toUpdate = avatar2Collections.filter(f => prevAvatar2Collections.some(s => s.id == f.id && !this.areEqual(s, f, null)));

        console.log("avatar2Collections toAdd", toAdd);
        console.log("avatar2Collections toRemove", toRemove);
        console.log("avatar2Collections toUpdate", toUpdate);

        for (let i = 0; i < toUpdate.length; i++) {
          this.avatar2Collections = this.avatar2Collections.filter(f => f.id != toUpdate[i].id).concat(toUpdate[i]);
        }

        this.avatar2Collections = this.avatar2Collections.concat(toAdd);
        this.avatar2Collections = this.avatar2Collections.filter(f => !toRemove.some(s => s.id == f.id));
      });

    this.avatarFacadeService.getAllAvatarCollections()
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (avatarCollections: AvatarCollection[]) => {

        if (!this.avatarCollections) {
          this.avatarCollections = new Array<AvatarCollection>();
        }

        let prevAvatarCollections = this.avatarCollections;

        let toAdd = avatarCollections.filter(f => !prevAvatarCollections.some(s => s.id == f.id));
        let toRemove = prevAvatarCollections.filter(f => !avatarCollections.some(s => s.id == f.id));
        let toUpdate = avatarCollections.filter(f => prevAvatarCollections.some(s => s.id == f.id && !this.areEqual(s, f, null)));

        console.log("collections toAdd", toAdd);
        console.log("collections toRemove", toRemove);
        console.log("collections toUpdate", toUpdate);

        for (let i = 0; i < toUpdate.length; i++) {
          this.avatarCollections = this.avatarCollections.filter(f => f.id != toUpdate[i].id).concat(toUpdate[i]);
        }

        this.avatarCollections = this.avatarCollections.concat(toAdd);
        this.avatarCollections = this.avatarCollections.filter(f => !toRemove.some(s => s.id == f.id));
      });

    this.avatarFacadeService.getAllNonDealIdAvatars()
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (avatars: Avatar[]) => {

        if (!this.avatars) {
          this.avatars = new Array<Avatar>();
        }

        let prevAvatars = this.avatars;

        let toAdd = avatars.filter(f => !prevAvatars.some(s => s.id == f.id));
        let toRemove = prevAvatars.filter(f => !avatars.some(s => s.id == f.id));
        let toUpdate = avatars.filter(f => prevAvatars.some(s => s.id == f.id && !this.areEqual(s, f, ["svg"])));

        console.log("avatars toAdd", toAdd);
        console.log("avatars toRemove", toRemove);
        console.log("avatars toUpdate", toUpdate);

        this.avatars = this.avatars.filter(f => !toUpdate.some(s => s.id == f.id)).concat(toUpdate);
        this.avatars = this.avatars.concat(toAdd);
        this.avatars = this.avatars.filter(f => !toRemove.some(s => s.id == f.id));

        this.isAvatarRenderCompleted = true;
      });

    this.isSpinner = false;
  }

  async ngOnInit() {
    this.isSpinner = true;
    this.fabricImages = await this.modeler3dProductService.getFabricImages();
    this.isSpinner = false;
  }

  public async save(event) {
    this.isSpinner = true;

    const avatarBuilderData = event.avatarBuilderData as AvatarBuilderData;
    const collections = event.collections as AvatarCollection[];
    const isDuplicate = event.originalAvatarId && event.originalAvatarCollectionId;

    if (event.isAvatarChanged) {
      const head = avatarBuilderData.options.find(f => f.elementTypeId == AvatarElementTypes.Head);

      if (head && ImageUtil.isBase64(head.imageLink)) {
        head.imageLink = await this.uploadImageService.uploadAvatarHeadImage(null, head.imageLink);
      }
    }

    if (isDuplicate) {
      if (event.isAvatarChanged) {
        await this.avatarFacadeService.removeAvatarFromCollection(event.originalAvatarId, event.originalAvatarCollectionId);

        const avatarId = await this.avatarFacadeService.createAvatarDuplicate(event.originalAvatarId);
        await this.avatarFacadeService.updateAvatar(avatarId, avatarBuilderData);

        collections.forEach(async collection => {
          await this.avatarFacadeService.addAvatarToCollection(avatarId, collection.id);
        });
      }
      else if (event.isCollectionsChanged) {
        let pervCollections = this.getCollectionsByAvatarId(event.originalAvatarId);

        let toAdd = collections.filter(f => !pervCollections.some(s => s.id == f.id));
        let toRemove = pervCollections.filter(f => !collections.some(s => s.id == f.id));

        toAdd.forEach(async collection => {
          await this.avatarFacadeService.addAvatarToCollection(event.originalAvatarId, collection.id);
        });

        toRemove.forEach(async collection => {
          await this.avatarFacadeService.removeAvatarFromCollection(event.originalAvatarId, collection.id);
        });
      }
    }
    else if (event.avatarId) {
      if (event.isAvatarChanged) {
        await this.avatarFacadeService.updateAvatar(event.avatarId, avatarBuilderData);
      }

      if (event.isCollectionsChanged) {
        let pervCollections = this.getCollectionsByAvatarId(event.avatarId);

        let toAdd = collections.filter(f => !pervCollections.some(s => s.id == f.id));
        let toRemove = pervCollections.filter(f => !collections.some(s => s.id == f.id));

        toAdd.forEach(async collection => {
          await this.avatarFacadeService.addAvatarToCollection(event.avatarId, collection.id);
        });

        toRemove.forEach(async collection => {
          await this.avatarFacadeService.removeAvatarFromCollection(event.avatarId, collection.id);
        });
      }
    }
    else {
      const avatarId = await this.avatarFacadeService.createAvatar(avatarBuilderData);

      collections.forEach(async collection => {
        await this.avatarFacadeService.addAvatarToCollection(avatarId, collection.id);
      });
    }

    this.isSpinner = false;
  }

  public async getAvatarSvg(avatar: Avatar): Promise<string> {
    return await this.avatarBuilderService.getAvatarSvg(avatar.id);
  }

  private async getAvatarBuilderData(avatarId: number) {
    const avatarBuilderData = (avatarId) ? await this.avatarBuilderService.getAvatarBuilderData(avatarId) : await this.avatarBuilderService.getAvatarBuilderDataDef();
    return avatarBuilderData
  }

  //////////////////////////////// Events START //////////////////////////////

  async addNewAvatarCollection() {
    this.isSpinner = true;
    await this.avatarFacadeService.addAvatarCollection('New collection');
    this.isSpinner = false;
  }

  async onSetSeasonalProposalCollection(event) {
    this.isSpinner = true;
    await this.avatarFacadeService.setSeasonalProposalCollection(event.avatarCollection.id, event.isChecked);
    this.isSpinner = false;
  }

  async onSetStylesAvailableCollection(event) {
    this.isSpinner = true;
    await this.avatarFacadeService.setStylesAvailableCollection(event.avatarCollection.id, event.isChecked);
    this.isSpinner = false;
  }

  async onSetGroupedViewChange(event) {
    this.isSpinner = true;
    await this.avatarFacadeService.setGroupedView(event.avatarCollection.id, event.isChecked);
    this.isSpinner = false;
  }

  public async addAvatar(event) {
    this.isSpinner = true;

    const avatarBuilderData = await this.getAvatarBuilderData(null);
    const collections = (event.avatarCollectionId) ? this.avatarCollections.filter(f => f.id == event.avatarCollectionId) : null;

    this.dialog.open(AvatarBuilderComponent, {
      disableClose: true,
      maxWidth: "90vw",
      minWidth: "1050px",
      data: {
        avatarCollections: this.avatarCollections,
        avatarsToCollections: this.avatar2Collections,
        fabricImages: this.fabricImages,
        avatarBuilderData: avatarBuilderData,
        collections: collections,
        onSave: this.save.bind(this),
      }
    });

    this.isSpinner = false;
  }

  public async editAvatar(event) {
    this.isSpinner = true;

    const avatar = event.avatar as Avatar;
    const avatarId = avatar.id;
    const avatarCollectionId = event.avatarCollectionId;
    const avatarBuilderData = await this.getAvatarBuilderData(avatarId);
    const isDuplicate = avatarCollectionId != this.existingAvatarCollection.id;
    const currentCollections = this.getCollectionsByAvatarId(avatarId);

    if (currentCollections && currentCollections.length > 1 && isDuplicate) {
      const collections = (event.avatarCollectionId) ? this.avatarCollections.filter(f => f.id == avatarCollectionId) : null;
      this.dialog.open(AvatarBuilderComponent, {
        disableClose: true,
        maxWidth: "90vw",
        minWidth: "1050px",
        data: {
          avatarCollections: this.avatarCollections,
          avatarsToCollections: this.avatar2Collections,
          fabricImages: this.fabricImages,
          avatarBuilderData: avatarBuilderData,
          collections: collections,
          avatarId: avatarId,
          avatarCollectionId: avatarCollectionId,
          onSave: this.save.bind(this),
        }
      });

    }
    else {
      const collections = this.getCollectionsByAvatarId(avatarId);
      this.dialog.open(AvatarBuilderComponent, {
        disableClose: true,
        maxWidth: "90vw",
        minWidth: "1050px",
        data: {
          avatarCollections: this.avatarCollections,
          avatarsToCollections: this.avatar2Collections,
          fabricImages: this.fabricImages,
          avatarBuilderData: avatarBuilderData,
          collections: collections,
          avatarId: avatarId,
          onSave: this.save.bind(this),
        }
      });
    }

    this.isSpinner = false;
  }

  public async deleteAvatar(event) {
    this.isSpinner = true;
    await this.avatarFacadeService.deleteAvatar(event.avatarId);
    this.isSpinner = false
  }

  public async removeAvatarFromCollection(event) {
    this.isSpinner = true;
    await this.avatarFacadeService.removeAvatarFromCollection(event.avatarId, event.avatarCollectionId);
    this.isSpinner = false
  }

  public async deleteCollection(event) {
    this.isSpinner = true;
    if (!event.keepAvatars) {
      this.avatars.forEach(async avatar => {
        const collections = this.getCollectionsByAvatarId(avatar.id);
        if (collections.length == 1 && collections[0].id == event.avatarCollectionId) {
          await this.avatarFacadeService.deleteAvatar(avatar.id);
        }
      });
    }

    await this.avatarFacadeService.deleteAvatarCollection(event.avatarCollectionId);
    this.isSpinner = false
  }

  public async downloadAvatar(event) {
    this.isSpinner = true;
    const base64 = await this.avatarFacadeService.downloadAvatar(event.avatarId);
    const byteArray = UtilsHelper.base64ToArrayBuffer(base64);
    const blob: any = new Blob([byteArray], { type: 'image/png' });
    saveAs(blob, 'Avatar.png', true);
    this.isSpinner = false
  }

  public async downloadAvatarCollection(event) {
    this.isSpinner = true;
    const result = (event.id && event.id > 0) ?
      await this.avatarFacadeService.downloadCollection(event.id)
      : await this.avatarFacadeService.downloadAllNonDealId();
    saveAs(result, event.collectionName + ".zip");
    this.isSpinner = false;
  }

  public loadAllAvatars(event) {
    if (event && event.isExistingAvatarCollection) {
      this.expansionPanels.forEach(panel => {
        panel.isLoadAll = true;
      });
    }
  }

  //////////////////////////////// Events END //////////////////////////////

  //////////////////////////////// Private START //////////////////////////////

  private areEqual(obj1, obj2, excludedProperties) {
    const keys = Object.keys(obj1);

    for (let key of keys) {
      if (!excludedProperties || !excludedProperties.includes(key)) {
        if (JSON.stringify(obj1[key]) !== JSON.stringify(obj2[key])) {
          return false;
        }
      }
    }

    return true;
  }

  //////////////////////////////// Private END //////////////////////////////

  //////////////////////////////// Helpers START ////////////////////////////////

  public getAvatarsByCollectionId(avatarCollectionId: number): Array<Avatar> {
    if (this.avatars && this.avatars.length > 0 && this.avatar2Collections && this.avatar2Collections.length > 0) {
      const relationships = this.avatar2Collections.filter(c => c.avatarCollectionId === avatarCollectionId);
      const avatars = this.avatars.filter(a => relationships.some(r => r.avatarId === a.id));
      return avatars;
    }
    return new Array<Avatar>();
  }

  public getCollectionsByAvatarId(avatarId: number): Array<AvatarCollection> {
    if (this.avatarCollections && this.avatarCollections.length > 0 && this.avatar2Collections && this.avatar2Collections.length > 0) {
      const relationships = this.avatar2Collections.filter(c => c.avatarId === avatarId);
      const avatarCollections = this.avatarCollections.filter(a => relationships.some(r => r.avatarCollectionId === a.id));
      return avatarCollections;
    }
    return new Array<AvatarCollection>();
  }

  //////////////////////////////// Helpers END ////////////////////////////////

  //////////////////////////////// View *ngIf START //////////////////////////////

  //////////////////////////////// View *ngIf END //////////////////////////////
}