import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'money'
})

export class MoneyPipe implements PipeTransform {

  transform(input: any) {
    if (input) {
      var round = (Math.round((input + Number.EPSILON) * 100) / 100);
      var formatted = round
        .toLocaleString("en", { useGrouping: false, minimumFractionDigits: 2 }).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return formatted;
    }
    return input;
  }
}