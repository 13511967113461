import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Pipeline } from "../../models/Pipeline";

@Injectable()
export class PipelineStoreService {
    private static currentPipeline: Pipeline;
    public previousPipeline: Pipeline;

    public readonly currentPipeline = new BehaviorSubject(PipelineStoreService.currentPipeline);

    public setCurrentPipeline(value: Pipeline) {
        console.log('PipelineStoreService setCurrentPipeline', value);
        this.previousPipeline = this.currentPipeline.value;
        this.currentPipeline.next(value);
    }

    public removeCurrentPipeline() {
        console.log('PipelineStoreService removeCurrentPipeline');
        this.currentPipeline.next(null);
    }

    public reset() {
        console.log('PipelineStoreService reset');
        PipelineStoreService.currentPipeline = null;
    }
}
