import { Component, OnInit, Input, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { takeUntil } from 'rxjs';
import { BaseComponent } from 'src/app/core/base.component';
import { AchievementGroups } from 'src/app/core/enums/AchievementGroups';
import { AchievementCardType, AchievementGroup } from 'src/app/core/models/AchievementCardConfig';
import { AchievementCardModel } from 'src/app/core/models/AchievementCardModel';
import { AchievementCardFacadeService } from 'src/app/core/services/achievement-card/achievement-card-facade.service';

@Component({
  selector: 'pd-achievement-expansion-panel',
  templateUrl: './achievement-expansion-panel.component.html',
  styleUrls: ['./achievement-expansion-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class AchievementExpansionPanelComponent extends BaseComponent implements OnInit {
  @Input() ownerId: number;
  @Input() achievementGroup: AchievementGroup;
  @Input() achievementCardTypes: Array<AchievementCardType>;
  @Input() isAchievementHistory: boolean;
  @Input() isAchievementGroup: boolean;
  @Input() achievementCards: Array<AchievementCardModel>;

  bestMonth: AchievementCardModel;

  constructor(
    private cdRef: ChangeDetectorRef
    , private achievementCardFacadeService: AchievementCardFacadeService) {
    super();
  }

  ngOnInit() {
    this.achievementCardFacadeService.getAchievementCards()
      .pipe(takeUntil(this.destroy$))
      .subscribe((achievementCards: AchievementCardModel[]) => {
        if (this.isAchievementGroup) {
          this.achievementCards = achievementCards;
        }
        else {
          this.achievementCards = achievementCards.filter(a => a.achievementGroupId == this.achievementGroup.id);

          if (this.achievementGroup.id == AchievementGroups.MonthlySalesAchievementPins) {
            this.bestMonth = this.achievementCards.find(a => a.isBestMonth);
          }
        }

        this.cdRef.detectChanges();
      });
  }

  ngOnDestroy() {
    this.cdRef.detach();
    super.ngOnDestroy();
  }

  public get isSplitByTypes(): boolean {
    return this.achievementGroup.id == AchievementGroups.MonthlySalesAchievementPins;
  }

  public getCardCount() {
    if (this.achievementCards) {
      return this.achievementCards.length;
    }
    return 0;
  }

  public getAchievementCardTypes() {
    this.sortAchievementCardTypes();
    return this.achievementCardTypes;
  }

  public findImgUrl(card: AchievementCardModel) {
    if (card.customImageUrl) {
      return card.customImageUrl;
    }
    else {
      let achievementCardType = this.achievementCardTypes.find(a => a.id == card.achievementCardTypeId);
      if (achievementCardType) {
        return achievementCardType.imageUrl;
      }
    }
  }

  private sortAchievementCardTypes() {
    if (this.achievementCardTypes) {
      this.achievementCardTypes = this.achievementCardTypes.sort((a, b) => this.compare(a.order, b.order));
    }
  }

  private compare(first: number, next: number) {
    if (next > first) {
      return -1;
    }
    if (next < first) {
      return 1;
    }
    return 0;
  }
}