import { AfterViewInit, Directive, ElementRef, Input, OnDestroy, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[bootstrapTooltip]'
})
export class TooltipDirective implements AfterViewInit, OnDestroy {
  private tooltip: any;
  @Input('bootstrapTooltip') private text: number;
  @Input() tooltipPlacement = 'bottom';
  @Input() tooltipDisabled = false;

  constructor(private elementRef: ElementRef) { }

  public ngOnChanges(changes: SimpleChanges) {
    const domElement: HTMLElement = this.elementRef.nativeElement;
    if (changes.text) {
      domElement.setAttribute('title', this.text.toString());
      this.tooltip = new bootstrap.Tooltip(domElement);
    }
  }

  ngAfterViewInit() {
    if (this.tooltipDisabled) {
      return;
    }
    
    const domElement: HTMLElement = this.elementRef.nativeElement;
    domElement.setAttribute('data-bs-toggle', 'tooltip');
    domElement.setAttribute('data-bs-placement', this.tooltipPlacement.toString());
    domElement.setAttribute('title', this.text.toString());
    this.tooltip = new bootstrap.Tooltip(domElement);
  }

  ngOnDestroy() {
    if (this.tooltip) {
      this.tooltip.dispose();
    }
  }
}