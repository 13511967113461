import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { AchievementCardContainerComponent } from './achievement-card-container/achievement-card-container.component';
import { AchievementExpansionPanelComponent } from './achievement-expansion-panel/achievement-expansion-panel.component';
import { AchievementsComponent } from './achievements.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule
    ],
    declarations: [
        AchievementsComponent,
        AchievementExpansionPanelComponent,
        AchievementCardContainerComponent
    ],
    exports: [
        AchievementsComponent,
        AchievementExpansionPanelComponent,
        AchievementCardContainerComponent
    ]
})
export class AchievementsCardModule { }
