export enum MultiSelectDataType {
	Stage = 1,
	User = 2,
	TargetRecallUser = 3,
	TaskStage,
	Location,
	LocationEdit,
	Company,
	CompanyEdit,
	PositionFilter,
	PositionSelect,
	UserSelect,
	UserSelectEdit,
}
