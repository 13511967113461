@if (contacts && contacts.length > 0) {
<div class="text-small">
    @for (contact of contacts; track contact) {
    <div>
        @if (contact.value) {

        <small>{{contact.value}} ({{contact.label | pdCapitalize}})</small>
        <img [src]="getIconSrc(contact)" [text-copy]="contact.value" [matTooltip]="getTooltipLabel(contact)"
            placement="bottom" (click)="$event.stopPropagation()">

        }
    </div>
    }
</div>
}