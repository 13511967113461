import { PipelineDealsStoreService } from './../../../core/services/stores/pipeline-deal-store.service';
import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';
import { FieldIntlTelComponent } from '../../../deals/fields/field-intl-tel/field-intl-tel.component';
import { Deal } from 'src/app/core/models/Deal';
import { UtilsHelper } from 'src/app/core/utils/utils.helper';
import { DealService } from 'src/app/core/services/deals.service';
import { FieldEmailsComponent } from 'src/app/deals/fields/field-emails/field-emails.component';
import { PersonPropertiesToUpdate } from 'src/app/core/enums/PersonPropertiesToUpdate';


@Component({
  selector: 'pd-custom-contacts-modal',
  templateUrl: './custom-contacts-modal.component.html',
  styleUrls: ['./custom-contacts-modal.component.scss'],
  animations: [
    trigger('modalState', [
      state('in', style({
        transform: 'translate(0, -10px)'
      })),
      state('out', style({
        transform: 'translate(0, -120vh)'
      })),
      transition('out => in', animate('400ms ease-in-out')),
      transition('in => out', animate('400ms ease-in-out'))
    ])
  ]
})
export class CustomContactsModalComponent {
  @ViewChild('fieldIntlTel') fieldIntlTel: FieldIntlTelComponent;
  @ViewChild('fieldEmails') fieldEmails: FieldEmailsComponent;
  @Input() moveContactsModal: string;
  @Output() closeModal = new EventEmitter<object>();

  public isModalOpen = false;
  public localPhones = [];
  public localEmails = [];
  public deal: Deal;

  constructor(private dealService: DealService, private pipelineDealsStoreService: PipelineDealsStoreService) { }

  private checkLabels(data) {
    if (data.length && data[0].label) {
      for (let i = 0; i < data.length; i++) {
        data[i].label = data[i].label.charAt(0).toUpperCase() + data[i].label.slice(1).toLowerCase()
      }
    }
  }

  public openModal(deal: Deal) {
    this.deal = deal;
    this.localPhones = UtilsHelper.deepClone(deal.phone)
    this.localEmails = UtilsHelper.deepClone(deal.email)

    this.fieldIntlTel.phones = this.localPhones;
    this.fieldEmails.emails = this.localEmails;

    if(!this.localPhones || this.localPhones.length === 0) {
      this.fieldIntlTel.addOneMore();
      this.fieldIntlTel.isEdit = true;
    }

    if(!this.localEmails || this.localEmails.length === 0) {
      this.fieldEmails.addOneMore();
      this.fieldEmails.isEdit = true;
    }

    this.checkLabels(this.localPhones)
    this.checkLabels(this.localEmails)
    this.isModalOpen = true;
  }

  public validationCheck() {
    if (this.isModalOpen && this.fieldIntlTel) {
      return this.fieldIntlTel.isAllValidCheck();
    }

    return false;
  }

  public async saveData(): Promise<void> {
    this.localPhones = this.fieldIntlTel.getPhones().filter(i => i.value.trim().length > 0);
    this.localEmails = this.fieldEmails.getEmails().filter(i => i.value.trim().length > 0);
    await this.dealService.updateInformation(this.deal.personId, PersonPropertiesToUpdate.Phone, this.localPhones, this.deal.id);
    await this.dealService.updateInformation(this.deal.personId, PersonPropertiesToUpdate.Email, this.localEmails, this.deal.id);
    this.deal.phone = this.localPhones;
    this.deal.email = this.localEmails;
    this.pipelineDealsStoreService.updateDeal(this.deal);
    this.closingModal()
  }

  public closingModal() {
    this.closeModal.emit();
    this.isModalOpen = false;
  }
}
