<div class="table-wrapper">
  <div class="userListModal" [@modalState]="menuState">
    <div class="owner">
      Transfer owner
      <div class="select">
        <mat-form-field>
          <mat-select [(ngModel)]="currentOwner">
            @for (item of usersWithoutStageChangeOption; track item) {
            <mat-option [value]="item.id">{{item.name}}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
      <div class="buttons">
        <button type="button" class="btn btn-danger waves-light cancel" (click)="hideModal()">Cancel</button>
        <button type="button" [disabled]="currentOwner === null" class="btn btn-success waves-light update"
          (click)="updateList()">Update</button>
      </div>
    </div>
  </div>
  <pd-transfer-pop-up #transferPopUp></pd-transfer-pop-up>
  <pd-export></pd-export>
  <pd-custom-export-modal #exportModal></pd-custom-export-modal>
  <div class="header row">
    <div class="check col-1 check-all border-right">
      <mat-checkbox (click)="isFilterPerformed || $event.preventDefault()" [checked]="isSelectedAll"
        (change)="selectAll($event)"></mat-checkbox>
    </div>
    <div class="title col-2 border-right">
      Title
    </div>
    <div class="col-2 border-right">Company</div>
    <div class="col-2 border-right">Position</div>
    <div class="col-1 border-right">Stage</div>
    <div class="col-2 border-right">Location</div>
    <div class="col-1 border-right">Gender</div>
    <div class="col-1 owner-header">Owner</div>
  </div>
  @if (listDeals && !spinner && deals) {
  <div class="table" id="id" #container>
    @for (deal of deals; track deal; let i = $index) {
    <div class="table-row row">
      <div class="check col-1 border-right">
        @if (currentUser.role_id !== rolesEnum.Recruiter) {
        <mat-checkbox style="padding: 0px;" [checked]="isChecked(deal.id)" (change)="selectOption($event, deal)"
          [disabled]="isAvelableForTransfer(deal.id)"></mat-checkbox>
        }
      </div>
      <div class="title text col-2 border-right title-link" (click)="routToDeals(deal)">
        {{deal.title}}
      </div>
      <div class="text col-2">
        {{deal.company}}
      </div>
      <div class="text col-2">
        {{deal.position}}
      </div>
      <div class="text col-1">
        {{deal.stage_name}}
      </div>
      <div class="text col-2">
        {{deal.location}}
      </div>
      <div class="text col-1">
        {{ !deal.genderId ? '' : deal.genderId == 26 ? 'Male' : 'Female'}}
      </div>
      <div class="col-1 owner-collumn">
        {{deal.user_name}}
      </div>
    </div>
    }
  </div>
  }

</div>
@if (lazyLoading) {
<div class="lazyLoading">
  <div class="text-lazy">Loading more deals...</div>
</div>
}

@if (spinner) {
<div class="spinner">
  <mat-spinner></mat-spinner>
</div>
}

@if (isShowError) {
<div class="show-error">
  <h2 style="color: red; font-weight: 400; padding: 20px">Sorry, something went wrong. Please try again later</h2>
</div>
}

@if (updateOwnerInfo && updateOwnerInfo.length > 0) {
<div class="move-leads">{{updateOwnerInfo}}</div>
}
<pd-export-deals #exportDeals></pd-export-deals>