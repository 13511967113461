export enum AchievementCardTypes {
    PresidentClub = 1,
    ChairmanClub = 2,
    MillionDollarClub = 3,
    HallOfFameClub = 4,
    MonthlySales40K = 5,
    MonthlySales50K = 6,
    MonthlySales75K = 7,
    MonthlySales100K = 8,
    MonthlySales250K = 9,
    MonthlySales500K = 10,
    MonthlySales1M = 11,
    MonthlySalesHallOfFame = 12,
    BigSalePins = 13,
    RingOfHonourPins = 14,
    GoldSalePins = 15,
    DiamondSalePins = 16,
    ServiceAwards = 17,
}
