import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ClientRating } from '../../core/enums/ClientRating';
import { CustomSelectFilterWithoutLazyComponent } from '../../shared/custom/custom-select-without-lazy/custom-select-without-lazy';
import * as stages from '../../../assets/stages_new_prospespects';
import { RequestStatus } from '../../core/enums/RequestStatus';
import { EnumUtil } from '../../core/utils/enum.util';
import { BulkMessageRequestViewModel } from 'src/app/core/models/bulk-message/BulkMessageRequestViewModel';
import { TemplateVariableContentTypes } from 'src/app/core/enums/TemplateVariableContentTypes';
import { BulkMessageSettingViewModel } from 'src/app/core/models/bulk-message/BulkMessageSettingsViewModel';
import { MessageTemplateType } from 'src/app/core/enums/MessageTemplateType';
import { BulkMessageEmailComponent } from '../bulk-message-requests/bulk-message-email/bulk-message-email.component';
import { TemplateVariableTypes } from 'src/app/core/enums/TemplateVariableTypes';

@Component({
  selector: 'pd-bulk-message-table',
  templateUrl: './bulk-message-table.component.html',
  styleUrls: ['./bulk-message-table.component.scss']
})
export class BulkMessageTableComponent implements OnInit {
  @ViewChild("statusFilter") customSelectStatus: CustomSelectFilterWithoutLazyComponent;
  @ViewChild('fullscreenView') fullscreenView: ElementRef;
  @ViewChild('templateView') templateView: BulkMessageEmailComponent;

  @Input() isMain: boolean;
  @Input() isArchive: boolean;
  @Input() isSelectedItem: boolean;
  @Input() isNoFilter: boolean;

  @Input() title: string;
  @Input() settings: Array<BulkMessageSettingViewModel>;

  @Output() public onSelectSetting = new EventEmitter<any>();
  @Output() public onSelectRequest = new EventEmitter<any>();
  @Output() public onRemove = new EventEmitter<any>();
  @Output() public onArchive = new EventEmitter<any>();

  @Output() public onConfigureMessage = new EventEmitter<any>();
  @Output() public onCancel = new EventEmitter<any>();

  public newStatus = RequestStatus.New;
  EnumUtil = EnumUtil;
  RequestStatus = RequestStatus;
  MessageTemplateType = MessageTemplateType;
  TemplateVariableTypes = TemplateVariableTypes;
  ClientRating = ClientRating;

  public ratings = [
    { label: 'NA clients', value: ClientRating.NA, checked: false },
    { label: 'C clients', value: ClientRating.C, checked: false },
    { label: 'B clients', value: ClientRating.B, checked: false },
    { label: 'A clients', value: ClientRating.A, checked: false }
  ];

  constructor(
  ) {
  }

  async ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log('isArchive', this.isArchive);
  }

  public getRatingLabel(rating: ClientRating) {
    return this.ratings.find(f => f.value == rating).label;
  }

  public getPipelineLabel(pipelineid: number) {
    return stages.ALL_PIPELINES.find(f => f.id == pipelineid).name;
  }

  select(any) {
    this.onSelectSetting.emit(any);
  }

  remove(settingId) {
    console.log('remove', settingId);
    this.onRemove.emit({ settingId: settingId });
  }

  archive(settingId, value) {
    console.log('archive', settingId);
    this.onArchive.emit({ settingId: settingId, value: value });
  }

  configureWhatsappMessageSending(event) {
    const data = {
      request: event,
      isEmail: false,
      isWhatsApp: true
    }

    this.onConfigureMessage.emit(data);
  }

  configureEmailMessageSending(event) {
    const data = {
      request: event,
      isEmail: true,
      isWhatsApp: false
    }

    this.onConfigureMessage.emit(data);
  }

  cancel() {
    this.onCancel.emit();
  }

  trackById(index: number, item: any) {
    return item.id;
  }

  public getTemplateName(setting: BulkMessageSettingViewModel) {
    const selectedRequest = this.getSelectedRequest(setting);

    if (selectedRequest?.messageTemplate?.messageTemplateType == MessageTemplateType.Email) {
      return selectedRequest?.messageTemplate?.subject ?? 'Unknown';
    }

    return selectedRequest?.messageTemplate?.displayName ?? 'Unknown';
  }

  public getTemplateBody(setting: BulkMessageSettingViewModel) {
    const selectedRequest = this.getSelectedRequest(setting);
    return selectedRequest?.messageTemplate?.body ?? 'Unknown';
  }

  public parseSettingData(settingData: BulkMessageSettingViewModel) {
    let ownersOfDealsStr = (settingData.owners && settingData.owners.length > 0) ? settingData.owners?.map(r => r.name).join('<br/>') : 'All';
    let ratingsStr = (settingData.clientRatings && settingData.clientRatings.length > 0) ? settingData.clientRatings.sort().map(r => ClientRating[r] || 'Unknown').join('<br/>') : 'All';
    let pipelinesStr = settingData.pipelines.map(r => this.getPipelineLabel(r)).join('<br/>');

    return "<div><b>Pipelines: </b><br/>" + pipelinesStr + "</div><br/>"
      + "<div><b>Client Ratings: </b><br/>" + ratingsStr + "</div><br/>"
      + "<div><b>Owners Of Deals: </b><br/>" + ownersOfDealsStr + "</div><br/>";
  }

  public parseRequestData(settingData: BulkMessageSettingViewModel) {
    const selectedRequest = this.getSelectedRequest(settingData);
    let replyRecipientsStr = (selectedRequest.replyRecipients && selectedRequest.replyRecipients.length > 0) ? selectedRequest.replyRecipients?.map(r => r.name).join('<br/>') : 'Nobody';

    return "<div><b>Reply Recipients: </b><br/>" + replyRecipientsStr + "</div><br/>";
  }

  onRequestSelectChange(event) {
    const requestId = event.requestId;
    const setting = this.settings.find(s => s.requests.some(r => r.id === requestId));
    setting.requests.forEach(r => {
      r.isSelected = r.id === requestId;
    });

    this.onSelectRequest.emit(event);
  }

  public getTemplateVariablesHtml(setting: BulkMessageSettingViewModel, templateVariableType: TemplateVariableTypes): string {
    let selectedRequest = this.getSelectedRequest(setting);

    if (!selectedRequest) {
      return '';
    }

    const requestVariables = selectedRequest.variables;
    const templateVariables = selectedRequest.messageTemplate?.variableDescriptions.filter(v => v.templateVariableType == templateVariableType);
    const htmlStrings: string[] = [];

    if (!(requestVariables && templateVariables)) {
      return '';
    }

    templateVariables.forEach(templateVar => {
      const requestVar = requestVariables.find(rv => rv.variableDescriptionId === templateVar.id);
      const variableNumber = templateVar.variableNumber;
      let displayValue: string;

      if (templateVar.templateVariableContentType === TemplateVariableContentTypes["Static Text"]) {
        displayValue = requestVar.staticValue;
        const html = `<div><b>{{${variableNumber}}}</b> - ${displayValue}</div>`;
        htmlStrings.push(html);
      } else {
        displayValue = TemplateVariableContentTypes[templateVar.templateVariableContentType];
        const html = `<div><b>{{${variableNumber}}}</b> - <i>${displayValue}</i></div>`;
        htmlStrings.push(html);
      }
    });

    return htmlStrings.join('');
  }

  getLocationBody(requestData: BulkMessageSettingViewModel) {
    let locations = requestData.locations;
    return locations
  }

  getSelectedRequest(setting: BulkMessageSettingViewModel): BulkMessageRequestViewModel {
    const selectedRequest = setting?.requests?.find(r => r.isSelected);

    if (selectedRequest) {
      return selectedRequest;
    }

    if (setting?.requests && setting?.requests.length > 0) {
      let firstRequest = setting.requests[0];
      firstRequest.isSelected = true;
      return firstRequest;
    }
    return null;
  }

  public openFullscreen(request: BulkMessageRequestViewModel) {
    this.templateView.setData(request.messageTemplate);
    const fullscreen = this.fullscreenView.nativeElement;
    fullscreen.setAttribute('style', `display: block`);
  }

  public closeFullscreen() {
    const fullscreen = this.fullscreenView.nativeElement;
    fullscreen.setAttribute('style', `display: none`);
  }

  public isWhatsapp(bulkMessageSetting: BulkMessageSettingViewModel) {
    return this.getSelectedRequest(bulkMessageSetting)?.messageTemplate?.messageTemplateType == MessageTemplateType.Whatsapp;
  }

  public isEmail(bulkMessageSetting: BulkMessageSettingViewModel) {
    return this.getSelectedRequest(bulkMessageSetting)?.messageTemplate?.messageTemplateType == MessageTemplateType.Email;
  }
  
  public getSelectedStatusesMessage(request: BulkMessageRequestViewModel): string {
    let receivedStatus = '';
    if (request.isSendToReceived === true) {
      receivedStatus = 'Clients who received the message.';
    } else if (request.isSendToReceived === false) {
      receivedStatus = 'Clients who did not receive the message.';
    }
  
    let readStatus = '';
    if (request.isSendToRead === true) {
      readStatus = 'Who read the message.';
    } else if (request.isSendToRead === false) {
      readStatus = 'Who did not read the message.';
    }
  
    let replyStatus = '';
    if (request.isSendToReplied === true) {
      replyStatus = 'Who replied to the message.';
    } else if (request.isSendToReplied === false) {
      replyStatus = 'Who did not reply to the message.';
    }
  
    return `${receivedStatus} ${readStatus} ${replyStatus}`;
  }
}