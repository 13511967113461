import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BaseService } from './base.service';
import { HttpRestService } from './http-client/http-rest-client.service';
import { UtilsHelper } from '../utils/utils.helper';

@Injectable()
export class Modeler3dService extends BaseService {

    constructor(private restService: HttpRestService) {
        super();
    }
    public static filesToUploadCount = 0;
    public static uploadedCount = 0;

    public async getLines(): Promise<any> {
        let response = await this.handleResponse(this.restService.get({ directUrl: environment.apiUrl3d + '/Attributes' }));
        if (response) {
            response = response.filter(f => f.type == "FabricLine");
            return response;
        }
        return response;

    }

    public async getOutfitParts(): Promise<any> {
        return await this.handleResponse(this.restService.get({ directUrl: environment.apiUrl3d + '/fabrics/outFitParts' }));
    }

    public async uploadFile(file: any, fileName: string, product: any, lineId: any): Promise<any> {
        let formData: FormData = new FormData();

        formData.append("files", file, fileName);
        formData.append("product", product);
        if (lineId && lineId != "None") {
            formData.append("lineId", lineId);
        }

        return await this.handleResponse(this.restService.upload(
            {
                directUrl: environment.apiUrl3d + '/Image/addImages',
                data: formData
            }
        ));
    }

    public async uploadFabricsFiles(files: FileList, product: any, lineId: any): Promise<any[]> {
        this.reset();

        Modeler3dService.filesToUploadCount = files.length;

        let filesByPages = UtilsHelper.splitArray(Array.from(files), 5);
        var promises = new Array<Promise<any>>();

        for (var i = 0; i < filesByPages.length; i++) {
            let element = filesByPages[i]
            var promise = this.uploadFiles(element, product, lineId)
            promises.push(promise);
            Modeler3dService.uploadedCount += element.length
            await promise;
        }

        this.reset();
        return Promise.all(promises);
    }

    public async uploadFiles(files: any, product: any, lineId: any): Promise<any> {
        const formData: FormData = new FormData();

        Array.from(files).forEach((element: any) => {
            formData.append("files", element, element.name);
        });
        formData.append("product", product);
        if (lineId && lineId != "None") {
            formData.append("lineId", lineId);
        }

        return await this.handleResponse(this.restService.upload(
            {
                directUrl: environment.apiUrl3d + '/Image/addImages',
                data: formData
            }
        ));
    }

    private reset() {
        Modeler3dService.filesToUploadCount = 0;
        Modeler3dService.uploadedCount = 0;
    }
}