<div class="wrapper">
    <div>
        <div>
            <div class="row">
                <div class="col-4">
                    <div class="row">
                        <div class="col">
                            <div class="label">Geocode</div>
                            <div class="help-tip">
                                <p>Watch the <a
                                        href="https://drive.google.com/file/d/1jFqD8CQ3dBIMD6ZCh7jEO5EduQAyy4mv/view?usp=sharing"
                                        target="_blank">video instruction</a>
                                    how to check the coordinates of the company.
                                </p>
                            </div>
                            <input style="transform: translateY(-23px);" type="text" [formControl]="geocodeControl"
                                class="input-text" [(ngModel)]="geocode" placeholder="43.6486401,-79.3839326"
                                [ngClass]="{'input-error': geocodeControl.touched && !geocodeControl.valid }">
                        </div>
                        <div class="col search-btn-container">
                            <button style="transform: translateY(-12px);" class="btn btn-success waves-light search-btn"
                                (click)="onSearchByGeocode()">Search</button>
                        </div>
                    </div>
                </div>

                <div class="col-5">
                    <div class="row">
                        <div class="col">
                            <div class="label">Address</div>
                            <input type="text" class="input-text" [(ngModel)]="address" [formControl]="addressControl"
                                [ngClass]="{'input-error': addressControl.touched && !addressControl.valid }">
                        </div>

                        <div class="col search-btn-container">
                            <button class="btn btn-success waves-light search-btn search-btn-2"
                                (click)="onSearchByAddress()">Search</button>
                        </div>
                    </div>
                </div>

                @if (currentUser.role_id === RolesEnum.MasterLead) {
                <div class="col-3 user-filter">
                    <div class="label">User Filter</div>
                    <pd-custom-select-without-lazy #userFilter (loadDataEvent)="getUsersFilterData()"
                        (autoCompleteEvent)="getUsersAutocomplete($event)" [dataLabel]="usersLabel"
                        [startLabel]="usersLabel" [isMultiple]="true">
                    </pd-custom-select-without-lazy>
                </div>
                }
            </div>

            <div class="row">
                @if (isMapStart) {
                <div class="map-wrapper">
                    <bing-map [filterUserIds]="filterUserIds" [latitude]="latitude" [longitude]="longitude"
                        [isSearchByGeocode]="true"></bing-map>
                </div>
                }
            </div>

            @if (spinner) {
            <div class="spinner">
                <mat-spinner [diameter]="100"></mat-spinner>
            </div>
            }
        </div>
    </div>
</div>