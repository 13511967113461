import { UsersService } from './../../core/services/users.service';
import { UserStoreService } from './../../core/services/stores/user-store.service';
import { UserContextService } from './../../core/services/user-context.service';
import { PipelineStoreService } from './../../core/services/stores/pipeline-store.service';
import { Component, ViewChild, Output, EventEmitter, Input, OnInit, AfterViewInit } from '@angular/core';
import * as stages from '../../../assets/stages_new_prospespects';
import { CustomChipsSelectFilterComponent } from 'src/app/shared/custom/custom-chips-select-filter/pd-custom-chips-select-filter.component';
import { ChipsSelectFilterDataModel, ChipsSelectFilterModel } from 'src/app/core/models/CustomChipsSelectFilterModel';
import { User } from 'src/app/core/models/UserModel';
import { MultiSelectDataType } from 'src/app/core/enums/MultiSelectDataType';
import { DealService } from 'src/app/core/services/deals.service';
import { FilterDealModel, FilterDealStoreModel } from 'src/app/core/models/filter-models/FilterDealModel';
import { FilterType } from 'src/app/core/enums/FilterType.enum';
import { RolesEnum } from 'src/app/core/enums/RolesEnum';
import { ReplaySubject, combineLatest, firstValueFrom, takeUntil } from 'rxjs';
import { CustomSelectFilterWithoutLazyComponent } from 'src/app/shared/custom/custom-select-without-lazy/custom-select-without-lazy';
import { Stage } from 'src/app/core/models/Stage';
import { StageFeatureStoreService } from 'src/app/core/services/stores/stage-feature-store.service';
import { ActivatedRoute } from '@angular/router';
import { CustomSelectFilterGroupedDataComponent } from 'src/app/shared/custom/custom-select-grouped-data/custom-select-grouped-data';
import { BaseComponent } from 'src/app/core/base.component';

@Component({
  selector: 'pd-right-deals-panel',
  templateUrl: './right-deals-panel.component.html',
  styleUrls: ['./right-deals-panel.component.scss'],
})

export class RightDealsPanelComponent extends BaseComponent implements OnInit, AfterViewInit {
  @ViewChild("companyFilter") customSelectCompany: CustomChipsSelectFilterComponent;
  @ViewChild("locationFilter") customSelectLocation: CustomChipsSelectFilterComponent;
  @ViewChild("positionFilter") customSelectPosition: CustomChipsSelectFilterComponent;
  @ViewChild("userFilter") public customSelectUser: CustomSelectFilterWithoutLazyComponent;
  public customSelectStage: CustomSelectFilterGroupedDataComponent;
  @ViewChild('stageFilter') set content(content: CustomSelectFilterGroupedDataComponent) {
    if (content) {
      this.customSelectStage = content;
      this.getStageFilterData();
    }
  }

  public companies: Array<ChipsSelectFilterModel>;
  public positions: Array<ChipsSelectFilterModel>;
  public locations: Array<ChipsSelectFilterModel>;

  public storageCompanies: Array<ChipsSelectFilterModel>;
  public storagePositions: Array<ChipsSelectFilterModel>;
  public storageLocations: Array<ChipsSelectFilterModel>;

  public companiesLabel: string = 'Companies';
  public positionsLabel: string = 'Positions';
  public locationsLabel: string = 'Locations';

  @Input() public isMasterLeadDeals = false;
  @Input() public isListDeals = false;
  @Output() applyFilters = new EventEmitter();

  public selectedPipelineId: number;

  public isContactByEmailOnlyFilter: boolean;
  public isSameBuildingFilter: boolean;
  public isWithPhoneNumberOnlyFilter: boolean;

  public currentUser: User;

  public isAdmin = false;
  public isMasterLeadAccount = false;
  public isSystemAccount = false;
  public isAdminAccount = false;
  public isClothierAccount = false;
  public isEaAccount = false;
  public isEaCallerAccount = false;
  public isMyDeals = false;

  public selectedGroupId: number;
  public selectedGenderId: number;
  public selectedUserId: number;
  public search: string;

  public eaAllowedUsersList: User[];

  private filteredUserMultiSelect: ReplaySubject<User[]> = new ReplaySubject<User[]>(1);
  private filteredUsers = [];
  private allFilteredUsers = [];
  public usersLabel: string = 'Users'

  private filteredStageMultiSelect: ReplaySubject<Stage[]> = new ReplaySubject<Stage[]>(1);
  private filteredStage = [];
  private allFilteredStage = [];
  public stageLabel: string = 'All Stages';
  public avelableUsers: User[];

  public selectDataTypes = MultiSelectDataType;

  public groupList = [{ id: -1, name: 'All' }]; //TODO
  public genderList = [ //TODO
    { id: 0, name: 'All' }
    , { id: 26, name: 'Male' }
    , { id: 27, name: 'Female' }
    , { id: -2, name: 'Exclude Female' }
    , { id: -1, name: 'None' }
  ];

  constructor(
    private pipelineStoreService: PipelineStoreService,
    private userContextService: UserContextService,
    private dealService: DealService,
    private userStoreService: UserStoreService,
    private usersService: UsersService,
    private stageFeatureStoreService: StageFeatureStoreService,
    private route: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    this.initializeStore();
    this.usersLabel = this.currentUser.name;
  }

  async ngAfterViewInit(): Promise<void> {
    this.dealService.getClientGroups().then(result => {
      result.forEach(element => {
        this.groupList.push(element);
      });
    });

    this.setDefaultValue();
    this.setFiltersFromStoreModel();
    this.allFilteredUsers = await this.usersService.getUsersByRoles();
    this.setUserFilter();

    combineLatest(this.userStoreService.selectedUsersFilter, this.stageFeatureStoreService.allStage)
      .pipe(takeUntil(this.destroy$)).subscribe(async ([users, allStages]) => {
        console.log('RightDealsPanelComponent ngAfterViewInit combineLatest', users, allStages);
        if (users && allStages) {
          this.allFilteredStage = this.stagesToPipelinesArray(await this.selectStageFeatureViewStagesForRole(users));
          this.getStageFilterData();
        }
      });
    this.getUsersFilterData();
  }

  public resetFilters() {
    console.log('RightDealsPanelComponent resetFilters');
    localStorage.removeItem('filterSettings');
    this.setDefaultValue();
  }

  private setUserFilter() {
    this.avelableUsers = this.allFilteredUsers.filter(i => i.role_id !== RolesEnum.EA && i.role_id !== RolesEnum.EaCaller);

    if (this.currentUser.role_id == RolesEnum.Admin || this.currentUser.role_id == RolesEnum.EA || this.currentUser.role_id == RolesEnum.EaCaller) {
      if (this.currentUser.role_id == RolesEnum.EA || this.currentUser.role_id == RolesEnum.EaCaller) {
        this.avelableUsers = this.allFilteredUsers.filter(x => x.role_id == RolesEnum.Clothier || x.role_id == RolesEnum.Admin);
      }
      else {
        this.avelableUsers = this.avelableUsers.concat(this.allFilteredUsers.filter(x => x.role_id != RolesEnum.MasterLead));
      }
      this.allFilteredUsers.forEach(x => {
        if (x.role_id != RolesEnum.Admin && x.role_id != RolesEnum.Clothier) {
          x.disabled = true
        }
      });
    }
    else {
      this.allFilteredUsers.forEach(x => {
        if (x.role_id != this.currentUser.role_id) {
          x.disabled = true
        }
      });
    }
    this.filteredUsers = this.allFilteredUsers;
  }

  initializeStore() {
    this.pipelineStoreService.currentPipeline.subscribe(pipeline => {
      if (!pipeline) {
        this.route.params.subscribe(params => {
          const id = params['id'];
          if (!isNaN(Number(id))) {
            const pipeline = stages.ALL_PIPELINES.find(p => p.id === Number(id));
            this.selectedPipelineId = pipeline.id;
          }
        });
        return;
      };
      this.selectedPipelineId = pipeline.id;
    });

    this.currentUser = this.userContextService.user.value;

    this.isAdmin = this.currentUser && this.currentUser.is_admin;
    this.isMasterLeadAccount = this.currentUser && this.currentUser.role_id === RolesEnum.MasterLead;
    this.isSystemAccount = this.currentUser && this.currentUser.role_id === RolesEnum.SystemAccount;
    this.isAdminAccount = this.currentUser && this.currentUser.role_id === RolesEnum.Admin;
    this.isClothierAccount = this.currentUser && this.currentUser.role_id === RolesEnum.Clothier;
    this.isEaAccount = this.currentUser && this.currentUser.role_id === RolesEnum.EA;
    this.isEaCallerAccount = this.currentUser && this.currentUser.role_id === RolesEnum.EaCaller;

    this.userStoreService.loadedDealsUsersFilter.subscribe(users => {
      if ((users && users.length === 1) && this.currentUser && this.currentUser.id === users[0].id) {
        this.isMyDeals = true;
      }
      else {
        this.isMyDeals = false;
      }
    });

    this.userStoreService.allUsers.subscribe(allItems => {
      if (!allItems || allItems.length === 0) {
        return;
      }
      const pipelineIds = [stages.Pipelines.Clients, stages.Pipelines.OpenOrders];
      const allUsersWithClientsPipelineAccess = Object.entries(stages.STAGES_BY_ROLES_VISIBILITY).reduce((result, current) => {
        if (current[1] && current[1].length > 0 && current[1].some(stageName =>
          stages.STAGES_FOR_ALL_PIPELINES.some(s => s.name === stageName && pipelineIds.indexOf(s.pipeline_id) !== -1))) {
          result.push(RolesEnum[current[0]]);
        }
        return result;
      }, []);
      if (this.currentUser.role_id === RolesEnum.EA || this.currentUser.role_id === RolesEnum.EaCaller) {
        this.eaAllowedUsersList = allItems.filter(i => i.role_id !== RolesEnum.MasterLead && i.role_id !== RolesEnum.EA && i.role_id !== RolesEnum.EaCaller && allUsersWithClientsPipelineAccess.some(r => r === i.role_id));
        this.userStoreService.setSelectedUsersFilter([this.eaAllowedUsersList[0]]);
      }
    });
  }

  public async onSelectedStageItem(): Promise<void> {
    if (this.customSelectStage) {
      this.stageFeatureStoreService.setCurrentStage(this.customSelectStage.dataArray);
    }
  }

  public getStageFilterData() {
    this.filteredStage = [];
    this.filteredStage = this.filteredStage.concat(this.allFilteredStage);
    this.filteredStageMultiSelect.next(this.allFilteredStage.slice());
    if (this.customSelectStage) {
      this.customSelectStage.setData(this.filteredStage);
      if (this.customSelectStage.dataArray.map((u) => u.id).length == 0) {
      }
    }
  }

  public getStageAutocomplete(event) {
    let search = event.searchString;
    if (!search) {
      this.filteredStageMultiSelect.next(this.allFilteredStage.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredStageMultiSelect.next(
      this.allFilteredStage.filter(loc => loc.name.toLowerCase().indexOf(search) > -1)
    );
    this.filteredStage = [];
    this.filteredStageMultiSelect.subscribe(x => {
      for (let i = 0; i < x.length; i++) {
        if (this.filteredStage.length == 0) {
          this.filteredStage.push(x[0]);
        } else {
          if (!this.filteredStage.includes(x[i], 0)) {
            this.filteredStage.push(x[i]);
          }
        }
      }
    });
    this.customSelectStage.setData(this.filteredStage);
  }

  public onUserSelectedItem() {
    if (this.customSelectUser.dataArray?.length == 0) {
      this.allFilteredUsers.forEach(x => {
        if (this.avelableUsers.some(u => u.id == x.id)) {
          x.disabled = false;
        }
      });
    }
    if (this.customSelectUser.dataArray?.length == 1) {
      if (this.customSelectUser.dataArray[0].role_id == RolesEnum.Clothier || this.customSelectUser.dataArray[0].role_id == RolesEnum.Admin) {
        this.allFilteredUsers.forEach(x => {
          if (x.role_id != RolesEnum.Clothier && x.role_id != RolesEnum.Admin)
            x.disabled = true;
        });
      }
      else {
        this.allFilteredUsers.forEach(x => {
          if (x.role_id != this.customSelectUser.dataArray[0].role_id)
            x.disabled = true;
        });
      }
    }
    this.userStoreService.setSelectedUsersFilter(this.customSelectUser.dataArray);
    this.customSelectUser.setData(this.allFilteredUsers);
    this.customSelectUser.searchText = '';
  }

  public getUsersFilterData() {
    this.filteredUsers = [];
    this.filteredUsers = this.filteredUsers.concat(this.allFilteredUsers);
    this.filteredUserMultiSelect.next(this.allFilteredUsers.slice());
    if (this.customSelectUser) {
      const savedFilter = JSON.parse(localStorage.getItem('filterUsers'));
      if (savedFilter && savedFilter.length > 0) {
        this.allFilteredUsers.forEach(x => {
          if (savedFilter[0].role_id == RolesEnum.Admin || savedFilter[0].role_id == RolesEnum.Clothier) {
            if (x.role_id == RolesEnum.Admin || x.role_id == RolesEnum.Clothier) {
              x.disabled = false;
            }
            else {
              x.disabled = true;
            }
          }
          else if (x.role_id == savedFilter[0].role_id) {
            x.disabled = false;
          } else {
            x.disabled = true;
          }
        });
      }
      this.customSelectUser.setData(this.filteredUsers);

      if (this.customSelectUser.dataArray.map((u) => u.id).length == 0) {
        this.customSelectUser.label = savedFilter && savedFilter.length > 0 ?
          savedFilter.map(x => x.name).join(", ") : this.currentUser.name;
        this.customSelectUser.dataArray = savedFilter && savedFilter.length > 0 ?
          savedFilter : this.allFilteredUsers.filter(x => x.id == this.currentUser.id);
      }
    }
  }

  public getUsersAutocomplete(event) {
    this.search = event.searchString;
    if (!this.search) {
      this.filteredUserMultiSelect.next(this.allFilteredUsers.slice());
      return;
    } else {
      this.search = this.search.toLowerCase();
    }
    this.filteredUserMultiSelect.next(
      this.allFilteredUsers.filter(loc => loc.name.toLowerCase().indexOf(this.search) > -1)
    );
    this.filteredUsers = [];
    this.filteredUserMultiSelect.subscribe(x => {
      for (let i = 0; i < x.length; i++) {
        if (this.filteredUsers.length == 0) {
          this.filteredUsers.push(x[0]);
        } else {
          if (!this.filteredUsers.includes(x[i], 0)) {
            this.filteredUsers.push(x[i]);
          }
        }
      }
    });
    this.customSelectUser.setData(this.filteredUsers);
  }

  public async selectStageFeatureViewStagesForRole(users: User[]): Promise<Stage[]> {
    console.log('RightDealsPanelComponent selectStageFeatureViewStagesForRole', users);
    let allStageFeatureItems = await firstValueFrom(this.stageFeatureStoreService.allStage);
    console.log('RightDealsPanelComponent selectStageFeatureViewStagesForRole', allStageFeatureItems);
    console.log('RightDealsPanelComponent selectStageFeatureViewStagesForRole', this.currentUser);
    const requiredStages = this.currentUser.is_admin && users.length > 0 && users[0].role_id === RolesEnum.MasterLead ?
      allStageFeatureItems.filter(s => stages.MASTER_LEAD_DEALS.some(ss => ss.id === s.id)) : [];
    if (allStageFeatureItems) {
      allStageFeatureItems = allStageFeatureItems.filter(x => x.pipeline_id !== stages.Pipelines.ClothierMeetingConfirm)
      let role = (users && users.length > 0 &&
        users[0] && users[0].role_id && RolesEnum[users[0].role_id])
        || this.currentUser && this.currentUser.role_id && RolesEnum[this.currentUser.role_id]
        || null;
      if (role) {
        return allStageFeatureItems.filter(s => ((stages.STAGES_BY_ROLES_VISIBILITY[role].some(st => st === s.name) || (requiredStages.length > 0 && requiredStages.some(ss => s.id === ss.id)))));
      }
      else {
        return allStageFeatureItems.filter(s => (this.currentUser.is_admin || s.pipeline_id === stages.NewProspectsPipeline.id) && stages.STAGES_BY_ROLES_VISIBILITY[RolesEnum[this.currentUser.role_id]].some(st => st === s.name))
      }
    } else {
      return null;
    }
  };

  public stagesToPipelinesArray(stagesToTransform: Stage[]) {
    let pipelines = stagesToTransform.map(s => s.pipeline_id);
    let uniquePipelines = pipelines.filter(function (elem, index, self) {
      return index === self.indexOf(elem);
    });
    return uniquePipelines.reduce((result, element) => {
      let pipeline = stages.ALL_PIPELINES.find(s => s.id === element);
      result.push({
        name: pipeline && pipeline.name || '',
        values: stagesToTransform.filter(s => s.pipeline_id === element)
      });
      return result;
    }, []);
  }

  public filterDashboard() {
    this.storeFilterModel();
    this.applyFilters.emit();
  }

  public getFilterModel(): FilterDealModel {
    const dealFilterModel: FilterDealModel = {
      isSameBuildingFilter: (this.isAllowedSameBuildingFlagFilter) ? this.isSameBuildingFilter : false,
      isWithPhoneNumberOnlyFilter: (this.isAllowedWithPhoneNumberOnlyFlagFilter) ? this.isWithPhoneNumberOnlyFilter : false,
      isContactByEmailOnlyFilter: (this.isAllowedContactByEmailOnlyFlagFilter) ? this.isContactByEmailOnlyFilter : false,
      filteredCompanies: (this.isAllowedToCompaniesFilter) ? this.getFilteredCompanies() : [],
      filteredPositions: (this.isAllowedToPositionsFilter) ? this.getFilteredPositions() : [],
      filteredLocations: (this.isAllowedToLocationsFilter) ? this.getFilteredLocations() : [],
      selectedGenderId: (this.isAllowedToGenderSelect) ? this.selectedGenderId : undefined,
      selectedGroupId: (this.isAllowedToGroupSelect) ? this.selectedGroupId : undefined,
    }
    return dealFilterModel;
  }

  public changeUser(user: any) {
    if (user) {
      this.userStoreService.setSelectedUsersFilter([user as User]);
    }
  }

  public changeGroup(value: number) {
    this.selectedGroupId = value;
  }

  public changeGender(value: number) {
    this.selectedGenderId = value;
  }

  //////////////////////////////// Private START //////////////////////////////

  private setDefaultValue() {
    console.log('RightDealsPanelComponent setDefaultValue');

    this.locations = [];
    this.companies = [];
    this.positions = [];
    this.storageCompanies = [];
    this.storagePositions = [];
    this.storageLocations = [];
    this.selectedGenderId = this.genderList[0].id;
    this.selectedGroupId = this.groupList[0].id;

    this.customSelectCompany && this.customSelectCompany.resetChips();
    this.customSelectLocation && this.customSelectLocation.resetChips();
    this.customSelectPosition && this.customSelectPosition.resetChips();
    this.isContactByEmailOnlyFilter = false;
    this.isSameBuildingFilter = false;
    this.isWithPhoneNumberOnlyFilter = false;

    this.stageFeatureStoreService.setCurrentStage([]);
  }

  private setFiltersFromStoreModel() {
    const filterSettings: FilterDealStoreModel = JSON.parse(localStorage.getItem('filterSettings'));
    if (filterSettings) {
      this.isSameBuildingFilter = filterSettings.isSameBuildingFilter;
      this.isWithPhoneNumberOnlyFilter = filterSettings.isWithPhoneNumberOnlyFilter;
      this.isContactByEmailOnlyFilter = filterSettings.isContactByEmailOnlyFilter;

      this.storageCompanies = filterSettings.filteredCompanies;
      this.storagePositions = filterSettings.filteredPositions;
      this.storageLocations = filterSettings.filteredLocations;

      this.companies = filterSettings.filteredCompanies;
      this.positions = filterSettings.filteredPositions;
      this.locations = filterSettings.filteredLocations;

      this.selectedGenderId = filterSettings.selectedGenderId;
      this.selectedGroupId = filterSettings.selectedGroupId;
    }
  }

  private storeFilterModel() {
    const toStore: FilterDealStoreModel = {
      isSameBuildingFilter: this.isSameBuildingFilter,
      isWithPhoneNumberOnlyFilter: this.isWithPhoneNumberOnlyFilter,
      isContactByEmailOnlyFilter: this.isContactByEmailOnlyFilter,
      filteredCompanies: this.companies,
      filteredPositions: this.positions,
      filteredLocations: this.locations,
      selectedGenderId: this.selectedGenderId,
      selectedGroupId: this.selectedGroupId
    }
    localStorage.setItem('filterSettings', JSON.stringify(toStore));
    localStorage.setItem('filterUsers', JSON.stringify(this.userStoreService.selectedUsersFilter.value));
  }

  //////////////////////////////// Private End //////////////////////////////

  //////////////////////////////// VIEW ngClass START //////////////////////////////

  public get isSameBuildingColorAttention(): boolean {
    return (this.isSameBuildingFilter && this.isAllowedSameBuildingFlagFilter);
  }

  //////////////////////////////// VIEW ngClass END //////////////////////////////

  //////////////////////////////// VIEW *ngIF START //////////////////////////////

  public get isAllowedToPositionsFilter(): boolean {
    return !this.isMasterLeadDeals
      && (this.isMasterLeadAccount || this.isAdminAccount)
      && (this.isListDeals || this.selectedPipelineId === stages.Pipelines.NewProspects);
  }

  public get isAllowedToCompaniesFilter(): boolean {
    return !this.isMasterLeadDeals
      && (this.isListDeals
        || this.isAdmin
        || (this.selectedPipelineId === stages.Pipelines.NewProspects)
        || (this.selectedPipelineId !== stages.Pipelines.Clients && this.selectedPipelineId !== stages.Pipelines.OpenOrders && this.isClothierAccount));
  }

  public get isAllowedToLocationsFilter(): boolean {
    return !this.isMasterLeadDeals
      && (this.isListDeals
        || this.isAdmin
        || (this.selectedPipelineId === stages.Pipelines.NewProspects)
        || (this.selectedPipelineId !== stages.Pipelines.Clients && this.selectedPipelineId !== stages.Pipelines.OpenOrders && this.isClothierAccount));
  }

  public get isAllowedToGenderSelect(): boolean {
    return !this.isMasterLeadDeals;
  }

  public get isAllowedToGroupSelect(): boolean {
    return (this.selectedPipelineId === stages.Pipelines.Clients || this.selectedPipelineId === stages.Pipelines.OpenOrders) && !this.isListDeals && !this.isMasterLeadDeals;
  }

  public get isAllowedToUserSelect(): boolean {
    return (this.isEaAccount || this.isEaCallerAccount) && (this.eaAllowedUsersList && this.eaAllowedUsersList.length > 0);
  }

  public get isAllowedToUsersMultiSelect(): boolean {
    return !this.isMasterLeadDeals && (this.isAdmin && !this.isEaAccount && !this.isEaCallerAccount);
  }

  public get isAllowedSameBuildingFlagFilter(): boolean {
    return !this.isMasterLeadDeals && (this.isListDeals || this.selectedPipelineId == stages.Pipelines.NewProspects);
  }

  public get isAllowedWithPhoneNumberOnlyFlagFilter(): boolean {
    return !this.isMasterLeadDeals && (this.isMasterLeadAccount);
  }

  public get isAllowedContactByEmailOnlyFlagFilter(): boolean {
    return !this.isListDeals
      && !this.isMasterLeadDeals
      && this.selectedPipelineId !== stages.Pipelines.NewProspects
      && this.selectedPipelineId !== stages.Pipelines.ClothierMeetingConfirm
      && (this.isMasterLeadAccount || this.isAdminAccount || this.isClothierAccount);
  }

  public get isExceptOnlyPositions(): boolean {
    return this.selectedPipelineId === stages.Pipelines.ClothierContactClients
      && !this.isMasterLeadAccount;
  }

  public get isExceptOnlyLocations(): boolean {
    return this.selectedPipelineId === stages.Pipelines.ClothierContactClients
      && !this.isMasterLeadAccount
      && !this.isAdminAccount;
  }

  //////////////////////////////// VIEW *ngIF END ////////////////////////////////

  //////////////////////////////// Chips Filters START //////////////////////////////

  onCompaniesAutocompleteRequest(event) {
    this.dealService.getFilterDataAutocomplete(FilterType.Company, event.searchString, event.skip, event.take).then(response => {
      if (event.isNotLetter == this.customSelectCompany.isNotLetter) {
        this.customSelectCompany.setData(response);
      }
    });
  }

  onLocationsAutocompleteRequest(event) {
    this.dealService.getFilterDataAutocomplete(FilterType.Location, event.searchString, event.skip, event.take).then(response => {
      if (event.isNotLetter == this.customSelectLocation.isNotLetter) {
        this.customSelectLocation.setData(response);
      }
    });
  }

  onPositionsAutocompleteRequest(event) {
    this.dealService.getFilterDataAutocomplete(FilterType.Position, event.searchString, event.skip, event.take).then(response => {
      if (event.isNotLetter == this.customSelectPosition.isNotLetter) {
        this.customSelectPosition.setData(response);
      }
    });
  }

  setCompaniesFilterData(event) {
    this.dealService.getFilterData(FilterType.Company, event.skip, event.take).then(response => {
      if (event.isNotLetter == this.customSelectCompany.isNotLetter) {
        this.customSelectCompany.setData(response);
      }
    });
  }

  setLocationsFilterData(event) {
    this.dealService.getFilterData(FilterType.Location, event.skip, event.take).then(response => {
      if (event.isNotLetter == this.customSelectLocation.isNotLetter) {
        this.customSelectLocation.setData(response);
      }
    });
  }

  setPositionsFilterData(event) {
    this.dealService.getFilterData(FilterType.Position, event.skip, event.take).then(response => {
      if (event.isNotLetter == this.customSelectPosition.isNotLetter) {
        this.customSelectPosition.setData(response);
      }
    });
  }

  public getFilteredCompanies(): ChipsSelectFilterDataModel[] {
    return (this.companies) ? this.companies.map((item) => new ChipsSelectFilterDataModel(item)) : null;
  }

  public getFilteredPositions(): ChipsSelectFilterDataModel[] {
    return (this.positions) ? this.positions.map((item) => new ChipsSelectFilterDataModel(item)) : null;
  }

  public getFilteredLocations(): ChipsSelectFilterDataModel[] {
    return (this.locations) ? this.locations.map((item) => new ChipsSelectFilterDataModel(item)) : null;
  }

  public getPositions(data: ChipsSelectFilterModel[]) {
    this.positions = data;
  }

  public getCompanies(data: ChipsSelectFilterModel[]) {
    this.companies = data;
  }

  public getLocations(data: ChipsSelectFilterModel[]) {
    this.locations = data;
  }

  //////////////////////////////// Chips Filters END //////////////////////////////
}
