import { UserContextService } from './../../../core/services/user-context.service';
import { UserStoreService } from './../../../core/services/stores/user-store.service';
import { GlobalConstants } from './../../../core/global-constants';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import * as stages from '../../../../assets/stages_new_prospespects';
import { RolesEnum } from 'src/app/core/enums/RolesEnum';
import { UsersService } from 'src/app/core/services/users.service';

@Component({
  selector: 'pd-field-user-id',
  templateUrl: './field-user-id.component.html',
  styleUrls: ['./field-user-id.component.scss']
})
export class FieldUserIdComponent implements OnInit {

  public owner: any;
  @Output() userIdChange = new EventEmitter<string>();
  public isEdit = false;
  public users = [];
  public filteredUsers = [];
  public currentUser: number;

  constructor(private usersService: UsersService, private userContextService: UserContextService) { }

  async ngOnInit(): Promise<void> {
    this.users = await this.usersService.getUsers();
    this.filteredUsers = this.users.filter(item => item.role_id !== RolesEnum.EA && item.role_id !== RolesEnum.EaCaller);
    this.currentUser = this.userContextService.user.value.id;
  }

  filterOwnersByStage(stageId) {
    let allowedRoles = [];
    const stageName = stages.STAGES_NEW_PROSPECTS_DICT.filter(stage => stage.id === stageId)[0].name;
    let prospectsStagesByRoles = { ...stages.PROSPECTS_BY_ROLES };
    prospectsStagesByRoles[RolesEnum[RolesEnum.MasterLead]].push(...stages.STAGES_NEW_PROSPECTS_DICT.filter(s => s.id === GlobalConstants.ALL_STAGES.ColdLOOKUP || s.id === GlobalConstants.ALL_STAGES.LinkedinLOOKUP).map(el => el.name));
    allowedRoles = Object.entries(prospectsStagesByRoles).reduce((result, current) => {
      if (current[1].some(stage => stage === stageName)) {
        result.push(RolesEnum[current[0]]);
      }
      return result;
    }, []);
    this.filteredUsers = this.users.filter(user => allowedRoles.some(roleId => roleId === user.role_id) && (user.role_id !== RolesEnum.EA && user.role_id !== RolesEnum.EaCaller));
  }

  /**
    * Change edit
    * in deal
    * @param {any} item value of owner
    * @memberof FieldUserIdComponent
    */

  public changeEdit(item: any) {
    this.isEdit = false;
    this.owner = item;
    this.userIdChange.emit(this.owner);
  }

  /**
    * Add value
    * open edit
    * @memberof FieldUserIdComponent
    */

  public addValue() {
    this.isEdit = true;
    this.users.forEach(element => {
      if (element.id === this.currentUser) {
        this.owner = element.id;
        this.userIdChange.emit(this.owner);
      }
    })
  }

}
