import { GlobalConstants } from 'src/app/core/global-constants';
import { Component, ViewChild, Input } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { Pipelines, STAGES_FOR_ALL_PIPELINES } from "../../../../assets/stages_new_prospespects";
import { DatePipe } from "@angular/common";
import { DealPersonMap, PushPinPerson as PushpinPerson } from "src/app/core/models/DealPersonMap";
import { DealService } from "src/app/core/services/deals.service";
import { MapSortTypes } from "src/app/core/enums/MapSortTypes"

declare var Microsoft: any;

@Component({
  selector: 'bing-map',
  templateUrl: './custom-map.component.html',
  styleUrls: ['./custom-map.component.scss']
})

export class MapComponent {
  @ViewChild('bingMap') bingMap: { nativeElement: string | HTMLElement; };
  public km: number = 1;
  public minKm: number = 1;
  public maxKm: number = 5;

  public map: Microsoft.Maps.Map;
  public currentPersonLoc: Microsoft.Maps.Location;
  public persons: Array<DealPersonMap>;
  public filterPerson: Array<DealPersonMap>;
  public infoboxes: Array<Microsoft.Maps.Infobox>;
  public currentPerson: DealPersonMap;

  public isShowFillMessage: boolean = false;
  public isSpinner: boolean = true;

  public pushpins: Array<PushpinPerson> = new Array<PushpinPerson>();
  public filterPushpins: Array<PushpinPerson> = new Array<PushpinPerson>();

  public isShowHomeAddress: boolean = true;
  public isShowCompanyAddress: boolean = true;
  public isShowLifetimeSpend: boolean = false;

  public isClient: boolean = true;
  public isMeeting: boolean = true;
  public isDeliveryMeeting: boolean = true;
  public isOrderShipped: boolean = true;
  public isFloat: boolean = true;
  public isReferral: boolean = true;

  public isClientExist: boolean = true;
  public isMeetingExist: boolean = true;
  public isDeliveryMeetingExist: boolean = true;
  public isOrderShippedExist: boolean = true;
  public isFloatExist: boolean = true;
  public isReferralExist: boolean = true;

  public isHomeFocus: boolean = false;
  public MapSortTypes = MapSortTypes; 
  public sortMethod: MapSortTypes = MapSortTypes.Distance;

  @Input()
  public dealId: number;
  @Input()
  public filterUserIds = [];
  @Input()
  public latitude: number;
  @Input()
  public longitude: number;
  @Input()
  public isSearchByGeocode: boolean = false;

  datePipe = new DatePipe('en-US');

  constructor(public dealService: DealService) {
  }

  ngAfterViewInit() {// after the view completes initializaion, create the map
    let data = {
      filterUserIds: this.filterUserIds,
      dealId: this.dealId,
      latitude: this.latitude,
      longitude: this.longitude,
      Km: this.maxKm
    };

    this.dealService.updateProspectsGeocode(data).then(() => {
      if (this.isSearchByGeocode) {
        this.dealService.getDealsNear(data).then(result => {
          this.afterResponse(result);
        },
          () => { }
        );
      }
      else {
        this.dealService.getDealsNearByDealId(this.dealId, this.maxKm).then(result => {
          this.afterResponse(result);
        },
          () => { }
        );
      }
    },
      () => { }
    );
  }

  private afterResponse(result) {
    this.persons = result;
    this.persons.forEach(item => {
      item.meetingDate = this.setMeetingDate(item);
    });

    if (this.isSearchByGeocode) {
      let geocodePerson = new DealPersonMap()

      geocodePerson.personId = 0;
      geocodePerson.dealId = 0;
      geocodePerson.name = 'Searched Address';
      geocodePerson.latitude = this.latitude;
      geocodePerson.longitude = this.longitude;
      geocodePerson.distanceCompanyToCompany = 0;

      this.currentPerson = geocodePerson;
      this.persons.push(this.currentPerson);     
    }
    else {
      this.currentPerson = this.persons.find(p => p.dealId == this.dealId);
    }

    if (!this.currentPerson) {
      this.isShowFillMessage = true;
      this.isSpinner = false;
      return;
    }

    this.map = new Microsoft.Maps.Map(this.bingMap.nativeElement, {
      credentials: environment.bingMapApiKey
    });

    if (!this.currentPerson.latitude || !this.currentPerson.longitude) {
      this.currentPersonLoc = new Microsoft.Maps.Location(this.currentPerson.homeLatitude, this.currentPerson.homeLongitude);
      this.isHomeFocus = true;
    }
    else {
      this.currentPersonLoc = new Microsoft.Maps.Location(this.currentPerson.latitude, this.currentPerson.longitude);
      this.isHomeFocus = false;
    }

    this.map.setView({ zoom: 13, center: this.currentPersonLoc });

    this.setStageAviability();
    this.fillPushpins();
    this.buildMap(null, this.isHomeFocus);

    this.isSpinner = false;
  }

  public onStageCheckChange() {
    this.buildMap();
  }

  public onHomeAddressesCheckChange() {
    if (!this.isShowHomeAddress) {
      this.isShowCompanyAddress = true;
    }
    this.buildMap();
  }

  public onCompanyAddressesCheckChange() {
    if (!this.isShowCompanyAddress) this.isShowHomeAddress = true;
    else this.isShowHomeAddress = false;

    this.buildMap();
  }
  
  onSortByLifetimeSpendChange(): void {
    if (this.isShowLifetimeSpend) {
    this.isShowLifetimeSpend = true;
    } 
    this.buildMap();
  }

  public onSelectChange(person: DealPersonMap, isHome: boolean) {
    if (person == this.currentPerson) {
      this.isHomeFocus = isHome;
      if (!this.currentPerson.homeLatitude || !this.currentPerson.homeLongitude) {
        this.isHomeFocus = false;
      }

      if (!this.currentPerson.latitude || !this.currentPerson.longitude) {
        this.isHomeFocus = true;
      }

      if (this.isHomeFocus) {
        this.currentPersonLoc = new Microsoft.Maps.Location(this.currentPerson.homeLatitude, this.currentPerson.homeLongitude);
      }
      else {
        this.currentPersonLoc = new Microsoft.Maps.Location(this.currentPerson.latitude, this.currentPerson.longitude);
      }
    }
    this.buildMap(person, isHome);
  }

  public onDistanceChange() {
    this.buildMap();
  }

  public getDealUrl(person: DealPersonMap) {
    return window.location.origin + "/deals/" + person.dealId;
  }

  private buildMap(selectedPerson: DealPersonMap = null, isHome: boolean = false) {
    this.map.entities.clear(); //clear map pushpins

    if (this.infoboxes) {
      this.infoboxes.forEach(i => i.setMap(null)); //clear map infoboxes
    }

    this.drawCircle();
    this.fillMapEnteties(selectedPerson, isHome);
    this.filterPerson.sort((a, b) => a.mapNumber - b.mapNumber);
  }

  private createInfobox(loc, groupedPerson: Array<DealPersonMap>, pushpin) {
    let infoboxes = this.infoboxes;

    var infobox = new (window as any).Microsoft.Maps.Infobox(
      loc,
      {
        description: this.getDescription(groupedPerson),
        visible: false,
        maxHeight: 256,
        maxWidth: 512
      }
    );

    infobox.setMap(this.map);

    Microsoft.Maps.Events.addHandler(pushpin, 'click', () => {
      infoboxes.forEach((ib) => ib.setOptions({ visible: false }));
      infobox.setOptions({ visible: true });
    });

    this.infoboxes.push(infobox);
  }

  private setUserCurrentLocation() {
    let map = this.map;
    navigator.geolocation.getCurrentPosition(function (position) {
      var userLocation = new Microsoft.Maps.Location(position.coords.latitude, position.coords.longitude);

      var pin = new Microsoft.Maps.Pushpin(userLocation, { color: 'blue' });
      map.entities.push(pin);
    });
  }

  private drawCircle() {
    let km = this.km;
    let map = this.map;
    let currentLoc = this.currentPersonLoc;
    Microsoft.Maps.loadModule("Microsoft.Maps.SpatialMath", function () {
      var path = Microsoft.Maps.SpatialMath.getRegularPolygon(currentLoc, km, 36, Microsoft.Maps.SpatialMath.DistanceUnits.Kilometers);
      var poly = new Microsoft.Maps.Polygon(path);
      map.entities.push(poly);
    });
  }

  private fillPushpins() {
    this.persons.forEach(
      (person) => {
        if (person.latitude && person.longitude) {
          let companyPushPins = new PushpinPerson();
          companyPushPins.personId = person.personId;
          companyPushPins.distanceToCompany = person.distanceCompanyToCompany;
          companyPushPins.distanceToHome = person.distanceCompanyToHome;
          companyPushPins.latitude = person.latitude;
          companyPushPins.longitude = person.longitude;
          companyPushPins.isHome = false;
          companyPushPins.stageId = person.stageId;
          companyPushPins.stageName = person.stageName;
          companyPushPins.meetingDate = person.meetingDate;

          this.pushpins.push(companyPushPins);
        }

        if (person.homeLatitude && person.homeLongitude) {
          let homePushPins = new PushpinPerson();
          homePushPins.personId = person.personId;
          homePushPins.distanceToCompany = person.distanceHomeToCompany;
          homePushPins.distanceToHome = person.distanceHomeToHome;
          homePushPins.latitude = person.homeLatitude;
          homePushPins.longitude = person.homeLongitude;
          homePushPins.isHome = true;
          homePushPins.stageId = person.stageId;
          homePushPins.stageName = person.stageName;
          homePushPins.meetingDate = person.meetingDate;

          this.pushpins.push(homePushPins);
        }
      }
    )
  }


  private fillMapEnteties(selectedPerson: DealPersonMap = null, isHome: boolean = false) {
    let mapNumber = 1;
    let pushpins = new Array<Microsoft.Maps.Pushpin>();
    this.infoboxes = new Array<Microsoft.Maps.Infobox>()
    let processedPushpins = new Array<PushpinPerson>();
    let selectedPushpin = new PushpinPerson();
   

    if (this.isHomeFocus) { //build a map based on the selected address type of current person
      this.filterPerson = this.persons
        .filter(f => (f.distanceCompanyToHome != null && f.distanceCompanyToHome <= this.km)
          || (f.distanceHomeToHome != null && f.distanceHomeToHome <= this.km));
      this.filterPerson.sort((a, b) => { //current person only first
        let sortResult = this.sortBeforeDistance(a, b);
        
        if (sortResult == 0) {
          return a.distanceCompanyToHome - b.distanceCompanyToHome;
        }
        
        return sortResult;
      });
    }
    else {
      this.filterPerson = this.persons.filter(f => (f.distanceCompanyToCompany != null && f.distanceCompanyToCompany <= this.km)
        || (f.distanceHomeToCompany != null && f.distanceHomeToCompany <= this.km));
        this.filterPerson.sort((a, b) => { //current person only first
        let sortResult = this.sortBeforeDistance(a, b);
        
        if (sortResult == 0) {
          return a.distanceCompanyToCompany - b.distanceCompanyToCompany
        }
          
        return sortResult;
      });
    } 

    if(MapSortTypes[this.sortMethod] === MapSortTypes[MapSortTypes.LifetimeSpend]) {
      this.filterPerson.sort((a, b) => this.comparePersonsByLifetimeSpend(a, b));
    } 

    this.filterPerson.forEach((person) => { person.mapNumber = mapNumber; mapNumber++; })

    this.filterPerson = this.filterPerson.filter(f => f.personId === this.currentPerson.personId || this.stageFilter(f));

    this.filterPushpins = this.pushpins
      .filter(f => this.filterPerson.find(fi => fi.personId == f.personId)
        && ((f.isHome == this.isShowHomeAddress) || (!f.isHome == this.isShowCompanyAddress))); //filter by checkboxes

    this.filterPerson = this.filterPerson.filter(f => this.filterPushpins.find(fi => fi.personId == f.personId));

    selectedPushpin = this.filterPushpins.find(f => selectedPerson && f.personId == selectedPerson.personId && f.isHome == isHome);

    this.filterPushpins.forEach(
      (item) => {
        if (processedPushpins.indexOf(item) === -1) {
          var loc = new Microsoft.Maps.Location(item.latitude, item.longitude);
          var pushpin = new Microsoft.Maps.Pushpin(loc);

          let groupedPushpins = this.filterPushpins.filter(p => this.getDistance(item.latitude, item.longitude, p.latitude, p.longitude, 'K') <= 0.005); //grouping if same build
          processedPushpins = processedPushpins.concat(groupedPushpins);

          let groupedPerson = this.filterPerson.filter(f => groupedPushpins.find(fi => fi.personId == f.personId));

          pushpin.setOptions({ title: groupedPerson.map(g => g.mapNumber).join('/') });

          let iconColor = "";
          let iconFigure = "";

          if (groupedPerson.indexOf(this.currentPerson) !== -1 && !item.isHome) {
            iconFigure = 'circle';
          }
          else if (groupedPerson.indexOf(this.currentPerson) !== -1 && item.isHome) {
            iconFigure = 'square';
          }
          else if (item.isHome) {
            iconFigure = 'triangle';
          } else {
            iconFigure = 'rhombus';
          }

          if (this.isSearchByGeocode && groupedPerson.indexOf(this.currentPerson) !== -1) {
            iconColor = 'orange'
          }
          else if (groupedPerson.find(f => {
            let stage = STAGES_FOR_ALL_PIPELINES.find(x => x.id == f.stageId)
            return ((stage.pipeline_id === Pipelines.Clients
              || stage.pipeline_id === Pipelines.OpenOrders
              || stage.pipeline_id === Pipelines.ClothierContactClients)
              && (this.clientCondition(f.stageId)))
          })) {
            iconColor = 'orange'
          }
          else if (groupedPerson.find(f => this.stageMeetingCondition(f.stageId))) {
            iconColor = 'red'
          }
          else if (groupedPerson.find(f => this.stageDeliveryMeetingCondition(f.stageId))) {
            iconColor = 'blue'
          }
          else if (groupedPerson.find(f => this.stageOrderShippedCondition(f.stageId))) {
            iconColor = 'light-green'
          }
          else if (groupedPerson.find(f => (this.stageReferralCondition(f.stageId)))) {
            iconColor = 'green'
          }
          else if (groupedPerson.find(f => (this.stageFloatCondition(f.stageId)))) {
            iconColor = 'pink';
          }

          pushpin.setOptions({ icon: '../../assets/pushpins/' + iconColor + '-' + iconFigure + '-pushpin.png' });

          if (selectedPushpin && groupedPushpins.indexOf(selectedPushpin) !== -1) {
            pushpin.setOptions({ icon: '../../assets/pushpins/selected-pushpin.png' });
            this.map.setView({ center: pushpin.getLocation() });
          }

          pushpins.push(pushpin);

          this.createInfobox(loc, groupedPerson, pushpin);
        }
      }
    )

    this.map.entities.add(pushpins);
  }

  private sortBeforeDistance(a, b) {
    if (a == this.currentPerson) { return -1; }
    if (b == this.currentPerson) { return 1; }

    let aMeetingDate = new Date(a.meetingDate);
    let bMeetingDate = new Date(b.meetingDate);

    if (a.meetingDate && !b.meetingDate) {
      return -1;
    }
    if (!a.meetingDate && b.meetingDate) {
      return 1;
    }
    if (a.meetingDate && b.meetingDate) {
      return bMeetingDate.getTime() - aMeetingDate.getTime()
    }

    return 0;
  }

  private getDescription(persons: Array<DealPersonMap>) {
    let description = "";
    persons.forEach((person) => {
      if (this.isSearchByGeocode && this.currentPerson.personId == person.personId) {
        description += person.mapNumber + " - " + person.name + "";
      }
      else {
        description += person.mapNumber + " - <a href='" + this.getDealUrl(person) + "' target='_blank'>" + person.name + "</a>" +
          '<p>' +
          '<i>Stage</i> - ' + person.stageName + '<br/>' +
          ((person.meetingDate) ? '<i>Meeting Date</i> - ' + person.meetingDate + '<br/>' : '') +
          '<i>Phones</i> - ' + person.phones.map(m => m.value + " " + "(" + m.label + ")").join('<br/>') + '<br/>' +
          '<i>Emails</i> - ' + person.emails.map(m => m.value + " " + "(" + m.label + ")").join('<br/>') + '<br/>' +
          '<i>Company</i> - ' + person.company + '<br/>' +
          '<i>Company Address</i> - ' + ((person.address) ? person.address : '') + '<br/>' +
          '<i>Home Address</i> - ' + ((person.homeAddress) ? person.homeAddress : '') + '<br/>' +
          '<i>Lifetime Spend </i> - $' + person.clientLifetimeSpend + '<br/>' +
          '</p>'
      }
    });

    return description;
  }

  private getDistance(lat1, lon1, lat2, lon2, unit) {
    if ((lat1 == lat2) && (lon1 == lon2)) {
      return 0;
    }
    else {
      var radlat1 = Math.PI * lat1 / 180;
      var radlat2 = Math.PI * lat2 / 180;
      var theta = lon1 - lon2;
      var radtheta = Math.PI * theta / 180;
      var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = dist * 180 / Math.PI;
      dist = dist * 60 * 1.1515;
      if (unit == "K") { dist = dist * 1.609344 }
      if (unit == "N") { dist = dist * 0.8684 }
      return dist;
    }
  }

  private comparePersonsByLifetimeSpend(a, b): number {
    if (a === this.currentPerson) { return -1; }
    if (b === this.currentPerson) { return 1; }
    
    return b.clientLifetimeSpend - a.clientLifetimeSpend;
  } 

  private stageFilter(dealPersonMap: DealPersonMap) {
    let stage = STAGES_FOR_ALL_PIPELINES.find(x => x.id === dealPersonMap.stageId)

    if (this.isMeeting && (
      this.stageMeetingCondition(stage.id))) {
      return true;
    }
    else if (this.isDeliveryMeeting && (
      this.stageDeliveryMeetingCondition(stage.id))) {
      return true;
    }
    else if (this.isOrderShipped && (
      this.stageOrderShippedCondition(stage.id))) {
      return true;
    }
    else if (this.isFloat &&
      this.stageFloatCondition(stage.id)) {
      return true;
    } else if (this.isReferral && (
      this.stageReferralCondition(stage.id))) {
      return true;
    } else if (this.isClient
      && (this.clientCondition(stage.id))
      && (stage.pipeline_id === Pipelines.Clients
        || stage.pipeline_id === Pipelines.OpenOrders
        || stage.pipeline_id === Pipelines.ClothierContactClients
        || stage.id === GlobalConstants.ALL_STAGES.DraftOrder
        || stage.id === GlobalConstants.ALL_STAGES.DraftConfirmed)) {
      return true;
    }
    return false;
  }

  private setStageAviability() {
    this.isClientExist = !!this.persons.find(f => {
      let stage = STAGES_FOR_ALL_PIPELINES.find(x => f.stageId && x.id === f.stageId)
      return f.personId !== this.currentPerson.personId
        && (this.clientCondition(f.stageId)
          && (stage.pipeline_id === Pipelines.Clients
            || stage.pipeline_id === Pipelines.OpenOrders
            || stage.pipeline_id === Pipelines.ClothierContactClients
            || stage.id === GlobalConstants.ALL_STAGES.DraftOrder
            || stage.id === GlobalConstants.ALL_STAGES.DraftConfirmed))
    })

    this.isMeetingExist = !!this.persons.find(f => f.personId !== this.currentPerson.personId &&
      (this.stageMeetingCondition(f.stageId)));

    this.isDeliveryMeetingExist = !!this.persons.find(f => f.personId !== this.currentPerson.personId &&
      (this.stageDeliveryMeetingCondition(f.stageId)));

    this.isOrderShippedExist = !!this.persons.find(f => f.personId !== this.currentPerson.personId &&
      (this.stageOrderShippedCondition(f.stageId)));

    this.isReferralExist = !!this.persons.find(f => f.personId !== this.currentPerson.personId &&
      (this.stageReferralCondition(f.stageId)));

    this.isFloatExist = !!this.persons.find(f => f.personId !== this.currentPerson.personId &&
      (this.stageFloatCondition(f.stageId)));

    this.isClient = this.isClientExist;
    this.isMeeting = this.isMeetingExist;
    this.isDeliveryMeeting = this.isDeliveryMeetingExist;
    this.isOrderShipped = this.isOrderShippedExist;
    this.isReferral = this.isReferralExist;
    this.isFloat = this.isFloatExist;
  }

  setMeetingDate(person) {
    let stageId = person.stageId;
    if (stageId === GlobalConstants.ALL_STAGES.DeliveryMeeting
      || stageId === GlobalConstants.ALL_STAGES.Meeting
      || stageId === GlobalConstants.ALL_STAGES.EACurrentMeeting
      || stageId === GlobalConstants.ALL_STAGES.CurrentMeeting
    ) {
      return this.datePipe.transform(new Date(person.meetingDate), "dd-MMM-yyyy h:mm a")
    }
    return null;
  }

  clientCondition(stageId) {
    return stageId !== GlobalConstants.ALL_STAGES.DeliveryMeeting &&
      stageId !== GlobalConstants.ALL_STAGES.OrderShipped &&
      stageId !== GlobalConstants.ALL_STAGES.Meeting &&
      stageId !== GlobalConstants.ALL_STAGES.EACurrentMeeting &&
      stageId !== GlobalConstants.ALL_STAGES.CurrentMeeting;
  }

  stageMeetingCondition(stageId) {
    return stageId === GlobalConstants.ALL_STAGES.Meeting ||
      stageId === GlobalConstants.ALL_STAGES.EACurrentMeeting ||
      stageId === GlobalConstants.ALL_STAGES.CurrentMeeting;
  }

  stageDeliveryMeetingCondition(stageId) {
    return stageId === GlobalConstants.ALL_STAGES.DeliveryMeeting
  }

  stageFloatCondition(stageId) {
    return stageId === GlobalConstants.ALL_STAGES.Float
  }

  stageOrderShippedCondition(stageId) {
    return stageId === GlobalConstants.ALL_STAGES.OrderShipped
  }

  stageReferralCondition(stageId) {
    return stageId === GlobalConstants.ALL_STAGES.RefVM1
      || stageId === GlobalConstants.ALL_STAGES.RefVM2
      || stageId === GlobalConstants.ALL_STAGES.RefVM3
      || stageId === GlobalConstants.ALL_STAGES.Referral;
  }

  onSortChange(event: any): void {
    this.sortMethod = event.value;
    this.buildMap();
  }
}