import { Injectable } from "@angular/core";
import { TwilioStoreService } from "./twilio-store.service";
import { TwilioApiService } from "./twilio-api.service";
import { Message } from "../../models/chat-models/Message";
import { Observable } from "rxjs";
import { Chat } from "../../models/chat-models/Chat";

@Injectable()
export class TwilioEffectsService {
    statusPriority = [
        { name: "accepted", priority: 0 },
        { name: "queued", priority: 1 },
        { name: "sent", priority: 2 },
        { name: "delivered", priority: 3 },
        { name: "read", priority: 4 },
        { name: "undelivered", priority: 5 },
        { name: "failed", priority: 6 },
    ];

    constructor(
        private twilioStoreService: TwilioStoreService
        , private twilioApiService: TwilioApiService
    ) {
    }

    public async sendWhatsappMessage(toPhone: string, message: string, file): Promise<any> {
        return await this.twilioApiService.sendWhatsappMessage(toPhone, message, file);
    }

    public async sendSmsMessage(toPhone: string, message: string, file): Promise<any> {
        return await this.twilioApiService.sendSmsMessage(toPhone, message, file);
    }

    public getLoaderObservable(): Observable<boolean> {
        return this.twilioStoreService.getLoaderObservable();
    }

    public setLoader(loader: boolean): void {
        this.twilioStoreService.setLoader(loader);
    }

    public getMessagesObservable(): Observable<Message[]> {
        return this.twilioStoreService.getMessagesObservable();
    }

    public async loadMessages(chatId: string): Promise<Message[]> {
        if (!chatId) {
            return null;
        }

        const messages = await this.twilioApiService.getMessagesByChatId(chatId);
        this.twilioStoreService.setMessages(messages);

        return messages;
    }

    public getNewMessageObservable(): Observable<Message> {
        return this.twilioStoreService.getNewMessageObservable();
    }

    public setNewMessage(message: Message): void {
        const activeChat = this.twilioStoreService.getActiveChatSnapshot();

        if (!activeChat || activeChat.id !== message.chatId) {
            return;
        }

        const messages = this.twilioStoreService.getMessagesSnapshot();
        messages.push(message);
        this.twilioStoreService.setMessages(messages);
        this.twilioStoreService.setNewMessage(message);
    }

    public updateMessageStatus(message: Message): void {
        const messages = this.twilioStoreService.getMessagesSnapshot();
        const index = messages.findIndex(m => m.sid === message.sid);

        if (index !== -1) {
            let existMessage = messages[index];
            this.updateMessageStatusByPriority(existMessage, message);
            this.twilioStoreService.setMessages(messages);
        }

        const chatList = this.twilioStoreService.getChatListSnapshot();
        const chat = chatList.find(c => c.id === message.chatId);

        if (chat) {
            let existMessage = chat.lastMessage;
            this.updateMessageStatusByPriority(existMessage, message);
            this.twilioStoreService.setChatList(chatList);
        }
    }

    private updateMessageStatusByPriority(existMessage: Message, message: Message): void {
        const oldPriority = this.statusPriority.find(s => s.name === existMessage.status);
        const newPriority = this.statusPriority.find(s => s.name === message.status);

        if (oldPriority && newPriority) {
            if (newPriority.priority > oldPriority.priority) {
                existMessage.status = message.status;
            }
        }
        else {
            existMessage.status = message.status;
        }

        existMessage.errorMessage = message.errorMessage;
    }

    public async setActiveChat(chat: Chat): Promise<void> {
        const oldActiveChat = this.twilioStoreService.getActiveChatSnapshot();

        if (!chat) {
            return null;
        }

        if (!oldActiveChat || oldActiveChat.id !== chat.id) {
            this.twilioStoreService.setActiveChat(chat);
            await this.loadMessages(chat.id);
        }
        return null;
    }

    public getActiveChatObservable(): Observable<Chat> {
        return this.twilioStoreService.getActiveChatObservable();
    }

    public async loadChatList(filter): Promise<Chat[]> {
        const chatList = await this.twilioApiService.getChats(filter);
        this.twilioStoreService.setChatList(chatList);
        return chatList;
    }

    public getChatListObservable(): Observable<Chat[]> {
        return this.twilioStoreService.getChatListObservable();
    }

    public setChatList(chat: Chat[]): void {
        this.twilioStoreService.setChatList(chat);
    }


}
