import { Modeler3dService } from 'src/app/core/services/3d-modeler.service';
import { GlobalConstants } from './../../core/global-constants';
import { Component, ViewChild, OnInit, ElementRef } from '@angular/core';

@Component({
  selector: 'pd-3d-modeler-fabric-upload',
  templateUrl: './3d-modeler-fabric-upload.component.html',
  styleUrls: ['./3d-modeler-fabric-upload.component.scss']
})


export class ModelerFabricUploadComponent implements OnInit {
  public outfitParts = [];
  public lines = [];

  @ViewChild('fileInput') fileInput: ElementRef;
  public uploadFileResult: any;

  public selectedOutfitPart;
  public selectedLine;
  public selectedFiles

  public isShowValidationMessages = false;
  public isShowProgressMessages = false;
  public uploadMessage;
  public spinner = false;

  public tasksRoute = GlobalConstants.ROUTE_NAMES.Tasks;

  constructor(private modeler3dService: Modeler3dService) { }

  ngOnInit() {
    this.modeler3dService.getLines().then((result) => {
      this.lines = result;
    });

    this.modeler3dService.getOutfitParts().then((result) => {
      this.outfitParts = result;
    });
  }

  public onFileSelected(event) {
    this.uploadMessage = "";
    this.uploadFileResult = null
    this.selectedFiles = event.target.files;
  }

  public onUploadFile() {
    if (this.validation()) {
      this.spinner = true;
      this.isShowProgressMessages = true;
      this.modeler3dService.uploadFabricsFiles(this.selectedFiles, this.selectedOutfitPart, this.selectedLine).then((result) => {
        this.spinner = false;
        this.uploadMessage = result[0].message;
        this.fileInput.nativeElement.value = "";
        this.isShowValidationMessages = false;
        this.isShowProgressMessages = false;
      });
    }
  }

  public validation() {
    this.isShowValidationMessages = true;

    return this.selectedOutfitPart && this.lineValidation() && this.fileValidation();
  }

  public lineValidation() {
    if (this.selectedOutfitPart == 1
      || this.selectedOutfitPart == 2
      || this.selectedOutfitPart == 3
      || this.selectedOutfitPart == 4) {
      return this.selectedLine;
    }
    return true;
  }

  public getProgressMessage() {
    return Modeler3dService.uploadedCount + "/" + Modeler3dService.filesToUploadCount;
  }

  public fileValidation() {
    if (this.selectedFiles) {
      let array = Array.from(this.selectedFiles);
      return array.length > 0 && !array.find((f: any) => {
        return f.type != "image/jpeg"
      });
    }
    return false;
  }
}
