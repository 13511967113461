import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import * as stages from '../../../assets/stages_new_prospespects';
import { DragulaService } from 'ng2-dragula';
import { Deal } from 'src/app/core/models/Deal';
import { CommonConnections } from 'src/app/core/models/Participant';
import { DealService } from 'src/app/core/services/deals.service';
import { CustomContactsModalComponent } from 'src/app/shared/custom/custom-contacts-modal/custom-contacts-modal.component';

@Component({
  selector: 'pd-master-lead',
  templateUrl: './master-lead.component.html',
  styleUrls: ['./master-lead.component.scss']
})
export class MasterLeadComponent implements OnInit { //TODO: remove
  public spinner = true;
  public start: number = 0;
  public deals: Deal[] = [];
  public minStage: any;
  public more_items_in_collection: boolean;
  public array: Deal[][] = [];
  public activeStages = [];
  public lazyLoading: boolean;
  public participants: CommonConnections;
  public masterLeadDealsCountByStage = {};
  @ViewChild('container') container: any;
  @ViewChild('contactsModal') contactsModal: CustomContactsModalComponent;

  @Output() moveContactsModal = 'out';
  @Output() editContactsDeal: Deal;

  @Output() loadingDeals: EventEmitter<any> = new EventEmitter<any>();

  public isShowError = false;

  constructor(public dealService: DealService, public dragulaService: DragulaService) {
  }

  ngOnInit() {
    this.activeStages = stages.MASTER_LEAD_DEALS;

    if (!this.dragulaService.find('first-bag')) {
      this.dragulaService.createGroup('first-bag', {
        moves: function (el: any, container: any, handle: any): any {
          if (el.classList.contains('hidden-deals')) {
            return false;
          }
          return true;
        },
        removeOnSpill: false
      });
    }

    this.dragulaService.drop().subscribe((args: any) => {
      const [bagName, el, target, source] = args;
      const dealId = el.dataset.id;
      const stageId = target.dataset.id;
      const previousLabeledStageId = target.dataset.previousLabeledStageId;
      const previousStageId = source.dataset.id;
      if (stageId !== previousStageId) {
        if (this.array[stageId].findIndex(d => d.id === dealId) === -1) {
          const index = this.array[previousStageId].findIndex(d => d.id === dealId);
          if (index !== -1) {
            this.array[stageId].push(this.array[previousStageId][index]);
            this.array[previousStageId].slice(index, 1);
          }
        }
        let isDashboardView = true;
        this.dealService.updateStage(dealId, stageId, previousStageId, previousLabeledStageId, isDashboardView, null, null, null, null).then(result => { //todo
          if (result.is_need_to_reload) {
            this.start = 0;
            this.getMasterLeadDeals();
            this.lazyLoading = true;
          } else {
            const index = this.array[result.stage_id].findIndex(d => d.id === result.id);
            if ((this.array[result.stage_id])[index]) {
              (this.array[result.stage_id])[index].is_rotting = false;
              (this.array[result.stage_id])[index].stage_order_nr = result.stage_order_nr;
              (this.array[result.stage_id])[index].stage_id = result.stage_id;
            }
            this.changeStageCounter(previousStageId, result.stage_id)
          }
        });
      }
    });
    this.getMasterLeadDeals();
  }

  /**
   * Get master lead deals
   * from API
   * @memberof MasterLeadComponent
   */
  private groupDeals(deals: Deal[]): Deal[][] {
    const result = [];
    for (let i = 0; i < deals.length; i++) {
      if (result[deals[i].stage_id] === undefined) {
        result[deals[i].stage_id] = deals.filter(d => d.stage_id === deals[i].stage_id);
      }
    }

    return result;
  }

  public getMasterLeadDeals() {
    this.dealService.getMasterLeadDeals(this.start, 30).then(result => {
      this.masterLeadDealsCountByStage = {}
      result.additional_data.deals_count_by_stage.forEach(item => this.masterLeadDealsCountByStage[item.stageId] = item.count)
      this.deals = result.data;
      this.minStage = result.additional_data.min_stage;
      this.start = result.additional_data.pagination.next_start;
      this.more_items_in_collection = result.additional_data.pagination.more_items_in_collection;
      this.loadingDeals.emit(result.additional_data);

      this.array = this.groupDeals(result.data);;
      this.group(this.activeStages.length, this.array);
      this.isShowError = false;
      this.spinner = false;
      this.lazyLoading = false;
    },
      err => {
        // this.isShowError = true;
        // this.spinner = false;
      }
    );
  }


  private changeStageCounter(previousStageId, currentStageId) {
    if (this.masterLeadDealsCountByStage[previousStageId] > 0) {
      this.masterLeadDealsCountByStage[previousStageId]--;
    }
    this.masterLeadDealsCountByStage[currentStageId] = this.masterLeadDealsCountByStage[currentStageId] === undefined ?
      1 : (this.masterLeadDealsCountByStage[currentStageId] + 1);
  }

  /**
   * Group
   * persons by column id
   * @param {any} data array column length
   * @param {any} data1 array persons
   * @memberof MasterLeadComponent
   */

  public group(data, data1) {
    for (let i = 0; i < this.activeStages.length; i++) {
      if (this.activeStages[i] !== undefined) {
        const id = this.activeStages[i].id;
        if (!data1[id]) {
          this.array[id] = [];
        }
      }
    }
  }

  /**
   * Do something
   * add lazy loading
   * @param {any} event scrolling
   * @memberof MasterLeadComponent
   */

  public loadingMoreDeals() {
    if (this.more_items_in_collection === true) {
      this.lazyLoading = true;
      this.dealService.getMasterLeadDeals(this.start, 20).then(result => {
        setTimeout(() => {
          this.deals = result.data;
          this.more_items_in_collection = result.additional_data.pagination.more_items_in_collection;
          this.loadingDeals.emit(this.more_items_in_collection);
          this.start = result.additional_data.pagination.next_start;
          const group = this.groupDeals(this.deals,);
          for (let i = 0; i < this.activeStages.length; i++) {
            if (this.activeStages[i] !== undefined) {
              const colIndex = this.activeStages[i].id;
              const groups = group[colIndex];
              if (groups !== undefined) {
                this.array[colIndex] = this.array[colIndex].concat(group[colIndex]);
              }
            }
          }
          this.lazyLoading = false;
        }, 300);
      },
        err => { }
      );
    } else {
      this.lazyLoading = false;
    }
  }

  /**
    * Open linkedin
    * @memberof MasterLeadComponent
    */

  public openlinkedIn(item: Deal) {
    window.open(item.linkedin_URL, '_blank');
  }

  public openEditingContactsModal(deal: Deal) {
    this.editContactsDeal = deal;
    this.moveContactsModal = 'in';
    this.contactsModal.openModal(deal);
  }

  public closeEditingContactsModal() {
    this.moveContactsModal = 'out';
  }

}
