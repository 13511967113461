<div>
    <div>
        <div class="search-address">
            <div style="max-width: 300px;">
                <pd-custom-select-without-lazy #userFilter (loadDataEvent)="getUsersFilterData()"
                    (selectedItemEvent)="onUserSelectedItem($event)"
                    (autoCompleteEvent)="getUsersAutocomplete($event)" [dataLabel]="usersLabel"
                    [startLabel]="usersLabel" [isUserMode]="true" [isMultiple]="true">
                </pd-custom-select-without-lazy>
            </div>
            <div class="stage">
                <pd-custom-select-without-lazy #stageFilter (loadDataEvent)="getStageFilterData()"
                    (autoCompleteEvent)="getStageAutocomplete($event)" [dataLabel]="stageLabel"
                    [startLabel]="stageLabel" [isMultiple]="true">
                </pd-custom-select-without-lazy>
            </div>
            <div class="count">
                <input type="number" mdbInputDirective placeholder="Count (max {{maxCountForSearchAddress}})"
                    [(ngModel)]="searchAddressCount" min="1" max="maxCountForSearchAddress" digitOnly
                    (keydown)="changeCountForSearchAddress($event)" (paste)="pasteCount($event)">
            </div>
        </div>
        <div class="target-buttons">
            <button class="btn btn-info" (click)="submitSearchAddress()" [disabled]="!isSelectAnyUsers || !searchAddressCount
                || searchAddressCount> maxCountForSearchAddress">Submit</button>
            <button class="btn btn-danger waves-light" [routerLink]="['/', tasksRoute]">Cancel</button>
        </div>
    </div>
</div>
<div class="update-info">
    {{updateSearchAddressInfo}}
</div>