@if (paymentResponse) {
<div class="payment-responce-container">
    @if (paymentResponse.transactionMessage === 'Success') {
    <div>
        <img src="../../assets/success-transaction.png" class="transaction-icon">
    </div>
    }
    @if (paymentResponse.transactionMessage !== 'Success') {
    <div>
        <img src="../../assets/error-transaction.png" class="transaction-icon">
    </div>
    }
    Transaction message: {{paymentResponse.transactionMessage}}
</div>
}