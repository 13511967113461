<modal #modal title="Upload Wardrobe Plan Images" secondaryBtnTextKey="Close" primaryBtnTextKey="Upload"
  [displayLaunchButton]="false" (secondaryClick)="close()" (onCompleteAction)="close()"
  [loadingIndicator]="isWardrobeloadLoading1 || isWardrobeloadLoading2" (primaryClick)="upload()"
  [isPrimaryBtnDisabled]="isWardrobeloadErrorValidation1
  || isWardrobeloadErrorValidation2 
  || isWardrobeloadLoading1 
  || isWardrobeloadLoading2 
  || !(fileInput1.value || fileInput2.value)">
  <div class="row">
    <div class="upload-form">
      <form action="" method="post" enctype="multipart/form-data">
        <input class="input" type="file" #fileInput1 ng2FileSelect [uploader]="wardrobeUploader1"
          [accept]="fileAccept" />
        @if (wardrobeImage1 && wardrobeImage1.url) {
        <a [href]='wardrobeImage1.url' target='_blank'>View</a>
        }
      </form>
    </div>
  </div>
  <div class="row alert-row">
    @if (isWardrobeloadErrorValidation1) {
    <div class="alert alert-danger">
      Wrong format of the file, please try again
    </div>
    }
    @if (isWardrobeloadSuccess1) {
    <div class="alert alert-success">
      Images uploaded
    </div>
    }
  </div>
  <div class="row">
    <div class="upload-form">
      <form action="" method="post" enctype="multipart/form-data">
        <input class="input" type="file" #fileInput2 ng2FileSelect [uploader]="wardrobeUploader2"
          [accept]="fileAccept" />
        @if (wardrobeImage2 && wardrobeImage2.url) {
        <a [href]='wardrobeImage2.url' target='_blank'>View</a>
        }
      </form>
    </div>
  </div>
  <div class="row alert-row">
    @if (isWardrobeloadErrorValidation2) {
    <div class="alert alert-danger">
      Wrong format of the file, please try again
    </div>
    }
    @if (isWardrobeloadSuccess2) {
    <div class="alert alert-success">
      Images uploaded
    </div>
    }
  </div>
</modal>