<div class="row content-wrapper">
  <div class="col-5">
    <form>
      <div class="sub-title">
        <h6>Login</h6>
      </div>
      <div class="username">
        <input type="email" placeholder="User Name" [(ngModel)]="username" name="username" required>
      </div>
      <div class="username">
        <input type="password" placeholder="Password" [(ngModel)]="password" name="password" required>
      </div>
      <div class="cancel-login">
        <button type="button" class="btn btn-danger" (click)="cancel()">Cancel</button>
        <button type="button" class="btn btn-success btn-submit" (click)="login()"
          [disabled]="(!username || (username && username.length === 0)) || (!password || (password && password.length === 0)) || spinner">
          @if (spinner) {
          <div class="spinner">
            <mat-spinner [diameter]="20"></mat-spinner>
          </div>
          }
          Login
        </button>
      </div>
    </form>
  </div>
  @if (isAuthenticated) {
  <div>
    isAuthenticated
  </div>
  }
</div>