@if (avatarCollection) {
<div class="tile-container">

  <pd-avatar-seasonal-proposal-tile [avatarCollection]="avatarCollection" (onAddAvatar)="addAvatar($event)">
  </pd-avatar-seasonal-proposal-tile>

  @for (avatar of avatars; track avatar) {

  <pd-avatar-seasonal-proposal-tile #tiles [avatar]="avatar" [avatarCollection]="avatarCollection"
    [isExistingAvatarCollection]="isExistingAvatarCollection" (onEditAvatar)="editAvatar($event)"
    (onDownloadAvatar)="downloadAvatar($event)" (onDeleteAvatar)="deleteAvatar($event)"
    (onRemoveAvatarFromCollection)="removeAvatarFromCollection($event)">
  </pd-avatar-seasonal-proposal-tile>

  }
</div>
}