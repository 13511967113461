import { GlobalConstants } from 'src/app/core/global-constants';
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import * as storedData from '../../../assets/stages_new_prospespects';
import { MultiSelectDataType } from 'src/app/core/enums/MultiSelectDataType';
import { TasksService } from 'src/app/core/services/tasks.service';
import { User } from 'src/app/core/models/UserModel';
import { CustomSelectFilterWithoutLazyComponent } from 'src/app/shared/custom/custom-select-without-lazy/custom-select-without-lazy';
import { UserContextService } from 'src/app/core/services/user-context.service';
import { UsersService } from 'src/app/core/services/users.service';
import { RolesEnum } from 'src/app/core/enums/RolesEnum';
import { Stage } from 'src/app/core/models/Stage';

@Component({
  selector: 'pd-search-address-task',
  templateUrl: './search-address-task.component.html',
  styleUrls: ['./search-address-task.component.scss']
})
export class SearchAddressTaskComponent implements AfterViewInit {
  @ViewChild("userFilter") public customSelectUser: CustomSelectFilterWithoutLazyComponent;
  @ViewChild("stageFilter") public customSelectStage: CustomSelectFilterWithoutLazyComponent;

  public maxCountForSearchAddress = 10000;
  public selectDataTypes = MultiSelectDataType;
  public searchAddressCount: number;
  public updateNumberInfo: string;
  public tasksRoute = GlobalConstants.ROUTE_NAMES.Tasks;
  public updateSearchAddressInfo: string;

  private filteredUserMultiSelect: ReplaySubject<User[]> = new ReplaySubject<User[]>(1);
  private filteredUsers = [];
  private currentUser: User;
  private allFilteredUsers = [];
  public usersLabel: string = 'Users'
  public isSelectAnyUsers: boolean = true;
  public avelableUsers: User[];

  private filteredStageMultiSelect: ReplaySubject<Stage[]> = new ReplaySubject<Stage[]>(1);
  private filteredStage = [];
  private allFilteredStage = [];
  public stageLabel: string = 'All Stages'
  public isSelectAnyStage: boolean = true;

  constructor(
    private taskService: TasksService,
    private userContextService: UserContextService,
    private usersService: UsersService) { }

  async ngAfterViewInit(): Promise<void> {
    this.currentUser = this.userContextService.user.value;
    this.allFilteredUsers = await this.usersService.getUsersByRoles();
    this.setUserFilter();
    this.getUsersFilterData();

    this.allFilteredStage = this.getFilteredStage(
      storedData.STAGES_FOR_ALL_PIPELINES as Stage[],
      this.currentUser,
      this.filteredUsers.filter(x => !x.disabled));
    this.getStageFilterData();
  }

  private getFilteredStage(allStageFeatureItems: Stage[], currentUser: User, selectedUsers: User[]) {
    if (allStageFeatureItems) {
      const role = (selectedUsers && selectedUsers.length > 0 && selectedUsers[0]
        && selectedUsers[0].role_id && RolesEnum[selectedUsers[0].role_id])
        || currentUser && currentUser.role_id && RolesEnum[currentUser.role_id]
        || null;
      const allowedPipelinesIds = [storedData.NewProspectsPipeline.id];
      if (role) {
        if (selectedUsers && selectedUsers.length > 0 && selectedUsers[0].role_id === RolesEnum.MasterLead) {
          return allStageFeatureItems.filter(s => ((storedData.STAGES_BY_ROLES_VISIBILITY[role].some(st => st === s.name)
            || storedData.MASTER_LEAD_DEALS.some(ss => ss.id === s.id))
            && allowedPipelinesIds.some(p => p === s.pipeline_id)));
        }
        return allStageFeatureItems.filter(s => ((storedData.STAGES_BY_ROLES_VISIBILITY[role].some(st => st === s.name))
          && allowedPipelinesIds.some(p => p === s.pipeline_id)));
      }
      else {// check if case is needed
        return allStageFeatureItems.filter(s => (currentUser.is_admin || s.pipeline_id === storedData.NewProspectsPipeline.id)
          && storedData.STAGES_BY_ROLES_VISIBILITY[RolesEnum[currentUser.role_id]].some(st => st === s.name))
      }
    } else {
      return null;
    }
  }

  private setUserFilter() {
    this.avelableUsers = this.allFilteredUsers.filter(i => i.role_id !== RolesEnum.EA && i.role_id !== RolesEnum.EaCaller);

    if (this.currentUser.role_id == RolesEnum.Admin || this.currentUser.role_id == RolesEnum.EA || this.currentUser.role_id == RolesEnum.EaCaller) {
      if (this.currentUser.role_id == RolesEnum.EA || this.currentUser.role_id == RolesEnum.EaCaller) {
        this.avelableUsers = this.allFilteredUsers.filter(x => x.role_id == RolesEnum.Clothier || x.role_id == RolesEnum.Admin);
      }
      else {
        this.avelableUsers = this.avelableUsers.concat(this.allFilteredUsers.filter(x => x.role_id != RolesEnum.MasterLead));
      }
      this.allFilteredUsers.forEach(x => {
        if (x.role_id != RolesEnum.Admin || x.role_id != RolesEnum.Clothier) {
          x.disabled = true
        }
      });
    }
    else {
      this.allFilteredUsers.forEach(x => {
        if (x.role_id != this.currentUser.role_id) {
          x.disabled = true
        }
      });
    }
    this.filteredUsers = this.allFilteredUsers.map(x => x);
  }

  public async submitSearchAddress(): Promise<void> {
    const users = this.customSelectUser.dataArray.map((u) => u.id);
    let stages = this.customSelectStage.dataArray.map((s) => s.id);

    stages = stages.length == 0 ? this.allFilteredStage : this.allFilteredStage.map((s) => s.id);
    if (users && users.length > 0) {
      this.updateNumberInfo = 'Search Addresses Task started';
      this.taskService.searchAddresses(users, stages, this.searchAddressCount);
      this.updateNumberInfo = null;
    }
  }

  public changeCountForSearchAddress(e) {
    if (
      // Allow: Delete, Backspace, Tab, Escape, Enter
      [46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1 ||
      (e.keyCode === 65 && e.ctrlKey === true) || // Allow: Ctrl+A
      (e.keyCode === 67 && e.ctrlKey === true) || // Allow: Ctrl+C
      (e.keyCode === 86 && e.ctrlKey === true) || // Allow: Ctrl+V
      (e.keyCode === 88 && e.ctrlKey === true) || // Allow: Ctrl+X
      (e.keyCode === 65 && e.metaKey === true) || // Cmd+A (Mac)
      (e.keyCode === 67 && e.metaKey === true) || // Cmd+C (Mac)
      (e.keyCode === 86 && e.metaKey === true) || // Cmd+V (Mac)
      (e.keyCode === 88 && e.metaKey === true) || // Cmd+X (Mac)
      (e.keyCode >= 35 && e.keyCode <= 39) // Home, End, Left, Right
    ) {
      return;
    }

    if (
      (e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) &&
      (e.keyCode < 96 || e.keyCode > 105)
    ) {
      e.preventDefault();
    }
  }

  public pasteCount(event: ClipboardEvent) {
    event.preventDefault();
    const pastedInput: string = event.clipboardData
      .getData('text/plain')
      .replace(/\D/g, ''); // get a digit-only string
    document.execCommand('insertText', false, pastedInput);
  }

  public onUserSelectedItem(checked) {
    if (this.customSelectUser.dataArray?.length == 0) {
      this.allFilteredUsers.forEach(x => {
        if (this.avelableUsers.some(u => u.id == x.id)) {
          x.disabled = false;
        }
      });
    }
    if (this.customSelectUser.dataArray?.length == 1) {
      if (this.customSelectUser.dataArray[0].role_id == RolesEnum.Clothier || this.customSelectUser.dataArray[0].role_id == RolesEnum.Admin) {
        this.allFilteredUsers.forEach(x => {
          if (x.role_id != RolesEnum.Clothier && x.role_id != RolesEnum.Admin)
            x.disabled = true;
        });
      }
      else {
        this.allFilteredUsers.forEach(x => {
          if (x.role_id != this.customSelectUser.dataArray[0].role_id)
            x.disabled = true;
        });
      }
    }
    if (checked) {
      if (this.customSelectUser) {
        this.isSelectAnyUsers = this.customSelectUser.dataArray.map((u) => u.id).length > 0;
      }
    }
    else {
      this.isSelectAnyUsers = false;
    }

    this.allFilteredStage = this.getFilteredStage(
      storedData.STAGES_FOR_ALL_PIPELINES as Stage[],
      this.currentUser,
      this.customSelectUser.dataArray);
  }

  public getUsersFilterData() {
    this.filteredUsers = [];
    this.filteredUsers = this.filteredUsers.concat(this.allFilteredUsers);
    this.filteredUserMultiSelect.next(this.allFilteredUsers.slice());
    if (this.customSelectUser) {
      const savedFilter = JSON.parse(localStorage.getItem('filterUsers'));
      if (savedFilter && savedFilter.length > 0) {
        this.allFilteredUsers.forEach(x => {
          if (x.role_id == savedFilter[0].role_id) {
            x.disabled = false;
          } else {
            x.disabled = true;
          }
        });
      }
      this.customSelectUser.setData(this.filteredUsers);
      if (this.customSelectUser.dataArray.map((u) => u.id).length == 0) {
        this.customSelectUser.label = savedFilter && savedFilter.length > 0 ?
          savedFilter.map(x => x.name).join(", ") : this.currentUser.name;
        this.customSelectUser.dataArray = savedFilter && savedFilter.length > 0 ?
          savedFilter : this.allFilteredUsers.filter(x => x.id == this.currentUser.id);
      }
    }
  }

  public getStageFilterData() {
    this.filteredStage = [];
    this.filteredStage = this.filteredStage.concat(this.allFilteredStage);
    this.filteredStageMultiSelect.next(this.allFilteredStage.slice());
    if (this.customSelectStage) {
      this.customSelectStage.setData(this.filteredStage);
    }
  }

  public getUsersAutocomplete(event) {
    let search = event.searchString;
    if (!search) {
      this.filteredUserMultiSelect.next(this.allFilteredUsers.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredUserMultiSelect.next(
      this.allFilteredUsers.filter(loc => loc.name.toLowerCase().indexOf(search) > -1)
    );
    this.filteredUsers = [];
    this.filteredUserMultiSelect.subscribe(x => {
      for (let i = 0; i < x.length; i++) {
        if (this.filteredUsers.length == 0) {
          this.filteredUsers.push(x[0]);
        } else {
          if (!this.filteredUsers.includes(x[i], 0)) {
            this.filteredUsers.push(x[i]);
          }
        }
      }
    });
    this.customSelectUser.setData(this.filteredUsers);
  }

  public getStageAutocomplete(event) {
    let search = event.searchString;
    if (!search) {
      this.filteredStageMultiSelect.next(this.allFilteredStage.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredStageMultiSelect.next(
      this.allFilteredStage.filter(loc => loc.name.toLowerCase().indexOf(search) > -1)
    );
    this.filteredStage = [];
    this.filteredStageMultiSelect.subscribe(x => {
      for (let i = 0; i < x.length; i++) {
        if (this.filteredStage.length == 0) {
          this.filteredStage.push(x[0]);
        } else {
          if (!this.filteredStage.includes(x[i], 0)) {
            this.filteredStage.push(x[i]);
          }
        }
      }
    });
    this.customSelectStage.setData(this.filteredStage);
  }
}
