import { GlobalConstants } from './../../../core/global-constants';
import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'pd-field-str',
  templateUrl: './field-str.component.html',
  styleUrls: ['./field-str.component.scss']
})
export class FieldStrComponent {

  @Input('field-title') fieldTitle: string = "Value";
  @Input('field-placeholder') fieldPlaceholder: string = "Enter your value";
  @Input('field-required') isRequired: boolean = false;
  @Input() fieldValue: string;
  @Input() fieldInvalid: boolean;
  @Input() stageId: number;
  public ALL_STAGES = GlobalConstants.ALL_STAGES;
  
  @Output() onValueChanged = new EventEmitter<string>();

  public isEdit = false;

  public changeEdit(value) {
    this.fieldValue = value
    this.onValueChanged.emit(this.fieldValue);
  }

  public addValue() {
    this.isEdit = true;
  }
}
