@if (avatarSortingType) {
<mat-expansion-panel class="avatar-groupped-expansion-panel" (opened)="onOpened()"
  [expanded]="isExportAction && !isHidden">
  <mat-expansion-panel-header>
    <mat-panel-title>
      <div class="panel-title">
        <span class="product-label">
          {{avatarSortingType}}
          @if (getAvatarCount() > 0) {
          <span>&nbsp;({{getAvatarCount()}})</span>
          }
        </span>
      </div>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <mat-panel-description>
    <ng-container *ngTemplateOutlet="contentWrapperTemplate"></ng-container>
  </mat-panel-description>
</mat-expansion-panel>
}

@if (!avatarSortingType) {

<ng-container *ngTemplateOutlet="contentWrapperTemplate"></ng-container>

}

<ng-template #contentWrapperTemplate>

  <div style="text-align: center;">
    @if (isNeedLoadAvatars || (isExportAction && !isHidden)) {
    <div class="expansion-panel-body">
      @for (avatar of getAvatars(); track avatar) {

      <pd-avatar-card-tile #tiles (onClickAvatar)="clickAvatar($event)" (onDeleteAvatar)="deleteAvatar($event)"
        (onDownloadAvatar)="downloadAvatar($event)" (onAddAvatarToCollection)="addAvatarToCollection($event)"
        [isCustomAvatars]="isCustomAvatars" [isGlobalCollection]="isGlobalCollection" [isExportAction]="isExportAction"
        [isAllowAddEditAvatars]="isAllowAddEditAvatars" [isMasterLeadAccount]="isMasterLeadAccount"
        [isAllowAddEditCustomAvatars]="isAllowAddEditCustomAvatars" [avatar]="avatar"
        [dealId]="dealId"></pd-avatar-card-tile>

      }
    </div>
    }

    @if (!isExportAction && avatars && getAvatars().length < avatars.length) { <button (click)="loadMoreAvatars()"
      type="button" mdbBtn class="load-more-btn waves-light btn-dark btn" placement="bottom" mdbWavesEffect>
      <span class="btn-label">Show more</span>
      </button>
      }
  </div>
</ng-template>