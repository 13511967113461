import { UserContextService } from './../core/services/user-context.service';
import { UsersService } from './../core/services/users.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { User } from '../core/models/UserModel';
import { RolesEnum } from '../core/enums/RolesEnum';
import { StandardRotation } from '../core/enums/StandardRotation';
import { ClientRating } from '../core/enums/ClientRating';
import { ClientCounterService } from '../core/services/client-counter.service';
import { DealService } from '../core/services/deals.service';
import { ChipsSelectFilterDataModel } from '../core/models/CustomChipsSelectFilterModel';
import { LocationChipsFilterComponent } from '../shared/filters/location-chips-filter.component';
import { CustomSelectFilterWithoutLazyComponent } from '../shared/custom/custom-select-without-lazy/custom-select-without-lazy';

@Component({
  selector: 'pd-client-counter-settings',
  templateUrl: './client-counter-settings.component.html',
  styleUrls: ['./client-counter-settings.component.scss']
})
export class ClientCounterSettingsComponent implements OnInit {
  @ViewChild("locationFilter") public locationFilter: LocationChipsFilterComponent;
  @ViewChild("userFilter") public customSelectUser: CustomSelectFilterWithoutLazyComponent;

  public usersLabel: string = 'Clothiers'

  public spinner: boolean = false;
  public isCountResponseWait: boolean = false;

  public selectedRotation: StandardRotation;
  public rules: any;

  public isMasterLeadAccount = false;

  public ratings = [
    { label: 'Automatically assign NA clients', count: 0, value: ClientRating.NA, checked: false },
    { label: 'Automatically assign C clients', count: 0, value: ClientRating.C, checked: false },
    { label: 'Automatically assign B clients', count: 0, value: ClientRating.B, checked: false },
    { label: 'Automatically assign A clients', count: 0, value: ClientRating.A, checked: false }
  ];

  public rotations = [
    { label: 'Always Clothier', value: StandardRotation.AlwaysClothier },
    { label: '2:1 Clothier to EA', value: StandardRotation.ClothierToEA2_1 },
    { label: '1:1 Clothier to EA', value: StandardRotation.ClothierToEA1_1 },
    { label: '1:2 Clothier to EA', value: StandardRotation.ClothierToEA1_2 },
    { label: 'Always EA', value: StandardRotation.AlwaysEA }
  ];

  private currentUser: User;

  private filteredLocations: ChipsSelectFilterDataModel[];
  private prevFilteredLocations: ChipsSelectFilterDataModel[];

  private filteredUserMultiSelect: ReplaySubject<User[]> = new ReplaySubject<User[]>(1);
  private filteredUsers = [];
  private prevfilterUserIds = [];
  private filterUserIds = [];
  private allFilteredUsers = [];

  constructor(
    private usersService: UsersService,
    private userContextService: UserContextService,
    private dealService: DealService,
    private clientCounterService: ClientCounterService) {
  }

  public async ngOnInit(): Promise<void> {
    this.spinner = true;
    this.initializeStore();
    const roles = [RolesEnum.Admin, RolesEnum.Clothier, RolesEnum.MasterLead];
    const result = await this.usersService.getUsersByRoles();
    this.allFilteredUsers = result.filter(x => roles.includes(x.role_id));
    this.filteredUsers = this.allFilteredUsers;
    this.spinner = false;
    this.getUsersFilterData();
    this.locationFilter.resetFilter();
    if (!this.customSelectUser) {
      await this.loadData();
    }
  }

  public async onSelectedItem(checked): Promise<void> {
    if (checked) {
      await this.loadData();
    }
    else {
      this.getUsersFilterData();
      await this.loadData();
    }
  }

  public getRatingLabel(rating: ClientRating) {
    return this.ratings.find(f => f.value == rating).label;
  }

  public getStandardRotationLabel(standardRotation: StandardRotation) {
    return this.rotations.find(f => f.value == standardRotation).label;
  }

  public async closeSelectFilter(): Promise<void> {
    await this.loadData();
  }

  public getLocations(data: ChipsSelectFilterDataModel[]) {
    this.filteredLocations = data;
  }

  public getUsersFilterData() {
    this.filteredUsers = [];
    this.filteredUsers = this.filteredUsers.concat(this.allFilteredUsers);
    this.filteredUserMultiSelect.next(this.allFilteredUsers.slice());
    if (this.customSelectUser) {
      this.customSelectUser.setData(this.filteredUsers);
      if (this.customSelectUser.dataArray.map((u) => u.id).length == 0) {
        this.customSelectUser.selectItem(this.currentUser, { checked: true });
      }
    }
  }

  public getUsersAutocomplete(event) {
    let search = event.searchString;
    if (!search) {
      this.filteredUserMultiSelect.next(this.allFilteredUsers.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredUserMultiSelect.next(
      this.allFilteredUsers.filter(loc => loc.name.toLowerCase().indexOf(search) > -1)
    );
    this.filteredUsers = [];
    this.filteredUserMultiSelect.subscribe(x => {
      for (let i = 0; i < x.length; i++) {
        if (this.filteredUsers.length == 0) {
          this.filteredUsers.push(x[0]);
        } else {
          if (!this.filteredUsers.includes(x[i], 0)) {
            this.filteredUsers.push(x[i]);
          }
        }
      }
    });
    this.customSelectUser.setData(this.filteredUsers);
  }

  public async onAdd(): Promise<void> {
    this.spinner = true;
    const data = {
      RatingList: this.ratings.filter(f => f.checked).map(r => r.value),
      StandardRotation: this.selectedRotation,
      Locations: this.filteredLocations,
      SettingUser: this.filterUserIds[0]
    }

    await this.clientCounterService.addClientCounterRule(data)
    await this.getRules();
  }

  public async onRemove(clientCounterRuleId): Promise<void> {
    await this.clientCounterService.removeClientCounterRule(clientCounterRuleId);
    await this.getRules();
  }

  public async rotationRadioChange(): Promise<void> {
    await this.loadData();
  }

  public validation() {
    return (this.filteredLocations && this.filteredLocations.length > 0)
      && (this.filterUserIds && this.filterUserIds.length > 0)
      && this.selectedRotation !== undefined
      && this.ratings.filter(f => f.checked).length > 0
      && !this.spinner
  }

  private async loadData(): Promise<void> {
    if (this.customSelectUser) {
      this.filterUserIds = this.customSelectUser.dataArray.map((u) => u.id);
    }

    if (!this.filterUserIds || this.filterUserIds.length == 0) {
      this.filterUserIds = new Array();
      this.filterUserIds.push(this.currentUser.id);
    }

    if (!this.filteredLocations) {
      this.locationFilter.resetFilter();
    }

    const isLocationsChanged = this.checkFilteredLocationsChanged();
    const isUserChanged = this.checkFilteredUsersChanged();

    if (isUserChanged) {
      await this.getRules();
    }

    if (isLocationsChanged || isUserChanged) {
      this.getRatingDealCount();
    }

    this.prevFilteredLocations = JSON.parse(JSON.stringify(this.filteredLocations));
    this.prevfilterUserIds = JSON.parse(JSON.stringify(this.filterUserIds));
  }

  private initializeStore() {
    this.currentUser = this.userContextService.user.value;
    this.isMasterLeadAccount = this.currentUser && this.currentUser.role_id === RolesEnum.MasterLead;
  }

  private async getRules(): Promise<void> {
    this.spinner = true;
    this.rules = await this.clientCounterService.getClientCounterRules(this.filterUserIds[0]);
    this.spinner = false;
  }

  private getRatingDealCount() {
    this.isCountResponseWait = true;
    this.dealService.getClientRatingDealCount(this.filterUserIds, this.filteredLocations).then(response => {
      if (response) {
        this.ratings.forEach(element => {
          const ratingDealCount = response.find(f => f.clientRating == element.value);
          if (ratingDealCount) {
            element.count = ratingDealCount.count;
          }
          else {
            element.count = 0;
          }
        })
      }
      this.isCountResponseWait = false;
    });
  }

  private checkFilteredLocationsChanged() {
    return JSON.stringify(this.prevFilteredLocations) !== JSON.stringify(this.filteredLocations);
  }

  private checkFilteredUsersChanged() {
    return JSON.stringify(this.prevfilterUserIds) !== JSON.stringify(this.filterUserIds);
  }
}