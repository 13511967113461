import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { AvatarOption } from '../../core/models/avatar-models/AvatarOption';
import { EnumUtil } from '../../core/utils/enum.util';
import { AvatarElementTypes } from '../../core/enums/avatar/AvatarElementTypes';
import { AvatarBuilderData } from '../../core/models/avatar-models/AvatarBuilderData';
import { AvatarUtil } from '../../core/utils/avatar.util';
import { BaseComponent } from '../../core/base.component';

@Component({
  selector: 'pd-avatar-builder-canvas',
  templateUrl: './avatar-builder-canvas.component.html',
  styleUrls: ['./avatar-builder-canvas.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class AvatarBuilderCanvasComponent extends BaseComponent implements OnDestroy {
  @ViewChild('avatarEditSpace') avatarEditSpace: ElementRef;
  @ViewChild('container') container: ElementRef;

  @Input() avararStr: string;
  @Output() onNeedReloadAvatar = new EventEmitter<any>();
  @Output() onDetectChanges = new EventEmitter<any>();

  public avatarBuilderData: AvatarBuilderData;

  isGrabbing = false;
  timerId: any
  isLockHead = false;

  constructor(
    private ref: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit() {
  }

  public ngOnDestroy() {
    this.avatarEditSpace.nativeElement.removeEventListener("mousedown", this.moseMoveEvent.bind(this));
    this.container.nativeElement.removeEventListener('wheel', this.zoom.bind(this));
    super.ngOnDestroy();
  }

  public setAvatarStr(avatarStr: string) {
    const avatar = AvatarUtil.htmlToElement(avatarStr) as Element;

    this.avatarEditSpace.nativeElement.addEventListener("mousedown", this.moseMoveEvent.bind(this));
    this.container.nativeElement.addEventListener('wheel', this.zoom.bind(this));
    this.container.nativeElement.innerHTML = avatar.outerHTML;

    this.detectChanges();
  }

  private dragPath(option: AvatarOption, textures) {
    const partId = this.getAvatarElementType(option.elementTypeId);
    const imgPattern = this.container.nativeElement.querySelector(`#${partId}`) as Element;

    if (!imgPattern || !textures) {
      this.reloadAvatar();
      return;
    }

    let patternHtml = "";
    let uses = "";

    const texture = textures[0];

    textures.forEach(texture => {
      uses += `<use x='${texture.x}' y='${texture.y}' xlink:href="#${partId}_image"></use>`
    })

    patternHtml = `<pattern id="${partId}" width="100%" height="100%">${uses}</pattern>`

    const image = this.container.nativeElement.querySelector(`#${partId}_image`) as Element;

    image.setAttribute('width', texture.width.toString());
    image.setAttribute('height', texture.height.toString());

    if (imgPattern) {
      imgPattern.innerHTML = uses;
    }
    else {
      this.container.nativeElement.insertAdjacentHTML('afterbegin', patternHtml);
    }
    this.detectChanges();
  }


  private moveAt(option: AvatarOption, pageX, pageY, startPageX, startPageY, start_xOffset, start_yOffset) {
    clearTimeout(this.timerId);
    this.timerId = setTimeout(async () => {
      const imgPattern = this.container.nativeElement.querySelector(`#${this.getAvatarElementType(option.elementTypeId)}`) as Element;

      if (!imgPattern) {
        this.reloadAvatar();
        return;
      }

      const originalWidth = parseFloat(imgPattern.getAttribute('data-original-width'));
      const originalHeight = parseFloat(imgPattern.getAttribute('data-original-height'));
      const imageWidth = Math.ceil(originalWidth / 100 * option.scale);
      const imageHeight = Math.ceil(originalHeight / 100 * option.scale);

      let xOffset = start_xOffset + (startPageX - pageX) * (-1);
      let yOffset = start_yOffset + (startPageY - pageY) * (-1);

      if (this.isHead(option)) {

        if (this.isLockHead) {
          return;
        }
        option.xoffset = xOffset;
        option.yoffset = yOffset;
        this.moveHeadTexture(option);
      }
      else {
        option.xoffset = xOffset % imageWidth;
        option.yoffset = yOffset % imageHeight;
        this.moveTexture(option);
      }

      this.detectChanges();
    }, 1);
  }

  private moseMoveEvent(event: MouseEvent) {
    event.preventDefault();
    const option = this.getOptionByEvent(event);
    if (!option)
      return;

    this.isGrabbing = true

    const startPageX = event.pageX;
    const startPageY = event.pageY;
    const start_xOffset = option.xoffset;
    const start_yOffset = option.yoffset;

    let main = this;

    const imgPattern = this.container.nativeElement.querySelector(`#${this.getAvatarElementType(option.elementTypeId)}`) as Element;
    AvatarUtil.storeOriginalPositions(option, imgPattern);

    main.moveAt(option, event.pageX, event.pageY, startPageX, startPageY, start_xOffset, start_yOffset);

    this.avatarEditSpace.nativeElement.addEventListener('mousemove', onMouseMove);

    function onMouseMove(event) {
      main.moveAt(option, event.pageX, event.pageY, startPageX, startPageY, start_xOffset, start_yOffset);
    }

    let mouseupleave = async (event) => {
      if (main.isGrabbing) {
        main.isGrabbing = false;
      }

      this.avatarEditSpace.nativeElement.removeEventListener('mousemove', onMouseMove);
      this.avatarEditSpace.nativeElement.removeEventListener("mouseup", mouseupleave);
      this.avatarEditSpace.nativeElement.removeEventListener("mouseleave", mouseupleave);
    }

    this.avatarEditSpace.nativeElement.addEventListener("mouseup", mouseupleave);
    this.avatarEditSpace.nativeElement.addEventListener("mouseleave", mouseupleave);
  }


  zoomHead(option: AvatarOption) {
    if (this.isLockHead) {
      return;
    }

    const imgPattern = this.container.nativeElement.querySelector(`#${this.getAvatarElementType(option.elementTypeId)}`) as Element;

    if (!imgPattern) {
      this.reloadAvatar();
      return;
    }

    const img = imgPattern.children[0] as Element;
    const scale = 500 / 100 * option.scale;

    img.setAttribute("width", scale.toString());
    img.setAttribute("height", scale.toString());
  }

  zoomTexture(option: AvatarOption) {
    const imgPattern = this.container.nativeElement.querySelector(`#${this.getAvatarElementType(option.elementTypeId)}`) as Element;

    if (!imgPattern) {
      this.reloadAvatar();
      return;
    }

    const originalWidth = parseFloat(imgPattern.getAttribute('data-original-width'));
    const originalHeight = parseFloat(imgPattern.getAttribute('data-original-height'));
    const boundsWidth = parseFloat(imgPattern.getAttribute('data-bounds-width'));
    const boundsHeight = parseFloat(imgPattern.getAttribute('data-bounds-height'));

    if (option.scale < 5) {
      option.scale = 5;
    }

    if (option.scale > 500) {
      option.scale = 500;
    }

    let textures = [];

    const overlapPixels = -2;
    const imageWidth = Math.ceil(originalWidth / 100 * option.scale) + overlapPixels;
    const imageHeight = Math.ceil(originalHeight / 100 * option.scale) + overlapPixels;

    const x1 = - 2 * imageWidth;
    const y1 = - 2 * imageHeight;
    const w1 = boundsWidth + 3 * imageWidth;
    const h1 = boundsHeight + 3 * imageHeight;

    const w2 = imageWidth;
    const h2 = imageHeight;

    const xoffset = Math.ceil(option.xoffset % imageWidth);
    const yoffset = Math.ceil(option.yoffset % imageHeight);

    let i = 0;
    let k = 0;

    let coveredWidth = x1;
    let coveredHeight = y1;

    while (!(coveredHeight >= h1)) {
      const y2 = Math.ceil(imageHeight * i - imageHeight + yoffset);

      while (!(coveredWidth >= w1)) {
        const x2 = Math.ceil(imageWidth * k - imageWidth + xoffset);

        if (!(x1 + w1 <= x2 || x2 + w2 <= x1 || y1 + h1 <= y2 || y2 + h2 <= y1)) {
          textures.push(
            {
              x: x2,
              y: y2,
              src: option.imageLink,
              width: w2 - overlapPixels,
              height: h2 - overlapPixels,
            });
        }

        coveredWidth = x2 + w2;
        k++;
      }
      coveredWidth = 0;
      i++;
      k = 0;
      coveredHeight = y2 + h2;
    }

    return textures;
  }

  moveHeadTexture(option: AvatarOption) {
    let g = this.container.nativeElement.querySelector(`#head_part`) as Element;
    let rect = g.getElementsByTagName('rect');
    rect[0].setAttribute('x', option.xoffset.toString());
    rect[0].setAttribute('y', option.yoffset.toString());
  }

  moveTexture(option: AvatarOption) {
    const imgPattern = this.container.nativeElement.querySelector(`#${this.getAvatarElementType(option.elementTypeId)}`) as Element;
    AvatarUtil.moveTexture(option, imgPattern);
  }

  public updateSingleOption(option: AvatarOption) {
    clearTimeout(this.timerId);
    this.timerId = setTimeout(async () => {
      if (!option.imageLink) {
        this.reloadAvatar();
        return;
      }

      if (this.isHead(option)) {
        this.zoomHead(option)
      }
      else {
        const newTextures = this.zoomTexture(option);
        this.dragPath(option, newTextures);
      }

      this.detectChanges();
    }, 1);
  }

  private getOptionByEvent(event): AvatarOption {
    let option = this.avatarBuilderData.options.find(o => (event.target as Element).getAttribute('style').includes(`url(#${this.getAvatarElementType(o.elementTypeId)})`));

    if (!option) {
      option = this.avatarBuilderData.options.find(o => `url(#${this.getAvatarElementType(o.elementTypeId)})` == (event.target as Element).getAttribute('fill'));
    }
    if (!option) {
      option = this.avatarBuilderData.options.find(o => `${this.getAvatarElementType(o.elementTypeId)}` == (event.target as Element).getAttribute('id'));
    }
    if (!option) {
      option = this.avatarBuilderData.options.find(o => `${this.getAvatarElementType(o.elementTypeId)}` == (event.target as Element).parentElement.getAttribute('id'));
    }
    return option;
  }

  private zoom(event) {
    event.preventDefault();
    clearTimeout(this.timerId);
    this.timerId = setTimeout(async () => {
      const option = this.getOptionByEvent(event);

      if (!option)
        return;

      if (event.deltaY > 0) {
        option.scale += 1;
      } else {
        option.scale -= 1;
      }
      if (option.scale < 5) {
        option.scale = 5;
      }

      this.updateSingleOption(option);
    }, 1);
  }

  public getAvatarElementType(avatarElementType: number) {
    return EnumUtil.getEnumName(AvatarElementTypes, avatarElementType).replace(/\s/g, '_');
  }

  public isHead(option: AvatarOption): boolean {
    return option.elementTypeId === AvatarElementTypes.Head;
  }

  private reloadAvatar() {
    this.onNeedReloadAvatar.emit();
  }

  private detectChanges() {
    this.ref.detectChanges();
    this.onDetectChanges.emit();
  }
}
