
export const IMPORT_TYPES_DICT = [
	{
	  'id': 1,
	  'name': '/PDImportDeals',
		'label_name': 'Import Deals',
		'message': 'The name of file should be in this format: TitleOfDeal_Location.csv; File should be with these columns: Name, Company, Position, City, UUID, Profile Link, isConnection'
	},
	{
	  'id': 2,
	  'name': '/PDImportEvents',
		'label_name': 'Import Events',
		'message': 'The name of file should be in this format: TitleOfDeal_Location.csv; File should be with these columns: Name, Company, Position, City, UUID, Profile Link, isConnection, Event'
	},
	{
	  'id': 3,
	  'name': '/PDImportRecruiter',
		'label_name': 'Import Recruiter',
		'message': 'The name of file should be in this format: TitleOfDeal_Location.csv; File should be with these columns: Name, Company, Position, City, UUID, Profile Link, isConnection'
	},
	{
	  'id': 4,
	  'name': '/PDImportPhones',
		'label_name': 'Import Phone and Emails',
		'message': 'File should be in .csv format; File should be with these columns: FirstName, LastName, FullName, Location, Company, LinkedInUrl, PersonId, Email, Phone'
	}]