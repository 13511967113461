import { Injectable } from '@angular/core';
import { HttpRestService } from './http-client/http-rest-client.service';
import { BaseService } from './base.service';

@Injectable()
export class GeolacationService extends BaseService {

    constructor(private restService: HttpRestService) {
        super();
    }

    public async getGeocodeByAddress(address: string): Promise<any> {
        return await this.handleResponse(this.restService.get({ url: '/geolocation/GetGeocodeByAddress?address=' + address }));
    }

    public async getGoogleNearbySearch(geocode: string, radius: number, nextPageToken: string): Promise<any> {
        let url = `/geolocation/GetGoogleNearbySearch?location=${geocode}&radius=${radius}`
        if (nextPageToken) {
            url += `&pagetoken=${nextPageToken}`;
        }
        return await this.handleResponse(this.restService.get({ url }));
    }
}
