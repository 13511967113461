import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'pd-email-chips-field',
  templateUrl: './email-chips-field.component.html',
  styleUrls: ['./email-chips-field.component.scss']
})
export class EmailChipsFieldComponent {
  @Input() isTemplatePreview: boolean = false;
  emailForm: FormGroup;
  emails: string[];

  constructor(private fb: FormBuilder) {
    this.emailForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  addEmail() {
    if (!this.emails) {
      this.emails = new Array<string>();
    }

    if (this.emailForm.valid) {
      const email = this.emailForm.value.email.trim();
      if (email && !this.emails.includes(email)) {
        this.emails.push(email);
        this.emailForm.reset();
      }
    }
  }

  removeEmail(index: number) {
    this.emails.splice(index, 1);
  }

  setEmails(emails: string[]) {
    this.emails = emails;
  }

  getEmails() {
    return this.emails;
  }
}