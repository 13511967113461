import { Component, Input, ViewChildren, Output, EventEmitter, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { Deal } from 'src/app/core/models/Deal';
import { SameBuildingConnections } from 'src/app/core/models/SameBuilding';
import { DealService } from 'src/app/core/services/deals.service';

@Component({
  selector: 'pd-custom-same-building',
  templateUrl: './custom-same-building.component.html',
  styleUrls: ['./custom-same-building.component.scss']
})

export class CustomSameBuildingComponent {
  @Input() deal: Deal;
  @ViewChildren('input') input;
  @ViewChild('popDiv') popDiv: ElementRef;
  @ViewChild("popTemplate") popTemplate: ElementRef;
  @Output() openPop = new EventEmitter<object>();
  
  sameBuildingConnections: SameBuildingConnections;
  isOpened = false
  poperSpinner = true;

  constructor(private renderer: Renderer2, private dealService : DealService) { }

  public cancel() {
    this.isOpened = false;
  }

  public openPopover() {
    this.isOpened = true;
    setTimeout(() => { // *ngIf not working with popover. And if you don’t use it, popover rendered on each deal and we lose performance. So, this way of rendering only the necessary popover. 
      this.popDiv.nativeElement.click();
      this.poperSpinner = true;
      this.sameBuildingConnections = null;
      this.dealService.getSameBuildings(this.deal.personId).then(result => {
        this.sameBuildingConnections = result;
        this.poperSpinner = false;
      });
      const rect = this.popTemplate.nativeElement.getBoundingClientRect();
      if (rect.x + rect.width > window.innerWidth) {
        this.renderer.setStyle(this.popTemplate.nativeElement, 'transform', `translate(-${rect.width + 40}px,-70px)`);
      }
      this.openPop.emit(this.input);
    }, 10);
  }
}
