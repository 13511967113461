<mat-expansion-panel #expansionPanel (opened)="isExpandedOnce = true">
  <mat-expansion-panel-header>
    <mat-panel-title>
      <span class="card-line-label">
        {{modeler3dCardType.name}}
        @if (getCardsCount() > 0) {
        <span>&nbsp;({{getCardsCount()}})</span>
        }
      </span>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <mat-panel-description>
    @if (expansionPanel.expanded || isExpandedOnce) {
    <div>
      @for (cardLine of cardLines; track cardLine) {
      <div>
        <pd-3d-modeler-product-card [cardLine]='cardLine' [modeler3dCards]='fabricsCardByLine[cardLine.lineId]'>
        </pd-3d-modeler-product-card>
      </div>
      }
    </div>
    }
  </mat-panel-description>
</mat-expansion-panel>