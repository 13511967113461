import { ObjectUtil } from "../utils/object.util";

export class UserData {
	public name: string = "";
	public company_name: string = "";
	public referralLink: string = "";

	constructor(data?: any) {
		ObjectUtil.extend(data, this);
	}
}
