<div class="numbers">
    <form [formGroup]="numberForm">
        <div class="location">
            <pd-custom-select-filter #locationFilter (loadDataEvent)="getLocationsFilterData($event)"
                (autoCompleteEvent)="getLocationsAutocomplete($event)" (sendData)="getNumberLocations($event)"
                [isNumberInfo]="true" [dataLabel]="locationsLabel"
                [filterType]="filterTypeLocation"></pd-custom-select-filter>
        </div>
        <div style="padding-right: 5px;">
            <input type="text" [(ngModel)]="numberCount" placeholder="Count of person"
                formControlName="numberCountOfPerson">
            @if (numberForm.get('numberCountOfPerson').invalid && numberForm.get('numberCountOfPerson').touched) {
            <p>
                Count of person is required</p>
            }
        </div>
        <div class="radio-number">
            <span class="title">Contact Type:</span>
            <div class="radio">
                <mat-radio-group class="example-radio-group" [value]="contactTypes[0]">
                    @for (type of contactTypes; track type) {
                    <mat-radio-button class="example-radio-button" [value]="type" (click)="getContactType(type)">
                        {{type}}
                    </mat-radio-button>
                    }
                </mat-radio-group>
            </div>
            <span class="title">Gender:</span>
            <div class="radio">
                <mat-radio-group class="example-radio-group" [value]="genderList[0]">
                    @for (item of genderList; track item) {
                    <mat-radio-button class="example-radio-button" [value]="item" (click)="getGender(item)">
                        {{item}}
                    </mat-radio-button>
                    }
                </mat-radio-group>
            </div>
        </div>
        <div class="submit">
            <button class="btn btn-success waves-light" type="submit" (click)="submitNumber()"
                [disabled]="numberForm.get('numberCountOfPerson').invalid || numberLocations.length === 0 && !isSelectAllLocationsNumber">Submit</button>
            <button class="btn btn-danger waves-light" [routerLink]="['/', tasksRoute]">Cancel</button>
        </div>
    </form>
</div>
<div class="update-info">
    {{updateNumberInfo}}
</div>