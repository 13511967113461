import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { filterNil } from "../../filter-nil";
import { MessageTemplateViewModel } from "../../models/message-template/MessageTemplateViewModel";

@Injectable()
export class BulkMessageStoreService {
    private readonly copiedTemplateSetting$: BehaviorSubject<MessageTemplateViewModel> = new BehaviorSubject<MessageTemplateViewModel>(null);

    public getCopiedTemplateSetting(): Observable<MessageTemplateViewModel> {
        return this.copiedTemplateSetting$.asObservable().pipe();
    }

    public getCopiedTemplateSettingSnapshot(): MessageTemplateViewModel {
        return this.copiedTemplateSetting$.getValue();
    }

    public setCopiedTemplateSetting(copiedTemplateSetting: MessageTemplateViewModel): void {
        this.copiedTemplateSetting$.next(copiedTemplateSetting);
    }
}