import { Phone, Email } from "./Deal";

export class Person {
    public id: number = 0;
    public name: string = "";
    public firstName: string = "";
    public lastName: string = "";
    public birthday?: Date = null;
    public company: string = "";
    public companyAddress: string = "";
    public homeAddress: string = "";
    public position: string = "";
    public positionId = 0;
    public linkedinUrl: string = "";
    public location: string = "";
    public genderId: number = 0;
    public isNotUseAsCommonConnection: boolean = false;
    public isDontSendPromotionalEmails: boolean = false;
    public isDontSendPartnerEmails: boolean = false;
    public isDontSendWhatsapp: boolean = false;
    public isDontSendSms: boolean = false;
    public isPartnerRepliedToBDayEmail: boolean = false;
    public phone: Phone[] = [];
    public email: Email[] = [];
    public companyId: any = null;
    public locationId: any = null;
    public latitude: string = null;
    public longitude: string = null;
    
    public partnerFirstName: string = "";
    public partnerLastName: string = "";
    public partnerEmail: string = "";
    public partnerPhone: string = "";
    public partnerToken: string = "";
}