export enum AvatarElementTypes {
    "Head" = 1,
    "Jacket",
    "Pants",
    "Tie",
    "Shirt",
    "Belt",
    "Bowtie",
    "Vest",
    "Turtleneck",
    "Sweater",
    "Lapel",
    "Coat",
    "Jacket_Type1",
    "Jacket_Type2",
    "Vest_Type1",
    "Puffy Coat",
    "Cuffs",
    "TShirt_Type1",
    "TShirt_Type2",
    "Jeans",
}