<div style="position: relative;">

  @if (isSpinner || isExportAction) {
  <div [ngClass]="{
  'spinner': !isAsModal,
  'spinner-modal': isAsModal,
  'spinner-export-action': isExportAction
}">
    <mat-spinner [diameter]="100"></mat-spinner>
  </div>
  }

  @if (dealId && !isLoadInProgress) {
  <div id="export-client-card-canvas" class="background" #clientExportCanvas>
    <div class="row header">
      <div class="title">
        <span>Client Card </span>
        @if (dealTitle) {
        <span>of {{dealTitle}}</span>
        }
      </div>
      @if (!isExportAction) {
      <div class="col tool">

        <div class="custom" (clickOutside)="closeCustomSelect()">
          <div class="custom-header">
            <div>
              <mat-slide-toggle class="order-tickmark" (change)="onClientHistoryChange($event)">
                Client
                History</mat-slide-toggle>
            </div>
            <div>
              @if (isMasterLeadAccount) {
              <button (click)="openCustomLinkModal();$event.stopPropagation()" type="button" mdbBtn
                class="export-btn waves-light btn-dark btn float-right" aria-label="Custom Link"
                mdbTooltip="Custom Link" placement="bottom" mdbWavesEffect>
                <span class="custom-input" data-html="true">Custom Link</span>
              </button>
              }
              <button (click)="generateQrCode();$event.stopPropagation()" type="button" mdbBtn
                class="export-btn waves-light btn-dark btn float-right" aria-label="Show QR code"
                mdbTooltip="Show QR code" placement="bottom" mdbWavesEffect>
                <span class="custom-input" data-html="true">Show QR code</span>
              </button>
              <div class="export-btn-wrapper">
                <button (click)="openSelect();$event.stopPropagation()" type="button" mdbBtn
                  class="export-btn waves-light btn-dark btn float-right" aria-label="Export" mdbTooltip="Export"
                  placement="bottom" mdbWavesEffect>
                  <span class="custom-input" data-html="true">Export</span>
                </button>
                @if (dealId) {
                <div class="custom-body" [ngClass]="{
            'visible-select': isOpenSelect, 
            'hidden-select': !isOpenSelect
          }">
                  <div class="select-all">
                    <div>
                      <button mat-button #selectAll type="button" class="button" style="margin: 0 10px;"
                        (click)="selectAllData();$event.stopPropagation()">Select All</button>
                      <button mat-button #deselectAll type="button" class="button" style="margin: 0 10px;"
                        (click)="deselectAllData();$event.stopPropagation()">Deselect All</button>
                    </div>
                  </div>
                  <hr class="line" />
                  <div class="group-options" #selectContainer>
                    <div class="list-options">
                      @if (isAllowStylesAvailableAvatars) {
                      <div class="custom-option">
                        <span class="option">
                          <mat-checkbox [checked]="checkItem(stylesAvailableProductType.id)"
                            (change)="selectItem(stylesAvailableProductType, $event)">{{stylesAvailableProductType.name}}
                          </mat-checkbox>
                        </span>
                      </div>
                      }
                      @if (isAllowCustomAvatars) {
                      <div class="custom-option">
                        <span class="option">
                          <mat-checkbox [checked]="checkItem(customAvatarProductType.id)"
                            (change)="selectItem(customAvatarProductType, $event)">{{customAvatarProductType.name}}
                          </mat-checkbox>
                        </span>
                      </div>
                      }
                      @if (isAllowAvatars) {
                      <div class="custom-option">
                        <span class="option">
                          <mat-checkbox [checked]="checkItem(avatarWardrobeProductType.id)"
                            (change)="selectItem(avatarWardrobeProductType, $event)">{{avatarWardrobeProductType.name}}
                          </mat-checkbox>
                        </span>
                      </div>
                      }
                      @if (isAllowSeasonalProposals) {
                      <div class="custom-option">
                        <span class="option">
                          <mat-checkbox [checked]="checkItem(seasonalProposalProductType.id)"
                            (change)="selectItem(seasonalProposalProductType, $event)">{{seasonalProposalProductType.name}}
                          </mat-checkbox>
                        </span>
                      </div>
                      }
                      @if (isClientHistory) {
                      <div class="custom-option">
                        <span class="option">
                          <mat-checkbox [checked]="checkItem(wardrobeProductType.id)"
                            (change)="selectItem(wardrobeProductType, $event)">{{wardrobeProductType.name}}
                          </mat-checkbox>
                        </span>
                      </div>
                      }
                      @for (productType of productTypes; track productType; let i = $index) {
                      <div class="custom-option">
                        <span class="option">
                          <mat-checkbox [checked]="checkItem(productType.id)"
                            (change)="selectItem(productType, $event)">{{productType.name}}
                          </mat-checkbox>
                        </span>
                      </div>
                      }
                    </div>
                  </div>
                  <hr class="line" />
                  <div>
                    <div>
                      <button mat-button #exportCardButton type="button" class="button" class="export-card-btn"
                        (click)="export()"
                        [disabled]="!(selectedProducts && selectedProducts.length > 0)">Export</button>
                    </div>
                  </div>
                </div>
                }
              </div>

              @if (!isFemale && isAllowAddEditAvatars) {
              <button (click)="openAvatarSettings();$event.stopPropagation()" type="button" mdbBtn
                class="export-btn waves-light btn-dark btn float-right" aria-label="Deal Avatar Settings"
                mdbTooltip="Deal Avatar Settings" placement="bottom" mdbWavesEffect>
                <span class="custom-input" data-html="true">Deal Avatar Settings</span>
              </button>
              }
            </div>
          </div>
        </div>

      </div>
      }
    </div>

    <div [ngClass]="{
      'wrapper': !isAsModal && !isExportAction,
      'wrapper-modal': isAsModal && !isExportAction
    }">

      @if (customLink && customLink.label && customLink.link && !isExportAction) {
      <div class="box-pdf">
        <a [href]="customLink.link" class="btn-pdf">
          <div class="link-content-wrapper">
            <span class="text-btn-pdf">{{customLink.label}}</span>
            <i class="material-icons icon">cloud_download</i>
          </div>
        </a>
      </div>
      }

      <mat-accordion [multi]="true">

        <div [ngClass]="{ 'hidden': !isShowExpansion(stylesAvailableProductType) }">
          @if (isAllowStylesAvailableAvatars) {
          <pd-avatar-expansion-panel #stylesAvailablePanel [dealId]='dealId' [dealTitle]="dealTitle"
            (onClickAvatar)="clickAvatar($event)" [isStylesAvailable]="true" [isGlobalCollection]="true"
            [isGroupedView]="collectionsViewConfig.isStyleAvaliableGroupingView"
            [productType]="stylesAvailableProductType" [isExportAction]="isExportAction"
            [isHidden]="!isShowExpansion(stylesAvailableProductType)" [isAllowAddEditAvatars]="isAllowAddEditAvatars"
            [avatars]="stylesAvailableAvatars" (onDownloadAvatar)="downloadAvatar($event)"
            [isMasterLeadAccount]="isMasterLeadAccount" [isAllowAddEditCustomAvatars]="isAllowAddEditCustomAvatars"
            [isLoadAllOnStart]="true"
            (onDownloadAllAvatars)="downloadAllAvatars(stylesAvailableProductType)"></pd-avatar-expansion-panel>
          }
        </div>

        <div [ngClass]="{ 'hidden': !isShowExpansion(avatarWardrobeProductType) }">
          @if (!isFemale) {
          <pd-avatar-expansion-panel #avatarWardrobePanel [dealId]='dealId' [dealTitle]="dealTitle"
            (onClickAvatar)="clickAvatar($event)" [isGlobalCollection]="false" [isGroupedView]="true"
            (onDeleteAvatar)="deleteAvatar($event)" (onGenerateDealAvatars)="generateDealAvatars($event)"
            (onDownloadAvatar)="downloadAvatar($event)"
            (onDownloadAllAvatars)="downloadAllAvatars(avatarWardrobeProductType)"
            (onAddAvatarToCollection)="addAvatarToCollection($event)" [productType]="avatarWardrobeProductType"
            [isExportAction]="isExportAction" [isHidden]="!isShowExpansion(avatarWardrobeProductType)"
            [isAllowAddEditAvatars]="isAllowAddEditAvatars" [isMasterLeadAccount]="isMasterLeadAccount"
            [isAllowAddEditCustomAvatars]="isAllowAddEditCustomAvatars" [avatars]="avatars"></pd-avatar-expansion-panel>
          }
        </div>

        <div [ngClass]="{ 'hidden': !isShowExpansion(seasonalProposalProductType) }">
          @if (isAllowSeasonalProposals) {
          <pd-avatar-expansion-panel #avatarSeasonalProposalPanel [dealId]='dealId' [dealTitle]="dealTitle"
            (onClickAvatar)="clickAvatar($event)" [isGlobalCollection]="true"
            [isGroupedView]="collectionsViewConfig.isSeasonalProposalGroupingView" [isGroupedView]="true"
            [productType]="seasonalProposalProductType" [isExportAction]="isExportAction"
            [isHidden]="!isShowExpansion(seasonalProposalProductType)" [isAllowAddEditAvatars]="isAllowAddEditAvatars"
            [avatars]="seasonalProposals" (onDownloadAvatar)="downloadAvatar($event)"
            [isMasterLeadAccount]="isMasterLeadAccount" [isAllowAddEditCustomAvatars]="isAllowAddEditCustomAvatars"
            (onDownloadAllAvatars)="downloadAllAvatars(seasonalProposalProductType)"></pd-avatar-expansion-panel>
          }
        </div>

        @if (isClientHistory) {
        <div>
          <div style="width: 100%;">
            <pd-client-card-expansion-panel [dealId]='dealId' [dealTitle]="dealTitle"
              [productType]='wardrobeProductType' [fabricConfigs]='fabricConfigs' [fabricImages]='fabricImages'
              (onChange)="detectChanges()" [isWardrobe]='(wardrobeProductType.id === ProductTypes.Wardrobe)'
              [isExportAction]='isExportAction' [isFemale]="isFemale"
              [isClientHistory]='isClientHistory'></pd-client-card-expansion-panel>
          </div>
        </div>
        }

        @if (getProductTypes().length > 0) {
        <div>
          <mat-expansion-panel [expanded]="isExportAction">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="panel-title">
                  <span class="product-label">
                    {{DealUtil.nameToPossessiveForm(dealTitle) + " Wardrobe Fabrics"}}
                    @if (getCardCount() > 0) {
                    <span>&nbsp;({{getCardCount()}})</span>
                    }
                  </span>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-panel-description>
              <div style="width: 100%;">
                @for (productType of getProductTypes(); track productType) {
                <pd-client-card-expansion-panel [dealId]='dealId' [dealTitle]="dealTitle" [productType]='productType'
                  [fabricConfigs]='fabricConfigs' [typeOfSuits]='typeOfSuits' [fabricImages]='fabricImages'
                  [isExportAction]='isExportAction' [isFemale]="isFemale" [isClientHistory]='isClientHistory'
                  [isAllowAddEditAvatars]="isAllowAddEditAvatars" (onChange)="detectChanges()"
                  (onAddCard)="openModal($event)" (onEdiCard)="openModal($event)" (onDeleteCard)="deleteCard($event)"
                  (onGenerateDealAvatars)="generateDealAvatars($event)"></pd-client-card-expansion-panel>
                }
              </div>
            </mat-panel-description>
          </mat-expansion-panel>
        </div>
        }
      </mat-accordion>

      <div [ngClass]="{ 'hidden': !isShowExpansion(customAvatarProductType) }">
        @if (isAllowCustomAvatars) {
        <pd-avatar-expansion-panel #customAvatarPanel [dealId]='dealId' [dealTitle]="dealTitle"
          (onClickAvatar)="clickAvatar($event)" (onAddAvatar)="addCustomAvatar()" [isCustomAvatars]="true"
          [isGlobalCollection]="false" [isGroupedView]="true" (onDeleteAvatar)="deleteAvatar($event)"
          (onGenerateDealAvatars)="generateDealAvatars($event)" (onDownloadAvatar)="downloadAvatar($event)"
          (onDownloadAllAvatars)="downloadAllAvatars(customAvatarProductType)"
          (onAddAvatarToCollection)="addAvatarToCollection($event)" [productType]="customAvatarProductType"
          [isExportAction]="isExportAction" [isHidden]="!isShowExpansion(customAvatarProductType)"
          [isAllowAddEditAvatars]="isAllowAddEditAvatars" [isMasterLeadAccount]="isMasterLeadAccount"
          [isAllowAddEditCustomAvatars]="isAllowAddEditCustomAvatars"
          [avatars]="avatarsCustom"></pd-avatar-expansion-panel>
        }
      </div>
    </div>
  </div>
  }

  <div #fullscreenView class="fullscreen" (click)="closeFullscreen()">
    <button class="fullscreen-close-btn waves-light btn-danger btn float-right" (click)="closeFullscreen()">
      <i class="material-icons">close</i>
    </button>
    <img #fullscreenImg src="" alt="image">
  </div>


</div>