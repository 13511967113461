import { Component, OnInit, ViewChild } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { ClientRating } from '../../core/enums/ClientRating';
import { CustomSelectFilterWithoutLazyComponent } from '../../shared/custom/custom-select-without-lazy/custom-select-without-lazy';
import * as stages from '../../../assets/stages_new_prospespects';
import { ChipsSelectFilterDataModel } from 'src/app/core/models/CustomChipsSelectFilterModel';
import { User } from 'src/app/core/models/UserModel';
import { LocationChipsFilterComponent } from 'src/app/shared/filters/location-chips-filter.component';
import { RolesEnum } from 'src/app/core/enums/RolesEnum';
import { UsersService } from 'src/app/core/services/users.service';
import { Pipeline } from 'src/app/core/models/Pipeline';
import { CustomSelectFilterGroupedDataComponent } from 'src/app/shared/custom/custom-select-grouped-data/custom-select-grouped-data';
import { Stage } from '../../core/models/Stage';

@Component({
  selector: 'pd-bulk-message-filter',
  templateUrl: './bulk-message-filter.component.html',
  styleUrls: ['./bulk-message-filter.component.scss']
})
export class BulkMessageFilterComponent implements OnInit {
  @ViewChild("locationFilter") public locationFilter: LocationChipsFilterComponent;
  @ViewChild("ownerFilter") public customSelectOwner: CustomSelectFilterWithoutLazyComponent;
  @ViewChild("pipelineFilter") customSelectPipeline: CustomSelectFilterGroupedDataComponent;

  public ownersLabel: string = 'Clothiers'
  public LocationsLabel: string = 'Locations'
  public pipelineLabel: string = 'SelectAll()';

  public ratings = [
    { label: 'NA clients', value: ClientRating.NA, checked: false },
    { label: 'C clients', value: ClientRating.C, checked: false },
    { label: 'B clients', value: ClientRating.B, checked: false },
    { label: 'A clients', value: ClientRating.A, checked: false }
  ];

  public spinner: boolean = false;

  private allUsers = [];

  private filteredLocations: ChipsSelectFilterDataModel[];

  private filteredOwnerMultiSelect: ReplaySubject<User[]> = new ReplaySubject<User[]>(1);
  private filteredOwners = [];

  private allFilteredPipeline = [];
  private filteredPipelineMultiSelect: ReplaySubject<Pipeline[]> = new ReplaySubject<Pipeline[]>(1);
  private filteredPipeline = [];

  constructor(private usersService: UsersService) {
    let pipelines = stages.ALL_PIPELINES as Pipeline[];
    this.allFilteredPipeline = pipelines
      .filter(x => x.id == stages.ClientsPipeline.id
        || x.id == stages.OpenOrders.id
        || x.id == stages.ClothierContactClientsPipeline.id);
  }

  public async ngOnInit() {
    this.spinner = true;
    const roles = [RolesEnum.Admin, RolesEnum.Clothier, RolesEnum.MasterLead];
    const result = await this.usersService.getUsersByRoles();

    this.allUsers = result.filter(x => roles.includes(x.role_id));
    this.filteredOwners = this.allUsers;
    this.getOwnersFilterData();
    this.getPipelineFilterData();
    this.locationFilter.resetFilter();
    this.spinner = false;
  }

  public getLocations(data: ChipsSelectFilterDataModel[]) {
    this.filteredLocations = data;
  }

  public getOwnersFilterData() {
    this.filteredOwners = [];
    this.filteredOwners = this.filteredOwners.concat(this.allUsers);
    this.filteredOwnerMultiSelect.next(this.allUsers.slice());
    if (this.customSelectOwner) {
      this.customSelectOwner.setData(this.filteredOwners);
    }
  }

  public getOwnersAutocomplete(event) {
    let search = event.searchString;
    if (!search) {
      this.filteredOwnerMultiSelect.next(this.allUsers.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredOwnerMultiSelect.next(
      this.allUsers.filter(loc => loc.name.toLowerCase().indexOf(search) > -1)
    );
    this.filteredOwners = [];
    this.filteredOwnerMultiSelect.subscribe(x => {
      for (let i = 0; i < x.length; i++) {
        if (this.filteredOwners.length == 0) {
          this.filteredOwners.push(x[0]);
        } else {
          if (!this.filteredOwners.includes(x[i], 0)) {
            this.filteredOwners.push(x[i]);
          }
        }
      }
    });
    this.customSelectOwner.setData(this.filteredOwners);
  }

  public async onSelectedPipelineItem(): Promise<void> {

  }

  public getPipelineFilterData() {
    this.filteredPipeline = [];
    this.filteredPipeline = this.filteredPipeline.concat(this.allFilteredPipeline);
    this.filteredPipelineMultiSelect.next(this.allFilteredPipeline.slice());
    if (this.customSelectPipeline) {
      this.customSelectPipeline.setData(this.filteredPipeline);
      this.customSelectPipeline.selectAllData();
    }
  }

  public getPipelineAutocomplete(event) {
    let search = event.searchString;
    if (!search) {
      this.filteredPipelineMultiSelect.next(this.allFilteredPipeline.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredPipelineMultiSelect.next(
      this.allFilteredPipeline.filter(loc => loc.name.toLowerCase().indexOf(search) > -1)
    );
    this.filteredPipeline = [];
    this.filteredPipelineMultiSelect.subscribe(x => {
      for (let i = 0; i < x.length; i++) {
        if (this.filteredPipeline.length == 0) {
          this.filteredPipeline.push(x[0]);
        } else {
          if (!this.filteredPipeline.includes(x[i], 0)) {
            this.filteredPipeline.push(x[i]);
          }
        }
      }
    });
    this.customSelectPipeline.setData(this.filteredPipeline);
  }

  public stagesToPipelinesArray(stagesToTransform: Stage[]) {
    let pipelines = stagesToTransform.map(s => s.pipeline_id);
    let uniquePipelines = pipelines.filter(function (elem, index, self) {
      return index === self.indexOf(elem);
    });
    return uniquePipelines.reduce((result, element) => {
      let pipeline = stages.ALL_PIPELINES.find(s => s.id === element);
      result.push({
        name: pipeline && pipeline.name || '',
        values: stagesToTransform.filter(s => s.pipeline_id === element)
      });
      return result;
    }, []);
  }

  public validation() {
    return (this.filteredLocations && this.filteredLocations.length > 0)
      && this.customSelectPipeline.dataArray && this.customSelectPipeline.dataArray.length > 0
      && !this.spinner
  }

  public getFilterData() {
    return {
      clientRatings: this.ratings.filter(f => f.checked).map(r => r.value),
      pipelines: this.customSelectPipeline.dataArray.map(u => u.id),
      ownerIds: this.customSelectOwner.dataArray.map(u => u.id),
      locations: this.filteredLocations
    };
  }
}