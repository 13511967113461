import { environment } from "../../../environments/environment";

export class ImageUtil {
  public static fabricToUrl(fabricName: string): string {
    if (fabricName) {
      return environment.apiUrl3d + environment.apiUrl3dFabricEndpoint + fabricName;
    }
    return null;
  }

  public static urlToFabric(url: string): string {
    if (this.isFabric(url)) {
      if (url.startsWith("https")) {
        return url.replace(environment.apiUrl3d + environment.apiUrl3dFabricEndpoint, '');
      }
      else {
        return url.replace(environment.apiUrl3d.replace("https", "http") + environment.apiUrl3dFabricEndpoint, '');
      }
    }
    return null;
  }

  public static isFabric(url: string): boolean {
    return url
      && (url.indexOf(environment.apiUrl3d + environment.apiUrl3dFabricEndpoint) !== -1
        || url.indexOf(environment.apiUrl3d.replace("https", "http") + environment.apiUrl3dFabricEndpoint) !== -1);
  }

  public static isBase64(str: string): boolean {
    if (!str) {
      return false;
    }
    const regex = new RegExp(
      "data:image/([a-zA-Z]*);base64,([^\"]*)"
    );
    return regex.test(str);
  }

  public static addVersionToUrl(url: string): string { //avoid caching
    const random = Math.random();
    return `${url}?v=${random}`;
  }
}