<mat-dialog-content>
  @if (isSpinner) {
  <div class="spinner" style="min-height: 300px;">
    <mat-spinner [diameter]="100"></mat-spinner>
  </div>
  }

  @if (!isSpinner) {
  <div class="modal-content">
    <div class="text modal-body">
      <div class="row address" style="display: flex; justify-content: center;">
        <div class="col-auto ml-auto datepicker">
          <mat-form-field>
            <mat-label>Date of meeting</mat-label>
            <input matInput [matDatepicker]="picker" [(ngModel)]="meetingDate" [min]="minDate" readonly>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker [startAt]="minDate"></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-auto mr-auto">
          <timepicker [(ngModel)]="meetingDate" name="test" class="timepicker" [showMeridian]=false>
          </timepicker>
        </div>
      </div>

      @if (isShowValidationMessages && !meetingDateValidation()) {<div class="row alert-row">
        <div class="alert alert-danger">
          Invalid Meeting Date
        </div>
      </div>
      }
      @if (currentUser?.role_id !== RolesEnum.BB) {
      <div>
        <div class="row">
          <div class="col-sm-12 col-lg-9 row">
            <div class="label">
              Company Address:
            </div>
            @if (dealsPerson) {
            <div class="col" [bootstrapTooltip]="addressValidationTooltip" tooltipPlacement="top">
              <input type="search" class="address" [(ngModel)]="dealsPerson.companyAddress"
                [placeholder]="addressLabel">
            </div>
            }
          </div>
        </div>

        @if (isShowValidationMessages && !companyAddressValidation()) {<div class="row alert-row">
          <div class="alert alert-danger">
            {{addressValidationTooltip}}
          </div>
        </div>
        }
        <div class="row">
          <div class="col-sm-12 col-lg-9 row">
            <div class="label">
              Home Address:
            </div>
            @if (dealsPerson) {
            <div class="col" [bootstrapTooltip]="addressValidationTooltip" tooltipPlacement="top">
              <input type="search" class="address" [(ngModel)]="dealsPerson.homeAddress" [placeholder]="addressLabel"
                [disabled]="isSkipHomeAddress">
            </div>
            }
          </div>
          @if (dealsPerson) {
          <div class="col checkbox">
            <mat-checkbox type="button" [(ngModel)]="isSkipHomeAddress">Don't know</mat-checkbox>
          </div>
          }
        </div>

        @if (isShowValidationMessages && !homeAddressValidation()) { <div class="row alert-row">
          <div class="alert alert-danger">
            {{addressValidationTooltip}}
          </div>
        </div>
        }
      </div>
      }
      @if (isShowUserList && !isConfirm) {
      <div style="margin-top: 15px;">
        <div>Select user</div>
        <mat-form-field>
          <mat-select placeholder="Select user" panelClass="owner-panel-class-inject">
            @for (item of usersListForMoveDeals; track item) {
            <mat-option [value]="item.id" (click)="selectUserForMove(item)">
              {{ item.name }}
            </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
      }
    </div>
  </div>
  }
</mat-dialog-content>
<mat-dialog-actions>
  <div class="modal-footer text center">
    @if (!isShowUserList && !isConfirm) {
    <div>

      <button type="button" class="btn btn-light waves-light cancel-meeting" (click)="confirm(false)">cancel</button>

      @if (currentUser?.isRepoAccount) {
      <button type="button" class="btn btn-success waves-light update" (click)="showMeetingDropdown()">for another
        clothier</button>
      }

      @if (!currentUser?.isRepoAccount) {
      <button type="button" class="btn btn-success waves-light update" (click)="confirm(true)">
        confirm meeting</button>
      }
    </div>
    }

    @if (isShowUserList && !isConfirm) {
    <div class="select-user-for-move">
      <button type="button" class="btn btn-light waves-light center" (click)="confirm(false)">cancel</button>
    </div>
    }

    @if (isConfirm) {
    <div>
      <div>This meeting is for {{selectedUser.name}}</div>
      <div>
        <button type="button" class="btn btn-light waves-light" (click)="confirm(false)">cancel</button>
        <button type="button" class="btn btn-danger waves-light" (click)="negativeConfirm()">no</button>
        <button type="button" class="btn btn-success waves-light update" (click)="confirm(true)">yes</button>
      </div>
    </div>
    }
  </div>
</mat-dialog-actions>