import { ObjectUtil } from "../utils/object.util";

export class CustomFilterModel {
    public searchString: string;
    public selectStart: number;
    public skip: number;
    public take: number;
    public isNotLetter: number;

    constructor(data?: any) {
        ObjectUtil.extend(data, this);
    }
}
