import { UserContextService } from './../../../core/services/user-context.service';
import { Component, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { FileUploader, FileUploaderOptions } from 'ng2-file-upload';
import { Subject } from 'rxjs';
import { ImageTag } from 'src/app/core/enums/ImageTag.enum';
import { GlobalConstants } from 'src/app/core/global-constants';
import { Deal } from 'src/app/core/models/Deal';
import { ImageService } from 'src/app/core/services/image.service';
import { environment } from 'src/environments/environment';
import { ModalComponent } from '../../modal/modal.component';

@Component({
  selector: 'pd-custom-wardrobe-plan-upload-modal',
  templateUrl: './custom-wardrobe-plan-upload-modal.component.html',
  styleUrls: ['./custom-wardrobe-plan-upload-modal.component.scss']
})
export class CustomUploadWardrobePlanModalComponent {
  @ViewChild('modal') uploadModal: ModalComponent;
  @Output() closeModal = new EventEmitter<object>();
  @ViewChild('fileInput1') fileInputViewChild1: ElementRef;
  @ViewChild('fileInput2') fileInputViewChild2: ElementRef;
  onDestroy$ = new Subject();

  constructor(private imageServ: ImageService, private userContextService: UserContextService) { }

  uploaderOptions: FileUploaderOptions;
  wardrobeUploader1: FileUploader;
  wardrobeUploader2: FileUploader;

  currentDealId: number;
  currentStageId: number;
  deal: Deal;

  isWardrobeloadSuccess1 = false;
  isWardrobeloadSuccess2 = false;

  isWardrobeloadLoading1 = false;
  isWardrobeloadLoading2 = false;

  isWardrobeloadErrorValidation1 = false;
  isWardrobeloadErrorValidation2 = false;

  wardrobeImage1;
  wardrobeImage2;
  allowedImageTypes = ['image/png', 'image/jpg', 'image/jpeg', 'image/gif'];

  fileAccept = this.allowedImageTypes.join(',');

  ngOnInit(): void {
    const config = {
      url: `${environment.apiUrl}/image/uploadDealImage`,
      allowedMimeType: this.allowedImageTypes,
      autoUpload: false,
      removeAfterUpload: true,
      headers: [
        {
          name: 'Authorization',
          value: `Bearer ${localStorage.getItem(GlobalConstants.jwtSessionKey)}`
        },
        {
          name: 'CurrentUserId',
          value: this.userContextService.user.value.id
        }
      ]
    }
    this.wardrobeUploader1 = new FileUploader(config as any);
    this.wardrobeUploader2 = new FileUploader(config as any);

    this.wardrobeUploader1.onAfterAddingFile = (fileItem: any): any => {
      fileItem.imageTag = ImageTag.Wardrobe1;
      this.isWardrobeloadErrorValidation1 = false;
      this.isWardrobeloadSuccess1 = false;
    };

    this.wardrobeUploader2.onAfterAddingFile = (fileItem: any): any => {
      fileItem.imageTag = ImageTag.Wardrobe2;
      this.isWardrobeloadErrorValidation2 = false;
      this.isWardrobeloadSuccess2 = false;
    };

    this.wardrobeUploader1.onWhenAddingFileFailed = (fileItem: any, filter: any, options: any): any => {
      console.log("onWhenAddingFileFailed")
      console.log(fileItem)
      console.log(filter)
      console.log(options)

      this.isWardrobeloadErrorValidation1 = true;
      this.isWardrobeloadSuccess1 = false;
    };

    this.wardrobeUploader2.onWhenAddingFileFailed = (fileItem: any, filter: any, options: any): any => {
      console.log("onWhenAddingFileFailed")
      console.log(fileItem)
      console.log(filter)
      console.log(options)

      this.isWardrobeloadErrorValidation2 = true;
      this.isWardrobeloadSuccess2 = false;
    };

    this.wardrobeUploader1.onBuildItemForm = this.onBuildItemForm;
    this.wardrobeUploader2.onBuildItemForm = this.onBuildItemForm;
    this.wardrobeUploader1.onSuccessItem = this.onSuccessItem;
    this.wardrobeUploader2.onSuccessItem = this.onSuccessItem;
    this.wardrobeUploader1.onErrorItem = this.onErrorItem;
    this.wardrobeUploader2.onErrorItem = this.onErrorItem;
  }

  onBuildItemForm = (fileItem: any, form: FormData): any => {
    form.append('dealId', this.currentDealId.toString());
    form.append('stageId', this.currentStageId.toString());
    form.append('fileName', fileItem.some.name);
    form.append('imageTag', fileItem.imageTag);
    fileItem.withCredentials = false;
    return { fileItem, form };
  };

  onSuccessItem = (fileItem: any, response: string, status: number, headers: any) => {
    console.log("onSuccessItem");
    console.log(fileItem)
    console.log(JSON.parse(response))
    console.log(status)
    console.log(headers)

    if (fileItem.imageTag == ImageTag.Wardrobe1) {
      this.fileInputViewChild1.nativeElement.value = "";
      this.isWardrobeloadSuccess1 = true;
      this.isWardrobeloadLoading1 = false;
    }

    if (fileItem.imageTag == ImageTag.Wardrobe2) {
      this.fileInputViewChild2.nativeElement.value = "";
      this.isWardrobeloadSuccess2 = true;
      this.isWardrobeloadLoading2 = false;
    }

    this.getExistImages(this.currentDealId);
  }

  onErrorItem = (fileItem, response, status, headers) => {
    console.log("onErrorItem");
    console.log(fileItem);
    console.log(response);
    console.log(status);
    console.log(headers);
  }

  onWhenAddingFileFailed = (fileItem: any, filter: any, options: any): any => {
    if (fileItem.imageTag == ImageTag.Wardrobe1) {
      this.isWardrobeloadErrorValidation1 = true;
      this.isWardrobeloadSuccess1 = false;
    }

    if (fileItem.imageTag == ImageTag.Wardrobe2) {
      this.isWardrobeloadErrorValidation2 = true;
      this.isWardrobeloadSuccess2 = false;
    }
  };

  private resetModal() {
    this.deal = undefined;
    this.currentDealId = 0;
    this.currentStageId = 0;
    this.fileInputViewChild1.nativeElement.value = "";
    this.fileInputViewChild2.nativeElement.value = "";
    this.isWardrobeloadSuccess1 = false;
    this.isWardrobeloadSuccess2 = false;
    this.isWardrobeloadLoading1 = false;
    this.isWardrobeloadLoading2 = false;
    this.isWardrobeloadErrorValidation1 = false;
    this.isWardrobeloadErrorValidation2 = false;
  }

  public openUploadModal(deal: Deal) {
    this.deal = deal;
    this.currentDealId = deal.id;
    this.currentStageId = deal.stage_id;
    this.getExistImages(this.currentDealId);
    this.uploadModal.open();
  }

  private getExistImages(currentDealId) {
    this.imageServ.getWardrobeImagesByDealId(currentDealId).then(response => {
      let images = response;
      if (images) {
        this.wardrobeImage1 = images.find(f => f.imageTag == ImageTag.Wardrobe1)
        this.wardrobeImage2 = images.find(f => f.imageTag == ImageTag.Wardrobe2)
        this.deal.wardrobe_image_count = 0;
        if (this.wardrobeImage1) this.deal.wardrobe_image_count += 1;
        if (this.wardrobeImage2) this.deal.wardrobe_image_count += 1;
      }
    });
  }

  public upload() {
    if (this.fileInputViewChild1.nativeElement.value) {
      this.isWardrobeloadLoading1 = true;
      this.wardrobeUploader1.uploadAll();
    }
    if (this.fileInputViewChild2.nativeElement.value) {
      this.isWardrobeloadLoading2 = true;
      this.wardrobeUploader2.uploadAll();
    }
  }

  public close() {
    this.resetModal();
    this.closeModal.emit();
    this.uploadModal.close();
  }
}
