import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { BaseComponent } from '../../core/base.component';
import { AvatarUploadHeadModalComponent } from '../avatar-upload-head-modal/avatar-upload-head-modal.component';
import { AvatarBuilderCanvasComponent } from '../avatar-builder-canvas/avatar-builder-canvas.component';
import { AllowedAvatarTypesForProductStyle, AllowedAvatarTypesForProductType, FabricImage } from '../../core/models/ClientCardConfig';
import { AvatarBuilderData } from '../../core/models/avatar-models/AvatarBuilderData';
import { AvatarCustomizationConfig } from '../../core/models/avatar-models/AvatarCustomizationConfig';
import { AvatarElementTypes } from '../../core/enums/avatar/AvatarElementTypes';
import { AvatarSkinTypes } from '../../core/enums/avatar/AvatarSkinTypes';
import { AvatarTypes } from '../../core/enums/avatar/AvatarTypes';
import { ProductStyles } from '../../core/enums/client-card/ProductStyles';
import { ProductTypes } from '../../core/enums/client-card/ProductTypes';
import { AvatarOption } from '../../core/models/avatar-models/AvatarOption';
import { AvatarBuilderService } from '../../core/services/avatar/avatar-builder.service';
import { AvatarUtil } from '../../core/utils/avatar.util';
import { EnumUtil } from '../../core/utils/enum.util';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'pd-avatar-deal-builder',
  templateUrl: './avatar-deal-builder.component.html',
  styleUrls: ['./avatar-deal-builder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarDealSettingsComponent extends BaseComponent implements OnDestroy {
  @ViewChild('avatarBuilderCanvas') avatarBuilderCanvas: AvatarBuilderCanvasComponent;
  @ViewChild('fileInput') fileInput: ElementRef;

  @Input() public fabricImages: FabricImage[];

  public onSave: any;

  public avatarBuilderData: AvatarBuilderData;
  public avatarCustomizationConfig: AvatarCustomizationConfig;
  public isSpinner = true;
  public isSetting = false;
  public isCustom = false;

  avatarId: number;
  allowedAvatarTypesForProductType: AllowedAvatarTypesForProductType;
  allowedAvatarTypesForProductStyle: AllowedAvatarTypesForProductStyle;
  prevAvatarBuilderData: string;

  constructor(
    private avatarBuilderService: AvatarBuilderService,
    private cdRef: ChangeDetectorRef,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AvatarDealSettingsComponent>
  ) {
    super();
    this.fabricImages = data.fabricImages;
  }

  async ngOnInit() {
    this.setSpinner(true);
    this.avatarCustomizationConfig = await this.avatarBuilderService.getAvatarCustomizationConfig();
    this.avatarCustomizationConfig.avatarTypes.sort((a, b) => a.value.localeCompare(b.value));
    await this.open();
    this.setSpinner(false);
  }

  public ngOnDestroy() {
    this.cdRef.detach();
    super.ngOnDestroy();
  }

  public async open() {
    this.setSpinner(true);

    this.avatarId = this.data.avatarId;
    this.allowedAvatarTypesForProductType = this.data.allowedAvatarTypesForProductType;
    this.allowedAvatarTypesForProductStyle = this.data.allowedAvatarTypesForProductStyle;
    this.isSetting = this.data.isSetting;
    this.isCustom = this.data.isCustom;
    this.onSave = this.data.onSave;
    
    if (this.data.isCustom) {
      this.avatarBuilderCanvas.isLockHead = true;
    }

    this.avatarBuilderData = this.data.avatarBuilderData;
    this.avatarBuilderData.options = AvatarUtil.getSortedOptions(this.avatarBuilderData.options);
    this.prevAvatarBuilderData = JSON.stringify(this.avatarBuilderData);

    const value = await this.avatarBuilderService.getAvatarSvgByOptions(this.avatarBuilderData);
    this.avatarBuilderCanvas.avatarBuilderData = this.avatarBuilderData;
    this.avatarBuilderCanvas.setAvatarStr(value);

    this.setSpinner(false);
  }

  public async uploadHead() {
    this.dialog.open(AvatarUploadHeadModalComponent, {
      disableClose: true,
      data: {
        onSave: this.onUploadHeadModalSave.bind(this)
      }
    });
    this.cdRef.detectChanges();
  }

  public async removeHead() {
    this.avatarBuilderData.options = this.avatarBuilderData.options.filter(o => !this.isHead(o));
    await this.reloadAvatar();
  }

  public async onUploadHeadModalSave(event) {
    this.setSpinner(true);
    const head = this.avatarBuilderData.options.find(o => this.isHead(o));

    if (head) {
      head.imageLink = event.base64;
    }
    else {
      const newHead = await this.avatarBuilderService.generateOptionHead(
        event.base64
        , this.avatarBuilderData.avatarTypeId
        , this.avatarBuilderData.bodyTypeId
        , this.avatarBuilderData.skinTypeId);
      this.avatarBuilderData.options.push(newHead);
    }
    this.avatarBuilderData.options = AvatarUtil.getSortedOptions(this.avatarBuilderData.options);
    await this.reloadAvatar();
  }

  public async updatePart(option: AvatarOption) {
    if (!this.isCustom) {
      const optionSetting = await this.avatarBuilderService.getOptionGlobalSetting(option);
      option.scale = optionSetting.scale;
      option.shadowColor = optionSetting.shadowColor;
    }

    await this.reloadAvatar();
    this.setSpinner(false);
  }

  public onChange(option: AvatarOption) {
    this.avatarBuilderCanvas.updateSingleOption(option);
  }

  getImage(imgUrl) {
    return new Promise<any>(resolve => {
      const img = new Image();
      img.src = imgUrl;
      img.onload = function (event) {
        resolve(img);
      }
    });
  }

  public close() {
    this.avatarBuilderCanvas.container.nativeElement.innerHTML = '';
    this.dialogRef.close();
  }

  public async reloadAvatar() {
    this.setSpinner(true);
    const value = await this.avatarBuilderService.getAvatarSvgByOptions(this.avatarBuilderData);
    const avatar = AvatarUtil.htmlToElement(value) as Element;
    this.avatarBuilderCanvas.container.nativeElement.innerHTML = avatar.outerHTML;
    this.setSpinner(false);
  }

  public save() {
    const isAvatarChanged = this.checkAvatarChanged();

    this.onSave({
      avatarBuilderData: this.avatarBuilderData
      , avatarId: this.avatarId
      , isAvatarChanged: isAvatarChanged
    });
    this.close();
  }

  public getAvatarType(avatarTypeId: number) {
    return EnumUtil.getEnumName(AvatarTypes, avatarTypeId);
  }

  public getSkinType(skinTypeId: number) {
    return EnumUtil.getEnumName(AvatarSkinTypes, skinTypeId);
  }

  public getAvatarElementTypeLabel(avatarElementType: number) {
    let label = EnumUtil.getEnumName(AvatarElementTypes, avatarElementType);
    label = label.split('_')[0];
    return label;
  }

  public getAvatarElementType(avatarElementType: number) {
    return EnumUtil.getEnumName(AvatarElementTypes, avatarElementType).replace(/\s/g, '_');
  }

  public async changeAvatarType(event) {
    this.setSpinner(true);
    this.avatarBuilderData.avatarTypeId = event.value;
    this.avatarBuilderData.options = await this.avatarBuilderService.checkOptionKit(this.avatarBuilderData);
    this.avatarBuilderData.options = AvatarUtil.getSortedOptions(this.avatarBuilderData.options);
    await this.reloadAvatar();
  }

  public async changeBodyType(event) {
    this.avatarBuilderData.bodyTypeId = event.value;
    await this.reloadAvatar();
  }

  public async changeSkinType(event) {
    this.avatarBuilderData.skinTypeId = event.value;
    await this.reloadAvatar();
  }

  public isHead(option: AvatarOption): boolean {
    return option.elementTypeId === AvatarElementTypes.Head;
  }

  public isLockFabric(option: AvatarOption): boolean {

    if (!this.allowedAvatarTypesForProductType || !this.allowedAvatarTypesForProductType.productTypeId) { //TODO: productTypeId as parameter
      return false;
    }

    if (this.allowedAvatarTypesForProductType.productTypeId === ProductTypes.Suits
      && (option.elementTypeId === AvatarElementTypes.Jacket
        || option.elementTypeId === AvatarElementTypes.Pants)) {
      return true;
    }

    if (this.allowedAvatarTypesForProductType.productTypeId === ProductTypes.Blazers
      && (option.elementTypeId === AvatarElementTypes.Jacket)) {
      return true;
    }

    if (this.allowedAvatarTypesForProductStyle
      && this.allowedAvatarTypesForProductStyle.productStyleId == ProductStyles.Overshirt
      && option.elementTypeId === AvatarElementTypes.Shirt) {
      return true;
    }

    if (this.allowedAvatarTypesForProductType.productTypeId === ProductTypes.SmartCasual
      && (option.elementTypeId === AvatarElementTypes.Jacket
        || option.elementTypeId === AvatarElementTypes.Jacket_Type1
        || option.elementTypeId === AvatarElementTypes.Jacket_Type2
        || option.elementTypeId === AvatarElementTypes.Vest
        || option.elementTypeId === AvatarElementTypes.Vest_Type1
      )) {
      return true;
    }

    if (this.allowedAvatarTypesForProductType.productTypeId === ProductTypes.Outerwear
      && (option.elementTypeId === AvatarElementTypes.Coat
        || option.elementTypeId === AvatarElementTypes['Puffy Coat'])) {
      return true;
    }

    if (this.allowedAvatarTypesForProductType.productTypeId === ProductTypes.Shirts
      && (option.elementTypeId === AvatarElementTypes.Shirt)) {
      return true;
    }

    if (this.allowedAvatarTypesForProductType.productTypeId === ProductTypes.Pants
      && (option.elementTypeId === AvatarElementTypes.Pants)) {
      return true;
    }

    return false;
  }

  public filterAvatarTypes() {
    let avatarTypes = [];

    if (this.allowedAvatarTypesForProductStyle) {
      avatarTypes = this.allowedAvatarTypesForProductStyle.allowedAvatarTypes;
    }
    else if (this.allowedAvatarTypesForProductType) {
      avatarTypes = this.allowedAvatarTypesForProductType.allowedAvatarTypes;
    }

    if (avatarTypes && avatarTypes.length > 0) {
      return this.avatarCustomizationConfig.avatarTypes.filter(f => avatarTypes.includes(f.key))
    }

    return this.avatarCustomizationConfig.avatarTypes;
  }

  public get isHasHead(): boolean {
    return this.avatarBuilderData.options.some(o => this.isHead(o));
  }

  private checkAvatarChanged(): boolean {
    return this.prevAvatarBuilderData !== JSON.stringify(this.avatarBuilderData);
  }

  public async fabricChanged(event, option: AvatarOption) {
    option.imageLink = event.imageLink;

    if (!this.isCustom) {
      const optionSetting = await this.avatarBuilderService.getOptionGlobalSetting(option);
      option.scale = optionSetting.scale;
      option.shadowColor = optionSetting.shadowColor;
    }

    await this.reloadAvatar();
  }

  public shadowColorChanged(event, option: AvatarOption) {
    option.shadowColor = event.color;
    const shadowVector = this.getShadowVector(option);
    const paths = shadowVector.getElementsByTagName('path');

    Array.from(paths).forEach((path) => {
      path.style.fill = option.shadowColor;
    });

    this.cdRef.detectChanges();
  }

  public getShadowVector(option: AvatarOption) {
    const vectorid = `shadow_${this.getAvatarElementType(option.elementTypeId).toLowerCase()}_vector`;
    const shadowVector = this.avatarBuilderCanvas.container.nativeElement.querySelector(`#${vectorid}`) as Element;
    return shadowVector;
  }

  public detectChanges() {
    if (!this.cdRef['destroyed']) {
      this.cdRef.detectChanges();
    }
  }

  private setSpinner(value: boolean) {
    this.isSpinner = value;
    this.detectChanges();
  }
}
