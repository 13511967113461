<div class="background">
  @if (isSpinner || !isLoadCompleted || !isAvatarRenderCompleted) {
  <div class="spinner">
    <mat-spinner [diameter]="100"></mat-spinner>
  </div>
  }

  <div class="row header">
    <div class="col title">
      <span>Avatar Collection</span>
    </div>
    <div class="col tool">
      <div class="custom">
        <div class="custom-header">

          <button (click)="addNewAvatarCollection();$event.stopPropagation()" type="button"
            matTooltip="Create collection" class="create-collection-btn waves-light btn-dark btn" style="float: right;">
            <span data-html="true">Create Collection</span>
          </button>

        </div>
      </div>
    </div>
  </div>

  <div class="wrapper">
    <mat-accordion [multi]="true">
      <div>
        <pd-avatar-seasonal-proposal-expansion-panel [avatarCollection]="existingAvatarCollection" [avatars]="avatars"
          [storedCollectionsExpanding]="storedCollectionsExpanding" [isExistingAvatarCollection]="true"
          (onAddAvatar)="addAvatar($event)" (onEditAvatar)="editAvatar($event)" (onDeleteAvatar)="deleteAvatar($event)"
          (onRemoveAvatarFromCollection)="removeAvatarFromCollection($event)"
          (onDownloadAvatar)="downloadAvatar($event)" (onDownloadCollection)="downloadAvatarCollection($event)"
          (onLoadAllAvatars)="loadAllAvatars($event)">
        </pd-avatar-seasonal-proposal-expansion-panel>

        @for (avatarCollection of avatarCollections; track avatarCollection) {
        <pd-avatar-seasonal-proposal-expansion-panel #expansionPanels [avatarCollection]="avatarCollection"
          [avatar2Collections]="avatar2Collections" [avatars]="getAvatarsByCollectionId(avatarCollection.id)"
          [storedCollectionsExpanding]="storedCollectionsExpanding"
          (isSeasonalProposalCollectionChange)="onSetSeasonalProposalCollection($event)"
          (isStylesAvailableCollectionChange)="onSetStylesAvailableCollection($event)"
          (isGroupedViewChange)="onSetGroupedViewChange($event)" (onAddAvatar)="addAvatar($event)"
          (onEditAvatar)="editAvatar($event)" (onDeleteAvatar)="deleteAvatar($event)"
          (onRemoveAvatarFromCollection)="removeAvatarFromCollection($event)"
          (onDownloadAvatar)="downloadAvatar($event)" (onDeleteCollection)="deleteCollection($event)"
          (onDownloadCollection)="downloadAvatarCollection($event)">
        </pd-avatar-seasonal-proposal-expansion-panel>
        }
      </div>
    </mat-accordion>
  </div>
</div>