@if (productType) {
<mat-expansion-panel class="pd-avatar-expansion-panel" (opened)="onOpened()" [expanded]="isExportAction && !isHidden">
  <mat-expansion-panel-header>
    <mat-panel-title>
      <div class="panel-title">
        <span class="product-label">
          {{this.getProductLabel}}
          @if (getAvatarCount() > 0) {
          <span>&nbsp;({{getAvatarCount()}})</span>
          }
        </span>
        <div class="panel-tool">
          @if (!isExportAction && avatars && avatars.length > 0) {
          <button class="tool-btn btn btn-outline-primary" (click)="downloadAll($event)" matTooltip="Download avatars"
            matTooltipPosition="above">
            <i class="material-icons">cloud_download</i>
          </button>
          }
          @if (isAllowAddEditAvatars && !isExportAction && isHasAvatarInProductTypeToProcessing && !isGlobalCollection
          && !isCustomAvatars) {
          <button class="tool-btn btn btn-outline-info" (click)="generateDealAvatars($event)"
            matTooltip="Refresh avatars" matTooltipPosition="above">
            <i class="material-icons">refresh</i>
          </button>
          }
          @if (isShowLoadAll) {
          <button (click)="onLoadAllAvatars($event)" type="button" class="avatar-btn waves-light btn-dark btn"
            placement="bottom" mdbWavesEffect>
            <span class="btn-label">Load All Avatars@if (isLoadingInProgress) {
              <mat-spinner class="spinner" [diameter]="20"></mat-spinner>
              }</span>
          </button>
          }
          @if (isAddAvatar) {
          <button (click)="addCustomAvatar($event)" type="button" matTooltip="Add Avatar"
            class="avatar-btn waves-light btn-dark btn">
            <span data-html="true">Add Avatar</span>
          </button>
          }
        </div>
      </div>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <mat-panel-description>
    <div class="expansion-panel-body">
      @if (!isGroupedView) {
      <pd-avatar-groupped-expansion-panel #groups (onClickAvatar)="clickAvatar($event)"
        (onDeleteAvatar)="deleteAvatar($event)" (onDownloadAvatar)="downloadAvatar($event)"
        (onAddAvatarToCollection)="addAvatarToCollection($event)" [isGlobalCollection]="isGlobalCollection"
        [isCustomAvatars]="isCustomAvatars" [isExportAction]="isExportAction" [isHidden]="isHidden"
        [isAllowAddEditAvatars]="isAllowAddEditAvatars" [isMasterLeadAccount]="isMasterLeadAccount"
        [isAllowAddEditCustomAvatars]="isAllowAddEditCustomAvatars" [avatars]="avatars" [isNeedLoadAvatars]="true"
        [dealId]="dealId"></pd-avatar-groupped-expansion-panel>
      }

      @if (isGroupedView) {
      @for (avatarSortingType of AvatarSortingTypes | enumToArray; track avatarSortingType) {
      <pd-avatar-groupped-expansion-panel #groups (onClickAvatar)="clickAvatar($event)"
        (onDeleteAvatar)="deleteAvatar($event)" (onDownloadAvatar)="downloadAvatar($event)"
        (onAddAvatarToCollection)="addAvatarToCollection($event)" [isGlobalCollection]="isGlobalCollection"
        [isCustomAvatars]="isCustomAvatars" [isExportAction]="isExportAction" [isHidden]="isHidden"
        [isAllowAddEditAvatars]="isAllowAddEditAvatars" [isMasterLeadAccount]="isMasterLeadAccount"
        [isAllowAddEditCustomAvatars]="isAllowAddEditCustomAvatars" [avatarSortingType]="avatarSortingType"
        [avatars]="getAvatarByGroup(avatarSortingType)" [dealId]="dealId"></pd-avatar-groupped-expansion-panel>
      }
      }

    </div>
  </mat-panel-description>
</mat-expansion-panel>
}