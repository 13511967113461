import { Phone, Email } from "./Deal";

export class DealPersonMap {
    public personId: number = 0;
    public dealId: number = 0;
    public stageId: number = 0;
    public stageName: string = "";
    public name: string = "";
    public firstName: string = "";
    public lastName: string = "";
    public company: string = "";
    public address: string = "";
    public homeAddress: string = "";
    public phones: Phone[] = [];
    public emails: Email[] = [];
    public latitude: number;
    public longitude: number;
    public homeLatitude: number;
    public homeLongitude: number;
    public clientLifetimeSpend: number = 0;
    public meetingDate: string = "";
    public mapNumber: number;

    public distanceCompanyToCompany: number;
    public distanceCompanyToHome: number;
    public distanceHomeToCompany: number;
    public distanceHomeToHome:  number;
}

export class PushPinPerson {
    public personId: number = 0;
    public stageId: number = 0;
    public stageName: string = "";
    public distanceToCompany: number;
    public distanceToHome: number;
    public latitude: number;
    public longitude: number;
    public isHome: boolean;
    public meetingDate: string = "";
}