import { Component, EventEmitter, Output, ViewChild, Input } from '@angular/core';
import { InputField } from '../../models/InputField';

@Component({
  selector: 'pd-field-referral-link',
  templateUrl: './field-referral-link.component.html',
  styleUrls: ['./field-referral-link.component.scss']
})
export class FieldReferralLinkComponent  {
  readonly referralLinkInitValue = "https://appointmentsou.com/social-media-hiring/?ref=";

  @ViewChild("inputReferralLink") public inputReferralLink;
  @Output() public referralLinkChange = new EventEmitter<InputField>();
  @Input() public referralLink = this.referralLinkInitValue;

  public changeEdit() {
    let model = {
      value: (this.referralLink && this.referralLink.trim() !== this.referralLinkInitValue) ? this.referralLink : null,
      isValid: this.inputReferralLink.valid
    }
    this.referralLinkChange.emit(model);
  }
}
