<div class="connections">

  <button type="button" class="buttonPoper" (click)="openPopover();$event.stopPropagation()">
    <img class="min-icon" src="../../assets/same-building-icon.png">
  </button>

  @if (isOpened) {
  <div>
    <div class="pop-div" (clickOutside)="cancel();" (click)="$event.stopPropagation()" #popDiv>
    </div>
    <div #popTemplate class="nodrag popover flip" (click)="$event.stopPropagation()">
      @if (!poperSpinner) {
      <div class="poper">
        @if (sameBuildingConnections.dealSameBuildings && sameBuildingConnections.dealSameBuildings.length > 0) {
        <div>
          <div class="title">
            Clients in the Same Building
          </div>
          <hr>
          @for (p of sameBuildingConnections.dealSameBuildings; track p) {
          <div class="information">
            <div class="text-title">
              <strong>{{p.isOpenedForCurrentUser ? p.name : 'Client at'}}</strong>
            </div>
            <div class="text-small">
              <small>{{p.company}}
                @if (p.company) {
                <br>
                }
              </small>
              <small>{{p.position}}</small>
            </div>
            <div class="text-small">
              <small>{{p.location}}</small>
            </div>
            <div class="text-small">
              <small>{{p.address}}</small>
            </div>
          </div>
          }
        </div>
        }
      </div>
      }
      @if (poperSpinner) {
      <div class="poperSpinner">
        <mat-spinner [diameter]="40"></mat-spinner>
      </div>
      }
    </div>
  </div>
  }
</div>