import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ClientRating } from 'src/app/core/enums/ClientRating';
import { EnumUtil } from 'src/app/core/utils/enum.util';
import { BulkMessageRequestApiService } from 'src/app/core/services/bulk-message/bulk-message-request-api.service';
import { RequestStatus } from 'src/app/core/enums/RequestStatus';
import { BulkMessageRequestViewModel } from 'src/app/core/models/bulk-message/BulkMessageRequestViewModel';
import { BulkMessageRequestReportViewModel } from 'src/app/core/models/bulk-message/BulkMessageRequestReportViewModel';
import { FilteredDealDto } from 'src/app/core/models/dto/FilteredDealDto';
import { BulkMessageJobModel } from 'src/app/core/models/task-models/BulkMessageJobModel';
import { TemplateVariableContentTypes } from 'src/app/core/enums/TemplateVariableContentTypes';
import { AlertService } from 'src/app/core/services/alert.service';
import { MessageTemplateViewModel } from 'src/app/core/models/message-template/MessageTemplateViewModel';
import { BulkMessageSettingApiService } from 'src/app/core/services/bulk-message/bulk-message-setting-api.service';
import { BulkMessageSettingViewModel } from 'src/app/core/models/bulk-message/BulkMessageSettingsViewModel';
import { BulkMessageRequestUpdateDto } from 'src/app/core/models/dto/BulkMessageRequestUpdateDto';
import { BulkMessageEmailComponent } from './bulk-message-email/bulk-message-email.component';
import { BulkMessageWhatsappComponent } from './bulk-message-whatsapp/bulk-message-whatsapp.component';
import { MessageTemplateType } from 'src/app/core/enums/MessageTemplateType';
import { MessageTemplateService } from 'src/app/core/services/message-template.service';
import { BulkMessageFacadeService } from 'src/app/core/services/bulk-message/bulk-message-facade.service';
import { Observable } from "rxjs";
import { MessageStatuses } from 'src/app/core/enums/MessageStatuses';

@Component({
  selector: 'pd-bulk-message-requests',
  templateUrl: './bulk-message-requests.component.html',
  styleUrls: ['./bulk-message-requests.component.scss']
})
export class BulkMessageRequestsComponent implements OnInit {
  @ViewChild("emailRequest") public emailRequest: BulkMessageEmailComponent;
  @ViewChild("whatsappRequest") public whatsappRequest: BulkMessageWhatsappComponent;

  @Input() public bulkMessageSetting: BulkMessageSettingViewModel;
  @Input() public templates: MessageTemplateViewModel[];

  @Output() public onClose = new EventEmitter<any>();

  public isArchive: boolean = false;

  public deals: FilteredDealDto[];
  public reportRows: BulkMessageRequestReportViewModel[];
  public filteredReportRows: BulkMessageRequestReportViewModel[];
  public TemplateVariableContentType = TemplateVariableContentTypes;
  public MessageTemplateType = MessageTemplateType;
  public copiedTemplateInfo$: Observable<MessageTemplateViewModel>;

  isEmailToSend: boolean = false;
  isWhatsappToSend: boolean = false;
  scheduledSendDate: Date;

  EnumUtil = EnumUtil;
  MessageStatuses = MessageStatuses;

  private sendBulkMessage = "Do you confirm bulk message using this setting?"
  private messageStatusFilterData: any;

  public spinner: boolean = false;

  public ratings = [
    { label: 'NA clients', count: 0, value: ClientRating.NA, checked: false },
    { label: 'C clients', count: 0, value: ClientRating.C, checked: false },
    { label: 'B clients', count: 0, value: ClientRating.B, checked: false },
    { label: 'A clients', count: 0, value: ClientRating.A, checked: false }
  ];

  constructor(
    private bulkMessageFacadeService: BulkMessageFacadeService,
    private bulkMessageSettingService: BulkMessageSettingApiService,
    private bulkMessageRequestService: BulkMessageRequestApiService,
    private messageTemplateService: MessageTemplateService,
    private alertService: AlertService
  ) {
  }

  public async ngOnInit(): Promise<void> {
    this.spinner = true;
    this.isArchive = this.bulkMessageSetting.isArchived;

    this.copiedTemplateInfo$ = this.bulkMessageFacadeService.getCopiedTemplateSetting();

    if (this.IsNewRequest) {
      await this.loadData();
    }
    else {
      await this.loadReportData();
    }

    this.spinner = false;
  }

  public get IsNewRequest(): boolean {
    let selectedRequest = this.bulkMessageSetting.requests.find(r => r.isSelected);
    return !selectedRequest || selectedRequest.statusId === RequestStatus.New;
  }

  public async onConfigureMessage(event): Promise<void> {
    this.isWhatsappToSend = event.isWhatsApp;
    this.isEmailToSend = event.isEmail;
  }

  private async createTemplate(templateData): Promise<number> {
    const data = new MessageTemplateViewModel();
    data.from = templateData.from;
    data.fromName = templateData.fromName;
    data.emailsCc = templateData.emailsCc;
    data.emailsBcc = templateData.emailsBcc;
    data.subject = templateData.subject;
    data.body = templateData.body;
    data.messageTemplateType = MessageTemplateType.Email;
    data.variableDescriptions = templateData.variableDescriptions;
    data.isNeedUnsubscribeHtml = templateData.isNeedUnsubscribeHtml;

    return await this.messageTemplateService.addTemplate(data);
  }

  private async addRequest(templateData): Promise<number> {
    const data = new BulkMessageRequestUpdateDto();
    data.parentRequestId = (this.IsNewRequest) ? null : this.bulkMessageSetting.requests.find(r => r.isSelected).id;
    data.settingId = this.bulkMessageSetting.id;
    data.templateId = templateData.messageTemplateId;
    data.variables = templateData.variables;
    data.replyRecipientIds = templateData.replyRecipientIds;
    data.expirationDate = templateData.expirationDate;
    data.scheduledSendDate = this.scheduledSendDate;

    if (data.parentRequestId && this.messageStatusFilterData) {
      console.log("this.messageStatusFilterData", this.messageStatusFilterData);
      const dealsFilterData = this.messageStatusFilterData;
      data.isSendToReceived = dealsFilterData.sendToReceived;
      data.isSendToRead = dealsFilterData.sendToRead;
      data.isSendToReplied = dealsFilterData.sendToReplied;
    }
    console.log("data", data);
    return await this.bulkMessageRequestService.addBulkMessageRequest(data);
  }

  public async onSendEmail(event): Promise<void> {
    if (!confirm(this.sendBulkMessage)) {
      return;
    }

    this.spinner = true;

    const templateData = this.emailRequest.getData();
    templateData.messageTemplateId = await this.createTemplate(templateData);
    const requestId = await this.addRequest(templateData);

    const data = new BulkMessageJobModel();
    data.requestId = requestId;
    data.dealIds = (this.IsNewRequest) ? this.deals.map(d => d.dealId) : this.reportRows.map(r => r.dealId);

    await this.bulkMessageRequestService.sendBulkMessage(data);
    this.spinner = false;
    this.onClose.emit();
  }

  public async onSendWhatsapp(event): Promise<void> {
    if (!confirm(this.sendBulkMessage)) {
      return;
    }

    this.spinner = true;

    const templateData = this.whatsappRequest.getData();
    const requestId = await this.addRequest(templateData);

    const data = new BulkMessageJobModel();
    data.requestId = requestId;
    data.dealIds = (this.IsNewRequest) ? this.deals.map(d => d.dealId) : this.reportRows.map(r => r.dealId);

    await this.bulkMessageRequestService.sendBulkMessage(data);
    this.spinner = false;
    this.onClose.emit();
  }

  public async onCancel(): Promise<void> {
    this.onClose.emit();
  }

  private async loadData(): Promise<void> {
    const data = {
      clientRatings: this.bulkMessageSetting.clientRatings,
      pipelines: this.bulkMessageSetting.pipelines,
      locations: this.bulkMessageSetting.locations,
      ownerIds: this.bulkMessageSetting.owners.map(o => o.id),
    }

    this.deals = await this.bulkMessageSettingService.getDealsBySetting(data);
  }

  private async loadReportData(): Promise<void> {
    let selectedRequest = this.bulkMessageSetting.requests.find(r => r.isSelected);
    this.reportRows = await this.bulkMessageRequestService.getRequestReport(selectedRequest.id);
    this.onFilterDealsByMessageStatus(this.messageStatusFilterData);
  }

  public getClientRatingName(clientRating: ClientRating): string {
    return ClientRating[clientRating];
  }

  getPlaceholdersCount(text: string): number {
    const regex = /{{\d+}}/g;
    const matches = text.match(regex);
    return matches ? matches.length : 0;
  }

  public validation() {
    if (this.isEmailToSend) {
      return false;
    }
    else {
      return !this.spinner
    }
  }

  async onRequestSelectChange() {
    if (this.IsNewRequest) {
      await this.loadData();
    }
    else {
      await this.loadReportData();
    }
  }

  getWhatsappTemplates() {
    return this.templates?.filter(f => f.messageTemplateType === MessageTemplateType.Whatsapp);
  }

  onPaste() {
    this.emailRequest.setData(this.bulkMessageFacadeService.getCopiedTemplateSettingSnapshot());
    this.bulkMessageFacadeService.setCopiedTemplateSetting(null);
  }

  onFilterDealsByMessageStatus(filters: { sendToReceived: boolean | null; sendToRead: boolean | null; sendToReplied: boolean | null }) {
    console.log("this.onFilterDealsByMessageStatus filters", filters);

    this.messageStatusFilterData = filters;

    if (!filters) {
      this.filteredReportRows = this.reportRows;
      return;
    }

    this.filteredReportRows = this.reportRows.filter(message => {
      let matchesReceived = true;
      let matchesRead = true;
      let matchesReplied = true;

      console.log("message", message);

      const isReceived = message.isSuccessful;
      const isRead = message.status === MessageStatuses.Read || message.status === MessageStatuses.Unsubscribed || message.status === MessageStatuses.Answered;
      const isAnswered = message.status === MessageStatuses.Answered;

      console.log("isReceived", isReceived);
      console.log("isRead", isRead);
      console.log("isAnswered", isAnswered);

      if (filters.sendToReceived !== null) {
        matchesReceived = filters.sendToReceived ? isReceived : !isReceived;
      }

      if (filters.sendToRead !== null) {
        matchesRead = filters.sendToRead
          ? isRead
          : !isRead;
      }

      if (filters.sendToReplied !== null) {
        matchesReplied = filters.sendToReplied
          ? isAnswered
          : !isAnswered;
      }

      console.log("matchesReceived", matchesReceived);
      console.log("matchesRead", matchesRead);
      console.log("matchesReplied", matchesReplied);

      return matchesReceived && matchesRead && matchesReplied;
    });
  }
}