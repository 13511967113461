import { Injectable} from '@angular/core';
import { AlertTypes } from '../enums/AlertTypes';
import { AlertModalComponent } from 'src/app/shared/alert-modal/alert-modal.component';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

@Injectable()
export class AlertService {
    constructor( private overlay: Overlay) { }

    showAlert(type: AlertTypes, message: string, buttonText: string = 'Noted') {
        const overlayRef = this.overlay.create();

        const alertComponentRef  =  overlayRef.attach(new ComponentPortal(AlertModalComponent));
    
        alertComponentRef.instance.type = type;
        alertComponentRef.instance.message = message;
        alertComponentRef.instance.buttonText = buttonText;
        alertComponentRef.instance.onClose.subscribe(() => {
            alertComponentRef.destroy();
            overlayRef.detach();
        });

        document.body.appendChild(alertComponentRef.location.nativeElement);
    }
}