import { Injectable } from '@angular/core';
import { PaymentProvince } from '../models/payment-models/PaymentProvince';
import { BaseService } from './base.service';
import { HttpRestService } from './http-client/http-rest-client.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class PaymentService extends BaseService {

    constructor(private restService: HttpRestService) {
        super();
    }

    public async getPaymentProvinces(): Promise<Array<PaymentProvince>> {
        return await this.handleResponse(this.restService.get({
            url: '/payment/getPaymentProvinces'
        }));
    }

    public async getClientPaymentProvincesByEmail(email: string): Promise<Array<PaymentProvince>> {
        return await this.handleResponse(this.restService.get({
            url: '/payment/getClientPaymentProvincesByEmail?email=' + email
        }));
    }

    public async getPaymentUrl(amount: string, clientName: string, clientEmail: string, province: string, comments: string): Promise<string> {

        return await this.handleResponse(this.restService.get({
            url: `/payment/bambora/getPaymentUrl?amount=${amount}&province=${province}&clientName=${clientName}&clientEmail=${clientEmail}&comments=${comments}`
        }));
    }

    public async getBraintreeToken(province: string): Promise<string> {
        return (await this.handleResponse(this.restService.get({
            directUrl: `${environment.apiUrl}/payment/braintree/getClientToken?province=${province}`
        }))).token;
    }

    public async payBraintree(nonce, amount: string, clientName: string, clientEmail: string, province: string, comments: string): Promise<any> {
        const url = environment.apiUrl + "/payment/braintree/createPurchase?"
            + 'amount=' + amount
            + '&province=' + province
            + '&clientName=' + clientName
            + '&clientEmail=' + clientEmail
            + '&comments=' + comments;
        return await this.handleResponse(this.restService.post(
            {
                directUrl: url,
                data: { nonce, chargeAmount: amount }
            }));
    }

    public async getRevolutOrderPublicId(amount: string, clientName: string, clientEmail: string, province: string, comments: string): Promise<any> {
        return this.handleResponse(this.restService.get({
            url: `/payment/revolut/createOrder?amount=${amount}&province=${province}&clientName=${clientName}&clientEmail=${clientEmail}&comments=${comments}`
        }));
    }

    public async sentRevolutPaymentResult(id: number, transactionMessage: string): Promise<boolean> {
        return this.handleResponse(this.restService.get({
            url: `/payment/revolut/sentRevolutPaymentResult?id=${id}&transactionMessage=${transactionMessage}`
        }));
    }

    public async createStripePaymentIntent(amount: string, clientName: string, clientEmail: string, province: string, comments: string) {
        return this.handleResponse(this.restService.get({
            url: `/payment/stripe/createPaymentIntent?amount=${amount}&province=${province}&clientName=${clientName}&clientEmail=${clientEmail}&comments=${comments}`
        }));
    }

    public async sentStripePaymentResult(transactionId: string, transactionMessage: string): Promise<boolean> {
        return this.handleResponse(this.restService.get({
            url: `/payment/stripe/sentStripePaymentResult?transactionId=${transactionId}&transactionMessage=${transactionMessage}`
        }));
    }
}
