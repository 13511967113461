<div id="achievements-component">

  @if (isSpinner) {
  <div class="spinner">
    <mat-spinner [diameter]="100"></mat-spinner>
  </div>
  }

  <div class="background">
    <div class="row header">
      <div class="col title">
        @if (ownerName) {
        <span><span>{{ownerName}}’s </span>achievements</span>
        }
        @if (!ownerName) {
        <span>Achievements</span>
        }
      </div>
      <div class="col tool">
        <div class="custom">
          <div class="custom-header">
            <div>
              <mat-slide-toggle class="order-tickmark" [(ngModel)]="isAchievementHistory"
                (click)="$event.stopPropagation()">
                Achievement History</mat-slide-toggle>
            </div>

            @if (isAllowedToSelectUser(currentUser?.role_id)) {
            <div class="users-select">
              <div class="row">
                <div class="col">
                  <pd-custom-select-without-lazy #userFilter (loadDataEvent)="getUsersFilterData()"
                    (selectedItemEvent)="onSelectedItem($event)" (autoCompleteEvent)="getUsersAutocomplete($event)"
                    [dataLabel]="usersLabel" [startLabel]="usersLabel" [isMultiple]="false">
                  </pd-custom-select-without-lazy>
                </div>
              </div>
            </div>
            }

            <button (click)="generateQrCode();$event.stopPropagation()" type="button" mdbBtn
              class="qr-code-btn waves-light btn-dark btn float-right" aria-label="Show QR code"
              mdbTooltip="Show QR code" placement="bottom" mdbWavesEffect>
              <span data-html="true">Show QR code</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    @if (isLoadCompleted && ownerId) {
    <div class="wrapper">
      <mat-accordion [multi]="true">
        @if (isAchievementHistory) {
        <div>
          <div>
            <pd-achievement-expansion-panel [ownerId]='ownerId' [achievementGroup]='achievementHistoryConfig'
              [achievementCardTypes]='achievementCardConfig.achievementCardTypes'
              [isAchievementHistory]='isAchievementHistory'
              [isAchievementGroup]="true"></pd-achievement-expansion-panel>
          </div>
        </div>
        }

        <div>
          @for (group of getAchievementGroups(); track group) {
          <pd-achievement-expansion-panel [ownerId]='ownerId' [achievementGroup]='group'
            [achievementCardTypes]='achievementCardTypeByGroup[group.id]' [isAchievementHistory]='isAchievementHistory'>
          </pd-achievement-expansion-panel>
          }
        </div>
      </mat-accordion>
    </div>
    }
  </div>
</div>