import { Component, EventEmitter, Output, Input } from '@angular/core';
import { RolesEnum } from 'src/app/core/enums/RolesEnum';
import { Role } from 'src/app/core/models/Role';

@Component({
  selector: 'pd-field-type-of-clothier',
  templateUrl: './field-type-of-clothier.component.html',
  styleUrls: ['./field-type-of-clothier.component.scss']
})
export class FieldTypeOfClothierComponent {
  @Output() public isLocalChange = new EventEmitter<any>();
  @Output() public isTravelChange = new EventEmitter<any>();
  @Input() public isLocal: boolean;
  @Input() public isTravel: boolean;
  @Input() public role: Role;

  public changeEdit() {
    this.isLocalChange.emit({ value: this.isLocal, isValid: this.isTravelLocalIsValid });
    this.isTravelChange.emit({ value: this.isTravel, isValid: this.isTravelLocalIsValid });
  }

  public get isTravelLocalIsValid(): boolean {
    return (this.role && (this.role.id !== RolesEnum.Clothier || (this.isLocal || this.isTravel)));
  }

  public get isShowRequired(): boolean {
    return (this.role && (this.role.id === RolesEnum.Clothier));
  }
}
