import { Component, Input, ViewChild, ElementRef, Renderer2, OnInit } from '@angular/core';
import { Deal } from 'src/app/core/models/Deal';
import { NoteComponent } from '../../notes/note-table.component';

@Component({
  selector: 'pd-custom-notes',
  templateUrl: './custom-notes.component.html',
  styleUrls: ['./custom-notes.component.scss']
})

export class CustomNotesComponent implements OnInit{
  @Input() deal: Deal;
  @ViewChild('popDiv') popDiv: ElementRef;
  @ViewChild("popTemplate") popTemplate: ElementRef;
  @ViewChild("notesTable") notesTable: NoteComponent;

  public constructor(private renderer: Renderer2) { }

  isOpened = false
  isLoadTable = false
  poperSpinner = true;
  public note: string;
  public isNotesExist: boolean = false;

  ngOnInit() {
    if (this.deal.isNotesExist) {
      this.isNotesExist = true;
    }
  }

  public cancel() {
    this.isOpened = false;
    this.isLoadTable = false;
    this.note = null;
    this.poperSpinner = true;
  }

  public onTableLoaded(event) {
    this.poperSpinner = false;
    this.isNotesExist = event.notes.length > 0;
  }

  public open() {
    this.isOpened = true;
    this.poperSpinner = true;
    setTimeout(() => { // *ngIf not working with popover. And if you don’t use it, popover rendered on each deal and we lose performance. So, this way of rendering only the necessary popover. 
      this.popDiv.nativeElement.click();
      this.note = this.deal.notes;
      const rect = this.popTemplate.nativeElement.getBoundingClientRect();
      if (rect.x + rect.width > window.innerWidth) {
        this.renderer.setStyle(this.popTemplate.nativeElement, 'transform', `translate(-${rect.width + 40}px,-70px)`);
      }
      this.isLoadTable = true;
    }, 10);
  }

  public clickPopover() {
    if (!this.isOpened) {
      this.open();
    }
    else {
      this.cancel();
    }
  }
}
