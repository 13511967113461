<div class="container">
    <mat-radio-group class="radio-group" (change)="onReceivedStatusChange($event.value)">
        <mat-radio-button class="radio-button" value="received">Client received email/message</mat-radio-button>

        <div *ngIf="sendToReceived" class="sub-container">
            <mat-radio-group class="radio-group" (change)="onReadStatusChange($event.value)">
                <mat-radio-button class="radio-button" value="sendToRead">Client did read the
                    message/email</mat-radio-button>

                <div *ngIf="sendToRead" class="sub-container">
                    <mat-radio-group class="radio-group">
                        <mat-radio-button class="radio-button" value="sendToReplied"
                            (change)="onReplyStatusChange('sendToReplied')">Send only to those who
                            replied</mat-radio-button>
                        <mat-radio-button class="radio-button" value="sendToNotReplied"
                            (change)="onReplyStatusChange('sendToNotReplied')">Send only to those who didn’t
                            reply</mat-radio-button>
                        <mat-radio-button class="radio-button" value="both" [checked]="sendToReplied == null"
                            (change)="onReplyStatusChange('both')">Send to both (who did not replying, who
                            replayed)</mat-radio-button>
                    </mat-radio-group>
                </div>

                <mat-radio-button class="radio-button" value="sendToNotRead">Client didn’t read the
                    message/email</mat-radio-button>
                <mat-radio-button class="radio-button" value="both" [checked]="sendToRead == null">I want to send
                    email/message to everyone (to do is who did read, to do is who didn`t read)</mat-radio-button>
            </mat-radio-group>
        </div>

        <mat-radio-button class="radio-button" value="notReceived">Client didn’t receive the previous
            email/message</mat-radio-button>
        <mat-radio-button class="radio-button" value="both" [checked]="sendToReceived == null">Send to both received and
            not
            received</mat-radio-button>
    </mat-radio-group>
</div>