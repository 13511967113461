import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { InputField } from '../../models/InputField';

@Component({
  selector: 'pd-field-confirm-password',
  templateUrl: './field-confirm-password.component.html',
  styleUrls: ['./field-confirm-password.component.scss']
})
export class FieldConfirmPasswordComponent {

  public confirmPassword: string;
  @ViewChild("inputConfirmPassword") inputConfirmPassword;
  @Output() confirmPasswordChange = new EventEmitter<InputField>();

  public changeEdit() {
    let model = {
      value: this.confirmPassword,
      isValid: this.inputConfirmPassword.valid
    }
    this.confirmPasswordChange.emit(model);
  }
}
