<div class="row wrapper">
  <div class="col-4 title">
    Notes
  </div>
  <div class="col-8 work-field">
    @if (!notes && !isEdit) {
    <pd-add-value (addValue)="addValue()"></pd-add-value>
    }
    @if (isEdit || notes) {
    <div class="md-form" (document:focusout)="changeEdit()">
      <input [(ngModel)]="notes" mdbActive type="text">
    </div>
    }
  </div>
</div>