import { Component, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { BaseComponent } from 'src/app/core/base.component';

@Component({
  selector: 'pd-color-selector',
  templateUrl: './color-selector.component.html',
  styleUrls: ['./color-selector.component.scss']
})

export class ColorSelectorComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() public isDisabled = false;
  @Input() public color: string;
  @Output() public onColorSelected = new EventEmitter<any>();
  
  public label: string;
  public presetColors: Array<any> = [
    { label: 'Red', color: '#ff0000' },
    { label: 'Green', color: '#00ff00' },
    { label: 'Blue', color: '#0000ff' },
    { label: 'Yellow', color: '#ffff00' },
    { label: 'Magenta', color: '#ff00ff' },
    { label: 'Cyan', color: '#00ffff' },
    { label: 'Grey', color: '#a7a7a7' }
  ];
  
  constructor() {
    super();
  }

  ngOnInit() {
    this.setLabel();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  public setColor() {
    console.log('setColor', this.color);
    this.setLabel();
    this.onColorSelected.emit({ color: this.color });
  }

  setLabel() {
    const presetColor = this.presetColors.find(x => x.color === this.color);
    console.log('presetColor', presetColor);
    console.log('color', this.color);
    if (presetColor) {
      this.label = "Shadow color";
      return;
    }
    this.label = 'Custom';
  }


  //////////////////////////////// Events START //////////////////////////////

  //////////////////////////////// Events END //////////////////////////////

  //////////////////////////////// Private START //////////////////////////////

  //////////////////////////////// Private END //////////////////////////////

  //////////////////////////////// Helpers START ////////////////////////////////

  //////////////////////////////// Helpers END ////////////////////////////////

  //////////////////////////////// View *ngIf START //////////////////////////////

  //////////////////////////////// View *ngIf END //////////////////////////////
}
