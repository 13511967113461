import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'pd-field-address',
  templateUrl: './field-address.component.html',
  styleUrls: ['./field-address.component.scss']
})
export class FieldAddressComponent  {

  public address: string;
  @Output() addressChange = new EventEmitter<string>();
  public isEdit = false;

  /**
    * Change edit
    * in deal
    * @memberof FieldAddressComponent
    */

  public changeEdit() {
    this.isEdit = false;
    this.addressChange.emit(this.address);
  }

  /**
    * Add value
    * open edit
    * @memberof FieldAddressComponent
    */

  public addValue() {
    this.isEdit = true;
  }
}
