import { Component, OnInit, Input, Output, EventEmitter, ViewChild, Inject, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { CustomSelectFilterWithoutLazyComponent } from '../shared/custom/custom-select-without-lazy/custom-select-without-lazy';
import { AchievementCardConfig, AchievementCardType, AchievementGroup } from '../core/models/AchievementCardConfig';
import { AchievementCardModel } from '../core/models/AchievementCardModel';
import { UtilsHelper } from '../core/utils/utils.helper';
import { User } from '../core/models/UserModel';
import { RolesEnum } from '../core/enums/RolesEnum';
import { ReplaySubject, takeUntil } from 'rxjs';
import { QrCodeModalComponent } from '../shared/custom/custom-qr-code-modal/custom-qr-code-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { UserContextService } from '../core/services/user-context.service';
import { AchievementCardFacadeService } from '../core/services/achievement-card/achievement-card-facade.service';
import { BaseComponent } from '../core/base.component';
import { UsersService } from '../core/services/users.service';

@Component({
  selector: 'pd-achievements',
  templateUrl: './achievements.component.html',
  styleUrls: ['./achievements.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class AchievementsComponent extends BaseComponent implements OnInit {
  @ViewChild("userFilter") customSelectUser: CustomSelectFilterWithoutLazyComponent;

  @Input() ownerId: number;
  @Input() ownerName: string;

  achievementCardTypeByGroup: Array<Array<AchievementCardType>>;
  achievementHistoryConfig: AchievementGroup;
  achievementHistoryId: number = -2;
  achievementHistoryName: string = 'Achievements';

  isAchievementHistory: boolean = false;

  isSpinner: boolean = false;

  achievementCardConfig: AchievementCardConfig;

  isLoadOnStart: boolean = true;
  isLoadCompleted: boolean = false;

  utilsHelper = UtilsHelper;

  currentUser: User;
  users: User[];
  RolesEnum = RolesEnum;

  filteredUserMultiSelect: ReplaySubject<User[]> = new ReplaySubject<User[]>(1);
  filteredUsers = [];
  prevfilterUserIds = [];
  filterUserIds = [];
  allFilteredUsers;

  usersLabel: string = 'Clothiers'

  constructor(
    private userContextService: UserContextService
    , private cdRef: ChangeDetectorRef
    , private achievementCardFacadeService: AchievementCardFacadeService
    , private usersService: UsersService
    , private dialog: MatDialog) {
    super();
  }

  async ngOnInit() {
    this.initializeStore();

    this.achievementHistoryConfig = new AchievementGroup();
    this.achievementHistoryConfig.id = this.achievementHistoryId;
    this.achievementHistoryConfig.name = this.achievementHistoryName;

    this.achievementCardFacadeService.getAchievementCards()
      .pipe(takeUntil(this.destroy$))
      .subscribe((achievementCards: AchievementCardModel[]) => {
        this.cdRef.detectChanges();
      });
  }

  private initializeStore() {
    this.currentUser = this.userContextService.user.value;
  }

  ngOnDestroy() {
  }

  async ngAfterViewInit() {
    const users = await this.usersService.getUsers();
    this.allFilteredUsers = users.filter(item => this.selectFilterUsers(item.role_id));

    this.getUsersFilterData();
    if (!this.customSelectUser) {
      this.initializeUsersFilter();
    }
  }

  initializeUsersFilter() {
    if (this.customSelectUser) {
      this.filterUserIds = this.customSelectUser.dataArray.map((u) => u.id);
    }

    if (!this.filterUserIds || this.filterUserIds.length == 0) {
      this.filterUserIds = new Array();
      this.filterUserIds.push(this.currentUser.id);
      this.ownerId = this.currentUser.id;
      this.ownerName = this.currentUser.name;
    }

    const isUserChanged = this.checkFilteredUsersChanged();

    if (isUserChanged && this.customSelectUser) {
      this.ownerId = this.filterUserIds[0];
      this.ownerName = this.customSelectUser.dataArray[0].name;
    }

    this.prevfilterUserIds = JSON.parse(JSON.stringify(this.filterUserIds));

    if (this.isLoadOnStart) {
      this.loadAchievementsData();
    }
  }

  async loadAchievementsData() {
    if (this.ownerId) {
      this.achievementCardConfig = new AchievementCardConfig();
      this.achievementCardConfig = await this.achievementCardFacadeService.getAchievementCardConfig();
      await this.achievementCardFacadeService.loadAchievementCardsByDealId(this.ownerId);

      this.achievementCardTypeByGroup = new Array<Array<AchievementCardType>>();
      this.achievementCardConfig.achievementCardTypes.forEach(element => {
        this.achievementCardTypeByGroup[element.id] = this.filterTypesByGroup(element.id)
      });

      this.isLoadCompleted = true;
    }

    this.cdRef.detectChanges();
  }

  private filterTypesByGroup(achievementGroupId: number): Array<AchievementCardType> {
    return this.achievementCardConfig.achievementCardTypes.filter(c => c.achievementGroupId === achievementGroupId);
  }

  public getAchievementGroups() {
    return this.achievementCardConfig.achievementGroups;
  }

  public generateQrCode() {
    this.dialog.open(QrCodeModalComponent, { data: { userId: this.ownerId, title: this.ownerName, isClientCard: false } });
  }

  public isAllowedToSelectUser(roleId) {
    return roleId === RolesEnum.Admin
      || roleId === RolesEnum.MasterLead;
  }

  public selectFilterUsers(roleId) {
    return roleId === RolesEnum.Admin
      || roleId === RolesEnum.Clothier
      || roleId === RolesEnum.MasterLead;
  }

  //////////////////////////////// User filter START //////////////////////////////

  getUsersFilterData() {
    this.filteredUsers = [];
    this.filteredUsers = this.filteredUsers.concat(this.allFilteredUsers);
    this.filteredUserMultiSelect.next(this.allFilteredUsers.slice());
    if (this.customSelectUser) {
      this.customSelectUser.setData(this.filteredUsers);
      if (this.customSelectUser.dataArray.map((u) => u.id).length == 0) {
        this.customSelectUser.selectItem(this.currentUser, { checked: true });
      }
    }
  }

  getUsersAutocomplete(event) {
    let search = event.searchString;
    if (!search) {
      this.filteredUserMultiSelect.next(this.allFilteredUsers.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredUserMultiSelect.next(
      this.allFilteredUsers.filter(loc => loc.name.toLowerCase().indexOf(search) > -1)
    );
    this.filteredUsers = [];
    this.filteredUserMultiSelect.subscribe(x => {
      for (let i = 0; i < x.length; i++) {
        if (this.filteredUsers.length == 0) {
          this.filteredUsers.push(x[0]);
        } else {
          if (!this.filteredUsers.includes(x[i], 0)) {
            this.filteredUsers.push(x[i]);
          }
        }
      }
    });
    this.customSelectUser.setData(this.filteredUsers);
  }

  onSelectedItem(checked) {
    if (checked) {
      this.initializeUsersFilter();
    }
    else {
      this.getUsersFilterData();
      this.initializeUsersFilter();
    }
  }

  private checkFilteredUsersChanged() {
    if (JSON.stringify(this.prevfilterUserIds) !== JSON.stringify(this.filterUserIds)) {
      return true;
    }
    else {
      return false;
    }
  }

  //////////////////////////////// User filter END //////////////////////////////
}