import { Component, Input } from '@angular/core';
import { ContactType } from 'src/app/core/enums/ContactType';
import { PersonContactInfo } from 'src/app/core/models/dto/PersonContactInfo';

@Component({
    selector: 'pd-deal-card-contacts',
    templateUrl: './deal-card-contacts.component.html',
    styleUrls: ['./deal-card-contacts.component.scss']
})

export class DealCardContactsComponent {
    @Input() contacts: Array<PersonContactInfo>

    constructor() { }

    public getIconSrc(contact: PersonContactInfo): string {
        if (contact.contactTypeId == ContactType.Phone) {
            if (contact.label.toLowerCase() === 'work') {
                return '../../../assets/workPhone.png';
            }
            return '../../../assets/otherPhones.png';
        }

        if (contact.label.toLowerCase() === 'work') {
            return '../../../assets/workEmail.png';
        }
        return '../../../assets/otherEmails.png';
    }

    public getTooltipLabel(contact: PersonContactInfo): string {
        if (contact.contactTypeId == ContactType.Phone) {
            return 'copy phone';
        }
        return 'copy email';
    }
}