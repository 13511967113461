import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { AvatarBuilderService } from '../../core/services/avatar/avatar-builder.service';
import { saveAs } from 'file-saver';
import { UtilsHelper } from '../../core/utils/utils.helper';
import { AvatarOption } from '../../core/models/avatar-models/AvatarOption';
import { AvatarCollection } from '../../core/models/avatar-models/AvatarCollection';
import { AvatarTypes } from '../../core/enums/avatar/AvatarTypes';
import { AvatarSkinTypes } from '../../core/enums/avatar/AvatarSkinTypes';
import { Avatar2Collection } from '../../core/models/avatar-models/Avatar2Collection';
import { AvatarElementTypes } from '../../core/enums/avatar/AvatarElementTypes';
import { AvatarCustomizationConfig } from '../../core/models/avatar-models/AvatarCustomizationConfig';
import { AvatarBuilderData } from '../../core/models/avatar-models/AvatarBuilderData';
import { AvatarUtil } from '../../core/utils/avatar.util';
import { FabricImage } from '../../core/models/ClientCardConfig';
import cloneDeep from 'lodash-es/cloneDeep';
import { AvatarBuilderCanvasComponent } from '../avatar-builder-canvas/avatar-builder-canvas.component';
import { BaseComponent } from '../../core/base.component';
import { AvatarUploadHeadModalComponent } from '../avatar-upload-head-modal/avatar-upload-head-modal.component';
import { EnumUtil } from '../../core/utils/enum.util';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'pd-avatar-builder',
  templateUrl: './avatar-builder.component.html',
  styleUrls: ['./avatar-builder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarBuilderComponent extends BaseComponent implements OnDestroy {
  @ViewChild('avatarBuilderCanvas') avatarBuilderCanvas: AvatarBuilderCanvasComponent;
  @ViewChild('fileInput') fileInput: ElementRef;

  @Input() avatarCollections: AvatarCollection[];
  @Input() avatarsToCollections: Avatar2Collection[];
  @Input() fabricImages: FabricImage[];

  onSave: any;

  public avatarBuilderData: AvatarBuilderData;
  public avatarCustomizationConfig: AvatarCustomizationConfig;
  public isSpinner = true;

  leftViewSelectList: AvatarCollection[];
  rightViewSelectList: AvatarCollection[];

  avatarId: number;
  originalAvatarId: number;
  originalAvatarCollectionId: number;
  prevAvatarBuilderData: AvatarBuilderData;
  prevCollections: AvatarCollection[];

  constructor(
    private avatarBuilderService: AvatarBuilderService,
    private cdRef: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AvatarBuilderComponent>,
    private dialog: MatDialog,
  ) {
    super();
    this.avatarCollections = data.avatarCollections;
    this.avatarsToCollections = data.avatarsToCollections;
    this.fabricImages = data.fabricImages;
  }

  async ngOnInit() {
    this.setSpinner(true);
    this.open();
    this.avatarCustomizationConfig = await this.avatarBuilderService.getAvatarCustomizationConfig();
    this.avatarCustomizationConfig.avatarTypes.sort((a, b) => a.value.localeCompare(b.value));
    this.setSpinner(false);
  }

  public ngOnDestroy() {
    this.cdRef.detach();
    super.ngOnDestroy();
  }

  public async open() {
    this.avatarId = this.data.avatarId;
    this.originalAvatarId = this.data.originalAvatarId;
    this.originalAvatarCollectionId = this.data.originalAvatarCollectionId;
    this.prevCollections = cloneDeep(this.data.collections);
    this.onSave = this.data.onSave;

    this.avatarBuilderData = this.data.avatarBuilderData;
    this.avatarBuilderData.options = AvatarUtil.getSortedOptions(this.avatarBuilderData.options);
    this.prevAvatarBuilderData = cloneDeep(this.avatarBuilderData);

    this.rightViewSelectList = this.data.collections;
    this.leftViewSelectList = this.avatarCollections.filter(a => !this.rightViewSelectList.some(r => r.id == a.id));
    this.sortLists();

    const value = await this.avatarBuilderService.getAvatarSvgByOptions(this.avatarBuilderData);
    this.avatarBuilderCanvas.avatarBuilderData = this.avatarBuilderData;
    this.avatarBuilderCanvas.setAvatarStr(value);
  }

  public async downloadAvatarSvg() {
    this.setSpinner(true);
    const base64 = await this.avatarBuilderService.downloadAvatarSvgByOptions(this.avatarBuilderData);
    const byteArray = UtilsHelper.base64ToArrayBuffer(base64);
    const blob: any = new Blob([byteArray], { type: 'image/png' });
    saveAs(blob, 'Avatar.png', true);
    this.setSpinner(false);
  }

  public async uploadHead() {
    this.dialog.open(AvatarUploadHeadModalComponent, {
      disableClose: true,
      data: {
        onSave: this.onUploadHeadModalSave.bind(this)
      }
    });
    this.detectChanges();
  }

  public async removeHead() {
    this.avatarBuilderData.options = this.avatarBuilderData.options.filter(o => !this.isHead(o));
    await this.reloadAvatar();
  }

  public async onUploadHeadModalSave(event) {
    this.setSpinner(true);
    const head = this.avatarBuilderData.options.find(o => this.isHead(o));

    if (head) {
      head.imageLink = event.base64;
    }
    else {
      const newHead = await this.avatarBuilderService.generateOptionHead(
        event.base64
        , this.avatarBuilderData.avatarTypeId
        , this.avatarBuilderData.bodyTypeId
        , this.avatarBuilderData.skinTypeId);
      this.avatarBuilderData.options.push(newHead);
    }
    this.avatarBuilderData.options = AvatarUtil.getSortedOptions(this.avatarBuilderData.options);
    await this.reloadAvatar();
    this.setSpinner(false);
  }

  public async updatePart(option: AvatarOption) {
    this.setSpinner(true);
    const optionSetting = await this.avatarBuilderService.getOptionGlobalSetting(option);
    option.scale = optionSetting.scale;
    option.shadowColor = optionSetting.shadowColor;
    await this.reloadAvatar();
    this.setSpinner(false);
  }

  public async changeAvatarType(event) {
    this.setSpinner(true);
    this.avatarBuilderData.avatarTypeId = event.value;
    this.avatarBuilderData.options = await this.avatarBuilderService.checkOptionKit(this.avatarBuilderData);
    this.avatarBuilderData.options = AvatarUtil.getSortedOptions(this.avatarBuilderData.options);
    await this.reloadAvatar();
    this.setSpinner(false);
  }

  public async changeBodyType(event) {
    this.avatarBuilderData.bodyTypeId = event.value;
    await this.reloadAvatar();
  }

  public async changeSkinType(event) {
    this.avatarBuilderData.skinTypeId = event.value;
    await this.reloadAvatar();
  }

  public onChange(option: AvatarOption) {
    this.avatarBuilderCanvas.updateSingleOption(option);
  }

  getImage(imgUrl) {
    return new Promise<any>(resolve => {
      const img = new Image();
      img.src = imgUrl;
      img.onload = function (event) {
        resolve(img);
      }
    });
  }

  public close() {
    this.dialogRef.close();
    this.leftViewSelectList = null;
    this.rightViewSelectList = null;
    this.avatarBuilderCanvas.container.nativeElement.innerHTML = '';
    this.detectChanges();
  }

  public async addToCollection(collection: AvatarCollection) {
    let index = this.leftViewSelectList.indexOf(collection);
    if (index > -1) {
      this.leftViewSelectList.splice(index, 1);
    }
    this.rightViewSelectList.push(collection);
    this.sortLists();
  }

  public async removeFromCollection(collection: AvatarCollection) {
    let index = this.rightViewSelectList.indexOf(collection);
    if (index > -1) {
      this.rightViewSelectList.splice(index, 1);
    }
    this.leftViewSelectList.push(collection);
    this.sortLists();
  }

  sortLists() {
    if (this.leftViewSelectList && this.leftViewSelectList.length > 1) {
      this.leftViewSelectList.sort((a: any, b: any) => a.collectionName.toString().localeCompare(b.collectionName));
    }
    if (this.rightViewSelectList && this.rightViewSelectList.length > 1) {
      this.rightViewSelectList.sort((a: any, b: any) => a.collectionName.toString().localeCompare(b.collectionName));
    }
  }

  public getAvatarType(avatarTypeId: number) {
    return EnumUtil.getEnumName(AvatarTypes, avatarTypeId);
  }

  public getSkinType(skinTypeId: number) {
    return EnumUtil.getEnumName(AvatarSkinTypes, skinTypeId);
  }

  public getCollectionsByAvatarId(avatarId: number): Array<AvatarCollection> {
    if (this.avatarCollections && this.avatarCollections.length > 0 && this.avatarsToCollections && this.avatarsToCollections.length > 0) {
      const relationships = this.avatarsToCollections.filter(c => c.avatarId === avatarId);
      const avatarCollections = this.avatarCollections.filter(a => relationships.some(r => r.avatarCollectionId === a.id));
      return avatarCollections;
    }
    return new Array<AvatarCollection>();
  }

  public async reloadAvatar() {
    this.setSpinner(true);
    const value = await this.avatarBuilderService.getAvatarSvgByOptions(this.avatarBuilderData);
    const avatar = AvatarUtil.htmlToElement(value) as Element;
    this.avatarBuilderCanvas.container.nativeElement.innerHTML = avatar.outerHTML;
    this.setSpinner(false);
  }

  public detectChanges() {
    if (!this.cdRef['destroyed']) {
      this.cdRef.detectChanges();
    }
  }

  public save() {
    const isAvatarChanged = this.checkAvatarChanged();
    const isCollectionsChanged = this.checkCollectionsChanged();

    if (this.onSave) {
      this.onSave({
        avatarBuilderData: this.avatarBuilderData
        , collections: this.rightViewSelectList
        , avatarId: this.avatarId
        , originalAvatarId: this.originalAvatarId
        , originalAvatarCollectionId: this.originalAvatarCollectionId
        , isAvatarChanged: isAvatarChanged
        , isCollectionsChanged: isCollectionsChanged
      })
    }

    this.close();
  }

  private checkAvatarChanged(): boolean {
    return JSON.stringify(this.prevAvatarBuilderData) !== JSON.stringify(this.avatarBuilderData);
  }

  private checkCollectionsChanged(): boolean {
    return JSON.stringify(this.prevCollections) !== JSON.stringify(this.rightViewSelectList);
  }

  public get isHasHead(): boolean {
    return this.avatarBuilderData.options.some(o => this.isHead(o));
  }

  public getAvatarElementTypeLabel(avatarElementType: number) {
    let label = EnumUtil.getEnumName(AvatarElementTypes, avatarElementType);
    label = label.split('_')[0];
    return label;
  }

  public getAvatarElementType(avatarElementType: number) {
    return EnumUtil.getEnumName(AvatarElementTypes, avatarElementType).replace(/\s/g, '_');
  }

  public isHead(option: AvatarOption): boolean {
    return option.elementTypeId === AvatarElementTypes.Head;
  }

  public async fabricChanged(event, option: AvatarOption) {
    this.setSpinner(true);
    const optionSetting = await this.avatarBuilderService.getOptionGlobalSetting(option);
    option.scale = optionSetting.scale;
    option.shadowColor = optionSetting.shadowColor;
    option.imageLink = event.imageLink;
    await this.reloadAvatar();
    this.setSpinner(false);
  }

  public shadowColorChanged(event, option: AvatarOption) {
    option.shadowColor = event.color;
    const shadowVector = this.getShadowVector(option);
    const paths = shadowVector.getElementsByTagName('path');

    Array.from(paths).forEach((path) => {
      path.style.fill = option.shadowColor;
    });

    this.detectChanges();
  }

  public getShadowVector(option: AvatarOption) {
    const vectorid = `shadow_${this.getAvatarElementType(option.elementTypeId).toLowerCase()}_vector`;
    const shadowVector = this.avatarBuilderCanvas.container.nativeElement.querySelector(`#${vectorid}`) as Element;
    return shadowVector;
  }

  private setSpinner(value: boolean) {
    this.isSpinner = value;
    this.detectChanges();
  }
}
