import uniqueId from 'lodash-es/uniqueId';
import { Component, Input, Output, EventEmitter, OnInit, ViewChild, ElementRef } from '@angular/core';

@Component({
    selector: 'modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
    @Input() public primaryBtnTextKey = "Ok";
    @Input() public secondaryBtnTextKey = "Close";
    @Input() public additionalBtnTextKey = "Cancel";
    @Input() public launchButtonText = "Launch modal";
    @Input() public title: string;
    @Input() public isInformational = false;
    @Input() public displayLaunchButton = true;
    @Input() public minWidth = 500;
    @Input() public loadingIndicator = false;
    @Input() public loadingIndicatorAbsolute = false;
    @Input() public isPrimaryBtnDisabled = false;
    @Input() public isSecondaryBtnDisabled = false;
    @Input() public isPrimaryBtnVisible = true;
    @Input() public isSecondaryBtnVisible = true;
    @Input() public isAdditionalBtnVisible = false;

    @Output() public secondaryClick: EventEmitter<void> = new EventEmitter<void>();
    @Output() public primaryClick: EventEmitter<void> = new EventEmitter<void>();
    @Output() public additionalClick: EventEmitter<void> = new EventEmitter<void>();
    @Output() public onCompleteAction: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild('toggle') toggleModal: ElementRef<HTMLElement>;
    @ViewChild('close') closeModal: ElementRef<HTMLElement>;

    public id: string;
    public targetAttr: string;
    public ariaLabelledbyAttr: string

    public ngOnInit() {
        this.id = uniqueId('id_');
        this.targetAttr = '#' + this.id;
        this.ariaLabelledbyAttr = 'staticBackdropLabel' + this.id;
    }

    public open() {
        this.toggleModal.nativeElement.click();
    }

    public close() {
        this.loadingIndicator = false;
        this.closeModal.nativeElement.click();
    }
}
