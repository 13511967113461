import { Component, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Inject } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { UploadImageService } from 'src/app/core/services/upload-image.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'pd-avatar-upload-head-modal',
  templateUrl: './avatar-upload-head-modal.component.html',
  styleUrls: ['./avatar-upload-head-modal.component.scss']
})

export class AvatarUploadHeadModalComponent implements OnInit {
  @ViewChild('fileInput') fileInput: ElementRef;

  onSave: any;
  imageChangedEvent: any = '';
  public croppedImage: string;

  public fileAccept = UploadImageService.allowedImageTypes.join(',');

  constructor(
    private ref: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AvatarUploadHeadModalComponent>,
  ) {
    this.onSave = data.onSave;
    console.log(data);
  }

  ngOnInit() {
  }

  public close() {
    this.dialogRef.close();
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.detectChanges();
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.detectChanges();
  }

  imageLoaded(event) {
  }

  cropperReady(event) {
  }

  loadImageFailed(event) {
  }

  async saveImage() {
    this.detectChanges();
    if (this.onSave) {
      this.onSave({ base64: this.croppedImage });
    }
    this.close();
  }

  public openInput() {
    this.fileInput.nativeElement.click();
  }

  private detectChanges() {
    if (!this.ref['destroyed']) {
      this.ref.detectChanges();
    }
  }
}
