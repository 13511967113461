export enum AvatarTypes {
    '1 Button Notch' = 1
    , '1 Button Notch Cuffs'
    , '1 Button Peak'
    , '1 Button Peak Cuffs'
    , '2 Button Notch'
    , '2 Button Notch Cuffs'
    , '2 Button Peak'
    , '2 Button Peak Cuffs'
    , '3 Button Notch'
    , '3 Button Notch Cuffs'
    , '3 Button Peak'
    , '3 Button Peak Cuffs'
    , '2x2 Doublebreasted Notch'
    , '2x2 Doublebreasted Notch Cuffs'
    , '2x2 Doublebreasted Peak'
    , '2x2 Doublebreasted Peak Cuffs'
    , '3x2 Doublebreasted Notch'
    , '3x2 Doublebreasted Notch Cuffs'
    , '3x2 Doublebreasted Peak'
    , '3x2 Doublebreasted Peak Cuffs'
    , 'Shirt'
    , '1 Buttontuxedo'
    , '1 Buttontuxedo Cuffs'
    , '2 Buttontuxedo'
    , '2 Buttontuxedo Cuffs'
    , '1 Button Notch Double Breasted Vest'
    , '1 Button Notch Double Breasted Vest Cuffs'
    , '2 Button Notch Double Breasted Vest'
    , '2 Button Notch Double Breasted Vest Cuffs'
    , '1 Button Notch Single Breasted Vest'
    , '1 Button Notch Single Breasted Vest Cuffs'
    , '2 Button Notch Single Breasted Vest'
    , '2 Button Notch Single Breasted Vest Cuffs'
    , '1 Button Peak Double Breasted Vest'
    , '1 Button Peak Double Breasted Vest Cuffs'
    , '2 Button Peak Double Breasted Vest'
    , '2 Button Peak Double Breasted Vest Cuffs'
    , '1 Button Peak Single Breasted Vest'
    , '1 Button Peak Single Breasted Vest Cuffs'
    , '2 Button Peak Single Breasted Vest'
    , '2 Button Peak Single Breasted Vest Cuffs'
    , 'Nehru'
    , 'Overshirt'
    , 'Balmacaan'
    , 'Overcoat'
    , 'Topcoat'
    , 'Bomber'
    , 'Trench Coat'
    , 'Safari Jacket Without Belt'
    , 'Insulated Vest'
    , 'Insulated Vest Shirt'
    , 'Safari Jacket With Belt'
    , 'Car Coat'
    , 'Puffy Coat'
    , 'Pants'
    , 'T-shirt'
    , 'Polo'
}
