export enum PaymentCurrencies {
  None,
  AUD,
  AED,
  HKD,
  NZD,
  EUR,
  USD,
  CAD,
  GBP,
}