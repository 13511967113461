import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'pd-add-value',
  templateUrl: './add-value.component.html',
  styleUrls: ['./add-value.component.scss']
})
export class AddValueComponent {

  @Output() addValue = new EventEmitter<string>();

  changeValue() {
    this.addValue.emit();
  }
}
