import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { BulkMessageTableComponent } from './bulk-message-table/bulk-message-table.component';
import { BulkMessageSettingsComponent } from './bulk-message-settings.component';
import { QuillModule } from 'ngx-quill';
import { BulkMessageFilterComponent } from './bulk-message-filter/bulk-message-filter.component';
import { BulkMessageRequestsComponent } from './bulk-message-requests/bulk-message-requests.component';
import { BulkMessageEmailComponent } from './bulk-message-requests/bulk-message-email/bulk-message-email.component';
import { BulkMessageWhatsappComponent } from './bulk-message-requests/bulk-message-whatsapp/bulk-message-whatsapp.component';
import { EmailChipsFieldComponent } from './bulk-message-requests/bulk-message-email/email-chips-field/email-chips-field.component';
import { BulkMessageRequestTreeComponent } from './bulk-message-request-tree/bulk-message-request-tree.component';
import { BulkMessageStatusFilterComponent } from './bulk-message-status-filter/bulk-message-status-filter.component';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        QuillModule,
        TimepickerModule
    ],
    declarations: [
        BulkMessageFilterComponent,
        BulkMessageStatusFilterComponent,
        BulkMessageTableComponent,
        BulkMessageWhatsappComponent,
        BulkMessageEmailComponent,
        BulkMessageSettingsComponent,
        BulkMessageRequestsComponent,
        BulkMessageRequestTreeComponent,
        EmailChipsFieldComponent
    ],
    exports: [
        BulkMessageFilterComponent,
        BulkMessageStatusFilterComponent,
        BulkMessageTableComponent,
        BulkMessageWhatsappComponent,
        BulkMessageEmailComponent,
        BulkMessageSettingsComponent,
        BulkMessageRequestsComponent,
        BulkMessageRequestTreeComponent,
        EmailChipsFieldComponent
    ]
})
export class BulkMessageModule { }
