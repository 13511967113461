<div class="row wrapper">
  <div class="col-4 title">
    Phone
  </div>
  <div class="col-8 work-field">
    @if (!isEdit) {
    <pd-add-value (addValue)="addValue()"></pd-add-value>
    }
    @if (isEdit) {
    <pd-two-rows-in-edit (cancel)="cancelEdit()" (save)="saveValue($event)" [phone]="phones">
    </pd-two-rows-in-edit>
    }
  </div>
</div>