import { Injectable } from '@angular/core';
import { HttpRestService } from './http-client/http-rest-client.service';
import { BaseService } from './base.service';
import { ContactClientRuleModel } from '../models/ContactClientRuleModel';

@Injectable()
export class ClientCounterService extends BaseService {
  constructor(private restService: HttpRestService) {
    super();
  }

  public async getClientCounterRules(userId: any): Promise<any> {
    const url = '/customSetting/clientCounterRules?' + 'userId=' + userId;
    return await this.handleResponse(this.restService.get({ url }));
  }

  public async addClientCounterRule(data): Promise<any> {
    const url = '/customSetting/clientCounterRules';
    return await this.handleResponse(this.restService.post({ url, data }));
  }

  public async removeClientCounterRule(clientCounterRuleId): Promise<ContactClientRuleModel> {
    const url = '/customSetting/clientCounterRules?clientCounterRuleId=' + clientCounterRuleId;
    return await this.handleResponse(this.restService.delete({ url }));
  }
}