import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AchievementCardStoreService } from "./achievement-card-store.service";
import { AchievementCardApiService } from "./achievement-card-api.service";
import { AchievementCardEffectsService } from "./achievement-card-effects.service";
import { AchievementCardModel } from "../../models/AchievementCardModel";
import { AchievementCardConfig } from "../../models/AchievementCardConfig";

@Injectable()
export class AchievementCardFacadeService {

    constructor(
        private achievementCardEffectsService: AchievementCardEffectsService,
        private achievementCardStoreService: AchievementCardStoreService,
        private achievementCardApiService: AchievementCardApiService,
    ) {
    }

    public loadAchievementCardsByDealId(ownerId: number): Promise<AchievementCardModel[]> {
        return this.achievementCardEffectsService.loadAchievementCardsByOwnerId(ownerId).toPromise();
    }

    public getAchievementCardConfig(): Promise<AchievementCardConfig> {
        return this.achievementCardApiService.getAchievementCardConfig().toPromise();
    }

    public getAchievementCards(): Observable<AchievementCardModel[]> {
        return this.achievementCardStoreService.getAchievementCards();
    }

    public updateAchievementCard(achievementCard: AchievementCardModel): Promise<AchievementCardModel> {
        return this.achievementCardEffectsService.updateAchievementCard(achievementCard).toPromise();
    }

    public updateAchievementCardGroup(achievementCard: AchievementCardModel): Promise<AchievementCardModel> {
        return this.achievementCardEffectsService.updateAchievementCardGroup(achievementCard).toPromise();
    }

    public deleteAchievementCard(achievementCardId: number): Promise<boolean> {
        return this.achievementCardEffectsService.deleteAchievementCard(achievementCardId).toPromise();
    }

    public deleteAchievementCardGroup(achievementCardId: number): Promise<boolean> {
        return this.achievementCardEffectsService.deleteAchievementCardGroup(achievementCardId).toPromise();
    }
}