import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ALL_PIPELINES, STAGES_FOR_ALL_PIPELINES } from 'src/assets/stages_new_prospespects';
import { Stage } from '../models/Stage';
import { BaseService } from './base.service';
import { HttpRestService } from './http-client/http-rest-client.service';

/**
 * Deal service
 * contains methods connected with deals
 * @export
 * @class StageService
 */
@Injectable()
export class StageService extends BaseService {

	constructor(private restService: HttpRestService) {
		super();
	}

	public stagesToPipelinesArray(stagesToTransform: Stage[], stageId: number = null) {
		if (!stagesToTransform || stagesToTransform.length < 1) {
			if (stageId == null)
				return []
			else {
				let currentStage = STAGES_FOR_ALL_PIPELINES.find(x => x.id === stageId)
				let currentPipeline = ALL_PIPELINES.find(s => s.id === currentStage.pipeline_id)
				if (currentStage && currentStage.id)
					return [{
						name: currentPipeline && currentPipeline.name || '',
						stages: [currentStage]
					}]
				return []
			}

		} else {
			let currentStage = stageId != null && STAGES_FOR_ALL_PIPELINES.find(x => x.id === stageId) as Stage || null
			if (currentStage && currentStage.id && !stagesToTransform.some(x => x.id === currentStage.id))
				stagesToTransform.push(currentStage)
			let pipelines = stagesToTransform.map(s => s.pipeline_id);
			let uniquePipelines = pipelines.filter(function (elem, index, self) {
				return index === self.indexOf(elem);
			});
			return uniquePipelines.reduce((result, element) => {
				let pipeline = ALL_PIPELINES.find(s => s.id === element);
				result.push({
					name: pipeline && pipeline.name || '',
					stages: stagesToTransform.filter(s => s.pipeline_id === element)
				});
				return result;
			}, []);
		}
	}

	public getNotBlockingStages(): Observable<any> {
		/*
		let url = '/stage/getNotBlocking/';
		return this.requestHelper.postRequestObservable(url, null);*/
		return null;
	}

	public getAllStages(): Observable<any> {
		/*	let url = '/stage/getAllStages/';
			return this.requestHelper.getRequestObservable(url, {});*/
		return null;
	}

	public async getStagesWithExistingDealByUserIdObservable(id: any): Promise<any> {
		return await this.handleResponse(this.restService.get({
			url: '/stage/getStagesWithDealsByUserId?userId=' + id
		}));
	}
}
