import { ObjectUtil } from "../utils/object.util";
import { NoteModel } from "./Note";
import { Participant } from "./Participant";
import { SameBuilding } from "./SameBuilding";

export class FilteredDealsModel {
    public data: Deal[] = [];
    public additional_data: any;
}

export class PipedriveResult {
    public success: boolean;
    public error: string;
    public error_info: string;
    public data: any;
    public additional_data: AdditionalData;
}

export class AdditionalData {
    public deals_count_by_stage: any[];
    public deals_lifetime_spend_by_stage: any[];
    public pagination: Pagination;
    public min_stage: Stage;
    public total_count: number;
}

export class Pagination {
    public start: number;
    public limit: number;
    public more_items_in_collection: boolean;
    public next_start: number;
}

export class Stage {
    public stage_id: number;
    public pipeline_id: number;
}

export class Deal {
    public id: number = 0;
    public is_rotting: boolean = false;
    public is_need_to_reload: boolean = false;
    public title: string = "";
    public company: string = "";
    public companyId: number = 0;
    public companyAddress: string = "";
    public homeAddress: string = "";
    public position: string = "";
    public positionId = 0;
    public phone: Phone[] = null;
    public email: Email[] = null;
    public linkedin_URL: string = ""; // ?? linkedinURL
    public stage_id: number = 0;
    public previous_labeled_stage_id?: number;
    public files_count: number = 0;
    public files_count_pdf: number = 0;
    public wardrobe_image_count: number = 0;
    public is_recently_requested_hotlist: boolean = false;
    public last_request_hotlist_date: string = "";
    public last_seasonal_check_email_sent_date: string = "";
    public participants_count: number = 0; // is it will be calculated??
    public same_building_count: number = 0;
    public personId: number = 0;
    public notes: string = ""; //Notes (Old)
    public personWhoReferred: string = "";
    public personWhoReferredId: number = 0;
    public stage_order_nr: number = 0;
    public called: boolean;
    public dateOfCall?: Date = null;
    public isOpenOrderNotified: boolean;
    public isDeliveryEmailSent: boolean;
    public birthday?: Date = null;
    public isIncreasedRecentlyContactedRotting?: boolean = false;
    public isForClothier?: boolean = false;
    public isOrderShipped?: boolean = false;
    public bookedById?: number = null;
    public trackingLink: string = "";
    public preferToContactByEmail: boolean;
    public clientRating: string = "";
    public latitude: string = null;
    public longitude: string = null;
    public client_lifetime_spend: number = 0;
    public isNotesExist: boolean = false;
    public last_export_deal_date?: Date = null;

    public viewOrder: number = 0;
    public rottenTime?: Date = null;
    public modified?: Date = null;
    
    // looks like these two properties are used in dto model before requestHotlist
    public meeting_date: string = "";
    public meeting_date_utc: Date;

    public user_id: number = 0;
    public pipeline_id: number = 0;
    public person_id: PersonShortInfo;

    // on searching deals
    public stage_name: string = "";
    public location: string = "";
    public locationId: number = 0;
    public user_name: string = "";
    public genderId: number = 0;
    public increasingRottenTimeCounter?: number;
    public maxIncreasingRottenTimeCounter?: number;
    public reselectRottenTimeCounter?: number;

    // from Notes table
    public noteList?: NoteModel[] = null;
    public participantList?: Participant[] = null;
    public sameBuildingList?: SameBuilding[] = null;

    constructor(data?: any) {
        ObjectUtil.extend(data, this);
    }
}

export class ContactInfo {
    public id: number = 0;
    public contactTypeId: number = 0;
    public label: string = null;
    public isPrimary: boolean = true;
    public value: string = "";
}

export class Phone extends ContactInfo {
    public normalizedValue: string = "";

    constructor(data?: any) {
        super();
        ObjectUtil.extend(data, this);
    }
}

export class Email extends ContactInfo {
    constructor(data?: any) {
        super();
        ObjectUtil.extend(data, this);
    }
}

export class PersonShortInfo {
    public value: number = 0;
    public name: string = "";
    public email: Email[] = null;
    public phone: Phone[] = null;

    constructor(data?: any) {
        ObjectUtil.extend(data, this);
    }
}

export class DealWithContact {
    public id: number = 0;
    public title: string = "";
    public value: string = "";
    public label: string = "";
}

export class FilteredByContactModel {
    public data: DealWithContact[] = [];
    public additional_data: any;
}
