import { GeolacationService } from './../../core/services/geolocation.service';
import { UserContextService } from 'src/app/core/services/user-context.service';
import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { NearbyCompaniesModel } from 'src/app/core/models/NearbyCompaniesModel';
import { User } from 'src/app/core/models/UserModel';
import { UtilsHelper } from 'src/app/core/utils/utils.helper';
import { RolesEnum } from 'src/app/core/enums/RolesEnum';
import { UserRequestService } from 'src/app/core/services/user-request.service';

@Component({
  selector: 'pd-nearby-companies',
  templateUrl: './nearby-companies.component.html',
  styleUrls: ['./nearby-companies.component.scss']
})
export class NearbyCompaniesComponent implements OnInit {
  currentUser: User;

  companies;
  nearbyCompanies = new Array<NearbyCompaniesModel>();
  nearbyCompaniesSelected = new Array<NearbyCompaniesModel>();

  nextPageToken = "";
  address = "";
  geocode = "";
  radius = 30;
  spinner = false;

  isAllowedRequest = true;
  isShowValidationMessages = false;
  public addressValidationTooltip = UtilsHelper.addressValidationMessage;
  radiusControl = new FormControl('', [Validators.required, Validators.min(1), Validators.max(50000)]);
  addressControl = new FormControl('', [Validators.required]);

  RolesEnum = RolesEnum;

  constructor(
    private userContextService: UserContextService,
    private userRequestService: UserRequestService,
    private geolacationService: GeolacationService) { }

  ngOnInit() {
    this.currentUser = this.userContextService.user.value;
    this.reset();
  }

  public validation() {
    if (this.addressControl.touched) {
      this.isShowValidationMessages = true;
    }

    return this.companyAddressValidation();
  }

  public async onLoadMore(): Promise<void> {
    await this.getData();
  }

  public async onSearchByAddress(): Promise<void> {
    if (!this.validation()) {
      return;
    }
    this.reset();
    this.spinner = true;
    const result = await this.geolacationService.getGeocodeByAddress(this.address);
    if (!result || !result.lat || !result.lng) {
      alert("INVALID_REQUEST");
      return;
    }
    this.geocode = result.lat + ", " + result.lng;
    await this.getData();
  }

  reset() {
    this.isShowValidationMessages = false;
    this.companies = null;
    this.nextPageToken = null;
    this.nearbyCompaniesSelected = null;
  }

  onSendRequest() {
    let data = {
      MadeRequestUser: this.currentUser.id,
      RequestedRadius: this.radius,
      RequestedAddress: this.address,
      ImportRequestList: this.nearbyCompaniesSelected,
    };

    this.userRequestService.sendNearbyImportRequest(data).then(() => {
      alert("Request has been sent.");
      this.nearbyCompaniesSelected = null;
    });
  }

  checkItem(item: NearbyCompaniesModel) {
    return this.nearbyCompaniesSelected && this.nearbyCompaniesSelected.findIndex(arrItem => arrItem.number == item.number) !== -1
  }

  selectItem(item: NearbyCompaniesModel, event: any) {
    if (!this.nearbyCompaniesSelected) {
      this.nearbyCompaniesSelected = new Array<NearbyCompaniesModel>();
    }

    if (event.checked) {
      this.nearbyCompaniesSelected.push(item);
    }
    else {
      let idx = this.nearbyCompaniesSelected.findIndex(arrItem => arrItem.number == item.number)
      if (idx != -1) {
        this.nearbyCompaniesSelected.splice(idx, 1);
      }
    }
  }

  public companyAddressValidation() {
    if (this.checkAddressIsValid(this.address)) {
      return true;
    }

    return false;
  }

  private checkAddressIsValid(address: string) {
    return UtilsHelper.checkAddressIsValid(address);
  }

  async getData(): Promise<void> {
    this.spinner = true;

    this.isAllowedRequest = false;
    const result = await this.geolacationService.getGoogleNearbySearch(this.geocode, this.radius, this.nextPageToken);
    this.spinner = false;

    setTimeout(() => this.isAllowedRequest = true, 1500); //spam protection

    if (!result || !result.results || result.status == "INVALID_REQUEST") {
      alert("INVALID_REQUEST");
      return;
    }

    this.nextPageToken = result.next_page_token;

    if (this.companies) {
      this.companies = this.companies.concat(result.results);
    }
    else {
      this.companies = result.results;
    }

    this.nearbyCompanies = new Array<NearbyCompaniesModel>();
    this.companies.forEach((elem, index: number) => {
      let nearbyCompany = new NearbyCompaniesModel();
      nearbyCompany.number = (index + 1),
        nearbyCompany.icon = elem.icon,
        nearbyCompany.companyName = elem.name
      nearbyCompany.address = elem.vicinity
      nearbyCompany.geocode = elem.geometry.location.lat + "," + elem.geometry.location.lng
      nearbyCompany.types = elem.types.join(", ")

      this.nearbyCompanies.push(nearbyCompany);
    });
  }
}