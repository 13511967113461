import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from 'src/app/core/models/UserModel';
import { RequestStatus } from 'src/app/core/enums/RequestStatus';
import { MessageTemplateViewModel } from 'src/app/core/models/message-template/MessageTemplateViewModel';
import { BulkMessageRequestMessageTemplateVariableViewModel } from 'src/app/core/models/bulk-message/BulkMessageRequestMessageTemplateVariableViewModel';
import { MessageTemplateType } from 'src/app/core/enums/MessageTemplateType';
import { EnumUtil } from 'src/app/core/utils/enum.util';

interface RequestNode {
  id: number;
  parentRequestId: number | null;
  statusId: RequestStatus;
  messageTemplate: MessageTemplateViewModel;
  variables: BulkMessageRequestMessageTemplateVariableViewModel[];
  replyRecipients?: User[];
  expirationDate?: Date;
  scheduledSendDate?: Date;
  created: Date;
  isSelected: boolean;
  children?: RequestNode[];
}

@Component({
  selector: 'pd-bulk-message-request-tree',
  templateUrl: './bulk-message-request-tree.component.html',
  styleUrls: ['./bulk-message-request-tree.component.scss']
})
export class BulkMessageRequestTreeComponent implements OnInit {
  @Input() requests: RequestNode[];
  @Output() public onRequestSelectChange = new EventEmitter<any>();

  MessageTemplateType = MessageTemplateType;
  EnumUtil = EnumUtil;
  RequestStatus = RequestStatus;
  treeData: RequestNode[] = [];

  ngOnInit(): void {
    this.treeData = this.buildTree(this.requests, null);
  }

  getStatusColor(request: RequestNode): any {
    let styles = {};

    if (request && request.statusId) {
      switch (request.statusId) {
        case RequestStatus.Failed: styles['background-color'] = "red"; break;
        case RequestStatus.Queued: styles['background-color'] = "yellow"; break;
        case RequestStatus.Sheduled: styles['background-color'] = "aqua"; break;
        case RequestStatus.InProgress: styles['background-color'] = "orange"; break;
        case RequestStatus.Completed: styles['background-color'] = "lawngreen"; break;
      }
    }
    return styles;
  }

  requestSelectChange(node): void {
    this.onRequestSelectChange.emit({ requestId: node.id });
  }

  private buildTree(arr: RequestNode[], parentId: number | null): RequestNode[] {
    const result: RequestNode[] = [];
    let children: RequestNode[];

    arr.forEach(node => {
      if (node.parentRequestId === parentId) {
        children = this.buildTree(arr, node.id);

        if (children.length) {
          node.children = children;
        }

        result.push(node);
      }
    });

    return result;
  }
}