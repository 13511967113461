import { environment } from "src/environments/environment";
import * as signalR from "@microsoft/signalr";

export class SignalrService {
    public hubConnection: signalR.HubConnection
    public startConnection = () => {
        console.log('StartConnection');

        this.hubConnection = new signalR.HubConnectionBuilder()
            .withUrl(environment.apiUrl + "/chat")
            .build();

        console.log('hubConnection', this.hubConnection);

        this.hubConnection
            .start()
            .then(() => console.log('Connection started'))
            .catch(err => console.log('Error while starting connection: ' + err))
    }
    
    public stopConnection = () => {
        if (this.hubConnection) {
            this.hubConnection
                .stop()
                .then(() => console.log('Connection stopped'))
                .catch(err => console.log('Error while stopping connection: ' + err));
        }
    };
}