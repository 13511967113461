import { UserContextService } from './../core/services/user-context.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { DateHelper } from "../core/utils/date.helper";
import { User } from "../core/models/UserModel";
import { SignalrService } from '../core/services/signalr.service';
import { AlertTypes } from '../core/enums/AlertTypes';
import { RolesEnum } from '../core/enums/RolesEnum';
import { FileUtil } from '../core/utils/file.util';
import { DealUtil } from '../core/utils/deal.util';
import { AlertService } from '../core/services/alert.service';
import { Chat } from '../core/models/chat-models/Chat';
import { Message } from '../core/models/chat-models/Message';
import { TwilioHelper } from '../core/utils/twilio.helper';
import { ChatSelectorComponent } from './chat-selector/chat-selector.component';
import { TwilioFacadeService } from '../core/services/twilio/twilio-facade.service';
import { BaseComponent } from '../core/base.component';
import { takeUntil } from 'rxjs';
import { ChatConversationTypes } from '../core/enums/ChatConversationTypes';

@Component({
  selector: 'chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class WhatsappChatComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild("targetHeader") targetHeader: ElementRef;
  @ViewChild("targetMessagesList") targetMessagesList: ElementRef;
  @ViewChild("targetMessagesInput") targetMessagesInput: ElementRef;
  @ViewChild('chatSelector') chatSelector: ChatSelectorComponent;

  @Input() public dealId: number;
  @Input() public dealTitle: string;
  @Input() public isDontWhatsappMessage: boolean = false;
  @Input() public isDontSmsMessage: boolean = false;
  @Input() public isCandidateConversation: boolean = false;

  public chatList: Chat[] = [];
  public activeChat?: Chat;

  public isSpinner: boolean = false;
  public isGlobalChat: boolean = false;

  public FileUtil = FileUtil;
  public DealUtil = DealUtil;
  public TwilioHelper = TwilioHelper;
  public DateHelper = DateHelper;

  public isShowTextIfNoConversations: boolean = false;
  public textIfNoConversations = `The client doesn't have any valid phone numbers. Please change the phone numbers in "Deal" tab and get back to this.`;

  private currentUser: User;
  private start: number = 0;
  private limit: number = 20;

  constructor(
    private readonly ref: ChangeDetectorRef,
    private readonly signalRService: SignalrService,
    private readonly twilioFacadeService: TwilioFacadeService,
    private userContextService: UserContextService,
    private readonly alertService: AlertService,
  ) {
    super();

    this.currentUser = this.userContextService.user.value;

    this.twilioFacadeService.getLoaderObservable().pipe(takeUntil(this.destroy$)).subscribe(loader => {
      this.isSpinner = loader;
      this.ref.markForCheck();
    });

    this.twilioFacadeService.getActiveChatObservable().pipe(takeUntil(this.destroy$)).subscribe(chat => {
      this.activeChat = chat;
      this.checkClientConversationWarning();
      this.ref.markForCheck();
    });

    this.twilioFacadeService.getChatListObservable().pipe(takeUntil(this.destroy$)).subscribe(chatList => {
      this.chatList = chatList;
      this.ref.markForCheck();

      if (!this.checkAllowedConversations(this.chatList)) { //TODO: check if this is needed
        return;
      }

      this.checkClientConversationWarning();
    });
  }

  private getfilter() {
    let conversationType = this.chatSelector?.selectedConversationType;
    if (this.isGlobalChat && this.currentUser.role_id === RolesEnum.Recruiter) {
      conversationType = ChatConversationTypes.Candidates;
    }

    const filter = {
      start: this.start,
      limit: this.limit,
      dealId: this.dealId,
      conversationType: conversationType,
      searchTerm: this.chatSelector?.searchText,
    }

    return filter;
  }

  private async setChats(): Promise<void> {
    this.twilioFacadeService.setLoader(true);
    const filter = this.getfilter();
    await this.twilioFacadeService.loadChatList(filter);

    if (!this.activeChat && this.chatList && this.chatList.length > 0) {
      await this.twilioFacadeService.setActiveChat(this.chatList[0]);
    }

    this.twilioFacadeService.setLoader(false);
  }

  onLazyLoading() {
    if (this.isSpinner) {
      return;
    }

    if (this.chatList.length < this.limit) {
      return;
    }
    this.limit += 20;
    this.setChats()
  }

  ngOnInit() {
    this.signalRService.startConnection();
    this.addFromLifetimeListener();
    this.addToCallbackListener();
  }

  ngOnDestroy() {
    this.signalRService.stopConnection();
    this.ref.detach();
    super.ngOnDestroy();
  }

  async ngAfterViewInit(): Promise<void> {
    this.isGlobalChat = !this.dealId;
    this.calcMessagesList();
    await this.setChats();
  }

  public async onChangeSearch(): Promise<void> {
    this.start = 0;
    this.limit = 20;
    await this.setChats();
  }

  public autoGrow() {
    this.calcMessagesList();
    this.ref.detectChanges();
  }

  private addFromLifetimeListener() {
    this.signalRService.hubConnection.on('Receive', async (newMessage: Message) => {
      console.log('Receive', newMessage);
      const chat = this.chatList.find(chat => chat.id === newMessage.chatId);
      this.twilioFacadeService.setNewMessage(newMessage);
      if (this.isGlobalChat || chat) {
        const filter = this.getfilter();
        await this.twilioFacadeService.loadChatList(filter);
      }
    });
  }

  private addToCallbackListener() {
    this.signalRService.hubConnection.on('Callback', async (message: Message) => {
      console.log('Callback', message);
      this.twilioFacadeService.updateMessageStatus(message);
      this.ref.detectChanges();
    });
  }

  private calcMessagesList() {
    if (!this.targetMessagesInput || !this.targetHeader) {
      return;
    }

    const offset = (this.isGlobalChat) ? -2 : 0;
    const height = (this.targetMessagesInput.nativeElement.clientHeight + this.targetHeader.nativeElement.clientHeight) + offset;
    this.targetMessagesList.nativeElement.setAttribute('style', `height: calc(100% - ${height}px)`);
  }

  private checkClientConversationWarning() {
    if (this.activeChat && this.activeChat.isWhatsApp && !this.isGlobalChat && this.isDontWhatsappMessage) {
      this.alertService.showAlert(AlertTypes.Warning, 'Important! The client asked not to send WhatsApp messages', 'Noted, I want to proceed');
    }
    if (this.activeChat && !this.activeChat.isWhatsApp && !this.isGlobalChat && this.isDontSmsMessage) {
      this.alertService.showAlert(AlertTypes.Warning, 'Important! The client asked not to send SMS messages', 'Noted, I want to proceed');
    }
  }

  private checkAllowedConversations(chatList: Chat[]): boolean {
    if (chatList && chatList.length == 0 && !this.isGlobalChat) {
      this.isShowTextIfNoConversations = true;
      return false;
    }
    this.isShowTextIfNoConversations = false;
    return true;
  }
}