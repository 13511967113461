import { ObjectUtil } from "../../utils/object.util";
import { PersonContactInfo } from "./PersonContactInfo";

export class PersonShortInfoDto {
    public personId: number;
    public name: string;
    public emails: PersonContactInfo[];
    public phones: PersonContactInfo[];

    constructor(data?: any) {
        ObjectUtil.extend(data, this);
    }
}