<div class="wrapper">
  <div class="header">
    <h2>Upload File</h2>
  </div>
  <div class="content-wrapper">
    @if (isAdmin && users.length) {
    <div class="users">
      <mat-form-field>
        <mat-select [value]="users[0].id" bootstrapTooltip="Users" placement="bottom">
          @for (item of users; track item) {
          <mat-option [value]="item.id" (click)="changeFilter(item)">
            {{ item.name }}
          </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
    }
    <div class="update-info">
      {{uploadFileInfo}}
    </div>
    @if (deals && deals.length) {
    <div class="table">
      <div class="table-wrapper">
        <div class="table-header">
          @for (col of tableTitle; track col) {
          <div class="header-collumn">
            {{col}}
          </div>
          }
        </div>
        <div class="table-content" (scroll)="doSomething($event)" #container>
          <div class="position-relative">
            @for (deal of deals; track deal) {
            <div class="content-row">
              <div class="name coll">
                {{deal.title}}
              </div>
              <div class="company coll">
                {{deal.company}}
              </div>
              <div class="position coll">
                {{deal.position}}
              </div>
              <div class="city coll">
                {{deal.location}}
              </div>

              <div class="coll content-centered">
                @if (deal.filesCount > 0) {
                <div>
                  <i class="material-icons" style="color: lawngreen;padding-right: 5px;">check_circle</i>
                </div>
                }
                <div class="upload" (click)="selectDeal(deal);openInput()">
                  <i class="material-icons">present_to_all</i>
                </div>
              </div>

              <div class="coll content-centered">
                @if (deal.filesCountPdf > 0) {
                <div>
                  <i class="material-icons" style="color: lawngreen;padding-right: 5px;">check_circle</i>
                </div>
                }
                <div class="upload" (click)="selectDeal(deal);openInputPdf()">
                  <i class="material-icons">present_to_all</i>
                </div>
              </div>

            </div>
            }
            @if (lazyLoading) {
            <div class="lazyLoading">
              <div class="text-lazy">Loading more deals...</div>
            </div>
            }
          </div>
        </div>
        <input #fileInput type="file" class="upload" accept=".csv" (change)="changeListener($event.target.files)"
          style="display: none">

        <input #fileInputPdf type="file" class="upload" accept=".pdf" (change)="changeListenerPdf($event.target.files)"
          style="display: none">
      </div>
    </div>
    }
  </div>
</div>

@if (spinner) {
<div class="spinner">
  <mat-spinner></mat-spinner>
</div>
}