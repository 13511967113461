import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

@Injectable()
export class BulkMessageEffectsService {

    constructor(
    ) {
    }
}
