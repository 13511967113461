import { CustomRottenTimeModalOptions } from "../custom-rotten-time-modal/custom-rotten-time-modal.options";

export const TryNextSeasonOptions = new CustomRottenTimeModalOptions(
    "Choose the date when you want to contact the client",
    new Date(new Date().setMonth((new Date()).getMonth() + 1)),
    new Date(new Date().setMonth((new Date()).getMonth() + 9)),
    true
)

export const RescheduleOptions = new CustomRottenTimeModalOptions(
    "Please choose the date",
    new Date(new Date().setMonth((new Date()).getMonth() + 0)),
    new Date(new Date().setMonth((new Date()).getMonth() + 1)),
    false
)

export const InAlterationsOptions = new CustomRottenTimeModalOptions(
    "When the order will be delivered?",
    new Date(new Date().setMonth((new Date()).getMonth() + 0)),
    new Date(new Date().setMonth((new Date()).getMonth() + 2)),
    false
)