import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { HotlistService } from '../core/services/hotlist.service';
import { UtilsHelper } from '../core/utils/utils.helper';

@Component({
  selector: 'pd-hotlist-download',
  templateUrl: './hotlist-download.component.html',
  styleUrls: ['./hotlist-download.component.scss']
})
export class HotlistDownloadComponent implements OnInit {
  public downloadUrl = ''
  public dealId = ''

  constructor(
    private hotlistService: HotlistService, 
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.paramMap.pipe(take(1)).subscribe(params => {
      this.dealId = params.get("id")
      if(this.dealId && !isNaN(Number(this.dealId))) {
        this.hotlistService.getHotlistPdfObservable(+this.dealId).pipe(take(1)).subscribe((result : any) => {
          let byteArray = UtilsHelper.base64ToArrayBuffer(result)
          let blob: any = new Blob([byteArray], { type: 'application/pdf' })
          saveAs(blob, this.dealId + '.pdf',true)
        })
      }
    })
  }
}
