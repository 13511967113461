import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { DateHelper } from "src/app/core/utils/date.helper";
import { FileUtil } from "src/app/core/utils/file.util";
import { Message } from "src/app/core/models/chat-models/Message";
import { TwilioHelper } from "src/app/core/utils/twilio.helper";
import { TwilioFacadeService } from "src/app/core/services/twilio/twilio-facade.service";
import { takeUntil, zip } from "rxjs";
import { BaseComponent } from "src/app/core/base.component";

@Component({
  selector: 'pd-chat-view',
  templateUrl: './chat-view.component.html',
  styleUrls: ['./chat-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ChatViewComponent extends BaseComponent {
  @ViewChild("targetScroll") targetScroll: ElementRef;
  @ViewChild('fullscreenView') fullscreenView: ElementRef;
  @ViewChild('fullscreenImg') fullscreenImg: ElementRef;

  public TwilioHelper = TwilioHelper;
  public DateHelper = DateHelper;
  public FileUtil = FileUtil;

  public messages: Message[] = [];
  public spinner = true;

  constructor(
    private readonly ref: ChangeDetectorRef,
    private readonly twilioFacadeService: TwilioFacadeService,
  ) {
    super();

    zip(
      twilioFacadeService.getActiveChatObservable(),
      twilioFacadeService.getMessagesObservable()
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe(([chat, messages]) => {
        this.ref.markForCheck();
        this.scrollToEnd();
      });

    twilioFacadeService.getMessagesObservable().pipe(takeUntil(this.destroy$)).subscribe(messages => {
      this.messages = messages;
      this.ref.markForCheck();
    });

    twilioFacadeService.getNewMessageObservable().pipe(takeUntil(this.destroy$)).subscribe(message => {
      this.ref.markForCheck();
      this.scrollToEndSmoothly();
    });
  }

  public getMessageClasses(message: Message) {
    if (message.isInboundMessage) {
      return 'message sender bubble-arrow-sender';
    }
    return 'message receiver bubble-arrow-receiver';
  }

  public getTimeClasses(message: Message) {
    if (message.isInboundMessage) {
      return 'message-time-sender';
    }
    return 'message-time-receiver';
  }

  public getErrorMessageTooltip(message) {
    return message.errorMessage;
  }

  public identify(index, message: Message) {
    return message.sid;
  }

  public setNewMessage(newMessage: Message) {
    this.messages.push(newMessage);
    this.ref.detectChanges();
    this.scrollToEndSmoothly();
  }

  private scrollToEndSmoothly() {
    setTimeout(() => {
      this.targetScroll.nativeElement.scrollIntoView({ block: "start", behavior: "smooth" });
    }, 100);
  }

  private scrollToEnd() {
    setTimeout(() => {
      this.targetScroll.nativeElement.scrollIntoView({ block: "start" });
    }, 100);
  }

  public openFullscreen(event) {
    const fullscreen = this.fullscreenView.nativeElement;
    const fullscreenImg = this.fullscreenImg.nativeElement;
    fullscreenImg.src = event.target.src;
    fullscreen.setAttribute('style', `display: block`);
  }

  public closeFullscreen() {
    const fullscreen = this.fullscreenView.nativeElement;
    fullscreen.setAttribute('style', `display: none`);
  }
}