<div class="pd-confirm-container">
  <div class="confirm">
    <div class="confirm-icon-wrapper confirm-info">
      <button type="button" (click)="close()" class="close-modal-btn btn-danger btn" aria-label="Close">
        <i class="material-icons">close</i>
      </button>
      <div class="icon">
        <i class="fa fa-info-circle fa-3"></i>
      </div>
    </div>
    <div class="confirm-message">{{message}}</div>

    <button class="btn btn-success" type="button" (click)="confirm()">{{buttonConfirmText}}</button>
    <button class="btn btn-danger" type="button" (click)="reject()">{{buttonRejectText}}</button>
  </div>
</div>