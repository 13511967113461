import { GlobalConstants } from './../../core/global-constants';
import { PipelineDealsStoreService } from './../../core/services/stores/pipeline-deal-store.service';
import { UserContextService } from 'src/app/core/services/user-context.service';
import { StageFeatureStoreService } from 'src/app/core/services/stores/stage-feature-store.service';
import { UserStoreService } from 'src/app/core/services/stores/user-store.service';
import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';
import { Router } from '@angular/router';
import { RolesEnum } from 'src/app/core/enums/RolesEnum';
import { Deal } from 'src/app/core/models/Deal';
import { Stage } from 'src/app/core/models/Stage';
import { User } from 'src/app/core/models/UserModel';
import { FilterDealModel } from 'src/app/core/models/filter-models/FilterDealModel';
import { ExportDealsComponent } from 'src/app/shared/custom/export-deals/export-deals.component';
import { DealService } from 'src/app/core/services/deals.service';
import { TransferPopUpComponent } from 'src/app/shared/custom/transfer-pop-up/transfer-pop-up.component';
import { CustomExportModalComponent } from 'src/app/shared/custom/custom-export-modal/custom-export-modal.component';
import * as stages from '../../../assets/stages_new_prospespects';
import { Multitransfer } from 'src/app/core/models/Multitransfer';
import { ExportDealsType } from 'src/app/core/enums/ExportDealsType';
import { Subscription, firstValueFrom, from, takeUntil } from 'rxjs';
import { BaseComponent } from 'src/app/core/base.component';

@Component({
  selector: 'pd-list-deal',
  templateUrl: './list-deal.component.html',
  styleUrls: ['./list-deal.component.scss'],
  animations: [
    trigger('modalState', [
      state('in', style({
        transform: 'translate(0,0)'
      })),
      state('out', style({
        transform: 'translate(100%, 0)'
      })),
      transition('out => in', animate('400ms ease-in-out')),
      transition('in => out', animate('400ms ease-in-out'))
    ])
  ]

})
export class ListDealComponent extends BaseComponent implements OnInit {
  public rolesEnum = RolesEnum;
  public isFilterPerformed = false;
  public currentUser: User;
  public users: User[];
  public usersWithoutStageChangeOption: User[];
  public filterStage: Stage;
  public listDeals: Deal[] = [];
  public start: any;
  @Output() more_items_in_collection = true;
  public spinner: boolean = true;
  public lazyLoading = false;
  public menuState: string = 'out';
  public userList = [];
  public filteredUsers = [];
  @Output() currentOwner: any;
  public isSelectedAll: boolean = false;
  public deals: Deal[] = [];
  public dealsAvailableForTransferCheck: Deal[] = [];
  public dealReqest: Subscription;

  public filterModel: FilterDealModel = new FilterDealModel();

  public stage: number = null;
  public totalCount: number | null;
  @ViewChild('container') container: any;
  @Output() loadingListDeals: EventEmitter<any> = new EventEmitter<any>();

  public isShowError = false;
  public updateOwnerInfo: string;
  public pipelines = [];

  public isDisabledTransfer = true;
  @ViewChild('transferPopUp') transferPopUp: TransferPopUpComponent;
  @ViewChild('exportModal') exportModal: CustomExportModalComponent;
  @ViewChild('exportDeals') exportDeals: ExportDealsComponent;

  public transferForm = {};

  constructor(
    private dealService: DealService,
    private userContextService: UserContextService,
    private router: Router,
    private userStoreService: UserStoreService,
    private stageFeatureStoreService: StageFeatureStoreService,
    private pipelineDealsStoreService: PipelineDealsStoreService,) {
    super();

    this.start = 0;
  }

  async ngOnInit() {
    await this.initialiseStore();
    if (this.currentUser.role_id === RolesEnum.EaCaller) {
      this.router.navigate(['/pipeline/', '4']);
    }
    if (this.currentUser.role_id === RolesEnum.EA) {
      this.router.navigate(['/pipeline/', '7']);
    }
    this.pipelineDealsStoreService.deals.pipe(takeUntil(this.destroy$)).subscribe(deals => {
      if (!deals) {
        return;
      }
      this.deals = deals;
      const selectedStages = this.stageFeatureStoreService.getCurrentStages.value;
      if (!selectedStages || selectedStages.length === 0) {
        this.isFilterPerformed = false;
      }
      this.dealsAvailableForTransferCheck = this.getAvailableForTransferCheck(this.deals, selectedStages);
    });
    this.getListDeals();

    this.stageFeatureStoreService.getCurrentStages.pipe(takeUntil(this.destroy$)).subscribe(selectedStages => {
      if (!selectedStages || selectedStages.length === 0) {
        this.isFilterPerformed = false;
        return;
      }
      const allUsers = this.userStoreService.allUsers.value
      this.isFilterPerformed = false;
      const users = this.userStoreService.getUsersForTransferDropDown(this.currentUser,
        this.getUserToStageAllowedRolesForTransferDropDown(selectedStages[0], allUsers, true));
      this.currentOwner = users && users.length > 0 && users[0].id || null;
      this.usersWithoutStageChangeOption = this.userStoreService.getUsersForTransferDropDown(this.currentUser,
        this.getUserToStageAllowedRolesForTransferDropDown(selectedStages[0], allUsers, true));
    });
  }

  public isChecked(id: number): boolean {
    return this.pipelineDealsStoreService.listDealsChecked.value.some(x => x.id == id);
  }

  public isAvelableForTransfer(id: number): boolean {
    return !this.isFilterPerformed || !this.dealsAvailableForTransferCheck.some(x => x.id == id);
  }

  private getAvailableForTransferCheck = (allItems: Deal[], filteredStages: Stage[]): Deal[] => {
    return allItems && allItems.filter(item => item.stage_id !== GlobalConstants.ALL_STAGES.Kill && item.stage_id !== GlobalConstants.ALL_STAGES.Meeting
      && item.stage_id !== GlobalConstants.ALL_STAGES.ClientSaved && item.stage_id !== GlobalConstants.ALL_STAGES.SM1 && item.pipeline_id !== stages.ClientsPipeline.id && item.pipeline_id !== stages.OpenOrders.id
      && (filteredStages && filteredStages.length > 0)) || []
  };

  private getUserToStageAllowedRolesForTransferDropDown(selectedStage: Stage, selectedUsersFilter: User[], isIgnoreStageAvaliability = false): RolesEnum[] {
    if (selectedStage && selectedStage.id) {
      if (this.currentUser && this.currentUser.is_admin === true && isIgnoreStageAvaliability) {
        return Object.keys(RolesEnum).map(key => RolesEnum[key]);
      } else {
        let allowedRoles: RolesEnum[] = [];
        allowedRoles = Object.entries(stages.STAGES_BY_ROLES_VISIBILITY).reduce((result, current) => {
          if (current[1].some(stage => stage === selectedStage.name)
            || ((current[0] === RolesEnum[RolesEnum.Admin] || current[0] === RolesEnum[RolesEnum.SystemAccount])
              && selectedUsersFilter && selectedUsersFilter[0] && selectedUsersFilter[0].role_id === RolesEnum.MasterLead)) {
            result.push(RolesEnum[current[0]]);
          }
          return result;
        }, []);
        return allowedRoles;
      }
    }

    return [];
  };

  private async initialiseStore() {
    this.userStoreService.selectedUsersFilter.pipe(takeUntil(this.destroy$)).subscribe(userList => {
      console.log('ListDealComponent userStoreService.selectedUsersFilter', userList);
      if (userList) {
        this.userList = userList;
        this.filteredUsers = this.userList.filter(user => user.role_id !== RolesEnum.EA);
      }
    })

    this.pipelineDealsStoreService.listDealsChecked.pipe(takeUntil(this.destroy$)).subscribe(listDealsChecked => {
      this.menuState = listDealsChecked && listDealsChecked.length > 0 ? "in" : "out";
    });

    this.pipelineDealsStoreService.additionalData.pipe(takeUntil(this.destroy$)).subscribe(additionalData => {
      if (additionalData) {
        this.totalCount = additionalData.total_count;
      }
    });

    this.currentUser = this.userContextService.user.value;
  }

  /**
   * Get list of deals
   * from API
   * @memberof ListDealComponent
   */

  public getListDeals() {
    let filteredStages = this.stageFeatureStoreService.getCurrentStages.value;
    const users = this.userStoreService.selectedUsersFilter.value;
    if (!filteredStages) {
      filteredStages = [];
    }
    if (this.dealReqest) {
      this.dealReqest.unsubscribe();
    }

    this.dealReqest = from(this.dealService.getListDeal(this.start, 30, null, users.map(u => u.id), this.filterModel.filteredLocations, this.filterModel.selectedGenderId, filteredStages.map(st => st.id), this.filterModel.filteredCompanies, this.filterModel.filteredPositions
      , this.filterModel.isSameBuildingFilter
      , this.filterModel.isWithPhoneNumberOnlyFilter
    )).subscribe(result => {
      this.listDeals = result.data;
      this.pipelineDealsStoreService.setAdditionalData(result.additional_data);
      this.pipelineDealsStoreService.setDeals(result.data);
      this.start = result.additional_data.pagination.next_start;
      this.more_items_in_collection = result.additional_data.pagination.more_items_in_collection;
      this.isShowError = false;
      this.spinner = false;
      this.loadingListDeals.emit(result.additional_data);
    });
  }

  /**
   * Select all
   * deals
   * @memberof ListDealComponent
   */


  public selectOption(event, item) {
    if (event.checked) {
      this.pipelineDealsStoreService.addCheckedDeal(item);
      this.isSelectedAll = this.listDeals.length == this.pipelineDealsStoreService.listDealsChecked.value.length;
    }
    else {
      this.isSelectedAll = false;
      this.pipelineDealsStoreService.removeCheckedDeal(item);
    }
  }

  public selectAll(event) {
    if (!this.isFilterPerformed) {
      return;
    }

    if (event.checked) {
      this.isSelectedAll = true;
      this.pipelineDealsStoreService.selectAllCheckedDeals();
    } else {
      this.isSelectedAll = false;
      this.pipelineDealsStoreService.removeAllCheckedDeals();
    }
  }

  /**
   * Hide modal
   * of transfer owner
   * @memberof ListDealComponent
   */

  public hideModal() {
    this.selectAll(false);
    this.menuState = 'out';
  }

  /**
   * Show modal
   * of move leads
   * @memberof ListDealComponent
   */

  public moveDeals() {
    this.transferPopUp.openModal(() => this.updateOwner())
  }

  /**
   * Update list
   * of deals
   * @memberof ListDealComponent
   */

  public updateList() {
    this.spinner = true;
    this.dealService.updateOwnerDeals(this.pipelineDealsStoreService.listDealsChecked.value.map(x => x.id), this.currentOwner).then(result => {
      this.resetUpdateOwner();
    },
      () => {
        this.resetUpdateOwner();
      }
    );
  }

  private resetUpdateOwner() {
    this.selectAll(false);
    this.menuState = 'out';
    setTimeout(() => {
      this.selectAll(false);
    }, 1);
    this.start = 0;
    this.listDeals = [];
    this.getListDeals();
  }

  /**
   * Change filter
   * Update list of deals
   * @param {any} item selected user
   * @memberof ListDealComponent
   */

  public onChange() {
    this.listDeals = [];
    this.spinner = true;
    this.start = 0;
    this.menuState = 'out';
    this.getListDeals();
  }

  /**
   * Filter list
   * of deals by selected filters
   * @memberof ListDealComponent
   */

  public filterList() {
    const users = this.userStoreService.selectedUsersFilter.value;
    if (!users || users.length === 0) {
      this.userStoreService.setSelectedUsersFilter([this.currentUser]);
    }
    this.start = 0;
    this.listDeals = [];
    this.spinner = true;
    this.isFilterPerformed = true;
    this.getListDeals();
  }

  /**
   * Update owner filter deal
   * from API
   * @memberof ListDealComponent
   */

  public updateOwnerFilterDeal() {
    this.sendTransferRequestForProcessing(
      this.userStoreService.selectedUsersFilter.value,
      this.stageFeatureStoreService.getCurrentStages.value,
      this.transferPopUp.multitransfer)
  }

  private sendTransferRequestForProcessing(users: User[], filteredStages: Stage[], multitransfers: Multitransfer[]) {
    if (users && users.length > 0) {
      let transferFilterModel = {
        filterUserIds: users.map(u => u.id),
        locations: this.filterModel.filteredLocations,
        companies: this.filterModel.filteredCompanies,
        positions: this.filterModel.filteredPositions,
        genderId: this.filterModel.selectedGenderId,
        stageIds: filteredStages.map(st => st.id),
        owners: multitransfers,
        isSameBuildingFilter: this.filterModel.isSameBuildingFilter,
        isWithPhoneNumberOnlyFilter: this.filterModel.isWithPhoneNumberOnlyFilter
      };
      this.dealService.updateOwnerFilteredDeal(transferFilterModel).then(() => {
        this.getListDeals();
      });
    }
  }

  /**
   * Update owner
   * in list of deals
   * @memberof ListDealComponent
   */

  public updateOwner() {
    this.spinner = true;
    this.updateOwnerFilterDeal();
    this.updateOwnerInfo = 'Transfer Task Started';
    setTimeout(() => {
      this.updateOwnerInfo = null;
    }, 1500);
  }

  /**
   * Loading more deals
   * in list of deals
   * @memberof ListDealComponent
   */

  public loadingMoreDeals() {
    const users = this.userStoreService.selectedUsersFilter.value;
    let filteredStages = this.stageFeatureStoreService.getCurrentStages.value;
    if (!filteredStages) {
      filteredStages = [];
    }
    if (this.more_items_in_collection === true) {
      this.lazyLoading = true;
      this.dealService.getListDeal(this.start, 20, '', users && users.length > 0 ? users.map(u => u.id) : [], this.filterModel.filteredLocations, this.filterModel.selectedGenderId, filteredStages.map(st => st.id), this.filterModel.filteredCompanies, this.filterModel.filteredPositions
        , this.filterModel.isSameBuildingFilter
        , this.filterModel.isWithPhoneNumberOnlyFilter
      ).then(result => {
        if (result.data && result.data.length > 0)
          setTimeout(() => {
            if (result.data) {
              result.data.forEach(element => {
                this.listDeals.push(element);
              });
            }
            this.pipelineDealsStoreService.setDeals(this.listDeals);
            this.more_items_in_collection = result.additional_data.pagination.more_items_in_collection;
            this.start = result.additional_data.pagination.next_start;
            this.loadingListDeals.emit(result.additional_data);
            this.lazyLoading = false;
          });
      },
        () => { }
      );
    } else {
      this.lazyLoading = false;
    }
  }

  /**
   * Deep clone
   * coppy phone or email array
   * @param {Object[]} array selected array
   * @memberof ListDealComponent
   */

  public deepClone(array: Object[]) {
    const newArray: any = [];
    array.forEach((item) => {
      newArray.push(Object.assign({}, item));
    });
    return newArray;
  }

  public routToDeals(deal: Deal) {
    window.open(window.location.origin + "/deals/" + deal.id, '_blank');
    //this.router.navigate(['/deals', deal.id]);
  }

  public exportLeads() {
    const filterModel = this.filterModel;
    const dealService = this.dealService;
    const exportModal = this.exportModal;

    this.exportModal.openExportModal((function (fileType) {
      const users = this.userStoreService.selectedUsersFilter.value;
      const filteredStages = this.stageFeatureStoreService.getCurrentStages.value;
      if (users && users.length > 0) {
        this.spinner = true;

        const limit = (exportModal.isCurrentViewExport && exportModal.exportDealLimit != null) ? exportModal.exportDealLimit : 0;
        const stages = (exportModal.isCurrentViewExport) ? filteredStages.map(x => x.id) : exportModal.stageFilter.dataArray && exportModal.stageFilter.dataArray.map(x => x.id);
        if (fileType == "csv") {
          let fileName = "";
          switch (ExportDealsType[exportModal.exportType]) {
            case ExportDealsType.Referrals: fileName = "Referrals Deals"; break;
            case ExportDealsType.Clients: fileName = "Clients Deals"; break;
            case ExportDealsType.ColdLeads: fileName = "Cold Leads Deals"; break;
            case ExportDealsType.SameBuildingLeads: fileName = "Same Building Leads Deals"; break;
            case ExportDealsType.CurrentViewLeads: fileName = "Current View Leads Deals"; break;
          }

          dealService.getExportListDealWithContactInfoAsCsv(
            0
            , limit
            , users.map(u => u.id)
            , filterModel.filteredLocations
            , filterModel.selectedGenderId
            , stages
            , ExportDealsType[exportModal.exportType]
            , fileName
            , filterModel.filteredCompanies
            , filterModel.filteredPositions
            , filterModel.isSameBuildingFilter
            , filterModel.isWithPhoneNumberOnlyFilter
            , exportModal.dateFrom
            , exportModal.dateTo
            , exportModal.isIncludeExportedLeads
            , exportModal.isIncludeUnexportedLeads
          ).then(() => {
            this.spinner = false;
          });
        }
        else {
          dealService.getExportListDealWithContactInfo(
            0
            , limit
            , users.map(u => u.id)
            , filterModel.filteredLocations
            , filterModel.selectedGenderId
            , stages
            , filterModel.filteredCompanies
            , filterModel.filteredPositions
            , filterModel.isSameBuildingFilter
            , filterModel.isWithPhoneNumberOnlyFilter
            , exportModal.dateFrom
            , exportModal.dateTo
            , exportModal.isIncludeExportedLeads
            , exportModal.isIncludeUnexportedLeads
          ).then(result => {
            if (result && result.data && result.data.length > 0) {
              this.exportDeals.print(result.data, ExportDealsType[exportModal.exportType]);
            }
            else {
              alert("No deals found for your export request.");
            }
            this.spinner = false;
          },
            () => {
              // this.isShowError = true;
            });
        }
      }
    }).bind(this));
  }
}
