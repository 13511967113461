import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { User } from "../../models/UserModel";
import { RolesEnum } from "../../enums/RolesEnum";

@Injectable()
export class UserStoreService {
    public readonly selectedUsersFilter = new BehaviorSubject<User[]>(null);
    public readonly loadedDealsUsersFilter = new BehaviorSubject<User[]>(null);
    public readonly selectedUserDropDown = new BehaviorSubject<User>(null);
    public readonly allUsers = new BehaviorSubject<User[]>(null);

    public reset() {
        console.log('UserStoreService reset');
        this.selectedUsersFilter.next(null);
        this.loadedDealsUsersFilter.next(null);
        this.selectedUserDropDown.next(null);
        this.allUsers.next(null);
    }

    public setSelectedUserDropDown(value: User) {
        console.log('UserStoreService setSelectedUserDropDown', value);
        this.selectedUserDropDown.next(value);
    }
    public removeSelectedUserDropDown() {
        console.log('UserStoreService removeSelectedUserDropDown');
        this.selectedUserDropDown.next(null);
    }

    public setAllUsers(value: User[]) {
        console.log('UserStoreService setAllUsers', value);
        this.allUsers.next(value);
    }

    public setSelectedUsersFilter(value: User[]) {
        console.log('UserStoreService setSelectedUsersFilter', value);
        this.selectedUsersFilter.next(value);
    }
    public removeSelectedUsersFilter() {
        console.log('UserStoreService removeSelectedUsersFilter');
        this.selectedUsersFilter.next([]);
    }

    public setLoadedDealsUsersFilter(value: User[]) {
        console.log('UserStoreService setLoadedDealsUsersFilter', value);
        this.loadedDealsUsersFilter.next(value);
    }
    public removeLoadedDealsUsersFilter() {
        console.log('UserStoreService removeLoadedDealsUsersFilter');
        this.loadedDealsUsersFilter.next([]);
    }

    public getUsersForTransferDropDown(currentDealOwner: User, allowedRoles: RolesEnum[]) {
        console.log('UserStoreService getUsersForTransferDropDown', currentDealOwner, allowedRoles);
        return this.allUsers.value.filter(user => (currentDealOwner && currentDealOwner.id === user.id) ||
            (user.role_id !== RolesEnum.EA && user.role_id !== RolesEnum.EaCaller && allowedRoles && allowedRoles.some(roleId => roleId == user.role_id)));
    }
}
