import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BaseService } from './base.service';
import { HttpRestService } from './http-client/http-rest-client.service';

@Injectable()
export class SocialMediaAdsTokensService extends BaseService {
    constructor(private restService: HttpRestService) {
        super();
    }

    public async getLocations(): Promise<any> {
        return await this.handleResponse(this.restService.get({ directUrl: environment.apiUrl + '/socialMediaAds/get-locations' }));
    }

    public async getTokens(): Promise<any> {
        return await this.handleResponse(this.restService.get({ directUrl: environment.apiUrl + '/socialMediaAds/get-tokens' }));
    }

    public async addToken(data: any): Promise<any> {
        return await this.handleResponse(this.restService.post({ directUrl: environment.apiUrl + '/socialMediaAds/add-token', data: data }));
    }
}