<div class="edit-in-progress">
  <div class="single-field">
    <input type="text"
           #inputField
           [(ngModel)]="value"
           (mousedown)="editValue()"
           value={{localName}}>
  </div>
  <div class="cancel-save-buttons">
    <button class="cancel-button"
            [hidden]="isComplete"
            (click)="cancelEdit()">
      Cancel
    </button>
    <button class="save-button"
            [disabled]="!(isAllowEmpty || (value && value.trim().length > 0))"
            [hidden]="isComplete"
            (click)="saveValue()">
      Save
    </button>
  </div>
</div>