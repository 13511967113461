import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SharedModule } from "../shared/shared.module";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from "@angular/material/core";
import { MatInputModule } from "@angular/material/input";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { TasksComponent } from "./tasks.component";
import { AppRoutingModule } from "../app-routing.module";
import { ModelerFabricUploadComponent } from "./3d-modeler-fabric-upload/3d-modeler-fabric-upload.component";
import { ImportDealsTaskComponent } from "./import-deals-task/import-deals-task.component";
import { MatRadioModule } from "@angular/material/radio";
import { SearchGenderTaskComponent } from "./search-gender-task/search-gender-task.component";
import { SearchNumbersTaskComponent } from "./search-numbers-task/search-numbers-task.component";
import { TargetedRecallTaskComponent } from "./targeted-recall-task/targeted-recall-task.component";
import { SearchAddressTaskComponent } from "./search-address-task/search-address-task.component";
import { NearbyCompaniesComponent } from "./nearby-companies/nearby-companies.component";
import { SocialMediaAdsTokensListComponent} from "./social-media-ads-tokens-list/social-media-ads-tokens-list.component";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { MatSnackBarModule } from "@angular/material/snack-bar";

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        SharedModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatSelectModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatInputModule,
        MatProgressSpinnerModule,
        MatCheckboxModule,
        AppRoutingModule,
        MatRadioModule,
        ClipboardModule,
        MatSnackBarModule
    ],
    declarations: [
        TasksComponent,
        ModelerFabricUploadComponent,
        ImportDealsTaskComponent,
        SearchGenderTaskComponent,
        SearchNumbersTaskComponent,
        TargetedRecallTaskComponent,
        SearchAddressTaskComponent,
        NearbyCompaniesComponent,
        SocialMediaAdsTokensListComponent
    ],
    providers: [
        MatDatepickerModule
    ],
    exports: [
        TasksComponent
    ]
})
export class TasksModule { }