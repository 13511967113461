@if (displayLaunchButton) {
<button type="button" class="btn btn-primary" data-bs-toggle="modal" [attr.data-bs-target]="targetAttr">
    {{launchButtonText}}
</button>
}
<div class="modal fade" [attr.id]="id" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    [attr.aria-labelledby]="ariaLabelledbyAttr" aria-hidden="true">
    <div [ngStyle]="{'min-width': minWidth+'px'}" class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" [attr.id]="ariaLabelledbyAttr">{{title}}</h5>
                <button (click)="onCompleteAction.emit()" type="button" class="btn-close" aria-hidden="true"
                    data-bs-dismiss="modal" aria-label="Close"></button>
                <span [attr.id]="id" aria-hidden="true" #close data-bs-dismiss="modal"
                    [attr.data-bs-target]="targetAttr"></span>
                <span [attr.id]="id" aria-hidden="true" #toggle data-bs-toggle="modal"
                    [attr.data-bs-target]="targetAttr"></span>
            </div>
            <div class="modal-body">
                <div style="position: relative;" class="container-fluid">
                    @if (loadingIndicator) {
                    <div [ngClass]="{'spinner-absolute': loadingIndicatorAbsolute }">
                        <mat-spinner style="margin: 0 auto;" [diameter]="100"></mat-spinner>
                    </div>
                    }
                    @if (!loadingIndicator || loadingIndicatorAbsolute) {
                    <ng-content></ng-content>
                    }
                </div>
            </div>
            <div class="modal-footer">
                <ng-container *ngIf="isInformational; then informationaltMode else defaultMode"></ng-container>
            </div>
        </div>
    </div>
</div>

<ng-template #defaultMode>
    @if (isAdditionalBtnVisible) {
    <button (click)="additionalClick.emit()" class="btn btn-danger">
        {{additionalBtnTextKey}}</button>
    }
    @if (isSecondaryBtnVisible) {
    <button [disabled]="isSecondaryBtnDisabled" (click)="secondaryClick.emit()"
        class="btn btn-secondary">{{secondaryBtnTextKey}}</button>
    }
    @if (isPrimaryBtnVisible) {
    <button [disabled]="isPrimaryBtnDisabled" (click)="primaryClick.emit()" class="btn btn-primary">
        {{primaryBtnTextKey}}</button>
    }
</ng-template>

<ng-template #informationaltMode>
    <button (click)="primaryClick.emit()" class="btn btn-primary">{{primaryBtnTextKey}}</button>
</ng-template>