import { UserContextService } from './../services/user-context.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class AuthGuard {
    returnUrl: string;
    constructor(public userContextService: UserContextService, public router: Router) {
        this.returnUrl = window.location.pathname;
    }

    canActivate(): boolean {
        if (!this.userContextService.user.value) {
            this.router.navigate(['/login'], { queryParams: { returnUrl: this.returnUrl } });
            return false;
        }
        return true;
    }
}
