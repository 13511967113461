<div class="row wrapper">
  <div class="col-4 title">
    Owner
  </div>
  <div class="col-8 work-field">
    @if (!owner && !isEdit) {
    <pd-add-value (addValue)="addValue()"></pd-add-value>
    }
    @if (isEdit || owner) {
    <mat-form-field class="owner-select-global owner-select">
      <mat-select [(ngModel)]="owner">
        @for (item of filteredUsers; track item; let i = $index) {
        <mat-option [value]="item.id" (click)="changeEdit(item.id)">{{item.name}}</mat-option>
        }
      </mat-select>
    </mat-form-field>
    }
  </div>
</div>