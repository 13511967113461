<div mat-dialog-title>
  {{editedCard.achievementCardTypeName}}
</div>
<mat-dialog-content>
  <div class="modal-body">

    <div class="row hover">
      <div class="info-label text">
        Image
      </div>
      <div class="text image">
        @if (imageUrl) {
        <img [src]="imageUrl" width="200" height="200"
          onerror="this.onerror=null; this.src='../../assets/lgfg-logo.png'" />
        }
        @if (!imageUrl) {
        <img src='../../assets/lgfg-logo.png' width="200" height="200" />
        }
      </div>
    </div>

    @if (isShowUploadImageField()) {
    <div class="row hover card-input-row">
      <div class="info-label text">
      </div>
      <div class="col d-flex align-items-center">
        <form action="" method="post" enctype="multipart/form-data">
          <div (click)="fileInput.click()">
            <input (change)="changeListenerImage($event.target.files)" class="d-none" type="file" #fileInput
              ng2FileSelect [uploader]="imageUploader" [accept]="fileAccept" />
            <div class="image-upload">Update Image</div>
          </div>
        </form>
      </div>

      <div class="row alert-row">
        @if (isImageLoadErrorValidation) {
        <div class="alert alert-danger">
          Wrong format of the file, please try again
        </div>
        }
        @if (isImageLoadSuccess) {
        <div class="alert alert-success">
          Images uploaded
        </div>
        }
      </div>
    </div>
    }

    <div class="row hover card-input-row" style="margin-top: 20px;">
      <div class="info-label text">
        Date <span class="star">*</span>
      </div>

      @if (isShowAnnualDateField()) {
      <div class="col">
        <mat-form-field>
          <input matInput [matDatepicker]="dateFromPicker" placeholder="Choose a date" [(ngModel)]="editedCard.date"
            [max]="maxDate" [matDatepickerFilter]="filterDateFrom">
          <mat-datepicker-toggle matSuffix [for]="dateFromPicker">
          </mat-datepicker-toggle>
          <mat-datepicker #dateFromPicker (dateChange)="onDataChange()" startView="multi-year">
          </mat-datepicker>
        </mat-form-field>
      </div>
      }

      @if (isShowMonthlyDateField()) {
      <div class="col">
        <mat-form-field>
          <input matInput [matDatepicker]="dateFromPicker" placeholder="Choose a date" [(ngModel)]="editedCard.date"
            [max]="maxDate" [matDatepickerFilter]="filterDateFrom">
          <mat-datepicker-toggle matSuffix [for]="dateFromPicker">
          </mat-datepicker-toggle>
          <mat-datepicker #dateFromPicker (dateChange)="onDataChange()" startView="year"></mat-datepicker>
        </mat-form-field>
      </div>
      }

      @if (isShowDefaultDateField()) {
      <div class="col">
        <mat-form-field>
          <input matInput [matDatepicker]="dateFromPicker" placeholder="Choose a date" [(ngModel)]="editedCard.date"
            [max]="maxDate" [matDatepickerFilter]="filterDateFrom">
          <mat-datepicker-toggle matSuffix [for]="dateFromPicker">
          </mat-datepicker-toggle>
          <mat-datepicker #dateFromPicker (dateChange)="onDataChange()"></mat-datepicker>
        </mat-form-field>
      </div>
      }
    </div>

    @if (isShowAchievementTypeField()) {
    <div class="row hover card-input-row">
      <div class="info-label text">
        Achievement Type <span class="star">*</span>
      </div>
      <div class="col">
        <mat-form-field>
          <mat-label>Choose a type</mat-label>
          <mat-select (selectionChange)="findImgUrl()" [(ngModel)]="editedCard.achievementCardTypeId">
            @for (type of achievementCardTypes; track type) {
            <mat-option [value]="type.id">
              {{type.name}}
            </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    }

    @if (isShowClientIdField()) {
    <div class="row hover card-input-row">
      <div class="info-label text">
        Client Id <span class="star">*</span>
      </div>
      <div class="col">
        <mat-form-field>
          <input matInput type="text" placeholder="Client Id" (input)="onClientIdInput($event)"
            [(ngModel)]="editedCard.clientId">
        </mat-form-field>
      </div>
    </div>
    }

    @if (isShowOrderIdField()) {
    <div class="row hover card-input-row">
      <div class="info-label text">
        Order Id <span class="star">*</span>
      </div>
      <div class="col">
        <mat-form-field>
          <input matInput type="text" placeholder="Order Id" (input)="onOrderIdInput($event)"
            [(ngModel)]="editedCard.orderId">
        </mat-form-field>
      </div>
    </div>
    }

    @if (isShowCustomTitleField()) {
    <div class="row hover card-input-row">
      <div class="info-label text">
        Custom Title @if (isShowCustomTitleField()) {
        <span class="star">*</span>
        }
      </div>
      <div class="col">
        <mat-form-field>
          <input matInput type="text" placeholder="Custom Title" [(ngModel)]="editedCard.customTitle">
        </mat-form-field>
      </div>
    </div>
    }

    @if (isShowClientNameField()) {
    <div class="row hover card-input-row">
      <div class="info-label text">
        Client Name <span class="star">*</span>
      </div>

      <div class="col">
        <mat-form-field>
          <input matInput type="text" placeholder="Client Name" [(ngModel)]="editedCard.clientName">
        </mat-form-field>
      </div>
    </div>
    }

    @if (isShowAmountField()) {
    <div class="row hover card-input-row">
      <div class="info-label text">
        Amount <span class="star">*</span>
      </div>
      <div class="col">
        <mat-form-field>
          <input matInput #amountInput [(ngModel)]="editedCard.amount" placeholder="Amount" type="text"
            (input)="onAmountInput($event);amountFormControlValidation()" (blur)="amountFormControlValidation()"
            onCopy="return false" onDrag="return false" onDrop="return false" onPaste="return false" autocomplete=off
            [formControl]="amountControl" [ngClass]="{'input-error': isShowAmountValidationMessage }">
        </mat-form-field>
      </div>
    </div>
    }

    @if (isShowAmountValidationMessage) {
    <div class="row hover card-input-row">
      <div class="info-label text">
      </div>
      <div class="col">
        <div class="row alert-row">
          <div class="alert alert-danger" style="padding: 5px; margin-bottom: 0;">
            Required. 10000.00 format
          </div>
        </div>
      </div>
    </div>
    }

    @if (isShowLinkField()) {
    <div class="row hover card-input-row">
      <div class="info-label text">
        Details
      </div>
      <div class="col">
        <mat-form-field>
          <input matInput type="text" placeholder="Details" [(ngModel)]="editedCard.link">
        </mat-form-field>
      </div>
    </div>
    }

    @if (isShowGroupCheckbox()) {
    <div class="row hover card-input-row">
      <div class="info-label text">
        For all clothiers
      </div>
      <div class="col">
        <mat-checkbox [(ngModel)]="isGroupped">
          Yes</mat-checkbox>
      </div>
    </div>
    }

  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div>
    @if (editedCard.id > 0) {
    <button value="Delete" (click)="deleteCard()" class="btn btn-outline-danger min-btn">
      <i class="material-icons">delete</i>
    </button>
    }
  </div>

  <div>
    <button type="button" mdbBtn class="waves-light btn-danger btn float-right" aria-label="Close"
      (click)="modalFinishAction(false)" mdbWavesEffect>Cancel</button>

    <button type="button" mdbBtn class="relative waves-light btn-success btn float-right" [disabled]="!isValid()
              || isShowAmountValidationMessage
              " (click)="modalFinishAction(true)">Save</button>
  </div>
</mat-dialog-actions>