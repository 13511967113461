<div class="wrapper" style="height: 100%; width: 100%;">
  <div>
    @if (isEdit) {
    <div>
      <div class="edit-in-progress">
        @for (phoneRow of phones; track phoneRow; let i = $index) {
        <div style="display: flex; margin-bottom: 15px;">
          <div>
            <div class="intl-tel-field">
              <pd-field-intl-tel-input #inputFields (phoneChange)="onPhoneChange(phoneRow, $event)"
                [phone]="phoneRow.value">
              </pd-field-intl-tel-input>
            </div>
          </div>
          <div>
            @if (!isSinglePhone){  
            <div class="label-field">
              <div class="select">
                <mat-form-field class="selector">
                  <mat-select [(value)]="phoneRow.label">
                    @for (item of phoneLabels; track item) {
                    <mat-option [value]="item">{{item}}</mat-option>
                    }
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            }
          </div>
          <div>
            <div class="delete" (click)="deletePhone(i)">
              <i style="margin-left: 12px;" class="material-icons">delete</i>
            </div>
          </div>
        </div>
        }
        <div class="tool-panel-wrapper">
          @if (!isSinglePhone){      
          @if (phones.length == 0) {
          <div class="add-one-more-wraper">
            <div class="add-one-more add-value" (click)="addOneMore()">
              <div>+Add value</div>
            </div>
          </div>
          }
          @if (phones.length > 0) {
          <div class="add-one-more" (click)="addOneMore()">
            <div>+Add one more</div>
          </div>
          }
        }
          @if (isEdit && isNeedSaveCancelBtn) {
          <div class="cancel-save-buttons">
            <button class="cancel-button" (click)="cancel()">Cancel</button>
            <button class="save-button" (click)="save()">Save</button>
          </div>
          }
        </div>
      </div>
    </div>
    }
  </div>
</div>