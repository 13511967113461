import { Component } from '@angular/core';
import Printd from 'printd';
import { ImageTag } from 'src/app/core/enums/ImageTag.enum';
import { ImageService } from 'src/app/core/services/image.service';

@Component({
  selector: 'pd-wardrobe-plan',
  templateUrl: './wardrobe-plan.component.html',
  styleUrls: ['./wardrobe-plan.component.scss']
})
export class WardrobePlanComponent {
  images: any
  public wardrobeImage1: any
  public wardrobeImage2: any
  constructor(private imageServ: ImageService) { }

  public async printPdf(dealId, title): Promise<void> {
    const response = await this.imageServ.getWardrobeImagesByDealId(dealId);

    this.images = response;
    if (this.images) {
      this.wardrobeImage1 = this.images.find(f => f.imageTag == ImageTag.Wardrobe1)
      this.wardrobeImage2 = this.images.find(f => f.imageTag == ImageTag.Wardrobe2)
    }

    setTimeout(() => {
      const cssWardrobe = `img {
        width: 989px;
        height: 1280px;
    }`;
      const titleString = document.getElementsByTagName('title')[0].textContent;
      const d: Printd = new Printd();
      document.title = title + " Wardrobe Plan";
      d.print(document.getElementById('print-wardrobe-plan-section'), [cssWardrobe]);
      document.title = titleString;
    }, 1000)
  }
}
