import { GlobalConstants } from './../../core/global-constants';
import { Pipe, PipeTransform } from '@angular/core';
import * as stagesDict from '../../../assets/stages_new_prospespects';
import { RolesEnum } from 'src/app/core/enums/RolesEnum';

@Pipe({
    name: 'masterLead'
})
export class MasterLeadPipe implements PipeTransform {
    transform(stages: any[], role: any, doPerform = true): any[] {
      if (role === RolesEnum.MasterLead && !stages.some(stage => stage.id === GlobalConstants.ALL_STAGES.ColdLOOKUP ||
          stage.id === GlobalConstants.ALL_STAGES.LinkedinLOOKUP)) {
        stages.push({ id: GlobalConstants.ALL_STAGES.ColdLOOKUP, name: stagesDict.STAGES_NEW_PROSPECTS_DICT
                      .find(st => st.id === GlobalConstants.ALL_STAGES.ColdLOOKUP).name },
                    { id: GlobalConstants.ALL_STAGES.LinkedinLOOKUP, name: stagesDict.STAGES_NEW_PROSPECTS_DICT
                      .find(st => st.id === GlobalConstants.ALL_STAGES.LinkedinLOOKUP).name });
      }

      return stages;
    }
}
