import { Component, ViewChild, Output, EventEmitter, OnInit } from '@angular/core';
import { CustomRottenTimeModalOptions } from './custom-rotten-time-modal.options';
import { CustomRottenTimeModalModel } from 'src/app/core/models/CustomRottenTimeModalModel';
import { ModalComponent } from '../../modal/modal.component';

@Component({
  selector: 'pd-custom-rotten-time-modal',
  templateUrl: './custom-rotten-time-modal.component.html',
  styleUrls: ['./custom-rotten-time-modal.component.scss']
})

export class CustomRottenTimeModalComponent implements OnInit {
  @ViewChild('modal') modal: ModalComponent;

  @Output() closeModal = new EventEmitter<object>();
  @Output() saveDate = new EventEmitter<CustomRottenTimeModalModel>();

  public selectedOption: string;
  public isCustomDate: boolean;

  private today: Date;

  public title: string;
  public isDateOptions: boolean;
  public minDate: Date;
  public maxDate: Date;
  public selectedDate: Date;

  private customDate: Date;
  private confirmAction: any;
  private isNrContactedRepeat: boolean;

  ngOnInit() {
    this.isCustomDate = false;
    this.isNrContactedRepeat = false;

    this.today = new Date();
    this.minDate = new Date();
    this.maxDate = new Date();

    this.selectedDate = new Date(this.minDate);
  }

  public onOpenedDatepicker(): void { //todo check if this is still needed
    //$(".mat-calendar-body-disabled").css("pointer-events", "none"); //todo find another way to disable pointer-events on mat-year-view
  }

  radioChange(event) {
    event.value === "customDate"
      ? this.isCustomDate = true :
      this.isCustomDate = false;
  }

  public openCustomDateModal(confirmCallback, options: CustomRottenTimeModalOptions) {
    this.title = options.Title;
    this.isDateOptions = options.IsDateOptions;
    this.minDate = options.MinDate;
    this.maxDate = options.MaxDate;

    this.selectedDate = new Date(this.minDate);

    this.confirmAction = confirmCallback;
    this.modal.open();
  }

  public confirm(isConfirm) {
    isConfirm ? this.ok() : this.cancel();
    this.modal.close();
  }

  private ok() {
    this.customDate = new Date();
    switch (this.selectedOption) {
      case "3":
        this.customDate.setMonth(this.today.getMonth() + 3);
        this.isNrContactedRepeat = false;
        break;
      case "6":
        this.customDate.setMonth(this.today.getMonth() + 6);
        this.isNrContactedRepeat = true;
        break;
      default: this.customDate.setFullYear(this.selectedDate.getFullYear(), this.selectedDate.getMonth(), this.selectedDate.getDate());
        this.isNrContactedRepeat = true;
        break;
    }

    if (this.confirmAction) {
      this.confirmAction();
    }

    this.saveDate.emit(new CustomRottenTimeModalModel(this.customDate, this.isNrContactedRepeat));

    this.isCustomDate = false
    this.isNrContactedRepeat = false;
    this.selectedOption = null;
  }

  private cancel() {
    this.isCustomDate = false
    this.isNrContactedRepeat = false;
    this.selectedOption = null;
    this.selectedDate = new Date(this.minDate);
    this.customDate = null;
    this.closeModal.emit();
    this.modal.close();
  }
}