import { Component, ViewChild, ElementRef } from '@angular/core';
import { Subject } from 'rxjs';;
import { FileUploader, FileUploaderOptions } from 'ng2-file-upload';
import { UploadImageService } from 'src/app/core/services/upload-image.service';
import { ImageTag } from 'src/app/core/enums/ImageTag.enum';
import { ModalComponent } from '../../modal/modal.component';

@Component({
  selector: 'pd-custom-ea-follow-up-image-upload-modal',
  templateUrl: './custom-ea-follow-up-image-upload-modal.component.html',
  styleUrls: ['./custom-ea-follow-up-image-upload-modal.component.scss']
})
export class CustomUploadEaFollowUpImageModalComponent {
  @ViewChild('modal') uploadModal: ModalComponent;
  @ViewChild('fileInput') fileInputViewChild: ElementRef;

  onDestroy$ = new Subject();

  constructor(private uploadImageServ: UploadImageService) { }

  uploaderOptions: FileUploaderOptions;
  uploader: FileUploader;

  currentDealId: number;
  currentStageId: number;
  currentImageId: number;
  imageTag: number;

  isUploadSuccess = false;
  isUploadLoading = false;
  isUploadErrorValidation = false;

  confirmAction: any;
  cancelAction: any;

  fileAccept = UploadImageService.allowedImageTypes.join(',');

  ngOnInit(): void {
    this.uploader = new FileUploader(this.uploadImageServ.uploaderOptions);

    this.uploader.onAfterAddingFile = (fileItem: any): any => {
      console.log('onAfterAddingFile', fileItem);
      fileItem.imageTag = this.imageTag;
      this.isUploadErrorValidation = false;
      this.isUploadSuccess = false;
    };

    this.uploader.onWhenAddingFileFailed = (fileItem: any, filter: any, options: any): any => {
      this.isUploadErrorValidation = true;
      this.isUploadSuccess = false;
    };

    this.uploader.onBuildItemForm = this.onBuildItemForm;
    this.uploader.onSuccessItem = this.onSuccessItem;
    this.uploader.onErrorItem = this.onErrorItem;
  }

  public openUploadModal(confirmCallback: any, cancelCallback: any, dealId: number, stageId: number, imageId?: number) {
    this.resetModal();
    this.currentDealId = dealId;
    this.currentStageId = stageId;
    this.currentImageId = imageId;
    this.imageTag = ImageTag.EaFollowUp;
    this.confirmAction = confirmCallback;
    this.cancelAction = cancelCallback;
    this.uploadModal.open();
  }

  public upload() {
    console.log('upload', this.fileInputViewChild.nativeElement.value);
    if (this.fileInputViewChild.nativeElement.value) {
      this.isUploadLoading = true;
      this.uploader.uploadAll();
    }
  }

  public confirm(isConfirm: boolean) {
    isConfirm ? this.ok() : this.cancel();
    this.uploadModal.close();
    this.resetModal();
  }

  private ok() {
    if (this.confirmAction)
      this.confirmAction();
  }

  private cancel() {
    if (this.cancelAction)
      this.cancelAction()
  }

  private resetModal() {
    this.currentDealId = undefined;
    this.currentStageId = undefined;
    this.currentImageId = undefined;
    this.imageTag = undefined;
    this.fileInputViewChild.nativeElement.value = "";
    this.isUploadSuccess = false;
    this.isUploadLoading = false;
    this.isUploadErrorValidation = false;
    this.confirmAction = undefined;
    this.cancelAction = undefined;
  }

  private onBuildItemForm = (fileItem: any, form: FormData): any => {
    console.log('onBuildItemForm', fileItem);
    form.append('dealId', this.currentDealId.toString());
    form.append('stageId', this.currentStageId.toString());
    if (this.currentImageId) {
      form.append('id', this.currentImageId.toString());
    }
    form.append('fileName', fileItem.some.name);
    form.append('imageTag', fileItem.imageTag);
    fileItem.withCredentials = false;
    return { fileItem, form };
  };

  private onSuccessItem = (fileItem: any, response: string, status: number, headers: any) => {
    console.log('onSuccessItem', fileItem);
    if (fileItem.imageTag == ImageTag.EaFollowUp) {
      this.fileInputViewChild.nativeElement.value = "";
      this.isUploadSuccess = true;
      this.isUploadLoading = false;
    }

    this.confirm(true);
  }

  private onErrorItem = (fileItem, response, status, headers) => {
  }
}


