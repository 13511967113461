import { Injectable } from '@angular/core';
import { HttpRestService } from './http-client/http-rest-client.service';
import { ImageUtil } from '../utils/image.util';
import { FabricImage } from '../models/ClientCardConfig';
import { Modeler3dCardConfig } from '../models/Modeler3dCardConfig';
import { BaseService } from './base.service';

@Injectable()
export class Modeler3dProductService extends BaseService {
    constructor(private restService: HttpRestService) {
        super();
    }

    public async getModeler3dCardConfig(): Promise<Modeler3dCardConfig> {
        let url = '/modeler3d/getModeler3dCardConfig/';
        return await this.handleResponse(this.restService.get({ url }));
    }

    public async getFabricImages(): Promise<FabricImage[]> {
        let url = '/modeler3d/getFabricImages/';
        return await this.handleResponse(this.restService.get({ url }));
    }

    public getModeler3dCardImgUrl(fabricName: string): string {
        return ImageUtil.fabricToUrl(fabricName);
    }
}