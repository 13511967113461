import {
    Directive,
    Input,
    HostListener
} from "@angular/core";
import { UtilsHelper } from "src/app/core/utils/utils.helper";

@Directive({
    selector: '[text-copy]'
})
export class TextCopyDirective {

    // Parse attribute value into a 'text' variable
    @Input('text-copy') text: string;

    constructor() {
    }


    // The HostListener will listen to click events and run the below function, the HostListener supports other standard events such as mouseenter, mouseleave etc.
    @HostListener('click') copyTextClick() {
        UtilsHelper.copyText(this.text);
    }
}