import { UserContextService } from './../core/services/user-context.service';
import { Component, ElementRef, OnInit, SecurityContext, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { FormControl, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { StripeModalComponent } from './stripe-modal/stripe-modal.component';
import { User } from '../core/models/UserModel';
import { PaymentTransactionInfo } from '../core/models/payment-models/PaymentTransactionInfo';
import { PaymentSystems } from '../core/enums/PaymentSystems';
import { PaymentCurrencies } from '../core/enums/PaymentCurrencies';
import { PaymentService } from '../core/services/payment.service';
import { PaymentProvince } from '../core/models/payment-models/PaymentProvince';
import RevolutCheckout, { Mode } from '@revolut/checkout';
import * as braintree from 'braintree-web';

@Component({
  selector: 'pd-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit, AfterViewInit, OnDestroy {
  currentUser: User;

  @ViewChild("targetScroll") targetScroll: ElementRef
  @ViewChild('amountInput') amountInput: ElementRef;
  @ViewChild('stripeModal') stripeModal: StripeModalComponent;

  bamboraUrl: any;
  paymentResponse: PaymentTransactionInfo;
  PaymentSystems = PaymentSystems;
  PaymentCurrencies = PaymentCurrencies;

  isPay = false;
  isPaid = false;
  blocker = false;
  provincies: PaymentProvince[];
  selectedProvince: PaymentProvince;
  selectedPaymentSystem: PaymentSystems;
  clientName = "";
  clientEmail = "";
  comment = "";
  amount = "";

  clientNameControl = new FormControl('')
  emailControl = new FormControl('', [
    Validators.required
    , Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[a-z]{2,4}$'), this.noCyrillic])
  provinceControl = new FormControl('');
  amountControl = new FormControl('');
  commentsControl = new FormControl('');

  isShowClientNameValidationMessage = false;
  isShowEmailValidationMessage = false;
  isShowProvinceValidationMessage = false;
  isShowAmountValidationMessage = false;
  isSpinner = false;
  isBrainTreeSpinner = false;
  public hostedFieldsInstance: braintree.HostedFields;
  public orderId: number;
  public threeDS: any
  public braintreeAmount: number;
  public clientInstance: any;
  public error: string;

  private _onDestroy = new Subject<void>();

  constructor(
    private sanitizer: DomSanitizer,
    private paymentService: PaymentService,
    private userContextService: UserContextService) {

    this.clientNameControl.valueChanges
      .pipe()
      .subscribe(() => {
        this.isPay = false;
      });

    this.emailControl.valueChanges
      .pipe()
      .subscribe(() => {
        this.isPay = false;
      });

    this.provinceControl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.isPay = false;
      });

    this.amountControl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.isPay = false;
      });

    this.commentsControl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.isPay = false;
      });
  }

  ngOnDestroy(): void {
    document.body.style.overflowY = "hidden";
  }

  ngAfterViewInit(): void {
    document.body.style.overflowY = "scroll";
  }

  async ngOnInit() {
    this.initializeStore();
    await this.getPaymentProvinces();
  }

  private initializeStore() {
    this.currentUser = this.userContextService.user.value;
  }

  onAmountInput(event) {
    if (event.target.value) {
      let val = event.target.value
      val = val.replace(',', '.');
      val = val.replace(/[^0-9\.]/g, '');
      val = val.replace(/^([^.]*\.)(.*)$/, function (a, b, c) { return b + c.replace(/\./g, ''); });

      event.target.value = val;

      let dollar = val.split('.')[0];
      let cents = val.split('.')[1];

      if (dollar && dollar.length > 10) {
        dollar = dollar.substring(0, 10);
        event.target.value = dollar;
      }

      if (cents) {
        if (cents.length == 1) {
          let cent = cents[0];
          event.target.value = dollar + '.' + cent;
        }

        if (cents.length >= 2) {
          let cent = cents[0] + cents[1];
          event.target.value = dollar + '.' + cent;
        }
      }
    }

    this.amount = event.target.value;
  }

  onClientNameKeydown(event) {
    if (!(/[A-Za-z0-9,\- ;:()'"\.]/).test(event.key)) {
      event.preventDefault();
    }
  }

  onEmailKeydown(event) {
  }

  validation() {
    if (this.clientNameControl.touched) {
      this.isShowClientNameValidationMessage = !this.requiredValidator(this.clientName)
        || !this.lengthValidator(this.clientName);
    }
    else {
      this.isShowClientNameValidationMessage = false;
    }

    if (this.emailControl.touched) {
      this.isShowEmailValidationMessage = this.emailControl.errors &&
        (this.emailControl.errors.required
          || this.emailControl.errors.email
          || this.emailControl.errors.pattern
          || this.emailControl.errors.cyrillic
        );
    }
    else {
      this.isShowEmailValidationMessage = false;
    }

    if (this.provinceControl.touched) {
      this.isShowProvinceValidationMessage = !this.requiredValidator(this.selectedProvince ? this.selectedProvince.name : '')
    }
    else {
      this.isShowProvinceValidationMessage = false;
    }

    if (this.amountControl.touched) {
      this.isShowAmountValidationMessage = !(+this.amount > 0)
        || !this.amountPatternValidator(this.amount)
    }
    else {
      this.isShowAmountValidationMessage = false;
    }
  }

  async onPayment(): Promise<void> {
    this.validation();

    this.paymentResponse = null;
    this.isPay = false;
    this.isPaid = false;
    this.clientEmail = this.clientEmail.toLowerCase();
    
    const clientPaymentSystem = await this.getClientPaymentSystem();
    this.selectedPaymentSystem = clientPaymentSystem ? clientPaymentSystem : this.selectedProvince.paymentSystem;

    if (this.selectedPaymentSystem == null) {
      return;
    }
    this.isPay = true;
    this.blocker = false;
    if (this.paymentSystemCheck(PaymentSystems.BrainTree)) {
      await this.createBraintreeUI(await this.paymentService.getBraintreeToken(this.selectedProvince.name));
    }

    if (this.paymentSystemCheck(PaymentSystems.Bambora)) {
      const bamboraUrl = await this.paymentService.getPaymentUrl(this.amount, this.clientName, this.clientEmail, this.selectedProvince.name,
        this.comment);

      if (bamboraUrl) {
        this.bamboraUrl = this.sanitizer.bypassSecurityTrustResourceUrl(bamboraUrl);
        window.open(this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, this.bamboraUrl), '_blank')
      }
    }

    if (this.paymentSystemCheck(PaymentSystems.Revolut)) {
      this.onRevolutResult(await this.paymentService.getRevolutOrderPublicId(this.amount, this.clientName, this.clientEmail,
        this.selectedProvince.name, this.comment));
    }

    if (this.paymentSystemCheck(PaymentSystems.Stripe)) {
      const response = await this.paymentService.createStripePaymentIntent(this.amount, this.clientName, this.clientEmail,
        this.selectedProvince.name, this.comment);

      const clientSecret = response.clientSecret;
      if (!clientSecret) {
        this.setPaymentTransactionInfo(response.transactionMessage ? response.transactionMessage : "Something went wrong.");
        return;
      }

      this.stripeModal.openModal(response.transactionId, clientSecret);
    }
  }

  async onRevolutResult(response): Promise<void> {
    let revoluteResponce = response;
    this.paymentResponse = null;

    if (revoluteResponce) {
      if (revoluteResponce && revoluteResponce.publicId) {
        const revolutOrder = await RevolutCheckout(revoluteResponce.publicId, environment.revolutMode as Mode);
        revolutOrder.payWithPopup({
          name: this.clientName,
          onSuccess: async () => {
            this.setPaymentTransactionInfo("Success");
            await this.paymentService.sentRevolutPaymentResult(revoluteResponce.paymentConversationId, this.paymentResponse.transactionMessage);
            this.targetScroll.nativeElement.click();
          },
          onError: async (apiMessage) => {
            this.setPaymentTransactionInfo("Error");
            await this.paymentService.sentRevolutPaymentResult(revoluteResponce.paymentConversationId, JSON.stringify(apiMessage));
            this.targetScroll.nativeElement.click();
          },
          onCancel: async () => {
            this.setPaymentTransactionInfo("Canceled");
            await this.paymentService.sentRevolutPaymentResult(revoluteResponce.paymentConversationId, this.paymentResponse.transactionMessage);
            this.isPay = false;
            this.targetScroll.nativeElement.click();
          }
        });
      }
      else {
        this.setPaymentTransactionInfo("Error");
      }
    }
  }

  onStripePaymentSuccess() {
    this.setPaymentTransactionInfo("Success");
  }

  async onStripeStatusUpdated(response: PaymentTransactionInfo): Promise<void> {
    await this.paymentService.sentStripePaymentResult(response.transactionId, response.transactionMessage);
    if (response.transactionMessage == "Success") {
      this.selectedProvince = null;
      await this.getPaymentProvinces();
    }
  }

  onDropinLoaded(): void {
    this.scrollToPayment();
  }

  async onBraintreePaymentStatus(response): Promise<void> {
    this.setPaymentTransactionInfo(response.message);
    await this.getPaymentProvinces();
    this.scrollToPayment();
  }

  onClear(): void {
    this.clientNameControl.reset()
    this.emailControl.reset()
    this.provinceControl.reset()
    this.amountControl.reset()
    this.clientName = "";
    this.clientEmail = "";
    this.selectedProvince = null;
    this.selectedPaymentSystem = null;
    this.amount = "";
    this.comment = "";
    this.isPay = false;
    this.bamboraUrl = "";
    this.paymentResponse = null;
    this.validation();
  }

  public paymentSystemCheck(paymentSystems: PaymentSystems): boolean {
    return this.selectedPaymentSystem === paymentSystems;
  }

  public get isDisablePayment(): boolean {
    return this.isShowClientNameValidationMessage
      || this.isShowProvinceValidationMessage
      || this.isShowAmountValidationMessage
      || this.isShowEmailValidationMessage
      || !this.clientNameControl.touched
      || (this.isStripePaymentSystem && !this.clientNameControl.touched)
      || !this.provinceControl.touched
      || !this.amountControl.touched
      || !this.selectedProvince
      || this.isPay
  }

  public get isStripePaymentSystem(): boolean {
    return this.selectedPaymentSystem == PaymentSystems.Stripe;
  }

  public async tokenizeUserDetails(): Promise<void> {
    this.error = null;
    try {
      if (this.blocker) {
        return;
      }

      setTimeout(() => {
        this.blocker = false;
      }, 4000);
      this.blocker = true;

      this.isBrainTreeSpinner = true;
      const payload = await this.hostedFieldsInstance.tokenize();
      /*   var result = await this.threeDS.verifyCard({
              amount: this.amount,
              nonce: payload.nonce,
              onLookupComplete: (data, next) => next()
          });*/
      const response = await this.paymentService.payBraintree(payload.nonce, this.amount, this.clientName, this.clientEmail, this.selectedProvince.name, this.comment);
      this.setPaymentTransactionInfo(response.message);

      if (response.message == "Success") {
        this.selectedProvince = null;
        await this.getPaymentProvinces();
      }

      this.isPaid = true;
    } catch (ex) {
      this.error = ex.message;
    }
    finally {
      this.isBrainTreeSpinner = false;
    }
  }

  private setPaymentTransactionInfo(transactionMessage: string) {
    this.paymentResponse = new PaymentTransactionInfo({ transactionMessage: transactionMessage });
  }

  private requiredValidator(value): boolean {
    let isWhitespace = (value || '').trim().length === 0;
    let isValid = !isWhitespace;
    return isValid;
  }

  private lengthValidator(value): boolean {
    let isValid = (value || '').trim().length > 4;
    return isValid;
  }

  private amountPatternValidator(value): boolean {
    let isValid = (value || '').match(/^\d+(\.\d{1,2})?$/);
    return isValid;
  }

  private noCyrillic(control) {
    if (/[а-яё]/i.test(control.value)) {
      return { 'cyrillic': true }
    }
    return null;
  }

  private scrollToPayment() {
    setTimeout(() => {
      this.targetScroll.nativeElement.scrollIntoView({ block: "start", behavior: "smooth" });
    }, 500);
  }

  private async getClientPaymentSystem(): Promise<PaymentSystems> {
    let clientPaymentSystem: PaymentSystems = null;
    const clientProvince = await this.paymentService.getClientPaymentProvincesByEmail(this.clientEmail);

    if (clientProvince) {
      const province = clientProvince.find(x => x.name === this.selectedProvince.name);
      if (province) {
        clientPaymentSystem = province.paymentSystem;
      }
    }

    return clientPaymentSystem;
  }

  private async getPaymentProvinces() {
    this.isSpinner = true;
    this.provincies = await this.paymentService.getPaymentProvinces();
    this.isSpinner = false;
  }

  private findLabel(field: braintree.HostedFieldsHostedFieldsFieldData) {
    return document.querySelector('.hosted-field--label[for="' + field.container.id + '"]');
  }

  private async getThreeDS(clientInstance: any): Promise<any> {
    return await braintree.threeDSecure.create({
      client: clientInstance,
      version: 2
    });
  }

  private async getHostedFields(clientInstance): Promise<any> {
    return await braintree.hostedFields.create({
      client: clientInstance,
      fields: {
        number: {
          selector: '#card-number',
          placeholder: '4111 1111 1111 1111'
        },
        expirationDate: {
          selector: '#expiration-date',
          placeholder: 'MM/YY'
        }
      }
    });
  }

  private setValidation() {
    this.hostedFieldsInstance.on('focus', (event) => {
      const field = event.fields[event.emittedBy];
      const label = this.findLabel(field);
      label.classList.remove('filled');
    });

    this.hostedFieldsInstance.on('validityChange', (event) => {
      const field = event.fields[event.emittedBy];
      const label = this.findLabel(field);
      if (field.isPotentiallyValid) {
        label.classList.remove('invalid');
      } else {
        label.classList.add('invalid');
      }
    });
  }

  private async createBraintreeUI(token): Promise<void> {
    this.error = null;
    if (!this.clientInstance) {
      this.clientInstance = await braintree.client.create({
        authorization: token
      });
      // this.threeDS = await this.getThreeDS(this.clientInstance);
    }
    this.hostedFieldsInstance = await this.getHostedFields(this.clientInstance);

    this.setValidation();
  }
}
