import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { filterNil } from "../../filter-nil";
import { AchievementCardModel } from "../../models/AchievementCardModel";

@Injectable()
export class AchievementCardStoreService {
    private readonly achievementCards$: BehaviorSubject<AchievementCardModel[]> = new BehaviorSubject<AchievementCardModel[]>(null);

    public getAchievementCards(): Observable<AchievementCardModel[]> {
        return this.achievementCards$.asObservable().pipe(filterNil());
    }

    public getAllAchievementCardsSnapshot(): AchievementCardModel[] {
        return this.achievementCards$.getValue();
    }

    public setAchievementCards(achievementCards: AchievementCardModel[]): void {
        this.achievementCards$.next(achievementCards);
    }
}