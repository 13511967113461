import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'pd-edit-in-progress',
  templateUrl: './edit-in-progress.component.html',
  styleUrls: ['./edit-in-progress.component.scss']
})
export class EditInProgressComponent implements OnInit {

  @Input() localName: string
  @Input() isAllowEmpty: boolean = true;
  public value: string
  public isComplete: boolean
  @Output() cancel = new EventEmitter<void>()
  @Output() save = new EventEmitter<string>()

  constructor() { }

  ngOnInit() {
    this.isComplete = false
  }

  /**
    * Cancel edit
    * @memberof EditInProgressComponent
    */
  cancelEdit() {
    this.cancel.emit();
  }

  /**
  * Save value
  * @memberof EditInProgressComponent
  */
  saveValue() {
    this.save.emit(this.value);
  }

  editValue() {
    this.isComplete = false
  }
}
