import { takeUntil } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import html2canvas from "html2canvas"
import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { BaseComponent } from '../core/base.component';
import { AvatarDealSettingsComponent } from '../shared/avatar-deal-builder/avatar-deal-builder.component';
import { AvatarBuilderComponent } from '../shared/avatar-builder/avatar-builder.component';
import { AvatarExpansionPanelComponent } from './avatar-expansion-panel/avatar-expansion-panel.component';
import { DealUtil } from '../core/utils/deal.util';
import { ClientCard } from '../core/models/ClientCard';
import { AllowedAvatarTypesForProductStyle, AllowedAvatarTypesForProductType, AvatarCollectionsViewConfig, CardLine, CustomLink, FabricConfig, FabricImage, ProductType, TypeOfSuit } from '../core/models/ClientCardConfig';
import { Avatar } from '../core/models/avatar-models/Avatar';
import { AvatarCollection } from '../core/models/avatar-models/AvatarCollection';
import { AvatarApiService } from '../core/services/avatar/avatar-api.service';
import { AvatarFacadeService } from '../core/services/avatar/avatar-facade.service';
import { AvatarDealFacadeService } from '../core/services/avatar-deal/avatar-deal-facade.service';
import { Avatar2CollectionApiService } from '../core/services/avatar/avatar-2-collection-api.service';
import { AvatarBuilderService } from '../core/services/avatar/avatar-builder.service';
import { UploadImageService } from '../core/services/upload-image.service';
import { RolesEnum } from '../core/enums/RolesEnum';
import { ProductTypes } from '../core/enums/client-card/ProductTypes';
import { UtilsHelper } from '../core/utils/utils.helper';
import { ImageUtil } from '../core/utils/image.util';
import { AvatarBuilderData } from '../core/models/avatar-models/AvatarBuilderData';
import { AvatarElementTypes } from '../core/enums/avatar/AvatarElementTypes';
import { ClientCardFacadeService } from '../core/services/client-card/client-card-facade.service';
import { QrCodeModalComponent } from '../shared/custom/custom-qr-code-modal/custom-qr-code-modal.component';
import { CustomLinkModalComponent } from '../shared/custom/custom-link-modal/custom-link-modal.component';
import { CustomClientCardModalComponent } from '../shared/custom/custom-client-card-modal/custom-client-card-modal.component';
import { User } from '../core/models/UserModel';
import { UserContextService } from '../core/services/user-context.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'pd-client-card',
  templateUrl: './client-card.component.html',
  styleUrls: ['./client-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientCardComponent extends BaseComponent implements OnInit {
  @ViewChild('clientExportCanvas') canvas: ElementRef;
  @ViewChild('fullscreenView') fullscreenView: ElementRef;
  @ViewChild('fullscreenImg') fullscreenImg: ElementRef;
  @ViewChild('stylesAvailablePanel') stylesAvailablePanel: AvatarExpansionPanelComponent;
  @ViewChild('customAvatarPanel') customAvatarPanel: AvatarExpansionPanelComponent;
  @ViewChild('avatarWardrobePanel') avatarWardrobePanel: AvatarExpansionPanelComponent;
  @ViewChild('avatarSeasonalProposalPanel') avatarSeasonalProposalPanel: AvatarExpansionPanelComponent;

  @Input() public dealId: number;
  @Input() public dealTitle: string;
  @Input() public genderId: number = 0;
  @Input() public isAsModal: boolean = false;

  @Output() public loadComplete: EventEmitter<Object> = new EventEmitter();

  ProductTypes = ProductTypes;
  DealUtil = DealUtil;

  isExportAction: boolean = false;
  isClientHistory: boolean = false;
  isSpinner: boolean = true;
  isLoadInProgress: boolean = true;
  clientCards: Array<ClientCard>;
  editedCard: ClientCard;
  typeOfSuits: Array<TypeOfSuit>;
  fabricConfigs: Array<FabricConfig>;
  customLink: CustomLink;
  allowedAvatarTypesForProductTypes: AllowedAvatarTypesForProductType[];
  allowedAvatarTypesForProductStyles: AllowedAvatarTypesForProductStyle[];
  collectionsViewConfig: AvatarCollectionsViewConfig;
  stylesAvailableAvatars: Avatar[];
  seasonalProposals: Avatar[];
  avatars: Avatar[];
  avatarsCustom: Avatar[];
  avatarCollections: AvatarCollection[];

  fabricImages: Array<FabricImage>;
  productTypes: Array<ProductType>;
  lines: Array<CardLine>;

  selectedProducts = new Array<ProductType>();

  stylesAvailableProductType: ProductType = new ProductType();
  customAvatarProductType: ProductType = new ProductType();
  avatarWardrobeProductType: ProductType = new ProductType();
  seasonalProposalProductType: ProductType = new ProductType();
  wardrobeProductType: ProductType = new ProductType();

  isOpenSelect = false;
  isSelectAll = false;

  isLoadOnStart = true;

  currentUser: User;
  isMasterLeadAccount = false;
  isAdminAccount = false;
  isClothierAccount = false;
  isEaAccount = false;
  isEaCallerAccount = false;
  isAvatarEditor = false;

  constructor(
    private userContextService: UserContextService
    , private cdRef: ChangeDetectorRef
    , private avatarApiService: AvatarApiService
    , private avatarFacadeService: AvatarFacadeService
    , private avatarDealFacadeService: AvatarDealFacadeService
    , private avatar2CollectionApiService: Avatar2CollectionApiService
    , private avatarBuilderService: AvatarBuilderService
    , private uploadImageService: UploadImageService
    , private clientCardFacadeService: ClientCardFacadeService
    , private dialog: MatDialog,
  ) {
    super();
  }

  async ngOnInit() {
    this.clientCardFacadeService.getClientCards()
      .pipe(takeUntil(this.destroy$))
      .subscribe((clientCards: ClientCard[]) => {
        this.clientCards = clientCards;
        this.cdRef.detectChanges();
      });

    this.avatarDealFacadeService.getSeasonalProposalAvatars()
      .pipe(takeUntil(this.destroy$))
      .subscribe((seasonalProposals: Avatar[]) => {
        this.seasonalProposals = seasonalProposals;
        this.cdRef.detectChanges();
      });

    this.avatarDealFacadeService.getStylesAvailableAvatars()
      .pipe(takeUntil(this.destroy$))
      .subscribe((stylesAvailableAvatars: Avatar[]) => {
        this.stylesAvailableAvatars = stylesAvailableAvatars;
        this.cdRef.detectChanges();
      });

    this.avatarDealFacadeService.getAvatars()
      .pipe(takeUntil(this.destroy$))
      .subscribe((avatars: Avatar[]) => {
        this.avatars = avatars;
        this.cdRef.detectChanges();
      });

    this.avatarDealFacadeService.getCustomAvatars()
      .pipe(takeUntil(this.destroy$))
      .subscribe((avatarsCustom: Avatar[]) => {
        this.avatarsCustom = avatarsCustom;
        this.cdRef.detectChanges();
      });

    this.initializeStore();
    if (this.isLoadOnStart) {
      await this.load();
    }

    if (this.isMasterLeadAccount) {
      this.avatarCollections = await this.avatarFacadeService.loadAvatarCollections();
    }

    this.isLoadInProgress = false;
    this.setSpinner(false);
  }

  private initializeStore() {
    this.currentUser = this.userContextService.user.value;
    this.isMasterLeadAccount = this.currentUser && this.currentUser.role_id === RolesEnum.MasterLead;
    this.isEaAccount = this.currentUser && this.currentUser.role_id === RolesEnum.EA;
    this.isEaCallerAccount = this.currentUser && this.currentUser.role_id === RolesEnum.EaCaller;
    this.isAdminAccount = this.currentUser && this.currentUser.role_id === RolesEnum.Admin;
    this.isClothierAccount = this.currentUser && this.currentUser.role_id === RolesEnum.Clothier;
    this.isAvatarEditor = this.currentUser && this.currentUser.isAvatarEditor;
  }

  ngOnDestroy() {
    this.cdRef.detach();
    super.ngOnDestroy();
  }

  public isShowExpansion(productType: ProductType) {
    if (this.isExportAction && productType) {
      return this.selectedProducts.indexOf(productType) != -1;
    }
    return true;
  }

  public getProductTypes() {
    if (this.isExportAction) {
      return this.productTypes.filter(c => this.selectedProducts.indexOf(c) != -1)
    }
    return this.productTypes;
  }

  async load() {
    this.setSpinner(true);
    if (this.dealId) {
      this.wardrobeProductType.id = ProductTypes.Wardrobe;
      this.wardrobeProductType.name = 'Wardrobe';

      this.seasonalProposalProductType.id = ProductTypes.SeasonalProposal;
      this.seasonalProposalProductType.name = 'Seasonal proposals';

      this.avatarWardrobeProductType.id = ProductTypes.AvatarWardrobe;
      this.avatarWardrobeProductType.name = 'Avatar Wardrobe';

      this.customAvatarProductType.id = ProductTypes.CustomAvatar;
      this.customAvatarProductType.name = 'Create your own style';

      this.stylesAvailableProductType.id = ProductTypes.StylesAvailable;
      this.stylesAvailableProductType.name = `Styles Available in LGFG's Current Collection`;

      this.productTypes = new Array<ProductType>();
      this.lines = new Array<CardLine>();
      this.selectedProducts = new Array<ProductType>();

      const clientCardConfig = await this.clientCardFacadeService.getCardConfig(this.dealId);
      if (!clientCardConfig)
        return;

      this.typeOfSuits = clientCardConfig.typeOfSuits;
      this.fabricConfigs = clientCardConfig.fabricConfigs;
      this.fabricImages = clientCardConfig.fabricImages;
      this.customLink = clientCardConfig.customLink;
      this.allowedAvatarTypesForProductTypes = clientCardConfig.allowedAvatarTypesForProductTypes;
      this.allowedAvatarTypesForProductStyles = clientCardConfig.allowedAvatarTypesForProductStyles;
      this.collectionsViewConfig = clientCardConfig.collectionsViewConfig;

      await this.clientCardFacadeService.loadClientCardsByDealId(this.dealId);

      if (!this.isFemale) {
        await this.avatarDealFacadeService.loadAvatarsByDealId(this.dealId);
        await this.avatarDealFacadeService.loadSeasonalProposalAvatarsByDealId(this.dealId);
        await this.avatarDealFacadeService.loadStylesAvailableAvatarsByDealId(this.dealId);
        if (this.isAllowCustomAvatars) {
          await this.avatarDealFacadeService.loadCustomAvatarsByDealId(this.dealId);
        }
      }

      this.productTypes = clientCardConfig.productTypes.sort((a, b) => a.orderNumber - b.orderNumber);
      this.lines = clientCardConfig.lines;
      this.loadComplete.emit();
    }
    this.setSpinner(false);
  }

  async export() {
    let dealTitle = this.dealTitle;
    this.isExportAction = true; // prepare html elements and styles to rendering img
    this.setSpinner(true);
    document.querySelector('.wrapper').classList.add('client-card-export');
    document.querySelector('.wrapper').classList.remove('wrapper'); // reset all wrappers and set 2500px for all resolution

    if (!this.isAllowAvatars) {
      const idx = this.selectedProducts.findIndex(arrItem => arrItem.id == this.avatarWardrobeProductType.id)
      if (idx != -1) {
        this.selectedProducts.splice(idx, 1);
      }
    }

    if (!this.isAllowSeasonalProposals) {
      const idx = this.selectedProducts.findIndex(arrItem => arrItem.id == this.seasonalProposalProductType.id)
      if (idx != -1) {
        this.selectedProducts.splice(idx, 1);
      }
    }

    if (!this.isAllowStylesAvailableAvatars) {
      const idx = this.selectedProducts.findIndex(arrItem => arrItem.id == this.stylesAvailableProductType.id)
      if (idx != -1) {
        this.selectedProducts.splice(idx, 1);
      }
    }

    if (!this.isAllowCustomAvatars) {
      const idx = this.selectedProducts.findIndex(arrItem => arrItem.id == this.customAvatarProductType.id)
      if (idx != -1) {
        this.selectedProducts.splice(idx, 1);
      }
    }

    if (this.customAvatarPanel && this.selectedProducts.findIndex(arrItem => arrItem.id == this.customAvatarProductType.id) != -1) {
      await this.customAvatarPanel.loadAllAvatars();
    }

    if (this.avatarWardrobePanel && this.selectedProducts.findIndex(arrItem => arrItem.id == this.avatarWardrobeProductType.id) != -1) {
      await this.avatarWardrobePanel.loadAllAvatars();
    }

    if (this.avatarSeasonalProposalPanel && this.selectedProducts.findIndex(arrItem => arrItem.id == this.seasonalProposalProductType.id) != -1) {
      await this.avatarSeasonalProposalPanel.loadAllAvatars();
    }

    if (this.stylesAvailablePanel && this.selectedProducts.findIndex(arrItem => arrItem.id == this.stylesAvailableProductType.id) != -1) {
      await this.stylesAvailablePanel.loadAllAvatars();
    }
    
    setTimeout(async () => { // delay for applying *ngIf
      let height = this.canvas.nativeElement.offsetHeight; // get height of canvas
      let width = 2500; // get width of canvas
    
      document.getElementById('export-client-card-canvas').setAttribute('style', 
        'height: ' + height + 'px !important; ' +
        'position: absolute; ' +
        'width: ' + width + 'px !important; ' +
        'overflow: hidden !important;'
      );
    
      const canvas = await html2canvas(this.canvas.nativeElement, { // rendering img from html container
        allowTaint: false,
        useCORS: true
      });
    
      canvas.toBlob(function (blob) { // convert and download as img
        let link = document.createElement('a');
        link.download = 'Client Card of ' + dealTitle + '.png';
        link.href = URL.createObjectURL(blob);
        link.click();
      }, 'image/png');
    
      this.isOpenSelect = false;
    
      this.selectedProducts = new Array<ProductType>();
      document.querySelector('.client-card-export').classList.add('wrapper');
      document.querySelector('.client-card-export').classList.remove('client-card-export'); // reset wrappers
      document.getElementById('export-client-card-canvas').removeAttribute('style'); // reset body
      
      this.setSpinner(false);
      this.isExportAction = false;
    }, 3000);
  }

  public openSelect() {
    this.isOpenSelect = !this.isOpenSelect;
  }

  public closeCustomSelect() {
    this.isOpenSelect = false;
  }

  public selectItem(item: ProductType, event: any) {
    this.isOpenSelect = true
    if (!this.selectedProducts) {
      this.selectedProducts = new Array<ProductType>();
    }

    if (event.checked) {
      this.selectedProducts.push(item);
    }
    else {
      this.isSelectAll = false;
      let idx = this.selectedProducts.findIndex(arrItem => arrItem.id == item.id)
      if (idx != -1) {
        this.selectedProducts.splice(idx, 1);
      }
    }
    this.cdRef.detectChanges();
  }

  public selectAllData() {
    this.isSelectAll = true;
    this.selectedProducts = new Array<ProductType>();
    this.productTypes.forEach(productType => {
      this.selectedProducts.push(productType)
    });
    this.selectedProducts.push(this.wardrobeProductType);

    if (this.isAllowAvatars) {
      this.selectedProducts.push(this.avatarWardrobeProductType);
    }

    if (this.isAllowSeasonalProposals) {
      this.selectedProducts.push(this.seasonalProposalProductType);
    }

    if (this.isAllowStylesAvailableAvatars) {
      this.selectedProducts.push(this.stylesAvailableProductType);
    }

    if (this.isAllowCustomAvatars) {
      this.selectedProducts.push(this.customAvatarProductType);
    }
  }

  public deselectAllData() {
    this.isSelectAll = false;
    this.selectedProducts = new Array<ProductType>();
  }

  public checkItem(id: number) {
    return this.selectedProducts.some(i => (i.id === id));
  }

  public async generateQrCode() {
    this.dialog.open(QrCodeModalComponent, { data: { dealId: this.dealId, title: this.dealTitle, isClientCard: true } });
  }

  public openCustomLinkModal() {
    const data = {
      label: this.customLink?.label,
      link: this.customLink?.link,
      customLinkChange: this.onCustomLinkChange.bind(this),
    }
    this.dialog.open(CustomLinkModalComponent, { maxWidth: "100vw",data: data });
  }

  public async onCustomLinkChange(event) {
    this.setSpinner(true);
    await this.clientCardFacadeService.updateCustomLink(this.dealId, event.label, event.link);
    this.customLink = new CustomLink();
    this.customLink.dealId = this.dealId;
    this.customLink.label = event.label;
    this.customLink.link = event.link;
    this.setSpinner(false);
  }

  public openModal(card: ClientCard) {
    if (this.isClientHistory) {
      return;
    }

    this.dialog.open(CustomClientCardModalComponent, {
      disableClose: true,
      data: {
        dealId: this.dealId
        , card: card
        , lines: this.lines
        , fabricConfigs: this.fabricConfigs
        , typeOfSuits: this.typeOfSuits
        , fabricImages: this.fabricImages
        , saveClientCard: this.saveCard.bind(this)
        , deleteClientCard: this.deleteCard.bind(this)
      }
    });
  }

  public async saveCard(event) {
    this.setSpinner(true);
    const card = event.card as ClientCard;
    const isFabricUpdated = event.isFabricUpdated as boolean;

    if (card.id > 0) {
      await this.clientCardFacadeService.updateClientCard(card, isFabricUpdated);
    }
    else {
      await this.clientCardFacadeService.createClientCard(card, isFabricUpdated);
    }

    if (isFabricUpdated) {
      const url = ImageUtil.fabricToUrl(card.fabricImgTitle);
      if (url !== null) {
        card.fabricImgUrl = ImageUtil.addVersionToUrl(url);
      }
    }

    this.setSpinner(false);
  }

  public async deleteCard(card: ClientCard) {
    this.setSpinner(true);
    await this.clientCardFacadeService.deleteClientCard(card.id);
    this.setSpinner(false);
  }

  public async openAvatarSettings() {
    this.setSpinner(true);
    const avatarBuilderData = await this.getDealSettingsAvatarBuilderData();

    this.dialog.open(AvatarDealSettingsComponent, {
      disableClose: true,
      maxWidth: "90vw",
      minWidth: "1050px",
      data: {
        avatarBuilderData: avatarBuilderData,
        isSetting: true,
        isCustom: false,
        fabricImages: this.fabricImages,
        onSave: this.avatarModalSave.bind(this),
      }
    });

    this.setSpinner(false);
  }

  public async clickAvatar(event) {
    if (event.isGlobalCollection || (!this.isAllowAddEditAvatars && !event.isCustomAvatars)) {
      this.openFullscreen(event.avatar);
      return;
    }

    this.setSpinner(true);
    const avatar = event.avatar as Avatar;
    const avatarId = avatar.id;
    const avatarBuilderData = (avatarId) ? await this.getAvatarBuilderData(avatarId) : await this.getDealSettingsAvatarBuilderData();

    if (event.isCustomAvatars) {
      this.dialog.open(AvatarDealSettingsComponent, {
        disableClose: true,
        maxWidth: "90vw",
        minWidth: "1050px",
        data: {
          avatarBuilderData: avatarBuilderData,
          avatarId: avatarId,
          isSetting: false,
          isCustom: true,
          fabricImages: this.fabricImages,
          onSave: this.avatarCustomModalSave.bind(this),
        }
      });
    }
    else {
      const card = this.clientCards.find(f => f.id == avatar.clientCardId);
      const allowedAvatarTypesForProductType = this.allowedAvatarTypesForProductTypes.find(f => f.productTypeId == card.productTypeId);
      const allowedAvatarTypesForProductStyle = this.allowedAvatarTypesForProductStyles.find(f => f.productStyleId == card.fabricConfigId);

      this.dialog.open(AvatarDealSettingsComponent, {
        disableClose: true,
        maxWidth: "90vw",
        minWidth: "1050px",
        data: {
          avatarBuilderData: avatarBuilderData,
          avatarId: avatarId,
          allowedAvatarTypesForProductType: allowedAvatarTypesForProductType,
          allowedAvatarTypesForProductStyle: allowedAvatarTypesForProductStyle,
          isSetting: false,
          isCustom: false,
          fabricImages: this.fabricImages,
          onSave: this.avatarModalSave.bind(this),
        }
      });
    }

    this.setSpinner(false);
  }

  public async addCustomAvatar() {
    if (!this.isAllowAddEditAvatars && !this.isAllowCustomAvatars)
      return;

    this.setSpinner(true);
    const avatarBuilderData = await this.getDealSettingsAvatarBuilderData();
    this.dialog.open(AvatarDealSettingsComponent, {
      disableClose: true,
      maxWidth: "90vw",
      minWidth: "1050px",
      data: {
        avatarBuilderData: avatarBuilderData,
        isSetting: false,
        isCustom: true,
        fabricImages: this.fabricImages,
        onSave: this.avatarCustomModalSave.bind(this),
      }
    });

    this.setSpinner(false);
  }

  public async avatarCustomModalSave(event) {
    this.setSpinner(true);

    const avatarBuilderData = event.avatarBuilderData as AvatarBuilderData;

    if (event.avatarId) {
      if (event.isAvatarChanged) {
        await this.avatarDealFacadeService.updateCustomAvatar(event.avatarId, avatarBuilderData);
      }
    }
    else {
      await this.avatarDealFacadeService.createCustomAvatar(this.dealId, avatarBuilderData);
    }

    if (this.stylesAvailablePanel) {
      await this.stylesAvailablePanel.loadAvatarsBase64IfNeed();
    }

    if (this.customAvatarPanel) {
      await this.customAvatarPanel.loadAvatarsBase64IfNeed();
    }

    this.setSpinner(false);
  }

  public async avatarModalSave(event) { //TODO: update avatar based on updated parts
    this.setSpinner(true);
    const avatarBuilderData = event.avatarBuilderData as AvatarBuilderData;

    const head = avatarBuilderData.options.find(f => f.elementTypeId == AvatarElementTypes.Head);
    if (head && ImageUtil.isBase64(head.imageLink)) {
      head.imageLink = await this.uploadImageService.uploadAvatarHeadImage(this.dealId, head.imageLink);
    }

    if (event.avatarId) {
      if (event.isAvatarChanged) {
        await this.avatarDealFacadeService.updateAvatar(event.avatarId, avatarBuilderData);
      }
    }
    else {
      await this.avatarDealFacadeService.updateAvatarDealSettings(this.dealId, avatarBuilderData);
      await this.clientCardFacadeService.generateDealAvatars(this.dealId, null);
    }

    await this.avatarDealFacadeService.loadAvatarsByDealId(this.dealId);
    await this.avatarDealFacadeService.loadSeasonalProposalAvatarsByDealId(this.dealId);
    await this.avatarDealFacadeService.loadStylesAvailableAvatarsByDealId(this.dealId);
    await this.avatarDealFacadeService.loadCustomAvatarsByDealId(this.dealId);

    if (this.avatarWardrobePanel) {
      await this.avatarWardrobePanel.loadAvatarsBase64IfNeed();
    }

    if (this.avatarSeasonalProposalPanel) {
      await this.avatarSeasonalProposalPanel.loadAvatarsBase64IfNeed();
    }

    if (this.stylesAvailablePanel) {
      await this.stylesAvailablePanel.loadAvatarsBase64IfNeed();
    }

    if (this.customAvatarPanel) {
      await this.customAvatarPanel.loadAvatarsBase64IfNeed();
    }

    this.setSpinner(false);
  }

  public async addAvatarToCollection(event: any) {
    this.setSpinner(true);

    const avatar = event.avatar as Avatar;
    const avatarId = avatar.id;
    const avatarBuilderData = await this.getAvatarBuilderData(avatarId);

    this.dialog.open(AvatarBuilderComponent, {
      disableClose: true,
      maxWidth: "90vw",
      minWidth: "1050px",
      data: {
        avatarBuilderData: avatarBuilderData,
        originalAvatarId: avatarId,
        collections: [],
        avatarCollections: this.avatarCollections,
        fabricImages: this.fabricImages,
        onSave: this.addAvatarToCollectionSave.bind(this),
      }
    });

    this.setSpinner(false);
  }

  public async addAvatarToCollectionSave(event) {
    this.setSpinner(true);

    const avatarBuilderData = event.avatarBuilderData as AvatarBuilderData;
    const collections = event.collections as AvatarCollection[];
    const head = avatarBuilderData.options.find(f => f.elementTypeId == AvatarElementTypes.Head);
    const isAddedToSeasonalProposals = collections && collections.some(c => c.isSeasonalProposal);
    const isAddedToStylesAvailable = collections && collections.some(c => c.isStylesAvailable);

    if (head && ImageUtil.isBase64(head.imageLink)) {
      head.imageLink = await this.uploadImageService.uploadAvatarHeadImage(this.dealId, head.imageLink);
    }

    const avatarId = await this.avatarApiService.createAvatarDuplicate(event.originalAvatarId).toPromise();
    await this.avatarApiService.updateAvatar(avatarId, avatarBuilderData).toPromise();

    for (let i = 0; i < collections.length; i++) {
      await this.avatar2CollectionApiService.addAvatarToCollection(avatarId, collections[i].id).toPromise();
    }

    if (isAddedToSeasonalProposals) {
      const avatar = await this.avatarApiService.getAvatar(avatarId).toPromise();
      this.avatarDealFacadeService.addToSeasonalProposalAvatars(avatar);
    }

    if (isAddedToStylesAvailable) {
      const avatar = await this.avatarApiService.getAvatar(avatarId).toPromise();
      this.avatarDealFacadeService.addToStylesAvailableAvatars(avatar);
    }

    this.setSpinner(false);
  }

  public async deleteAvatar(event) {
    this.setSpinner(true);

    if (event.isCustomAvatars) {
      await this.avatarDealFacadeService.deleteCustomAvatar(event.avatarId);
    }
    else {
      await this.avatarDealFacadeService.deleteAvatar(event.avatarId);
    }

    this.setSpinner(false);
  }

  public async generateDealAvatars(event) {
    this.setSpinner(true);
    await this.clientCardFacadeService.generateDealAvatars(this.dealId, event.productTypeId);
    await this.avatarDealFacadeService.loadAvatarsByDealId(this.dealId);
    if (this.avatarWardrobePanel) {
      await this.avatarWardrobePanel.loadAvatarsBase64IfNeed();
    }
    this.setSpinner(false);
  }

  public get isAllowAddEditAvatars(): boolean {
    return this.isMasterLeadAccount || this.isEaAccount || this.isAvatarEditor;
  }

  public get isAllowAddEditCustomAvatars(): boolean {
    return this.isMasterLeadAccount || this.isAdminAccount || this.isClothierAccount;
  }

  public get isFemale(): boolean {
    return this.genderId == 27;
  }

  public get isAllowAvatars(): boolean {
    return !this.isFemale && this.avatars && this.avatars.length > 0
  }

  public get isAllowSeasonalProposals(): boolean {
    return !this.isFemale && this.seasonalProposals && this.seasonalProposals.length > 0
  }

  public get isAllowStylesAvailableAvatars(): boolean {
    return !this.isFemale && this.stylesAvailableAvatars && this.stylesAvailableAvatars.length > 0
  }

  public get isAllowCustomAvatars(): boolean {
    return !this.isFemale && !this.isEaAccount
  }

  public async downloadAvatar(event) {
    this.setSpinner(true);
    const base64 = await this.avatarDealFacadeService.downloadDealAvatar(this.dealId, event.avatarId);
    const byteArray = UtilsHelper.base64ToArrayBuffer(base64);
    const blob: any = new Blob([byteArray], { type: 'image/png' });
    saveAs(blob, 'Avatar.png', true);
    this.setSpinner(false);
  }

  public async downloadAllAvatars(productType: ProductType) {
    this.setSpinner(true);

    let fileName = null;
    let result = null;

    switch (productType.id) {
      case ProductTypes.StylesAvailable:
        fileName = "Styles Available in LGFG's Current Collection";
        result = await this.avatarDealFacadeService.downloadStylesAvailableAvatarsByDealId(this.dealId)
        break;
      case ProductTypes.CustomAvatar:
        fileName = "Create your own style";
        result = await this.avatarDealFacadeService.downloadAllCustomAvatarsByDealId(this.dealId);
        break;
      case ProductTypes.SeasonalProposal:
        fileName = "Seasonal Proposals";
        result = await this.avatarDealFacadeService.downloadSeasonalProposalAvatarsByDealId(this.dealId)
        break;
      default:
        fileName = "Avatar Wardrobe";
        result = await this.avatarDealFacadeService.downloadAllClientCardAvatarsByDealId(this.dealId);
    }

    saveAs(result, fileName + ".zip");
    this.setSpinner(false);
  }

  getCardCount() {
    if (this.clientCards) {
      let cards = this.clientCards;
      if (cards) {
        return cards.length;
      }
    }
    return 0;
  }

  private async getDealSettingsAvatarBuilderData() {
    return await this.avatarDealFacadeService.loadAvatarDealSettingsBuilder(this.dealId);
  }

  private async getAvatarBuilderData(avatarId: number) {
    return (avatarId) ? await this.avatarBuilderService.getAvatarBuilderData(avatarId) : await this.avatarBuilderService.getAvatarBuilderDataDef();
  }

  public openFullscreen(avatar: Avatar) {
    const fullscreen = this.fullscreenView.nativeElement;
    const fullscreenImg = this.fullscreenImg.nativeElement;
    fullscreenImg.src = 'data:image/png;base64,' + avatar.base64;
    fullscreen.setAttribute('style', `display: block`);
  }

  public closeFullscreen() {
    const fullscreen = this.fullscreenView.nativeElement;
    fullscreen.setAttribute('style', `display: none`);
  }

  public onClientHistoryChange(event: MatSlideToggleChange) {
    this.isClientHistory = event.checked;
    this.detectChanges();
  }

  public detectChanges() {
    this.cdRef.detectChanges();
  }

  private setSpinner(value: boolean) {
    this.isSpinner = value;
    this.detectChanges();
  }
}