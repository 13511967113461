export enum ProductTypes {
    StylesAvailable = -6,
    CustomAvatar = -5,
    AvatarWardrobe = -4,
    SeasonalProposal = -3,
    Wardrobe = -2,
    Suits = 1,
    Blazers,
    Shirts,
    Pants,
    Shoes,
    Outerwear,
    CasualWear,
    Accessories,
    Denim,
    SmartCasual,
}
