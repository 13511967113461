import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { CustomSelectFilterWithoutLazyComponent } from '../shared/custom/custom-select-without-lazy/custom-select-without-lazy';
import { RolesEnum } from '../core/enums/RolesEnum';
import { UserRequestModel } from '../core/models/UserRequestModel';
import { UserRequestService } from '../core/services/user-request.service';
import { User } from '../core/models/UserModel';
import { UserContextService } from '../core/services/user-context.service';
import { RequestStatus } from '../core/enums/RequestStatus';
import { EnumUtil } from '../core/utils/enum.util';

@Component({
  selector: 'pd-user-request-panel',
  templateUrl: './user-request-panel.component.html',
  styleUrls: ['./user-request-panel.component.scss']
})
export class UserRequestPanelComponent implements OnInit {
  @ViewChild('fileInput') fileInput: ElementRef;

  @ViewChild("statusFilter") customSelectStatus: CustomSelectFilterWithoutLazyComponent;
  @ViewChild("userFilter") customSelectUser: CustomSelectFilterWithoutLazyComponent;

  statusLabel: string = 'Status'
  usersLabel: string = 'Made Request User'

  filteredStatusMultiSelect: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  filteredStatus = [];
  selectedRequestStatusList: any[];
  requestStatusList: any[];

  filteredUserMultiSelect: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  filteredUsers = [];
  selectedRequestUsersList: any[];
  requestUsersList: any[];

  currentUser: User;
  RolesEnum = RolesEnum;
  EnumUtil = EnumUtil;
  isRequestsManager: boolean = false;
  spinner: boolean = false;
  allUserRequests: Array<UserRequestModel>;
  filteredUserRequests: Array<UserRequestModel>;
  selectedRequest: UserRequestModel;
  uploadNearByImportFile: File;

  isFirstRequest: boolean = true;

  constructor(    
    private userContextService: UserContextService
    , private userRequestService: UserRequestService) {
  }

  ngOnInit() {
    this.initializeStore();
  }

  ngAfterViewInit() {
    this.loadData();
  }

  loadData() {
    this.getUserRequests();
  }

  filterData() {
    if (this.allUserRequests) {
      let filteredUserRequests = this.allUserRequests;

      if (this.customSelectStatus) {
        this.selectedRequestStatusList = this.customSelectStatus.dataArray;
        filteredUserRequests = filteredUserRequests
          .filter(r => this.selectedRequestStatusList.findIndex(u => u.id === r.statusId) != -1)
      }

      if (this.customSelectUser) {
        this.selectedRequestUsersList = this.customSelectUser.dataArray;
        filteredUserRequests = filteredUserRequests
          .filter(r => this.selectedRequestUsersList.findIndex(u => u.id === r.madeRequestUser) != -1);
      }

      this.filteredUserRequests = filteredUserRequests;
    }
  }

  private initializeStore() {
    this.currentUser = this.userContextService.user.value;
    this.isRequestsManager = (this.currentUser.role_id === RolesEnum.Admin || this.currentUser.role_id === RolesEnum.MasterLead)
  }

  private getUserRequests() {
    this.spinner = true;
    let filterUserId = this.currentUser.id
    if (this.isRequestsManager) {
      filterUserId = null;
    }

    this.userRequestService.getUserRequests(filterUserId).then(response => {
      this.allUserRequests = response;
      this.getRequestFilteringData();
      this.getStatusFilterData();
      this.getUsersFilterData();
      this.isFirstRequest = false;
      this.spinner = false;
    });
  }

  parseNearbyImportDealsRequest(requestInfoJson) {
    var obj = JSON.parse(requestInfoJson);
    return "<div><b>Company: </b>" + obj.CompanyName + "</div>"
      + "<div><b>Address: </b>" + obj.Address + "</div>"
      + "<div><b>Geocode: </b>" + obj.Geocode.split(',')[0] + ", " + obj.Geocode.split(',')[1] + "</div>"
  }

  selectRequest(request: UserRequestModel) {
    this.selectedRequest = request;
  }

  openInput() {
    this.fileInput.nativeElement.click();
  }

  changeListener(files: FileList) {
    if (files && files.length > 0) {
      var splitArray = files[0].name.split('.');
      var fileType = splitArray[splitArray.length - 1];
      if (fileType && fileType.toLowerCase() !== "csv") {
        alert("Wrong file format. Please upload CSV file.");
        return;
      }
      this.uploadNearByImportFile = files[0];
    }
    this.uploadNearbyImport();
  }

  resetFileUpload() {
    this.fileInput.nativeElement.value = "";
    this.uploadNearByImportFile = null;
  }

  uploadNearbyImport() {
    this.userRequestService.uploadNearbyImport(this.uploadNearByImportFile, this.selectedRequest.id).then(() => { this.loadData(); this.resetFileUpload() });
  }

  getRequestFilteringData() {
    if (this.allUserRequests) {
      this.requestStatusList = this.allUserRequests.map(ur => ({ id: ur.statusId, name: EnumUtil.getRequestStatusViewLabel(ur.statusId) }))
        .reduce((arr, c) => { if (!arr.some(x => x.id === c.id)) { arr.push(c) } return arr; }, []) //distinct
        .sort((a, b) => a.id - b.id);

      this.requestUsersList = this.allUserRequests.map(ur => ({ id: ur.madeRequestUser, name: ur.madeRequestUserName }))
        .reduce((arr, c) => { if (!arr.some(x => x.id === c.id)) { arr.push(c) } return arr; }, []) //distinct
        .sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
    }
  }

  getStatusColor(userRequest: UserRequestModel) {
    let styles = [];
    if (userRequest && userRequest.statusId) {
      switch (userRequest.statusId) {
        case RequestStatus.Failed: styles['background-color'] = "red"; break;
        case RequestStatus.Queued: styles['background-color'] = "yellow"; break;
        case RequestStatus.InProgress: styles['background-color'] = "orange"; break;
        case RequestStatus.Completed: styles['background-color'] = "lawngreen"; break;
      }
    }
    return styles;
  }

  getStatusFilterData() {
    if (this.requestStatusList && this.customSelectStatus) {
      this.filteredStatus = [];
      this.filteredStatus = this.filteredStatus.concat(this.requestStatusList);
      this.filteredStatusMultiSelect.next(this.requestStatusList.slice());
      this.customSelectStatus.setData(this.filteredStatus);

      if (this.isFirstRequest) {
        this.customSelectStatus.selectAllData();
      }
    }
  }

  getStatusAutocomplete(event) {
    let search = event.searchString;
    if (!search) {
      this.filteredStatusMultiSelect.next(this.requestStatusList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredStatusMultiSelect.next(
      this.requestStatusList.filter(r => r.name.toLowerCase().indexOf(search) > -1)
    );
    this.filteredStatus = [];
    this.filteredStatusMultiSelect.subscribe(x => {
      for (let i = 0; i < x.length; i++) {
        if (this.filteredStatus.length == 0) {
          this.filteredStatus.push(x[0]);
        } else {
          if (!this.filteredStatus.includes(x[i], 0)) {
            this.filteredStatus.push(x[i]);
          }
        }
      }
    });
    this.customSelectStatus.setData(this.filteredStatus);
  }

  getUsersFilterData() {
    if (this.requestUsersList && this.customSelectUser) {
      this.filteredUsers = [];
      this.filteredUsers = this.filteredUsers.concat(this.requestUsersList);
      this.filteredUserMultiSelect.next(this.requestUsersList.slice());
      this.customSelectUser.setData(this.filteredUsers);

      if (this.isFirstRequest) {
        this.customSelectUser.selectAllData();
      }
    }
  }

  getUsersAutocomplete(event) {
    let search = event.searchString;
    if (!search) {
      this.filteredUserMultiSelect.next(this.requestUsersList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredUserMultiSelect.next(
      this.requestUsersList.filter(u => u.name.toLowerCase().indexOf(search) > -1)
    );
    this.filteredUsers = [];
    this.filteredUserMultiSelect.subscribe(x => {
      for (let i = 0; i < x.length; i++) {
        if (this.filteredUsers.length == 0) {
          this.filteredUsers.push(x[0]);
        } else {
          if (!this.filteredUsers.includes(x[i], 0)) {
            this.filteredUsers.push(x[i]);
          }
        }
      }
    });
    this.customSelectUser.setData(this.filteredUsers);
  }

}