import { Component } from '@angular/core';
import printJS from 'print-js'
import { Deal } from 'src/app/core/models/Deal';
import { HotlistService } from 'src/app/core/services/hotlist.service';

@Component({
  selector: 'pd-meeting-prep',
  templateUrl: './meeting-prep.component.html',
  styleUrls: ['./meeting-prep.component.scss']
})

export class MeetingPrepComponent {
  deal: Deal;
  isSpinner = false;
  constructor(private hotlistService: HotlistService) { }

  public async openMeetingPrepPdf(deal: Deal): Promise<void> {
    this.isSpinner = true;
    this.deal = deal;
    const result = await this.hotlistService.getMeetingPrepPdf(this.deal.id, this.deal.title);
    if (result) {
      printJS({ printable: result, type: 'pdf', base64: true })
    }
    this.isSpinner = false;
    this.deal = null;
  }
}
