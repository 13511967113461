import { UserContextService } from './../../core/services/user-context.service';
import { GlobalConstants } from './../../core/global-constants';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { User } from 'src/app/core/models/UserModel';
import * as importTypes from '../../../assets/import_types';
import { TasksService } from 'src/app/core/services/tasks.service';

@Component({
  selector: 'pd-import-deals-task',
  templateUrl: './import-deals-task.component.html',
  styleUrls: ['./import-deals-task.component.scss']
})
export class ImportDealsTaskComponent implements OnInit {
  public importForm: FormGroup;
  public importTypes = importTypes;

  selectedImportType: any;

  public dropboxAccessToken: string;
  public isGeneratingToken = false;

  public selectedFile = null;
  public isDropboxTokenGeneratedForUser = true;
  public uploadFileResult: any;
  isUploadingUnavailable = true;
  uploadFileExtension: any;

  isEnabledSubmitCodeForm: boolean;
  isWrongAccessToken: boolean;
  public currentUser: User;
  public currentUserId: number;
  public spinner: boolean = false;

  public tasksRoute = GlobalConstants.ROUTE_NAMES.Tasks;

  constructor(private taskService: TasksService, private userContextService: UserContextService) {
    this.importForm = new FormGroup({});
  }

  ngOnInit() {
    this.currentUser = this.userContextService.user.value;
    this.currentUserId = this.currentUser.id;
    this.selectedImportType = this.importTypes.IMPORT_TYPES_DICT[0]
  }

  public selectImportType(value) {
    this.selectedImportType = value;
  }

  public submitImport() {
    this.importForm.reset();
  }

  public onFileSelected(event) {
    this.uploadFileResult = null
    this.selectedFile = event.target.files[0]
    if (!this.selectedFile) {
      this.isUploadingUnavailable = true
      return
    }
    let splittedFileName = this.selectedFile.name.split('.')
    this.uploadFileExtension = splittedFileName[splittedFileName.length - 1]
    this.dropboxAccessToken = null;
    if (this.uploadFileExtension !== 'csv') {
      this.isGeneratingToken = false
    }
    this.isUploadingUnavailable = !this.selectedFile || this.uploadFileExtension !== 'csv'
  }

  public onTokenChanged(event) {
    this.dropboxAccessToken = event.target.value
  }

  public onUploadFile() {
    this.taskService.getIsGeneratedDropboxKey(this.currentUserId).then(r => {
      this.isDropboxTokenGeneratedForUser = r
      if (!this.isDropboxTokenGeneratedForUser) {
        this.isGeneratingToken = true;
        this.isUploadingUnavailable = !this.selectedFile || this.uploadFileExtension !== 'csv'
        if (!this.dropboxAccessToken) {
          return;
        }
        this.taskService.generateAccessToken(this.currentUserId, this.dropboxAccessToken).then(r => {
          this.spinner = true
          this.isWrongAccessToken = !r;
          if (r) {
            this.isGeneratingToken = false;
            this.taskService.uploadFileToDropbox(this.selectedFile, this.selectedImportType.name, this.currentUserId).then(uploadResult => {
              this.uploadFileResult = uploadResult
              this.spinner = false
              this.selectedFile = null;
              this.selectedImportType = this.importTypes.IMPORT_TYPES_DICT[0]
              this.isUploadingUnavailable = true;
              this.importForm.reset();
            });
          }
          else {
            this.spinner = false;
            return;
          }
        });
      }
      else {
        this.spinner = true
        this.taskService.uploadFileToDropbox(this.selectedFile, this.selectedImportType.name, this.currentUserId).then(uploadResult => {
          this.uploadFileResult = uploadResult
          this.spinner = false
          this.selectedFile = null;
          this.selectedImportType = this.importTypes.IMPORT_TYPES_DICT[0]
          this.isUploadingUnavailable = true;
          this.importForm.reset();
        });
      }
    });
  }

  public goToLink(url: string) {
    window.open(url, "_blank");
    this.isEnabledSubmitCodeForm = true;
  }
}
