import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'pd-field-gender',
  templateUrl: './field-gender.component.html',
  styleUrls: ['./field-gender.component.scss']
})
export class FieldGenderComponent implements OnInit {

  public genderId;
  public isEdit = false;
  @Output() genderChange = new EventEmitter<Object>();

  public genderList = [{ key: 0, value: 'All' }, { key: 26, value: 'Male' }, { key: 27, value: 'Female' }];

  constructor() { }

  ngOnInit() {
  }

  /**
    * Add value
    * open edit
    * @memberof FieldGenderComponent
    */

  public addValue() {
    this.isEdit = true;
  }

  /**
    * Cancel edit
    * @memberof FieldGenderComponent
    */

  public cancelEdit() {
    this.isEdit = false;
  }

  /**
    * Go to edit
    * open edit
    * @memberof FieldGenderComponent
    */

  public goToEdit() {
    this.isEdit = true;
  }

  /**
    * Change value
    * @param {any} value value of gender
    * @memberof FieldGenderComponent
    */

  public changeValue(value) {
    this.genderId = value;
    this.isEdit = false;
    this.genderChange.emit(this.genderId);
  }

}
