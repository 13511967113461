import { AvatarTypes } from "../enums/avatar/AvatarTypes";
import { ProductStyles } from "../enums/client-card/ProductStyles";
import { ProductTypes } from "../enums/client-card/ProductTypes";

export class ClientCardConfig {
  public productTypes: Array<ProductType>;
  public lines: Array<CardLine>;
  public typeOfSuits: Array<TypeOfSuit>;
  public fabricImages: Array<FabricImage>;
  public fabricConfigs: Array<FabricConfig>;
  public customLink: CustomLink;
  public allowedAvatarTypesForProductTypes: Array<AllowedAvatarTypesForProductType>;
  public allowedAvatarTypesForProductStyles: Array<AllowedAvatarTypesForProductStyle>;
  public collectionsViewConfig: AvatarCollectionsViewConfig;
}

export class TypeOfSuit {
  public id: number;
  public name: string;
}

export class ProductType {
  public id: ProductTypes;
  public name: string;
  public orderNumber: number;
}

export class CardLine {
  public lineId: number;
  public name: string;
}

export class FabricConfig {
  public id: number;
  public title: string;
  public productTypeId: ProductTypes;
  public order: number;
  public count: number;
}

export class FabricImage {
  public id: number;
  public title: string;
  public fileName: string;
}

export class CustomLink {
  public dealId: number;
  public label: string;
  public link: string;
}

export class AllowedAvatarTypesForProductType {
  public productTypeId: ProductTypes;
  public allowedAvatarTypes: AvatarTypes[];
}

export class AllowedAvatarTypesForProductStyle {
  public productStyleId: number;
  public allowedAvatarTypes: AvatarTypes[];
}

export class AvatarCollectionsViewConfig {
  public isSeasonalProposalGroupingView: boolean;
  public isStyleAvaliableGroupingView: boolean;
}