<div class="left">
  <div class="pipeline-switch">
    @if (isShowRoutToPipeline) {
    <button class="buttons pipeline" (click)="onRoutToPipelineClicked.emit();$event.stopPropagation();"
      bootstrapTooltip="Pipeline" placement="bottom">
      <i class="material-icons">equalizer</i>
    </button>
    }
    @if (isShowRoutToList) {
    <button class="buttons list" (click)="onRoutToListClicked.emit();$event.stopPropagation();" bootstrapTooltip="List"
      placement="bottom">
      <i class="material-icons">reorder</i>
    </button>
    }
  </div>
  @if (isShowRoutToNewDeal) {
  <button type="button" class="btn btn-success btn-sm waves-light deals-button"
    (click)="onRoutToNewDealClicked.emit();$event.stopPropagation();">
    <div>Add</div>
  </button>
  }
  @if (isShowPipelineSelect) {
  <mat-form-field class="pipeline-select-global pipeline-select">
    <mat-select class="selector" [(value)]="selectedPipelineId" Tooltip="Pipelines" placement="bottom"
      panelClass="pipeline-panel-class-inject" [disabled]="isPipelineSelectDisabled"
      (selectionChange)="onSelectedPipelineChanged.emit(selectedPipelineId)">
      @for (item of allPipelines; track item) {
      <mat-option [value]="item.id">
        {{ item.name }}
      </mat-option>
      }
    </mat-select>
  </mat-form-field>
  }
  <button type="button" class="btn btn-info btn-sm waves-light deals-button"
    (click)="onLoadingMoreDealsClicked.emit();$event.stopPropagation();" [disabled]="!isMoreDeals">Load
    More</button>
  <div bootstrapTooltip="Select filter stage for moving" [tooltipDisabled]="false">
    @if (isShowMoveDeals) {
    <button type="button" class="btn btn-primary btn-sm waves-light deals-button"
      (click)="onMoveDealsClicked.emit();$event.stopPropagation();" [disabled]="isMoveDealsDisabled()">Move
      leads</button>
    }
  </div>
  @if (isShowExportLeads) {
  <button type="button" class="btn btn-primary btn-sm waves-light deals-button"
    (click)="onExportLeadsClicked.emit();$event.stopPropagation();">Export
    Leads</button>
  }
  @if (isShowExportFile) {
  <button type="button" class="btn btn-info btn-sm waves-light deals-button"
    (click)="onExportFileClicked.emit();$event.stopPropagation();" [disabled]="isExportFileDisabled">Export
    File</button>
  }
  @if (isShowExportEmail) {
  <button type="button" class="btn btn-info btn-sm waves-light deals-button"
    (click)="onExportEmailsClicked.emit();$event.stopPropagation();" [disabled]="isExportEmailDisabled">Export
    Email</button>
  }
  @if (isShowLookupExport) {
  <button type="button" class="btn btn-info btn-sm waves-light deals-button"
    (click)="onExportLookupsClicked.emit();$event.stopPropagation();" [disabled]="isExportLookupDisabled">Export
    Lookup</button>
  }
  @if (isShowEmailFinder) {
  <button type="button" class="btn btn-info btn-sm waves-light deals-button"
    (click)="onFindEmailsClicked.emit();$event.stopPropagation();" [disabled]="isEmailFinderDisabled">Email
    finder</button>
  }
  @if (isShowSameBuildingCompanies) {
  <a class="btn btn-primary waves-light deals-button long-label-deals-button same-building-button"
    bootstrapTooltip="Same Building companies" placement="bottom" href="/NearbyCompanies" target="_blank">
    <img class="min-icon" src="../../assets/same-building-icon.png">
    SB companies
  </a>
  }
</div>