<div class="header">
    <h2>WhatsApp/Email Bulk Messages</h2>
</div>

<div class="wrapper">
    <ng-container>
        <div [ngClass]="{'visible': !selectedSetting, 'hidden': selectedSetting }">

            <div class="settings__container">
                <pd-bulk-message-filter #filter></pd-bulk-message-filter>

                <div class="row" style="display: flex; justify-content: end; margin: 0;">
                    <button style="max-width: 100px;" class="btn btn-success waves-light"
                        [disabled]="!(filter.validation())" (click)="onAdd()">Add</button>
                </div>
            </div>

            <ul class="nav nav-tabs justify-content-left">
                @for (tab of tabs; track tab) {
                <li class="nav-item">
                    <a (click)="onTabClick(tab)" class="nav-link"
                        [ngClass]="tab.isActive ? 'active': ''">{{tab.name}}</a>
                </li>
                }
            </ul>

            <ng-container>
                <div class="col">
                    @if (tabs[0].isActive) {
                    <pd-bulk-message-table [isMain]="true" [title]="'Bulk Message Rules'"
                        [settings]="getNonArchivedSettings()" (onSelectSetting)="onSelectSetting($event)"
                        (onRemove)="onRemove($event)" (onArchive)="onArchive($event)"></pd-bulk-message-table>
                    }

                    @if (tabs[1].isActive) {
                    <pd-bulk-message-table [title]="'Archive'" [isArchive]="true"
                        (onSelectSetting)="onSelectSetting($event)" [settings]="getArchivedSettings()"
                        (onArchive)="onArchive($event)"></pd-bulk-message-table>
                    }
                </div>
            </ng-container>

            @if (spinner) {
            <div class="spinner">
                <mat-spinner></mat-spinner>
            </div>
            }
        </div>
    </ng-container>

    @if(selectedSetting)
    {
    <pd-bulk-message-requests [bulkMessageSetting]="selectedSetting" [templates]="templates"
        [currentTemplate]="currentTemplate" (onClose)="onClosed()"></pd-bulk-message-requests>
    }
</div>