import { RolesEnum } from '../enums/RolesEnum';
import { ObjectUtil } from '../utils/object.util';

export class User {
	public id: number;
	public name: string;
	public email: string;
	public referralLink: string;
	public is_admin: boolean;
	public is_you: boolean;
	public active_flag: boolean;
	public role_id: RolesEnum;
	public is_systemAccount: boolean;
	public open_cc_date: Date;

	public isTasksTab: boolean;
	public isUsersTab: boolean;
	public isAllowedExportLeadDate: boolean;
	public isAllowedHistoryOfLeads: boolean;
	public isRepoAccount: boolean;
	public isWhatsappChat: boolean;
	public isGlobalChat: boolean;
	public isAvatarEditor: boolean;
	public isSeeOthersCms: boolean;
	public isInSmReport: boolean;
	public isNotInClothierReport: boolean;

	public isLocal: boolean;
	public isTravel: boolean;
	public clothier_ea_connection?: number;

	public disabled = false;

	constructor(data?) {
		ObjectUtil.extend(data, this);
	}
}
