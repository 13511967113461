import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'pdCapitalize' })
export class PdCapitalizePipe implements PipeTransform {
  public transform(text: string) {
    if (text) {
      return text.charAt(0).toUpperCase() + text.slice(1);
    }
  }
}
