<div class="row wrapper">
  <div class="col-4 title">
    <span style="color: red; font-size: 20px">*</span> LinkedIn Location
  </div>
  <div class="col-8 work-field">
    @if (!isEdit) {
    <pd-custom-select-filter #locationFilter (document:focusout)="changeValue()"
      (loadDataEvent)="getLocationsFilterData($event)" (autoCompleteEvent)="getLocationsAutocomplete($event)"
      (isSelectedItem)="onChanged($event)" (open)="onSelectOpen()" [dataLabel]="locationsLabel" [isMultiple]="false">
    </pd-custom-select-filter>
    }
    @if (isSelectedLocation == false && !isEdit) {
    <pd-add-value (addValue)="addValue()"></pd-add-value>
    }
    @if (isEdit) {
    <pd-edit-in-progress #locationEdit (save)="saveLocation($event)"
      (cancel)="cancelNewLocation()"></pd-edit-in-progress>
    }
  </div>
</div>