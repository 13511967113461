import { Injectable } from "@angular/core";
import { Avatar } from "../../models/avatar-models/Avatar";
import { Observable, from } from "rxjs";
import { AvatarBuilderData } from "../../models/avatar-models/AvatarBuilderData";
import { BaseService } from "../base.service";
import { HttpRestService } from "../http-client/http-rest-client.service";

@Injectable()
export class AvatarApiService extends BaseService {
    constructor(private restService: HttpRestService) {
        super();
    }

    public getAllNonDealIdAvatars(): Observable<Avatar[]> {
        return from(this.handleResponse(this.restService.get({
            url: `/avatar/getAllNonDealId`
        })));
    }

    public getAvatar(avatarId: number): Observable<Avatar> {
        return from(this.handleResponse(this.restService.get({
            url: `/avatar/get/${avatarId}`
        })));
    }

    public getSeasonalProposal(): Observable<Avatar[]> {
        return from(this.handleResponse(this.restService.get({
            url: `/avatar/get-seasonal-proposal`
        })));
    }

    public getAvatarsBase64(avatarIds: number[]): Observable<Avatar[]> {
        const data =
        {
            avatarIds: avatarIds
        }
        return from(this.handleResponse(this.restService.post({
            url: `/avatar/getAvatarsBase64`,
            data: data
        })));
    }

    public createAvatar(avatarBuilderData: AvatarBuilderData): Observable<number> {
        const data = avatarBuilderData;
        return from(this.handleResponse(this.restService.post({
            url: `/avatar/createAvatar`,
            data: data
        })));
    }

    public createAvatarDuplicate(avatarId: number, dealId: number = null, clientCardId: number = null): Observable<number> {
        const data = {
            avatarId: avatarId,
            dealId: dealId,
            clientCardId: clientCardId
        }
        return from(this.handleResponse(this.restService.post({
            url: `/avatar/createAvatarDuplicate`,
            data: data
        })));
    }

    public updateAvatar(avatarId: number, avatarBuilderData: AvatarBuilderData): Observable<boolean> {
        const data = avatarBuilderData;
        return from(this.handleResponse(this.restService.put({
            url: `/avatar/updateAvatar/${avatarId}`,
            data: data
        })));
    }

    public deleteAvatar(avatarId: number): Observable<boolean> {
        return from(this.handleResponse(this.restService.delete({
            url: `/avatar/deleteAvatar/${avatarId}`
        })));
    }

    public downloadAvatar(avatarId: number): Observable<string> {
        return from(this.handleResponse(this.restService.get({
            url: `/avatar/downloadAvatar/${avatarId}`
        })));
    }

    public downloadAllNonDealId(): Observable<Blob> {
        return from(this.handleResponse(this.restService.get({
            url: `/avatar/downloadAllNonDealId`,
            responseType: 'blob' as 'json'
        })));
    }
}
