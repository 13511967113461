import { Component, ViewChild, Output, EventEmitter } from '@angular/core';
import { Deal } from 'src/app/core/models/Deal';
import { ModalComponent } from '../../modal/modal.component';
import { DealDetails } from 'src/app/core/models/DealDetails';

@Component({
  selector: 'pd-custom-referral-modal',
  templateUrl: './custom-referral-modal.component.html',
  styleUrls: ['./custom-referral-modal.component.scss']
})
export class CustomReferralModalComponent {
  @ViewChild('modal') modal: ModalComponent;

  @Output() closeModal = new EventEmitter<object>();
  @Output() saveWhoRef = new EventEmitter<string>();

  public whoReferredText: string;
  private confirmAction: any;

  public openReferralModal(deal: Deal | DealDetails, confirmCallback) {
    this.whoReferredText = deal.personWhoReferred;
    this.confirmAction = confirmCallback;
    this.modal.open();
  }

  public confirm(isConfirm) {
    isConfirm ? this.ok() : this.cancel();
    this.modal.close();
  }

  private ok() {
    this.saveWhoRef.emit(this.whoReferredText);
    if (this.confirmAction) {
      this.confirmAction();
    }
  }

  private cancel() {
    this.whoReferredText = null;
    this.closeModal.emit();
    this.modal.close();
  }
}
