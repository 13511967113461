export enum MessageStatuses {
	Queued = 1
	, Sending = 2
	, Sent = 3
	, Failed = 4
	, Delivered = 5
	, Undelivered = 6
	, Receiving = 7
	, Received = 8
	, Accepted = 9
	, Scheduled = 10
	, Read = 11
	, PartiallyDelivered = 12
	, Canceled = 13
	, Answered = 14
	, Unsubscribed = 15
}
