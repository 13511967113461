import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { ClientCard } from "../../models/ClientCard";
import { filterNil } from "../../filter-nil";

@Injectable()
export class ClientCardStoreService {
    private readonly clientCards$: BehaviorSubject<ClientCard[]> = new BehaviorSubject<ClientCard[]>(null);

    public getClientCards(): Observable<ClientCard[]> {
        return this.clientCards$.asObservable().pipe(filterNil());
    }

    public getAllClientCardsSnapshot(): ClientCard[] {
        return this.clientCards$.getValue();
    }

    public setClientCards(clientCards: ClientCard[]): void {
        this.clientCards$.next(clientCards);
    }
}