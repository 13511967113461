import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Deal } from 'src/app/core/models/Deal';

@Component({
  selector: 'pd-custom-contacts',
  templateUrl: './custom-contacts.component.html',
  styleUrls: ['./custom-contacts.component.scss'],
  
})
export class CustomContactsComponent {

  @Input() deal: Deal;
  @Output() openModal = new EventEmitter<object>();
  
  public showModal() {
    this.openModal.emit(this.deal);
  }
}
