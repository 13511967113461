<div>
  <div style="position: relative;">
    @if (isSpinner) {
    <div class="spinner">
      <mat-spinner [diameter]="20"></mat-spinner>
    </div>
    }
    
    <input type="text" #inputField [(ngModel)]="phone" value={{phone}}
      [ngClass]="{'input-error': isShowValidationMessages }" (blur)="checkIsValid()" (keydown)="resetValidation()"
      (change)="onChange()" (countrychange)="onChange()">
  </div>
  @if (isShowValidationMessages) {
  <div class="row alert-row error-message-wrapper">
    <div class="alert alert-danger error-message">{{validationMessages}}</div>
  </div>
  }
</div>