import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpRestService } from './http-client/http-rest-client.service';

@Injectable()
export class ImageService extends BaseService {
    constructor(private restService: HttpRestService) {
        super();
    }

    public async getWardrobeImagesByDealId(dealId: number): Promise<any> {
        return await this.handleResponse(this.restService.get({ url: '/image/getWardrobeImagesByDealId/' + dealId }));
    }

    public async getEaFollowUpImagesByDealId(dealId: number): Promise<any> {
        return await this.handleResponse(this.restService.get({ url: '/image/getEaFollowUpImagesByDealId/' + dealId }));
    }
}
