import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderByNumber',
  pure: true
})
export class OrderByNumberPipe implements PipeTransform {

  transform(value: any[], propertyName: string): any[] {
    if (propertyName)
      return value.sort((a: number, b: number) => this.compareNumbers(a[propertyName], b[propertyName]));
    else
      return value;
  }

  compareNumbers(a: number, b: number) {
    return a - b;
  }
}