<div class="header">
    @if (activeChat) {
    <div class="header-content">
        <div class="name">
            @if (!activeChat.isWhatsApp) {
            <img src='../../assets/sms.png' class="chat-type-icon" />
            }
            @if (activeChat.isWhatsApp) {
            <img src='../../assets/whatsapp.png' class="chat-type-icon" />
            }

            @if (activeChat?.dealId) {
            <a style="text-decoration: none;" [href]='DealUtil.getDealUrl(activeChat?.dealId)'
                target='_blank'>{{getActiveChatTitle()}}</a>
            }
            @if (!activeChat?.dealId) {
            <span>
                {{ getActiveChatTitle() }}</span>
            }
        </div>
        @if (this.activeChat.isWhatsApp) {
        <div class="session-status">
            @if (isSessionActive) {
            <div class="session-active">
                <span>Session expires after: </span>{{timer}}
            </div>
            }
            @if (!isSessionActive) {
            <div class="session-inactive">
                Session not active, please send template message
            </div>
            }
        </div>
        }
    </div>
    }
</div>