export enum ProductStyles {
    Tuxedo = 7,
    Overcoat = 41,
    BomberJacket = 66,
    SafariJacket = 67,
    SafariJacketWithBelt = 72,
    Nehru = 68,
    Overshirt = 69,
    InsulatedVest = 70,
    InsulatedVestWithCardigan = 71,
    TrenchCoat = 73,
    Topcoat = 74,
    Balmacaan = 75,
    PuffyJacket = 76,
}