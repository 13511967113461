<div class="export-file-modal" [@showModal]="showModal">
  <div class="wrapper">
    <span class="title">{{title}}</span>
    <div class="count-deals">
      <div class="md-form">
        @if (!isRange) {
        <input type="number" class="form-control" mdbInputDirective placeholder="Count" [(ngModel)]="countDeals" min="1"
          digitOnly>
        }
        @if (isRange) {
        <input type="number" class="form-control" mdbInputDirective placeholder="From" [(ngModel)]="fromNumber" min="1"
          digitOnly>
        }
        @if (isRange) {
        <input type="number" class="form-control" mdbInputDirective placeholder="To" [(ngModel)]="limitNumber" min="1"
          digitOnly>
        }
      </div>
    </div>
    <div class="result-info">
      {{messageToShow}}
    </div>
    <div class="buttons">
      <button type="button" class="btn btn-danger waves-light cancel" (click)="onCancel()">Cancel</button>
      <button type="button" class="btn btn-success waves-light save" (click)="onSubmit()" [disabled]="spinner || ((!countDeals || countDeals <= 0) && (!fromNumber || fromNumber <= 0 || !limitNumber || fromNumber > limitNumber || (limitNumber - fromNumber) >= limitationValue)) 
              || countDeals > limitationValue || limitNumber > limitationValue">
        @if (spinner) {
        <div class="spinner">
          <mat-spinner [diameter]="20"></mat-spinner>
        </div>
        }
        Save
      </button>
    </div>
  </div>
</div>