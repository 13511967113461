import { UserContextService } from './../core/services/user-context.service';
import { Component, OnInit, HostListener, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { UsersService } from '../core/services/users.service';
import { HotlistService } from '../core/services/hotlist.service';

@Component({
  selector: 'pd-uploading',
  templateUrl: './uploading.component.html',
  styleUrls: ['./uploading.component.scss']
})
export class UploadingComponent implements OnInit {
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('fileInputPdf') fileInputPdf: ElementRef;
  @ViewChild('container', { static: false }) container: ElementRef;

  public users = [];
  public deals = [];
  public start = 0;
  public limit = 20;
  public tableTitle = ['Name', 'Company', 'Position', 'Location', 'Upload Hotlist', 'Upload Hotlist 2.0'];
  public spinner = false;
  public file: any;
  public userId: number;
  public dealId: any;
  public lazyLoading = false;
  public uploadFileInfo: string;
  public more_in_provider = false;
  public currentUserId: any;
  public isAdmin = false;

  constructor(
    private cdRef: ChangeDetectorRef,
    private usersSerivce: UsersService,
    private hotlistService: HotlistService,
    private userContextService: UserContextService) { }

  async ngOnInit(): Promise<void> {
    this.isAdmin = this.userContextService.user.value.is_admin;
    this.currentUserId = this.userContextService.user.value.id;

    if (this.isAdmin) {
      this.users = await this.usersSerivce.getUsers();
    } else {
      this.userId = this.currentUserId;
      await this.loadMeetingDeals();
    }

  }

  public async changeFilter(user): Promise<void> {
    this.userId = user.id
    this.deals = [];
    this.start = 0;
    await this.loadMeetingDeals();
  }

  private async loadMeetingDeals(): Promise<void> {
    this.spinner = true;
    const result = await this.hotlistService.getMeetingDeals(this.userId, this.start, this.limit);
    this.spinner = false;
    if (result.data) {
      this.deals = result.data;
      this.more_in_provider = result.additional_data.pagination.more_items_in_collection;
      this.start = result.additional_data.pagination.next_start;
    }
  }

  public selectDeal(deal) {
    this.dealId = deal.dealId;
  }

  public openInput() {
    this.fileInput.nativeElement.click();
  }

  public async changeListener(files: FileList): Promise<void> {
    if (files && files.length > 0) {
      var splitArray = files[0].name.split('.');
      var fileType = splitArray[splitArray.length - 1];
      if (fileType && fileType.toLowerCase() !== "csv") {
        alert("Wrong file format. Please upload CSV file.");
      }
      else {
        this.file = files[0];
        this.uploadFileInfo = 'Uploading file task was started';
        await this.hotlistService.uploadHotlist(this.file, this.dealId, this.currentUserId);
        setTimeout(() => {
          this.uploadFileInfo = null;
        }, 3000);
      }
      this.resetFileUpload();
    }
  }

  public openInputPdf() {
    this.fileInputPdf.nativeElement.click();
  }

  public async changeListenerPdf(files: FileList): Promise<void> {
    if (files && files.length > 0) {
      var splitArray = files[0].name.split('.');
      var fileType = splitArray[splitArray.length - 1];
      if (fileType && fileType.toLowerCase() !== "pdf") {
        alert("Wrong file format. Please upload PDF file.");
      }
      else {
        this.file = files[0];
        this.uploadFileInfo = 'Uploading file task was started';
        await this.hotlistService.uploadHotlistPdf(this.file, this.dealId, this.currentUserId);
        setTimeout(() => {
          this.uploadFileInfo = null;
        }, 3000);
        this.file = null;
      }
      this.resetFileUpload();
    }
  }

  resetFileUpload() {
    this.fileInput.nativeElement.value = "";
    this.fileInputPdf.nativeElement.value = "";
    this.file = null;
  }

  @HostListener('window:scroll', ['$event'])
  doSomething(event) {
    this.cdRef.detectChanges();
    if (this.container.nativeElement.scrollTop === this.container.nativeElement.scrollHeight - this.container.nativeElement.clientHeight) {
      if (this.more_in_provider === true) {
        this.lazyLoading = true;
        this.hotlistService.getMeetingDeals(this.userId, this.start, this.limit).then(result => {
          setTimeout(() => {
            if (result.data) {
              result.data.forEach(element => {
                this.deals.push(element);
              });
            }
            this.more_in_provider = result.additional_data.pagination.more_items_in_collection;
            this.start = result.additional_data.pagination.next_start;
            this.lazyLoading = false;
          }, 300);
        },
          err => { }
        );
      } else {
        this.lazyLoading = false;
      }
    }
  }

}
