export enum RolesEnum {
    Admin = 1,
    MasterLead = 2,
    MissedAccount = 3,
    CampaignAccount = 4,
    DirectMailAccount = 5,
    SystemAccount = 6,
    User = 7,
    Conference = 8,
    EA = 9,
    EmailMarketing = 10,
    EventAccount = 11,
    Recruiter = 12,
    Clothier = 13,
    BB = 14,
    EaCaller = 15,
}
