import { UserContextService } from 'src/app/core/services/user-context.service';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { CustomSelectFilterWithoutLazyComponent } from '../shared/custom/custom-select-without-lazy/custom-select-without-lazy';
import { User } from '../core/models/UserModel';
import { RolesEnum } from '../core/enums/RolesEnum';
import { UsersService } from '../core/services/users.service';
import { ModalComponent } from '../shared/modal/modal.component';

@Component({
  selector: 'pd-clothier-to-ea-connection',
  templateUrl: './clothier-to-ea-connection.component.html',
  styleUrls: ['./clothier-to-ea-connection.component.scss']
})

export class ClothierToEaConnectionComponent implements OnInit {
  @Output() closeAdmin = new EventEmitter<object>();

  currentUser: User;

  eaUsersLabel: string = 'EA'

  @ViewChild("userEaFromFilter") userEaFromFilter: CustomSelectFilterWithoutLazyComponent;
  @ViewChild("userEaToFilter") userEaToFilter: CustomSelectFilterWithoutLazyComponent;
  @ViewChild('modal') reassignModal: ModalComponent;

  filteredEaFromUserMultiSelect: ReplaySubject<User[]> = new ReplaySubject<User[]>(1);
  filteredEaFromUsers = [];
  selectedEaFromUser: any;

  filteredEaToUserMultiSelect: ReplaySubject<User[]> = new ReplaySubject<User[]>(1);
  filteredEaToUsers = [];
  selectedEaToUser: any;

  RolesEnum = RolesEnum;

  allEaUsers = [];
  allClothierUsers = [];

  leftViewSelectList = [];
  rightViewSelectList = [];

  rightPrevSelectList = [];

  isOpened = false;
  isResponseWait = true;


  constructor(private usersService: UsersService, private userContextService: UserContextService) {}

  ngOnInit() {
    this.currentUser = this.userContextService.user.value;
  }


  setSelectLists() {
    if (this.selectedEaFromUser) {
      this.leftViewSelectList = this.allClothierUsers.filter(user => !user.clothier_ea_connection || user.clothier_ea_connection != this.selectedEaFromUser.id);
    }
    else {
      this.leftViewSelectList = [];
    }

    if (this.selectedEaFromUser) {
      this.rightViewSelectList = this.allClothierUsers.filter(user => user.clothier_ea_connection && user.clothier_ea_connection === this.selectedEaFromUser.id);
      this.rightPrevSelectList = this.allClothierUsers.filter(user => user.clothier_ea_connection && user.clothier_ea_connection === this.selectedEaFromUser.id);
    }
    else {
      this.rightViewSelectList = [];
      this.rightPrevSelectList = [];
    }
  }

  ngAfterViewInit() {
    this.loadData();
  }

  loadData() {
    this.isResponseWait = true;
    this.usersService.getUsers().then((users) => {
      this.leftViewSelectList = [];
      this.rightViewSelectList = [];
      this.rightPrevSelectList = [];
      
      this.allClothierUsers = users.filter(user => user.role_id === RolesEnum.MasterLead || user.role_id === RolesEnum.Admin || user.role_id === RolesEnum.Clothier);
      this.allEaUsers = users.filter(user => user.role_id === RolesEnum.EA || user.role_id === RolesEnum.EaCaller || user.role_id === RolesEnum.Admin);

      this.filteredEaFromUsers = this.allEaUsers;
      this.filteredEaToUsers = this.allEaUsers;
      this.getEaFromUsersFilterData();
      this.getEaToUsersFilterData();

      this.selectedEaFromUser = this.userEaFromFilter.dataArray[0];
      this.selectedEaToUser = this.userEaToFilter.dataArray[0];
      this.setSelectLists();
      this.sortLists();

      this.isResponseWait = false;
    })
  }

  onEaFromSelectedItem(checked) {
    if (checked) {
      this.selectedEaFromUser = this.userEaFromFilter.dataArray[0];
    }
    else {
      this.selectedEaFromUser = null;
    }
    this.setSelectLists();
    this.sortLists();
  }

  onEaToSelectedItem(checked) {
    if (checked) {
      this.selectedEaToUser = this.userEaToFilter.dataArray[0];
    }
    else {
      this.selectedEaToUser = null;
    }
  }

  onAddToEa(user) {
    let index = this.leftViewSelectList.indexOf(user);
    if (index > -1) {
      this.leftViewSelectList.splice(index, 1);
    }
    this.rightViewSelectList.push(user);
    this.sortLists();
  }

  onRemoveFromEa(user) {
    let index = this.rightViewSelectList.indexOf(user);
    if (index > -1) {
      this.rightViewSelectList.splice(index, 1);
    }
    this.leftViewSelectList.push(user);
    this.sortLists();
  }

  onSelectAll() {
    this.rightViewSelectList = this.rightViewSelectList.concat(this.leftViewSelectList);
    this.leftViewSelectList = [];
  }

  onClear() {
    this.leftViewSelectList = this.leftViewSelectList.concat(this.rightViewSelectList);
    this.rightViewSelectList = [];
  }

  onReassignTo() {
    this.isResponseWait = true;

    this.rightPrevSelectList.forEach(user => {
      user.clothier_ea_connection = this.selectedEaToUser.id;
    })

    this.usersService.updateClothierToEaConnections(this.rightPrevSelectList).then(result => {
      if (!result) {
        alert("Selected EA Not Found")
      }
      this.loadData();
      this.closeReassignModal();
      this.isResponseWait = false;
    })
  }

  onSaveAssign() {
    this.isResponseWait = true;

    let addConnectionList = this.rightViewSelectList.filter(f => this.rightPrevSelectList.findIndex(fi => fi.id === f.id) == -1);
    let removeConnectionList = this.rightPrevSelectList.filter(f => this.rightViewSelectList.findIndex(fi => fi.id === f.id) == -1);

    addConnectionList.forEach(user => {
      user.clothier_ea_connection = this.selectedEaFromUser.id;
    })

    removeConnectionList.forEach(user => {
      user.clothier_ea_connection = null;
    })

    let actionConnectionList = addConnectionList.concat(removeConnectionList);

    this.usersService.updateClothierToEaConnections(actionConnectionList).then(result => {
      if (!result) {
        this.selectedEaFromUser = null;
        this.userEaFromFilter.deselectAllData()
        alert("Selected EA Not Found");
      }
      this.loadData();
    })
  }

  sortLists() {
    if (this.leftViewSelectList && this.leftViewSelectList.length > 1) {
      this.leftViewSelectList.sort((a: any, b: any) => a.name.toString().localeCompare(b.name));
    }
    if (this.rightViewSelectList && this.rightViewSelectList.length > 1) {
      this.rightViewSelectList.sort((a: any, b: any) => a.name.toString().localeCompare(b.name));
    }
  }

  getEaFromUsersFilterData() {
    this.filteredEaFromUsers = [];
    this.filteredEaFromUsers = this.filteredEaFromUsers.concat(this.allEaUsers);
    this.filteredEaFromUserMultiSelect.next(this.allEaUsers.slice());
    if (this.userEaFromFilter) {
      this.userEaFromFilter.setData(this.filteredEaFromUsers);
    }
  }

  getEaToUsersFilterData() {
    this.filteredEaToUsers = [];
    this.filteredEaToUsers = this.filteredEaToUsers.concat(this.allEaUsers);
    this.filteredEaToUserMultiSelect.next(this.allEaUsers.slice());
    if (this.userEaToFilter) {
      this.userEaToFilter.setData(this.filteredEaToUsers);
    }
  }

  getEaFromUsersAutocomplete(event) {
    let search = event.searchString;
    if (!search) {
      this.filteredEaFromUserMultiSelect.next(this.allEaUsers.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredEaFromUserMultiSelect.next(
      this.allEaUsers.filter(loc => loc.name.toLowerCase().indexOf(search) > -1)
    );
    this.filteredEaFromUsers = [];
    this.filteredEaFromUserMultiSelect.subscribe(x => {
      for (let i = 0; i < x.length; i++) {
        if (this.filteredEaFromUsers.length == 0) {
          this.filteredEaFromUsers.push(x[0]);
        } else {
          if (!this.filteredEaFromUsers.includes(x[i], 0)) {
            this.filteredEaFromUsers.push(x[i]);
          }
        }
      }
    });
    this.userEaFromFilter.setData(this.filteredEaFromUsers);
  }

  getEaToUsersAutocomplete(event) {
    let search = event.searchString;
    if (!search) {
      this.filteredEaToUserMultiSelect.next(this.allEaUsers.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredEaToUserMultiSelect.next(
      this.allEaUsers.filter(loc => loc.name.toLowerCase().indexOf(search) > -1)
    );
    this.filteredEaToUsers = [];
    this.filteredEaToUserMultiSelect.subscribe(x => {
      for (let i = 0; i < x.length; i++) {
        if (this.filteredEaToUsers.length == 0) {
          this.filteredEaToUsers.push(x[0]);
        } else {
          if (!this.filteredEaToUsers.includes(x[i], 0)) {
            this.filteredEaToUsers.push(x[i]);
          }
        }
      }
    });
    this.userEaToFilter.setData(this.filteredEaToUsers);
  }

  getUnderEaLabel(user) {
    if (user.clothier_ea_connection) {
      var eaConnectionUser = this.allEaUsers.find(f => f.id === user.clothier_ea_connection);
      if (eaConnectionUser) {
        return '(Under EA ' + eaConnectionUser.name + ')'
      }
      return '(EA Not Found)'
    }
    else {
      return null;
    }
  }

  getColorClass(user) {
    if (!user.clothier_ea_connection)
      return '';
    if (!this.allEaUsers.find(f => f.id === user.clothier_ea_connection))
      return "error-connected";
    if (user.clothier_ea_connection)
      return "connected";
  }

  openReassignModal() {
    this.selectedEaToUser = null;
    this.userEaToFilter.deselectAllData();
    this.isOpened = true;
    this.reassignModal.open();
  }

  closeReassignModal() {
    this.reassignModal.close();
    this.isOpened = false;
  }

  closeClothierEaConnectionAdmin() {
    this.closeAdmin.emit();
  }

  saveAssignValidation() {
    return !this.isResponseWait && this.selectedEaFromUser && (JSON.stringify(this.rightViewSelectList) !== JSON.stringify(this.rightPrevSelectList));
  }

  onClearValidation() {
    return !this.isResponseWait && (this.rightViewSelectList && this.rightViewSelectList.length > 0);
  }

  onSelectAllValidation() {
    return !this.isResponseWait && (this.leftViewSelectList && this.leftViewSelectList.length > 0);
  }

  openReassignModalValidation() {
    return !this.isResponseWait && this.selectedEaFromUser && this.rightPrevSelectList && this.rightPrevSelectList.length > 0;
  }

  onReassignToValidation() {
    return !this.isResponseWait && this.selectedEaFromUser && this.selectedEaToUser && this.selectedEaFromUser.id !== this.selectedEaToUser.id;
  }
}