<pd-custom-contacts-modal #contactsModal [moveContactsModal]="moveContactsModal"
  (closeModal)="closeEditingContactsModal()"></pd-custom-contacts-modal>
@if (!spinner && !isShowError) {
<div class="tableWidth">
  <div class="container-deals">
    @for (col of activeStages; track col; let i = $index) {
    <div class="border-clients">
      <div class="col-name" bootstrapTooltip="{{col.name}}" placement="bottom" mdbRippleRadius>
        <p word-break="normal">{{col.name}}</p>
        @if (masterLeadDealsCountByStage[col.id]) {
        <p font-weight="lighter">( {{masterLeadDealsCountByStage[col.id]}} )</p>
        }
      </div>
    </div>
    }
  </div>
  <div id="id" class="tableWrapper" #container>
    <div class="wrapp">
      @for (col of activeStages; track col; let i = $index) {
      <div class="tableContentClient">
        <div [dragula]='"first-bag"' class="dragula" [dragulaModel]="array[col.id]" [attr.data-id]="col.id">
          @for (item of array[col.id]; track item) {
          <div class="content" [attr.data-id]="item.id"
            [attr.data-previousLabeledStageId]="item.previous_labeled_stage_id" [attr.data-index]="i">
            <!-- <div class="deal-item" [routerLink]="['/deals', item.id]">  TODO: check it-->
            <div class="deal-item">
              <div class="deal-information">
                <div class="text-title">
                  <strong>{{item.title}}</strong>
                  <img src="../../assets/copy_title.png" [text-copy]="item.title" bootstrapTooltip="Copy Name"
                    placement="bottom" (click)="$event.stopPropagation()">
                </div>
                <div class="text-small">
                  <small class="row text">{{item.company}}</small>
                  <small class="row text">{{item.position}}</small>
                </div>
                @if (item.phone && item.phone[0] && item.phone[0].value) {
                <div class="text-small">
                  @for (phone of item.phone; track phone) {
                  <div class="phones">
                    @if (phone.value) {
                    <small> {{phone.value}} ({{phone.label | pdCapitalize}})</small>
                    }
                    @if (phone.label.toLowerCase() === 'work') {
                    <img src="../../../assets/workPhone.png" [text-copy]="phone.value" bootstrapTooltip="copy phone"
                      placement="bottom" (click)="$event.stopPropagation()">
                    }
                    @if (phone.label.toLowerCase() !== 'work') {
                    <img src="../../../assets/otherPhones.png" [text-copy]="phone.value" bootstrapTooltip="copy phone"
                      placement="bottom" (click)="$event.stopPropagation()">
                    }
                  </div>
                  }
                </div>
                }
                <pd-custom-contacts [deal]="item" (openModal)="openEditingContactsModal($event)"></pd-custom-contacts>
                @if (item.email && item.email[0] && item.email[0].value) {
                <div class="text-small">
                  @for (email of item.email; track email) {
                  <div class="emails">
                    <small> {{email.value}} ({{email.label | pdCapitalize}})</small>
                    @if (email.label.toLowerCase() === 'work') {
                    <img src="../../assets/workEmail.png" [text-copy]="email.value" bootstrapTooltip="copy email"
                      placement="bottom" (click)="$event.stopPropagation()">
                    }
                    @if (email.label.toLowerCase() !== 'work') {
                    <img src="../../assets/otherEmails.png" [text-copy]="email.value" bootstrapTooltip="copy email"
                      placement="bottom" (click)="$event.stopPropagation()">
                    }
                  </div>
                  }
                </div>
                }
                @if (item.linkedin_URL) {
                <div class="text-small">
                  <div class="linkedin-url" (click)="openlinkedIn(item);$event.stopPropagation()">LinkedIn Profile</div>
                </div>
                }
              </div>
              @if (item.participants_count > 0) {
              <pd-custom-crown [deal]="item"></pd-custom-crown>
              }
              @if (item.same_building_count > 0) {
              <pd-custom-same-building [deal]="item"></pd-custom-same-building>
              }
            </div>
          </div>
          }
        </div>
      </div>
      }
    </div>
  </div>
</div>
}
@if (lazyLoading) {
<div class="lazyLoading">
  <div class="text-lazy">Loading more deals...</div>
</div>
}
@if (spinner) {
<div class="spinner">
  <mat-spinner></mat-spinner>
</div>
}

@if (isShowError) {
<div class="show-error">
  <h2 style="color: red; font-weight: 400; padding: 20px">Sorry, something went wrong. Please try again later</h2>
</div>
}