<div>
  <pd-export #exportFileModal (closeModal)="closeExportModal()" (exportFile)="exportDeals()"></pd-export>
  <pd-custom-modal #exportEmailsModal [titleInput]="exportEmailsModalTitle"
    [maxPossibleLimit]="exportEmailsModalMaxLimit" [isRange]="false" (onCloseModal)="exportEmailsModalClosed()"
    (onSubmitModal)="exportEmailsModalSubmitted($event)">
  </pd-custom-modal>
  <pd-custom-modal #exportLookupsModal [titleInput]="exportLookupsModalTitle"
    [maxPossibleLimit]="exportLookupsModalMaxLimit" [isRange]="false"
    (onSubmitModal)="exportLookupsModalSubmitted($event)"></pd-custom-modal>
  <pd-custom-modal #findEmailsModal [titleInput]="findEmailsModalTitle" [maxPossibleLimit]="findEmailsModalMaxLimit"
    [isRange]="true" (onCloseModal)="findEmailsModalClosed()" (onSubmitModal)="findEmailsModalSubmitted($event)">
  </pd-custom-modal>
  <pd-custom-referral-modal #referralModal (closeModal)="closeReferralModal()" (saveWhoRef)="saveWhoReferred($event)">
  </pd-custom-referral-modal>
  <pd-custom-contacts-modal #contactsModal [moveContactsModal]="moveContactsModal"
    (closeModal)="closeEditingContactsModal()"></pd-custom-contacts-modal>

  <pd-custom-meeting-date #meetingDateModal (closeModal)="closeDateMeetingModal()"
    (saveDate)="saveDateOfMeeting($event)">
  </pd-custom-meeting-date>
  <pd-custom-rotten-time-modal #rottenTimeModal (closeModal)="closeCustomDateModal()"
    (saveDate)="saveCustomDate($event)">
  </pd-custom-rotten-time-modal>

  <div class="header-deals">
    <div>
      <pd-left-deals-panel [isMasterLeadDeals]="isMasterLeadDeals" [isListDeals]="isListDeals"
        [isFilterPerformed]="(listDeal && listDeal.isFilterPerformed)" [isMoreDeals]="isMoreDeals"
        [isListDealRender]="!!listDeal" (onRoutToPipelineClicked)="routToPipeline()"
        (onRoutToListClicked)="routToList()" (onRoutToNewDealClicked)="routToNewDeal()"
        (onLoadingMoreDealsClicked)="loadingMoreDeals()" (onSelectedPipelineChanged)="updatePipeline($event)"
        (onMoveDealsClicked)="listDeal.moveDeals()" (onExportLeadsClicked)="listDeal.exportLeads()"
        (onExportFileClicked)="exportFile()"
        (onExportEmailsClicked)="exportEmails()" (onExportLookupsClicked)="exportLookups()"
        (onFindEmailsClicked)="findEmails()">
      </pd-left-deals-panel>
    </div>
    <div>
      <pd-right-deals-panel #filters (applyFilters)="filterDashboard()" [isMasterLeadDeals]="isMasterLeadDeals"
        [isListDeals]="isListDeals">
      </pd-right-deals-panel>
    </div>
  </div>
  @if (isListDeals && listDealTotalCount !== null) {
  <div class="amount-of-deals">Amount of deals:
    {{listDealTotalCount}}</div>
  }
  <div class="page-wrapper">
    @if (isMasterLeadDeals) {
    <pd-master-lead #masterLeadView (loadingDeals)="loadingListDeals($event)"></pd-master-lead>
    }
    @if (isListDeals) {
    <pd-list-deal (loadingListDeals)="loadingListDeals($event)" #listDeal></pd-list-deal>
    }
    @if (
    !isListDeals && !isMasterLeadDeals
    && (selectedPipelineId === PIPELINES.Clients
    || selectedPipelineId === PIPELINES.OpenOrders
    || selectedPipelineId === PIPELINES.ClothierContactClients)
    && dealsCountByStage && dealsLifeTimeSpendByStage &&
    (isMasterLeadAccount || isSystemAccount || isEaAccount || isEaCallerAccount || isAdminAccount || (isMyDeals &&
    isClothierAccount))) {
    <div class="lifetime-spend lifetime-spend--total-fixed" font-weight="lighter">
      Total pipeline amount: ${{lifeTimeSpendTotal | money}}</div>
    }
    @if ((selectedPipelineId === PIPELINES.Clients
    || selectedPipelineId === PIPELINES.OpenOrders) && !spinner && ((currentUser?.role_id !== rolesEnum.EA &&
    currentUser?.role_id !== rolesEnum.EaCaller) || currentUserFilter?.length > 0)) {
    <div class="tableWidth">
      <div class="container-deals">
        @for (col of stagesOnFilteredUsers | stagesForPipeline : selectedPipelineId; track col; let i = $index) {
        <div class="border-clients" [ngStyle]="{'width':stylesForColumn[col.name].width}">
          <div class="col-name" bootstrapTooltip="{{col.name}}">
            <p word-break="normal">{{col.name}}</p>
            @if (dealsCountByStage && dealsCountByStage[col.id] > 0) {
            <p class="count-deals" font-weight="lighter">
              ({{dealsCountByStage[col.id]}})</p>
            }
            @if (dealsCountByStage && dealsCountByStage[col.id] > 0 && dealsLifeTimeSpendByStage &&
            (isMasterLeadAccount || isSystemAccount || isEaAccount || isEaCallerAccount || isAdminAccount || (isMyDeals
            && isClothierAccount)) && isClient(col.id)) {
            <p class="count-deals lifetime-spend--header lifetime-spend" font-weight="lighter">
              ${{dealsLifeTimeSpendByStage[col.id] | money}}</p>
            }
          </div>
        </div>
        }
      </div>
      <div id="id" class="tableWrapper" #container>
        <div class="wrapp" [ngStyle]="{'min-height':wrappMinHeight}">
          @for (col of stagesOnFilteredUsers | stagesForPipeline : selectedPipelineId; track col; let i = $index) {
          <div class="tableContentClient" [ngStyle]="{'width':stylesForColumn[col.name].width}">
            <div [dragula]='"first-bag"' #dragulaContainer class="dragula" [ngClass]="{ 'nodrag' : !isAllowToDrag }"
              [dragulaModel]="array[col.id]" [attr.data-id]="col.id">
              @for (item of array[col.id]; track item) {
              <div class="content nodrag" [attr.data-id]="item.id"
                [attr.data-previousLabeledStageId]="item.previous_labeled_stage_id" [attr.data-index]="i">

                @if (array[col.id] && array[col.id].length > 0 && !minStageRule(col)) {
                <pd-deal-card [deal]="item" (onBasketClicked)="openBasketClientCardModal($event)"
                  (onEditContactsClicked)="openEditingContactsModal($event)"
                  (onExportWardrobePlanClicked)="exportWardrobePlan($event)"
                  (onUploadWardrobePlanClicked)="uploadWardrobePlanModal($event)"
                  (onMeetingPrepClicked)="openMeetingPrepPdf($event)"
                  (onIsOrderShippedChanged)="onIsOrderShippedChanged($event)"
                  (onIsOpenOrderNotifiedChanged)="onIsOpenOrderNotifiedChanged($event)"
                  (onIsDeliveryEmailSentChanged)="onIsDeliveryEmailSentChanged($event)"
                  (onDraftReadyChanged)="draftReadyUpdateStage($event)"
                  (onPostponeClicked)="increaseRottingTimeChange($event)"
                  (onReselectRescheduleDateClicked)="reselectRottingTimeReschedule($event)"
                  (onReselectInAlterationDateClicked)="reselectRottingTimeInAlteration($event)"
                  (onReselectDeliveryMeetingDateClicked)="reselectRottingTimeDeliveryMeeting($event)">
                </pd-deal-card>
                }

              </div>
              }
              @if (minStageRule(col) === true && minStage && (minStage.pipeline_id !== PIPELINES.ClothierMeetingConfirm
              || (idPipeline$ | async) === PIPELINES.ClothierMeetingConfirm)) {
              <div class="empty-container hidden-deals">
                Deals are not available for viewing. Please change the status of expired deals for further work. If you
                don't see any expired deals on the pipeline, please <b><u>check filters</u></b>
              </div>
              }
              @if (minStageRule(col) === true && minStage && minStage.pipeline_id === PIPELINES.ClothierMeetingConfirm
              && (idPipeline$ | async ) !== PIPELINES.ClothierMeetingConfirm) {
              <div class="empty-container hidden-deals">
                Deals are not available for viewing. Please, take a look at <b>Meeting Confirm pipeline.</b> You have
                red
                leads there.
              </div>
              }
            </div>
          </div>
          }
        </div>
      </div>
    </div>
    }
    @if ((selectedPipelineId === PIPELINES.NewProspects || selectedPipelineId === PIPELINES.ClothierMeetingConfirm ||
    selectedPipelineId === PIPELINES.ClothierContactClients) && !spinner) {
    <div class="tableWidth">
      <div class="container-deals">
        @for (col of stagesOnFilteredUsers | stagesForPipeline : selectedPipelineId; track col; let i = $index) {
        <div [ngClass]="{
          'sb-left'  : i === 0,
          'sb-right' : activeStages?.length === (i + 1),
          'col-name-border': true
          }" [ngStyle]="stylesForColumn[col.name]">
          <div class="col-name" bootstrapTooltip="{{col.name}}" placement="bottom" mdbRippleRadius>
            <p word-break="normal">{{col.name}}</p>
            @if (dealsCountByStage && dealsCountByStage[col.id] > 0) {
            <p class="count-deals" font-weight="lighter">(
              {{dealsCountByStage[col.id]}} )</p>
            }
            @if (dealsCountByStage && dealsCountByStage[col.id] > 0 && dealsLifeTimeSpendByStage &&
            (isMasterLeadAccount || isSystemAccount || isAdminAccount || (isMyDeals && isClothierAccount)) &&
            isClient(col.id)) {
            <p class="count-deals lifetime-spend--header lifetime-spend" font-weight="lighter">
              ${{dealsLifeTimeSpendByStage[col.id] | money}}</p>
            }
          </div>
        </div>
        }
      </div>
      <div id="id" class="tableWrapper" #container>
        <div class="wrapp" [ngStyle]="{'min-height':wrappMinHeight}">
          @for (col of stagesOnFilteredUsers | stagesForPipeline : selectedPipelineId; track col; let i = $index) {
          <div [class.tableContentCampaing]="isCampaignDeals" [class.tableContentMissedMtg]="isMissedMtg"
            [class.tableContent]="!isCampaignDeals && !isMissedMtg"
            [ngStyle]="{'width':stylesForColumn[col.name].width}">
            <div [dragula]='"first-bag"' #dragulaContainer class="dragula" [ngClass]="{ 'nodrag' : !isAllowToDrag }"
              [dragulaModel]="array[col.id]" [attr.data-id]="col.id">
              @for (item of array[col.id]; track item) {
              <div class="content" [attr.data-id]="item.id"
                [attr.data-previousLabeledStageId]="item.previous_labeled_stage_id" [attr.data-index]="i">

                @if (array[col.id] && array[col.id].length > 0 && !minStageRule(col)) {
                <pd-deal-card [deal]="item" (onBasketClicked)="openBasketClientCardModal($event)"
                  (onEditContactsClicked)="openEditingContactsModal($event)"
                  (onExportWardrobePlanClicked)="exportWardrobePlan($event)"
                  (onUploadWardrobePlanClicked)="uploadWardrobePlanModal($event)"
                  (onMeetingPrepClicked)="openMeetingPrepPdf($event)"
                  (onIsOrderShippedChanged)="onIsOrderShippedChanged($event)"
                  (onIsOpenOrderNotifiedChanged)="onIsOpenOrderNotifiedChanged($event)"
                  (onIsDeliveryEmailSentChanged)="onIsDeliveryEmailSentChanged($event)"
                  (onDraftReadyChanged)="draftReadyUpdateStage($event)"
                  (onPostponeClicked)="increaseRottingTimeChange($event)"
                  (onReselectRescheduleDateClicked)="reselectRottingTimeReschedule($event)"
                  (onReselectInAlterationDateClicked)="reselectRottingTimeInAlteration($event)"
                  (onReselectDeliveryMeetingDateClicked)="reselectRottingTimeDeliveryMeeting($event)">
                </pd-deal-card>
                }

              </div>
              }
              @if (minStageRule(col) === true && minStage && (minStage.pipeline_id !== PIPELINES.ClothierContactClients
              || (idPipeline$ | async) === PIPELINES.ClothierContactClients) && (minStage.pipeline_id !==
              PIPELINES.ClothierMeetingConfirm || (idPipeline$ | async) === PIPELINES.ClothierMeetingConfirm)) {
              <div class="empty-container hidden-deals">
                Deals are not available for viewing. Please change the status of expired deals for further work. If you
                don't see any expired deals on the pipeline, please <b><u>check filters</u></b>
              </div>
              }
              @if (minStageRule(col) === true && minStage && minStage.pipeline_id === PIPELINES.ClothierContactClients
              && (idPipeline$ | async ) !== PIPELINES.ClothierContactClients) {
              <div class="empty-container hidden-deals">
                Deals are not available for viewing. Please, take a look at <b>Clothier Contact Clients pipeline.</b>
                You
                have red leads there.
              </div>
              }
              @if (minStageRule(col) === true && minStage && minStage.pipeline_id === PIPELINES.ClothierMeetingConfirm
              && (idPipeline$ | async ) !== PIPELINES.ClothierMeetingConfirm) {
              <div class="empty-container hidden-deals">
                Deals are not available for viewing. Please, take a look at <b>Meeting Confirm pipeline.</b> You have
                red
                leads there.
              </div>
              }
            </div>
          </div>
          }
        </div>
      </div>
    </div>
    }
  </div>

  @if (lazyLoading) {
  <div class="lazyLoading">
    <div class="text-lazy">Loading more deals...</div>
  </div>
  }
  @if (spinner && !isListDeals) {
  <div class="spinner">
    <mat-spinner></mat-spinner>
  </div>
  }
  @if (dropSpinner && !isListDeals) {
  <div class="drop-spinner">
    <mat-spinner></mat-spinner>
  </div>
  }
  @if (isShowError) {
  <div class="show-error">
    <h2 style="color: red; font-weight: 400; padding: 20px">Sorry, something went wrong. Please try again later</h2>
  </div>
  }

  @if (isAllowGlobalChat) {
  <a [href]="ROUTE_NAMES.GlobalChat" target='_blank'>
    <img src="../../assets/global_chat.png" class="global-chat-img">
  </a>
  }

  <pd-custom-kill-record-modal #killRecordModal [dialogOptions]="killRecordOptions"
    [isInputDisplayed]="currentUser?.is_admin == true" (confirmBtn)="moveRecordToKill()"
    (cancelBtn)="cancelMoveRecord()"></pd-custom-kill-record-modal>
  <pd-custom-kill-record-modal #trackingLinkModal [isUrl]="true" [inputPlaceholder]="trackingLinkPlaceholder"
    [isInputDisplayed]="true" [dialogOptions]="trackingLinkOptions" (cancelBtn)="onTrackingLinkCanceled()"
    (confirmBtn)="onTrackingLinkConfirmed()"></pd-custom-kill-record-modal>
  @if (infoMessage && infoMessage.length > 0) {
  <div class="move-leads">{{infoMessage}}</div>
  }
  <pd-wardrobe-plan #wardrobePlan></pd-wardrobe-plan>
  <pd-meeting-prep #meetingPrep></pd-meeting-prep>
  <pd-custom-wardrobe-plan-upload-modal #uploadWardrobePlan></pd-custom-wardrobe-plan-upload-modal>
  <pd-custom-ea-follow-up-image-upload-modal #eaFollowUpImageUpload>
  </pd-custom-ea-follow-up-image-upload-modal>
</div>