<div class="row" style="padding: 20px; display: flex; justify-content: space-between;">
    <div class="col-md-6 col-xl-2 filter__item">
        <div class="row">
            <div class="setting-label">
                Client’s reply goes to
            </div>
        </div>
        <div class="row">
            <div class="col">
                <pd-custom-select-without-lazy #userFilter (loadDataEvent)="getUsersFilterData()"
                    (autoCompleteEvent)="getUsersAutocomplete($event)" [dataLabel]="usersLabel"
                    [startLabel]="usersLabel" [isMultiple]="true">
                </pd-custom-select-without-lazy>
            </div>
        </div>
        <div class="row">
            <div class="setting-label" style="padding-top: 10px;">
                (only for WhatsApp replies)
            </div>
        </div>
    </div>
    <div class="col-md-6 col-xl-2 filter__item" style="padding-top: 9px;">
        <div class="row">
            <mat-form-field>
                <mat-label>Choose Expiration Date</mat-label>
                <input matInput [matDatepicker]="picker" (dateChange)="selectExpirationDate($event.value)">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="row">
            <div class="setting-label" style="padding-top: 10px;">
                (only for WhatsApp replies)
            </div>
        </div>
    </div>

    <div class="col-md-6 col-xl-2 filter__item" style="padding-top: 9px;">
        <div class="row">
            @if(templates)
            {
            <mat-form-field appearance="fill" style="width: 250px;">
                <mat-label>Select Template</mat-label>
                <mat-select [(value)]="currentTemplate">
                    <mat-option *ngFor="let template of templates" [value]="template">
                        {{ template.displayName }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            }
        </div>
    </div>
</div>

<div class="col" style="padding: 25px;">
    @if(currentTemplate)
    {
    <div class="template__container">
        <textarea class="template__body">{{currentTemplate.body}}</textarea>
    </div>
    <div class="row" style="padding: 10px 0;">
        @for(variable of currentTemplate.variableDescriptions; track variable) {
        <div class="col variable_sample__item">
            <div class="variable_sample__label">
                Sample content for variable
                <span>&#123;</span><span>&#123;</span>{{variable.variableNumber}}<span>&#125;</span><span>&#125;</span>
            </div>
            <div class="variable_sample__value"
                [ngClass]="{'variable_sample__value_disabled': !isStaticVariable(variable) }">
                <input type="text" [(ngModel)]="variable.exampleData" [disabled]="!isStaticVariable(variable)">
            </div>
            <div class="variable_sample__type">
                {{TemplateVariableContentTypes[variable.templateVariableContentType]}}
            </div>
        </div>
        }
    </div>
    }
</div>