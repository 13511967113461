export enum RequestStatus {
	Failed = 1,
	New = 2,
	InProgress = 3,
	Queued = 4,
	Completed = 5,
	Sheduled = 6,
}


