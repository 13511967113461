@if (isSpinner) {
<div class="spinner">
  <mat-spinner [diameter]="100"></mat-spinner>
</div>
}

<div class="row"
  [ngClass]="{'visible-select': !isShowFillMessage && !isSpinner, 'hidden-select': (isShowFillMessage || isSpinner) }">
  <div class="col-xl-7 col-lg-12">
    <div #bingMap class="row map"></div>
    <div class="row">
      <div class="col menu">
        <div class="row">
          <div style="max-width: 130px;" class="col menu-item label">Search radius</div>
          <div class="col" style="display: flex;">
            <mat-slider showTickMarks thumbLabel step="1" [min]="minKm" [max]="maxKm" (input)="onDistanceChange()">
              <input [(ngModel)]="km" matSliderThumb>
            </mat-slider>
            <div style="margin-left: 15px;" class="menu-item label">{{km}} km</div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div>
              <mat-checkbox [(ngModel)]='isShowCompanyAddress' (change)="onCompanyAddressesCheckChange()">Company
                Address
              </mat-checkbox>
            </div>
            <div>
              <mat-checkbox [(ngModel)]='isShowHomeAddress' (change)="onHomeAddressesCheckChange()">
                Home Address
              </mat-checkbox>
            </div>
          </div>
          <div class="col">
            <div>
              <mat-checkbox [(ngModel)]='isClient' (change)="onStageCheckChange()" [disabled]="!isClientExist">
                Client
              </mat-checkbox>
            </div>
            <div>
              <mat-checkbox [(ngModel)]='isMeeting' (change)="onStageCheckChange()" [disabled]="!isMeetingExist">
                Meeting
              </mat-checkbox>
            </div>
            <div>
              <mat-checkbox [(ngModel)]='isDeliveryMeeting' (change)="onStageCheckChange()"
                [disabled]="!isDeliveryMeetingExist">
                Delivery Meeting
              </mat-checkbox>
            </div>
            <div>
              <mat-checkbox [(ngModel)]='isOrderShipped' (change)="onStageCheckChange()"
                [disabled]="!isOrderShippedExist">
                Order Shipped
              </mat-checkbox>
            </div>
            <div>
              <mat-checkbox [(ngModel)]='isReferral' (change)="onStageCheckChange()" [disabled]="!isReferralExist">
                Referral
              </mat-checkbox>
            </div>
            <div>
              <mat-checkbox [(ngModel)]='isFloat' (change)="onStageCheckChange()" [disabled]="!isFloatExist">
                Float
              </mat-checkbox>
            </div>
          </div>
        </div>
      </div>
      <div class="col menu" style="padding: 0;border: 1px;">
        <div class="row" style="height: 100%;">
          <div class="col" style="min-width: 300px; border: 1px solid #b9babb;">
            <div class="menu-item">
              <img class="pushpin" src="../../assets/pushpins/black-circle-pushpin.png">
              <span class="pushpin-label">Current Client - Company Address</span>
            </div>
            <div class="menu-item">
              <img class="pushpin" src="../../assets/pushpins/black-square-pushpin.png">
              <span class="pushpin-label">Current Client - Home Address</span>
            </div>
            <div class="menu-item">
              <img class="pushpin" src="../../assets/pushpins/black-rhombus-pushpin.png">
              <span class="pushpin-label">Company Address</span>
            </div>
            <div class="menu-item">
              <img class="pushpin" src="../../assets/pushpins/black-triangle-pushpin.png">
              <span class="pushpin-label">Home Address</span>
            </div>
          </div>
          <div class="col" style="border: 1px solid #b9babb;">
            <div class="menu-item">
              <div>
                <div class="pushpin circule-pushpin circule-pushpin--color-orange"></div>
              </div>
              <span class="pushpin-label">Client</span>
            </div>
            <div class="menu-item">
              <div>
                <div class="pushpin circule-pushpin circule-pushpin--color-red"></div>
              </div>
              <span class="pushpin-label">Meeting</span>
            </div>
            <div class="menu-item">
              <div>
                <div class="pushpin circule-pushpin circule-pushpin--color-blue"></div>
              </div>
              <span class="pushpin-label">Delivery Meeting</span>
            </div>
            <div class="menu-item">
              <div>
                <div class="pushpin circule-pushpin circule-pushpin--color-light-green"></div>
              </div>
              <span class="pushpin-label">Order Shipped</span>
            </div>
            <div class="menu-item">
              <div>
                <div class="pushpin circule-pushpin circule-pushpin--color-green"></div>
              </div>
              <span class="pushpin-label">Referral</span>
            </div>
            <div class="menu-item">
              <div>
                <div class="pushpin circule-pushpin circule-pushpin--color-pink"></div>
              </div>
              <span class="pushpin-label">Float</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-xl-5 col-lg-12">
    <div>
      <div class="row">
        <div class="col-xl-7 col-lg-12">
          <mat-radio-group aria-label="Select sort method" [(ngModel)]="sortMethod" (change)="onSortChange($event)">
            <mat-radio-button [value]="MapSortTypes.Distance">Sort by Distance</mat-radio-button>
            <mat-radio-button [value]="MapSortTypes.LifetimeSpend">Sort by Lifetime Spend</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="content-wrapper">
        <div class="content">
          <div class="info-table">
            <div class="info-table-header row">
              <div class="col-1 cell">№</div>
              <div class="col-11 cell">Info</div>
            </div>
          </div>
        </div>
        <div>
          <div>
            <div class="info-table">
              @for (item of filterPerson; track item) {
              <div>
                <div class="info-table-content row">
                  <div class="col-1 cell">{{item.mapNumber}}</div>
                  @if (isSearchByGeocode && currentPerson.personId == item.personId) {
                  <div class="col-11 cell">
                    <div class="address" (click)="onSelectChange(item, false);$event.stopPropagation()">{{item.name}}
                    </div>
                  </div>
                  }

                  @if (!(isSearchByGeocode && currentPerson.personId == item.personId)) {
                  <div class="col-11 cell">
                    <a [href]='getDealUrl(item)' target='_blank'>{{item.name}}</a>
                    <div><b>Stage:</b>
                      {{item.stageName}}
                    </div>
                    @if (item.meetingDate) {
                    <div><b>Meeting Date:</b>
                      {{item.meetingDate}}
                    </div>
                    }
                    <div><b>Phones:</b></div>
                    @for (phone of item.phones; track phone) {
                    <div style="padding-left: 10px;">{{phone.value}} ({{phone.label}})
                    </div>
                    }
                    <div><b>Emails:</b></div>
                    @for (email of item.emails; track email) {
                    <div style="padding-left: 10px;">{{email.value}} ({{email.label}})
                    </div>
                    }
                    <div class="address" (click)="onSelectChange(item, false);$event.stopPropagation()"><b>Company:</b>
                      {{item.company}}
                    </div>
                    <div class="address" (click)="onSelectChange(item, false);$event.stopPropagation()"><b>Company
                        Address:</b>
                      {{item.address}}
                    </div>
                    <div class="address" (click)="onSelectChange(item, true);$event.stopPropagation()"><b>Home
                        Address:</b>
                      {{item.homeAddress}}
                    </div>
                    <div><b>Lifetime Spend:</b>
                      ${{ item.clientLifetimeSpend | money }}
                    </div>
                  </div>
                  }
                </div>
              </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
@if (isShowFillMessage && !isSpinner) {
<div class="message">
  Geocode is not found. Please, update the Company address field in Deal and refresh the page.

  Thanks!
</div>
}