import { Component, EventEmitter, Output, ViewChild, Input } from '@angular/core';
import { InputField } from '../../models/InputField';

@Component({
  selector: 'pd-field-full-name',
  templateUrl: './field-full-name.component.html',
  styleUrls: ['./field-full-name.component.scss']
})
export class FieldFullNameComponent {

  @ViewChild("inputFullName") inputFullName;
  @Output() fullNameChange = new EventEmitter<InputField>();
  @Input() fullName: string;

  public changeEdit() {
    let model = {
      value: this.fullName,
      isValid: this.inputFullName.valid
    }
    this.fullNameChange.emit(model);
  }
}
