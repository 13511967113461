import { GlobalConstants } from 'src/app/core/global-constants';
import { UserContextService } from 'src/app/core/services/user-context.service';
import { Component, Inject } from '@angular/core';
import { Subject } from 'rxjs';
import { DealDetails } from 'src/app/core/models/DealDetails';
import { User } from 'src/app/core/models/UserModel';
import { UtilsHelper } from 'src/app/core/utils/utils.helper';
import { RolesEnum } from 'src/app/core/enums/RolesEnum';
import { UsersService } from 'src/app/core/services/users.service';
import * as stages from 'src/assets/stages_new_prospespects';
import { BaseComponent } from 'src/app/core/base.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'pd-custom-meeting',
  templateUrl: './custom-meeting.component.html',
  styleUrls: ['./custom-meeting.component.scss']
})
export class CustomMeetingComponent extends BaseComponent {
  closeModal: any;
  saveDate: any;
  moveLeadsToUser: any;

  minDate = new Date(Date.now());
  maxDate = undefined;
  today = new Date();
  yesterDay = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate());

  ALL_STAGES: any = GlobalConstants.ALL_STAGES;
  RolesEnum: any = RolesEnum;

  meetingDate: Date;
  confirmAction: any;
  isShowUserList = false;
  isConfirm = false;
  dealsPerson: DealDetails;
  prevDealsPerson: DealDetails = new DealDetails();
  stageIdToMoveTo: number;
  pipelineId: number;
  onDestroy$ = new Subject();
  users = [];
  filteredUsers = [];
  currentUser: any;
  sm1UsersListForMoveDeals = [];
  usersListForMoveDeals = [];
  owner: User;
  isAllowedBookingForSelf = false;
  isAllowedBookingForSomeoneElse = false;
  isAllowedBookingSM1ForSelf = false;
  isAllowedBookingSM1ForSomeoneElse = false;
  isAllowAutofill = true;
  isAutofillError = false;
  isSkipHomeAddress = false;
  isShowValidationMessages = false;
  isSpinner = true;
  addressLabel = "Please, write the address"
  public addressValidationTooltip = UtilsHelper.addressValidationMessage;

  primaryBtnText: string = "";
  secondaryBtnText: string = "";

  isPrimaryBtnVisible: boolean = true;
  isSecondaryBtnVisible: boolean = true;

  options = [];

  userFilter: any;
  selectedUser: any;

  constructor(
    private userContextService: UserContextService,
    private usersService: UsersService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<CustomMeetingComponent>) {
    super();
    this.closeModal = data.closeModal;
    this.saveDate = data.saveDate;
    this.moveLeadsToUser = data.moveLeadsToUser;
    this.dealsPerson = data.dealsPerson;

    this.currentUser = this.userContextService.user.value;
    this.openMeetingModal(data.confirmCallback, data.meetingDate, data.stageId)
  }

  private setUserFilter() {
    if (this.dealsPerson && this.dealsPerson.user_id != null) {
      this.userFilter = this.users.find(user => user.id === this.dealsPerson.user_id);
    }
  }

  public async openMeetingModal(confirmCallback, meetingDate: Date, stageId) {
    this.users = await this.usersService.getUsers();
    this.owner = this.users.find(u => u.id === this.dealsPerson.user_id);

    this.filteredUsers = this.users.filter(item => item.role_id !== RolesEnum.EA && item.role_id !== RolesEnum.EaCaller);
    if (this.dealsPerson && this.dealsPerson.pipeline_id === stages.Pipelines.ClothierMeetingConfirm)
      this.filteredUsers = this.filteredUsers.filter(x => x.role_id === RolesEnum.Clothier || x.role_id === RolesEnum.Admin)

    this.isAllowedBookingForSelf = this.currentUser.role_id !== RolesEnum.BB &&
      this.currentUser.role_id !== RolesEnum.DirectMailAccount &&
      this.currentUser.role_id !== RolesEnum.CampaignAccount && (this.owner
        && this.owner.role_id !== RolesEnum.BB
        && this.owner.role_id !== RolesEnum.DirectMailAccount
        && this.owner.role_id !== RolesEnum.CampaignAccount);

    this.isAllowedBookingForSomeoneElse = this.currentUser && this.currentUser.role_id !== RolesEnum.Clothier &&
      this.owner && this.owner.role_id !== RolesEnum.Clothier;

    this.isAllowedBookingSM1ForSelf = this.currentUser.role_id !== RolesEnum.BB &&
      this.currentUser.role_id !== RolesEnum.DirectMailAccount &&
      this.currentUser.role_id !== RolesEnum.CampaignAccount && (this.owner
        && this.owner.role_id !== RolesEnum.BB
        && this.owner.role_id !== RolesEnum.DirectMailAccount
        && this.owner.role_id !== RolesEnum.CampaignAccount);

    this.isAllowedBookingSM1ForSomeoneElse = this.currentUser && this.owner != null;

    this.primaryBtnText = ((this.isAllowedBookingForSomeoneElse && !this.isAllowedBookingForSelf) ||
      (this.isAllowedBookingSM1ForSomeoneElse && !this.isAllowedBookingSM1ForSelf)) ? "Book meeting for clothier" : "for another clothier";
    this.isPrimaryBtnVisible = (!this.isShowUserList && !this.isConfirm &&
      (this.isAllowedBookingForSomeoneElse || this.isAllowedBookingSM1ForSomeoneElse)) || this.isConfirm;

    this.secondaryBtnText = ((this.isAllowedBookingForSelf && !this.isAllowedBookingForSomeoneElse) ||
      (this.isAllowedBookingSM1ForSelf && !this.isAllowedBookingSM1ForSomeoneElse)) ? "Book meeting for current owner" : "for current owner";
    this.isSecondaryBtnVisible = (!this.isShowUserList && !this.isConfirm &&
      (this.isAllowedBookingForSelf || this.isAllowedBookingSM1ForSelf)) || this.isConfirm;

    this.confirmAction = confirmCallback;
    this.meetingDate = meetingDate;

    this.prevDealsPerson.companyAddress = this.dealsPerson.companyAddress;
    this.prevDealsPerson.homeAddress = this.dealsPerson.homeAddress;

    this.setStageUserList(stageId);
    this.setUserFilter();

    this.isSpinner = false;
  }

  public confirm(isConfirm) {
    if (isConfirm) {
      if (!this.validation()) return;
      this.ok();
    } else {
      this.cancel()
    }
    this.dialogRef.close();
  }

  private ok() {
    this.meetingDate = (this.stageIdToMoveTo == GlobalConstants.ALL_STAGES.SM1) ? null : this.meetingDate;
    this.dealsPerson.meetingDateUtc = this.meetingDate;
    this.saveDate({ dealsPerson: this.dealsPerson });
    if (this.confirmAction) {
      this.confirmAction();
    }
  }

  private cancel() {
    this.dealsPerson.companyAddress = this.prevDealsPerson.companyAddress
    this.dealsPerson.homeAddress = this.prevDealsPerson.homeAddress

    this.closeModal();
  }

  moveLeads() {
    if (!this.validation()) return;

    this.meetingDate = (this.stageIdToMoveTo == GlobalConstants.ALL_STAGES.SM1) ? null : this.meetingDate;
    this.dealsPerson.meetingDateUtc = this.meetingDate;
    this.moveLeadsToUser({
      selectedUser: this.selectedUser,
      dealsPerson: this.dealsPerson
    });
    this.dialogRef.close();
  }

  public showMeetingDropdown() {
    if (!this.validation()) return;
    this.isShowUserList = true;
  }

  public selectUserForMove(user) {
    this.userFilter = user;
    this.selectedUser = user;
    this.isConfirm = true;
  }

  public negativeConfirm() {
    this.isShowUserList = false;
    this.isConfirm = false;
  }

  public validation() {
    this.isShowValidationMessages = true;

    return this.meetingDateValidation()
      && this.homeAddressValidation()
      && this.companyAddressValidation();
  }

  public meetingDateValidation() {
    if (this.stageIdToMoveTo == GlobalConstants.ALL_STAGES.SM1 || (this.meetingDate && this.meetingDate >= this.yesterDay)) {
      return true;
    }
    return false;
  }

  public companyAddressValidation() {
    if (this.dealsPerson && ((this.currentUser && this.currentUser.role_id === RolesEnum.BB)
      || (this.isAutofillError || this.checkAddressIsValid(this.dealsPerson.companyAddress)))) {
      return true;
    }

    return false;
  }

  public homeAddressValidation() {
    if (this.dealsPerson
      && ((this.currentUser && this.currentUser.role_id === RolesEnum.BB)
        || (this.isSkipHomeAddress || this.checkAddressIsValid(this.dealsPerson.homeAddress)))
    ) {
      return true;
    }

    return false;
  }

  private checkAddressIsValid(address: string) {
    return UtilsHelper.checkAddressIsValid(address);
  }

  private setStageUserList(stageId: number) {
    this.pipelineId = this.dealsPerson.pipeline_id;
    this.stageIdToMoveTo = stageId
    const pipelineStages = this.pipelineId === stages.NewProspectsPipeline.id ? stages.STAGES_NEW_PROSPECTS_DICT : stages.STAGES_CLIENTS_DICT;
    const pipelineRoles = this.pipelineId === stages.NewProspectsPipeline.id ? stages.PROSPECTS_BY_ROLES : stages.CLIENTS_BY_ROLES;
    const stage = pipelineStages.find(st => st.id === stageId);
    if (stage) {
      const stageName = stage.name;
      const allowedRoles = Object.entries(pipelineRoles).reduce((result, current) => {
        if (current[1].some(stage => stage === stageName)) {
          result.push(RolesEnum[current[0]]);
        }
        return result;
      }, []);
      this.sm1UsersListForMoveDeals = this.users.filter(user => allowedRoles.some(roleId => roleId === user.role_id));
      this.usersListForMoveDeals = this.users.filter(user => allowedRoles.some(roleId => roleId != RolesEnum.BB && roleId === user.role_id));
      if ((this.currentUser.role_id === RolesEnum.DirectMailAccount || this.currentUser.role_id === RolesEnum.BB) && stageId === GlobalConstants.ALL_STAGES.Meeting) {
        this.usersListForMoveDeals = this.usersListForMoveDeals.filter(user => user.id !== this.currentUser.id);
      }

      if (stageId === GlobalConstants.ALL_STAGES.Meeting) {
        if (this.owner && this.owner.role_id === RolesEnum.BB) {
          this.usersListForMoveDeals = this.usersListForMoveDeals.filter(user => user.role_id === RolesEnum.SystemAccount || user.role_id === RolesEnum.Admin || user.role_id === RolesEnum.Clothier);
        }
        else {
          this.usersListForMoveDeals = this.usersListForMoveDeals.filter(user => user.role_id === RolesEnum.Admin || user.role_id === RolesEnum.Clothier);
        }
      }
    }
  }
}