import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { AvatarOption } from '../../models/avatar-models/AvatarOption';
import { AvatarTypes } from '../../enums/avatar/AvatarTypes';
import { AvatarBodyTypes } from '../../enums/avatar/AvatarBodyTypes';
import { AvatarSkinTypes } from '../../enums/avatar/AvatarSkinTypes';
import { AvatarCustomizationConfig } from '../../models/avatar-models/AvatarCustomizationConfig';
import { AvatarBuilderData } from '../../models/avatar-models/AvatarBuilderData';
import { HttpRestService } from '../http-client/http-rest-client.service';

@Injectable()
export class AvatarBuilderService extends BaseService {
  constructor(private restService: HttpRestService) {
    super();
  }

  public async checkOptionKit(avatarBuilderData: AvatarBuilderData): Promise<AvatarOption[]> {
    const url = '/avatarBuilder/checkOptionKit/';
    const data = avatarBuilderData;
    return await this.handleResponse(this.restService.post({ url, data }));
  }

  public async getAvatarCustomizationConfig(): Promise<AvatarCustomizationConfig> {
    const url = '/avatarBuilder/getAvatarCustomizationConfig';
    return await this.handleResponse(this.restService.get({ url }));
  }

  public async getAvatarBuilderDataDef(): Promise<AvatarBuilderData> {
    const url = '/avatarBuilder/getAvatarBuilderData';
    return await this.handleResponse(this.restService.get({ url }));
  }

  public async getAvatarBuilderData(id: number): Promise<AvatarBuilderData> {
    const url = '/avatarBuilder/getAvatarBuilderData/' + id;
    return await this.handleResponse(this.restService.get({ url }));
  }

  public async getAvatarSvg(id: number): Promise<string> {
    const url = '/avatarBuilder/getAvatarSvg/' + id;
    return await this.handleResponse(this.restService.get({ url }));
  }

  public async getAvatarSvgByOptions(avatarBuilderData: AvatarBuilderData): Promise<string> {
    const url = '/avatarBuilder/getAvatarSvgByOptions';
    const data = avatarBuilderData;
    return await this.handleResponse(this.restService.post({ url, data }));
  }

  public async reloadAvatarCache(): Promise<boolean> {
    const url = '/avatarBuilder/reloadAvatarCache';
    return await this.handleResponse(this.restService.get({ url }));
  }

  public async reloadAllAvatars(): Promise<boolean> {
    const url = '/avatarBuilder/reloadAllAvatars';
    return await this.handleResponse(this.restService.get({ url }));
  }

  public async downloadAvatarSvgByOptions(avatarBuilderData: AvatarBuilderData): Promise<string> {
    const url = '/avatarBuilder/downloadAvatarSvgByOptions';
    const data = avatarBuilderData;
    return await this.handleResponse(this.restService.post({ url, data }));
  }

  public async generateOptionHead(base64: string, avatarTypeId: AvatarTypes, bodyTypeId: AvatarBodyTypes, skinTypeId: AvatarSkinTypes): Promise<AvatarOption> {
    const url = '/avatarBuilder/generateOptionHead';
    const data = {
      avatarTypeId: avatarTypeId,
      bodyTypeId: bodyTypeId,
      skinTypeId: skinTypeId,
      base64: base64
    }
    return await this.handleResponse(this.restService.post({ url, data }));
  }

  public async getOptionGlobalSetting(avatarOption: AvatarOption): Promise<AvatarOption> {
    const url = '/avatarBuilder/getOptionGlobalSetting';
    const data = avatarOption;

    return await this.handleResponse(this.restService.post({ url, data }));
  }
}
