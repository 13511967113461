@if (deals && deals.length > 0) {
<div id="print-pdf-section">
  <div style="margin-left: 10px;" class="header row">
    <div style="border-left: 1px solid #b9babb;" class="col-1 border-right sort">Status</div>
    <div class="col-1 border-right sort">Name</div>
    <div class="col-2 border-right sort">Company</div>
    <div class="col-2 border-right sort">Contacts</div>
    <div class="col-2 border-right sort">Company Address</div>
    @if (exportType === ExportDealsType.Referrals) {
    <div class="col-2 border-right sort">Referred By</div>
    }
    <div style="flex: 1;" class="border-right sort">Notes</div>
  </div>
  <div style="margin-left: 10px;" class="table" id="id" #container>
    @for (deal of deals; track deal; let i = $index) {
    <div class="content-row row">
      <div style="border-left: 1px solid #b9babb;" class="title text col-1 border-right title-link">
        {{deal.stage_name}}
      </div>
      <div class="text col-1">
        {{deal.title}}
      </div>
      <div class="text col-2">
        {{deal.company}}
      </div>
      <div class="text col-2">
        @for (item of deal.phone; track item) {
        <div>
          <div>{{item.value}}</div>
        </div>
        }
        @for (item of deal.email; track item) {
        <div>
          <div>{{item.value}}</div>
        </div>
        }
      </div>
      <div class="text col-2">
        {{deal.companyAddress}}
      </div>
      @if (exportType === ExportDealsType.Referrals) {
      <div class="text col-2">
        {{deal.personWhoReferred}}
      </div>
      }
      @if (deal.noteList && deal.noteList.length > 0) {
      <div style="flex: 1;" class="border-right sort text">
        @for (note of deal.noteList; track note; let i = $index) {
        <div>
          @if (i < 2) { <div> <!-- top 2 items -->
            <div>{{note.created | date :'dd-MMM-yyyy h:mm a'}}</div>
            <div>{{note.createdByName}}:</div>
            <p style="white-space: pre-line">{{note.text}}</p>
            <div>&nbsp;</div>
        </div>
        }
      </div>
      }
    </div>
    }
    @if (!(deal.noteList && deal.noteList.length > 0)) {
    <div style="flex: 1;" class="border-right sort text">
      {{deal.notes}}
    </div>
    }
  </div>
  }
</div>
</div>
}