import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'pd-field-linkedin-url',
  templateUrl: './field-linkedin-url.component.html',
  styleUrls: ['./field-linkedin-url.component.scss']
})
export class FieldLinkedinUrlComponent implements OnInit {

  public url: string;
  @Output() linkedinUrlChange = new EventEmitter<string>();
  public isEdit = false;
  @Input() isUrlExists = false;

  constructor() { }

  ngOnInit() {
  }

  /**
    * Change edit
    * in deal
    * @memberof FieldLinkedinUrlComponent
    */

  public changeEdit() {
    this.linkedinUrlChange.emit(this.url);
  }

  /**
    * Add value
    * open edit
    * @memberof FieldLinkedinUrlComponent
    */

  public addValue() {
    this.isEdit = true;
  }

}
