import { ObjectUtil } from "../../utils/object.util";

export class PaymentTransactionInfo {
    transactionId: string;
    transactionMessage: string;

    constructor(data?: any) {
        ObjectUtil.extend(data, this);
    }
}
