import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { HttpRestService } from '../http-client/http-rest-client.service';
import { BaseService } from '../base.service';
import { AchievementCardConfig } from '../../models/AchievementCardConfig';
import { AchievementCardModel } from '../../models/AchievementCardModel';

@Injectable()
export class AchievementCardApiService extends BaseService {

    constructor(private restService: HttpRestService) {
        super();
    }

    public getAchievementCardConfig(): Observable<AchievementCardConfig> {
        return from(this.handleResponse(this.restService.get({
            url: `/achievement/getAchievementCardConfig`
        })));
    }

    public getAchievementCardsByOwnerId(ownerId: number): Observable<Array<AchievementCardModel>> {
        return from(this.handleResponse(this.restService.get({
            url: `/achievement/achievementCardsByOwnerId/${ownerId}`
        })));
    }

    public getAchievementQrCodeDataByUserId(userId: number): Observable<any> {
        return from(this.handleResponse(this.restService.get({
            url: `/achievement/getAchievementQrCodeDataByUserId/${userId}`
        })));
    }

    public updateAchievementCard(achievementCard: AchievementCardModel): Observable<AchievementCardModel> {
        return from(this.handleResponse(this.restService.post({
            url: `/achievement/achievementCard`,
            data: achievementCard
        })));
    }

    public updateAchievementCardGroup(achievementCard: AchievementCardModel): Observable<AchievementCardModel> {
        return from(this.handleResponse(this.restService.post({
            url: `/achievement/achievementCardGroup`,
            data: achievementCard
        })));
    }

    public deleteAchievementCard(achievementCardId: number): Observable<boolean> {
        return from(this.handleResponse(this.restService.delete({
            url: `/achievement/achievementCard/${achievementCardId}`
        })));
    }

    public deleteAchievementCardGroup(achievementCardId: number): Observable<boolean> {
        return from(this.handleResponse(this.restService.delete({
            url: `/achievement/achievementCardGroup/${achievementCardId}`
        })));
    }

    // public async clientCardPdf(file: any, clientCardId: any): Promise<any> { 
    //     const formData: FormData = new FormData();
    //     formData.append('file', file, file.name);
    //     formData.append('clientCardId', clientCardId);
    //     const url = '/clientCard/upload-pdf';

    //     return this.handleResponse(this.restService.upload({
    //         url: url,
    //         data: formData
    //     }));
    // }
}