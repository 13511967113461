import { PipelineDealsStoreService } from './../../../core/services/stores/pipeline-deal-store.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, EventEmitter, Output, HostListener } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'pd-custom-modal',
  templateUrl: './custom-modal.component.html',
  styleUrls: ['./custom-modal.component.scss'],
  animations: [
    trigger('showModal', [
      state('in', style({
        transform: 'translate(0,0)'
      })),
      state('out', style({
        transform: 'translate(100%, 0)'
      })),
      transition('out => in', animate('400ms ease-in-out')),
      transition('in => out', animate('400ms ease-in-out'))
    ])
  ]
})
export class CustomModalComponent {
  public title = '';
  public spinner = false;
  public messageToShow = null;
  @Input() showModal = 'out';
  public countDeals: number;
  public fromNumber: number;
  public limitNumber: number;
  public limitationValue: number;

  @Input() titleInput = '';
  @Input() maxPossibleLimit: number = 0;

  @Input() isRange: boolean = false;
  @Output() onSubmitModal = new EventEmitter<object>();
  @Output() onCloseModal = new EventEmitter<object>();

  constructor(public router: Router, private pipelineDealsStoreService: PipelineDealsStoreService) { }

  public onSubmit() {
    if(this.countDeals || (this.fromNumber && this.limitNumber)) {
      this.onSubmitModal.emit({count: this.countDeals, from: this.fromNumber, limit: this.limitNumber});
      this.onCancel();
      this.countDeals = null;
      this.fromNumber = null;
      this.limitNumber = null;
      this.onCloseModal.emit();
    }
  }

  public openModal() {
    const dealsCountAvailiable = this.pipelineDealsStoreService.additionalData.value.total_count;
    this.limitationValue = this.maxPossibleLimit < dealsCountAvailiable &&  this.maxPossibleLimit != null && this.maxPossibleLimit || dealsCountAvailiable || 0;
    this.title = this.titleInput + ' (max ' + this.limitationValue + ')';
    this.showModal = 'in';
  }

  public onCancel() {
    this.spinner = false;
    this.showModal = 'out';
    this.countDeals = null;
    this.messageToShow = null;
  }


  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    event.preventDefault();
    const pastedInput: string = event.clipboardData
      .getData('text/plain')
      .replace(/\D/g, ''); // get a digit-only string
    document.execCommand('insertText', false, pastedInput);
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent) {
    if (
      // Allow: Delete, Backspace, Tab, Escape, Enter
      [46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1 || 
      (e.keyCode === 65 && e.ctrlKey === true) || // Allow: Ctrl+A
      (e.keyCode === 67 && e.ctrlKey === true) || // Allow: Ctrl+C
      (e.keyCode === 86 && e.ctrlKey === true) || // Allow: Ctrl+V
      (e.keyCode === 88 && e.ctrlKey === true) || // Allow: Ctrl+X
      (e.keyCode === 65 && e.metaKey === true) || // Cmd+A (Mac)
      (e.keyCode === 67 && e.metaKey === true) || // Cmd+C (Mac)
      (e.keyCode === 86 && e.metaKey === true) || // Cmd+V (Mac)
      (e.keyCode === 88 && e.metaKey === true) || // Cmd+X (Mac)
      (e.keyCode >= 35 && e.keyCode <= 39) // Home, End, Left, Right
    ) {
      return;
    }

    if (
      (e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) &&
      (e.keyCode < 96 || e.keyCode > 105)
    ) {
      e.preventDefault();
    }
  }
}
