import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpRestService } from './http-client/http-rest-client.service';

@Injectable()
export class ExportService extends BaseService {

  constructor(private restService: HttpRestService) {
    super();
  }

  public async exportDeals(stageIds, countDeals, locations, userIds, companies, positions, isSameBuildingFilter, isWithPhoneNumberOnlyFilter): Promise<Blob> {
    let data = { StageIds: stageIds, Count: countDeals, Locations: locations, Companies: companies, Positions: positions, IsSameBuildingFilter: isSameBuildingFilter, IsWithPhoneNumberOnlyFilter: isWithPhoneNumberOnlyFilter, AccountIds: userIds };
    return await this.handleResponse(this.restService.post({ url: '/test/deal/exportDeals', responseType: 'blob', data }));
  }
}
