<div class="row wrapper">
  <div class="col-4 title">
    <span style="color: red; font-size: 20px">*</span> Stage
  </div>
  <div class="col-8 work-field">
    <div class="edit-gender">
      <div class="select">
        <mat-form-field class="stage-select-global stage-select">
          <mat-select [(value)]="currentStageId" (selectionChange)="changeValue($event)"
            (keydown)="$event.stopPropagation()">
            @for (pipeline of pipelines; track pipeline) {
            <mat-optgroup style="padding: 0px;" [label]="pipeline.name">
              @for (stage of pipeline.name === newProspectsStageName ? (pipeline.stages | masterLead: filterRole) :
              pipeline.stages; track stage) {
              <mat-option [value]="stage.id">
                {{ stage.name }}
              </mat-option>
              }
            </mat-optgroup>
            }
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
</div>
<pd-custom-referral-modal #referralModal (saveWhoRef)="saveWhoReferred($event)"
  (closeModal)="closeReferralModal()"></pd-custom-referral-modal>