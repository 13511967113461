import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseComponent } from 'src/app/core/base.component';

@Component({
  selector: 'pd-custom-link-modal',
  templateUrl: './custom-link-modal.component.html',
  styleUrls: ['./custom-link-modal.component.scss']
})

export class CustomLinkModalComponent extends BaseComponent {
  public customLinkChange: any;
  public isShowValidationMessages = false;

  public label: string;
  public link: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any
    , private dialogRef: MatDialogRef<CustomLinkModalComponent>) {
    super();
    console.log(data);
    if (data) {
      this.label = data.label;
      this.link = data.link;
      this.customLinkChange = data.customLinkChange;
    }
  }

  public confirm() {
    if (!this.validation()) return;
    this.customLinkChange({ label: this.normalize(this.label), link: this.getLink(this.normalize(this.link)) });
    this.dialogRef.close();
  }

  public cancel() {
    this.dialogRef.close();
  }

  public validation() {
    this.isShowValidationMessages = true;
    return this.modalValidation();
  }

  public modalValidation() {
    return this.checkLabelIsValid() && this.checkLinkIsValid();
  }

  public checkLabelIsValid() {
    const spaceCheck = this.checkAllEmpty() || (this.label && (this.label.replace(/\s/g, '').length) > 0);
    return spaceCheck;
  }

  public checkLinkIsValid() {
    const spaceCheck = this.checkAllEmpty() || (this.link && (this.link.replace(/\s/g, '').length) > 0);
    return spaceCheck;
  }

  private checkAllEmpty() {
    return !this.label && !this.link;
  }

  private normalize(value: string) {
    if (value) {
      return value.trim();
    }
    return value;
  }

  private getLink(link: string) {
    if (link.indexOf("http://") == -1 && link.indexOf("https://") == -1) {
      return "https://" + link;
    }
    return link;
  }
}