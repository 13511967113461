<div class="wrapper">
  <div class="header">
    <h2>Hotlist</h2>
  </div>
  <div class="content-wrapper">
    <div class="table" id="print-pdf-section">
      <div class="table-wrapper">
        <div class="table-content">
          @for (deal of deals; track deal; let i = $index) {
          <div class="position-relative">
            @if (i % 4 == 0) {
            <div class="content-row">
              @if (i+1 <= deals.length) { <div class='col-md-3 container' [ngClass]="{'bold': deals[i].isClient}">
                <div class='cell'>
                  <mat-checkbox class="hotlist-checkbox" (click)="selectUser(deals[i])"></mat-checkbox>
                </div>
                <div class='cell'>
                  <div>{{deals[i].name}}</div><br>
                  <div>{{deals[i].company}}</div><br>
                  <div>{{deals[i].city}}</div>
                </div>
            </div>
            }
            @if (i+2 <= deals.length) { <div class='col-md-3 container' [ngClass]="{'bold': deals[i+1].isClient}">
              <div class='cell'>
                <mat-checkbox class="hotlist-checkbox" (click)="selectUser(deals[i+1])"></mat-checkbox>
              </div>
              <div class='cell'>
                <div>{{deals[i+1].name}}</div><br>
                <div>{{deals[i+1].company}}</div><br>
                <div>{{deals[i+1].city}}</div>
              </div>
          </div>
          }
          @if (i+3 <= deals.length) { <div class='col-md-3 container' [ngClass]="{'bold': deals[i+2].isClient}">
            <div class='cell'>
              <mat-checkbox class="hotlist-checkbox" (click)="selectUser(deals[i+2])"></mat-checkbox>
            </div>
            <div class='cell'>
              <div>{{deals[i+2].name}}</div><br>
              <div>{{deals[i+2].company}}</div><br>
              <div>{{deals[i+2].city}}</div>
            </div>
        </div>
        }
        @if (i+4 <= deals.length) { <div class='col-md-3 container' [ngClass]="{'bold': deals[i+3].isClient}">
          <div class='cell'>
            <mat-checkbox class="hotlist-checkbox" (click)="selectUser(deals[i+3])"></mat-checkbox>
          </div>
          <div class='cell'>
            <div>{{deals[i+3].name}}</div><br>
            <div>{{deals[i+3].company}}</div><br>
            <div>{{deals[i+3].city}}</div>
          </div>
      </div>
      }
    </div>
    }
  </div>
  }
</div>
</div>
</div>
<div class="buttons">
  <button class="btn btn-success save" (click)="saveDeals()" [disabled]="personsHotlist.length === 0 || buttonSpinner">
    @if (buttonSpinner) {
    <div class="buttonSpinner">
      <mat-spinner [diameter]="20"></mat-spinner>
    </div>
    }
    Save
  </button>
  <button class="btn btn-primary" (click)="print()">Print</button>
  @if (!isAccessByToken) {
  <div class="generateToken">
    <button type="button" class="btn btn-primary waves-light" (click)="generateToken();$event.stopPropagation()">Copy
      link</button>
    @if (tooltip) {
    <span class="pop-up">Copied!</span>
    }
  </div>
  }
</div>
</div>
</div>

@if (clientMessage && isAccessByToken) {
<div class="client-message">{{clientMessage}}</div>
}