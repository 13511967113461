import { Component, OnInit, ViewChild } from '@angular/core';
import { DateHelper } from '../core/utils/date.helper';
import { Tab } from '../core/models/tab.model';
import { TemplateVariableContentTypes } from '../core/enums/TemplateVariableContentTypes';
import { BulkMessageSettingUpdateDto } from '../core/models/dto/BulkMessageSettingUpdateDto';
import { MessageTemplateViewModel } from '../core/models/message-template/MessageTemplateViewModel';
import { MessageTemplateService } from '../core/services/message-template.service';
import { BulkMessageSettingApiService } from '../core/services/bulk-message/bulk-message-setting-api.service';
import { BulkMessageSettingViewModel } from '../core/models/bulk-message/BulkMessageSettingsViewModel';
import { BulkMessageFilterComponent } from './bulk-message-filter/bulk-message-filter.component';

@Component({
  selector: 'pd-bulk-message-settings',
  templateUrl: './bulk-message-settings.component.html',
  styleUrls: ['./bulk-message-settings.component.scss']
})
export class BulkMessageSettingsComponent implements OnInit {
  @ViewChild("filter") public filter: BulkMessageFilterComponent;

  settings: Array<BulkMessageSettingViewModel>;
  TemplateVariableType = TemplateVariableContentTypes;
  public templates: MessageTemplateViewModel[];

  public DateHelper = DateHelper;
  public spinner: boolean = false;
  public isCountResponseWait: boolean = false;

  public selectedSetting: any;
  private deleteCollectionMessage = "Do you confirm deletion of this setting?"

  tabs = [
    new Tab({ isActive: true, name: "Bulk Message Rules", id: 1 }),
    new Tab({ isActive: false, name: "Archive", id: 2 })
  ];

  constructor(
    private bulkMessageSettingService: BulkMessageSettingApiService,
    private messageTemplateService: MessageTemplateService
  ) {
  }

  public async ngOnInit() {
    this.spinner = true;

    this.templates = await this.messageTemplateService.getTemplates();
    this.getSettings();

    this.spinner = false;
  }

  public getNonArchivedSettings(): Array<BulkMessageSettingViewModel> {
    return this.settings?.filter(x => !x.isArchived);
  }

  public getArchivedSettings(): Array<BulkMessageSettingViewModel> {
    return this.settings?.filter(x => x.isArchived);
  }
  
  public onTabClick(tab) {
    this.tabs.find(x => x.isActive == true).isActive = false;
    tab.isActive = true;
  }

  private async getSettings(): Promise<void> {
    this.spinner = true;
    this.settings = await this.bulkMessageSettingService.getBulkMessageSettings();
    this.selectedSetting = null;
    this.spinner = false;
  }

  public async onAdd(): Promise<void> {
    this.spinner = true;

    const data = this.getSettingToAdd();
    await this.bulkMessageSettingService.addBulkMessageSetting(data)
    await this.getSettings();

    this.spinner = false;
  }

  public async onRemove(event): Promise<void> {
    if (!confirm(this.deleteCollectionMessage)) {
      return;
    }
    await this.bulkMessageSettingService.removeBulkMessageSetting(event.settingId);
    await this.getSettings();
  }

  public async onArchive(event): Promise<void> {
    console.log('archive', event);
    await this.bulkMessageSettingService.archiveBulkMessageSetting(event.settingId, event.value);
    await this.getSettings();
  }

  public async onSelectSetting(setting): Promise<void> {
    this.selectedSetting = setting;
  }

  public onClosed() {
    this.selectedSetting = null;
    this.getSettings();
  }

  addDays(date: Date, days: number): Date {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  private getSettingToAdd(): BulkMessageSettingUpdateDto {
    const filterData = this.filter.getFilterData();

    const data = new BulkMessageSettingUpdateDto()
    data.clientRatings = filterData.clientRatings;
    data.pipelines = filterData.pipelines;
    data.ownerIds = filterData.ownerIds;
    data.locations = filterData.locations;

    return data;
  }
}