<div class="col">
    <div [ngClass]="{'visible': !spinner, 'hidden': spinner }">
        <div>
            <div class="requests-table">
                <div class="requests-table-label">
                    Clothier's requests
                </div>
                <div class="requests-table-header row">
                    <div class="col-1 cell">ID of request</div>
                    <div class="col-1 cell">
                        <div class="data-filter">
                            <pd-custom-select-without-lazy #statusFilter (loadDataEvent)="getStatusFilterData()"
                                (stateUpdatedEvent)="filterData()" (autoCompleteEvent)="getStatusAutocomplete($event)"
                                [dataLabel]="statusLabel" [startLabel]="statusLabel" [isMultiple]="true"
                                [isFixedStartLabel]="true">
                            </pd-custom-select-without-lazy>
                        </div>
                    </div>
                    <div class="col cell">Request Info</div>
                    <div class="col-2 cell">Request Date</div>
                    @if (isRequestsManager) {
                    <div class="col-2 cell">
                        <div class="data-filter">
                            <pd-custom-select-without-lazy #userFilter (loadDataEvent)="getUsersFilterData()"
                                (stateUpdatedEvent)="filterData()" (autoCompleteEvent)="getUsersAutocomplete($event)"
                                [dataLabel]="usersLabel" [startLabel]="usersLabel" [isMultiple]="true"
                                [isFixedStartLabel]="true">
                            </pd-custom-select-without-lazy>
                        </div>
                    </div>
                    }
                    @if (isRequestsManager) {
                    <div class="col-2 cell">Actions</div>
                    }
                </div>
            </div>
        </div>
        <div class="content-wrapper">
            <div class="content">
                <div class="requests-table">
                    @for (userRequest of this.filteredUserRequests; track userRequest) {
                    <div class="requests-table-content row" [ngStyle]="getStatusColor(userRequest)">
                        <div class="col-1 cell">{{userRequest.id}}</div>
                        <div class="col-1 cell">{{EnumUtil.getRequestStatusViewLabel(userRequest.statusId)}}</div>
                        <div class="col cell" [innerHTML]="parseNearbyImportDealsRequest(userRequest.requestInfoJson)">
                        </div>
                        <div class="col-2 cell">{{userRequest.created| date :'dd-MMM-yyyy h:mm a'}}</div>
                        @if (isRequestsManager) {
                        <div class="col-2 cell">{{userRequest.madeRequestUserName}}</div>
                        }
                        @if (isRequestsManager) {
                        <div class="col-2 cell">
                            <button class="btn btn-primary waves-light"
                                (click)="selectRequest(userRequest);openInput()">Upload file</button>
                        </div>
                        }
                    </div>
                    }
                </div>
            </div>
        </div>
    </div>

    @if (spinner) {
    <div class="spinner">
        <mat-spinner></mat-spinner>
    </div>
    }
    <input #fileInput type="file" class="upload" accept=".csv" (change)="changeListener($event.target.files)"
        style="display: none">
</div>