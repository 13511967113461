import { ValueMode } from './../../enums/ValueMode';
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Stage } from "../../models/Stage";
import * as stages from '../../../../assets/stages_new_prospespects';
import { RoleStageFilter } from "../../models/RoleStageFilter";
import { RolesEnum } from '../../enums/RolesEnum';
import { User } from '../../models/UserModel';
import { Deal } from '../../models/Deal';

@Injectable()
export class StageFeatureStoreService {
    public readonly allStage = new BehaviorSubject<Stage[]>(null);
    public readonly selectedPipeline = new BehaviorSubject<number>(null);
    public readonly getStagesForCurrentRoleAndCurrentPipeline = new BehaviorSubject<Stage[]>(null);
    public readonly getSelectedRole = new BehaviorSubject<RoleStageFilter>(null);
    public readonly getCurrentStages = new BehaviorSubject<Stage[]>(null);

    public reset() {
        console.log('StageFeatureStoreService reset');
        this.allStage.next(null);
        this.selectedPipeline.next(null);
        this.getStagesForCurrentRoleAndCurrentPipeline.next(null);
        this.getSelectedRole.next(null);
        this.getCurrentStages.next(null);
    }

    public setAllStage(value: Stage[]) {
        console.log('StageFeatureStoreService setAllStage', value);
        this.allStage.next(value.sort((a, b) => this.sortComparer(a, b)));
    }
    public removeAllStage() {
        console.log('StageFeatureStoreService removeAllStage');
        this.allStage.next([]);
    }

    public setCurrentStage(value: Stage[]) {
        console.log('StageFeatureStoreService setCurrentStage', value);
        this.getCurrentStages.next(value.sort((a, b) => this.sortComparer(a, b)));
    }
    
    public removeCurrentStage() {
        console.log('StageFeatureStoreService removeCurrentStage');
        this.getCurrentStages.next(null);
    }

    public setSelectedPipeline(value: number) {
        console.log('StageFeatureStoreService setSelectedPipeline', value);
        this.selectedPipeline.next(value);
        let selectedStages = [];
        if (this.getSelectedRole.value) {
            selectedStages = this.allStage.value.filter(s => (
                this.selectedPipeline.value === stages.Pipelines.ClothierMeetingConfirm ||
                stages.STAGES_BY_ROLES_VISIBILITY[this.getSelectedRole.value.role].some(st => st === s.name))
                && this.selectedPipeline.value === s.pipeline_id);
        }
        this.getCurrentStages.next(selectedStages.sort((a, b) => this.sortComparer(a, b)));
        this.getStagesForCurrentRoleAndCurrentPipeline.next(selectedStages.sort((a, b) => this.sortComparer(a, b)));
    }

    public setSelectedRole(value: RoleStageFilter) {
        console.log('StageFeatureStoreService setSelectedRole', value);
        this.getSelectedRole.next(value);
        let selectedStages = this.allStage.value.filter(s => (this.selectedPipeline.value === stages.Pipelines.ClothierMeetingConfirm ||
            stages.STAGES_BY_ROLES_VISIBILITY[value.role].some(st => st === s.name))
            && this.selectedPipeline.value === s.pipeline_id);

        this.getCurrentStages.next(selectedStages.sort((a, b) => this.sortComparer(a, b)));
        this.getStagesForCurrentRoleAndCurrentPipeline.next(selectedStages.sort((a, b) => this.sortComparer(a, b)));
    }

    public selectStageToPipelineDealBlockedStages(allPipelineDealItems: Deal[], additionalData: any, currentUser: User) {
        console.log('StageFeatureStoreService selectStageToPipelineDealBlockedStages', allPipelineDealItems, additionalData, currentUser);
        if (!currentUser) {
            return;
        }
        return this.allStage.value.reduce((result, current) => {
            if ((currentUser.role_id === RolesEnum.EA || currentUser.role_id === RolesEnum.EaCaller) && allPipelineDealItems
                && current.pipeline_id === stages.Pipelines.ClothierContactClients) {
                result.push(current)
                return result
            }
            let storeMinStage = additionalData && additionalData.minStage && additionalData.minStage.stage_id && this.allStage.value.find(s => s.id === additionalData.minStage.stage_id) || null
            if (storeMinStage && storeMinStage.not_blocking_stages && storeMinStage.not_blocking_stages.length > 0 && ((!currentUser.is_admin || currentUser.role_id === RolesEnum.EA || currentUser.role_id === RolesEnum.EaCaller) &&
                (!storeMinStage.not_blocking_stages.some(s => s === current.id)) && storeMinStage.order_nr > current.order_nr)) {
                result.push(current)
                return result
            } else if (additionalData && additionalData.min_stage != null
                && additionalData.min_stage.stage_order_nr > current.order_nr
                && (additionalData.min_stage.unlockedStageIds != null && additionalData.min_stage.unlockedStageIds.indexOf(current.id) < 0)) {
                result.push(current)
                return result
            }
            return result
        }, []);
    }

    private sortComparer(a: Stage, b: Stage) {
        if (a.pipeline_id > b.pipeline_id) {
            return 1;
        }
        else if (a.pipeline_id === b.pipeline_id) {
            if (a.pipeline_id === stages.OpenOrders.id) {
                if (a.order_nr < b.order_nr) {
                    return 1;
                }
                else {
                    return -1;
                }
            }

            if (a.order_nr > b.order_nr) {
                return 1;
            }
            else {
                return -1;
            }
        }
        else {
            return -1;
        }
    }
}
