import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Role } from '../models/Role';
import { HttpRestService } from './http-client/http-rest-client.service';


@Injectable()
export class RoleService extends BaseService {

	constructor(private restService: HttpRestService) {
		super();
	}

	public async getAll(): Promise<Role[]> {
		return await this.handleResponse(this.restService.get({ url: '/role/getAll' }));
	}
}
