import { NgModule } from '@angular/core';
import { AvatarGrouppedExpansionPanelComponent } from './avatar-groupped-expansion-panel/avatar-groupped-expansion-panel.component';
import { AvatarExpansionPanelComponent } from './avatar-expansion-panel/avatar-expansion-panel.component';
import { AvatarCardTileComponent } from './avatar-card-tile/avatar-card-tile.component';
import { ClientCardExpansionPanelComponent } from './client-card-expansion-panel/client-card-expansion-panel.component';
import { ClientCardComponent } from './client-card.component';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';

@NgModule({
    imports: [
        CommonModule,
        SharedModule
    ],
    declarations: [
        ClientCardComponent,
        ClientCardExpansionPanelComponent,
        AvatarGrouppedExpansionPanelComponent,
        AvatarExpansionPanelComponent,
        AvatarCardTileComponent,
    ],
    exports: [
        ClientCardComponent,
        ClientCardExpansionPanelComponent,
        AvatarGrouppedExpansionPanelComponent,
        AvatarExpansionPanelComponent,
        AvatarCardTileComponent,
    ]
})
export class ClientCardModule { }
