import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { UserRequestPanelComponent } from './user-request-panel.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule
    ],
    declarations: [
        UserRequestPanelComponent
    ],
    exports: [
        UserRequestPanelComponent
    ]
})
export class UserRequestPanelModule { }
