import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SharedModule } from "../shared/shared.module";
import { FieldConfirmPasswordComponent } from "./fields/field-confirm-password/field-confirm-password.component";
import { FieldEmailComponent } from "./fields/field-email/field-email.component";
import { FieldFullNameComponent } from "./fields/field-full-name/field-full-name.component";
import { FieldPasswordComponent } from "./fields/field-password/field-password.component";
import { FieldReferralLinkComponent } from "./fields/field-referral-link/field-referral-link.component";
import { FieldTypeOfClothierComponent } from "./fields/field-type-of-clothier/field-type-of-clothier.component";
import { FieldRoleComponent } from "./fields/field-role/field-role.component";
import { NewUserComponent } from "./new-user/new-user.component";
import { UsersComponent } from "./users.component";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from "@angular/material/core";
import { MatInputModule } from "@angular/material/input";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatCheckboxModule } from "@angular/material/checkbox";

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        SharedModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatSelectModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatInputModule,
        MatProgressSpinnerModule,
        MatCheckboxModule
    ],
    declarations: [
        FieldConfirmPasswordComponent,
        FieldEmailComponent,
        FieldFullNameComponent,
        FieldPasswordComponent,
        FieldReferralLinkComponent,
        FieldTypeOfClothierComponent,
        FieldRoleComponent,
        NewUserComponent,
        UsersComponent,
    ],
    providers: [
        MatDatepickerModule
    ],
    exports: [
        UsersComponent,
        NewUserComponent
    ]
})
export class UsersModule { }