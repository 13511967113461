export class AchievementCardModel {
  public id: number;
  public ownerId: number;

  public clientId: number;
  public orderId: number;

  public groupId: string;

  public customTitle: string;
  public customImageUrl: string;

  public achievementGroupId: number;
  public achievementGroupName: string;

  public achievementCardTypeId: number;
  public achievementCardTypeName: string;

  public date: Date;

  public clientName: string;
  public amount?: number;
  public link: string;

  public isBestMonth: boolean;

  constructor(id: number
    , ownerId: number
    , achievementGroupId: number
  ) {
    this.id = id;
    this.ownerId = ownerId;
    this.achievementGroupId = achievementGroupId;
  }
}