<div class="row wrapper">
  <div class="col-4 title">
    <span style="color: red; font-size: 20px">*</span> Email
  </div>
  <div class="col-8 work-field">
    <div class="md-form" (document:focusout)="changeEdit()" (document:focusin)="changeEdit()">
      <input [(ngModel)]="email" mdbActive type="text" required #inputEmail="ngModel">
      @if (inputEmail.invalid && inputEmail.touched) {
      <span style="color: red">Enter correct email</span>
      }
    </div>
  </div>
</div>