import { ObjectUtil } from "../../utils/object.util";

export class AvatarCollection {
    id: number;
    collectionName: string;
    isSeasonalProposal: boolean;
    isStylesAvailable: boolean;
    isGroupedView: boolean;
    created: Date;
    createdBy: number;
    modified: Date;
    modifiedBy: number;

    constructor(data?: any) {
        ObjectUtil.extend(data, this);
    }
}
