export class FileUtil {
    public static isImage(contentType: string) {
        return (contentType == 'image/png'
            || contentType == 'image/jpeg'
            || contentType == 'image/webp'
        )
    }

    public static isVideo(contentType: string) {
        return contentType == 'video/mp4'
    }

    public static isAudio(contentType: string) {
        return contentType == 'audio/ogg'
    }

    public static getBase64String(media) {
        return (media.base64) ? `data:${media.contentType};base64,${media.base64}` : media.url;
    }

    public static fileSizeToView(bytes, si = false, dp = 1) {
        const thresh = si ? 1000 : 1024;

        if (Math.abs(bytes) < thresh) {
            return bytes + ' B';
        }

        const units = si
            ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
            : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
        let u = -1;
        const r = 10 ** dp;

        do {
            bytes /= thresh;
            ++u;
        } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


        return bytes.toFixed(dp) + ' ' + units[u];
    }
}