import { UserContextService } from './../../core/services/user-context.service';
import { Component, OnInit, Input, OnDestroy, ViewChild, ElementRef, Output, EventEmitter } from "@angular/core";
import { RolesEnum } from "src/app/core/enums/RolesEnum";
import { NoteModel } from "src/app/core/models/Note";
import { User } from "src/app/core/models/UserModel";
import { NoteService } from 'src/app/core/services/note.service';

@Component({
    selector: 'pd-note-table',
    templateUrl: './note-table.component.html',
    styleUrls: ['./note-table.component.scss']
})

export class NoteComponent implements OnInit, OnDestroy {
    @Input() dealId: number;
    @Input() isMyDeal: boolean = true;
    @Input() isAsModal: boolean = false;
    @ViewChild("targetScroll") targetScroll: ElementRef
    @Output() onLoaded = new EventEmitter<any>();

    editedNote: NoteModel;
    notes: Array<NoteModel> = [];
    isNewRecord: boolean;
    rolesEnum = RolesEnum
    isShowAll: boolean = false;
    countItemsToShow: number = 4;

    currentUser: User;

    constructor(private serv: NoteService, private userContextService: UserContextService) { }

    async ngOnInit() {
        this.currentUser = this.userContextService.user.value;
        await this.loadNotes();
    }

    ngOnDestroy() {
        this.editedNote = null;
        this.notes = new Array<NoteModel>();
        this.isNewRecord = false;
    }

    isEditRecord(note: NoteModel) {
        return this.editedNote && this.editedNote.id == note.id
    }

    public async loadNotes() {
        this.isNewRecord = false;
        this.editedNote = null;

        const data = await this.serv.getNotesByDealId(this.dealId)
        this.notes = data ? data : [];
        this.onLoaded.emit({ notes: this.notes });
    }

    addNote() {
        this.editedNote = new NoteModel(-1, this.dealId, "", new Date, 0, this.currentUser.name);
        if (this.isAsModal) {
            this.notes.unshift(this.editedNote);
            this.scrollToStart();
        }
        else {
            this.notes.push(this.editedNote);
        }
        this.isNewRecord = true;
    }

    editNote(note: NoteModel) {
        this.editedNote = new NoteModel(note.id, this.dealId, note.text, note.created, note.createdBy, note.createdByName);
    }

    async saveNote() {
        if (this.isNewRecord) {
            if (this.editedNote.text && this.editedNote.text.length > 0) {
                await this.serv.updateNote(this.editedNote);
                await this.loadNotes();
            }
            else {
                await this.loadNotes();
            }
            this.isNewRecord = false;
            this.editedNote = null;
        } else {
            await this.serv.updateNote(this.editedNote)
            await this.loadNotes();
            this.editedNote = null;
        }
    }

    async deleteNote(note: NoteModel) {
        if (confirm("Do you confirm deletion of this note?")) {
            await this.serv.deleteNote(note.id);
            await this.loadNotes();
        }
    }

    cancelNote() {
        if (this.isNewRecord) {
            this.notes.splice(this.notes.indexOf(this.editedNote), 1);
            this.isNewRecord = false;
        }
        this.editedNote = null;
    }

    showMore() {
        this.isShowAll = true;
    }

    scrollToStart() {
        setTimeout(() => {
            this.targetScroll.nativeElement.scrollIntoViewIfNeeded(false);
        }, 100);
    }

    //////////////////////////////// VIEW *ngIF START //////////////////////////////

    public get isAllowAddNote(): boolean {
        return this.isMyDeal
            || (this.currentUser && (this.currentUser.role_id === RolesEnum.MasterLead
                || this.currentUser.role_id === RolesEnum.Admin
                || this.currentUser.role_id === RolesEnum.EA
                || this.currentUser.role_id === RolesEnum.EaCaller));
    }

    public isAllowEditNote(note: NoteModel): boolean {
        return (this.currentUser && (this.currentUser.role_id === RolesEnum.MasterLead
            || this.currentUser.role_id === RolesEnum.Admin
            || this.currentUser.id === note.createdBy));
    }

    public isAllowDeleteNote(note: NoteModel): boolean {
        return (this.currentUser && (this.currentUser.role_id === RolesEnum.MasterLead
            || this.currentUser.id === note.createdBy));
    }

    //////////////////////////////// VIEW *ngIF END ////////////////////////////////
}