import { Component, ViewChild, ElementRef, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTooltip } from '@angular/material/tooltip';
import { DomSanitizer } from '@angular/platform-browser';
import { RolesEnum } from 'src/app/core/enums/RolesEnum';
import { ProductTypes } from 'src/app/core/enums/client-card/ProductTypes';
import { ClientCard } from 'src/app/core/models/ClientCard';
import { CardLine, FabricConfig, FabricImage, ProductType, TypeOfSuit } from 'src/app/core/models/ClientCardConfig';
import { User } from 'src/app/core/models/UserModel';
import { Modeler3dService } from 'src/app/core/services/3d-modeler.service';
import { ClientCardApiService } from 'src/app/core/services/client-card/client-card-api.service';
import { UserContextService } from 'src/app/core/services/user-context.service';
import { DateHelper } from 'src/app/core/utils/date.helper';
import { ImageUtil } from 'src/app/core/utils/image.util';

@Component({
  selector: 'pd-custom-client-card-modal',
  templateUrl: './custom-client-card-modal.component.html',
  styleUrls: ['./custom-client-card-modal.component.scss']
})

export class CustomClientCardModalComponent {
  @ViewChild('clientCardInput') clientCardInput: ElementRef;
  @ViewChild('fabricUploadFileInput') fabricUploadFileInput: ElementRef;
  @ViewChild('fileNameTooltip') fileNameTooltip: MatTooltip;

  saveClientCard: any;
  deleteClientCard: any;

  dealId: number;
  productType: ProductType;
  typeOfSuits: Array<TypeOfSuit>;
  lines: Array<CardLine>;
  fabricImages: Array<FabricImage>;
  fabricConfigs: Array<FabricConfig>;

  isSuit: boolean;
  isAccessories: boolean;

  currentUser: User;
  prevCard: ClientCard;
  editedCard: ClientCard;
  rolesEnum = RolesEnum;
  fabricFilter: FormControl = new FormControl();
  maxDate: Date;
  isDateFromValide: boolean = true;
  searchLengthStart: number = 2;
  fabricImgUrl: any;
  selectedFile: any;
  isPdfUploadSpinner: boolean = false;
  isPdfUploadSuccessful: boolean = false;
  isPdfUploadError: boolean = false;
  fileNameValidationPattern = /[\\\/:*?"<>|]/g;
  fileNameValidationMessage = "The file name must not contain the following characters:   \\\/:*?\"<>|\nThey are all replaced by   -";
  deleteCardMessage = "Do you confirm deletion of this card?"

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any
    , private dialogRef: MatDialogRef<CustomClientCardModalComponent>
    , private userContextService: UserContextService
    , private serv: ClientCardApiService
    , private modeler3dService: Modeler3dService
    , private sanitizer: DomSanitizer) {
    this.dealId = data.dealId;
    this.productType = data.productType;
    this.typeOfSuits = data.typeOfSuits;
    this.lines = data.lines;
    this.fabricImages = data.fabricImages;
    this.fabricConfigs = data.fabricConfigs;
    this.saveClientCard = data.saveClientCard;
    this.deleteClientCard = data.deleteClientCard;

    this.openClientCardModal(data.card);
  }

  ngOnInit() {
    this.initializeStore();
    this.maxDate = new Date();
  }

  private initializeStore() {
    this.currentUser = this.userContextService.user.value;
  }

  private async ok() {
    if (this.selectedFile) {
      await this.uploadFabricImg();
    }

    this.prevCard.fabricImgTitle = this.editedCard.fabricImgTitle;
    this.prevCard.lineId = this.editedCard.lineId;
    this.prevCard.fabricConfigId = this.editedCard.fabricConfigId;
    this.prevCard.fabricConfigTitle = this.editedCard.fabricConfigTitle;
    this.prevCard.typeOfSuitId = this.editedCard.typeOfSuitId;
    this.prevCard.dateOfOrder = new Date(DateHelper.formatAsISODateString(new Date(this.editedCard.dateOfOrder)));
    this.prevCard.order = this.editedCard.order;
    this.prevCard.filesCount = this.editedCard.filesCount;

    this.saveClientCard({ card: this.prevCard, isFabricUpdated: !!this.selectedFile });
  }

  private cancel() {
  }

  private copyCard(prevCard: ClientCard): ClientCard {
    let newCard = new ClientCard(0, prevCard.fabricImgTitle,
      prevCard.productTypeId,
      prevCard.lineId,
      prevCard.fabricConfigId,
      prevCard.fabricConfigTitle,
      prevCard.typeOfSuitId,
      prevCard.dateOfOrder,
      prevCard.order,
      prevCard.filesCount);

    newCard.id = prevCard.id;
    newCard.dealId = prevCard.dealId;
    newCard.fabricImgTitle = prevCard.fabricImgTitle;
    newCard.fabricImgUrl = prevCard.fabricImgUrl;
    newCard.productTypeId = prevCard.productTypeId;
    newCard.productTypeName = prevCard.productTypeName;
    newCard.lineId = prevCard.lineId;
    newCard.lineName = prevCard.lineName;
    newCard.fabricConfigId = prevCard.fabricConfigId;
    newCard.fabricConfigTitle = prevCard.fabricConfigTitle;
    newCard.typeOfSuitId = prevCard.typeOfSuitId;
    newCard.typeOfSuitName = prevCard.typeOfSuitName;
    newCard.dateOfOrder = prevCard.dateOfOrder;
    newCard.order = prevCard.order;
    newCard.filesCount = prevCard.filesCount;
    return newCard;
  }

  private async uploadFabricImg() {
    await this.modeler3dService.uploadFile(
      this.selectedFile,
      this.editedCard.fabricImgTitle + '.jpg',
      this.editedCard.productTypeId,
      this.editedCard.lineId);
  }

  private openClientCardModal(editedCard: ClientCard) {
    this.selectedFile = null;
    if (this.fabricUploadFileInput) {
      this.fabricUploadFileInput.nativeElement.value = null;
    }

    this.prevCard = editedCard;
    this.isSuit = this.prevCard.productTypeId === ProductTypes.Suits;
    this.isAccessories = this.prevCard.productTypeId === ProductTypes.Accessories;
    this.isPdfUploadSuccessful = false;
    this.isPdfUploadError = false;
    this.editedCard = this.copyCard(editedCard);
    this.fabricImgUrl = editedCard.fabricImgUrl;
  }

  showFabricValidationMessage() {
    let validationMessage = (this.isAccessories) ? "Please fill in the image number" : "Please fill in the line and fabric number"
    alert(validationMessage);
  }

  async confirm(isConfirm) {
    isConfirm ? await this.ok() : this.cancel();
    this.dialogRef.close();
  }

  getFilteredFabrics() {
    let filteredFabrics = this.fabricImages;

    if (this.editedCard && this.editedCard.fabricImgTitle) {
      let existFabricImg = filteredFabrics.find(f => f.title === this.editedCard.fabricImgTitle);
      if (!existFabricImg) {
        existFabricImg = new FabricImage();
        existFabricImg.title = this.editedCard.fabricImgTitle;
        filteredFabrics.push(existFabricImg);
      }
    }

    let value = this.fabricFilter.value;
    if (value && (this.fileNameValidationPattern).test(value)) {
      value = value.replace(this.fileNameValidationPattern, '-')
      this.fileNameTooltip.show();
      setTimeout(() => {
        this.fileNameTooltip.hide();
      }, 10000);
      this.fabricFilter.setValue(value);
    }

    let search = value;
    if (search && search.length >= this.searchLengthStart) {
      search = search.toLowerCase();
    } else {
      let result = new Array<FabricImage>();
      if (this.editedCard && this.editedCard.fabricImgTitle) {
        result.push(filteredFabrics.find(f => f.title === this.editedCard.fabricImgTitle));
      }
      return result;
    }

    let result = filteredFabrics.filter(fabric => fabric.title.toLowerCase().indexOf(search) > -1)

    result = result.sort((a, b) => { return this.customFilteredSort(a, b, search) });
    return result.slice(0, 100);
  }

  customFilteredSort(a, b, search) {
    let aTitle = a.title.toLowerCase()
    let bTitle = b.title.toLowerCase()
    let result = aTitle.indexOf(search) - bTitle.indexOf(search)

    if (result == 0) {
      if (aTitle < bTitle)
        return -1
      if (aTitle > bTitle)
        return 1
      return 0
    }

    return result;
  }

  findFabricImg(card: ClientCard) {
    const data = ImageUtil.fabricToUrl(card.fabricImgTitle);
    if (data !== null) {
      card.fabricImgUrl = data;
      this.fabricImgUrl = card.fabricImgUrl;
    }
  }

  addFabricTitle(title: string) {
    const fabricImg = new FabricImage();
    fabricImg.title = title;

    this.fabricImages.push(fabricImg);
    this.editedCard.fabricImgTitle = fabricImg.title
  }

  deleteCard() {
    if (confirm(this.deleteCardMessage)) {
      this.deleteClientCard(this.prevCard);
      this.dialogRef.close();
    }
  }

  onDataChange(): void {
    if (this.editedCard.dateOfOrder && new Date(this.editedCard.dateOfOrder) <= this.maxDate) {
      this.isDateFromValide = true;
    }
    else {
      this.isDateFromValide = false;
    }
  }

  filterDateFrom = (date: Date): boolean => {
    return date <= this.maxDate;
  }

  public changeListenerFabric(files: FileList) {
    if (files && files.length > 0) {
      var splitArray = files[0].name.split('.');
      var fileType = splitArray[splitArray.length - 1];
      if (fileType && fileType.toLowerCase() !== "jpg") {
        alert("Wrong file format. Please upload JPG file.");
        return;
      }
      this.selectedFile = files[0];
      this.fabricImgUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(this.selectedFile));
    }
  }

  getfabricConfigs() {
    return this.fabricConfigs.filter(f => f.productTypeId === this.editedCard.productTypeId);
  }

  public async changeListenerPdf(files: FileList) {
    if (files && files.length > 0) {
      var splitArray = files[0].name.split('.');
      var fileType = splitArray[splitArray.length - 1];
      if (fileType && fileType.toLowerCase() !== "pdf") {
        alert("Wrong file format. Please upload PDF file.");
        return;
      }

      this.isPdfUploadSpinner = true;
      this.isPdfUploadSuccessful = false;
      this.isPdfUploadError = false;

      const file = files[0];
      const response = await this.serv.clientCardPdf(file, this.editedCard.id)
      if (response) {
        this.prevCard.filesCount += 1;
        this.editedCard.filesCount += 1;
        this.isPdfUploadSpinner = false;
        this.isPdfUploadSuccessful = true;
        this.isPdfUploadError = false;
      }
      else {
        this.isPdfUploadSpinner = false;
        this.isPdfUploadSuccessful = false;
        this.isPdfUploadError = true;
      }
    }
    this.clientCardInput.nativeElement.value = null;
  }
}