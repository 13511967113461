import { Injectable } from "@angular/core";
import { Observable, from } from "rxjs";
import { Avatar2Collection } from "../../models/avatar-models/Avatar2Collection";
import { BaseService } from "../base.service";
import { HttpRestService } from "../http-client/http-rest-client.service";

@Injectable()
export class Avatar2CollectionApiService extends BaseService {
    constructor(private restService: HttpRestService) {
        super();
    }

    public getAllAvatar2Collections(): Observable<Avatar2Collection[]> {
        return from(this.handleResponse(this.restService.get({
            url: `/avatarCollection2Avatar/getAll`
        })));
    }

    public getAvatar2Collection(id: number): Observable<Avatar2Collection> {
        return from(this.handleResponse(this.restService.get({
            url: `/avatarCollection2Avatar/get/${id}`
        })));
    }

    public addAvatarToCollection(avatarId: number, avatarCollectionId: number): Observable<number> {
        return from(this.handleResponse(this.restService.get({
            url: `/avatarCollection2Avatar/addAvatarToCollection/${avatarId}/${avatarCollectionId}`
        })));
    }

    public removeAvatarFromCollection(avatarId: number, avatarCollectionId: number): Observable<boolean> {
        return from(this.handleResponse(this.restService.delete({
            url: `/avatarCollection2Avatar/removeAvatarFromCollection/${avatarId}/${avatarCollectionId}`
        })));
    }
}