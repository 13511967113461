<modal #modal title="Date of meeting" primaryBtnTextKey="Save" secondaryBtnTextKey="Cancel"
  [displayLaunchButton]="false" [isPrimaryBtnDisabled]="!meetingDate || meetingDate < yesterDay"
  (secondaryClick)="confirm(false)" (onCompleteAction)="confirm(false)" (primaryClick)="confirm(true)">
  <div class="col-auto ml-auto flex-center">
    <mat-form-field>
      <input matInput [matDatepicker]="picker" [(ngModel)]="meetingDate" placeholder="Date of meeting" [min]="minDate"
        [max]="maxDate" readonly>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker [startAt]="minDate"></mat-datepicker>
    </mat-form-field>
  </div>
  <div class="col-auto mr-auto flex-center">
    <timepicker [(ngModel)]="meetingDate" name="test" class="timepicker" [showMeridian]=false></timepicker>
  </div>
</modal>