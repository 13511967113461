@if (avatarCollection && avatarSortingType) {
<mat-expansion-panel class="pd-avatar-seasonal-proposal-groupped-expansion-panel" (opened)="onOpened()">
  <mat-expansion-panel-header>
    <mat-panel-title>
      <div class="panel-title">
        <div>
          <span>
            {{avatarSortingType}}
          </span>

          @if (getAvatarCount() > 0) {
          <span>&nbsp;({{getAvatarCount()}})</span>
          }
        </div>

        <div class="panel_tool">
        </div>

      </div>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <mat-panel-description>
    @if (isNeedLoadAvatars) {

    <ng-container *ngTemplateOutlet="contentWrapperTemplate"></ng-container>

    }
  </mat-panel-description>
</mat-expansion-panel>
}

@if (!avatarSortingType) {

<ng-container *ngTemplateOutlet="contentWrapperTemplate"></ng-container>

}

<ng-template #contentWrapperTemplate>
  <pd-avatar-seasonal-proposal-container #container [avatarCollection]='avatarCollection' [avatars]='getAvatars()'
    (onEditAvatar)="editAvatar($event)" (onDeleteAvatar)="deleteAvatar($event)"
    (onDownloadAvatar)="downloadAvatar($event)" (onRemoveAvatarFromCollection)="removeAvatarFromCollection($event)"
    [isExistingAvatarCollection]="isExistingAvatarCollection">
  </pd-avatar-seasonal-proposal-container>

  <div style="text-align: center;">
    @if (avatars && getAvatars().length < avatars.length) { <button (click)="loadMoreAvatars()" type="button"
      class="load-more-btn waves-light btn-dark btn" placement="bottom" mdbWavesEffect>
      <span class="btn-label">Show more</span>
      </button>
      }
  </div>
</ng-template>