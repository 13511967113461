import { Component, OnInit } from '@angular/core';
import { Printd } from 'printd';
import { ExportDealsType } from 'src/app/core/enums/ExportDealsType';
import { Deal } from 'src/app/core/models/Deal';
import { CsvUtil } from 'src/app/core/utils/csv.util';

@Component({
  selector: 'pd-export-deals',
  templateUrl: './export-deals.component.html',
  styleUrls: ['./export-deals.component.scss']
})

export class ExportDealsComponent implements OnInit {
  public deals: Deal[]
  public exportType: ExportDealsType
  public ExportDealsType = ExportDealsType
  public csvHelper: CsvUtil

  public ngOnInit() {
    this.csvHelper = new CsvUtil();
  }

  public print(items: Deal[], exportDealsType = ExportDealsType.Referrals) {
    this.deals = items
    this.exportType = exportDealsType
    setTimeout(() => {
      const cssHotlist =`.container {  
        width: 23vw;
    
        margin-bottom: 2vh;
        height: 18vh;    
    
        float: left;
        display: flex;
    }
    .content-row {
        display: flex;
        flex-wrap: wrap;
    }
    .cell{
        margin-right: 1vw;
    }
    
    .bold{
        font-weight: 800;
    }
    
    .header {
        margin: 0;
        border-top: 1px solid #b9babb;
        font-weight: 400;
        width: calc(100% - 15px);
        display: flex;
        text-align: center;
        font-size: 16px;   
    }
    
    .table {
        margin: 0;
        border-top: 1px solid #b9babb;
        font-weight: 400;
        width: calc(100% - 15px);
    }
    
    .row {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;   
    }
    
    
    .border-right {
        border-right: 1px solid #b9babb;
        padding-left: 2px;
    }
    
    .text {
        border-right: 1px solid #b9babb;
        border-bottom: 1px solid #b9babb;
        word-wrap: break-word;
        word-break: break-word;
        font-size: 14px;   
        padding-left: 2px;
    }
    
    .col-2 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 16.4%;
        flex: 0 0 16.4%;
        max-width: 16.4%;
    }
    
    .col-1 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 8%;
        flex: 0 0 8%;
        max-width: 8%;
    }
    
    .sort {
        cursor: pointer;
    }
    
    .sort:focus {
        background-color:  #e5e5e5;
    }
    
    
    
    .table-row {
        margin: 0;
        border-bottom: 1px solid #b9babb;
    }
    
    @media print {
        @page {size: landscape}
        .container, .cell {
            page-break-inside: avoid;
            page-break-after: auto;
            page-break-before: auto;
        }
         br + br {
             display: none;
         }
         .cell {       
            word-wrap: break-word;   
            font-size: 16px;    
            border: 1px;            
         }
         .cell div {
             margin-bottom: -5px;
         }
    }`;
      const titleString = document.getElementsByTagName('title')[0].textContent;
      const d: Printd = new Printd();
      document.title = "Export Referral";
      d.print(document.getElementById('print-pdf-section'), [cssHotlist]);
      // d.print( document.getElementById('print-pdf-section'), cssHotlist ); - if not render with 73 row
      document.title = titleString;
    }, 1000)
  }
}
