import { GlobalConstants } from './../../core/global-constants';
import { Component,  Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FilterType } from 'src/app/core/enums/FilterType.enum';
import { TasksService } from 'src/app/core/services/tasks.service';
import { DealService } from 'src/app/core/services/deals.service';
import { CustomSelectFilterComponent } from 'src/app/shared/custom/custom-select-filter/custom-select-filter.component';

@Component({
  selector: 'pd-search-numbers-task',
  templateUrl: './search-numbers-task.component.html',
  styleUrls: ['./search-numbers-task.component.scss']
})
export class SearchNumbersTaskComponent {
  public numberForm: FormGroup;
  public updateNumberInfo: string;
  public numberLocations = [];
  public numberCount: number;
  public contactType: number;
  public contactTypes = ['Referral', 'LinkedIn', 'Cold Call'];
  public gender: number;
  public genderList = ['All', 'Male', 'Female', 'None'];
  public spinner: boolean = false;
  public tasksRoute = GlobalConstants.ROUTE_NAMES.Tasks;
  public currentUserId: string = '';
  public isShowError = false;

  public filteredUserIds = null;

  @ViewChild("locationFilter") customSelectLocation: CustomSelectFilterComponent;
  @Output() locationsLabel: string = 'Locations';
  public isSelectAllLocations: boolean;
  selectTextLocations: string;
  isSelectAllLocationsNumber: boolean;
  selectTextLocationsNumber: string;
  isSelectAllLocationsGender: boolean;
  selectTextLocationsGender: string;
  filterTypeLocation = FilterType.Location;

  constructor(private taskService: TasksService, private dealService: DealService) {
    this.numberForm = new FormGroup({
      multiNumber: new FormControl('', Validators.required),
      numberCountOfPerson: new FormControl('', Validators.required)
    });
  }

  public submitNumber() {
    let locationsId = this.numberLocations.reduce((acc, current) => {
      acc.push(current.id)
      return acc
    }, [])
    this.taskService.RunUpdateNumber(locationsId, this.contactType, this.gender, +this.numberCount, this.currentUserId, this.isSelectAllLocationsNumber, this.selectTextLocationsNumber).then(result => {
    });
    this.numberCount = 0;
    this.numberLocations = [];
    this.customSelectLocation.deselectAllData();
    this.updateNumberInfo = 'Number Update Task started';
    setTimeout(() => {
      this.updateNumberInfo = null;
    }, 3000);
    this.numberForm.reset();
  }

  getLocationsFilterData(event) {
    let isNotleter: number = event.isNotLetter;
    this.dealService.getFilterData(FilterType.Location, event.skip, event.take).then(response => {
      if (isNotleter == this.customSelectLocation.isNotLetter) {
        this.customSelectLocation.setData(response);
      }
    });
  }

  getLocationsAutocomplete(event) {
    let isNotleter: number = event.isNotLetter;
    this.dealService.getFilterDataAutocomplete(FilterType.Location, event.searchString, event.skip, event.take).then(response => {
      if (isNotleter == this.customSelectLocation.isNotLetter) {
        this.customSelectLocation.setData(response);
      }
    });
  }

  public getNumberLocations(locations) {
    this.numberLocations = locations.data;
    this.isSelectAllLocationsNumber = locations.isSelectAll;
    this.selectTextLocationsNumber = locations.selectText;
  }

  public getContactType(value) {
    if (value === 'Referral') {
      this.contactType = 0;
    } else if (value === 'LinkedIn') {
      this.contactType = 1;
    } else {
      this.contactType = 2;
    }
  }

  public getGender(value: any) {
    if (value === 'Male') {
      this.gender = 26;
    } else if (value === 'Female') {
      this.gender = 27;
    } else if (value === 'None') {
      this.gender = -1;
    }
  }
}
