import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { Modeler3dProductComponent } from './3d-modeler-product.component';
import { Modeler3dProductLineComponent } from './3d-modeler-product-line/3d-modeler-product-line.component';
import { Modeler3dProductCardComponent } from './3d-modeler-product-card/3d-modeler-product-card.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule
    ],
    declarations: [
        Modeler3dProductComponent,
        Modeler3dProductLineComponent,
        Modeler3dProductCardComponent
    ],
    exports: [
        Modeler3dProductComponent,
        Modeler3dProductLineComponent,
        Modeler3dProductCardComponent
    ]
})
export class Modeler3dProductComponentModule { }
