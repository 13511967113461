import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'pd-edit-button',
  templateUrl: './edit-button.component.html',
  styleUrls: ['./edit-button.component.scss']
})
export class EditButtonComponent{

  /**
    * Change value
    * emit value in other component
    * @memberof EditButtonComponent
    */

  @Output() openEdit = new EventEmitter<void>();
  goToEdit() {
    this.openEdit.emit();
  }
}
