import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { HttpRestService } from '../http-client/http-rest-client.service';
import { BulkMessageSettingUpdateDto } from '../../models/dto/BulkMessageSettingUpdateDto';
import { BulkMessageSettingViewModel } from '../../models/bulk-message/BulkMessageSettingsViewModel';
import { FilteredDealDto } from '../../models/dto/FilteredDealDto';

@Injectable()
export class BulkMessageSettingApiService extends BaseService {

	constructor(private restService: HttpRestService) {
		super();
	}

	public async getBulkMessageSettings(): Promise<Array<BulkMessageSettingViewModel>> {
		return await this.handleResponse(this.restService.get({ url: '/bulk-message-setting/get-bulk-message-settings' }));
	}

	public async addBulkMessageSetting(data: BulkMessageSettingUpdateDto): Promise<number> {
		const url = '/bulk-message-setting/add-bulk-message-setting';
		return await this.handleResponse(this.restService.post({ url, data }));
	}

	public async removeBulkMessageSetting(settingId: number): Promise<boolean> {
		const url = '/bulk-message-setting/remove-bulk-message-setting/' + settingId;
		return await this.handleResponse(this.restService.delete({ url }));
	}

	public async archiveBulkMessageSetting(settingId: number, value: boolean): Promise<boolean> {
		const url = '/bulk-message-setting/archive-bulk-message-setting/' + settingId + '/' + value;
		return await this.handleResponse(this.restService.post({ url }));
	}

	public async getDealsBySetting(data): Promise<FilteredDealDto[]> {
		const url = '/bulk-message-setting/get-deals-by-setting';
		return await this.handleResponse(this.restService.post({ url, data }));
	}
}
