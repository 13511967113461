<div mat-dialog-title>
  Custom Link
</div>
<mat-dialog-content>
  <div>
    <div class="wrapper">
      <div class="col-12 row">
        <div class="label">
          Label:<span class="star">*</span>
        </div>
        <div class="col" mdbTooltip="Label is required">
          <input type="text" [(ngModel)]="label">
        </div>
      </div>

      <div class="row col-12 alert-row">
        @if (isShowValidationMessages && !this.checkLabelIsValid()) {
        <div class="alert alert-danger">
          Label is required
        </div>
        }
      </div>

      <div class="col-12 row">
        <div class="label">
          Link:<span class="star">*</span>
        </div>
        <div class="col" mdbTooltip="Link is required">
          <input type="text" [(ngModel)]="link">
        </div>
      </div>

      <div class="row col-12 alert-row">
        @if (isShowValidationMessages && !this.checkLinkIsValid()) {
        <div class="alert alert-danger">
          Link is required
        </div>
        }
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button type="button" mdbBtn class="waves-light btn-danger btn" aria-label="Close" (click)="cancel()"
    mdbWavesEffect>Close</button>
  <button type="button" mdbBtn class="waves-light btn-success btn" aria-label="Save" (click)="confirm()"
    mdbWavesEffect>Save</button>
</mat-dialog-actions>