import { UsersService } from './../../../core/services/users.service';
import { UserContextService } from './../../../core/services/user-context.service';
import { Component, ViewChild, Output, EventEmitter, Inject, OnInit } from '@angular/core';
import { RolesEnum } from 'src/app/core/enums/RolesEnum';
import { DealDetails } from 'src/app/core/models/DealDetails';
import { User } from 'src/app/core/models/UserModel';
import { UtilsHelper } from 'src/app/core/utils/utils.helper';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseComponent } from 'src/app/core/base.component';

@Component({
  selector: 'pd-custom-meeting-confirm',
  templateUrl: './custom-meeting-confirm.component.html',
  styleUrls: ['./custom-meeting-confirm.component.scss']
})
export class CustomMeetingConfirmComponent extends BaseComponent {
  closeModal: any;
  saveDate: any;

  minDate = new Date(Date.now());
  maxDate = undefined;
  today = new Date();
  yesterDay = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate());
  confirmAction: any;
  meetingDate: Date;

  isShowUserList = false;
  isConfirm = false;
  dealsPerson: DealDetails;
  prevDealsPerson: DealDetails = new DealDetails();
  isAllowAutofill = true;
  isAutofillError = false;
  isSkipHomeAddress = false;
  isShowValidationMessages = false;
  isSpinner = true;
  addressLabel = "Please, write the address"
  public addressValidationTooltip = UtilsHelper.addressValidationMessage;
  options = [];

  RolesEnum = RolesEnum;
  currentUser: User;
  usersListForMoveDeals = [];
  selectedUser: any;

  primaryBtnText: string = "";
  secondaryBtnText: string = "";
  isPrimaryBtnVisible: boolean = false;
  isSecondaryBtnVisible: boolean = false;

  constructor(
    private userContextService: UserContextService,
    private usersService: UsersService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<CustomMeetingConfirmComponent>) {
    super();
    this.closeModal = data.closeModal;
    this.saveDate = data.saveDate;
    this.currentUser = this.userContextService.user.value;
    this.dealsPerson = data.dealsPerson;
    this.confirmAction = data.confirmCallback;

    this.openMeetingConfirmModal(data.meetingDate)
  }

  public async openMeetingConfirmModal(meetingDate: Date) {    
    const users = await this.usersService.getUsers();
    this.usersListForMoveDeals = users.filter(item => item.role_id === RolesEnum.MasterLead || item.role_id === RolesEnum.Admin || item.role_id === RolesEnum.Clothier);

    this.meetingDate = meetingDate;

    this.prevDealsPerson.companyAddress = this.dealsPerson.companyAddress
    this.prevDealsPerson.latitude = this.dealsPerson.latitude
    this.prevDealsPerson.longitude = this.dealsPerson.longitude
    this.prevDealsPerson.homeAddress = this.dealsPerson.homeAddress

    this.isSpinner = false;
  }

  public negativeConfirm() {
    this.selectedUser = null;
    this.isShowUserList = false;
    this.isConfirm = false;
  }

  public showMeetingDropdown() {
    if (!this.validation()) return;
    this.isShowUserList = true;
  }

  public selectUserForMove(user) {
    this.selectedUser = user;
    this.isConfirm = true;
  }

  public confirm(isConfirm) {
    if (isConfirm) {
      if (!this.validation()) return;
      this.ok();
    } else {
      this.cancel()
    }
    this.dialogRef.close();
  }

  private ok() {
    this.meetingDate = this.meetingDate;
    this.dealsPerson.meetingDateUtc = this.meetingDate;
    this.saveDate({ dealsPerson: this.dealsPerson, selectedUser: this.selectedUser });
    if (this.confirmAction) {
      this.confirmAction();
    }
  }

  private cancel() {
    this.dealsPerson.companyAddress = this.prevDealsPerson.companyAddress
    this.dealsPerson.homeAddress = this.prevDealsPerson.homeAddress
    this.closeModal();
  }

  public validation() {
    this.isShowValidationMessages = true;

    return this.meetingDateValidation()
      && this.homeAddressValidation()
      && this.companyAddressValidation();
  }

  public meetingDateValidation() {
    if (this.meetingDate && this.meetingDate >= this.yesterDay) {
      return true;
    }
    return false;
  }

  public companyAddressValidation() {
    if (this.dealsPerson && (this.isAutofillError || this.checkAddressIsValid(this.dealsPerson.companyAddress))) {
      return true;
    }

    return false;
  }

  public homeAddressValidation() {
    if (this.dealsPerson && (this.isSkipHomeAddress || this.checkAddressIsValid(this.dealsPerson.homeAddress))) {
      return true;
    }

    return false;
  }

  private checkAddressIsValid(address: string) {
    return UtilsHelper.checkAddressIsValid(address);
  }
}
