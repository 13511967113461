import { Pipe, PipeTransform } from '@angular/core';
import * as stagesDict from '../../../assets/stages_new_prospespects';
import { Stage } from 'src/app/core/models/Stage';

@Pipe({
    name: 'stagesForPipeline'
})
export class StagesForPipelinePipe implements PipeTransform {
    transform(stages: Stage[], pipelineId = stagesDict.Pipelines.NewProspects, doPerform = true): any[] {
      return stages && stages.length > 0 ? stages.filter(st => st.pipeline_id === pipelineId) : [];
    }
}
