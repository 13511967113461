<div>
    <div>
        <div class="settings-table">
            <div class="settings-table-label">
                {{title}}
            </div>
            <div class="settings-table-header row">
                <div class="col-1 cell">ID of setting</div>
                <div class="col cell">Statuses of bulk message requests</div>
                <div class="col cell">Settings Info</div>
                <div class="col cell">Request Info</div>
                <div class="col cell">Location</div>
                <div class="col-1 cell">Creation Date</div>
                <div class="col-2 cell">Actions</div>
            </div>
        </div>
    </div>
    <div class="content-wrapper">
        <div class="content">
            <div class="settings-table">
                @for (bulkMessageSetting of settings; track bulkMessageSetting) {
                <div class="settings-table-content row">
                    <div class="col-1 cell">{{bulkMessageSetting.id}}</div>
                    <div class="col cell">
                        @if(bulkMessageSetting.requests.length == 0)
                        {
                        <div><b>{{EnumUtil.getBulkRequestStatusViewLabel(RequestStatus.New)}}</b></div>
                        }
                        @if(bulkMessageSetting.requests.length > 0)
                        {
                        <pd-bulk-message-request-tree [requests]="bulkMessageSetting.requests"
                            (onRequestSelectChange)="onRequestSelectChange($event)">
                        </pd-bulk-message-request-tree>
                        }
                    </div>
                    <div class="col cell" [innerHTML]="parseSettingData(bulkMessageSetting)">
                    </div>
                    <div class="col cell">
                        @if(getSelectedRequest(bulkMessageSetting)?.isSendToReceived != null)
                        {
                        <div>
                            {{getSelectedStatusesMessage(getSelectedRequest(bulkMessageSetting))}}
                        </div>
                        <br />
                        }

                        @if(isEmail(bulkMessageSetting))
                        {
                        <ng-container>
                            @if(getSelectedRequest(bulkMessageSetting)?.messageTemplate?.isNeedUnsubscribeHtml)
                            {
                            <div>
                                Added unsubscribe link
                            </div>
                            <br />
                            }
                            @if(!getSelectedRequest(bulkMessageSetting)?.messageTemplate?.isNeedUnsubscribeHtml)
                            {
                            <div>
                                No unsubscribe link
                            </div>
                            <br />
                            }
                        </ng-container>
                        }

                        @if(isWhatsapp(bulkMessageSetting))
                        {
                        <div [innerHTML]="parseRequestData(bulkMessageSetting)"></div>
                        <div>
                            <div><b>Expiration Date: </b><br />{{getSelectedRequest(bulkMessageSetting)?.expirationDate
                                | date :'dd-MMM-yyyy h:mm a'}}</div><br />
                        </div>
                        }

                        <div><b>{{getTemplateName(bulkMessageSetting)}}</b></div>
                        <br />
                        <div [innerHTML]="getTemplateVariablesHtml(bulkMessageSetting, TemplateVariableTypes.Subject)">
                        </div>

                        @if(getSelectedRequest(bulkMessageSetting)?.messageTemplate?.messageTemplateType ==
                        MessageTemplateType.Whatsapp)
                        {
                        <div>{{getTemplateBody(bulkMessageSetting)}}</div>
                        }

                        @if(getSelectedRequest(bulkMessageSetting)?.messageTemplate?.messageTemplateType ==
                        MessageTemplateType.WhatsApp)
                        {
                        <br />
                        <div [innerHTML]="getTemplateVariablesHtml(bulkMessageSetting, TemplateVariableTypes.Body)">
                        </div>
                        }

                        @if(getSelectedRequest(bulkMessageSetting)?.messageTemplate?.messageTemplateType ==
                        MessageTemplateType.Email)
                        {
                        <button class="btn btn-primary waves-light ml-0"
                            (click)="openFullscreen(getSelectedRequest(bulkMessageSetting))">Show Template</button>
                        }
                    </div>
                    <div class="col cell">
                        @for (location of getLocationBody(bulkMessageSetting); track location) {
                        <div>
                            {{location.largeLabel}}
                        </div>
                        }
                    </div>

                    <div class="col-1 cell">{{bulkMessageSetting.created | date :'dd-MMM-yyyy h:mm a'}}</div>
                    <div class="col-2 cell">

                        @if(!isSelectedItem)
                        {
                        <div class="row">
                            <div class="col">
                                <button class="btn btn-primary waves-light ml-0"
                                    (click)="select(bulkMessageSetting)">Show Details</button>
                            </div>
                        </div>
                        <ng-container>
                            @if(isArchive)
                            {
                            <div class="row">
                                <div class="col">
                                    <button class="btn btn-warning waves-light ml-0"
                                        (click)="archive(bulkMessageSetting.id, false)">Unarchive</button>
                                </div>
                            </div>
                            }
                        </ng-container>
                        }

                        @if(isSelectedItem)
                        {
                        <ng-container>
                            @if(!isArchive)
                            {
                            <div class="row">
                                <div class="col">
                                    <button class="btn btn-success waves-light ml-0"
                                        (click)="configureWhatsappMessageSending(bulkMessageSetting)">Configure
                                        Whatsapp
                                        Message</button>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <button class="btn btn-primary waves-light ml-0"
                                        (click)="configureEmailMessageSending(bulkMessageSetting)">Configure
                                        Email</button>
                                </div>
                            </div>
                            }
                        </ng-container>

                        <div class="row">
                            <div class="col"><button class="btn btn-danger waves-light ml-0"
                                    (click)="cancel()">Return</button></div>
                        </div>
                        }

                        @if(isMain)
                        {
                        <ng-container>
                            @if(bulkMessageSetting.requests.length == 0)
                            {
                            <div class="row">
                                <div class="col">
                                    <button class="btn btn-danger waves-light ml-0"
                                        (click)="remove(bulkMessageSetting.id)">Remove</button>
                                </div>
                            </div>
                            }

                            <div class="row">
                                <div class="col">
                                    <button class="btn btn-warning waves-light ml-0"
                                        (click)="archive(bulkMessageSetting.id, true)">Archive</button>
                                </div>
                            </div>
                        </ng-container>
                        }
                    </div>
                </div>
                }
            </div>
        </div>
    </div>
</div>


<div #fullscreenView class="fullscreen">
    <button class="fullscreen-close-btn waves-light btn-danger btn" style="float: right;" (click)="closeFullscreen()">
        <i class="material-icons">close</i>
    </button>
    <div class="row">
        <div class="col-10" style="    
            margin: 10vh auto;
            background: white;
            height: 80vh;
            overflow: auto;">
            <pd-bulk-message-email #templateView [isTemplatePreview]="true"></pd-bulk-message-email>
        </div>
    </div>
</div>