import { Injectable } from '@angular/core';
import { HttpRestService } from './http-client/http-rest-client.service';
import { BaseService } from './base.service';
import { MessageTemplateViewModel } from '../models/message-template/MessageTemplateViewModel';

@Injectable()
export class MessageTemplateService extends BaseService {

    constructor(private restService: HttpRestService) {
        super();
    }

    public async getTemplates(): Promise<MessageTemplateViewModel[]> {
        const url = '/message-template'
        return await this.handleResponse(this.restService.get({ url }));
    }

    public async addTemplate(data: MessageTemplateViewModel): Promise<number> {
        const url = '/message-template';
        return await this.handleResponse(this.restService.post({ url, data }));
    }
}
