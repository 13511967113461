import { Injectable } from "@angular/core";
import { Avatar } from "../../models/avatar-models/Avatar";
import { BehaviorSubject, Observable } from "rxjs";
import { filterNil } from "../../filter-nil";
import { AvatarCollection } from "../../models/avatar-models/AvatarCollection";
import { Avatar2Collection } from "../../models/avatar-models/Avatar2Collection";

@Injectable()
export class AvatarStoreService {
    private readonly allAvatars$: BehaviorSubject<Avatar[]> = new BehaviorSubject<Avatar[]>(null);
    private readonly allAvatarCollections$: BehaviorSubject<AvatarCollection[]> = new BehaviorSubject<AvatarCollection[]>(null);
    private readonly allAvatar2Collections$: BehaviorSubject<Avatar2Collection[]> = new BehaviorSubject<Avatar2Collection[]>(null);

    //Avatar
    public getAllNonDealIdAvatars(): Observable<Avatar[]> {
        return this.allAvatars$.asObservable().pipe(filterNil());
    }

    public getAllAvatarsSnapshot(): Avatar[] {
        return this.allAvatars$.getValue();
    }

    public setAllAvatars(avatars: Avatar[]): void {
        this.allAvatars$.next(avatars);
    }

    //AvatarCollection
    public getAllAvatarCollections(): Observable<AvatarCollection[]> {
        return this.allAvatarCollections$.asObservable().pipe(filterNil());
    }

    public getAllAvatarCollectionsSnapshot(): AvatarCollection[] {
        return this.allAvatarCollections$.getValue();
    }

    public setAllAvatarCollections(avatars: AvatarCollection[]): void {
        this.allAvatarCollections$.next(avatars);
    }

    //Avatar2Collection
    public getAllAvatar2Collections(): Observable<Avatar2Collection[]> {
        return this.allAvatar2Collections$.asObservable().pipe(filterNil());
    }

    public getAllAvatar2CollectionsSnapshot(): Avatar2Collection[] {
        return this.allAvatar2Collections$.getValue();
    }

    public setAllAvatar2Collections(avatar2Collections: Avatar2Collection[]): void {
        this.allAvatar2Collections$.next(avatar2Collections);
    }
}