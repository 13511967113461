import { Component, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseComponent } from 'src/app/core/base.component';
import { FabricImage } from 'src/app/core/models/ClientCardConfig';
import { ImageUtil } from 'src/app/core/utils/image.util';

@Component({
  selector: 'pd-fabric-selector',
  templateUrl: './fabric-selector.component.html',
  styleUrls: ['./fabric-selector.component.scss']
})

export class FabricSelectorComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() public imageLink: string;
  @Input() public fabricImages: FabricImage[];
  @Input() public isDisabled = false;

  @Output() public onFabricSelected = new EventEmitter<any>();

  public fabricFilter = new FormControl();
  public fabric: string;

  private searchLengthStart: number = 2;

  constructor(
  ) {
    super();
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  public getFabricTitle(imageLink: string) {
    return ImageUtil.urlToFabric(imageLink);
  }

  public getFilteredFabrics() {
    const filteredFabrics = this.fabricImages;
    this.fabric = ImageUtil.urlToFabric(this.imageLink);

    if (this.fabric) {
      let existFabricImg = filteredFabrics.find(f => f.title === this.fabric);
      if (!existFabricImg) {
        existFabricImg = new FabricImage();
        existFabricImg.title = this.fabric;
        filteredFabrics.push(existFabricImg);
      }
    }

    let value = this.fabricFilter.value;

    let search = value;
    if (search && search.length >= this.searchLengthStart) {
      search = search.toLowerCase();
    } else {
      let result = new Array<FabricImage>();
      if (this.fabric) {
        result.push(filteredFabrics.find(f => f.title === this.fabric));
      }
      return result;
    }

    let result = filteredFabrics.filter(fabric => fabric.title.toLowerCase().indexOf(search) > -1)

    result = result.sort((a, b) => { return this.customFilteredSort(a, b, search) });
    return result.slice(0, 100);
  }

  public setImgUrl() {
    this.imageLink = ImageUtil.fabricToUrl(this.fabric);
    this.onFabricSelected.emit({ imageLink: this.imageLink });
  }

  //////////////////////////////// Events START //////////////////////////////

  //////////////////////////////// Events END //////////////////////////////

  //////////////////////////////// Private START //////////////////////////////

  private customFilteredSort(a, b, search) {
    let aTitle = a.title.toLowerCase()
    let bTitle = b.title.toLowerCase()
    let result = aTitle.indexOf(search) - bTitle.indexOf(search)

    if (result == 0) {
      if (aTitle < bTitle)
        return -1
      if (aTitle > bTitle)
        return 1
      return 0
    }

    return result;
  }

  //////////////////////////////// Private END //////////////////////////////

  //////////////////////////////// Helpers START ////////////////////////////////

  //////////////////////////////// Helpers END ////////////////////////////////

  //////////////////////////////// View *ngIf START //////////////////////////////

  //////////////////////////////// View *ngIf END //////////////////////////////
}
