import { AvatarElementTypes } from "../enums/avatar/AvatarElementTypes";
import { AvatarTypes } from "../enums/avatar/AvatarTypes";
import { ProductTypes } from "../enums/client-card/ProductTypes";
import { Avatar } from "../models/avatar-models/Avatar";
import { AvatarElement } from "../models/avatar-models/AvatarElement";
import { AvatarOption } from "../models/avatar-models/AvatarOption";

export class AvatarUtil {

  public static sortOptions: any[] =
    [
      { order: 1, elementTypeId: AvatarElementTypes.Head },
      { order: 2, elementTypeId: AvatarElementTypes.Coat },
      { order: 3, elementTypeId: AvatarElementTypes["Puffy Coat"] },
      { order: 4, elementTypeId: AvatarElementTypes.Jacket },
      { order: 5, elementTypeId: AvatarElementTypes.Jacket_Type1 },
      { order: 6, elementTypeId: AvatarElementTypes.Jacket_Type2 },
      { order: 7, elementTypeId: AvatarElementTypes.Lapel },
      { order: 8, elementTypeId: AvatarElementTypes.Tie },
      { order: 9, elementTypeId: AvatarElementTypes.Vest },
      { order: 10, elementTypeId: AvatarElementTypes.Vest_Type1 },
      { order: 11, elementTypeId: AvatarElementTypes.Sweater },
      { order: 12, elementTypeId: AvatarElementTypes.Shirt },
      { order: 13, elementTypeId: AvatarElementTypes["TShirt_Type1"] },
      { order: 13, elementTypeId: AvatarElementTypes["TShirt_Type2"] },
      { order: 14, elementTypeId: AvatarElementTypes.Cuffs },
      { order: 15, elementTypeId: AvatarElementTypes.Belt },
      { order: 16, elementTypeId: AvatarElementTypes.Bowtie },
      { order: 17, elementTypeId: AvatarElementTypes.Turtleneck },
      { order: 18, elementTypeId: AvatarElementTypes.Pants },
      { order: 19, elementTypeId: AvatarElementTypes.Jeans },
    ];


  public static isTuxedo(avatar: Avatar): boolean {
    return avatar && (avatar.avatarTypeId === AvatarTypes['1 Buttontuxedo'] ||
      avatar.avatarTypeId === AvatarTypes['1 Buttontuxedo Cuffs'] ||
      avatar.avatarTypeId === AvatarTypes['2 Buttontuxedo'] ||
      avatar.avatarTypeId === AvatarTypes['2 Buttontuxedo Cuffs']);
  }

  public static productTypeToAvatarProcessing: ProductTypes[] =
    [
      ProductTypes.Suits
      , ProductTypes.Shirts
      , ProductTypes.Blazers
      , ProductTypes.Outerwear
      , ProductTypes.Pants
      , ProductTypes.SmartCasual
    ];

  public static getSortedOptions(options: AvatarOption[]) {
    console.log('getSortedOptions', options);
    console.log('sortOptions', this.sortOptions);
    options.sort((a, b) => {
      const aSort = this.sortOptions.find(o => o.elementTypeId === a.elementTypeId);
      const bSort = this.sortOptions.find(o => o.elementTypeId === b.elementTypeId);
      return aSort.order - bSort.order;
    });
    return options;
  }

  public static getSortedAvatarElements(options: AvatarElement[]) {
    options.sort((a, b) => {
      const aSort = this.sortOptions.find(o => o.elementTypeId === a.elementTypeId);
      const bSort = this.sortOptions.find(o => o.elementTypeId === b.elementTypeId);
      return aSort.order - bSort.order;
    });
    return options;
  }

  public static moveTexture(option: AvatarOption, imgPattern: Element) {
    let uses = imgPattern.getElementsByTagName('use');

    Array.from(uses).forEach((use, index) => {
      let xOriginalStr = use.getAttribute('xOriginal');
      let yOriginalStr = use.getAttribute('yOriginal');
      let xoffsetOriginalStr = use.getAttribute('xoffsetOriginal');
      let yoffsetOriginalStr = use.getAttribute('yoffsetOriginal');

      if (xOriginalStr == null || yOriginalStr == null || xoffsetOriginalStr == null || yoffsetOriginalStr == null) {
        this.storeOriginalPositions(option, imgPattern);
        xOriginalStr = use.getAttribute('xOriginal');
        yOriginalStr = use.getAttribute('yOriginal');
        xoffsetOriginalStr = use.getAttribute('xoffsetOriginal');
        yoffsetOriginalStr = use.getAttribute('yoffsetOriginal');
      }

      let x = parseInt(xOriginalStr);
      let y = parseInt(yOriginalStr);
      const xoffsetOriginal = parseInt(xoffsetOriginalStr);
      const yoffsetOriginal = parseInt(yoffsetOriginalStr);

      x += (option.xoffset - xoffsetOriginal);
      y += (option.yoffset - yoffsetOriginal);

      use.setAttribute('x', x.toString());
      use.setAttribute('y', y.toString());
    });
  }

  public static htmlToElement(html) {
    var template = document.createElement('template');
    html = html.trim(); // Never return a text node of whitespace as the result
    template.innerHTML = html;
    return template.content.firstChild;
  }

  public static storeOriginalPositions(option: AvatarOption, imgPattern: Element) {
    const uses = imgPattern.getElementsByTagName('use');

    Array.from(uses).forEach((use, index) => {
      const xStr = use.getAttribute('x');
      const yStr = use.getAttribute('y');

      let x = parseInt(xStr);
      let y = parseInt(yStr);

      use.setAttribute('xOriginal', x.toString());
      use.setAttribute('yOriginal', y.toString());
      use.setAttribute('xoffsetOriginal', option.xoffset.toString());
      use.setAttribute('yoffsetOriginal', option.yoffset.toString());
    });
  }
}