import { Injectable } from '@angular/core';
import { ClientCardConfig } from '../../models/ClientCardConfig';
import { ClientCard } from '../../models/ClientCard';
import { ProductTypes } from '../../enums/client-card/ProductTypes';
import { Observable, from } from 'rxjs';
import { HttpRestService } from '../http-client/http-rest-client.service';
import { BaseService } from '../base.service';

@Injectable()
export class ClientCardApiService extends BaseService {
    constructor(private restService: HttpRestService) {
        super();
    }

    public getCardConfig(dealId: number): Promise<ClientCardConfig> {
        return this.handleResponse(this.restService.get({
            url: `/clientCard/getClientCardConfig/${dealId}`
        }));
    }

    public getClientCardsByDealId(dealId: number): Observable<ClientCard[]> {
        return from(this.handleResponse(this.restService.get({
            url: `/clientCard/cardsByDealId/${dealId}`
        })));
    }

    public getClientCardId(cardId: number): Observable<ClientCard> {
        return from(this.handleResponse(this.restService.get({
            url: `/clientCard/card/${cardId}`
        })));
    }

    public createClientCard(card: ClientCard): Observable<number> {
        return from(this.handleResponse(this.restService.post({
            url: `/clientCard/card`,
            data: card
        })));
    }

    public updateClientCard(card: ClientCard): Observable<boolean> {
        return from(this.handleResponse(this.restService.put({
            url: `/clientCard/card`,
            data: card
        })));
    }

    public resetAvatarsCacheByFabric(fabricTitle: string): Observable<boolean> {
        return from(this.handleResponse(this.restService.put({
            url: `/clientCard/resetAvatarsCacheByFabric`,
            data: { fabric: fabricTitle }
        })));
    }

    public deleteClientCard(cardId: number): Observable<boolean> {
        return from(this.handleResponse(this.restService.delete({
            url: `/clientCard/card/${cardId}`
        })));
    }

    public generateDealAvatars(dealId: number, productTypeId: ProductTypes): Observable<boolean> {
        return from(this.handleResponse(this.restService.get({
            url: `/clientCard/generateDealAvatars/${dealId}/${productTypeId}`
        })));
    }

    public async getClientCardQrCodeDataByDealId(dealId: number): Promise<any> {
        const url = '/clientCard/clientCardQrCodeDataByDealId/' + dealId;
        return await this.handleResponse(this.restService.get({ url }));
    }

    public async clientCardPdf(file: any, clientCardId: any): Promise<any> {
        const formData: FormData = new FormData();
        formData.append('file', file, file.name);
        formData.append('clientCardId', clientCardId);
        const url = '/clientCard/upload-pdf';

        return this.handleResponse(this.restService.upload({
            url: url,
            data: formData
        }));
    }

    public async getClientCardPdf(id: number): Promise<Blob> {
        const url = '/clientCard/get-pdf/' + id;
        return await this.handleResponse(this.restService.get({ url }));
    }

    public async updateCustomLink(dealId: number, label: string, link: string): Promise<boolean> {
        const url = '/clientCard/updateCustomLink';
        const data = {
            dealId: dealId,
            label: label,
            link: link
        }
        return await this.handleResponse(this.restService.post({ url, data }));
    }
}