<div id="images-table">
	<table class="table table-bordered">
		<thead>
			<tr>
				<th scope="col" style="width: 20%;">Action Time</th>
				<th scope="col" style="width: 20%;">Stage</th>
				<th scope="col" style="width: 20%;">Type of follow-up</th>
				<th scope="col" style="width: 20%;">Author</th>
				<th scope="col" style="width: 20%;">Image</th>
			</tr>
		</thead>
		<tbody>
			@for (image of images; track image; let i = $index) {
			<tr>
				@if (i < countItemsToShow || isShowAll) { <td>{{image.modified | date :'dd-MMM-yyyy h:mm a'}}</td>
					<td>{{image.stageName}}</td>
					<td>{{getTypeOfFollowUp(image.stageId)}}</td>
					<td>{{image.modifiedByName}}</td>
					<td>
						<a [href]='image.url' target='_blank' style="color: #0275d8;" class="float-left">View</a>
						@if (isAllowEdit(image)) {
						<button value="Edit" (click)="editImage(image)" class="btn btn-outline-warning"
							style="float: right;">
							<i class="material-icons">edit</i>
						</button>
						}
					</td>

					}
			</tr>
			}
			@if (!(images && images.length > 0)) {
			<tr>
				<td colspan="5" class="text-center">
					<strong>No
						screenshots so far…</strong>
				</td>
			</tr>
			}
		</tbody>
	</table>

	<div style="width: 100%;">
		@if (images
		&& images.length > countItemsToShow
		&& !isShowAll && !(images.length === (countItemsToShow + 1)
		&& editedImage !== null)) {
		<button value="More" class="btn btn-outline-primary" (click)="showMore()"
			style="margin: 0px auto;border: 2px solid #343a40;display: block;"><i class="material-icons"
				style="color: #343a40;">
				expand_more
			</i></button>
		}
	</div>
</div>