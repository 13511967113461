import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'pd-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent {
  @Input() public message: string;
  @Input() public buttonConfirmText: string;
  @Input() public buttonRejectText: string;
  @Output() public onChose = new EventEmitter<boolean>();
  @Output() public onClose = new EventEmitter();

  callback: () => boolean;

  constructor() {
  }

  public confirm() {
    this.onChose.emit(true);
    this.onClose.emit();
  }

  public reject() {
    this.onChose.emit(false);
    this.onClose.emit();
  }

  public close() {
    this.onClose.emit();
  }
}