import { Component, Input, Output, EventEmitter, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Avatar } from '../../core/models/avatar-models/Avatar';
import { AvatarBodyTypes } from '../../core/enums/avatar/AvatarBodyTypes';
import { AvatarTypes } from '../../core/enums/avatar/AvatarTypes';
import { AvatarSkinTypes } from '../../core/enums/avatar/AvatarSkinTypes';
import { AvatarElementTypes } from '../../core/enums/avatar/AvatarElementTypes';
import { AvatarCollection } from '../../core/models/avatar-models/AvatarCollection';
import { BaseComponent } from '../../core/base.component';
import { AvatarFacadeService } from '../../core/services/avatar/avatar-facade.service';
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AvatarUtil } from '../../core/utils/avatar.util';
import { ImageUtil } from 'src/app/core/utils/image.util';
import { EnumUtil } from 'src/app/core/utils/enum.util';

@Component({
  selector: 'pd-avatar-seasonal-proposal-tile',
  templateUrl: './avatar-seasonal-proposal-tile.component.html',
  styleUrls: ['./avatar-seasonal-proposal-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class AvatarSeasonalProposalTileComponent extends BaseComponent implements OnInit, OnDestroy {
  @Output() public onEditAvatar = new EventEmitter<any>();
  @Output() public onDeleteAvatar = new EventEmitter<any>();
  @Output() public onDownloadAvatar = new EventEmitter<any>();
  @Output() public onRemoveAvatarFromCollection = new EventEmitter<any>();

  @Input() public avatarCollection: AvatarCollection;
  @Input() public avatar: Avatar;
  @Input() public isExistingAvatarCollection = false;

  public avatarCollections: AvatarCollection[];
  public AvatarElementTypes = AvatarElementTypes;
  public AvatarUtil = AvatarUtil;

  private deleteAvatarMessage = "Do you confirm deletion of this avatar?"
  private deleteAvatarFromCollectionMessage = "Do you confirm the removal of the avatar from the collection?"

  constructor(
    private avatarFacadeService: AvatarFacadeService
    , private cdRef: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {

    const combinedObservable = combineLatest(
      this.avatarFacadeService.getAllAvatarCollections()
      , this.avatarFacadeService.getAllAvatar2Collections());

    this.avatarFacadeService.getAllNonDealIdAvatars().subscribe(() => {
      if (!this.cdRef['destroyed']) {
        this.cdRef.detectChanges();
      }
    })

    combinedObservable.pipe(takeUntil(this.destroy$)).subscribe(([avatarCollections, avatar2Collections]) => {
      console.log('[avatarCollections, avatar2Collections]', avatarCollections, avatar2Collections, this.avatar);
      if (this.avatar) {
        const relationships = avatar2Collections.filter(x => x.avatarId === this.avatar.id);
        this.avatarCollections = avatarCollections.filter(x => relationships.some(y => y.avatarCollectionId === x.id));
      }

      if (!this.cdRef['destroyed']) {
        this.cdRef.detectChanges();
      }
    });

    if (this.avatar) {
      this.avatar.avatarElements = AvatarUtil.getSortedAvatarElements(this.avatar.avatarElements);
    }
  }

  ngOnDestroy() {
    this.cdRef.detach();
    super.ngOnDestroy();
  }

  public detectChanges() {
    if (!this.cdRef['destroyed']) {
      this.cdRef.detectChanges();
    }
  }

  //////////////////////////////// Events START //////////////////////////////

  public editAvatar(avatar: Avatar) {
    this.onEditAvatar.emit({ avatar: avatar, avatarCollectionId: this.avatarCollection.id });
  }

  public deleteAvatar(avatar: Avatar) {
    if (confirm(this.deleteAvatarFromCollectionMessage)) {
      this.onDeleteAvatar.emit({ avatarId: avatar.id });
    }
  }

  public removeAvatarFromCollection(avatar: Avatar) {
    if (confirm(this.deleteAvatarMessage)) {
      this.onRemoveAvatarFromCollection.emit({ avatarId: avatar.id, avatarCollectionId: this.avatarCollection.id });
    }
  }

  public downloadAvatar(avatar: Avatar) {
    this.onDownloadAvatar.emit({ avatarId: avatar.id });
  }

  //////////////////////////////// Events END //////////////////////////////

  //////////////////////////////// Private START //////////////////////////////

  //////////////////////////////// Private END //////////////////////////////

  //////////////////////////////// Helpers START ////////////////////////////////

  public getCollectionTooltip() {
    if (this.avatarCollections && this.avatarCollections.length > 0) {
      return this.avatarCollections.map(a => a.collectionName).join(', ');
    }
    return '';
  }

  public getAvatarLabel(avatarTypeId: number) {
    if (this.avatar && this.avatar.customTitle) return this.avatar.customTitle;

    return this.getAvatarType(avatarTypeId);
  }

  public getAvatarType(avatarTypeId: number) {
    return EnumUtil.getEnumName(AvatarTypes, avatarTypeId);
  }

  public getBodyType(bodyTypeId: number) {
    return EnumUtil.getEnumName(AvatarBodyTypes, bodyTypeId);
  }

  public getSkinType(skinTypeId: number) {
    return EnumUtil.getEnumName(AvatarSkinTypes, skinTypeId);
  }

  public getAvatarElementTypeLabel(avatarElementType: number) {
    let label = EnumUtil.getEnumName(AvatarElementTypes, avatarElementType);
    label = label.split('_')[0];
    return label;
  }

  public getFabricTitle(imageLink: string) {
    return ImageUtil.urlToFabric(imageLink);
  }

  public isFabric(imageLink: string): boolean {
    return ImageUtil.isFabric(imageLink);
  }

  //////////////////////////////// Helpers END ////////////////////////////////

  //////////////////////////////// View *ngIf START //////////////////////////////

  //////////////////////////////// View *ngIf END //////////////////////////////
}
