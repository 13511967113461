import { AppRouterService } from './core/services/app-router.service';
import { Component, HostListener, ViewChild } from '@angular/core';
import { LoginService } from './core/services/login.service';
import { UserContextService } from './core/services/user-context.service';
import { GlobalConstants } from './core/global-constants';
import { environment } from 'src/environments/environment';
import { DealWithContact } from './core/models/Deal';
import { RolesEnum } from './core/enums/RolesEnum';
import { UserData } from './core/models/UserData';
import { User } from './core/models/UserModel';
import { NavigationEnd, Router } from '@angular/router';
import { UsersService } from './core/services/users.service';
import { DealService } from './core/services/deals.service';
import * as stages from '../assets/stages_new_prospespects';
import { UserStoreService } from './core/services/stores/user-store.service';
import { StageFeatureStoreService } from './core/services/stores/stage-feature-store.service';
import { PipelineDealsStoreService } from './core/services/stores/pipeline-deal-store.service';
import { PipelineStoreService } from './core/services/stores/pipeline-store.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public currentUser: User;
  public usersWithRedDealsInMeetingConfirmPipeline: string;
  public personsBirthdayToday: string;
  public isAuth: boolean;
  public oauthLink: string;
  public searchText: string;
  public user: UserData;
  public showContactDropDown = false;
  public start: number;
  public deals = [];
  public more_in_provider = true;
  public lazyLoading = false;
  public contactLazyLoading = false;
  public dealsSearchSpinner = false;
  public contactSearchSpinner = false;
  public isAdmin = false;
  public RolesEnum = RolesEnum;
  public searchTextByContact = '';
  public contactStart: number;
  public dealsByContact: DealWithContact[] = [];
  public phones = [];
  public modeler3dUrl = environment.apiUrl3d;
  public salesChartUrl = environment.salesChartUrl;
  public ROUTE_NAMES = GlobalConstants.ROUTE_NAMES
  private pageSize = 20;
  public copied = false;

  @ViewChild('contactContainer') contactContainer: any;
  @ViewChild('navbarCollapse') navbarCollapse: any;

  constructor(
    private loginService: LoginService,
    private userContextService: UserContextService,
    private router: Router,
    private usersService: UsersService,
    private dealService: DealService,
    private routerService: AppRouterService,
    private userStoreService: UserStoreService,
    private stageFeatureStoreService: StageFeatureStoreService,
    private pipelineDealsStoreService: PipelineDealsStoreService,
    private pipelineStoreService: PipelineStoreService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer
  ) {
    iconRegistry.addSvgIcon('fa-check-double', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/fa-check-double.svg'));
    iconRegistry.addSvgIcon('fa-check', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/fa-check.svg'));
    iconRegistry.addSvgIcon('fa-clock', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/fa-clock.svg'));
    iconRegistry.addSvgIcon('fa-paper-plane', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/fa-paper-plane.svg'));
    iconRegistry.addSvgIcon('fa-xmark', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/fa-xmark.svg'));
    iconRegistry.addSvgIcon('fa-paperclip', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/fa-paperclip.svg'));
    iconRegistry.addSvgIcon('fa-circle-exclamation', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/fa-circle-exclamation.svg'));
    iconRegistry.addSvgIcon('fa-exclamation', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/fa-exclamation.svg'));
  }

  public async ngOnInit(): Promise<void> {
    this.userContextService.isAuthorize.subscribe(async (isAuth) => {
      this.isAuth = isAuth;
      if (!isAuth) {
        this.currentUser = null;
        return;
      }
      this.start = 0;
      this.contactStart = 0;
      this.user = await this.usersService.getUsersInHeader();
      if (!this.currentUser || this.currentUser != this.userContextService.user.value) {
        this.currentUser = this.userContextService.user.value;
      }
      if (this.isAllowBirthdayToday) {
        this.getPersonsBirthdayToday();
      }
      if (this.isAllowUsersWithRedDealsInMeetingConfirmPipeline) {
        var result = await this.usersService.getUsersWithRedDealsByRoleAndPipeline(stages.Pipelines.ClothierMeetingConfirm, null);
        this.usersWithRedDealsInMeetingConfirmPipeline = result.map(u => u.name).join("\n");
      }
    });
    this.loginService.setUserContext();
    this.user = await this.usersService.getUsersInHeader();
    this.currentUser = this.userContextService.user.value;
    this.isAdmin = this.currentUser && this.currentUser.is_admin;
    if (await this.loginService.isAuth()) {
      this.userContextService.setauthorizeStatus(true);
    }
    else {
      if (localStorage.getItem(GlobalConstants.refreshTokenKey)) {
        setTimeout(async () => {
          await this.loginService.refreshTokenImmediately();
          this.userContextService.setauthorizeStatus(await this.loginService.isAuth());
        }, 1000);
      }
    }

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {

        if (this.searchText) {
          this.searchText = ' ';
          this.deals = null;
        } else if (this.searchTextByContact) {
          this.searchTextByContact = ' ';
          this.dealsByContact = null;
          this.phones = null;
        }
      }
    });
  }

  public isCopied() {
    this.copied = true;

    setTimeout(() => {
      this.copied = false;
    }, 1000);
  }

  public getDealsBySearchContact() {
    this.dealService.getDealsBySearchContact(this.searchTextByContact, this.contactStart, this.pageSize).then(result => {
      this.dealsByContact = result.data;
      this.contactStart = result.additional_data.pagination.next_start;
      this.more_in_provider = result.additional_data.pagination.more_items_in_collection;
      this.contactSearchSpinner = false;
      this.contactLazyLoading = false;
    });
  }

  public logout() {
    this.userContextService.resetContext();
    this.userStoreService.reset();
    this.stageFeatureStoreService.reset();
    this.pipelineDealsStoreService.reset();
    this.pipelineStoreService.reset();
    this.currentUser = null;
    this.isAuth = false;
    this.user = null;
    localStorage.setItem('filterUsers', null);
    localStorage.setItem('filterSettings', null);
    this.routerService.openLogin();
  }

  public closeDropDown(dropdown) {
    if (dropdown.id === 'contact-search') {
      this.showContactDropDown = false;
    }
  }

  public openDealInNewTab(deal: DealWithContact): string {
    return window.location.origin + "/deals/" + deal.id;
  }

  /**
   * Search by contact
   * add lazy loading in search
   * @param {any} event scrolling
   * @memberof HomeComponent
   */

  @HostListener('window:scroll', ['$event'])
  searchByContact() {
    if (this.contactContainer) {
      if (this.contactContainer.nativeElement.scrollTop === this.contactContainer.nativeElement.scrollHeight - this.contactContainer.nativeElement.clientHeight) {
        if (this.more_in_provider === true) {
          this.contactLazyLoading = true;
          this.dealService.getDealsBySearchContact(this.searchTextByContact, this.contactStart, this.pageSize).then(result => {
            setTimeout(() => {
              if (result.data) {
                result.data.forEach(element => {
                  this.dealsByContact.push(element);
                });
              }
              this.more_in_provider = result.additional_data.pagination.more_items_in_collection;
              this.contactStart = result.additional_data.pagination.next_start;
              this.contactLazyLoading = false;
            }, 300);
          },
            () => {
              this.contactLazyLoading = false;
            }
          );
        } else {
          this.contactLazyLoading = false;
        }
      }
    }
  }

  public getPersonsBirthdayToday() {
    this.dealService.getPersonsBirthdayToday().then(result => {
      this.personsBirthdayToday = (result != null) ? result.map(u => u.name).join("\n") : null;
    },
      () => { }
    );
  }

  public toggleDropDown(event, dropdown) {

    if (dropdown.id === 'contact-search' && event.key === 'Enter') {
      this.contactLazyLoading = false;
      this.dealsByContact = null;
      this.contactSearchSpinner = true;
      this.contactStart = 0;
      this.getDealsBySearchContact();
      this.showContactDropDown = true;
    }

    if (this.searchTextByContact.length === 0 || this.searchTextByContact.trim() === '') {
      this.dealsByContact = null;
      this.showContactDropDown = false;
    }

  }


  public onCollapse() {
    this.navbarCollapse.nativeElement.classList.remove("show")
  }

  public onTogleCollapse() {
    if (this.navbarCollapse.nativeElement.classList.contains("show")) {
      this.navbarCollapse.nativeElement.classList.remove("show");
    }
    else {
      this.navbarCollapse.nativeElement.classList.add("show");
    }
  }

  //////////////////////////////// VIEW *ngIF START //////////////////////////////

  public get isAllowTasksTab(): boolean {
    return this.currentUser.role_id === RolesEnum.MasterLead
      || (this.currentUser.role_id === RolesEnum.Admin && this.currentUser.isTasksTab);
  }

  public get isAllowMapTab(): boolean {
    return this.currentUser.role_id === RolesEnum.MasterLead
      || this.currentUser.role_id === RolesEnum.Admin
      || this.currentUser.role_id === RolesEnum.Clothier
      || this.currentUser.role_id === RolesEnum.BB;
  }

  public get isAllowUsersTab(): boolean {
    return this.currentUser.role_id === RolesEnum.MasterLead
      || (this.currentUser.role_id === RolesEnum.Admin && this.currentUser.isUsersTab);
  }

  public get isAllowTOPsTab(): boolean {
    return this.currentUser.role_id === RolesEnum.MasterLead
      || this.currentUser.role_id === RolesEnum.Admin
      || this.currentUser.role_id === RolesEnum.Clothier;
  }

  public get isAllowUploadHotlistTab(): boolean {
    return this.currentUser.role_id !== RolesEnum.Recruiter;
  }

  public get isAllowPaymentTab(): boolean {
    return this.currentUser.role_id === RolesEnum.MasterLead
      || this.currentUser.role_id === RolesEnum.Admin
      || this.currentUser.role_id === RolesEnum.Clothier;
  }

  public get isAllowClientSettingsTab(): boolean {
    return this.currentUser.role_id === RolesEnum.MasterLead
      || this.currentUser.role_id === RolesEnum.Admin
      || this.currentUser.role_id === RolesEnum.Clothier;
  }

  public get isAllowUsersWithRedDealsInMeetingConfirmPipeline(): boolean {
    return this.currentUser.role_id === RolesEnum.MasterLead
      || this.currentUser.role_id === RolesEnum.EA
      || this.currentUser.role_id === RolesEnum.EaCaller;
  }

  public get isAllowBirthdayToday(): boolean {
    return this.currentUser.role_id === RolesEnum.MasterLead
      || this.currentUser.role_id === RolesEnum.Admin
      || this.currentUser.role_id === RolesEnum.EA
      || this.currentUser.role_id === RolesEnum.EaCaller
      || this.currentUser.role_id === RolesEnum.Clothier;
  }

  public get isAllowAchievement(): boolean {
    return this.currentUser.role_id === RolesEnum.MasterLead
      || this.currentUser.role_id === RolesEnum.Admin
      || this.currentUser.role_id === RolesEnum.EA
      || this.currentUser.role_id === RolesEnum.EaCaller
      || this.currentUser.role_id === RolesEnum.Clothier;
  }
}
