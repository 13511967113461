<div id="print-wardrobe-plan-section">
  <div>
    <div>
      @if (wardrobeImage1) {
      <img [src]="wardrobeImage1.url">
      }
      @if (!(wardrobeImage1)) {
      <img src="../../assets/Wardrobe_Evaluation_1.jpg">
      }
    </div>
    <div>
      @if (wardrobeImage2) {
      <img [src]="wardrobeImage2.url">
      }
      @if (!(wardrobeImage2)) {
      <img src="../../assets/Wardrobe_Evaluation_2.jpg">
      }
    </div>
  </div>
</div>