import { Injectable } from "@angular/core";
import { Avatar } from "../../models/avatar-models/Avatar";
import { AvatarStoreService } from "./avatar-store.service";
import { AvatarApiService } from "./avatar-api.service";
import { Observable } from "rxjs";
import { catchError, finalize, take, tap } from "rxjs/operators";
import { Avatar2CollectionApiService } from "./avatar-2-collection-api.service";
import { AvatarCollection } from "../../models/avatar-models/AvatarCollection";
import { AvatarCollectionApiService } from "./avatar-collection-api.service";
import { Avatar2Collection } from "../../models/avatar-models/Avatar2Collection";
import { AvatarBuilderData } from "../../models/avatar-models/AvatarBuilderData";

@Injectable()
export class AvatarEffectsService {

    constructor(
        private avatarStoreService: AvatarStoreService
        , private avatarApiService: AvatarApiService
        , private avatarCollectionApiService: AvatarCollectionApiService
        , private avatar2CollectionApiService: Avatar2CollectionApiService
    ) {
    }

    //Avatar
    public loadAllNonDealIdAvatars(): Observable<Avatar[]> {
        return this.avatarApiService.getAllNonDealIdAvatars().pipe(
            tap((avatars: Avatar[]) => {
                console.log('loadAllAvatars', avatars);
                this.avatarStoreService.setAllAvatars(avatars);
            }));
    }

    public createAvatar(avatarBuilderData: AvatarBuilderData): Observable<number> {
        return this.avatarApiService.createAvatar(avatarBuilderData).pipe(
            tap(async (avatarId: number) => {
                if (avatarId) {
                    const avatar = await this.avatarApiService.getAvatar(avatarId).toPromise();
                    const avatars = this.avatarStoreService.getAllAvatarsSnapshot();
                    avatars.push(avatar);
                    this.avatarStoreService.setAllAvatars(avatars);

                    this.reloadAvatarsByImageLinks(avatarBuilderData);
                }
            })
        )
    }

    public createAvatarDuplicate(avatarId: number, dealId: number = null, clientCardId: number = null): Observable<number> {
        return this.avatarApiService.createAvatarDuplicate(avatarId, dealId, clientCardId).pipe(
            tap(async (avatarId: number) => {
                if (avatarId) {
                    const avatar = await this.avatarApiService.getAvatar(avatarId).toPromise();
                    const avatars = this.avatarStoreService.getAllAvatarsSnapshot();
                    avatars.push(avatar);
                    this.avatarStoreService.setAllAvatars(avatars);
                }
            })
        )
    }

    public updateAvatar(avatarId: number, avatarBuilderData: AvatarBuilderData): Observable<boolean> {
        return this.avatarApiService.updateAvatar(avatarId, avatarBuilderData).pipe(
            tap(async (isUpdated: boolean) => {
                if (isUpdated) {
                    const avatar = await this.avatarApiService.getAvatar(avatarId).toPromise();
                    const avatars = this.avatarStoreService.getAllAvatarsSnapshot();
                    const resultAvatats = avatars.filter(a => a.id != avatarId);
                    resultAvatats.push(avatar);

                    console.log("updateAvatar", avatar, avatars, resultAvatats);
                    this.avatarStoreService.setAllAvatars(resultAvatats);

                    this.reloadAvatarsByImageLinks(avatarBuilderData);
                }
            })
        )
    }

    public deleteAvatar(avatarId: number): Observable<boolean> {
        return this.avatarApiService.deleteAvatar(avatarId).pipe(
            tap((isDeleted: boolean) => {
                if (isDeleted) {
                    const avatars = this.avatarStoreService.getAllAvatarsSnapshot();
                    const avatar2Collections = this.avatarStoreService.getAllAvatar2CollectionsSnapshot();
                    const resultAvatars = avatars.filter((avatar: Avatar) => avatar.id !== avatarId);
                    const resultAvatar2Collections = avatar2Collections.filter((avatar2Collection: Avatar2Collection) => avatar2Collection.avatarId !== avatarId);
                    this.avatarStoreService.setAllAvatars(resultAvatars);
                    this.avatarStoreService.setAllAvatar2Collections(resultAvatar2Collections);
                }
            })
        );
    }

    public downloadAvatar(avatarId: number): Observable<string> {
        return this.avatarApiService.downloadAvatar(avatarId);
    }

    public downloadAllNonDealId(): Observable<Blob> {
        return this.avatarApiService.downloadAllNonDealId();
    }

    //AvatarCollection
    public loadAvatarCollections(): Observable<AvatarCollection[]> {
        return this.avatarCollectionApiService.getAllCollections().pipe(
            tap((avatarCollections: AvatarCollection[]) => {
                console.log('loadAvatarCollections', avatarCollections);
                this.avatarStoreService.setAllAvatarCollections(avatarCollections);
            }));
    }

    public addCollection(collectionName: string): Observable<number> {
        return this.avatarCollectionApiService.addCollection(collectionName).pipe(
            tap(async (avatarCollectionId: number) => {
                if (avatarCollectionId) {
                    const avatarCollection = await this.avatarCollectionApiService.getCollection(avatarCollectionId).toPromise();
                    const avatarCollections = this.avatarStoreService.getAllAvatarCollectionsSnapshot();
                    avatarCollections.push(avatarCollection);
                    this.avatarStoreService.setAllAvatarCollections(avatarCollections);
                }
            })
        );
    }

    public updateAvatarCollection(avatarCollectionId: number, collectionName: string): Observable<boolean> {
        return this.avatarCollectionApiService.updateCollection(avatarCollectionId, collectionName).pipe(
            tap((isUpdated: boolean) => {
                if (isUpdated) {
                    const avatarCollections = this.avatarStoreService.getAllAvatarCollectionsSnapshot();
                    const updatedCollection = avatarCollections.find((avatarCollection: AvatarCollection) => avatarCollection.id === avatarCollectionId);

                    console.log('updateAvatarCollection', avatarCollectionId, collectionName, avatarCollections, updatedCollection);

                    updatedCollection.collectionName = collectionName;
                    this.avatarStoreService.setAllAvatarCollections(avatarCollections);
                }
            })
        );
    }

    public setSeasonalProposalCollection(avatarCollectionId: number, isChecked: boolean): Observable<boolean> {
        return this.avatarCollectionApiService.setSeasonalProposalCollection(avatarCollectionId, isChecked).pipe(
            tap((isUpdated: boolean) => {
                if (isUpdated) {
                    const avatarCollections = this.avatarStoreService.getAllAvatarCollectionsSnapshot();
                    const updatedCollection = avatarCollections.find((avatarCollection: AvatarCollection) => avatarCollection.id === avatarCollectionId);
                    console.log('setSeasonalProposalCollection', avatarCollectionId, avatarCollections, updatedCollection);

                    avatarCollections.forEach((avatarCollection: AvatarCollection) => avatarCollection.isSeasonalProposal = false);
                    updatedCollection.isSeasonalProposal = isChecked;

                    this.avatarStoreService.setAllAvatarCollections(avatarCollections);
                }
            })
        );
    }

    public setStylesAvailableCollection(avatarCollectionId: number, isChecked: boolean): Observable<boolean> {
        return this.avatarCollectionApiService.setStylesAvailableCollection(avatarCollectionId, isChecked).pipe(
            tap((isUpdated: boolean) => {
                if (isUpdated) {
                    const avatarCollections = this.avatarStoreService.getAllAvatarCollectionsSnapshot();
                    const updatedCollection = avatarCollections.find((avatarCollection: AvatarCollection) => avatarCollection.id === avatarCollectionId);
                    console.log('setStylesAvailableCollection', avatarCollectionId, avatarCollections, updatedCollection);

                    avatarCollections.forEach((avatarCollection: AvatarCollection) => avatarCollection.isStylesAvailable = false);
                    updatedCollection.isStylesAvailable = isChecked;

                    this.avatarStoreService.setAllAvatarCollections(avatarCollections);
                }
            })
        );
    }

    public setGroupedView(avatarCollectionId: number, isChecked: boolean): Observable<boolean> {
        return this.avatarCollectionApiService.setGroupedView(avatarCollectionId, isChecked);
    }

    public deleteAvatarCollection(avatarCollectionId: number): Observable<boolean> {
        return this.avatarCollectionApiService.deleteCollection(avatarCollectionId).pipe(
            tap((isDeleted: boolean) => {
                if (isDeleted) {
                    const avatarCollections = this.avatarStoreService.getAllAvatarCollectionsSnapshot();
                    const avatar2Collections = this.avatarStoreService.getAllAvatar2CollectionsSnapshot();
                    const resultAvatarCollections = avatarCollections.filter((avatarCollection: AvatarCollection) => avatarCollection.id !== avatarCollectionId);
                    const resultAvatar2Collections = avatar2Collections.filter((avatar2Collection: Avatar2Collection) => avatar2Collection.avatarCollectionId !== avatarCollectionId);
                    this.avatarStoreService.setAllAvatarCollections(resultAvatarCollections);
                    this.avatarStoreService.setAllAvatar2Collections(resultAvatar2Collections);
                }
            })
        );
    }

    public downloadCollection(avatarCollectionId: number): Observable<Blob> {
        return this.avatarCollectionApiService.downloadCollection(avatarCollectionId);
    }

    //Avatar2Collection
    public loadAvatar2Collections(): Observable<Avatar2Collection[]> {
        return this.avatar2CollectionApiService.getAllAvatar2Collections().pipe(
            tap((avatar2Collections: Avatar2Collection[]) => {
                console.log('loadAvatar2Collections', avatar2Collections);
                this.avatarStoreService.setAllAvatar2Collections(avatar2Collections);
            })
        );
    }

    public addAvatarToCollection(avatarId: number, avatarCollectionId: number): Observable<number> {
        return this.avatar2CollectionApiService.addAvatarToCollection(avatarId, avatarCollectionId).pipe(
            tap(async (relationshipId: number) => {
                if (relationshipId) {
                    const relationship = await this.avatar2CollectionApiService.getAvatar2Collection(relationshipId).toPromise();
                    const avatar2Collections = this.avatarStoreService.getAllAvatar2CollectionsSnapshot();

                    console.log('addAvatarToCollection', relationshipId, relationship, avatar2Collections);

                    avatar2Collections.push(relationship);
                    this.avatarStoreService.setAllAvatar2Collections(avatar2Collections);
                }
            })
        );
    }

    public removeAvatarFromCollection(avatarId: number, avatarCollectionId: number): Observable<boolean> {
        return this.avatar2CollectionApiService.removeAvatarFromCollection(avatarId, avatarCollectionId).pipe(
            tap((isDeleted: boolean) => {
                if (isDeleted) {
                    const avatar2Collections = this.avatarStoreService.getAllAvatar2CollectionsSnapshot();
                    const resultAvatar2Collections = avatar2Collections
                        .filter((avatar2Collection: Avatar2Collection) => !(avatar2Collection.avatarId === avatarId && avatar2Collection.avatarCollectionId === avatarCollectionId));

                    console.log('removeAvatarFromCollection', avatarId, avatarCollectionId, isDeleted, avatar2Collections, resultAvatar2Collections);

                    this.avatarStoreService.setAllAvatar2Collections(resultAvatar2Collections);
                }
            })
        );
    }

    private async reloadAvatarsByImageLinks(avatarBuilderData: AvatarBuilderData): Promise<Observable<boolean>> {
        console.log("reloadAvatarsByImageLink", avatarBuilderData);
        const imageLinks = avatarBuilderData.options.map(o => o.imageLink);
        const avatars = this.avatarStoreService.getAllAvatarsSnapshot();
        const avatarsForReload = avatars.filter(a => a.avatarElements.some(o => imageLinks.includes(o.imageLink)));
        const resultAvatars = avatars.filter(a => !avatarsForReload.some(b => b.id == a.id));

        if (avatarsForReload.length == 0) {
            console.log("No avatars for reset");
            return;
        }

        for (let avatar of avatarsForReload) {
            const resetedAvatar = await this.avatarApiService.getAvatar(avatar.id).toPromise();
            resultAvatars.push(resetedAvatar);
        }

        this.avatarStoreService.setAllAvatars(resultAvatars);
        console.log("avatars", avatars)
        console.log("avatarsForReload", avatarsForReload)
        console.log("resultAvatars", resultAvatars)
    }
}
