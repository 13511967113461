import { NgModule } from '@angular/core';
import { AvatarSeasonalProposalComponent } from './avatar-seasonal-proposal.component';
import { AvatarSeasonalProposalExpansionPanelComponent } from './avatar-seasonal-proposal-expansion-panel/avatar-seasonal-proposal-expansion-panel.component';
import { AvatarSeasonalProposalGrouppedExpansionPanelComponent } from './avatar-seasonal-proposal-groupped-expansion-panel/avatar-seasonal-proposal-groupped-expansion-panel.component';
import { AvatarSeasonalProposalContainerComponent } from './avatar-seasonal-proposal-container/avatar-seasonal-proposal-container.component';
import { AvatarSeasonalProposalTileComponent } from './avatar-seasonal-proposal-tile/avatar-seasonal-proposal-tile.component';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';

@NgModule({
    imports: [
        CommonModule,
        SharedModule
    ],
    declarations: [
        AvatarSeasonalProposalComponent,
        AvatarSeasonalProposalExpansionPanelComponent,
        AvatarSeasonalProposalGrouppedExpansionPanelComponent,
        AvatarSeasonalProposalContainerComponent,
        AvatarSeasonalProposalTileComponent,
    ],
    exports: [
        AvatarSeasonalProposalComponent,
        AvatarSeasonalProposalExpansionPanelComponent,
        AvatarSeasonalProposalGrouppedExpansionPanelComponent,
        AvatarSeasonalProposalContainerComponent,
        AvatarSeasonalProposalTileComponent,
    ]
})
export class AvatarSeasonalProposalModule { }
