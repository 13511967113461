<div mat-dialog-title>
  <button type="button" mdbBtn (click)="close()" class="remove waves-light btn-danger btn float-right"
    style="margin: 0; margin-right: 15px;" aria-label="Close" mdbWavesEffect>
    <i class="material-icons">close</i>
  </button>
</div>
<mat-dialog-content>
  <div class="wrapper" style="
      max-height: 60vh;
      overflow-y: auto;
      overflow-x: hidden;">
    <div>

      <div class="row">
        <div class="col">
          <div style="width: 500px; margin: 0 auto; height: 500px;">
            <image-cropper #imageCropper [imageChangedEvent]="imageChangedEvent" [output]="'base64'"
              (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded($event)"
              (cropperReady)="cropperReady($event)" resizeToWidth="500" resizeToHeight="500"
              [containWithinAspectRatio]="true" (loadImageFailed)="loadImageFailed($event)"></image-cropper>
          </div>
        </div>
        <div class="col">
          <div style="width: 500px; margin: 0 auto; height: 500px;">
            @if (croppedImage) {
            <img [src]="croppedImage" style="position: absolute;" />
            }
          </div>
        </div>
      </div>

      <input #fileInput type="file" class="upload" (change)="fileChangeEvent($event)" [accept]="fileAccept"
        style="display: none">
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button type="button" mdbBtn (click)="openInput()" class="btn btn-success" aria-label="Close" mdbWavesEffect>Choose
    file</button>
  <button type="button" mdbBtn (click)="saveImage()" class="btn btn-primary" aria-label="Close"
    [disabled]="!croppedImage" mdbWavesEffect>Save
    Head</button>
</mat-dialog-actions>