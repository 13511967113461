<div style="justify-content: center;">
  <div class="row">
    <div class="col-6 drop-down">
      <mat-form-field appearance="fill">
        <mat-label>Choose the product</mat-label>
        <mat-select [(ngModel)]="selectedOutfitPart">
          @for (outfitPart of outfitParts; track outfitPart) {
          <mat-option [value]="outfitPart.key">{{outfitPart.value}}
          </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-6 drop-down">
      <mat-form-field appearance="fill">
        <mat-label>Choose the line</mat-label>
        <mat-select [(ngModel)]="selectedLine">
          <mat-option value="" selected>None</mat-option>
          @for (line of lines; track line) {
          <mat-option [value]="line.attributeId">{{line.name}}</mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div>
      <div style="margin: 10px 0;">
        <input #fileInput type="file" (change)="onFileSelected($event)" accept=".jpg" multiple>
      </div>
      <div class="row">
        <button class="col-6 btn btn-success waves-light fabric-btn" type="submit" (click)="onUploadFile()">Upload
          File</button>
        <button class="col-6 btn btn-danger waves-light fabric-btn" [routerLink]="['/', tasksRoute]">Cancel</button>
      </div>
    </div>
  </div>

  @if (isShowProgressMessages) {
  <div class="row upload-message">
    {{getProgressMessage()}}
  </div>
  }

  @if (uploadMessage) {
  <div class="row upload-message">
    {{uploadMessage}}
  </div>
  }

  @if (spinner) {
  <div class="spinner">
    <mat-spinner></mat-spinner>
  </div>
  }

  <div class="row alert-row">
    @if (isShowValidationMessages && !selectedOutfitPart) {
    <div class="alert alert-danger">
      Please choose the type of product
    </div>
    }
  </div>
  <div class="row alert-row">
    @if (isShowValidationMessages && !lineValidation()) {
    <div class="alert alert-danger">
      Line is required for Jackets, Pants, Suits, Shirts
    </div>
    }
  </div>
  <div class="row alert-row">
    @if (isShowValidationMessages && !fileValidation()) {
    <div class="alert alert-danger">
      Please select .JPG files
    </div>
    }
  </div>
</div>