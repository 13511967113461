import { ComponentFactoryResolver, ApplicationRef, Injector, ComponentRef, Injectable } from '@angular/core';
import { ConfirmModalComponent } from 'src/app/shared/confirm-modal/confirm-modal.component';

@Injectable()
export class ConfirmService {
    private confirmComponentRef: ComponentRef<ConfirmModalComponent>;

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private appRef: ApplicationRef,
        private injector: Injector
    ) { }

    showDialog(message: string, buttonConfirmText: string = 'Yes', buttonRejectText: string = 'No'): ConfirmModalComponent {
        if (this.confirmComponentRef) {
            this.closeDialog();
        }

        const factory = this.componentFactoryResolver.resolveComponentFactory(ConfirmModalComponent);
        this.confirmComponentRef = factory.create(this.injector);

        this.confirmComponentRef.instance.message = message;
        this.confirmComponentRef.instance.buttonConfirmText = buttonConfirmText;
        this.confirmComponentRef.instance.buttonRejectText = buttonRejectText;
        this.confirmComponentRef.instance.onClose.subscribe(() => {
            this.closeDialog();
        });

        this.appRef.attachView(this.confirmComponentRef.hostView);
        document.body.appendChild(this.confirmComponentRef.location.nativeElement);

        return this.confirmComponentRef.instance;
    }

    private closeDialog() {
        if (this.confirmComponentRef) {
            this.appRef.detachView(this.confirmComponentRef.hostView);
            this.confirmComponentRef.destroy();
            this.confirmComponentRef = null;
        }
    }
}