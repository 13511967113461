import { Component, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { AvatarCollection } from '../../core/models/avatar-models/AvatarCollection';
import { Avatar } from '../../core/models/avatar-models/Avatar';
import { BaseComponent } from '../../core/base.component';
import { Avatar2Collection } from '../../core/models/avatar-models/Avatar2Collection';
import { AvatarSortingTypes } from '../../core/enums/avatar/AvatarSortingTypes';
import { AvatarApiService } from '../../core/services/avatar/avatar-api.service';
import { AvatarSeasonalProposalContainerComponent } from '../avatar-seasonal-proposal-container/avatar-seasonal-proposal-container.component';

@Component({
  selector: 'pd-avatar-seasonal-proposal-groupped-expansion-panel',
  templateUrl: './avatar-seasonal-proposal-groupped-expansion-panel.component.html',
  styleUrls: ['./avatar-seasonal-proposal-groupped-expansion-panel.component.scss']
})

export class AvatarSeasonalProposalGrouppedExpansionPanelComponent extends BaseComponent {
  @ViewChild('container') container: AvatarSeasonalProposalContainerComponent;

  @Output() public onEditAvatar = new EventEmitter<any>();
  @Output() public onDeleteAvatar = new EventEmitter<any>();
  @Output() public onRemoveAvatarFromCollection = new EventEmitter<any>();
  @Output() public onDownloadAvatar = new EventEmitter<any>();

  @Input() public avatarCollection: AvatarCollection;
  @Input() public avatar2Collections: Avatar2Collection[];
  @Input() public avatars: Avatar[];
  @Input() public avatarSortingType: AvatarSortingTypes;
  @Input() public isExistingAvatarCollection: boolean;
  @Input() public isNeedLoadAvatars: boolean;
  @Input() public isLoadAll: boolean;

  private loadCount: number = 8;
  private loadCountStep: number = 8;

  constructor(private avatarApiService: AvatarApiService) {
    super();
  }

  //////////////////////////////// Events START //////////////////////////////

  public editAvatar(event) {
    this.onEditAvatar.emit(event);
  }

  public deleteAvatar(event) {
    this.onDeleteAvatar.emit(event);
  }

  public removeAvatarFromCollection(event) {
    this.onRemoveAvatarFromCollection.emit(event);
  }

  public downloadAvatar(event) {
    this.onDownloadAvatar.emit(event);
  }

  public getAvatars() {
    if (this.avatars) {
      const sortedAvatars = this.avatars.sort((a, b) => b.id - a.id);

      if (this.isLoadAll) {
        return sortedAvatars;
      }

      const filteredAvatars = sortedAvatars.filter(avatar => avatar.base64);

      const uniqueAvatars = Array.from(new Set(
        sortedAvatars.slice(0, this.loadCount).concat(filteredAvatars)
          .map(avatar => avatar.id)
      )).map(id => this.avatars.find(avatar => avatar.id === id))
        .sort((a, b) => b.id - a.id);

      return uniqueAvatars;
    }
    return [];
  }

  public async loadAllAvatars() {
    this.isLoadAll = true;
    await this.loadAvatarsBase64();
  }

  public async loadMoreAvatars() {
    this.loadCount += this.loadCountStep;
    await this.loadAvatarsBase64();
  }

  public async onOpened() {
    this.isNeedLoadAvatars = true;
    await this.loadAvatarsBase64();
  }

  public async loadAvatarsBase64IfNeed() {
    if (!this.isNeedLoadAvatars) {
      return;
    }
    await this.loadAvatarsBase64();
  }

  public async loadAvatarsBase64() {
    const avatars = this.getAvatars();
    const ids = avatars.filter((avatar: Avatar) => !avatar.base64).map((avatar: Avatar) => avatar.id);
    if (ids && ids.length > 0) {
      const avatarsBase64 = await this.avatarApiService.getAvatarsBase64(ids).toPromise();
      if (avatarsBase64 && avatarsBase64.length > 0) {
        for (const avatarBase64 of avatarsBase64) {
          let avatar = avatars.find((a: Avatar) => a.id === avatarBase64.id);
          if (avatar) {
            avatar.base64 = avatarBase64.base64;
          }
        }
      }
    }

    if (this.container) {
      this.container.detectChanges();
    }
  }

  //////////////////////////////// Events END //////////////////////////////

  //////////////////////////////// Private START //////////////////////////////

  //////////////////////////////// Private END //////////////////////////////

  //////////////////////////////// Helpers START ////////////////////////////////

  public getAvatarCount() {
    if (this.avatars) {
      return this.avatars.length;
    }
    return 0;
  }

  //////////////////////////////// Helpers END ////////////////////////////////

  //////////////////////////////// View *ngIf START //////////////////////////////

  //////////////////////////////// View *ngIf END //////////////////////////////
}