import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { filterNil } from "../../filter-nil";
import { Avatar } from "../../models/avatar-models/Avatar";

@Injectable()
export class AvatarDealStoreService {
    private readonly clientCardAvatarsByDealId$: BehaviorSubject<Avatar[]> = new BehaviorSubject<Avatar[]>(null);
    private readonly avatarsSeasonalProposalByDealId$: BehaviorSubject<Avatar[]> = new BehaviorSubject<Avatar[]>(null);
    private readonly avatarsStylesAvailableByDealId$: BehaviorSubject<Avatar[]> = new BehaviorSubject<Avatar[]>(null);
    private readonly avatarsCustomByDealId$: BehaviorSubject<Avatar[]> = new BehaviorSubject<Avatar[]>(null);

    public getClientCardAvatars(): Observable<Avatar[]> {
        return this.clientCardAvatarsByDealId$.asObservable().pipe(filterNil());
    }

    public getClientCardAvatarsSnapshot(): Avatar[] {
        return this.clientCardAvatarsByDealId$.getValue();
    }

    public setClientCardAvatars(avatars: Avatar[]): void {
        this.clientCardAvatarsByDealId$.next(avatars);
    }

    public getSeasonalProposalAvatars(): Observable<Avatar[]> {
        return this.avatarsSeasonalProposalByDealId$.asObservable().pipe(filterNil());
    }

    public getSeasonalProposalAvatarsSnapshot(): Avatar[] {
        return this.avatarsSeasonalProposalByDealId$.getValue();
    }

    public setSeasonalProposalAvatars(avatars: Avatar[]): void {
        this.avatarsSeasonalProposalByDealId$.next(avatars);
    }

    public getStylesAvailableAvatars(): Observable<Avatar[]> {
        return this.avatarsStylesAvailableByDealId$.asObservable().pipe(filterNil());
    }

    public getStylesAvailableAvatarsSnapshot(): Avatar[] {
        return this.avatarsStylesAvailableByDealId$.getValue();
    }

    public setStylesAvailableAvatars(avatars: Avatar[]): void {
        this.avatarsStylesAvailableByDealId$.next(avatars);
    }

    public getCustomAvatars(): Observable<Avatar[]> {
        return this.avatarsCustomByDealId$.asObservable().pipe(filterNil());
    }

    public getCustomAvatarsSnapshot(): Avatar[] {
        return this.avatarsCustomByDealId$.getValue();
    }

    public setCustomAvatars(avatars: Avatar[]): void {
        this.avatarsCustomByDealId$.next(avatars);
    }
}