import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { EnumUtil } from 'src/app/core/utils/enum.util';
import { TemplateVariableContentTypes } from 'src/app/core/enums/TemplateVariableContentTypes';
import { MessageTemplateViewModel } from 'src/app/core/models/message-template/MessageTemplateViewModel';
import { BulkMessageRequestMessageTemplateVariableUpdateDto } from 'src/app/core/models/dto/BulkMessageRequestMessageTemplateVariableDto';
import { ReplaySubject } from 'rxjs';
import { User } from 'src/app/core/models/UserModel';
import { UsersService } from 'src/app/core/services/users.service';
import { RolesEnum } from 'src/app/core/enums/RolesEnum';
import { CustomSelectFilterWithoutLazyComponent } from 'src/app/shared/custom/custom-select-without-lazy/custom-select-without-lazy';

@Component({
  selector: 'pd-bulk-message-whatsapp',
  templateUrl: './bulk-message-whatsapp.component.html',
  styleUrls: ['./bulk-message-whatsapp.component.scss']
})
export class BulkMessageWhatsappComponent implements OnInit {
  @ViewChild("userFilter") public customSelectUser: CustomSelectFilterWithoutLazyComponent;

  @Input() public currentTemplate: MessageTemplateViewModel;
  @Input() public templates: MessageTemplateViewModel[];

  public TemplateVariableContentTypes = TemplateVariableContentTypes;
  public EnumUtil = EnumUtil;

  public usersLabel: string = 'Clothiers'
  public spinner: boolean = false;

  private allUsers = [];

  private filteredUserMultiSelect: ReplaySubject<User[]> = new ReplaySubject<User[]>(1);
  private filteredUsers = [];

  private expirationDate: Date;

  constructor(private usersService: UsersService) {
  }

  public async ngOnInit(): Promise<void> {
    this.spinner = true;
    const roles = [RolesEnum.Admin, RolesEnum.Clothier, RolesEnum.MasterLead];
    const result = await this.usersService.getUsersByRoles();

    this.allUsers = result.filter(x => roles.includes(x.role_id));
    this.filteredUsers = this.allUsers;
    this.getUsersFilterData();
    this.spinner = false;
  }

  public getUsersFilterData() {
    this.filteredUsers = [];
    this.filteredUsers = this.filteredUsers.concat(this.allUsers);
    this.filteredUserMultiSelect.next(this.allUsers.slice());
    if (this.customSelectUser) {
      this.customSelectUser.setData(this.filteredUsers);
    }
  }

  public getUsersAutocomplete(event) {
    let search = event.searchString;
    if (!search) {
      this.filteredUserMultiSelect.next(this.allUsers.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredUserMultiSelect.next(
      this.allUsers.filter(loc => loc.name.toLowerCase().indexOf(search) > -1)
    );
    this.filteredUsers = [];
    this.filteredUserMultiSelect.subscribe(x => {
      for (let i = 0; i < x.length; i++) {
        if (this.filteredUsers.length == 0) {
          this.filteredUsers.push(x[0]);
        } else {
          if (!this.filteredUsers.includes(x[i], 0)) {
            this.filteredUsers.push(x[i]);
          }
        }
      }
    });
    this.customSelectUser.setData(this.filteredUsers);
  }

  public selectExpirationDate(value) {
    this.expirationDate = value;
  }

  public templateSelectionChange(event) {
    this.currentTemplate = this.templates.find(f => f.id == event.value);
  }

  public isStaticVariable(variable) {
    return variable.templateVariableContentType == TemplateVariableContentTypes['Static Text'];
  }

  public getData() {
    const variables = new Array<BulkMessageRequestMessageTemplateVariableUpdateDto>;
    for (let i = 0; i < this.currentTemplate.variableDescriptions.length; i++) {
      const currentTemplateVariable = this.currentTemplate.variableDescriptions[i];
      const variable = new BulkMessageRequestMessageTemplateVariableUpdateDto();
      variable.variableDescriptionId = currentTemplateVariable.id;
      variable.staticValue = currentTemplateVariable.exampleData;
      variables.push(variable);
    }

    const data = { 
      messageTemplateId: this.currentTemplate.id, 
      variables: variables, 
      replyRecipientIds: this.customSelectUser?.dataArray.map((u) => u.id), 
      expirationDate: this.expirationDate 
    };

    return data;
  }

  public validation() {
    return true;
  }
}