<div class="header">
    <h2>Social Media Ads Tokens List</h2>
</div>
<div class="wrapper col">
    <div class="row" *ngIf="isMasterLeadAccount && false">
        <div class="col-md-6 col-xl-2">
            <div class="setting-label">Owner</div>
            <pd-custom-select-without-lazy #userFilter (loadDataEvent)="getUsersFilterData()"
                (selectedItemEvent)="onSelectedUserItem($event)" (autoCompleteEvent)="getUsersAutocomplete($event)"
                [dataLabel]="usersLabel" [startLabel]="usersLabel" [isMultiple]="false">
            </pd-custom-select-without-lazy>
        </div>
        <div class="col-md-6 col-xl-2">
            <div>
                <div class="setting-label">
                    Location
                </div>
            </div>
            <div>
                <div class="col">
                    <pd-custom-select-without-lazy #locationFilter (loadDataEvent)="getLocationFilterData()"
                        (selectedItemEvent)="onSelectedLocationItem($event)"
                        (autoCompleteEvent)="getLocationsAutocomplete($event)" [dataLabel]="locationLabel"
                        [startLabel]="locationLabel" [isMultiple]="false">
                    </pd-custom-select-without-lazy>
                </div>
            </div>
        </div>
        <div class="col-md-6 col-xl-2">
            <mat-radio-group aria-label="Select ads source" [(ngModel)]="adsSource"
                (change)="onAdsSourceChange($event)">
                <mat-radio-button [value]="SocialMediaAdsTypes.Instagram">Instagram</mat-radio-button>
                <mat-radio-button [value]="SocialMediaAdsTypes.Facebook">Facebook</mat-radio-button>
            </mat-radio-group>
        </div>
        <div class="col-md-6 col-xl-2">
            <button class="btn btn-success waves-light" [disabled]="!(validation())" (click)="onAdd()">Generate
                Link</button>
        </div>
    </div>
    <div *ngIf="referenceLink" class="reference-link">
        <a [href]="referenceLink" (click)="copyReferenceLinkToClipboard($event, referenceLink)">{{ referenceLink }}</a>
    </div>
    <div *ngIf="!spinner" class="scroll-table-container">
        <table mat-table>
            <thead>
                <tr mat-header-row>
                    <th mat-header-cell>Id</th>
                    <th mat-header-cell>Old Rep</th>
                    <th mat-header-cell>New Rep</th>
                    <th mat-header-cell>Location</th>
                    <th mat-header-cell>Ads Source</th>
                    <!-- <th mat-header-cell>Value</th> -->
                </tr>
            </thead>
            <tbody *ngIf="tokens?.length">
                <tr mat-row *ngFor="let token of tokens; let i = index; trackBy: trackByTokenId">
                    <td mat-cell>{{ i + 1 }}</td>
                    <td mat-cell>{{ token.adsUserName }}</td>
                    <td mat-cell>{{ token.ownerName }}</td>
                    <td mat-cell>{{ token.locationName }}</td>
                    <td mat-cell>{{ getAdsSourceName(token.adsSourceId) }}</td>
                    <!-- <td mat-cell class="reference-link"><a [href]="this.generateReferenceLink(token.tokenValue, token.adsSourceId)"
                            (click)="copyReferenceLinkToClipboard($event, this.generateReferenceLink(token.tokenValue, token.adsSourceId))">{{
                            token.tokenValue }}</a></td> -->
                </tr>
            </tbody>
        </table>
    </div>

    <div *ngIf="spinner" class="spinner">
        <mat-spinner></mat-spinner>
    </div>
</div>