import { ObjectUtil } from "../utils/object.util";

export class Tab {
    public isActive: boolean;
    public name: string;
    public id: number;

    constructor(data?: any) {
        ObjectUtil.extend(data, this);
    }
}
