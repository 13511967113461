import { Component, ViewChild, Output, EventEmitter, Input, ElementRef, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { AchievementCardType, AchievementGroup } from 'src/app/core/models/AchievementCardConfig';
import { User } from 'src/app/core/models/UserModel';
import { AchievementCardModel } from 'src/app/core/models/AchievementCardModel';
import { AchievementGroups } from 'src/app/core/enums/AchievementGroups';
import { FileUploader, FileUploaderOptions } from 'ng2-file-upload';
import { UploadImageService } from 'src/app/core/services/upload-image.service';
import { UserContextService } from 'src/app/core/services/user-context.service';
import { DateHelper } from 'src/app/core/utils/date.helper';
import { ImageTag } from 'src/app/core/enums/ImageTag.enum';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'pd-custom-achievement-card-modal',
  templateUrl: './custom-achievement-card-modal.component.html',
  styleUrls: ['./custom-achievement-card-modal.component.scss']
})

export class CustomAchievementCardModalComponent {
  @ViewChild('fileInput') fileInputViewChild: ElementRef;

  saveAchievementCard: any;
  deleteAchievementCard: any;

  @Input()
  ownerId: number;
  @Input()
  achievementGroup: AchievementGroup;
  @Input()
  achievementCardTypes: Array<AchievementCardType>;

  currentUser: User;

  prevCard: AchievementCardModel;
  editedCard: AchievementCardModel;

  achievementGroupEnum = AchievementGroups;
  fabricFilter: FormControl = new FormControl();
  maxDate: Date;
  isDateFromValide: boolean = true;
  imageUrl: any;

  isModalOpened = false;

  amountControl = new FormControl('');

  isShowAmountValidationMessage = false;

  uploaderOptions: FileUploaderOptions;
  imageUploader: FileUploader;

  isImageLoadSuccess = false;
  isImageLoadLoading = false;
  isImageLoadErrorValidation = false;

  fileAccept = UploadImageService.allowedImageTypes.join(',');

  isGroupped = false;
  groupAlertMessage = "Do you want to proceed? Please make a note that this action will affect all of the clothiers."
  deleteCardMessage = "Do you confirm deletion of this card?"

  constructor(
    private userContextService: UserContextService
    , @Inject(MAT_DIALOG_DATA) public data: any
    , private dialogRef: MatDialogRef<CustomAchievementCardModalComponent>
    , private sanitizer: DomSanitizer
    , private uploadImageServ: UploadImageService) {

    this.ownerId = data.ownerId;
    this.achievementCardTypes = data.achievementCardTypes;
    this.achievementGroup = data.achievementGroup;
    this.saveAchievementCard = data.saveAchievementCard;
    this.deleteAchievementCard = data.deleteAchievementCard;
    this.openAchievementCardModal(data.card);
  }

  ngOnInit() {
    this.initializeStore();
    this.maxDate = new Date();
  }

  private initializeStore() {
    this.currentUser = this.userContextService.user.value;
  }

  public openAchievementCardModal(editedCard: AchievementCardModel) {
    this.prevCard = editedCard;
    this.editedCard = this.copyCard(editedCard);
    this.findImgUrl();
    this.setFileUploader();
    this.resetModal();
    this.isModalOpened = true;
    this.isGroupped = !!(editedCard.groupId);
  }

  modalFinishAction(isConfirm) {
    isConfirm ? this.ok() : this.cancel();
  }

  private ok() {
    if (this.isGroupAlertCheck()) {
      if (this.fileInputViewChild && this.fileInputViewChild.nativeElement.value) {
        this.upload();
      }
      else {
        this.save();
      }
      this.dialogRef.close();
    }
  }

  private cancel() {
    this.resetModal();
    this.isModalOpened = false;
    this.dialogRef.close();
  }

  findImgUrl() {
    if (this.editedCard.customImageUrl) {
      this.imageUrl = this.editedCard.customImageUrl;
    }
    else {
      let achievementCardType = this.achievementCardTypes.find(a => a.id == this.editedCard.achievementCardTypeId);
      if (achievementCardType) {
        this.imageUrl = achievementCardType.imageUrl;
      }
    }
  }

  deleteCard() {
    if (confirm(this.deleteCardMessage) && this.isGroupAlertCheck()) {
      this.deleteAchievementCard({ card: this.prevCard, isGroupped: this.isGroupped });
      this.dialogRef.close();
    }
  }

  public isValid() {
    if (this.editedCard.date > this.maxDate) {
      return false;
    }

    switch (this.editedCard.achievementGroupId) {
      case AchievementGroups.BigSalePins:
      case AchievementGroups.RingOfHonourPins:
      case AchievementGroups.GoldSalePins:
      case AchievementGroups.DiamondSalePins:
        return (
          this.editedCard.date
          && this.editedCard.clientId
          && this.editedCard.orderId
          && this.editedCard.clientName
          && this.amountPatternValidator(this.editedCard.amount));

      case AchievementGroups.AnnualSalesAchievementPins:
      case AchievementGroups.MonthlySalesAchievementPins:
      case AchievementGroups.LeadershipAnnualAchievementPins:
      case AchievementGroups.LeadershipMonthlyAchievementPins:
        return (
          this.editedCard.date
          && this.editedCard.achievementCardTypeId);

      case AchievementGroups.ServiceAwards:
      case AchievementGroups.CompanyTripsWon:
      case AchievementGroups.CompanyMeetingsAttended:
        return (
          this.editedCard.date
          && this.editedCard.achievementCardTypeId
          && this.editedCard.customTitle);
    }
  }

  amountFormControlValidation() {
    if (this.amountControl.touched) {
      this.isShowAmountValidationMessage = !(+this.editedCard.amount > 0)
        || !this.amountPatternValidator(this.editedCard.amount)
    }
    else {
      this.isShowAmountValidationMessage = false;
    }
  }

  //////////////////////////////// Events START //////////////////////////////

  onDataChange(): void {
    if (this.editedCard.date && new Date(this.editedCard.date) <= this.maxDate) {
      this.isDateFromValide = true;
    }
    else {
      this.isDateFromValide = false;
    }
  }

  onClientIdInput(event) {
    this.editedCard.clientId = this.numberInputNormalize(event);
  }

  onOrderIdInput(event) {
    this.editedCard.orderId = this.numberInputNormalize(event);
  }

  onAmountInput(event) {
    if (event.target.value) {
      let val = event.target.value
      val = val.replace(',', '.');
      val = val.replace(/[^0-9\.]/g, '');
      val = val.replace(/^([^.]*\.)(.*)$/, function (a, b, c) { return b + c.replace(/\./g, ''); });

      event.target.value = val;

      let dollar = val.split('.')[0];
      let cents = val.split('.')[1];

      if (dollar && dollar.length > 10) {
        dollar = dollar.substring(0, 10);
        event.target.value = dollar;
      }

      if (cents) {
        if (cents.length == 1) {
          let cent = cents[0];
          event.target.value = dollar + '.' + cent;
        }

        if (cents.length >= 2) {
          let cent = cents[0] + cents[1];
          event.target.value = dollar + '.' + cent;
        }
      }
    }

    this.editedCard.amount = event.target.value;
  }

  //////////////////////////////// Events END ////////////////////////////////

  //////////////////////////////// Private START //////////////////////////////

  private save() {
    this.prevCard.id = this.editedCard.id;
    this.prevCard.ownerId = this.editedCard.ownerId;
    this.prevCard.clientId = this.editedCard.clientId;
    this.prevCard.orderId = this.editedCard.orderId;
    this.prevCard.achievementGroupId = this.editedCard.achievementGroupId;
    this.prevCard.achievementGroupName = this.editedCard.achievementGroupName;
    this.prevCard.achievementCardTypeId = (this.achievementCardTypes && this.achievementCardTypes.length == 1) ? this.achievementCardTypes[0].id : this.editedCard.achievementCardTypeId;
    this.prevCard.achievementCardTypeName = (this.achievementCardTypes && this.achievementCardTypes.length == 1) ? this.achievementCardTypes[0].name : this.editedCard.achievementCardTypeName;
    this.prevCard.date = new Date(DateHelper.formatAsISODateString(new Date(this.editedCard.date)));
    this.prevCard.groupId = this.editedCard.groupId;
    this.prevCard.clientName = this.editedCard.clientName;
    this.prevCard.customTitle = this.editedCard.customTitle;
    this.prevCard.customImageUrl = this.editedCard.customImageUrl;
    this.prevCard.amount = this.editedCard.amount;
    this.prevCard.link = this.editedCard.link;

    if (this.isGroupped && this.prevCard.id > 0) {
      this.prevCard.groupId = this.editedCard.groupId;
    }
    else {
      this.prevCard.groupId = null;
    }

    this.saveAchievementCard({ card: this.prevCard, isGroupped: this.isGroupped });
  }

  private copyCard(prevCard: AchievementCardModel): AchievementCardModel {
    let newCard = new AchievementCardModel(0, this.ownerId, this.achievementGroup.id);

    newCard.id = prevCard.id;
    newCard.ownerId = prevCard.ownerId;
    newCard.clientId = prevCard.clientId;
    newCard.orderId = prevCard.orderId;
    newCard.customTitle = prevCard.customTitle;
    newCard.customImageUrl = prevCard.customImageUrl;
    newCard.achievementGroupId = prevCard.achievementGroupId;
    newCard.achievementGroupName = prevCard.achievementGroupName;
    newCard.achievementCardTypeId = (this.achievementCardTypes && this.achievementCardTypes.length == 1) ? this.achievementCardTypes[0].id : prevCard.achievementCardTypeId;
    newCard.achievementCardTypeName = (this.achievementCardTypes && this.achievementCardTypes.length == 1) ? this.achievementCardTypes[0].name : prevCard.achievementCardTypeName;
    newCard.date = prevCard.date;
    newCard.groupId = prevCard.groupId;
    newCard.clientName = prevCard.clientName;
    newCard.amount = prevCard.amount;
    newCard.link = prevCard.link;

    return newCard;
  }

  private isGroupAlertCheck() {
    if (this.isAllowedToHaveGroup() && this.isGroupped) {
      return confirm(this.groupAlertMessage);
    }
    return true;
  }

  private amountPatternValidator(value): boolean {
    return (value?.toString() || '').match(/^\d+(\.\d{1,2})?$/);
  }

  private resetModal() {
    this.isShowAmountValidationMessage = false;
    this.imageUrl = null;

    if (this.fileInputViewChild) {
      this.fileInputViewChild.nativeElement.value = "";
      this.isImageLoadSuccess = false;
      this.isImageLoadLoading = false;
      this.isImageLoadErrorValidation = false;
    }

    this.findImgUrl();
  }

  //////////////////////////////// Private END ////////////////////////////////

  //////////////////////////////// Helpers START //////////////////////////////

  filterDateFrom = (date: Date): boolean => {
    return date <= this.maxDate;
  }

  numberInputNormalize(event) {
    if (event.target.value) {
      let val = event.target.value
      val = val.replace(/[^0-9\.]/g, '');
      event.target.value = val;
    }

    return event.target.value;
  }

  //////////////////////////////// Helpers END ////////////////////////////////

  //////////////////////////////// Image Loader START ////////////////////////////////

  public upload() {
    if (this.fileInputViewChild.nativeElement.value) {
      this.isImageLoadLoading = true;
      this.imageUploader.uploadAll();
    }
  }

  public changeListenerImage(files: FileList) {
    if (files && files.length > 0) {
      this.imageUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(files[0]));
    }
  }

  setFileUploader(): void {
    this.imageUploader = new FileUploader(this.uploadImageServ.uploaderOptions);
    this.imageUploader.onAfterAddingFile = (fileItem: any): any => {
      fileItem.imageTag = ImageTag.AchievementCard;
      this.isImageLoadErrorValidation = false;
      this.isImageLoadSuccess = false;
    };

    this.imageUploader.onWhenAddingFileFailed = (fileItem: any, filter: any, options: any): any => {
      this.isImageLoadErrorValidation = true;
      this.isImageLoadSuccess = false;
    };

    this.imageUploader.onBuildItemForm = this.onBuildItemForm;
    this.imageUploader.onSuccessItem = this.onSuccessItem;
    this.imageUploader.onErrorItem = this.onErrorItem;
  }

  onBuildItemForm = (fileItem: any, form: FormData): any => {
    form.append('fileName', fileItem.some.name);
    form.append('imageTag', fileItem.imageTag);
    fileItem.withCredentials = false;
    return { fileItem, form };
  };

  onSuccessItem = (fileItem: any, response: string, status: number, headers: any) => {
    let url = JSON.parse(response);

    this.fileInputViewChild.nativeElement.value = "";
    this.isImageLoadSuccess = true;
    this.isImageLoadLoading = false;

    this.editedCard.customImageUrl = url;
    this.imageUrl = url;

    this.save();
  }

  onErrorItem = (fileItem, response, status, headers) => {
  }

  onWhenAddingFileFailed = (fileItem: any, filter: any, options: any): any => {
    this.isImageLoadErrorValidation = true;
    this.isImageLoadSuccess = false;
  };

  //////////////////////////////// Image Loader END ////////////////////////////////

  //////////////////////////////// View *ngIf START //////////////////////////////

  isShowAchievementTypeField(): boolean {
    return this.achievementCardTypes && this.achievementCardTypes.length > 1;
  }

  isShowClientIdField(): boolean {
    return this.editedCard.achievementGroupId == AchievementGroups.BigSalePins
      || this.editedCard.achievementGroupId == AchievementGroups.RingOfHonourPins
      || this.editedCard.achievementGroupId == AchievementGroups.GoldSalePins
      || this.editedCard.achievementGroupId == AchievementGroups.DiamondSalePins;
  }

  isShowOrderIdField(): boolean {
    return this.editedCard.achievementGroupId == AchievementGroups.BigSalePins
      || this.editedCard.achievementGroupId == AchievementGroups.RingOfHonourPins
      || this.editedCard.achievementGroupId == AchievementGroups.GoldSalePins
      || this.editedCard.achievementGroupId == AchievementGroups.DiamondSalePins;
  }

  isShowClientNameField(): boolean {
    return this.editedCard.achievementGroupId == AchievementGroups.BigSalePins
      || this.editedCard.achievementGroupId == AchievementGroups.RingOfHonourPins
      || this.editedCard.achievementGroupId == AchievementGroups.GoldSalePins
      || this.editedCard.achievementGroupId == AchievementGroups.DiamondSalePins;
  }

  isShowAmountField(): boolean {
    return this.editedCard.achievementGroupId == AchievementGroups.BigSalePins
      || this.editedCard.achievementGroupId == AchievementGroups.RingOfHonourPins
      || this.editedCard.achievementGroupId == AchievementGroups.GoldSalePins
      || this.editedCard.achievementGroupId == AchievementGroups.DiamondSalePins;
  }

  isShowUploadImageField(): boolean {
    return this.editedCard.achievementGroupId == AchievementGroups.CompanyTripsWon
      || this.editedCard.achievementGroupId == AchievementGroups.CompanyMeetingsAttended;
  }

  isShowLinkField(): boolean {
    return this.editedCard.achievementGroupId == AchievementGroups.CompanyTripsWon
      || this.editedCard.achievementGroupId == AchievementGroups.CompanyMeetingsAttended;
  }

  isShowCustomTitleField(): boolean {
    return this.editedCard.achievementGroupId == AchievementGroups.ServiceAwards
      || this.editedCard.achievementGroupId == AchievementGroups.CompanyTripsWon
      || this.editedCard.achievementGroupId == AchievementGroups.CompanyMeetingsAttended;
  }

  isShowAnnualDateField(): boolean {
    return this.editedCard.achievementGroupId == AchievementGroups.AnnualSalesAchievementPins
      || this.editedCard.achievementGroupId == AchievementGroups.LeadershipAnnualAchievementPins;
  }

  isShowMonthlyDateField(): boolean {
    return this.editedCard.achievementGroupId == AchievementGroups.MonthlySalesAchievementPins
      || this.editedCard.achievementGroupId == AchievementGroups.LeadershipMonthlyAchievementPins;
  }

  isShowDefaultDateField(): boolean {
    return !(this.isShowAnnualDateField() || this.isShowMonthlyDateField());
  }

  isShowGroupCheckbox(): boolean {
    return this.isAllowedToHaveGroup()
      && !this.isExcludedFromGroup();
  }

  private isAllowedToHaveGroup() {
    return this.editedCard.achievementGroupId == AchievementGroups.CompanyMeetingsAttended
  }

  private isExcludedFromGroup(): boolean {
    return this.editedCard.id > 0 && !this.editedCard.groupId;
  }

  //////////////////////////////// View *ngIf END ////////////////////////////////
}
