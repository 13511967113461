<div mat-dialog-title>
  {{editedCard.fabricConfigTitle}}
</div>
<mat-dialog-content>
  <div class="modal-body">

    <div class="row hover">
      @if (!isAccessories) {
      <div class="card-info-label text">
        Fabric picture
      </div>
      }
      @if (isAccessories) {
      <div class="card-info-label text">
        Image
      </div>
      }
      <div class="text image">
        <img [src]="fabricImgUrl" width="200" height="200"
          onerror="this.onerror=null; this.src='../../assets/lgfg-logo.png'" />
      </div>
    </div>

    <div class="row hover">
      <div class="card-info-label text">
      </div>
      @if ((currentUser?.role_id === rolesEnum.Admin
      || currentUser?.role_id === rolesEnum.EA
      || currentUser?.role_id === rolesEnum.EaCaller
      || currentUser?.role_id === rolesEnum.MasterLead)) {
      <div class="col d-flex align-items-center">
        @if (!((editedCard.fabricImgTitle) && (editedCard.lineId || isAccessories))) {
        <div (click)="showFabricValidationMessage()">
          <div class="fabric-upload">Update Image</div>
        </div>
        }
        @if (((editedCard.fabricImgTitle) && (editedCard.lineId || isAccessories))) {
        <div (click)="fabricUploadFileInput.click()">
          <input #fabricUploadFileInput type="file" accept=".jpg" (change)="changeListenerFabric($event.target.files)"
            class="d-none">
          <div class="fabric-upload">Update Image</div>
        </div>
        }
      </div>
      }
    </div>

    <div class="row hover">
      <div class="card-info-label text">
        Type of fabric <span class="star">*</span>
      </div>
      <div class="col">
        <mat-form-field>
          <mat-label>Choose a type of fabric </mat-label>
          <mat-select [(value)]="editedCard.fabricConfigId">
            @for (fabricConfig of getfabricConfigs() | orderByNumber:'order'; track fabricConfig) {
            <mat-option [value]="fabricConfig.id">
              {{fabricConfig.title}}
            </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="row hover">
      <div class="card-info-label text">
        Date of order <span class="star">*</span>
      </div>
      <div class="col">
        <mat-form-field>
          <input matInput [matDatepicker]="dateFromPicker" placeholder="Choose a date"
            [(ngModel)]="editedCard.dateOfOrder" [max]="maxDate" [matDatepickerFilter]="filterDateFrom">
          <mat-datepicker-toggle matSuffix [for]="dateFromPicker">
          </mat-datepicker-toggle>
          <mat-datepicker #dateFromPicker (dateChange)="onDataChange()"></mat-datepicker>
        </mat-form-field>
      </div>
    </div>

    @if (isSuit) {
    <div class="row hover">
      <div class="card-info-label text">
        Type of suit <span class="star">*</span>
      </div>
      <div class="col">
        <mat-form-field>
          <mat-label>Choose a suit</mat-label>
          <mat-select [(ngModel)]="editedCard.typeOfSuitId">
            @for (typeOfSuit of typeOfSuits; track typeOfSuit) {
            <mat-option [value]="typeOfSuit.id">
              {{typeOfSuit.name}}
            </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    }

    @if (!isAccessories) {
    <div class="row hover">
      <div class="card-info-label text">
        Line <span class="star">*</span>
      </div>
      <div class="col">
        <mat-form-field>
          <mat-label>Choose a line</mat-label>
          <mat-select [(ngModel)]="editedCard.lineId">
            @for (line of lines; track line) {
            <mat-option [value]="line.lineId">
              {{line.name}}
            </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    }

    <div class="row hover">
      @if (!isAccessories) {
      <div class="card-info-label text">
        Fabric number <span class="star">*</span>
      </div>
      }
      @if (isAccessories) {
      <div class="card-info-label text">
        Image number
      </div>
      }
      <div class="col">
        <mat-form-field>
          @if (!isAccessories) {
          <mat-label>Choose a fabric number</mat-label>
          }
          @if (isAccessories) {
          <mat-label>Choose a image</mat-label>
          }
          <mat-select (selectionChange)="findFabricImg(editedCard)" [(ngModel)]="editedCard.fabricImgTitle">
            <div [matTooltip]='fileNameValidationMessage' #fileNameTooltip="matTooltip" matTooltipPosition="above">
            </div>
            <mat-option>
              <ngx-mat-select-search [formControl]="fabricFilter" placeholderLabel="Search" noEntriesFoundLabel="">
              </ngx-mat-select-search>
            </mat-option>
            @if (isAccessories && (editedCard.fabricImgTitle)) {
            <mat-option value="">
            </mat-option>
            }
            <div>
              @for (fabric of getFilteredFabrics(); track fabric) {
              <mat-option [value]="fabric.title">
                {{fabric.title}}
              </mat-option>
              }
              @if (((fabricFilter.value && fabricFilter.value.length >= searchLengthStart))) {
              <mat-option (click)="addFabricTitle(fabricFilter.value)" [value]="editedCard.fabricImgTitle">
                <span>
                  Not found. Add new fabric?
                </span>
                <mat-icon class="addbtn"> add_circle
                </mat-icon>
              </mat-option>
              }
            </div>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="d-flex">
    @if (editedCard.id > 0) {
    <button value="Remove" (click)="deleteCard()" class="btn btn-outline-danger min-btn">
      <i class="material-icons">delete</i>
    </button>
    }

    <div class="d-flex align-items-center" matTooltip="Upload PDF" matTooltipPosition="above">
      @if (editedCard.id > 0) {
      <button class="btn btn-outline-primary min-btn" (click)="clientCardInput.click()">
        <input #clientCardInput type="file" accept=".pdf" (change)="changeListenerPdf($event.target.files)"
          class="d-none">
        @if (!isPdfUploadSpinner) {
        <i class="material-icons">cloud_upload</i>
        }
      </button>
      }
      @if (isPdfUploadSpinner) {
      <div>
        <mat-spinner [diameter]="15"></mat-spinner>
      </div>
      }
      @if (isPdfUploadSuccessful) {
      <i class="material-icons min-btn m-0 text-success">done</i>
      }
      @if (isPdfUploadError) {
      <i class="material-icons min-btn m-0 text-danger">error</i>
      }
    </div>
  </div>
  <div>
    <button type="button" mdbBtn class="waves-light btn-danger btn float-right" aria-label="Close"
      (click)="confirm(false)" mdbWavesEffect>Cancel</button>
    <button type="button" mdbBtn class="relative waves-light btn-success btn float-right" mdbWavesEffect [disabled]="!(isDateFromValide
    && (editedCard.fabricImgTitle || isAccessories)
    && (editedCard.fabricConfigId)
    && (editedCard.dateOfOrder)
    && (editedCard.lineId || isAccessories)
    && (editedCard.typeOfSuitId || !isSuit))" (click)="confirm(true)">Save</button>
  </div>
</mat-dialog-actions>