import { Component, OnInit, Output, EventEmitter, ViewChildren, Input, QueryList, ElementRef } from '@angular/core';

@Component({
  selector: 'pd-field-emails',
  templateUrl: './field-emails.component.html',
  styleUrls: ['./field-emails.component.scss']
})
export class FieldEmailsComponent implements OnInit {
  @ViewChildren('inputFields') inputFields: QueryList<ElementRef>;
  @Input() emails = [];
  @Input() isNeedSaveCancelBtn = true;
  @Output() emailsSave = new EventEmitter<Object>();
  @Output() emailsCancel = new EventEmitter<Object>();

  public isEdit = false;
  public prevEmails = [];
  public label: string;
  public emailLabels = ['Work', 'Home', 'Other'];

  constructor() { }

  ngOnInit() {
    this.prevEmails = this.deepClone(this.emails);
    this.isEdit = true;
  }

  public isAllValidCheck() {
    return true;
  }

  public onEmailChange(email, updatedEmailModel) {
    email.tempNewValue = updatedEmailModel.email;
  }

  public addOneMore() {
    this.emails.push({ value: '', label: 'Work' });
  }

  public deleteEmail(index) {
    if (this.emails && this.emails.length > 0) {
      this.emails.splice(index, 1)
    }
  }

  public save() {
    if (this.isAllValidCheck()) {
      this.isEdit = false;
      let emails = this.getEmails();
      this.emailsSave.emit(emails);
    }
  }

  public getEmails() {
    this.emails.forEach((email) => { if (email.newValue !== undefined) { email.value = email.newValue } });
    this.prevEmails = this.deepClone(this.emails);
    return this.emails;
  }

  public cancel() {
    this.isEdit = false;
    this.emails = this.deepClone(this.prevEmails);
    this.emailsCancel.emit(this.emails);
  }

  private deepClone(array: Object[]) {
    const newArray: any = [];
    array.forEach((item) => {
      newArray.push(Object.assign({}, item));
    });
    return newArray;
  }
}
