<div class="export-file-modal" [@exportFileModal]="exportFileModal">
  <div class="wrapper">
    <span class="title">Count Deals</span>
    <div class="count-deals">
      <div class="md-form">
        <input type="number" class="form-control" mdbInputDirective placeholder="Count (max {{maxCountDealsForExport}})"
          min="1" max="maxCountDealsForExport" [(ngModel)]="countDeals" (input)="changeCountDealsForExport()">
      </div>
    </div>
    <div class="result-info">
      {{exportNullResult}}
    </div>
    <div class="buttons">
      <button type="button" class="btn btn-danger waves-light cancel" (click)="hideExportModal()">Cancel</button>
      <button type="button" class="btn btn-success waves-light save" (click)="exportDeals()"
        [disabled]="spinner || !isCountOfDealsValid">
        @if (spinner) {
        <div class="spinner">
          <mat-spinner [diameter]="20"></mat-spinner>
        </div>
        }
        Save
      </button>
    </div>
  </div>
</div>