import { ObjectUtil } from "../../utils/object.util";

export class BulkMessageRequestMessageTemplateVariableUpdateDto {
    public variableDescriptionId: number;
    public staticValue: string;

    constructor(data?: any) {
        ObjectUtil.extend(data, this);
    }
}
