import { Injectable } from "@angular/core";
import { Observable, from } from "rxjs";
import { AvatarCollection } from "../../models/avatar-models/AvatarCollection";
import { HttpRestService } from "../http-client/http-rest-client.service";
import { BaseService } from "../base.service";

@Injectable()
export class AvatarCollectionApiService extends BaseService {
    constructor(private restService: HttpRestService) {
        super();
    }

    public getAllCollections(): Observable<AvatarCollection[]> {
        return from(this.handleResponse(this.restService.get({
            url: `/avatarCollection/getAll`
        })));
    }

    public getCollection(id: number): Observable<AvatarCollection> {
        return from(this.handleResponse(this.restService.get({
            url: `/avatarCollection/get/${id}`
        })));
    }

    public addCollection(collectionName: string): Observable<number> {
        return from(this.handleResponse(this.restService.post({
            url: `/avatarCollection/add`,
            data: { collectionName }
        })));
    }

    public updateCollection(id: number, collectionName: string): Observable<boolean> {
        return from(this.handleResponse(this.restService.put({
            url: `/avatarCollection/put`,
            data: { id, collectionName }
        })));
    }

    public setSeasonalProposalCollection(id: number, isChecked: boolean): Observable<boolean> {
        return from(this.handleResponse(this.restService.put({
            url: `/avatarCollection/setSeasonalProposalCollection`,
            data: { id, isChecked }
        })));
    }

    public setStylesAvailableCollection(id: number, isChecked: boolean): Observable<boolean> {
        return from(this.handleResponse(this.restService.put({
            url: `/avatarCollection/setStylesAvailableCollection`,
            data: { id, isChecked }
        })));
    }

    public setGroupedView(id: number, isChecked: boolean): Observable<boolean> {
        return from(this.handleResponse(this.restService.put({
            url: `/avatarCollection/setGroupedView`,
            data: { id, isChecked }
        })));
    }

    public deleteCollection(id: number): Observable<boolean> {
        return from(this.handleResponse(this.restService.delete({
            url: `/avatarCollection/delete/${id}`
        })));
    }

    public downloadCollection(id: number): Observable<Blob> {
        return from(this.handleResponse(this.restService.get({
            url: `/avatarCollection/downloadCollection/${id}`,
            responseType: 'blob' as 'json'
        })));
    }
}