@if (dialogOptions) {
<modal #modal [minWidth]="480"
  [secondaryBtnTextKey]="currentUser?.is_admin ? dialogOptions.CancelBtnText: dialogOptions.ConfirmBtnText"
  [primaryBtnTextKey]="dialogOptions.ConfirmBtnText" [displayLaunchButton]="false" (secondaryClick)="confirm(false)"
  (primaryClick)="confirm(true)" [isPrimaryBtnVisible]="currentUser?.is_admin" (onCompleteAction)="confirm(false)"
  [isPrimaryBtnDisabled]="(!isUrl && inputData.trim().length <= 3 && isInputDisplayed) || (isUrl && (inputData.length <= 10 || (!inputData.trim().startsWith('http://') && !inputData.trim().startsWith('https://'))))">
  <span class="title">{{currentUser?.is_admin ? dialogOptions.MessageText
    :dialogOptions.MessageTextForRegularUser}}</span>
  @if (currentUser?.is_admin || isInputDisplayed === true) {
  <div>
    @if (isInputDisplayed) {
    <div class="form-group row" style="margin-top: 10px;">
      <input mdbInput (paste)="$event.stopPropagation()" (keydown)="$event.stopPropagation()" type="text"
        class="form-control col" [(ngModel)]="inputData" id="inputReason" [placeholder]="inputPlaceholder">
    </div>
    }
    @if (isUrl && inputData.length > 3 && !inputData.trim().startsWith('http://') &&
    !inputData.trim().startsWith('https://')) {
    <div class="alert alert-danger">
      Url should start with http:// or https://
    </div>
    }
  </div>
  }
</modal>
}