<div class="recall">
    <div class="runningRecall">
        <div class="targetInfo">
            <div class="users">
                <pd-custom-select-without-lazy #userFilter (loadDataEvent)="getUsersFilterData()"
                    (selectedItemEvent)="onUserSelectedItem($event)" (autoCompleteEvent)="getUsersAutocomplete($event)"
                    [dataLabel]="usersLabel" [startLabel]="usersLabel" [isUserMode]="true" [isMultiple]="true">
                </pd-custom-select-without-lazy>
            </div>
            <div class="location location-recall">
                <pd-custom-select-filter #locationFilter (loadDataEvent)="getLocationsFilterData($event)"
                    (autoCompleteEvent)="getLocationsAutocomplete($event)" (sendData)="getRecallLocations($event)"
                    [isNumberInfo]="true" [dataLabel]="locationsLabel"
                    [filterType]="filterTypeLocation"></pd-custom-select-filter>
                @if (recallLocations.length > 1500) {
                <span class="error">Your filter is too large, we will filter only
                    by
                    first 1500 location</span>
                }
            </div>
        </div>
        <div class="target-buttons">
            <button class="btn btn-info" (click)="submitRecall()"
                [disabled]="(recallLocations.length === 0 && !isSelectAllLocations) || isSelectAnyUsersNotMasterLead !== true">Submit</button>
            <button class="btn btn-danger waves-light" [routerLink]="['/', tasksRoute]">Cancel</button>
        </div>
    </div>
</div>