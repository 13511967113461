import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable()
export class AppRouterService {
    constructor(private router: Router, private route: ActivatedRoute) { }

    public returnUrl = () => this.route.snapshot.queryParams['returnUrl'];
    public openLogin = () => this.router.navigate(['/login']);
    public openPipeline1 = () => this.router.navigate(['pipeline/1']);
    public openPipeline4 = () => this.router.navigate(['pipeline/4']);
    public openPipeline7 = () => this.router.navigate(['pipeline/7']);
    public openPage = (url: string) => this.router.navigate([url]);
    public isPublicLink = () => this.isPublicHotlistLink();

    private isPublicHotlistLink = () => {
        const regex = /^\/Hotlist\/[^\/]+$/;
        return regex.test(this.router.url);
    };
}
