<div class="messages-list">
    <ng-container>
        @if(messages && messages.length > 0)
        {
        <ng-container>
            @for (message of messages; track identify($index, message)) {
            <div>
                <div [ngClass]="getMessageClasses(message)">
                    @for (media of message.medias; track media) {
                    <div>
                        @if (FileUtil.isImage(media.contentType)) {
                        <img [src]="FileUtil.getBase64String(media)" (click)="openFullscreen($event)"
                            class="preview-image" />
                        }
                        @if (FileUtil.isVideo(media.contentType)) {
                        <video class="preview-video" controls>
                            <source [src]="FileUtil.getBase64String(media)" [type]="media.contentType" />
                        </video>
                        }
                        @if (FileUtil.isAudio(media.contentType)) {
                        <audio controls>
                            <source [src]="FileUtil.getBase64String(media)" [type]="media.contentType" />
                        </audio>
                        }
                    </div>
                    }
                    @if(TwilioHelper.isReactionMessage(message))
                    {
                    <pre class="text"><i>{{TwilioHelper.textForReactionMessage}}</i></pre>
                    }
                    @if(!TwilioHelper.isReactionMessage(message))
                    {
                    <pre class="text">{{message.text}}</pre>
                    }
                    <div>
                        <div [ngClass]="getTimeClasses(message)">
                            {{DateHelper.utcStringToLocalDate(message.time) | date :'dd-MMM-yyyy h:mm a'}}
                        </div>
                        @if (!message.isInboundMessage) {
                        <div class="icon-wrapper" bootstrapTooltip={{getErrorMessageTooltip(message)}}>
                            <mat-icon [svgIcon]="TwilioHelper.getMessageIcon(message)"
                                [ngClass]="TwilioHelper.getMessageIconClass(message)" class="icon">
                            </mat-icon>
                        </div>
                        }
                    </div>
                </div>
            </div>
            }
        </ng-container>
        }
    </ng-container>
    <div #targetScroll></div>
</div>

<div #fullscreenView class="fullscreen">
    <button class="fullscreen-close-btn waves-light btn-danger btn" style="float: right;" (click)="closeFullscreen()">
        <i class="material-icons">close</i>
    </button>
    <img #fullscreenImg src="" alt="image">
</div>