<div class="custom">
  <div class="custom-header" (click)="openSelect()">
    <span class="custom-input" bootstrapTooltip="{{getMainLabel()}}" data-html="true"
      placement="bottom">{{getMainLabel()}}</span>
    <span>
      <i class="material-icons" style="color:rgba(0,0,0,.54);">
        arrow_drop_down
      </i>
    </span>
  </div> <!-- Warning! $event.stopPropagation() blocks (clickOutside) -->
  <div class="custom-body" [ngClass]="{
    'visible-select': isOpenSelect, 
    'hidden-select': !isOpenSelect
  }">
    <div class="custom-search">
      <input type="text" placeholder="{{(currentFilterChip.isExcept) ? 'Except mode is active' : 'Search'}}"
        (click)="$event.stopPropagation()" (keyup)="onSearch()" [(ngModel)]="currentFilterChip.searchText"
        [formControl]="multiNumberFilter">
    </div>
    <div class="chip-list">
      <mat-chip-listbox>
        <mat-chip class="chip addbtn" (click)="addChip()">&nbsp;
          <mat-icon class="add-btn-icon"> add_circle
          </mat-icon>
        </mat-chip>

        @for (filterChip of filterChips; track filterChip) {
        <mat-chip [ngClass]="{
        'active-chip': filterChip === currentFilterChip,
        'inactive-chip': filterChip !== currentFilterChip
      }" class="chip" [removable]="true" (click)="selectChip(filterChip)">
          {{(filterChip.label) ? filterChip.label : "New Filter"}}
          <mat-icon (click)="deleteChip(filterChip)" matChipRemove class="closebtn">cancel</mat-icon>
        </mat-chip>
        }
      </mat-chip-listbox>
    </div>
    <hr class="line" />
    <div class="select-all">
      <div>
        <button mat-button type="button" style="margin-right: 10px;" class="button"
          (click)="selectAllData();$event.stopPropagation()">Select
          All</button>
        <button mat-button type="button" class="button" (click)="deselectAllData();$event.stopPropagation()">Deselect
          All</button>
        <mat-slide-toggle class="exceptTickmark" [(ngModel)]="currentFilterChip.isExcept"
          (change)="onExceptModeChange()" [disabled]="isExceptOnly"
          bootstrapTooltip="Except mode is always active in Clothier Client Pipeline. You cannot deactivate it."
          [tooltipDisabled]="!isExceptOnly">Except
          Mode</mat-slide-toggle>
      </div>
    </div>
    <hr class="line" />
    <div class="group-options" (scroll)="doLazyLoading($event)" #selectContainer>
      <div class="list-options">
        @for (loc of filteredData; track loc; let i = $index) {
        <div class="custom-option">
          <span class="option">
            <mat-checkbox [checked]="checkItem(loc.id)" (change)="selectItem(loc, $event)">{{loc.name}}</mat-checkbox>
          </span>
        </div>
        }
        @if (selectLazyLoading) {
        <div class="select-lazy-loading">
          <div class="text-lazy">Loading more {{currentFilterChip.label}}...</div>
        </div>
        }
        @if (isShowLoading) {
        <div class="select-lazy-loading">
          <div class="text-lazy">Loading {{currentFilterChip.label}}...</div>
        </div>
        }
      </div>
    </div>
  </div>
</div>