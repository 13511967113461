<mat-expansion-panel [expanded]="isExportAction" (opened)="onOpened()">
  <mat-expansion-panel-header>
    <mat-panel-title>
      <div class="panel-title">
        <span class="product-label">
          {{this.getProductLabel}}
          @if (getCardCount() > 0) {
          <span>&nbsp;({{getCardCount()}})</span>
          }
        </span>
        <div class="panel-tool">
          @if (isAllowRefresh) {
          <button class="tool-btn btn btn-outline-info" (click)="generateDealAvatars($event)"
            matTooltip="Generate avatars" matTooltipPosition="above">
            <i class="material-icons">refresh</i>
          </button>
          }
        </div>
      </div>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <mat-panel-description>
    @if (isNeedLoad) {
    <div [ngClass]="{ 'export-action': isExportAction }" style="width: 100%">
      @if (!isExportAction) {
      <div class="tool">
        <div class="sort-label">
          Sort
        </div>
        <div class="sort-selector">
          <mat-form-field class="global-custom-select">
            <mat-select [(value)]="currentSort.id" (selectionChange)="onSortChange()">
              @for (item of sortArray; track item) {
              <mat-option [value]="item.id">
                {{item.name}}
              </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      }
      <div class="expansion-panel-body">
        @for (card of getClientCardsToView(); track card) {
        <div (click)="clickCard(card);$event.stopPropagation()">
          <div class="tile">

            <div class="tile-title">
              @if (!isWardrobe) {
              <span>{{card.fabricConfigTitle}}</span>
              }
              @if (isWardrobe) {
              <span>{{card.productTypeName + ' - ' + card.fabricConfigTitle}}</span>
              }
              <span>
                @if (card.filesCount && !isExportAction) {
                <button class="btn btn-outline-primary remove" (click)="getCardPdf(card);$event.stopPropagation()"
                  matTooltip="Print PDF" matTooltipPosition="above">
                  <i class="material-icons">cloud_download</i>
                </button>
                }</span>
            </div>

            @if (isFilledCard(card)) {
            <div class="tile-body">
              <div *ngIf="getCardViewConfig(card); let cardViewConfig">

                <div class="relative">
                  <div class="image">
                    <img [src]="card.fabricImgUrl" onerror="this.onerror=null; this.src='../../assets/lgfg-logo.png'" />
                  </div>
                  <span class="circle" [matTooltip]="cardViewConfig.toolTipLabel" matTooltipPosition="above"
                    [ngStyle]="cardViewConfig.styles" data-html="true"></span>
                </div>

                <div class="row">
                  @if (card.productTypeId !== ProductTypes.Accessories) {
                  <div class="tile-info-label text">
                    <span>Fabric number:</span>
                  </div>
                  }
                  @if (card.productTypeId === ProductTypes.Accessories && card.fabricImgTitle) {
                  <div class="tile-info-label text">
                    <span>Image:</span>
                  </div>
                  }
                  @if (card.fabricImgTitle) {
                  <div class="col tile-info-value">
                    <div class="text">
                      <span>{{card.fabricImgTitle.slice(0, 15) + ((card.fabricImgTitle.length > 15) ? '...' : '')}}
                      </span>
                    </div>
                  </div>
                  }
                </div>

                @if (card.productTypeId !== ProductTypes.Accessories) {
                <div class="row">
                  <div class="tile-info-label text">
                    <span>Line:</span>
                  </div>
                  <div class="col tile-info-value">
                    <div class="text">
                      <span>{{card.lineName}}</span>
                    </div>
                  </div>
                </div>
                }

                @if (card.productTypeId === ProductTypes.Suits) {
                <div class="row">
                  <div class="tile-info-label text">
                    <span>Type of suit:</span>
                  </div>
                  <div class="col tile-info-value">
                    <div class="text">
                      <span>{{card.typeOfSuitName}}</span>
                    </div>
                  </div>
                </div>
                }

                <div class="row">
                  <div class="tile-info-label text">
                    <span>Date of order:</span>
                  </div>
                  <div class="col tile-info-value">
                    <div class="text">
                      <span>{{card.dateOfOrder | date :'dd-MMM-yyyy'}}</span>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            }
            @if (!isFilledCard(card)) {
            <div>
              @if (isAllowAddEdit) {
              <div value="Add" class="add-tile"></div>
              }
            </div>
            }

          </div>
        </div>
        }
      </div>
    </div>
    }
  </mat-panel-description>
</mat-expansion-panel>