import { Component, OnInit, Input, ChangeDetectionStrategy, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import printJS from 'print-js'
import { BaseComponent } from 'src/app/core/base.component';
import { RolesEnum } from 'src/app/core/enums/RolesEnum';
import { ProductTypes } from 'src/app/core/enums/client-card/ProductTypes';
import { ClientCard } from 'src/app/core/models/ClientCard';
import { FabricConfig, FabricImage, ProductType, TypeOfSuit } from 'src/app/core/models/ClientCardConfig';
import { User } from 'src/app/core/models/UserModel';
import { ClientCardApiService } from 'src/app/core/services/client-card/client-card-api.service';
import { ClientCardFacadeService } from 'src/app/core/services/client-card/client-card-facade.service';
import { UserContextService } from 'src/app/core/services/user-context.service';
import { DealUtil } from 'src/app/core/utils/deal.util';
import { takeUntil } from 'rxjs/operators';
import { DateHelper } from 'src/app/core/utils/date.helper';
import { ImageUtil } from 'src/app/core/utils/image.util';
import { AvatarUtil } from 'src/app/core/utils/avatar.util';

@Component({
  selector: 'pd-client-card-expansion-panel',
  templateUrl: './client-card-expansion-panel.component.html',
  styleUrls: ['./client-card-expansion-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ClientCardExpansionPanelComponent extends BaseComponent implements OnInit {
  @Input() dealId: number;
  @Input() dealTitle: string;
  @Input() productType: ProductType;
  @Input() typeOfSuits: Array<TypeOfSuit>;
  @Input() fabricImages: Array<FabricImage>;
  @Input() isWardrobe: boolean;
  @Input() fabricConfigs: Array<FabricConfig>;
  @Input() isExportAction: boolean = false;
  @Input() isClientHistory: boolean = false;
  @Input() isAllowAddEditAvatars: boolean = false;
  @Input() isFemale: boolean = false;

  @Output() public onAddCard = new EventEmitter<ClientCard>();
  @Output() public onEdiCard = new EventEmitter<ClientCard>();
  @Output() public onGenerateDealAvatars = new EventEmitter<any>();
  @Output() public onChange = new EventEmitter<any>();

  isAllowAddEdit: boolean;
  isNeedLoad: boolean;

  currentUser: User;
  sortArray: Array<any>;
  currentSort: any = {};

  ProductTypes = ProductTypes;
  DealUtil = DealUtil;

  clientCards: Array<ClientCard>;
  clientCardsToView: Array<ClientCard>;

  constructor(
    private userContextService: UserContextService
    , private cdRef: ChangeDetectorRef
    , private clientCardApiService: ClientCardApiService
    , private clientCardFacadeService: ClientCardFacadeService
  ) {
    super();
    this.sortArray = new Array<any>();
  }

  ngOnInit() {
    if (this.isWardrobe) {
      this.sortArray.push({ id: 1, name: "the newest" });
      this.sortArray.push({ id: 2, name: "the oldest" });
      this.currentSort = { id: 1, name: "the newest" }
    }
    else {
      this.sortArray.push({ id: 0, name: "by item" });
      this.sortArray.push({ id: 1, name: "the newest" });
      this.sortArray.push({ id: 2, name: "the oldest" });
      this.currentSort = { id: 0, name: "by item" }
    }
    this.initializeStore();
    this.isAllowAddEdit = (this.currentUser.role_id === RolesEnum.EA
      || this.currentUser.role_id === RolesEnum.EaCaller
      || this.currentUser.role_id === RolesEnum.Clothier
      || this.currentUser.role_id === RolesEnum.Admin
      || this.currentUser.role_id === RolesEnum.MasterLead)

    this.clientCardFacadeService.getClientCards()
      .pipe(takeUntil(this.destroy$))
      .subscribe((clientCards: ClientCard[]) => {
        if (this.isWardrobe) {
          this.clientCardsToView = clientCards;
          this.clientCards = clientCards;
          this.fillFabricImg();
        }
        else {
          this.clientCardsToView = new Array<ClientCard>();
          this.clientCards = clientCards.filter(f => f.productTypeId === this.productType.id);
          this.fillFabricImg();
          this.addEmptyCards();
        }

        this.getClientCardsToView();
        this.cdRef.detectChanges();
      });
    this.cdRef.detectChanges();
  }

  ngOnDestroy() {
    this.cdRef.detach();
    super.ngOnDestroy();
  }

  ngAfterContentInit() {
  }

  public onSortChange() {
    this.cdRef.detectChanges();
  }

  private initializeStore() {
    this.currentUser = this.userContextService.user.value;
  }

  private addEmptyCards() {
    this.clientCardsToView = new Array<ClientCard>();

    const fabricConfigs = this.fabricConfigs.filter(c => c.productTypeId === this.productType.id);
    fabricConfigs.forEach(fabricConfig => {
      let clientCardsByFabricConfig = this.clientCards.filter(f => f.fabricConfigId === fabricConfig.id);
      var i = 0;

      if (clientCardsByFabricConfig) {
        i = (clientCardsByFabricConfig.length >= fabricConfig.count) ? fabricConfig.count - 1 : clientCardsByFabricConfig.length;

        clientCardsByFabricConfig.forEach(clientCard => {
          clientCard.order = fabricConfig.order;
          this.clientCardsToView.push(clientCard);
        });
      }

      for (; i < fabricConfig.count; i++) {
        let emptyCard = new ClientCard(this.dealId, null, fabricConfig.productTypeId, null, fabricConfig.id, fabricConfig.title, null, null, fabricConfig.order, 0);
        this.clientCardsToView.push(emptyCard);
      }
    });
    return this.clientCardsToView;
  }

  private sortCard() {
    if (this.currentSort.id === 0) {
      this.clientCardsToView = this.clientCardsToView.sort((a, b) => this.cardsCompare(a, b));
    }
    else {
      let filledCards = this.clientCardsToView.filter(f => f.dateOfOrder)
      let emptyCards = this.clientCardsToView.filter(f => !f.dateOfOrder)
      emptyCards = emptyCards.sort((a, b) => this.cardsCompare(a, b));
      if (this.currentSort.id === 1) {
        filledCards = filledCards.sort((a, b) => this.cardsCompare(b, a));

      } else if (this.currentSort.id === 2) {
        filledCards = filledCards.sort((a, b) => -this.cardsCompare(b, a));
      }
      this.clientCardsToView = filledCards.concat(emptyCards);
    }
  }

  private cardsCompare(first: ClientCard, next: ClientCard) {
    let firstDate = new Date(first.dateOfOrder);
    let nextDate = new Date(next.dateOfOrder);

    if (this.currentSort.id !== 0) {
      if (nextDate.getTime() > firstDate.getTime()) {
        return -1;
      } else if (nextDate.getTime() < firstDate.getTime()) {
        return 1;
      }
    }
    else {
      if (next.order > first.order) {
        return -1;
      } else if (next.order < first.order) {
        return 1;
      }
    }
    return this.compare(first.id, next.id);
  }

  private compare(first: number, next: number) {
    if (next > first) {
      return -1;
    }
    if (next < first) {
      return 1;
    }
    return 0;
  }

  isFilledCard(card: ClientCard) {
    return card.id || card.id === 0
  }

  calculateDiff(data) {
    let date = new Date(data.sent);
    let currentDate = new Date();

    let days = Math.floor((currentDate.getTime() - date.getTime()) / DateHelper.dayToMiliseconds);
    return days;
  }

  getCardViewConfig(card: ClientCard) {
    let cardViewConfig = { styles: [], toolTipLabel: {} };
    if (card && card.dateOfOrder) {
      let dateNow = new Date(DateHelper.formatAsISODateString(new Date()));
      let secondDate = new Date(card.dateOfOrder);
      let diffInYears = (Math.floor((dateNow.getTime() - secondDate.getTime()) / 1000 / 60 / 60 / 24)) / 365;
      if (diffInYears >= 3) {
        cardViewConfig.styles['background-color'] = "#a20000";
        cardViewConfig.toolTipLabel = "Order was placed more than 3 years ago";
      } else if (diffInYears >= 2) {
        cardViewConfig.styles['background-color'] = "#afa900";
        cardViewConfig.toolTipLabel = "Order was placed less than 3 years but more than 2";
      } else if (diffInYears >= 0) {
        cardViewConfig.styles['background-color'] = "#0f6500";
        cardViewConfig.toolTipLabel = "Order was placed less than 2 years ago";
      }
    }
    return cardViewConfig;
  }

  findFabricImg(card: ClientCard) {
    const url = ImageUtil.fabricToUrl(card.fabricImgTitle);
    if (url !== null) {
      card.fabricImgUrl = ImageUtil.addVersionToUrl(url);
    }
  }

  getClientCardsToView() {
    if (this.clientCardsToView && this.clientCardsToView.length > 0) {
      let cards = this.clientCardsToView;
      if (this.isClientHistory) {
        cards = cards.filter(f => f.id > 0);
      }
      this.sortCard()
      return cards;
    }
    return new Array<ClientCard>();
  }

  public clickCard(card: ClientCard) {
    if (this.isFilledCard(card)) {
      this.onEdiCard.emit(card);
    }
    else {
      this.onAddCard.emit(card);
    }
  }

  getCardCount() {
    if (this.clientCards) {
      let cards = this.clientCards;
      if (cards) {
        return cards.length;
      }
    }
    return 0;
  }

  public getCardPdf(card: ClientCard) {
    this.clientCardApiService.getClientCardPdf(card.id).then((result) => {
      if (result)
        printJS({ printable: result, type: 'pdf', base64: true })
    })
  }

  public get isAllowRefresh(): boolean {
    return this.isAllowAddEditAvatars
      && this.getCardCount() > 0
      && !this.isExportAction
      && (AvatarUtil.productTypeToAvatarProcessing.some(p => p == this.productType.id))
      && !this.isFemale;
  }

  public generateDealAvatars(event) {
    event.stopPropagation();
    this.onGenerateDealAvatars.emit({ productTypeId: this.productType.id });
  }

  public get getProductLabel(): string {
    return ((this.isWardrobe) ? DealUtil.nameToPossessiveForm(this.dealTitle) + " " : "") + this.productType.name;
  }

  public onOpened() {
    this.isNeedLoad = true;
    this.cdRef.detectChanges();
  }

  private fillFabricImg() {
    this.clientCards.forEach(clientCard => {
      this.findFabricImg(clientCard);
    });
  }
}
