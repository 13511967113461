import { ObjectUtil } from "../../utils/object.util";
import { BulkMessageRequestMessageTemplateVariableViewModel } from "../bulk-message/BulkMessageRequestMessageTemplateVariableViewModel";

export class BulkMessageRequestUpdateDto {
    public parentRequestId: number;
    public settingId: number;
    public templateId: number;
    public variables: BulkMessageRequestMessageTemplateVariableViewModel[];
    public replyRecipientIds?: number[];
    public expirationDate?: Date;
    public scheduledSendDate?: Date;
    public isSendToReceived?: boolean;
    public isSendToRead?: boolean;
    public isSendToReplied?: boolean;

    constructor(data?: any) {
        ObjectUtil.extend(data, this);
    }
}
