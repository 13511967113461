<div mat-dialog-title>
    <button type="button" mdbBtn (click)="close()" class="remove waves-light btn-danger btn float-right close-btn"
        aria-label="Close" mdbWavesEffect>
        <i class="material-icons">close</i>
    </button>
</div>
<mat-dialog-content>
    <div class="row avatar-builder avatar-wrapper">
        @if (isSpinner) {
        <div class="spinner">
            <mat-spinner></mat-spinner>
        </div>
        }
        <div class="row">
            <div class="col">
                <pd-avatar-builder-canvas #avatarBuilderCanvas (onNeedReloadAvatar)="reloadAvatar()"
                    (onDetectChanges)="detectChanges()">
                </pd-avatar-builder-canvas>
            </div>

            @if (avatarCustomizationConfig && avatarBuilderData) {
            <div class="col configurator-wrapper">
                <div>
                    <div class="row">
                        <div class="col">
                            @if (!isSetting) {
                            <div class="row">
                                <div class="col type-label">
                                    Avatar type
                                </div>
                                <div class="col">
                                    @if (avatarCustomizationConfig.avatarTypes) {
                                    <mat-select class="type-select" [value]="avatarBuilderData.avatarTypeId"
                                        (selectionChange)="changeAvatarType($event)">
                                        @for (item of avatarCustomizationConfig.avatarTypes; track item) {
                                        <mat-option [value]="item.key">
                                            {{getAvatarType(item.key)}}
                                        </mat-option>
                                        }
                                    </mat-select>
                                    }
                                </div>
                            </div>
                            }
                            @if (!isCustom) {
                            <div class="row">
                                <div class="col type-label">
                                    Body type
                                </div>
                                <div class="col">
                                    @if (avatarCustomizationConfig.bodyTypes) {
                                    <mat-select class="type-select" [value]="avatarBuilderData.bodyTypeId"
                                        (selectionChange)="changeBodyType($event)">
                                        @for (item of avatarCustomizationConfig.bodyTypes; track item) {
                                        <mat-option [value]="item.key">
                                            {{item.value}}
                                        </mat-option>
                                        }
                                    </mat-select>
                                    }
                                </div>
                            </div>
                            }
                            @if (!isCustom) {
                            <div class="row">
                                <div class="col type-label">
                                    Skin type
                                </div>
                                <div class="col">
                                    @if (avatarCustomizationConfig.skinTypes) {
                                    <mat-select class="type-select" [value]="avatarBuilderData.skinTypeId"
                                        (selectionChange)="changeSkinType($event)">
                                        @for (item of avatarCustomizationConfig.skinTypes; track item) {
                                        <mat-option [value]="item.key">
                                            {{getSkinType(item.key)}}
                                        </mat-option>
                                        }
                                    </mat-select>
                                    }
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                </div>
                <div class="row options-wrapper">
                    @if (avatarBuilderData) {

                    @for (option of avatarBuilderData.options; track option) {

                    <div class="col-12">
                        @if (isHead(option) && !isCustom) {

                        <strong>{{getAvatarElementTypeLabel(option.elementTypeId)}}</strong>
                        <div class="row">
                            <div class="row col head-btn-wrapper">
                                <div class="head-btn-container">
                                    <button class="btn btn-primary upload-head-btn" (click)="uploadHead()">Upload
                                        Head</button>
                                    <button class="btn btn-danger remove-head-btn" (click)="removeHead()">Remove
                                        Head</button>
                                </div>
                            </div>

                            <div class="col scale-wrapper">
                                <mat-form-field class="scale-input">
                                    <mat-label>Scale</mat-label>
                                    <input matInput type="number" [(ngModel)]="option.scale"
                                        [disabled]="!option.imageLink" (change)="onChange(option)"
                                        (wheel)="onChange(option)">
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row who-upload-head-wrapper" *ngIf="isSetting && option.modifiedByName">
                            <div class="col who-upload-head-lebel">Who
                                uploaded head</div>
                            <div class="col">
                                {{option.modifiedByName}}
                            </div>
                        </div>
                        }
                        @if (!isHead(option) && !isSetting) {

                        <strong>{{getAvatarElementTypeLabel(option.elementTypeId)}}</strong>
                        <div class="row">

                            <div class="row col texture-config-wrapper">
                                <div class="col">
                                    <mat-form-field class="img-link">
                                        <mat-label>Image Link</mat-label>
                                        <input matInput type="text" [(ngModel)]="option.imageLink"
                                            (change)="updatePart(option)">
                                    </mat-form-field>
                                </div>

                                @if (fabricImages) {
                                <div class="col fabric-wrapper">
                                    <pd-fabric-selector [imageLink]="option.imageLink" [fabricImages]="fabricImages"
                                        (onFabricSelected)="fabricChanged($event, option)">
                                    </pd-fabric-selector>
                                </div>
                                }
                            </div>

                            <div class="col scale-wrapper">
                                <div class="col">
                                    <mat-form-field class="scale-input">
                                        <mat-label>Scale</mat-label>
                                        <input matInput type="number" [(ngModel)]="option.scale"
                                            [disabled]="!option.imageLink" (change)="onChange(option)"
                                            (wheel)="onChange(option)">
                                    </mat-form-field>
                                </div>

                                @if (!!getShadowVector(option)) {
                                <div class="shadow-wrapper">
                                    <pd-color-selector [color]="option.shadowColor"
                                        (onColorSelected)="shadowColorChanged($event, option)">
                                    </pd-color-selector>
                                </div>
                                }
                            </div>

                        </div>

                        }
                    </div>

                    }

                    }
                </div>

                @if (!isHasHead && !isCustom) {
                <button class="btn btn-primary upload-first-head-btn" (click)="uploadHead()">Upload
                    Head</button>
                }
            </div>
            }
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button class="btn btn-danger" (click)="close()">Close</button>
    <button class="btn btn-success" [disabled]="isSpinner" (click)="save()">Save</button>
</mat-dialog-actions>