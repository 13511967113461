<div #targetScroll></div>
@if (notes && notes.length > 0) {
<table class="nodrag table table-striped">
	<thead class="nodrag">
		<tr class="nodrag">
			<td class="nodrag">Date</td>
			<td class="nodrag">Author</td>
			<td class="nodrag">Note</td>
			<td class="nodrag"></td>
		</tr>
	</thead>
	<tbody>
		@for (note of notes; track note; let i = $index) {
		<tr class="nodrag" #noteTable>
			@if (!isEditRecord(note)) {

			@if (i < countItemsToShow || isShowAll) { <td class="nodrag">{{note.created | date :'dd-MMM-yyyy h:mm a'}}
				</td>
				<td class="nodrag">{{note.createdByName}}</td>
				<td class="nodrag">
					<p class="nodrag" style="white-space: pre-line">{{note.text}}</p>
				</td>
				<td class="nodrag">
					@if (isAllowEditNote(note)) {
					<button value="Edit" (click)="editNote(note)" class="btn btn-outline-warning nodrag"
						[disabled]='isNewRecord'>
						<i class="material-icons nodrag">edit</i>
					</button>
					}
					@if (isAllowDeleteNote(note)) {
					<button value="Remove" (click)="deleteNote(note)" class="btn btn-outline-danger nodrag"
						[disabled]="isNewRecord">
						<i class="material-icons nodrag">delete</i>
					</button>
					}
				</td>

				}

				} @else {

				<td class="nodrag">{{editedNote.created | date :'dd-MMM-yyyy h:mm a'}}</td>
				<td class="nodrag">{{editedNote.createdByName}}</td>
				<td class="nodrag">
					<textarea [(ngModel)]="editedNote.text" class="nodrag"></textarea>
				</td>
				<td class="nodrag">
					<button value="Save" (click)="saveNote()"
						[disabled]="!(editedNote.text && (editedNote.text.trim().length > 0))"
						style="margin-right: 7px;" class="btn btn-outline-success float-left nodrag"><i
							class="material-icons nodrag">save</i>
					</button>
					<button value="Cancel" (click)="cancelNote()" class="btn btn-outline-danger nodrag">
						<i class="material-icons nodrag">
							cancel
						</i>
					</button>
				</td>

				}
		</tr>
		}
	</tbody>
</table>
}

<div class="panel-wrapper">
	@if (isAllowAddNote) {
	<button value="Add" class="btn btn-outline-primary nodrag" style="border: 2px solid #4285F4;" (click)="addNote()"
		[disabled]="isNewRecord"><i class="material-icons nodrag">
			note_add
		</i></button>
	}
	<div class="more-btn-wrapper">
		@if (notes && notes.length > countItemsToShow && !isShowAll && !(notes.length === (countItemsToShow + 1) &&
		editedNote !== null)) {
		<button value="More" class="btn btn-outline-dark more-btn nodrag" (click)="showMore()"><i
				class="material-icons nodrag">
				expand_more
			</i></button>
		}
	</div>
</div>