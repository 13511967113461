import { Injectable } from "@angular/core";
import { AvatarDealStoreService } from "./avatar-deal-store.service";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { AvatarDealApiService } from "./avatar-deal-api.service";
import { Avatar } from "../../models/avatar-models/Avatar";
import { AvatarBuilderData } from "../../models/avatar-models/AvatarBuilderData";
import { AvatarApiService } from "../avatar/avatar-api.service";

@Injectable()
export class AvatarDealEffectsService {

    constructor(
        private avatarDealStoreService: AvatarDealStoreService
        , private avatarDealApiService: AvatarDealApiService
        , private avatarApiService: AvatarApiService
    ) {
    }

    public loadClientCardAvatarsByDealId(dealId: number): Observable<Avatar[]> {
        return this.avatarDealApiService.getClientCardAvatarsByDealId(dealId).pipe(
            tap((avatars: Avatar[]) => {
                console.log('loadClientCardAvatarsByDealId', avatars);
                this.avatarDealStoreService.setClientCardAvatars(avatars);
            }));
    }

    public loadSeasonalProposalAvatarsByDealId(dealId: number): Observable<Avatar[]> {
        return this.avatarDealApiService.getSeasonalProposalsByDealId(dealId).pipe(
            tap((avatars: Avatar[]) => {
                console.log('loadSeasonalProposalAvatarsByDealId', avatars);
                this.avatarDealStoreService.setSeasonalProposalAvatars(avatars);
            }));
    }

    public loadStylesAvailableAvatarsByDealId(dealId: number): Observable<Avatar[]> {
        return this.avatarDealApiService.getStylesAvailableAvatarsByDealId(dealId).pipe(
            tap((avatars: Avatar[]) => {
                console.log('loadStylesAvailableAvatarsByDealId', avatars);
                this.avatarDealStoreService.setStylesAvailableAvatars(avatars);
            }));
    }

    public loadCustomAvatarsByDealId(dealId: number): Observable<Avatar[]> {
        return this.avatarDealApiService.getCustomAvatarsByDealId(dealId).pipe(
            tap((avatars: Avatar[]) => {
                console.log('loadCustomAvatarsByDealId', avatars);
                this.avatarDealStoreService.setCustomAvatars(avatars);
            }));
    }

    public createCustomAvatar(dealId: number, avatarBuilderData: AvatarBuilderData): Observable<number> {
        return this.avatarDealApiService.createCustomAvatar(dealId, avatarBuilderData).pipe(
            tap(async (avatarId: number) => {
                if (avatarId) {
                    const avatar = await this.avatarApiService.getAvatar(avatarId).toPromise();
                    const avatars = this.avatarDealStoreService.getCustomAvatarsSnapshot();
                    avatars.push(avatar);
                    this.avatarDealStoreService.setCustomAvatars(avatars);
                }
            })
        )
    }

    public updateClientCardAvatar(avatarId: number, avatarBuilderData: AvatarBuilderData): Observable<boolean> {
        return this.avatarDealApiService.updateClientCardAvatar(avatarId, avatarBuilderData).pipe(
            tap(async (isUpdated: boolean) => {
                if (isUpdated) {
                    const updatedAvatar = await this.avatarApiService.getAvatar(avatarId).toPromise();
                    const avatars = this.avatarDealStoreService.getClientCardAvatarsSnapshot();
                    const resultAvatars = avatars.filter(a => a.id != avatarId);
                    resultAvatars.push(updatedAvatar);

                    const dataLog = {
                        "updatedAvatar": updatedAvatar,
                        "avatars": avatars,
                        "resultAvatars": resultAvatars,
                    };

                    console.log(JSON.stringify(dataLog));
                    this.avatarDealStoreService.setClientCardAvatars(resultAvatars);
                }
            })
        )
    }

    public updateCustomAvatar(avatarId: number, avatarBuilderData: AvatarBuilderData): Observable<boolean> {
        return this.avatarDealApiService.updateCustomAvatar(avatarId, avatarBuilderData).pipe(
            tap(async (isUpdated: boolean) => {
                if (isUpdated) {
                    const updatedAvatar = await this.avatarApiService.getAvatar(avatarId).toPromise();
                    const avatars = this.avatarDealStoreService.getCustomAvatarsSnapshot();
                    const resultAvatars = avatars.filter(a => a.id != avatarId);
                    resultAvatars.push(updatedAvatar);

                    const dataLog = {
                        "updatedAvatar": updatedAvatar,
                        "avatars": avatars,
                        "resultAvatars": resultAvatars,
                    };

                    console.log(JSON.stringify(dataLog));
                    this.avatarDealStoreService.setCustomAvatars(resultAvatars);
                }
            })
        )
    }

    public deleteClientCardAvatar(avatarId: number): Observable<boolean> {
        return this.avatarApiService.deleteAvatar(avatarId).pipe(
            tap((isDeleted: boolean) => {
                if (isDeleted) {
                    const avatars = this.avatarDealStoreService.getClientCardAvatarsSnapshot();
                    const resultAvatars = avatars.filter(a => a.id != avatarId);

                    const dataLog = {
                        "deleteAvatar": isDeleted,
                        "avatars": avatars,
                        "resultAvatars": resultAvatars,
                    };

                    console.log(JSON.stringify(dataLog));
                    this.avatarDealStoreService.setClientCardAvatars(resultAvatars);
                }
            })
        );
    }

    public deleteCustomAvatar(avatarId: number): Observable<boolean> {
        return this.avatarApiService.deleteAvatar(avatarId).pipe(
            tap((isDeleted: boolean) => {
                if (isDeleted) {
                    const avatars = this.avatarDealStoreService.getCustomAvatarsSnapshot();
                    const resultAvatars = avatars.filter(a => a.id != avatarId);

                    const dataLog = {
                        "deleteAvatar": isDeleted,
                        "avatars": avatars,
                        "resultAvatars": resultAvatars,
                    };

                    console.log(JSON.stringify(dataLog));
                    this.avatarDealStoreService.setCustomAvatars(resultAvatars);
                }
            })
        );
    }
}

