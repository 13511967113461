@if (currentUser) {
<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
  <div class="row">
    <div class="col">
      <a [routerLink]="['/pipeline/1']" class="content-center aou-link">
        <img src="../../assets/lfh1.png" class="logo d-inline">
        <span [routerLink]="['/pipeline/1']" class="navbar-brand d-none d-xl-inline">AOU</span>
      </a>
    </div>
  </div>
  @if (isAuth) {
  <div class="search phone-search d-none d-xl-block" #contactSearch id="contact-search"
    (clickOutside)="closeDropDown(contactSearch)">
    <div class="input-search">
      <input placeholder="Search by contact" type="text" [(ngModel)]="searchTextByContact"
        (keyup)="toggleDropDown($event, contactSearch)">
    </div>
    @if (showContactDropDown) {
    <div class="dropdown-container" (scroll)="searchByContact()" #contactContainer>
      <div class="position-relative">
        @for (deal of dealsByContact; track deal) {
        <div class="state">
          @if (deals) {
          <a class="deal-title" [href]="openDealInNewTab(deal)" target="_blank">
            <div class="title">
              <i class="material-icons">monetization_on</i>
              {{deal.title}}
            </div>
            <div class="search-phones">
              {{deal.value}} ({{deal.label | pdCapitalize}})
            </div>
          </a>
          }
        </div>
        }
        @if (contactLazyLoading) {
        <div class="lazyLoading">
          <div class="text-lazy">Loading more deals...</div>
        </div>
        }
        @if (contactSearchSpinner) {
        <div class="spinner">
          <mat-spinner [diameter]="30"></mat-spinner>
        </div>
        }
        @if ((!dealsByContact || dealsByContact.length == 0) && searchTextByContact.length > 2 && !contactSearchSpinner)
        {
        <div class="no-result">
          No results for '{{searchTextByContact}}'
        </div>
        }
      </div>
    </div>
    }
  </div>
  }
  <div #navbarCollapse class="collapse navbar-collapse order-1 order-lg-0" id="navbarNavDropdown">
    <ul class="navbar-nav align-items-lg-center">
      <li class="nav-item" [routerLinkActive]="['active']">
        <a class="nav-link" [routerLink]="['/pipeline/1']" (click)="onCollapse()">Deals</a>
      </li>
      @if (isAllowTasksTab) {
      <li class="nav-item" [routerLinkActive]="['active']">
        <a class="nav-link" [routerLink]="['/', ROUTE_NAMES.Tasks]">Tasks</a>
      </li>
      }
      @if (isAllowMapTab) {
      <li class="nav-item" [routerLinkActive]="['active']">
        <a class="nav-link" [routerLink]="['/Map']" (click)="onCollapse()">Map</a>
      </li>
      }
      @if (isAllowUsersTab) {
      <li class="nav-item" [routerLinkActive]="['active']">
        <a class="nav-link" [routerLink]="['/Users']" (click)="onCollapse()">Users</a>
      </li>
      }
      <li class="nav-item" [routerLinkActive]="['active']">
        <a class="nav-link" [routerLink]="['/GlobalSearch']" (click)="onCollapse();">Search</a>
      </li>
      @if (isAllowTOPsTab) {
      <li class="nav-item">
        <a class="nav-link" href="http://ingot.cloud" target="_blank" (click)="onCollapse()">TOPs</a>
      </li>
      }
      <li class="nav-item">
        <a class="nav-link" [href]="modeler3dUrl" target="_blank" (click)="onCollapse()">3D</a>
      </li>
      <li class="nav-item" [routerLinkActive]="['active']">
        <a class="nav-link" [routerLink]="['/Uploading']" (click)="onCollapse()">Upload Hotlist</a>
      </li>
      @if (isAllowPaymentTab) {
      <li class="nav-item" [routerLinkActive]="['active']">
        <a class="nav-link" [routerLink]="['/', ROUTE_NAMES.Payment]" (click)="onCollapse()">Payment</a>
      </li>
      }
      @if (isAllowClientSettingsTab) {
      <li class="nav-item" [routerLinkActive]="['active']">
        <a class="nav-link" [routerLink]="['/client-settings']" (click)="onCollapse()">Client Settings</a>
      </li>
      }
      @if (isAuth) {
      <li class="nav-item" [routerLinkActive]="['active']">
        <a class="nav-link" style="cursor: pointer;" (click)="onCollapse();logout()">Logout</a>
      </li>
      }
      @if (isAllowUsersWithRedDealsInMeetingConfirmPipeline && usersWithRedDealsInMeetingConfirmPipeline?.length > 0) {
      <li class="nav-item d-none d-lg-block">
        <div>
          <img src="../../assets/flag.png" class="flag-img"
            [bootstrapTooltip]="usersWithRedDealsInMeetingConfirmPipeline">
        </div>
      </li>
      }
      @if (isAllowBirthdayToday && personsBirthdayToday?.length > 0) {
      <li class="nav-item">
        <div>
          <img src="../../assets/birthday_cake.png" bootstrapTooltip="{{personsBirthdayToday}}" data-html="true"
            placement="bottom" class="birthday-img">
        </div>
      </li>
      }
    </ul>
  </div>

  @if (user && isAuth) {
  <button class="d-none d-xl-block btn waves-light sales-chart-btn right-item">
    <a class="sales-chart-btn-text" [href]="salesChartUrl" target="_blank">Sales month to date</a>
  </button>
  }

  @if (user && isAuth && user.referralLink) {
  <button type="button" class="d-none d-xl-block btn waves-light referral-link-btn right-item help-tip"
    [text-copy]="user.referralLink" (click)="isCopied()" #tooltip (mouseenter)="$event.stopImmediatePropagation()"
    placement="bottom">Copy Referral
    Link
    <p [ngStyle]="{'display': copied ? 'block': 'none'}">Copied</p>
  </button>
  }


  @if (user && isAuth) {
  <div class="row user m-auto">
    <div class="content-center col user-account">
      <i class="material-icons">account_circle</i>
      <div class="information">
        @if (isAllowAchievement) {
        <a href="/Achievements" class="name">{{user.name}}</a>
        }
        @if (!isAllowAchievement) {
        <span class="name">{{user.name}}</span>
        }
        <br>
        <span class="company-name">{{user.company_name}}</span>
      </div>
    </div>
  </div>
  }
  <div class="row">
    <div class="col">
      <div class="navbar-toggler-wrapper">
        <button class="navbar-toggler p-0" (click)="onTogleCollapse()" type="button" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>
    </div>
  </div>
</nav>
}
@if (!currentUser) {
<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
  <div class="row">
    <div class="col">
      <span [routerLink]="['/pipeline/1']" class="content-center aou-link">
        <img src="../../assets/lfh1.png" class="logo d-inline">
        <span [routerLink]="['/pipeline/1']" class="navbar-brand d-none d-xl-inline">AOU</span>
      </span>
    </div>
  </div>
  <div #navbarCollapse class="collapse navbar-collapse order-1 order-lg-0" id="navbarNavDropdown">
    <ul class="navbar-nav align-items-lg-center">
      @if (!isAuth) {
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['/login']" (click)="onCollapse()">Login</a>
      </li>
      }
    </ul>
  </div>
</nav>
}
@if (this.isAuth || !currentUser) {
<router-outlet></router-outlet>
}