import { ClientRating } from "../../enums/ClientRating";
import { ObjectUtil } from "../../utils/object.util";
import { ChipsSelectFilterDataModel } from "../CustomChipsSelectFilterModel";
import { Pipeline } from "../Pipeline";

export class BulkMessageSettingUpdateDto {
    public statusId: number;
    public clientRatings: ClientRating[];
    public pipelines: Pipeline[];
    public ownerIds: number[];
    public locations: ChipsSelectFilterDataModel[];

    constructor(data?: any) {
        ObjectUtil.extend(data, this);
    }
}
