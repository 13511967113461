import { Injectable } from "@angular/core";
import { Avatar } from "../../models/avatar-models/Avatar";
import { Observable, from } from "rxjs";
import { AvatarBuilderData } from "../../models/avatar-models/AvatarBuilderData";
import { BaseService } from "../base.service";
import { HttpRestService } from "../http-client/http-rest-client.service";

@Injectable()
export class AvatarDealApiService extends BaseService {
    constructor(private restService: HttpRestService) {
        super();
    }

    public getClientCardAvatarsByDealId(dealId: number): Observable<Avatar[]> {
        return from(this.handleResponse(this.restService.get({
            url: `/avatarDeal/getClientCardAvatarsByDealId/${dealId}`
        })));
    }

    public getAvatarByClientCardId(cardId: number): Observable<Avatar> {
        return from(this.handleResponse(this.restService.get({
            url: `/avatarDeal/getAvatarByClientCardId/${cardId}`
        })));
    }

    public getSeasonalProposalsByDealId(dealId: number): Observable<Avatar[]> {
        return from(this.handleResponse(this.restService.get({
            url: `/avatarDeal/getSeasonalProposalsByDealId/${dealId}`
        })));
    }

    public getStylesAvailableAvatarsByDealId(dealId: number): Observable<Avatar[]> {
        return from(this.handleResponse(this.restService.get({
            url: `/avatarDeal/getStylesAvailableAvatarsByDealId/${dealId}`
        })));
    }

    public getCustomAvatarsByDealId(dealId: number): Observable<Avatar[]> {
        return from(this.handleResponse(this.restService.get({
            url: `/avatarDeal/getCustomAvatarsByDealId/${dealId}`
        })));
    }

    public getAvatarDealSettingsBuilder(dealId: number): Observable<AvatarBuilderData> {
        return from(this.handleResponse(this.restService.get({
            url: `/avatarDeal/getAvatarDealSettingsBuilder/${dealId}`
        })));
    }

    public getAvatarsBase64(dealId: number, avatarIds: number[]): Observable<Avatar[]> {
        const data =
        {
            dealId: dealId,
            avatarIds: avatarIds
        }
        return from(this.handleResponse(this.restService.post({
            url: `/avatarDeal/getAvatarsBase64`,
            data: data
        })));
    }

    public createCustomAvatar(dealId: number, avatarBuilderData: AvatarBuilderData): Observable<number> {
        const data = avatarBuilderData;
        return from(this.handleResponse(this.restService.post({
            url: `/avatarDeal/createCustomAvatar/${dealId}`,
            data: data
        })));
    }

    public updateAvatarDealSettings(dealId: number, avatarBuilderData: AvatarBuilderData): Observable<boolean> {
        const data = avatarBuilderData;
        return from(this.handleResponse(this.restService.put({
            url: `/avatarDeal/updateAvatarDealSettings/${dealId}`,
            data: data
        })));
    }

    public updateClientCardAvatar(avatarId: number, avatarBuilderData: AvatarBuilderData): Observable<boolean> {
        const data = avatarBuilderData;
        return from(this.handleResponse(this.restService.put({
            url: `/avatarDeal/updateClientCardAvatar/${avatarId}`,
            data: data
        })));
    }

    public updateCustomAvatar(avatarId: number, avatarBuilderData: AvatarBuilderData): Observable<boolean> {
        const data = avatarBuilderData;
        return from(this.handleResponse(this.restService.put({
            url: `/avatarDeal/updateCustomAvatar/${avatarId}`,
            data: data
        })));
    }

    public downloadDealAvatar(dealId: number, avatarId: number): Observable<string> {
        return from(this.handleResponse(this.restService.get({
            url: `/avatarDeal/downloadDealAvatar/${dealId}/${avatarId}`
        })));
    }

    public downloadAllClientCardAvatarsByDealId(dealId: number): Observable<Blob> {
        return from(this.handleResponse(this.restService.get({
            url: `/avatarDeal/downloadAllClientCardAvatarsByDealId/${dealId}`,
            responseType: 'blob' as 'json'
        })));
    }

    public downloadSeasonalProposalAvatarsByDealId(dealId: number): Observable<Blob> {
        return from(this.handleResponse(this.restService.get({
            url: `/avatarDeal/downloadSeasonalProposalAvatarsByDealId/${dealId}`,
            responseType: 'blob' as 'json'
        })));
    }

    public downloadStylesAvailableAvatarsByDealId(dealId: number): Observable<Blob> {
        return from(this.handleResponse(this.restService.get({
            url: `/avatarDeal/downloadStylesAvailableAvatarsByDealId/${dealId}`,
            responseType: 'blob' as 'json'
        })));
    }

    public downloadAllCustomAvatarsByDealId(dealId: number): Observable<Blob> {
        return from(this.handleResponse(this.restService.get({
            url: `/avatarDeal/downloadAllCustomAvatarsByDealId/${dealId}`,
            responseType: 'blob' as 'json'
        })));
    }
}