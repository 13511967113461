import { Component, ViewChild, Input, Output, EventEmitter, ElementRef, HostListener, AfterViewInit, ChangeDetectorRef } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: 'pd-custom-select-grouped-data',
  templateUrl: './custom-select-grouped-data.html',
  styleUrls: ['./custom-select-grouped-data.scss']
})

export class CustomSelectFilterGroupedDataComponent implements AfterViewInit {
  _onDestroy = new Subject<void>();
  countLettersForPagination = 2;
  isShowSelectAll = true;
  isNotLetter: number = 0;
  label: string;

  public isOpenSelect = false;
  public searchText: string;
  public multiNumberFilter: FormControl = new FormControl();
  public filteredData = [];
  public groupedData = [];
  public selectAllDisabled: boolean = false;

  @ViewChild('selectContainer', { static: false }) selectContainer: ElementRef;
  @ViewChild('selectAll') selectAllButton: ElementRef;
  @ViewChild('deselectAll') deselectAllButton: ElementRef;

  @Input() public dataArray = [];
  @Input() selectText: string;
  @Input() dataLabel: string;
  @Input() isMultiple = true;
  @Input() public startLabel: string
  @Input() public isSelectAll: boolean;
  @Input() public isFixedStartLabel: boolean = false;
  @Input() public isUserMode: boolean = false;
  @Input() public displaySearch: boolean = true;
  @Input() public isDisabled: boolean = false;

  @Output() autoCompleteEvent = new EventEmitter<Object>();
  @Output() loadDataEvent = new EventEmitter<Object>();
  @Output() openEvent = new EventEmitter();
  @Output() closeEvent = new EventEmitter();
  @Output() selectedItemEvent = new EventEmitter<boolean>();
  @Output() stateUpdatedEvent = new EventEmitter();

  constructor(private cdRef: ChangeDetectorRef, private _el: ElementRef) {
    this.isSelectAll = false;
    this.multiNumberFilter.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.cdRef.detectChanges();
        this.scrollToTop();
      });
  }

  ngAfterViewInit(): void {
    if (!this.selectText) {
      this.selectText = "";
    }
    else {
      this.searchText = this.selectText;
    }
    if (this.isSelectAll) {
      this.dataArray = [];
      for (let i = 0; i < this.filteredData.length; i++) {
        if (this.selectText) {
          if (this.filteredData[i].toLowerCase().includes(this.selectText.toLowerCase()) && !this.dataArray.includes(this.filteredData[i])) {
            this.dataArray.push(this.filteredData[i]);
          }
        }
        else {
          this.dataArray.push(this.filteredData[i]);
        }
      }

      this.selectAllButton.nativeElement.className = "button background-color-green";
      this.label = "SelectAll";
      if (this.selectText) {
        this.label = "SelectAll (" + this.selectText + ")";
      }
    }
    else {
      this.selectAllButton.nativeElement.className = "button";
      this.label = this.startLabel || this.dataLabel;
      if (this.dataArray && this.dataArray.length > 0 && this.isMultiple) {
        this.label = '';
        for (let i = 0; i < this.dataArray.length; i++) {
          if (this.dataArray.length === 1) {
            this.label += this.dataArray[i].name;
          } else {
            this.label += this.dataArray[i].name + ', ';
          }
        }
        for (let i = 0; i < this.dataArray.length; i++) {
          this.dataArray.push(this.dataArray[i]);
        }
      }
    }
  }

  public onSearch() {
    if (this.searchText && this.searchText.length >= this.countLettersForPagination) {
      this.filterMultiSelect();
    } else if (!this.searchText) {
      this.filteredData = [];
      this.isShowSelectAll = true;
      this.isNotLetter = 0;
      this.loadDataEvent.emit({ isNotLetter: this.isNotLetter });
    }
  }

  private filterMultiSelect() {
    let search = this.searchText;
    if (!search) {
      this.loadDataEvent.emit({ isNotLetter: this.isNotLetter });
      this.stateUpdatedEvent.emit();
      return;
    } else {
      search = search.toLowerCase();
      this.autoCompleteEvent.emit({ searchString: search, isNotLetter: this.isNotLetter });
      return;
    }
  }

  public openSelect() {
    this.isOpenSelect = !this.isOpenSelect;
    this.openEvent.emit();
  }

  public closeCustomSelect() {
    this.isOpenSelect = false;
    this.closeEvent.emit();
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement: ElementRef) {
    const clickedOutside = !this._el.nativeElement.contains(targetElement);
    if (clickedOutside && this.isOpenSelect) {
      this.isOpenSelect = false;
      this.closeEvent.emit();
    }
  }

  public selectItem(item: any, event: any) {
    this.isOpenSelect = true

    if (!this.dataArray) {
      this.dataArray = [];
    }

    if (this.isMultiple && !event.checked) {
      this.isSelectAll = false;
    }
    this.label = "";
    if (event.checked) {
      this.selectAllDisabled = false;
      this.dataArray.push(item)
    }
    else if (this.isMultiple) {
      let idx = this.dataArray.findIndex(arrItem => arrItem.id == item.id)
      if (idx != -1) {
        this.dataArray.splice(idx, 1)
      } else {
        this.dataArray.push(item)
      }
      if (this.dataArray.length == 0 && this.isUserMode) {
        this.selectAllDisabled = true;
        this.label = this.dataLabel;
      }
    }

    if (this.isMultiple) {
      if (this.isSelectAll) {
        if (!event.checked) {
          let itemsWithoutUnchecked = this.filteredData.filter(value => !this.dataArray.includes(value))
          for (let i = 0; i < itemsWithoutUnchecked.length; i++) {
            this.label += i === itemsWithoutUnchecked.length ? itemsWithoutUnchecked[i].name : itemsWithoutUnchecked[i].name + ', '
          }
        }
        else {
          this.label = "SelectAll";
          if (this.selectText) {
            this.label = "SelectAll (" + this.selectText + ")";
          }
        }
      }
      else {
        for (let i = 0; i < this.dataArray.length; i++) {
          if (this.dataArray.length === 1) {
            this.label += this.dataArray[i].name;
          } else {
            this.label += this.dataArray[i].name + ', ';
          }
        }
      }
    } else {
      if (event.checked) {
        this.dataArray = [item]
        this.label = item.name
      } else {
        this.dataArray = []
        this.label = this.dataLabel
      }

    }
    this.selectedItemEvent.emit(event.checked)
    this.deselectAllButton.nativeElement.className = "button";
    this.selectAllButton.nativeElement.className = "button";
    if (!this.isMultiple) {
      this.isOpenSelect = false;
      this.closeEvent.emit();
    }

    this.stateUpdatedEvent.emit();
  }

  public selectAllData() {
    if (this.searchText) {
      if (this.selectText.toLowerCase() != this.searchText.toLowerCase()) {
        this.selectText = this.searchText;
      }
    }
    let localCheckedBuff = [];
    this.dataArray = [];
    for (let i = 0; i < this.filteredData.length; i++) {
      if (this.selectText) {
        if (this.filteredData[i].name.toLowerCase().includes(this.selectText.toLowerCase())) {
          localCheckedBuff.push(this.filteredData[i]);
        }
      }
      else {
        localCheckedBuff.push(this.filteredData[i]);
      }
    }

    this.dataArray = [];
    this.isSelectAll = true;
    this.label = "SelectAll";
    if (this.selectText) {
      this.label = "SelectAll (" + this.selectText + ")";
    }

    this.dataArray = this.isUserMode ? localCheckedBuff.filter(x => !x.disabled) : localCheckedBuff;
    this.stateUpdatedEvent.emit();
  }

  public deselectAllData() {
    this.onSearch();
    this.resetSelectorAllData();
    if (this.isUserMode) {
      this.selectAllDisabled = true;
    }
    this.label = this.dataLabel;
    this.selectedItemEvent.emit(false);
  }

  public resetSelectorAllData() {
    this.selectText = "";
    this.searchText = "";
    this.isSelectAll = false;
    this.dataArray = [];
    this.label = this.dataLabel;
    this.stateUpdatedEvent.emit();
  }

  public checkItem(item: any) {
    return this.dataArray && this.dataArray.some(i => i.id === item)
  }

  public setData(data) {
    if (data) {
      if (data.length != 0) {
        this.isShowSelectAll = true;
      }
      else {
        this.isShowSelectAll = false;
      }
      this.groupedData = data.map(x => x);
      this.filteredData = [];
      data.forEach(i => {
        this.filteredData = this.filteredData.concat(i.values);
      });
      if (this.isSelectAll) {
        this.dataArray = [];
        for (let i = 0; i < this.filteredData.length; i++) {
          if (this.selectText) {
            if (this.filteredData[i] && this.filteredData[i].name.toLowerCase().includes(this.selectText.toLowerCase()) && !this.dataArray.includes(this.filteredData[i])) {
              this.dataArray.push(this.filteredData[i]);
            }
          }
          else {
            this.dataArray.push(this.filteredData[i]);
          }
        }

        this.label = "SelectAll";
        if (this.selectText) {
          this.label = "SelectAll (" + this.selectText + ")";
        }

      }
      else {
        if (this.dataArray) {
          if (this.dataArray.length > 0) {
            this.label = "";
          }
          for (let i = 0; i < this.dataArray.length; i++) {
            if (this.dataArray.length === 1) {
              this.label += this.dataArray[i].name;
            } else {
              this.label += this.dataArray[i].name + ', ';
            }
          }
        }
      }
      this.stateUpdatedEvent.emit();
    }
  }

  private scrollToTop() {
    this.selectContainer.nativeElement.scrollTo({
      top: 0,
      behavior: 'auto'
    });
  }
}