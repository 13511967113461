import { GlobalConstants } from './../../../core/global-constants';
import { PipelineDealsStoreService } from './../../../core/services/stores/pipeline-deal-store.service';
import { StageFeatureStoreService } from './../../../core/services/stores/stage-feature-store.service';
import { UserStoreService } from './../../../core/services/stores/user-store.service';
import { UserContextService } from './../../../core/services/user-context.service';
import { Component, OnInit, ViewChild, OnDestroy, HostListener } from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';
import * as stages from '../../../../assets/stages_new_prospespects';
import { FormControl, FormGroup } from '@angular/forms';
import { KillRecordOptions } from '../modal-options/kill-record.options';
import { CustomKillRecordModalComponent } from '../custom-kill-record-modal/custom-kill-record-modal.component';
import { User } from 'src/app/core/models/UserModel';
import { StageService } from 'src/app/core/services/stage.service';
import { DealService } from 'src/app/core/services/deals.service';
import { Stage } from 'src/app/core/models/Stage';
import { RolesEnum } from 'src/app/core/enums/RolesEnum';
import { Multitransfer } from 'src/app/core/models/Multitransfer';
import { takeUntil } from 'rxjs';
import { BaseComponent } from 'src/app/core/base.component';

@Component({
  selector: 'pd-transfer-pop-up',
  templateUrl: './transfer-pop-up.component.html',
  styleUrls: ['./transfer-pop-up.component.scss'],
  animations: [
    trigger('moveDealsState', [
      state('in', style({
        transform: 'translate(0,0)'
      })),
      state('out', style({
        transform: 'translate(100%, 0)'
      })),
      transition('out => in', animate('400ms ease-in-out')),
      transition('in => out', animate('400ms ease-in-out'))
    ])
  ]
})
export class TransferPopUpComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild('killRecordModal') killRecordModal: CustomKillRecordModalComponent
  public killRecordOptions: KillRecordOptions
  private previousStageToMove: any
  private currentRecord: any
  public totalCount: number | null
  public currentUser: User
  public users: User[]
  public multitransfer: Multitransfer[] = []
  public isDisabledTransfer = true
  public maxCountOwners = 5
  public moveDealsState: string = 'out'
  public transferForm = {}
  public isOpen = false
  public isTransfersValid: boolean
  public currentDisplayedDealsFilter: Stage[]
  private confirmCallback: any
  public ALL_STAGES = GlobalConstants.ALL_STAGES;

  constructor(
    private userContextService: UserContextService,
    private userStoreService: UserStoreService,
    private stageService: StageService,
    public dealService: DealService,
    private stageFeatureStoreService: StageFeatureStoreService,
    private pipelineDealsStoreService: PipelineDealsStoreService,) {
    super();
    this.killRecordOptions = new KillRecordOptions();
  }

  ngOnInit() {
    this.pipelineDealsStoreService.additionalData.pipe(takeUntil(this.destroy$)).subscribe(additionalData => {
      if (additionalData) {
        this.totalCount = additionalData.total_count;
      }
    });
    this.stageFeatureStoreService.getCurrentStages.pipe(takeUntil(this.destroy$)).subscribe(selectedStages => {
      if (!selectedStages || selectedStages.length === 0) {
        return;
      }
      this.currentDisplayedDealsFilter = selectedStages;
      this.currentUser = this.userContextService.user.value;
      const allUsers = this.userStoreService.allUsers.value
      if (selectedStages && selectedStages.length > 0) {
        if (selectedStages.some(selectedStage => selectedStage.id === this.ALL_STAGES.Referral || selectedStage.id === this.ALL_STAGES.RefVM1 || selectedStage.id === this.ALL_STAGES.RefVM2 || selectedStage.id === this.ALL_STAGES.RefVM3))
          this.users = this.userStoreService.getUsersForTransferDropDown(this.currentUser,
            this.getUserToStageAllowedRolesForTransferDropDown(selectedStages[0], allUsers));
        else
          this.users = this.userStoreService.getUsersForTransferDropDown(this.currentUser,
            this.getUserToStageAllowedRolesForTransferDropDown(selectedStages[0], allUsers, true));
      }
      this.multitransfer = [];
      this.addOneMoreOwner();
      if (this.users && this.users.length > 0) {
        this.multitransfer.forEach(transfer => {
          if (!this.users.some(user => user.id === transfer.OwnerId)) {
            transfer.OwnerId = this.users[0].id;
            transfer.StageTo = null;
          }
        });
      }
    });

    this.validate();
  }

  private getUserToStageAllowedRolesForTransferDropDown(selectedStage: Stage, selectedUsersFilter: User[], isIgnoreStageAvaliability = false): RolesEnum[] {
    if (selectedStage && selectedStage.id) {
      if (this.currentUser && this.currentUser.is_admin === true && isIgnoreStageAvaliability) {
        return Object.keys(RolesEnum).map(key => RolesEnum[key]);
      } else {
        let allowedRoles: RolesEnum[] = [];
        allowedRoles = Object.entries(stages.STAGES_BY_ROLES_VISIBILITY).reduce((result, current) => {
          if (current[1].some(stage => stage === selectedStage.name)
            || ((current[0] === RolesEnum[RolesEnum.Admin] || current[0] === RolesEnum[RolesEnum.SystemAccount])
              && selectedUsersFilter && selectedUsersFilter[0] && selectedUsersFilter[0].role_id === RolesEnum.MasterLead)) {
            result.push(RolesEnum[current[0]]);
          }
          return result;
        }, []);
        return allowedRoles;
      }
    }

    return [];
  };

  ngOnDestroy() {
  }


  public addOneMoreOwner() {
    if (this.users && this.users.length > 0) {
      const user = this.users[0];
      let newMultitransfer = new Multitransfer(user.id, null, null, null, null)
      this.setPipelineForUser(user, newMultitransfer)
      newMultitransfer.StageTo = null;
      this.multitransfer.push(newMultitransfer)
      this.validate();
    }
  }

  public deleteOwner(index) {
    this.multitransfer.splice(index, 1);
    this.validate();
  }

  public transferToChanged(transferTo, transfer: Multitransfer) {
    const user = this.users.find(u => u.id === transferTo);
    if (user) {
      let changedTransfer = this.multitransfer.find(m => m.Id === transfer.Id)
      this.setPipelineForUser(user, changedTransfer)
      if (changedTransfer) {
        changedTransfer.StageTo = changedTransfer.SelectedStage;
        changedTransfer.ReasonOfKill = null
      }
    }
  }

  public openStageSelect(stageId) {
    this.previousStageToMove = stageId;
  }

  public selectStageToMove(stage, record) {
    if (stage.id === this.ALL_STAGES.Kill || stage.id === this.ALL_STAGES.KilledRecord) {
      this.currentRecord = record;
      this.killRecordModal.openModal((function () {
        this.multitransfer.find(tr => tr.Id === record.Id).ReasonOfKill = this.killRecordModal.inputData;
        this.killRecordModal.inputData = "";
      }).bind(this));
    }
    record.SelectedStage = record.StageTo;
    this.validate();
  }

  public cancelMoveRecord() {
    this.currentRecord.StageTo = this.previousStageToMove;
  }

  public closeModal() {
    this.moveDealsState = 'out';
    this.killRecordModal.inputData = "";
  }

  public updateOwner() {
    if (this.confirmCallback)
      this.confirmCallback()
    this.resetComponentState()
  }

  public validate() {
    this.isTransfersValid = this.multitransfer && this.multitransfer.length > 0 &&
      this.multitransfer.every(t => t.Limit > 0 && t.OwnerId && t.StageTo) && this.multitransfer.reduce((a, b) => a + b.Limit, 0) <= this.totalCount;
  }

  private resetComponentState() {
    this.moveDealsState = 'out'
    this.killRecordModal.inputData = "";
    if (this.users && this.users.length > 0) {
      this.multitransfer = []
      if (this.currentUser && this.currentUser.role_id) {
        let newMultitransfer = new Multitransfer(this.users[0].id, null, null, null, null)
        this.setPipelineForUser(this.users[0], newMultitransfer)
        newMultitransfer.StageTo = null;
        this.multitransfer.push(newMultitransfer)
      }
      this.validate();
    }
  }

  private setPipelineForUser(user: any, newMultitransfer: Multitransfer) {
    let forbiddenStageIds = [this.ALL_STAGES.ClientSaved, this.ALL_STAGES.Meeting, this.ALL_STAGES.Hired];
    if (!this.currentUser.is_admin) {
      forbiddenStageIds.push(this.ALL_STAGES.Float, this.ALL_STAGES.Kill);
    }

    if (this.currentUser.role_id !== RolesEnum.CampaignAccount) {
      forbiddenStageIds.push(...[this.ALL_STAGES.SM1]);
    }

    const selectedStages = this.selectStageToUserStagesForDropDown([], forbiddenStageIds, user).filter(i => i.pipeline_id === stages.Pipelines.NewProspects);
    if (selectedStages && selectedStages.length > 0) {
      newMultitransfer.StatusesByPipelines = this.stageService.stagesToPipelinesArray(selectedStages)
      if (this.transferForm[newMultitransfer.Id] === undefined) {
        this.transferForm[newMultitransfer.Id] = new FormGroup({
          owner: new FormControl(''),
          stage: new FormControl(''),
        });
      }

      this.transferForm[newMultitransfer.Id].setValue({
        owner: user.id,
        stage: newMultitransfer.SelectedStage || null
      });
    }
  }

  private selectStageToUserStagesForDropDown(requiredStageIds: number[], forbiddedStageIds: number[], selectedUser: User) {
    let allItems = this.stageFeatureStoreService.allStage.value;
    if (selectedUser && allItems) {
      selectedUser.role_id = selectedUser.role_id || RolesEnum.User
      let allowedStageNames = stages.STAGES_BY_ROLES_VISIBILITY[RolesEnum[selectedUser.role_id]];
      if (this.currentDisplayedDealsFilter.some(x => x.id === this.ALL_STAGES.Referral))
        allItems = allItems.filter(x => x.id !== this.ALL_STAGES.VM1 && x.id !== this.ALL_STAGES.VM2 && x.id !== this.ALL_STAGES.VM3)
      return allItems.filter(stage => stage.pipeline_id !== stages.Pipelines.ClothierMeetingConfirm && stage.pipeline_id !== stages.Pipelines.ClothierContactClients && !forbiddedStageIds.some(s => s === stage.id)
        && (requiredStageIds.some(s => s === stage.id) || allowedStageNames.some((s: string) => s === stage.name)));
    }
    return [];
  }

  public openModal(confirmCallback = null) {
    this.confirmCallback = confirmCallback
    this.moveDealsState = 'in';
    if (this.users && this.users.length > 0) {
      if (this.currentUser && this.currentUser.role_id) {
        let newMultitransfer = new Multitransfer(this.users[0].id, null, null, null, null)
        this.setPipelineForUser(this.users[0], newMultitransfer)
        newMultitransfer.StageTo = null;
      }
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    event.preventDefault();
    const pastedInput: string = event.clipboardData
      .getData('text/plain')
      .replace(/\D/g, ''); // get a digit-only string
    document.execCommand('insertText', false, pastedInput);
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent) {
    if (
      // Allow: Delete, Backspace, Tab, Escape, Enter
      [46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1 ||
      (e.keyCode === 65 && e.ctrlKey === true) || // Allow: Ctrl+A
      (e.keyCode === 67 && e.ctrlKey === true) || // Allow: Ctrl+C
      (e.keyCode === 86 && e.ctrlKey === true) || // Allow: Ctrl+V
      (e.keyCode === 88 && e.ctrlKey === true) || // Allow: Ctrl+X
      (e.keyCode === 65 && e.metaKey === true) || // Cmd+A (Mac)
      (e.keyCode === 67 && e.metaKey === true) || // Cmd+C (Mac)
      (e.keyCode === 86 && e.metaKey === true) || // Cmd+V (Mac)
      (e.keyCode === 88 && e.metaKey === true) || // Cmd+X (Mac)
      (e.keyCode >= 35 && e.keyCode <= 39) // Home, End, Left, Right
    ) {
      return;
    }

    if (
      (e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) &&
      (e.keyCode < 96 || e.keyCode > 105)
    ) {
      e.preventDefault();
    }
  }
}
