export class PaymentUtil {
  public static getStatusMessage(key: string) {
    switch (key) {
      case 'succeeded': return "Success"
      case 'processing': return "Your payment is processing."
      case 'requires_payment_method': return "Your payment was not successful, please try again."

      default: return "Something went wrong.";
    }
  }

  public static isStatusMessageError(key: string) {
    switch (key) {
      case 'succeeded': return false;
      case 'processing': return false;
      default: return true;
    }
  }

  public static getStripeErrorMessage(error) {
    let message = "";

    if (error.type === 'card_error'
      || error.type === 'validation_error'
      || error.type === "invalid_request_error") {
      message = error.message;
    }
    else {
      message = "An unexpected error occurred.";
    }
    return message;
  }
}
