import { UserContextService } from './../core/services/user-context.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import * as stages from '../../assets/stages_new_prospespects';
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';
import { Router } from '@angular/router';
import { RolesEnum } from '../core/enums/RolesEnum';
import { InputField } from './models/InputField';
import { Role } from '../core/models/Role';
import { UsersService } from '../core/services/users.service';
import { User } from '../core/models/UserModel';
import { RoleService } from '../core/services/role.service';
import { DateHelper } from '../core/utils/date.helper';
import { STAGES_FOR_ALL_PIPELINES } from '../../assets/stages_new_prospespects';
import { DealService } from '../core/services/deals.service';
import { GlobalConstants } from '../core/global-constants';
import { StageDealSummaryModel } from '../core/models/StageDealSummaryModel';

@Component({
  selector: 'pd-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  animations: [
    trigger('modalState', [
      state('in', style({
        transform: 'translate(0,300px)'
      })),
      state('out', style({
        transform: 'translate(0,-10vh)'
      })),
      transition('out => in', animate('400ms ease-in-out')),
      transition('in => out', animate('400ms ease-in-out'))
    ]),
    trigger('modalChange', [
      state('in', style({
        transform: 'translate(0,135%)'
      })),
      state('out', style({
        transform: 'translate(0,-15vh)'
      })),
      transition('out => in', animate('400ms ease-in-out')),
      transition('in => out', animate('400ms ease-in-out'))
    ])
  ]
})
export class UsersComponent implements OnInit {
  public users: User[] = [];
  public spinner: boolean = true;
  public menuState: string = 'out';
  public usersList: User[] = [];
  public ownerId: number | null = null;
  public userId: number | null = null;
  public closedUser: User | null = null;
  public localstorageUsers: User[] = [];
  public allUsers: User[] = [];
  public isShowError: boolean = false;
  public allowToUpdateUsers: boolean = false;
  public changePasswordMenuState: string = 'out';
  public updateUserMenuState: string = 'out';
  public openCCMenuState: string = 'out';
  public selectedUser: User = new User();
  public password: string = '';
  public confirmPassword: string = '';
  public UserName: string = '';
  public Email: string = '';
  public Role: RolesEnum | null = null;
  public RolesEnum = RolesEnum;
  public stagesWithDeals: any;
  public fullName: InputField = new InputField();
  public email: InputField = new InputField();
  public referralLink: InputField = new InputField();
  public isLocal: boolean = false;
  public isTravel: boolean = false;
  public isTravelLocalIsValid: boolean = false;
  public role: Role | null = null;
  public roles: Role[] = [];
  public isTasksTab: boolean = false;
  public isUsersTab: boolean = false;
  public isAllowedExportLeadDate: boolean = false;
  public isAllowedHistoryOfLeads: boolean = false;
  public isRepoAccount: boolean = false;
  public isWhatsappChat: boolean = false;
  public isGlobalChat: boolean = false;
  public isAvatarEditor: boolean = false;
  public isSeeOthersCms: boolean = false;
  public isInSmReport: boolean = false;
  public isNotInClothierReport: boolean = false;
  public openCCDate: Date | null = null;
  public exceptions: string[] = [];
  public allRoles: Role[] = [];
  @ViewChild('form') form: any; // Specify the type if possible

  constructor(private roleService: RoleService, private usersService: UsersService, private dealService: DealService,
    private router: Router, private userContextService: UserContextService) { }

  async ngOnInit(): Promise<void> {
    const user = this.userContextService.user.value;
    this.allowToUpdateUsers = (user.role_id === RolesEnum.MasterLead) || (user.isUsersTab && user.role_id === RolesEnum.Admin);
    this.roles = await this.roleService.getAll();
    await this.getUsers();
    this.localstorageUsers = this.users;
  }

  /**
   * Get users
   * from server
   * @memberof UsersComponent
   */

  public async getUsers(): Promise<void> {
    const result = await this.usersService.getUsers();

    if (result) {
      this.users = result as User[];
      this.allUsers = this.users;
      this.isShowError = false;
      this.spinner = false;
    }
  }

  /**
   * Hide modal
   * @memberof UsersComponent
   */

  public hideModal() {
    if (this.isShowError) {
      this.getUsers();
    }
    this.ownerId = null;
    this.usersList = this.deepClone(this.allUsers);
    this.menuState = 'out';

  }

  /**
   * Close user
   * @memberof UsersComponent
   */

  public updateList() {
    this.usersService.closeUser(this.userId, this.ownerId).then(result => {
      localStorage.removeItem('users');
      for (let i = 0; i < this.localstorageUsers.length; i++) {
        if (this.userId === this.localstorageUsers[i].id) {
          this.localstorageUsers.splice(i, 1);
        }
      }
      localStorage.setItem('users', JSON.stringify(this.localstorageUsers));
      this.usersList = this.deepClone(this.allUsers);
      this.menuState = 'out';
      this.spinner = true;
      this.getUsers();
    },
      err => {
        this.isShowError = true;
        //this.menuState = 'out';
        // this.spinner = true;
        // this.getUsers();
      });
  }

  public async closeOutUser(user: User): Promise<void> {
    this.spinner = true;
    const hasConnections = await this.usersService.checkIsEaHasConnections(user.id);

    if (hasConnections) {
      this.spinner = false;
      await this.showAlert('Warning! You cannot remove user/update role EA/Admin because clothier is assigned under him.');
      return;
    }

    const dealStages = await this.dealService.getStagesDealsSummaryByOwnerId(user.id);

    if (dealStages && dealStages.length > 0) {
      this.spinner = false;
      const alertMessages: string[] = [];

      dealStages.forEach((deal: StageDealSummaryModel) => {
        const stageInfo = STAGES_FOR_ALL_PIPELINES.find(s => s.id === deal.stageId);
        if ((stageInfo.pipeline_id === stages.Pipelines.Clients
          || stageInfo.pipeline_id === stages.Pipelines.OpenOrders
          || stageInfo.pipeline_id === stages.Pipelines.ClothierContactClients
          || stageInfo.pipeline_id === stages.Pipelines.ClothierMeetingConfirm
          || stageInfo.id === GlobalConstants.ALL_STAGES.Meeting
          || stageInfo.id === GlobalConstants.ALL_STAGES.ClientSaved)
        ) {                    
          alertMessages.push(`Stage: ${stageInfo.name}, Deal Count: ${deal.dealCount}`);
        }
      });

      if (alertMessages.length > 0) {
        const alertMessage = alertMessages.join('\n');
        await this.showAlert(`Warning! You cannot close user account because user has clients assigned:\n${alertMessage}`);
        return;
      }
    }

    this.spinner = false;
    this.usersList = this.deepClone(this.allUsers);
    this.userId = user.id;

    for (let i = 0; i < this.usersList.length; i++) {
      if (this.usersList[i].id === user.id) {
        this.closedUser = this.usersList[i]; { }
        this.usersList.splice(i, 1);
      }
    }

    this.usersList = this.usersList.filter(u => {
      const isSameRole = u.role_id === user.role_id;
      const isAdminAndClothier = (u.role_id === RolesEnum.Admin && user.role_id === RolesEnum.Clothier) || (u.role_id === RolesEnum.Clothier && user.role_id === RolesEnum.Admin);
      const isMasterLeadWithNoStages = u.role_id === RolesEnum.MasterLead && dealStages.length === 0;
      const isMasterLeadWithVisibleStages = dealStages.length > 0 && u.role_id === RolesEnum.MasterLead && dealStages.every(s => stages.STAGES_BY_ROLES_VISIBILITY.MasterLead.includes(this.getStageName(s.stageId)));

      return isSameRole || isAdminAndClothier || isMasterLeadWithNoStages || isMasterLeadWithVisibleStages;
    });

    this.menuState = 'in';
  }

  /**
    * Deep clone
    * coppy phone or email array
    * @param {Object[]} array selected array
    * @memberof UsersComponent
    */

  public deepClone(array: Object[]) {
    const newArray: any = [];
    array.forEach((item) => {
      newArray.push(Object.assign({}, item));
    });
    return newArray;
  }

  public routToNewUser() {
    this.router.navigate(['/', 'newUser']);
  }

  public getIsEnabledDeleting(user: any) {
    if (user) {
      return this.users.filter(u => u.role_id === user.role_id).length > 1 || (this.users.filter(u => u.role_id === user.role_id).length <= 1 &&
        stages.PROSPECTS_BY_ROLES[RolesEnum[user.role_id]].every(s => stages.PROSPECTS_BY_ROLES.MasterLead.includes(s)) && stages.MEETING_CONFIRM_BY_ROLES[RolesEnum[user.role_id]].length === 0)
    }
    return false;
  }

  public changeUserPassword(user: any) {
    this.selectedUser = user;
    this.changePasswordMenuState = "in";
  }

  public updateUser(user: any) {
    if (!user) {
      return;
    }
    this.selectedUser = user;
    this.role = this.roles.find(x => x.id === user.role_id);
    this.fullName.value = this.selectedUser.name;
    this.referralLink.value = this.selectedUser.referralLink;
    this.isLocal = this.selectedUser.isLocal;
    this.isTravel = this.selectedUser.isTravel;
    this.updateUserMenuState = "in";
  }

  public async saveUpdateUser() {
    let data = new User()
    data.name = this.fullName.value
    data.id = this.selectedUser.id
    data.role_id = this.role.id
    data.isTasksTab = this.isTasksTab;
    data.isUsersTab = this.isUsersTab;
    data.isAllowedExportLeadDate = this.isAllowedExportLeadDate;
    data.isAllowedHistoryOfLeads = this.isAllowedHistoryOfLeads;
    data.isRepoAccount = this.isRepoAccount;
    data.isWhatsappChat = this.isWhatsappChat;
    data.isGlobalChat = this.isGlobalChat;
    data.isAvatarEditor = this.isAvatarEditor;
    data.isSeeOthersCms = this.isSeeOthersCms;
    data.isInSmReport = this.isInSmReport;
    data.isNotInClothierReport = this.isNotInClothierReport;
    data.email = this.email.value
    data.referralLink = this.referralLink.value
    data.isLocal = (this.isShowTravelLocal) ? this.isLocal : false;
    data.isTravel = (this.isShowTravelLocal) ? this.isTravel : false;

    await this.usersService.updateUser(data);
    this.getUsers()
    this.hideUpdateUserModal()
  }

  public openCCModal(user: any) {
    if (user) {
      this.selectedUser = user;
      this.openCCDate = user.open_cc_date;
      this.openCCMenuState = "in";
    }
  }

  public saveOpenCC() {
    const formattedDate = DateHelper.formatAsISODateString(this.openCCDate);
    if (formattedDate) {
      this.selectedUser.open_cc_date = new Date(formattedDate);
      this.usersService.updateOpenCC(this.selectedUser).then(result => {
        this.getUsers();
        this.hideCCModal();
      });
    }
  }

  public savePassword() {
    let changePasswordModel = {
      userId: this.selectedUser.id,
      password: this.password,
      confirmPassword: this.confirmPassword,
    };

    this.usersService.changePassword(changePasswordModel).then(result => {
      if (result.succeeded) {
        localStorage.removeItem('users');
        for (let i = 0; i < this.localstorageUsers.length; i++) {
          if (this.userId === this.localstorageUsers[i].id) {
            this.localstorageUsers.splice(i, 1);
          }
        }
        localStorage.setItem('users', JSON.stringify(this.localstorageUsers));
        this.usersList = this.deepClone(this.allUsers);
        this.hideChangePasswordModal();
        this.spinner = true;
        this.getUsers();
      }
    },
      err => {
        this.isShowError = true;
      });
  }

  public saveChanges(user: any) {

  }

  public hideChangePasswordModal() {
    this.changePasswordMenuState = 'out';
    this.password = "";
    this.confirmPassword = "";
    this.selectedUser = null;
    this.form.resetForm();
  }

  public hideUpdateUserModal() {
    this.updateUserMenuState = 'out';
    this.UserName = "";
    this.Email = "";
    this.Role = undefined;
    this.selectedUser = null;
    this.form.resetForm();
  }

  public hideCCModal() {
    this.openCCMenuState = 'out';
    this.openCCDate = undefined;
  }

  public calcCCExpired(date: Date): boolean {
    if (date) {
      let ccDate = new Date(date);
      let today = DateHelper.removeTimeFromDate(new Date());
      return ccDate >= today;
    }
    return false;
  }

  public getFullName(data: InputField): void {
    this.fullName = data;
    this.exceptions = [];
  }

  public getReferralLink(data: InputField): void {
    this.referralLink = data;
    this.exceptions = [];
  }

  public getIsLocal(data: any): void {
    this.isLocal = data.value;
    this.isTravelLocalIsValid = data.isValid;
    this.exceptions = [];
  }

  public getIsTravel(data: any): void {
    this.isTravel = data.value;
    this.isTravelLocalIsValid = data.isValid;
    this.exceptions = [];
  }

  public getEmail(data: InputField): void {
    this.email = data;
    this.exceptions = [];
  }

  public getRole(data: any) {
    this.role = data.role;
    this.isTasksTab = data.isTasksTab;
    this.isUsersTab = data.isUsersTab;
    this.isAllowedExportLeadDate = data.isAllowedExportLeadDate;
    this.isAllowedHistoryOfLeads = data.isAllowedHistoryOfLeads;
    this.isRepoAccount = data.isRepoAccount;
    this.isWhatsappChat = data.isWhatsappChat;
    this.isGlobalChat = data.isGlobalChat;
    this.isAvatarEditor = data.isAvatarEditor;
    this.isSeeOthersCms = data.isSeeOthersCms;
    this.isInSmReport = data.isInSmReport;
    this.isNotInClothierReport = data.isNotInClothierReport;
    this.exceptions = [];
  }

  public get isShowTravelLocal(): boolean {
    return (this.role && (this.role.id === RolesEnum.Admin || this.role.id === RolesEnum.Clothier));
  }

  private getStageName(stageId: number): string {
    return STAGES_FOR_ALL_PIPELINES.find(stage => stage.id === stageId)?.name || 'Unknown Stage';
  }

  public async showAlert(message: string): Promise<void> {
    this.spinner = false;
    await new Promise(resolve => setTimeout(resolve, 100));
    window.alert(message);
  }
}
