import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'pd-field-notes',
  templateUrl: './field-notes.component.html',
  styleUrls: ['./field-notes.component.scss']
})
export class FieldNotesComponent implements OnInit {

  public notes: string;
  @Output() notesChange = new EventEmitter<string>();
  public isEdit = false;

  constructor() { }

  ngOnInit() {
  }

  /**
    * Change edit
    * in deal
    * @memberof FieldNotesComponent
    */

  public changeEdit() {
    this.isEdit = !this.isEdit;
    this.notesChange.emit(this.notes);
  }

  /**
    * Add value
    * open edit
    * @memberof FieldNotesComponent
    */

  public addValue() {
    this.isEdit = true;
  }
}
