import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from "@angular/core";
import { DateHelper } from "src/app/core/utils/date.helper";
import { Message } from "src/app/core/models/chat-models/Message";
import { TwilioFacadeService } from "src/app/core/services/twilio/twilio-facade.service";
import { takeUntil } from "rxjs";
import { BaseComponent } from "src/app/core/base.component";
import { Chat } from "src/app/core/models/chat-models/Chat";
import { DealUtil } from "src/app/core/utils/deal.util";

@Component({
  selector: 'pd-chat-header',
  templateUrl: './chat-header.component.html',
  styleUrls: ['./chat-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ChatHeaderComponent extends BaseComponent {
  public DealUtil = DealUtil;

  public timer: string = '';
  public isSessionActive = false;
  public activeChat: Chat;
  public messages: Message[];

  constructor(
    private ref: ChangeDetectorRef,
    private twilioFacadeService: TwilioFacadeService,
  ) {
    super();
    twilioFacadeService.getActiveChatObservable().pipe(takeUntil(this.destroy$)).subscribe(chat => {
      this.activeChat = chat;
      this.ref.markForCheck();
    });

    twilioFacadeService.getMessagesObservable().pipe(takeUntil(this.destroy$)).subscribe(messages => {
      this.messages = messages;
      this.ref.markForCheck();
    });

    this.startTimer();
  }

  public getActiveChatTitle() {
    if (!this.activeChat)
      return;

    return this.activeChat.name ? `${this.activeChat.name} (${this.activeChat.chatPhone})` : this.activeChat.chatPhone;
  }

  private startTimer() {
    setInterval(() => {
      if (!this.messages || this.messages.length === 0) {
        this.timer = '';
        this.isSessionActive = false;
        this.ref.detectChanges();
        return;
      }

      const currentTime = new Date().getTime();
      const lastClientMessage = this.messages
        .filter(m => m.isInboundMessage)
        .sort((a, b) => DateHelper.utcStringToLocalDate(a.time).getTime() - DateHelper.utcStringToLocalDate(b.time).getTime())
        .pop();

      if (!lastClientMessage) {
        this.timer = '';
        this.isSessionActive = false;
        this.ref.detectChanges();
        return;
      }

      const timeDiff = currentTime - DateHelper.utcStringToLocalDate(lastClientMessage.time).getTime();
      const timeLeft = DateHelper.dayToMiliseconds - timeDiff;

      if (timeLeft < 1) {
        this.timer = '';
        this.isSessionActive = false;
        this.ref.detectChanges();
        return;
      }

      const hours = Math.floor(timeLeft / DateHelper.hourToMiliseconds);
      const minutes = Math.floor((timeLeft % DateHelper.hourToMiliseconds) / DateHelper.minuteToMiliseconds);
      const seconds = Math.floor((timeLeft % DateHelper.minuteToMiliseconds) / DateHelper.secondToMiliseconds);

      this.timer = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

      this.isSessionActive = true;
      this.ref.detectChanges();
    }, 100);
  }
}