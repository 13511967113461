<div mat-dialog-title>
  Qr Code
</div>
<mat-dialog-content>
  @if (isSpinner) {
  <div class="spinner">
    <mat-spinner [diameter]="100"></mat-spinner>
  </div>
  }

  @if (!isSpinner && base64DataImg) {
  <div class="modal-content">
    <img class="qr-code_img" [src]="base64DataImg" />
    @if (!data.isClientCard) {
    <div class="qr-code_copy-link-wrapper">
      <button type="button" mdbBtn class="waves-light btn-primary btn qr-code_copy-link-btn" aria-label="Copy Link"
        [text-copy]="qrCodeShortLink" (click)="$event.stopPropagation()" mdbTooltip="Copy Link" placement="bottom"
        mdbWavesEffect>Copy
        Link</button>
    </div>
    }

    @if (data.isClientCard) {
    <div class="qr-code_copy-link-wrapper">
      <button type="button" mdbBtn class="waves-light btn-primary btn qr-code_copy-link-btn"
        style="margin-bottom: 20px;" aria-label="Copy Link" [text-copy]="qrCodeShortLink"
        (click)="$event.stopPropagation()" mdbTooltip="Copy link (for clients)" placement="bottom" mdbWavesEffect>Copy
        link (for clients)</button>
      <button type="button" mdbBtn class="waves-light btn-primary btn qr-code_copy-link-btn" aria-label="Copy Link"
        [text-copy]="qrCodeLink" (click)="$event.stopPropagation()" mdbTooltip="Copy link (for TOPS)" placement="bottom"
        mdbWavesEffect>Copy link (for TOPS)</button>
    </div>
    }
  </div>
  }
</mat-dialog-content>
<mat-dialog-actions>
  <button type="button" mdbBtn class="waves-light btn-danger btn" aria-label="Close" (click)="cancel()"
    mdbWavesEffect>Close</button>
  <button type="button" mdbBtn class="relative waves-light btn-success btn" mdbWavesEffect [disabled]="!qrCodeBase64"
    (click)="downloadQrCode()">Download</button>
</mat-dialog-actions>