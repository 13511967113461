<div class="row wrapper">
  <div class="col-4 title">
    Position
  </div>
  <div class="col-8 work-field">
    @if (!isEdit) {
    <pd-custom-select-filter #positionFilter (document:focusout)="changeValue()"
      (loadDataEvent)="getPositionsFilterData($event)" (autoCompleteEvent)="getPositionsAutocomplete($event)"
      (isSelectedItem)="onChanged($event)" (open)="onSelectOpen()" [dataLabel]="positionsLabel" [isShowExcept]="false"
      [isMultiple]="false">
    </pd-custom-select-filter>
    }
    @if (isSelectedPosition == false && !isEdit) {
    <pd-add-value (addValue)="addValue()"></pd-add-value>
    }
    @if (isEdit) {
    <pd-edit-in-progress #positionEdit [isAllowEmpty]="false" (save)="savePosition($event)"
      (cancel)="cancelNewPosition()"></pd-edit-in-progress>
    }
  </div>
</div>