import { Component, OnInit, ViewChild, HostListener, Input, Output, EventEmitter, ElementRef, ChangeDetectorRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ChipsSelectFilterModel } from 'src/app/core/models/CustomChipsSelectFilterModel';
import { CustomFilterModel } from 'src/app/core/models/CustomFilterModel';

@Component({
  selector: 'pd-custom-chips-select-filter',
  templateUrl: './pd-custom-chips-select-filter.component.html',
  styleUrls: ['./pd-custom-chips-select-filter.component.scss']
})
export class CustomChipsSelectFilterComponent implements OnInit {

  public selectLazyLoading = false;
  public selectStart = 0;
  public isOpenSelect = false;
  public multiNumberFilter: FormControl = new FormControl();
  public filteredData = [];
  public currentFilterChip: ChipsSelectFilterModel;
  private _onDestroy = new Subject<void>();
  private countPagination = 8;
  private countLettersForPagination = 2;

  @Input() dataLabel: string;
  @Input() filterChips: ChipsSelectFilterModel[];
  @Input() isExceptOnly: boolean;

  @Output() sendData = new EventEmitter<Object>();
  @Output() autoCompleteEvent = new EventEmitter<CustomFilterModel>();
  @Output() loadDataEvent = new EventEmitter<CustomFilterModel>();
  @Output() open = new EventEmitter();
  @Output() close = new EventEmitter();

  @ViewChild('selectContainer', { static: false }) selectContainer: ElementRef;

  isNotLetter: number = 0;
  label: string;
  isDisabled = false;
  isShowLoading = false;
  timeout: any;

  ngOnInit() {
  }

  getFilterChips(): ChipsSelectFilterModel[] {
    if (!this.filterChips || this.filterChips.length === 0) {
      this.filterChips = new Array<ChipsSelectFilterModel>();
      this.addChip();
    }
    if (this.filterChips.length === 1) {
      this.currentFilterChip = this.filterChips[0];
    }
    return this.filterChips;
  }

  addChip() {
    const newItem = new ChipsSelectFilterModel()
    newItem.isExcept = this.isExceptOnly;
    this.currentFilterChip = newItem;
    this.filterChips.push(newItem);
  }

  selectChip(filterChip: any) {
    this.currentFilterChip = filterChip;
  }

  public resetChips() {
    this.filterChips = new Array<ChipsSelectFilterModel>();
  }

  deleteChip(filterChip: any) {
    const index = this.filterChips.indexOf(filterChip);

    if (index >= 0) {
      this.filterChips.splice(index, 1);
    }

    const filterChips = this.getFilterChips();
    this.currentFilterChip = filterChips[filterChips.length - 1];

    this.isOpenSelect = true;
    this.sendData.emit(this.getFilteredItems());
  }

  constructor(private cdRef: ChangeDetectorRef, private _el: ElementRef) {
    this.getFilterChips();
    this.multiNumberFilter.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {      
        this.cdRef.detectChanges();
        this.scrollToTop();
        if (!this.currentFilterChip.searchText) {
          if (this.timeout) {
            clearTimeout(this.timeout);
          }
          this.timeout = setTimeout(() => {
            this.filteredData = [];
            this.selectLazyLoading = false;
            this.isShowLoading = true;
            this.isNotLetter = 0;
            this.selectStart = 0;
            this.loadDataEvent.emit(new CustomFilterModel({ take: this.countPagination, skip: 0, isNotLetter: this.isNotLetter }));
          }, 1000);
        }
      });
  }

  public onSearch()
  {
    if (this.currentFilterChip.searchText && this.currentFilterChip.searchText.length > this.countLettersForPagination) {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.isNotLetter = this.currentFilterChip.searchText.length;
        this.filterMultiSelect();
        this.filteredData = [];
        this.isShowLoading = true;

      }, 1000);
    }
  }

  /**
  * Do lazy loading
  * add lazy loading on custom select
  * @param {any} event scrolling
  * @memberof CustomChipsSelectFilterComponent
  */

  @HostListener('window:scroll', ['$event'])
  doLazyLoading(event) {
    if (this.selectContainer.nativeElement.scrollTop >= this.selectContainer.nativeElement.scrollHeight - this.selectContainer.nativeElement.clientHeight - 100 && !this.selectLazyLoading) {
      this.selectLazyLoading = true;
      this.isDisabled = true;
      this.selectStart += this.countPagination;
      this.filterMultiSelect();
    }
  }

  onExceptModeChange() {
    this.getLabel();
    this.getLargeLabel();
  }

  public openSelect() {
    if (this.isExceptOnly) {
      this.getFilterChips().forEach((item) => {
        item.isExcept = true;
      })
    }

    this.isOpenSelect = true;
    this.open.emit();
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement: ElementRef) {
    const clickedOutside = !this._el.nativeElement.contains(targetElement) && !(targetElement as any).className.includes("closebtn");
    if (clickedOutside && this.isOpenSelect) {
      this.isOpenSelect = false;
      this.close.emit();
      this.sendData.emit(this.getFilteredItems());
    }
  }

  public selectItem(item: any, event: any) {
    this.isOpenSelect = true
    if (!this.currentFilterChip.dataArray) {
      this.currentFilterChip.dataArray = [];
    }

    if (!this.currentFilterChip.isSelectAll) {
      if (event.checked) {
        this.currentFilterChip.dataArray.push(item)
      }
      else {
        const idx = this.currentFilterChip.dataArray.findIndex(arrItem => arrItem.id == item.id)
        if (idx != -1) {
          this.currentFilterChip.dataArray.splice(idx, 1)
        }
      }
    }
    else {
      if (event.checked) {
        const idx = this.currentFilterChip.dataArray.findIndex(arrItem => arrItem.id == item.id)
        if (idx != -1) {
          this.currentFilterChip.dataArray.splice(idx, 1)
        }
      }
      else {
        this.currentFilterChip.dataArray.push(item)
      }
    }

    this.sendData.emit(this.getFilteredItems());
  }

  public selectAllData() {
    if (this.currentFilterChip.searchText) {
      if (this.currentFilterChip.selectText.toLowerCase() != this.currentFilterChip.searchText.toLowerCase()) {
        this.currentFilterChip.selectText = this.currentFilterChip.searchText;
      }
    }
    else {
      this.currentFilterChip.selectText = "";
    }

    this.currentFilterChip.dataArray = [];
    this.currentFilterChip.isSelectAll = true;
    this.sendData.emit(this.getFilteredItems());
  }

  public getMainLabel(): string {
    const filterChips = this.getFilterChips();
    if (filterChips.length > 0) {
      const label = filterChips.map(u => u.label).filter(f => f).join("\n");
      return (label) ? label : this.dataLabel;
    }
    return this.dataLabel;
  }

  public checkItem(id: any): boolean {
    return (this.currentFilterChip.isSelectAll)
      ? this.filteredData.some(i => (i.id === id) && !this.currentFilterChip.dataArray.some(j => (j.id === id)))
      : this.currentFilterChip.dataArray.some(i => (i.id === id));
  }

  public deselectAllData() {
    this.currentFilterChip.selectText = "";
    this.currentFilterChip.searchText = "";
    this.currentFilterChip.label = "";
    this.currentFilterChip.largeLabel = "";
    this.currentFilterChip.isSelectAll = false;
    this.currentFilterChip.isExcept = (this.isExceptOnly) ? true : false;
    this.currentFilterChip.dataArray = [];

    for (let i = 0; i < this.filteredData.length; i++) {
      this.currentFilterChip.dataArray.push(this.filteredData[i]);
    }
    this.currentFilterChip.dataArray = [];
    this.sendData.emit(this.getFilteredItems());
  }

  public setData(data) {
    this.isShowLoading = false;
    if (data) {
      if (this.selectLazyLoading) {
        if (data.length != 0) {
          data.forEach(i => {
            this.filteredData.push(i);
          });
        }
        this.isDisabled = false;
        this.selectLazyLoading = false;
      }
      else {
        this.filteredData = data;
      }
    }

    this.selectChip(this.currentFilterChip);
  }

  private filterMultiSelect() {
    let search = this.currentFilterChip.searchText;
    if (!search) {
      if (!this.selectLazyLoading) {
        this.selectStart = 0;
      }
      this.loadDataEvent.emit(new CustomFilterModel({ take: this.countPagination, skip: this.selectStart, isNotLetter: this.isNotLetter }));
      return;
    } else {
      if (!this.selectLazyLoading) {
        this.selectStart = 0;
      }
      search = search.toLowerCase();
      this.autoCompleteEvent.emit(new CustomFilterModel({ searchString: search, skip: this.selectStart, take: this.countPagination, isNotLetter: this.isNotLetter }));
      return;
    }
  }

  private toShortLabel(label: string): string {
    return label.slice(0, 10) + (label.length > 10 ? "..." : "");
  }

  private toLargeLabel(label: string): string {
    return label.slice(0, 40) + (label.length > 40 ? "..." : "");
  }

  private getFilteredItems(): ChipsSelectFilterModel[] {
    this.getLabel();
    this.getLargeLabel();
    return this.getFilterChips().filter(f => f.label);
  }

  private getLabel() {
    this.currentFilterChip.label = '';

    if (this.currentFilterChip.isSelectAll) {
      const selectAllLabel = (this.currentFilterChip.isExcept) ? "ExceptAll" : "SelectAll";
      const selectExludeLabel = (this.currentFilterChip.isExcept) ? " Exclude" : " Except"
      this.currentFilterChip.label = selectAllLabel + "(" + this.currentFilterChip.selectText + ")";
      if (this.currentFilterChip.dataArray.length > 0) {
        this.currentFilterChip.label += selectExludeLabel + "("
        this.currentFilterChip.label += (this.currentFilterChip.dataArray.length === 1)
          ? this.toShortLabel(this.currentFilterChip.dataArray[0].name) : this.toShortLabel(this.currentFilterChip.dataArray[0].name) + '; '

        if (this.currentFilterChip.dataArray.length > 1) {
          this.currentFilterChip.label += "+" + (this.currentFilterChip.dataArray.length - 1);
        }

        this.currentFilterChip.label += ")"
      }
    }
    else {
      const selectAllLabel = (this.currentFilterChip.isExcept) ? "Except" : "Select";
      if (this.currentFilterChip.dataArray.length > 0) {
        this.currentFilterChip.label += selectAllLabel + "("
        this.currentFilterChip.label += (this.currentFilterChip.dataArray.length === 1)
          ? this.toShortLabel(this.currentFilterChip.dataArray[0].name) : this.toShortLabel(this.currentFilterChip.dataArray[0].name) + '; '

        if (this.currentFilterChip.dataArray.length > 1) {
          this.currentFilterChip.label += "+" + (this.currentFilterChip.dataArray.length - 1);
        }

        this.currentFilterChip.label += ")"
      }
    }
  }

  private getLargeLabel() {
    this.currentFilterChip.largeLabel = '';
    if (this.currentFilterChip.isSelectAll) {
      const selectAllLabel = (this.currentFilterChip.isExcept) ? "ExceptAll" : "SelectAll";
      const selectExludeLabel = (this.currentFilterChip.isExcept) ? " Exclude" : " Except"
      this.currentFilterChip.largeLabel = selectAllLabel + "(" + this.currentFilterChip.selectText + ")";
      if (this.currentFilterChip.dataArray.length > 0) {
        this.currentFilterChip.largeLabel += selectExludeLabel + "("

        this.currentFilterChip.dataArray.forEach(chip => {
          this.currentFilterChip.largeLabel += this.toLargeLabel(chip.name) + "; ";
        });

        this.currentFilterChip.largeLabel += ")";
      }
    }
    else {
      const selectAllLabel = (this.currentFilterChip.isExcept) ? "Except" : "Select";
      if (this.currentFilterChip.dataArray.length > 0) {
        this.currentFilterChip.largeLabel += selectAllLabel + "("
        this.currentFilterChip.dataArray.forEach(chip => {
          this.currentFilterChip.largeLabel += this.toLargeLabel(chip.name) + "; ";
        });

        this.currentFilterChip.largeLabel += ")";
      }
    }
  }

  private scrollToTop() {
    this.selectContainer.nativeElement.scrollTo({
      top: 0,
      behavior: 'auto'
    });
  }
}
