<div class="row wrapper">
  <div class="col-4 title">
    <span style="color: red; font-size: 20px">*</span> Company
  </div>
  <div class="col-8 work-field">
    @if (!isEdit) {
    <pd-custom-select-filter #companyFilter (document:focusout)="changeValue()"
      (loadDataEvent)="getCompaniesFilterData($event)" (autoCompleteEvent)="getCompaniesAutocomplete($event)"
      (isSelectedItem)="onChanged($event)" (open)="onSelectOpen()" [dataLabel]="companiesLabel" [isMultiple]="false">
    </pd-custom-select-filter>
    }
    @if (isSelectedCompany == false && !isEdit) {
    <pd-add-value (addValue)="addValue()"></pd-add-value>
    }
    @if (isEdit) {
    <pd-edit-in-progress #companyEdit (save)="saveCompany($event)" (cancel)="cancelNewCompany()"></pd-edit-in-progress>
    }
  </div>
</div>