export enum AchievementGroups {
    AnnualSalesAchievementPins = 1,
    MonthlySalesAchievementPins,
    BigSalePins,
    RingOfHonourPins,
    GoldSalePins,
    DiamondSalePins,
    ServiceAwards,
    LeadershipAnnualAchievementPins,
    LeadershipMonthlyAchievementPins,
    CompanyTripsWon,
    CompanyMeetingsAttended,
}
