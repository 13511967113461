import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FilterType } from 'src/app/core/enums/FilterType.enum';
import { ChipsSelectFilterDataModel, ChipsSelectFilterModel } from 'src/app/core/models/CustomChipsSelectFilterModel';
import { DealService } from 'src/app/core/services/deals.service';
import { CustomChipsSelectFilterComponent } from '../custom/custom-chips-select-filter/pd-custom-chips-select-filter.component';

@Component({
  selector: 'pd-location-chips-filter',
  templateUrl: './location-chips-filter.component.html'
})
export class LocationChipsFilterComponent implements OnInit {
  @ViewChild("locationFilter") public customSelectLocation: CustomChipsSelectFilterComponent;
  @Input() public isAllSelectedDefaultMode = false;
  @Output() public filterDataChanged = new EventEmitter<ChipsSelectFilterDataModel[]>();
  @Output() public open = new EventEmitter();
  @Output() public close = new EventEmitter();

  public locationsLabel: string = 'Locations';
  public spinner: boolean = false;
  public isCountResponseWait: boolean = false;
  public filteredLocations: ChipsSelectFilterDataModel[];

  constructor(private dealService: DealService) {
  }

  public ngOnInit() {
    this.filteredLocations = [];
  }

  public ngAfterViewInit() {
    this.resetFilter();
  }

  public openSelectFilter(select) {
    const customSelects = [this.customSelectLocation]
    customSelects.forEach(x => {
      if (x != select && x !== undefined)
        x.isOpenSelect = false
    })
    this.open.emit();
  }

  public setLocationsFilterData(event) {
    this.dealService.getFilterData(FilterType.Location, event.skip, event.take).then(response => {
      if (event.isNotLetter == this.customSelectLocation.isNotLetter) {
        this.customSelectLocation.setData(response);
      }
    });
  }

  public onLocationsAutocompleteRequest(event) {
    this.dealService.getFilterDataAutocomplete(FilterType.Location, event.searchString, event.skip, event.take).then(response => {
      if (event.isNotLetter == this.customSelectLocation.isNotLetter) {
        this.customSelectLocation.setData(response);
      }
    });
  }

  public getFilterData(data: ChipsSelectFilterModel[]) {
    this.filteredLocations = (data) ? data.map((item) => new ChipsSelectFilterDataModel(item)) : null;
    this.filterDataChanged.emit(this.filteredLocations);
  }

  public resetFilter() {
    this.customSelectLocation.deselectAllData();
    if (this.isAllSelectedDefaultMode) {
      this.customSelectLocation.selectAllData();
    }
  }

  public closeSelectFilter() {
    this.close.emit();
  }
}
