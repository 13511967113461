export class GlobalConstants {
    public static jwtSessionKey = 'jwt';
    public static refreshTokenKey = 'refreshToken';
    public static userContextKey = 'userContext';
    public static ROUTE_NAMES =
        {
            Tasks: 'tasks'
            , SearchNumbersTask: 'search-numbers-task'
            , SearchGenderTask: 'search-gender-task'
            , TargetedRecallTask: 'targeted-recall-task'
            , SearchAddressTask: 'search-address-task'
            , ImportDealsTask: 'import-deals-task'
            , AddFabricsTask: 'add-fabrics-task'
            , Reports: 'reports'
            , GlobalChat: 'global-chat'
            , AvatarSeasonalProposal: 'avatar-seasonal-proposal'
            , Payment: 'payment'
            , PaymentResult: 'payment-result'
            , SocialMediaAdsTokens: 'social-media-ads-tokens'
            , ClientSettings: 'client-settings'
            , BulkMessageSettings: 'bulk-message-settings'
            , BulkMessageRequests: 'bulk-message-requests'
        };
    public static ALL_STAGES = {
        NoPhone: 33,
        Kill: 18,
        NG: 25,
        DQ: 26,
        NoAns: 22,
        Cold: 1,
        Linkedin: 2,
        Email1: 20,
        Email2: 30,
        Email3: 31,
        Float: 4,
        Referral: 3,
        VM1: 6,
        VM2: 23,
        VM3: 24,
        Resched: 17,
        Meeting: 16,
        Cancel: 37,
        MetNG: 36,
        Less3Weeks: 32,
        CashedOut: 27,
        RecentlyContacted: 38,
        ContactClientNow: 8,
        EAVMEmail1: 9,
        EAVMEmail2: 28,
        EACurrentMeeting: 21,
        KilledRecord: 14,
        LinkedinLOOKUP: 35,
        ColdLOOKUP: 34,
        NA_Cancel: 39,
        NA_MetNG: 40,
        ClientSaved: 41,
        DeliveryCheck: 42,
        //Overdue: 43,
        SM: 44,
        SM1: 45,
        DM: 46,
        RefAsk: 47,
        Add1: 48,
        Add2: 49,
        LookForClientContactInfo: 50,
        ToContact: 51,
        FirstCall: 52,
        FirstWaiting: 53,
        FirstCallScheduled: 54,
        SecondCall: 55,
        SecondWaiting: 56,
        SecondCallScheduled: 57,
        ThirdCall: 58,
        ThirdWaiting: 59,
        ThirdCallScheduled: 60,
        Booked: 61,
        RepeatEvent: 62,
        //SetDelivery: 63,
        EmailFound: 64,
        Reschedule: 65,
        SeasonalCall: 66,
        VMEM1: 67,
        VMEM2: 68,
        TryNextSeason: 70,
        CurrentMeeting: 71,
        //NoResponse: 72,
        NewCandidates: 73,
        //ReachOut: 74,
        //ReachOut2: 75,
        SetInterview1: 76,
        Interview1: 77,
        Interview2: 78,
        //RefCheck: 79,
        OfferSent: 80,
        //CheckBack: 81,
        RecruiterNG: 82,
        Hired: 83,
        //ClientIssue: 84,
        //ClientIssueFollowUp: 85,
        LookForContactInfo: 86,
        NoEmailFound: 89,
        MeetingToConfirm: 92,
        CMVMEM1: 93,
        CMVMEM2: 94,
        CMVMEM3: 95,
        CMCancel: 96,
        CMReschedule: 97,
        Confirmed: 98,
        RefVM1: 99,
        RefVM2: 100,
        RefVM3: 101,
        CMRescheduleFollowUp: 102,
        PassToEaToContact: 103,
        OrderShipped: 104,
        //PassToEaToBookDelivery: 105,
        //EAInAlterations: 106,
        //EADeliveryMeeting: 107,
        DeliveryMeeting: 108,
        InAlterations: 109,
        //UKAlterations: 110,
        //Delivered: 111,
        NewOrder: 112,
        DraftOrder: 113,
        DraftConfirmed: 114,
        SameBuilding: 115,
        //GcOverdue: 116,
        MeetingSet: 117,
        WriteOff: 118,
        OverdueLess24Weeks: 119,
        OverdueLess16Weeks: 120,
        OverdueLess12Weeks: 121,
        Less9Weeks: 122,
        Less6Weeks: 123,
        RecruiterNgWithoutVideo: 124,
        VideoApplications: 125,
        //AdditionalQuestions: 126,
        SetInterview2: 127,
        //AdditionalInterview: 128,
        FollowDay: 129,
        RecruiterNGInterview1: 130,
        RecruiterNGInterview2: 131
    };
}
