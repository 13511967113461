<div class="col" style="margin: 15px;">
    <div class="row">
        <div class="col-6 col-xl-3">
            <div class="row">
                <div class="label">
                    EA:
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <pd-custom-select-without-lazy #userEaFromFilter (loadDataEvent)="getEaFromUsersFilterData()"
                        (selectedItemEvent)="onEaFromSelectedItem($event)"
                        (autoCompleteEvent)="getEaFromUsersAutocomplete($event)" [dataLabel]="eaUsersLabel"
                        [startLabel]="eaUsersLabel" [isMultiple]="false">
                    </pd-custom-select-without-lazy>
                </div>
            </div>
        </div>

        <div class="col-6 col-xl-3 reassign-all">
            <button class="btn btn-success waves-light m-0" [disabled]="!openReassignModalValidation()"
                (click)="openReassignModal()">Reassign
                All</button>
        </div>
    </div>


    <div [ngClass]="{ 'hidden': isResponseWait }">
        <div class="row">
            <div class="col-12 col-xl-6">
                <div class="row">
                    <div class="label">
                        Reps:
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="list-wrapper">
                            <ul class="list-group">
                                @for (clothier of leftViewSelectList; track clothier) {
                                <button type="button" class="list-group-item list-group-item-action"
                                    [ngClass]="getColorClass(clothier)" (click)="onAddToEa(clothier)">
                                    {{ clothier.name }} {{getUnderEaLabel(clothier)}}</button>
                                }
                            </ul>
                        </div>
                    </div>

                    <div class="col">
                        <div class="list-wrapper">
                            <ul class="list-group">
                                @for (clothier of rightViewSelectList; track clothier) {
                                <button type="button" class="list-group-item list-group-item-action"
                                    [ngClass]="getColorClass(clothier)" (click)="onRemoveFromEa(clothier)">
                                    {{ clothier.name }} {{getUnderEaLabel(clothier)}}</button>
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-xl-6">
                <div class="btn-container">
                    <div>
                        <button class="btn btn-info waves-light" [disabled]="!onSelectAllValidation()"
                            (click)="onSelectAll()">Select
                            All</button>
                        <button class="btn btn-danger waves-light" [disabled]="!onClearValidation()"
                            (click)="onClear()">Clear</button>
                    </div>
                    <div>
                        <button class="btn btn-success waves-light" [disabled]="!saveAssignValidation()"
                            (click)="onSaveAssign()">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    @if (isResponseWait) {
    <div class="row">
        <div class="col-12 col-xl-6">
            <div class="spinner">
                <mat-spinner></mat-spinner>
            </div>
        </div>
    </div>
    }
</div>

<modal id="stripe-payment-form" #modal [title]="'Reassign ALL from '+ selectedEaFromUser?.name" primaryBtnTextKey="Save"
    secondaryBtnTextKey="Close" [displayLaunchButton]="false" [isPrimaryBtnDisabled]="!onReassignToValidation()"
    (secondaryClick)="closeReassignModal()" (onCompleteAction)="closeReassignModal()" (primaryClick)="onReassignTo()">
    <div>
        <div class="col-8">
            <div class="row">
                @if (selectedEaFromUser) {
                <div class="label">
                    Reassign ALL from {{selectedEaFromUser.name}} to:
                </div>
                }
            </div>
            <div class="row">
                <div class="col">
                    <pd-custom-select-without-lazy #userEaToFilter (loadDataEvent)="getEaToUsersFilterData()"
                        (selectedItemEvent)="onEaToSelectedItem($event)"
                        (autoCompleteEvent)="getEaToUsersAutocomplete($event)" [dataLabel]="eaUsersLabel"
                        [startLabel]="eaUsersLabel" [isMultiple]="false">
                    </pd-custom-select-without-lazy>
                </div>
            </div>
        </div>
    </div>
</modal>