import { Component, OnInit } from '@angular/core';
import { LoginService } from '../core/services/login.service';
import { GlobalConstants } from '../core/global-constants';
import { UserContextService } from '../core/services/user-context.service';
import { RolesEnum } from '../core/enums/RolesEnum';
import { AppRouterService } from '../core/services/app-router.service';

@Component({
  selector: 'pd-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public username: string;
  public password: string;
  public spinner = false;
  public exception = null;
  public currentRole: string;
  public returnUrl: string;
  public isAuthenticated = false;
  constructor(private router: AppRouterService,
    private loginService: LoginService,
    private userContext: UserContextService) { }

  public async ngOnInit(): Promise<void> {
    this.returnUrl = this.router.returnUrl();
    this.userContext.isAuthorize.subscribe(isAuthenticated => {
      this.isAuthenticated = isAuthenticated;
      if (isAuthenticated) {
        this.router.openPipeline1();
      }
    });
  }

  public async login(): Promise<void> {
    this.spinner = true;
    let result = null;
    try {
      result = await this.loginService.login({ email: this.username, password: this.password });
    }
    catch (error) {
      this.spinner = false;
        this.exception = error.message;
        this.username = null;
        this.password = null;
    }
    if (!result) {
      return;
    }
    this.spinner = false;
    localStorage.setItem(GlobalConstants.jwtSessionKey, result.access_token);
    localStorage.setItem(GlobalConstants.refreshTokenKey, result.refresh_token);

    const user = await this.loginService.getContext();
    if (!user) {
      return;
    }
    this.userContext.setUser(user);
    this.userContext.setauthorizeStatus(true);
    localStorage.setItem(GlobalConstants.userContextKey, JSON.stringify(user));
    this.isAuthenticated = true;
    if (this.returnUrl) {
      this.router.openPage(this.returnUrl);
      return;
    }

    if (user.role_id === RolesEnum.EaCaller) {
      this.router.openPipeline4();
      return;
    }

    if (user.role_id === RolesEnum.EA) {
      this.router.openPipeline7();
      return;
    }

    this.router.openPipeline1();
  }

  cancel() {
    this.exception = null;
    this.username = null;
    this.password = null;
  }
}
