<ng-container>
    <div class="row" style="padding: 20px; display: flex; justify-content: space-between;">
        <div class="col">

            @if(isTemplatePreview)
            {
            <div class="row" style="padding: 10px 0;">
                <div class="col" style="display: flex; align-items: center;justify-content: end;">
                    <button style="margin: 0; max-width: 200px; position: relative;" class="btn btn-success waves-light"
                        (click)="onCopy();">Copy Template
                        <p class="tooltop" [ngStyle]="{'display': copied ? 'block': 'none'}">Copied!</p>
                    </button>
                </div>
            </div>
            }

            <div class="row" style="padding: 10px 0;">
                <div class="col variable_sample__item">
                    <div class="variable_sample__label">
                        Message From Name
                    </div>
                    <div class="variable_sample__value"
                        [ngClass]="{'variable_sample__value_disabled': isTemplatePreview }">
                        <input type="text" [(ngModel)]="messageFromName" [disabled]="isTemplatePreview">
                    </div>
                </div>
                <div class="col variable_sample__item">
                    <div class="variable_sample__label">
                        Message From Email
                    </div>
                    <div class="variable_sample__value"
                        [ngClass]="{'variable_sample__value_disabled': isTemplatePreview }">
                        <input type="text" [(ngModel)]="messageFromEmail" [disabled]="isTemplatePreview">
                    </div>
                </div>
            </div>

            <div class="row" style="padding: 10px 0;">
                <div class="col variable_sample__item">
                    <div class="variable_sample__label">
                        Emails Cc
                    </div>
                    <pd-email-chips-field #emailsCcField [isTemplatePreview]="isTemplatePreview"></pd-email-chips-field>
                </div>
                <div class="col variable_sample__item">
                    <div class="variable_sample__label">
                        Emails Bcc
                    </div>
                    <pd-email-chips-field #emailsBccField
                        [isTemplatePreview]="isTemplatePreview"></pd-email-chips-field>
                </div>
            </div>

            <div class="row" style="padding: 10px 0;">
                <div class="col variable_sample__item">
                    <div class="variable_sample__label">
                        Title of email
                    </div>
                    <div class="variable_sample__value"
                        [ngClass]="{'variable_sample__value_disabled': isTemplatePreview }">
                        <input type="text" [(ngModel)]="emailSubject" (ngModelChange)="generateSubjectVariables()"
                            [disabled]="isTemplatePreview">
                    </div>
                </div>
            </div>

            <div class="row" style="padding: 10px 0;">
                @for(variable of subjectVariables; track variable) {
                <div class="col variable_sample__item">
                    <div class="variable_sample__label">
                        Sample content for subject variable
                        <span>&#123;</span><span>&#123;</span>{{variable.variableNumber}}<span>&#125;</span><span>&#125;</span>
                    </div>
                    <div class="variable_sample__value variable_sample__value_disabled">
                        <input type="text" [(ngModel)]="variable.exampleData" disabled="true">
                    </div>
                    <div class="variable_sample__type" style="color: white; background-color:white;">
                        <mat-form-field style="width: 100%;">
                            <mat-label>Select Variable Type</mat-label>
                            <mat-select [(ngModel)]="variable.templateVariableContentType"
                                [disabled]="isTemplatePreview" (ngModelChange)="updateExampleData(variable)">
                                @for (type of variableTypes; track type) {
                                <mat-option [value]="type.id">
                                    {{ type.name }}
                                </mat-option>
                                }
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                }
            </div>

            <div class="row" style="padding: 10px 0;">
                <div class="col">
                    @if(!isTemplatePreview)
                    {
                    <quill-editor style="width: 100%;" [(ngModel)]="emailBody" [modules]="editorModules"
                        (onContentChanged)="onContentChanged($event)"></quill-editor>
                    }
                    @if(isTemplatePreview)
                    {
                    <div style="
                            box-sizing: border-box;
                            width: 100%;
                            box-shadow: rgb(202, 205, 216) 0px 0px 0px 1px;
                            border-radius: 4px;
                            transition: box-shadow 100ms ease-in 0s;">
                        <quill-view-html [content]="emailBody"></quill-view-html>
                    </div>
                    }
                </div>
            </div>

            <div class="row" style="padding: 10px 0;">
                @for(variable of variableDescriptions; track variable) {
                <div class="col variable_sample__item">
                    <div class="variable_sample__label">
                        Sample content for body variable
                        <span>&#123;</span><span>&#123;</span>{{variable.variableNumber}}<span>&#125;</span><span>&#125;</span>
                    </div>
                    <div class="variable_sample__value variable_sample__value_disabled">
                        <input type="text" [(ngModel)]="variable.exampleData" disabled="true">
                    </div>
                    <div class="variable_sample__type" style="color: white; background-color:white">
                        <mat-form-field style="width: 100%;">
                            <mat-label>Select Variable Type</mat-label>
                            <mat-select [(ngModel)]="variable.templateVariableContentType"
                                [disabled]="isTemplatePreview"
                                (ngModelChange)="updateExampleData(variable);generateInlinePlaceholder()">
                                @for (type of variableTypes; track type) {
                                <mat-option [value]="type.id">
                                    {{ type.name }}
                                </mat-option>
                                }
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                }
            </div>

            <div class="row" style="padding: 10px 0;">
                <mat-checkbox [(ngModel)]="isNeedUnsubscribeHtml" [disabled]="isTemplatePreview">Add Unsubscribe Button</mat-checkbox>
            </div>

            @if(!isTemplatePreview)
            {
            <div class="row" style="padding: 10px 0;">
                <div class="col variable_sample__item">
                    <div class="variable_sample__label">
                        Check the email by sending the preview (just indicate the email and click on Send Preview
                        button)
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="variable_sample__value">
                                <input type="text" [(ngModel)]="previewEmailTo">
                            </div>
                        </div>
                        <div class="col" style="display: flex; align-items: center;">
                            <button style="margin: 0; max-width: 200px; position: relative;"
                                class="btn btn-primary waves-light" (click)="sendPreviewEmail()"
                                [disabled]="!sendPreviewValdation()">Send
                                Preview

                                <p class="tooltop" [ngStyle]="{'display': sent ? 'block': 'none'}">Sent!</p>
                                <p class="tooltop tooltop-error" [ngStyle]="{'display': errorSent ? 'block': 'none'}">
                                    Error!</p>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            }

        </div>
    </div>
</ng-container>