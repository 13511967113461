import { UserContextService } from './../../core/services/user-context.service';
import { GlobalConstants } from './../../core/global-constants';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';
import * as stages from '../../../assets/stages_new_prospespects';
import printJS from 'print-js'
import { Deal } from 'src/app/core/models/Deal';
import { DealUtil } from 'src/app/core/utils/deal.util';
import { User } from 'src/app/core/models/UserModel';
import { HotlistService } from 'src/app/core/services/hotlist.service';
import { DealService } from 'src/app/core/services/deals.service';
import { RolesEnum } from 'src/app/core/enums/RolesEnum';
import { take} from 'rxjs';
import { BaseComponent } from 'src/app/core/base.component';

@Component({
    selector: 'pd-deal-card',
    templateUrl: './deal-card.component.html',
    styleUrls: ['./deal-card.component.scss']
})

export class DealCardComponent extends BaseComponent implements OnInit {
    @Input() deal: Deal

    @Output() onBasketClicked = new EventEmitter<Deal>();
    @Output() onEditContactsClicked = new EventEmitter<Deal>();
    @Output() onExportWardrobePlanClicked = new EventEmitter<Deal>();
    @Output() onUploadWardrobePlanClicked = new EventEmitter<Deal>();
    @Output() onMeetingPrepClicked = new EventEmitter<Deal>();
    @Output() onReselectRescheduleDateClicked = new EventEmitter<Deal>();
    @Output() onReselectInAlterationDateClicked = new EventEmitter<Deal>();
    @Output() onReselectDeliveryMeetingDateClicked = new EventEmitter<Deal>();
    @Output() onPostponeClicked = new EventEmitter<Deal>();
    @Output() onIsOrderShippedChanged = new EventEmitter<Deal>();
    @Output() onIsOpenOrderNotifiedChanged = new EventEmitter<Deal>();
    @Output() onIsDeliveryEmailSentChanged = new EventEmitter<Deal>();
    @Output() onDraftReadyChanged = new EventEmitter<Deal>();

    public ALL_STAGES = GlobalConstants.ALL_STAGES;
    public PIPELINES = stages.Pipelines;
    public DealUtil = DealUtil;

    public isAdmin = false;
    public isMasterLeadAccount = false;
    public isSystemAccount = false;
    public isAdminAccount = false;
    public isClothierAccount = false;
    public isEaAccount = false;
    public isEaCallerAccount = false;
    
    public currentUser: User;

    public loadedDealsUsersFilter: User[];

    constructor(private userContextService: UserContextService
        , private router: Router
        , private hotlistService: HotlistService
        , private dealService: DealService) {
        super();

        this.userContextService.user.pipe(take(1)).subscribe(user => {
            this.currentUser = user;
        });
    }

    async ngOnInit() {
        await this.initializeStore();
    }

    private async initializeStore() {
        this.isAdmin = this.currentUser && this.currentUser.is_admin;
        this.isMasterLeadAccount = this.currentUser && this.currentUser.role_id === RolesEnum.MasterLead;
        this.isSystemAccount = this.currentUser && this.currentUser.role_id === RolesEnum.SystemAccount;
        this.isAdminAccount = this.currentUser && this.currentUser.role_id === RolesEnum.Admin;
        this.isClothierAccount = this.currentUser && this.currentUser.role_id === RolesEnum.Clothier;
        this.isEaAccount = this.currentUser && this.currentUser.role_id === RolesEnum.EA;
        this.isEaCallerAccount = this.currentUser && this.currentUser.role_id === RolesEnum.EaCaller;
    }

    public getLabeledStageName(): string {
        const previousLabeledStage = stages.STAGES_FOR_ALL_PIPELINES.find(s => s.id === this.deal.previous_labeled_stage_id);
        return (previousLabeledStage) ? previousLabeledStage.name : "";
    }

    public getPdf() {
        this.hotlistService.getHotlistPdf(this.deal.id).then((result) => {
            if (result)
                printJS({ printable: result, type: 'pdf', base64: true })
        })
    }

    public goToLink(url: string) {
        window.open(url, "_blank");
    }

    public openDealDetails() {
        window.open(window.location.origin + "/deals/" + this.deal.id, '_blank');
    }

    public openlinkedIn() {
        window.open(this.deal.linkedin_URL, '_blank');
    }

    public openHotlist() {
        const id = this.deal.id;
        const title = this.deal.title;
        this.router.navigate(['/Hotlist', id, title]);
    }

    public async onCalledChanged() {
        this.deal.called = !this.deal.called;
        this.deal.dateOfCall = new Date();
        await this.dealService.updateCalled(this.deal.id, this.deal.called);
    }

    public async isOrderShippedChange() {
        this.onIsOrderShippedChanged.emit(this.deal);
    }

    public get isRotting(): boolean {
        if (this.checkIsBirthday != true) {
            let noRedStages = [];
            noRedStages[this.ALL_STAGES.NoPhone] = true;
            noRedStages[this.ALL_STAGES.Kill] = true;
            noRedStages[this.ALL_STAGES.NG] = true;
            noRedStages[this.ALL_STAGES.DQ] = true;
            noRedStages[this.ALL_STAGES.NoAns] = true;
            noRedStages[this.ALL_STAGES.Cancel] = true;
            noRedStages[this.ALL_STAGES.MetNG] = true;
            if (noRedStages[this.deal.stage_id]) {
                return false;
            }
            return this.deal.is_rotting;
        }
    }

    public get isAlwaysRedDeal(): boolean {
        return this.deal.stage_id == this.ALL_STAGES.OverdueLess12Weeks
            || this.deal.stage_id == this.ALL_STAGES.OverdueLess16Weeks
            || this.deal.stage_id == this.ALL_STAGES.OverdueLess24Weeks
            || this.deal.stage_id == this.ALL_STAGES.WriteOff
    }

    public get checkIsBirthday(): boolean {
        if (this.deal && this.deal.birthday != undefined && this.deal.birthday != null) {
            let birthday = new Date(this.deal.birthday);
            let currentDate = new Date();

            if (birthday.getDate() === currentDate.getDate() && birthday.getMonth() === currentDate.getMonth()) {
                if (this.isClient) {
                    return true;
                }
            }
        }
        return false;
    }

    public get isClient() {
        return this.deal.stage_id === this.ALL_STAGES.DraftOrder
            || this.deal.stage_id === this.ALL_STAGES.DraftConfirmed
            || this.deal.pipeline_id === this.PIPELINES.Clients
            || this.deal.pipeline_id === this.PIPELINES.OpenOrders
            || this.deal.pipeline_id === this.PIPELINES.ClothierContactClients;
    }

    public get isDealer(): boolean {
        return this.isClothierAccount
            || this.isAdminAccount
            || this.isMasterLeadAccount;
    }

    public get isDocumentBtnShow(): boolean {
        return this.deal.stage_id === this.ALL_STAGES.Meeting
            || this.deal.pipeline_id === this.PIPELINES.Clients
            || this.deal.pipeline_id === this.PIPELINES.OpenOrders
            || this.deal.pipeline_id === this.PIPELINES.ClothierContactClients
            || this.deal.pipeline_id === this.PIPELINES.ClothierMeetingConfirm;
    }

    public get isDealerAssistant(): boolean {
        return this.currentUser.is_admin || this.isEaAccount || this.isEaCallerAccount || this.isMasterLeadAccount;
    }

    public get isMyDealOrAdmin(): boolean {
        return this.isMasterLeadAccount || this.isAdmin;
    }

    public get isAllowedClientLifetimeSpend(): boolean {
        return this.isClient
            && (this.isMasterLeadAccount
                || this.isSystemAccount
                || this.isEaAccount
                || this.isEaCallerAccount
                || this.isAdminAccount
                || this.isClothierAccount);
    }

    public get isAllowedPostpone(): boolean {
        return (this.deal.stage_id === this.ALL_STAGES.OrderShipped || this.deal.stage_id === this.ALL_STAGES.Resched)
            && !this.isEaAccount && !this.isEaCallerAccount
            && this.deal.is_rotting;
    }
}