<div class="wrapper">
  <div class="header">
    <h2>Nearby Companies</h2>
  </div>
  <div class="container">

    <div class="row">
      <div class="col address-container">
        <div class="label">Address</div>
        <input type="text" class="input-text" [(ngModel)]="address" [formControl]="addressControl"
          [ngClass]="{'input-error': addressControl.touched && !(validation()) }">

        @if (isShowValidationMessages && !companyAddressValidation()) {
        <div class="row alert-row">
          <div class="alert alert-danger">
            {{addressValidationTooltip}}
          </div>
        </div>
        }
      </div>

      @if ((currentUser?.role_id === RolesEnum.MasterLead)) {
      <div class="col-2">
        <div class="label">Radius (meters)</div>
        <input type="number" [formControl]="radiusControl" class="input-text" [(ngModel)]="radius"
          [ngClass]="{'input-error': radiusControl.touched && !radiusControl.valid }">
      </div>
      }

      <div class="col-1 search-btn-container">
        <button class="btn btn-success waves-light search-btn" [disabled]="!(validation())"
          (click)="onSearchByAddress()">Search</button>
      </div>

      <div class="col-2 load-more-container">
        <button class="btn btn-info waves-light" (click)="onLoadMore()"
          [disabled]="!nextPageToken || spinner || !isAllowedRequest || !(validation())">Load
          More</button>
      </div>
    </div>

    @if (spinner) {
    <div class="spinner">
      <mat-spinner [diameter]="100"></mat-spinner>
    </div>
    }

    <div class="row" [ngClass]="{'visible': !spinner, 'hidden': spinner }">
      <div class="scroll-content-wrapper-container">
        <div class="content-wrapper">
          <table class="table">
            <tr>
              <th>№</th>
              <th>Icon</th>
              <th>Company name</th>
              <th>Address</th>
              <th>Geocode</th>
              <th>Types</th>
              <th>Actions</th>
            </tr>

            @for (nearbyCompany of nearbyCompanies; track nearbyCompany) {
            <tr>
              <td>{{nearbyCompany.number}}</td>
              <td><img class='image' [src]='nearbyCompany.icon'></td>
              <td>{{nearbyCompany.companyName}}</td>
              <td>{{nearbyCompany.address}}</td>
              <td>{{nearbyCompany.geocode}}</td>
              <td>{{nearbyCompany.types}}</td>
              <td>
                <mat-checkbox [checked]="checkItem(nearbyCompany)" (change)="selectItem(nearbyCompany, $event)">
                </mat-checkbox>
              </td>
            </tr>
            }
          </table>
        </div>
      </div>
    </div>

    <div class="row send-request-container">
      <button class="col-2 btn btn-info waves-light" (click)="onSendRequest()"
        [disabled]="!(nearbyCompaniesSelected && nearbyCompaniesSelected.length > 0)">Send Request</button>
    </div>
  </div>
</div>