import { ObjectUtil } from '../utils/object.util';
import { SearchModel } from './SearchModel';

export class ChipsSelectFilterModel {
	label: string = "";
	largeLabel: string = "";
	searchText: string = "";
	search = new SearchModel();
	dataArray: ChipsSelectDataItemModel[] = [];
	isSelectAll: boolean = false;
	isDeselectAll: boolean = false;
	selectText: string = "";
	isExcept: boolean = false;
}

export class ChipsSelectDataItemModel {
	id: number;
	name: string;
	
	constructor(data?: any) {
		ObjectUtil.extend(data, this);
	}
}

export class ChipsSelectFilterDataModel {
	label: string = "";
	largeLabel: string = "";
	dataArray: number[] = [];
	isSelectAll: boolean = false;
	selectText: string = "";
	isExcept: boolean = false;

	constructor(data?: any) {
		ObjectUtil.extend(data, this);
		if (data && data.dataArray) {
			this.dataArray = data.dataArray.map(m => m.id);
		}
		delete this['searchText']; //TODO: mappimng only values in model
	}
}