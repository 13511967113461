@if (fabricImages) {
<div>
    <mat-form-field style="width: 100%;">
        <mat-label>{{(isDisabled) ? "" : "Choose a fabric number"}}</mat-label>
        <mat-select (selectionChange)="setImgUrl()" [(ngModel)]="fabric" [disabled]="isDisabled">
            <mat-option>
                <ngx-mat-select-search [formControl]="fabricFilter" placeholderLabel="Search">
                </ngx-mat-select-search>
            </mat-option>
            @if ((fabric)) {
            <mat-option value="">
            </mat-option>
            }
            @for (fabric of getFilteredFabrics(); track fabric) {
            <mat-option [value]="fabric.title">
                {{fabric.title}}
            </mat-option>
            }
        </mat-select>
    </mat-form-field>
</div>
}