import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { EditInProgressComponent } from '../field-landing/edit-in-progress/edit-in-progress.component'
import { CustomSelectFilterComponent } from 'src/app/shared/custom/custom-select-filter/custom-select-filter.component';
import { DealService } from 'src/app/core/services/deals.service';
import { FilterType } from 'src/app/core/enums/FilterType.enum';

@Component({
  selector: 'pd-field-linkedin-location',
  templateUrl: './field-linkedin-location.component.html',
  styleUrls: ['./field-linkedin-location.component.scss']
})
export class FieldLinkedinLocationComponent implements OnInit {

  @Output() location: string = "Locations"
  @Output() linkedinLocationChange = new EventEmitter<string>();
  @Output() open = new EventEmitter<string>();
  public locationsLabel: string = 'Locations';
  @ViewChild("locationFilter") customSelectLocation: CustomSelectFilterComponent;
  @ViewChild("locationEdit") locationEdit: EditInProgressComponent;
  public isEdit = false;
  public isSelectedLocation = false

  constructor(private dealService: DealService) { }

  ngOnInit() {
    this.isSelectedLocation = false
  }

  /**
    * Add value
    * open edit
    * @memberof FieldLinkedinLocationComponent
    */

  public addValue() {
    this.isEdit = true;
  }

  public changeValue() {
    this.emitChangeValue(null)
  }

  public closeEdit() {
    this.isEdit = false
  }

  onChanged(isSelectedItem: boolean) {
    this.isSelectedLocation = isSelectedItem
    this.emitChangeValue(null)
  }

  emitChangeValue(location: string) {
    if (location === null) {
      if (this.customSelectLocation.localChecked.length > 0) {
        this.linkedinLocationChange.emit(this.customSelectLocation.localChecked[0])
      } else {
        this.linkedinLocationChange.emit('')
      }
    } else {
      this.linkedinLocationChange.emit(location)
    }
  }

  saveLocation(location: string) {
    this.location = location
    this.emitChangeValue(location)
    this.locationEdit.isComplete = true
  }

  cancelNewLocation() {
    this.isEdit = false
  }

  editValue() {
    this.locationEdit.isComplete = false
  }

  getLocationsFilterData(event) {
    let isNotleter: number = event.isNotLetter;
    this.dealService.getFilterData(FilterType.Location, event.skip, event.take).then(response => {
      if (isNotleter == this.customSelectLocation.isNotLetter) {
        this.customSelectLocation.setData(response);
      }
    });
  }

  getLocationsAutocomplete(event) {
    let isNotleter: number = event.isNotLetter;
    this.dealService.getFilterDataAutocomplete(FilterType.Location, event.searchString, event.skip, event.take).then(response => {
      if (isNotleter == this.customSelectLocation.isNotLetter) {
        this.customSelectLocation.setData(response);
      }
    });
  }

  public onAnotherSelectOpen() {
    if (this.customSelectLocation) {
      this.customSelectLocation.isOpenSelect = false;
    }
  }

  onSelectOpen() {
    this.open.emit();
  }

  // openSelectFilter(){
  //   this.customSelectLocation.isOpenSelect=false;
  // }

  /**
   * Select option
   * on custom select
   * @param {string} location selected location
   * @memberof FieldLinkedinLocationComponent
   */

  // public selectOption(location) {
  //   this.location = location;
  //   this.linkedinLocationChange.emit(this.location);
  // }
}
