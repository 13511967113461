export class AchievementCardConfig {
  public achievementGroups: Array<AchievementGroup>;
  public achievementCardTypes: Array<AchievementCardType>;
}

export class AchievementGroup {
  public id: number;
  public name: string;
}

export class AchievementCardType {
  public id: number;
  public name: string;
  public achievementGroupId: number;
  public imageUrl: string;
  public order: number;
}