import { UserContextService } from './../../../core/services/user-context.service';
import { GlobalConstants } from './../../../core/global-constants';
import { Component, Output, EventEmitter, ViewChild } from '@angular/core';
import * as stages from '../../../../assets/stages_new_prospespects';
import { CustomReferralModalComponent } from 'src/app/shared/custom/custom-referral-modal/custom-referral-modal.component';
import { CustomMeetingDateModalComponent } from 'src/app/shared/custom/custom-meeting-date/custom-meeting-date.component';
import { RolesEnum } from 'src/app/core/enums/RolesEnum';
import { UtilsHelper } from 'src/app/core/utils/utils.helper';
import { User } from 'src/app/core/models/UserModel';

@Component({
  selector: 'pd-field-stage-id',
  templateUrl: './field-stage-id.component.html',
  styleUrls: ['./field-stage-id.component.scss']
})
export class FieldStageIdComponent {
  public newProspectsStageName: string;
  public pipelines = [];
  public currentStageId: number;
  @Output() stagesChange = new EventEmitter<Object>();
  private whoReferredText: string = null;
  public currentUser: any;
  public filterRole: any;

  @ViewChild('referralModal') referralModal: CustomReferralModalComponent;
  @ViewChild('meetingDateModal') meetingDateModal: CustomMeetingDateModalComponent;

  constructor(private userContextService: UserContextService) {
    this.newProspectsStageName = stages.NewProspectsPipeline.name;
    this.currentUser = this.userContextService.user.value;
    this.filterRole = this.currentUser.role_id;
    this.filterPipelinesByUser(this.currentUser);
  }

  filterPipelinesByUser(currentUser: User) {
    if (currentUser && currentUser.role_id) {
      this.filterRole = RolesEnum[currentUser.role_id];
      const prospects = UtilsHelper.deepClone(stages.STAGES_NEW_PROSPECTS_DICT);

      for (let index = prospects.length - 1; index >= 0; index--) {
        const element = prospects[index];
        if (element.id === GlobalConstants.ALL_STAGES.ClientSaved || element.id === GlobalConstants.ALL_STAGES.Meeting) {
          prospects.splice(index, 1);
        }
        if ((this.currentUser.role_id !== RolesEnum.MasterLead
          && this.currentUser.role_id !== RolesEnum.Admin
          && this.currentUser.role_id !== RolesEnum.Clothier) && element.id === GlobalConstants.ALL_STAGES.Float) {
          prospects.splice(index, 1);
        }

        if (this.currentUser.role_id === RolesEnum.Clothier && (element.id === GlobalConstants.ALL_STAGES.RefVM1 || element.id === GlobalConstants.ALL_STAGES.RefVM2 || element.id === GlobalConstants.ALL_STAGES.RefVM3)) {
          prospects.splice(index, 1);
        }

        if (element.id === GlobalConstants.ALL_STAGES.SM) {
          prospects.splice(index, 1);
        }

        if (element.id === GlobalConstants.ALL_STAGES.SM1) {
          prospects.splice(index, 1);
        }

      }
      this.pipelines = [
        {
          name: stages.NewProspectsPipeline.name,
          stages: prospects
        }
      ];
      this.pipelines.forEach(pipeline => {
        const allowedStages = stages.PROSPECTS_BY_ROLES[this.filterRole];
        pipeline.stages = pipeline.stages.filter(pr => allowedStages.some(stageToAccess => stageToAccess === pr.name));
      });
      this.emitChanges();
    }
  }

  private emitChanges() {
    this.stagesChange.emit({ id: this.currentStageId, personWhoReferred: this.whoReferredText });
  }

  /**
    * Change value
    * @param {any} value value of stage
    * @memberof FieldStageIdComponent
    */

  public changeValue(event: any) {
    let value = event.value;
    if (value === GlobalConstants.ALL_STAGES.Referral) {
      this.referralModal.modal.open();
    } else {
      this.emitChanges();
    }
  }

  public saveWhoReferred(whoReferredText) {
    this.whoReferredText = whoReferredText;
    this.emitChanges();
  }

  public closeReferralModal() {
    this.currentStageId = GlobalConstants.ALL_STAGES.NoPhone;
    this.emitChanges();
  }
}
