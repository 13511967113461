import { Component, OnInit, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs';
import { BaseComponent } from 'src/app/core/base.component';
import { AchievementCardTypes } from 'src/app/core/enums/AchievementCardTypes';
import { AchievementGroups } from 'src/app/core/enums/AchievementGroups';
import { RolesEnum } from 'src/app/core/enums/RolesEnum';
import { AchievementCardType, AchievementGroup } from 'src/app/core/models/AchievementCardConfig';
import { AchievementCardModel } from 'src/app/core/models/AchievementCardModel';
import { User } from 'src/app/core/models/UserModel';
import { AchievementCardFacadeService } from 'src/app/core/services/achievement-card/achievement-card-facade.service';
import { UserContextService } from 'src/app/core/services/user-context.service';
import { CustomAchievementCardModalComponent } from 'src/app/shared/custom/custom-achievement-card-modal/custom-achievement-card-modal.component';

@Component({
  selector: 'pd-achievement-card-container',
  templateUrl: './achievement-card-container.component.html',
  styleUrls: ['./achievement-card-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class AchievementCardContainerComponent extends BaseComponent implements OnInit {
  @Input() ownerId: number;
  @Input() achievementGroup: AchievementGroup;
  @Input() achievementCardTypes: Array<AchievementCardType>;
  @Input() isAchievementHistory: boolean = false;
  @Input() isAchievementGroup: boolean = false;
  @Input() isSplitByTypes: boolean = false;
  @Input() achievementCardType: AchievementCardType;

  achievementCards: Array<AchievementCardModel>;
  isUserAllowAddEdit: boolean;
  isCardAllowAddEdit: boolean;
  isNeedLoad: boolean;

  currentUser: User;

  sortArray: Array<any> = new Array<any>();
  currentSort: any = {};

  constructor(
    private userContextService: UserContextService
    , private dialog: MatDialog
    , private cdRef: ChangeDetectorRef
    , private achievementCardFacadeService: AchievementCardFacadeService) {
    super();

    this.sortArray.push({ id: 0, name: "by item" });
    this.sortArray.push({ id: 1, name: "the newest" });
    this.sortArray.push({ id: 2, name: "the oldest" });
    this.currentSort = this.sortArray[1];
  }

  ngOnInit() {
    this.initializeStore();

    this.isUserAllowAddEdit = (this.currentUser.role_id === RolesEnum.EA
      || this.currentUser.role_id === RolesEnum.EaCaller
      || this.currentUser.role_id === RolesEnum.Admin
      || this.currentUser.role_id === RolesEnum.MasterLead)

    this.isCardAllowAddEdit = !(
      this.achievementGroup.id == AchievementGroups.AnnualSalesAchievementPins
      || (this.achievementGroup.id == AchievementGroups.MonthlySalesAchievementPins
        && (!(this.isSplitByTypes && this.achievementCardType.id == AchievementCardTypes.MonthlySalesHallOfFame)))
    )

    this.achievementCardFacadeService.getAchievementCards()
      .pipe(takeUntil(this.destroy$))
      .subscribe((achievementCards: AchievementCardModel[]) => {
        if (this.isAchievementGroup) {
          this.achievementCards = achievementCards;
        }
        else if (this.isSplitByTypes) {
          this.achievementCards = achievementCards.filter(f => f.achievementCardTypeId === this.achievementCardType.id);
        }
        else {
          this.achievementCards = achievementCards.filter(c => c.achievementGroupId === this.achievementGroup.id);
        }

        this.sortCard();
        this.cdRef.detectChanges();
      });
  }

  ngOnDestroy() {
    this.cdRef.detach();
    super.ngOnDestroy();
  }

  private initializeStore() {
    this.currentUser = this.userContextService.user.value;
  }

  //////////////////////////////// To Components Input START //////////////////////////////

  public async saveCard(model) {
    if (model.isGroupped) {
      await this.achievementCardFacadeService.updateAchievementCardGroup(model.card);
    }
    else {
      await this.achievementCardFacadeService.updateAchievementCard(model.card);
    }

    this.cdRef.detectChanges();
  }

  async deleteCard(model) {
    if (model.isGroupped) {
      await this.achievementCardFacadeService.deleteAchievementCardGroup(model.card.id);
    }
    else {
      await this.achievementCardFacadeService.deleteAchievementCard(model.card.id);
    }

    this.cdRef.detectChanges();
  }

  //////////////////////////////// To Components Input END //////////////////////////////

  //////////////////////////////// Events START //////////////////////////////

  public onTileClick(card: AchievementCardModel) {
    if (this.isAchievementHistory || !this.isUserAllowAddEdit || !this.isCardAllowAddEdit) {
      return;
    }

    if (!card) {
      card = new AchievementCardModel(0, this.ownerId, this.achievementGroup.id);
    }

    this.dialog.open(CustomAchievementCardModalComponent, {
      disableClose: true,
      data: {
        card: card
        , ownerId: this.ownerId
        , achievementCardTypes: this.achievementCardTypes
        , achievementGroup: this.achievementGroup
        , saveAchievementCard: this.saveCard.bind(this)
        , deleteAchievementCard: this.deleteCard.bind(this)
      }
    });
  }

  public onOpened() {
    this.isNeedLoad = true;
  }

  public sortSelectionChange(event) {
    this.currentSort = this.sortArray[event.value];
    this.sortCard();
  }

  public getCardCountByType(achievementTypeId: number) {
    if (this.achievementCards) {
      const cards = this.achievementCards.filter(c => c.achievementCardTypeId === achievementTypeId);
      if (cards) {
        return cards.length;
      }
    }
    return 0;
  }
  //////////////////////////////// Events END //////////////////////////////

  //////////////////////////////// Private START //////////////////////////////

  public get isNeedShowEmptyCard(): boolean {
    return !this.isAchievementHistory && this.isUserAllowAddEdit && this.isCardAllowAddEdit;
  }

  private sortCard() {
    if (this.currentSort.id === 0) {
      this.achievementCards = this.achievementCards.sort((a, b) => this.cardsCompare(a, b));
    }
    else {
      if (this.currentSort.id === 1) {
        this.achievementCards = this.achievementCards.sort((a, b) => this.cardsCompare(b, a));
      } else if (this.currentSort.id === 2) {
        this.achievementCards = this.achievementCards.sort((a, b) => -this.cardsCompare(b, a));
      }
    }

    this.cdRef.detectChanges();
  }

  private cardsCompare(first: AchievementCardModel, next: AchievementCardModel) {
    let firstDate = new Date(first.date);
    let nextDate = new Date(next.date);

    if (this.currentSort.id > -1) {
      if (nextDate.getTime() > firstDate.getTime()) {
        return -1;
      } else if (nextDate.getTime() < firstDate.getTime()) {
        return 1;
      }
    }
    else {
      return -1;
    }
    return this.compare(first.id, next.id);
  }

  //////////////////////////////// Private END //////////////////////////////

  //////////////////////////////// Helpers START ////////////////////////////////

  public getLink(link) {
    if (link.indexOf("http://") == -1 && link.indexOf("https://") == -1) {
      return "http://" + link;
    }
    return link;
  }

  public shortString(str: string): string {
    if (str) {
      return str.slice(0, 20) + ((str.length > 20) ? '...' : '');
    }
  }

  public findImgUrl(card: AchievementCardModel) {
    if (card.customImageUrl) {
      return card.customImageUrl;
    }
    else {
      if (this.achievementCardTypes) {
        const achievementCardType = this.achievementCardTypes.find(a => a.id == card.achievementCardTypeId);
        if (achievementCardType) {
          return achievementCardType.imageUrl;
        }
      }
    }
  }

  private compare(first: number, next: number) {
    if (next > first) {
      return -1;
    }
    if (next < first) {
      return 1;
    }
    return 0;
  }

  //////////////////////////////// Helpers END ////////////////////////////////

  //////////////////////////////// View *ngIf START //////////////////////////////

  isFilledCard(card: AchievementCardModel) {
    return card.id && card.id > -1;
  }

  isShowDateField(card: AchievementCardModel): boolean {
    return !(card.achievementGroupId == AchievementGroups.AnnualSalesAchievementPins
      && card.achievementCardTypeId == AchievementCardTypes.HallOfFameClub);
  }

  isShowAmountField(card: AchievementCardModel): boolean {
    return card.achievementGroupId == AchievementGroups.BigSalePins
      || card.achievementGroupId == AchievementGroups.RingOfHonourPins
      || card.achievementGroupId == AchievementGroups.GoldSalePins
      || card.achievementGroupId == AchievementGroups.DiamondSalePins;
  }

  isShowSingleRowAmountField(card: AchievementCardModel): boolean {
    return card.achievementGroupId == AchievementGroups.AnnualSalesAchievementPins
      || (card.achievementGroupId == AchievementGroups.MonthlySalesAchievementPins && card.achievementCardTypeId != AchievementCardTypes.MonthlySalesHallOfFame);
  }

  isShowAnnualDateField(card: AchievementCardModel): boolean {
    return card.achievementGroupId == AchievementGroups.AnnualSalesAchievementPins
      || card.achievementGroupId == AchievementGroups.LeadershipAnnualAchievementPins;
  }

  isShowMonthlyDateField(card: AchievementCardModel): boolean {
    return card.achievementGroupId == AchievementGroups.MonthlySalesAchievementPins
      || card.achievementGroupId == AchievementGroups.LeadershipMonthlyAchievementPins
      || card.achievementGroupId == AchievementGroups.ServiceAwards;
  }

  isShowDefaultDateField(card: AchievementCardModel): boolean {
    return !(this.isShowAnnualDateField(card) || this.isShowMonthlyDateField(card));
  }

  isShowAchievementGroup(card) {
    return card.achievementGroupId == AchievementGroups.AnnualSalesAchievementPins
      || card.achievementGroupId == AchievementGroups.LeadershipAnnualAchievementPins
      || card.achievementGroupId == AchievementGroups.MonthlySalesAchievementPins
      || card.achievementGroupId == AchievementGroups.LeadershipMonthlyAchievementPins
  }

  isShowCustomTitle(card) {
    return card.achievementGroupId == AchievementGroups.ServiceAwards
      || card.achievementGroupId == AchievementGroups.CompanyTripsWon
      || card.achievementGroupId == AchievementGroups.CompanyMeetingsAttended
  }

  //////////////////////////////// View *ngIf END //////////////////////////////
}