@if (isSplitByTypes) {
<mat-expansion-panel (opened)="onOpened()">
  <mat-expansion-panel-header>
    @if (achievementCardType) {
    <mat-panel-title>
      <span class="achievement-panel-title">
        {{achievementCardType.name}}
        @if (getCardCountByType(achievementCardType.id) > 0) {
        <span>&nbsp;({{getCardCountByType(achievementCardType.id)}})</span>
        }
      </span>
    </mat-panel-title>
    }
  </mat-expansion-panel-header>
  <mat-panel-description>
    @if (isNeedLoad) {

    <ng-container *ngTemplateOutlet="contentWrapperTemplate"></ng-container>

    }
  </mat-panel-description>
</mat-expansion-panel>
}

@if (!isSplitByTypes) {

<ng-container *ngTemplateOutlet="contentWrapperTemplate"></ng-container>

}

<ng-template #contentWrapperTemplate>
  <div style="width: 100%;">
    <div class="tool">
      <div class="sort-label">
        Sort
      </div>
      <div class="sort-selector">
        <mat-form-field class="global-custom-select">
          <mat-select [value]="currentSort.id" (selectionChange)="sortSelectionChange($event)">
            @for (item of sortArray; track item) {
            <mat-option [value]="item.id">
              {{item.name}}
            </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="tile-container">

      @if (isNeedShowEmptyCard) {

      <div class="tile">
        <div [ngClass]="{'allow-add-edit': isUserAllowAddEdit && isCardAllowAddEdit }"
          (click)="onTileClick(); $event.stopPropagation()">
          <div class="tile-title">
            <span>Add New Achievement</span>
          </div>
          <div>
            <div value="Add" class="add-tile"></div>
          </div>
        </div>
      </div>

      }

      @for (card of achievementCards; track card) {
      <div [ngClass]="{'allow-add-edit': isUserAllowAddEdit && isCardAllowAddEdit }"
        (click)="onTileClick(card); $event.stopPropagation()">
        <div class="tile">
          <div class="tile-title">
            @if (!(isShowCustomTitle(card))) {
            <span>
              {{card.achievementCardTypeName}}</span>
            }
            @if (isShowCustomTitle(card)) {
            <span>
              {{shortString(card.customTitle)}}</span>
            }
          </div>
          <div class="tile-body">
            @if (card) {
            <div>

              <div class="relative">
                <div class="image">
                  <img [src]="findImgUrl(card)" onerror="this.onerror=null; this.src='../../assets/lgfg-logo.png'" />
                </div>
              </div>

              @if (isAchievementHistory && (isShowAchievementGroup(card))) {
              <div class="single-row-group-information">
                {{card.achievementGroupName}}</div>
              }

              @if (isShowDateField(card)) {
              <div>
                @if (isShowAnnualDateField(card)) {
                <div class="single-row">
                  <div class="monthly-date">{{card.date | date :'yyyy'}}</div>
                  @if (isShowSingleRowAmountField(card)) {
                  <div>${{card.amount | money}} CAD</div>
                  }
                </div>
                }

                @if (isShowMonthlyDateField(card)) {
                <div class="single-row">
                  <div class="monthly-date">{{card.date | date :'MMMM yyyy'}}</div>
                  @if (isShowSingleRowAmountField(card)) {
                  <div>${{card.amount | money}} CAD</div>
                  }
                </div>
                }

                @if (isShowDefaultDateField(card)) {
                <div class="row">
                  <div class="tile-info-label text">
                    <span>Date:</span>
                  </div>
                  <div class="col tile-info-value">
                    <div class="text">
                      <span>
                        {{card.date | date :'dd-MMM-yyyy'}}</span>
                    </div>
                  </div>
                </div>
                }
              </div>
              }

              @if (card.clientName) {
              <div class="row">
                <div class="tile-info-label text">
                  <span>Client:</span>
                </div>
                <div class="col tile-info-value">
                  <div class="text">
                    <span>{{shortString(card.clientName)}}</span>
                  </div>
                </div>
              </div>
              }

              @if (isShowAmountField(card)) {
              <div class="row">
                <div class="tile-info-label text">
                  <span>Amount:</span>
                </div>
                <div class="col tile-info-value">
                  <div class="text">
                    <span>${{card.amount | money}} CAD</span>
                  </div>
                </div>
              </div>
              }

              @if (card.link) {
              <div class="row">
                <div class="tile-info-label text">
                  <span>Details:</span>
                </div>
                <div class="col tile-info-value">
                  <div class="text">
                    <a [href]="getLink(card.link)" (click)="$event.stopPropagation()">Link</a>
                  </div>
                </div>
              </div>
              }

            </div>
            }
          </div>
        </div>
      </div>
      }
    </div>
  </div>
</ng-template>