import { Injectable } from '@angular/core';
import { FileUploaderOptions } from 'ng2-file-upload';
import { BaseService } from './base.service';
import { environment } from 'src/environments/environment';
import { HttpRestService } from './http-client/http-rest-client.service';
import { GlobalConstants } from '../global-constants';
import { UserContextService } from './user-context.service';

@Injectable()
export class UploadImageService extends BaseService {
    public static allowedImageTypes = ['image/png', 'image/jpg', 'image/jpeg', 'image/gif']

    private url = `${environment.apiUrl}/image/uploadDealImage`;

    constructor(private restService: HttpRestService, private userContextService: UserContextService) {
        super();
    }

    public uploaderOptions: FileUploaderOptions = {
        url: this.url,
        allowedMimeType: UploadImageService.allowedImageTypes,
        autoUpload: false,
        removeAfterUpload: true,
        headers: [
            {
                name: 'Authorization',
                value: `Bearer ${localStorage.getItem(GlobalConstants.jwtSessionKey)}`
            },
            {
                name: 'CurrentUserId',
                value: this.userContextService.user.value.id.toString()
            }
        ]
    };

    public async uploadAvatarHeadImage(dealId: number, base64: string): Promise<any> {
        const url = `/image/uploadAvatarHeadImage`;
        const data = {
            dealId: dealId,
            base64: base64
        }
        return await this.handleResponse(this.restService.post({ url, data }));
    }
}
