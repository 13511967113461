import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef, QueryList, ViewChildren } from '@angular/core';
import { AvatarGrouppedExpansionPanelComponent } from '../avatar-groupped-expansion-panel/avatar-groupped-expansion-panel.component';
import { Avatar } from 'src/app/core/models/avatar-models/Avatar';
import { BaseComponent } from 'src/app/core/base.component';
import { ProductType } from 'src/app/core/models/ClientCardConfig';
import { ClientCard } from 'src/app/core/models/ClientCard';
import { AvatarSortingTypes } from 'src/app/core/enums/avatar/AvatarSortingTypes';
import { DealUtil } from 'src/app/core/utils/deal.util';
import { AvatarDealFacadeService } from 'src/app/core/services/avatar-deal/avatar-deal-facade.service';
import { ClientCardFacadeService } from 'src/app/core/services/client-card/client-card-facade.service';
import { take, takeUntil } from 'rxjs/operators';
import { AvatarUtil } from 'src/app/core/utils/avatar.util';
import { EnumUtil } from 'src/app/core/utils/enum.util';

@Component({
  selector: 'pd-avatar-expansion-panel',
  templateUrl: './avatar-expansion-panel.component.html',
  styleUrls: ['./avatar-expansion-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class AvatarExpansionPanelComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChildren('groups') groups: QueryList<AvatarGrouppedExpansionPanelComponent>;

  @Input() public avatars: Array<Avatar>;
  @Input() public productType: ProductType;
  @Input() public isExportAction: boolean;
  @Input() public isHidden: boolean;
  @Input() public isGlobalCollection: boolean;
  @Input() public isStylesAvailable: boolean;
  @Input() public isCustomAvatars: boolean;
  @Input() public isLoadAllOnStart: boolean;
  @Input() public dealId: number;
  @Input() public dealTitle: string;
  @Input() public isAllowAddEditAvatars: boolean = false;
  @Input() public isMasterLeadAccount: boolean = false;
  @Input() public isAllowAddEditCustomAvatars: boolean = false;
  @Input() public isGroupedView: boolean = false;

  @Output() public onClickAvatar = new EventEmitter<any>();
  @Output() public onAddAvatar = new EventEmitter<any>();
  @Output() public onDeleteAvatar = new EventEmitter<any>();
  @Output() public onGenerateDealAvatars = new EventEmitter<any>();
  @Output() public onDownloadAvatar = new EventEmitter<any>();
  @Output() public onDownloadAllAvatars = new EventEmitter();
  @Output() public onAddAvatarToCollection = new EventEmitter<any>();

  public isHasAvatarInProductTypeToProcessing: boolean;
  public isLoadingInProgress: boolean;
  public clientCards: ClientCard[];
  public AvatarSortingTypes = AvatarSortingTypes;
  public DealUtil = DealUtil;

  public isNeedLoadAvatars: boolean = false;
  public isLoadAll: boolean = false;

  private deleteAvatarMessage = "Do you confirm deletion of this avatar?"

  constructor(
    private cdRef: ChangeDetectorRef,
    private avatarDealFacadeService: AvatarDealFacadeService,
    private clientCardFacadeService: ClientCardFacadeService,
  ) {
    super();
  }

  ngOnInit() {
    this.clientCardFacadeService.getClientCards()
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (clientCards: ClientCard[]) => {
        this.clientCards = clientCards;
        this.isHasAvatarInProductTypeToProcessing = clientCards
          && clientCards.some(c => AvatarUtil.productTypeToAvatarProcessing.some(p => p == c.productTypeId));
        this.cdRef.detectChanges();
      });

    this.avatarDealFacadeService.getAvatars()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.cdRef.detectChanges();
      });

    this.avatarDealFacadeService.getSeasonalProposalAvatars()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.cdRef.detectChanges();
      });

    this.avatarDealFacadeService.getStylesAvailableAvatars()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.cdRef.detectChanges();
      });

    this.avatarDealFacadeService.getCustomAvatars()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.cdRef.detectChanges();
      });
  }

  public ngOnDestroy() {
    this.cdRef.detach();
    super.ngOnDestroy();
  }

  clickAvatar(event) {
    this.onClickAvatar.emit(event);
  }

  addCustomAvatar(event: any) {
    event.stopPropagation();
    this.onAddAvatar.emit()
  }

  deleteAvatar(event) {
    if (confirm(this.deleteAvatarMessage)) {
      this.onDeleteAvatar.emit(event);
    }
  }

  public generateDealAvatars(event) {
    event.stopPropagation();
    this.onGenerateDealAvatars.emit(event);
  }

  public downloadAvatar(event: any) {
    this.onDownloadAvatar.emit(event);
  }

  public downloadAll(event: any) {
    event.stopPropagation();
    this.onDownloadAllAvatars.emit();
  }

  public addAvatarToCollection(event: any) {
    this.onAddAvatarToCollection.emit(event);
  }

  public getAvatarCount() {
    if (this.avatars) {
      return this.avatars.length;
    }
    return 0;
  }

  public getAvatarByGroup(avatarSortingType: AvatarSortingTypes) {
    const avatarSortingTypeValue = EnumUtil.getEnumValue(AvatarSortingTypes, avatarSortingType.toString());
    if (this.avatars && this.avatars.length > 0) {
      return this.avatars.filter(a => a.avatarSortingType == avatarSortingTypeValue);
    }
    return [];
  }

  public async onOpened() {
    if (this.isLoadAllOnStart) {
      this.isLoadAll = true;
    }
    this.isNeedLoadAvatars = true;
    await this.loadAvatarsBase64IfNeed();
  }

  public async onChangeGroupedView() {
    this.isNeedLoadAvatars = true;
    this.cdRef.detectChanges();
    await this.loadAvatarsBase64IfNeed();
  }

  public async onLoadAllAvatars(event: any) {
    event.stopPropagation();
    await this.loadAllAvatars();
  }

  public async loadAllAvatars() {
    this.isLoadAll = true;
    this.isLoadingInProgress = true;

    const groupArr = this.groups.toArray()
    for (const group of groupArr) {
      await group.loadAllAvatars();
    }

    this.isLoadingInProgress = false;
    this.cdRef.detectChanges();
  }

  public async loadAvatarsBase64IfNeed() {
    const groupArr = this.groups.toArray()
    for (const group of groupArr) {
      if (this.isLoadAllOnStart) {
        group.isLoadAll = true;
      }
      await group.loadAvatarsBase64IfNeed();
    }

    this.cdRef.detectChanges();
  }

  //////////////////////////////// Events END //////////////////////////////

  //////////////////////////////// Private START //////////////////////////////

  //////////////////////////////// Private END //////////////////////////////

  //////////////////////////////// Helpers START ////////////////////////////////

  public get getProductLabel(): string {
    if (this.isCustomAvatars || this.isStylesAvailable) {
      return this.productType.name;
    }
    return DealUtil.nameToPossessiveForm(this.dealTitle) + " " + this.productType.name
  }

  public get isShowLoadAll(): boolean {
    return !this.isLoadAllOnStart && ((this.getAvatarCount() > 0 && !this.isLoadAll && !this.isExportAction) || this.isLoadingInProgress);
  }

  public get isAddAvatar(): boolean {
    return !this.isExportAction && this.isCustomAvatars;
  }

  //////////////////////////////// Helpers END ////////////////////////////////

  //////////////////////////////// View *ngIf START //////////////////////////////

  //////////////////////////////// View *ngIf END //////////////////////////////
}
