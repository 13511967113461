<div class="custom">
  <div class="custom-header" (click)="openSelect();$event.stopPropagation()" (document:click)="closeCustomSelect()">
    <span class="custom-input" bootstrapTooltip="{{dataLabel}}" placement="bottom">{{label}}</span>
    <span>
      <i class="material-icons" style="color:rgba(0,0,0,.54);">
        arrow_drop_down
      </i>
    </span>
  </div>
  <div class="custom-body" [ngClass]="{'visible-select': isOpenSelect, 'hidden-select': !isOpenSelect }">
    <div class="custom-search">
      <input type="text" placeholder="Search" (keyup)="onSearch()" (click)="$event.stopPropagation()"
        [(ngModel)]="searchText">
    </div>
    <div class="group-options" (scroll)="doLazyLoading($event)" #selectContainer>
      <div class="list-options" [ngStyle]="{'max-height.px': !isMultiple ? 280 : 'auto'}">
        <div [hidden]="!isShowSelectAll || !isMultiple" class="select-all">
          <div [hidden]="!isMultiple">
            <span #selectAll class="button" (click)="selectAllData();$event.stopPropagation()">Select All</span>
            <span #deselectAll class="button" (click)="deselectAllData();$event.stopPropagation()">Deselect All</span>
            @if (isShowExcept) {
            <mat-checkbox [hidden]="true" class="exceptTickmark" [(ngModel)]="isExcept" (change)="except()">Except
            </mat-checkbox>
            }
          </div>
        </div>

        @for (loc of filteredData; track loc; let i = $index) {
        <div class="custom-option">
          <div class="option">
            <mat-checkbox [checked]="checkItem(loc.id)" (change)="selectItem(loc, $event)">
              {{loc.name}}</mat-checkbox>
          </div>
        </div>
        }
        @if (selectLazyLoading) {
        <div class="select-lazy-loading">
          <div class="text-lazy">Loading more {{dataLabel}}...</div>
        </div>
        }
        @if (isShowLoading) {
        <div class="select-lazy-loading">
          <div class="text-lazy">Loading {{dataLabel}}...</div>
        </div>
        }
      </div>
    </div>
  </div>
</div>