import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { AchievementCardStoreService } from "./achievement-card-store.service";
import { AchievementCardApiService } from "./achievement-card-api.service";
import { AchievementCardModel } from "../../models/AchievementCardModel";

@Injectable()
export class AchievementCardEffectsService {

    constructor(
        private achievementCardStoreService: AchievementCardStoreService
        , private achievementCardApiService: AchievementCardApiService
    ) {
    }

    public loadAchievementCardsByOwnerId(ownerId: number): Observable<AchievementCardModel[]> {
        return this.achievementCardApiService.getAchievementCardsByOwnerId(ownerId).pipe(
            tap((achievementCards: AchievementCardModel[]) => {
                const dataLog = {
                    "loadAchievementCardsByOwnerId": achievementCards,
                };

                console.log(JSON.stringify(dataLog));
                this.achievementCardStoreService.setAchievementCards(achievementCards);
            }));
    }

    public updateAchievementCard(achievementCard: AchievementCardModel): Observable<AchievementCardModel> { //TODO: make create method
        return this.achievementCardApiService.updateAchievementCard(achievementCard).pipe(
            tap(async (updatedAchievementCard: AchievementCardModel) => {
                console.log(JSON.stringify({ methodName: "updateClientCard", achievementCard, updatedAchievementCard }));
                if (updatedAchievementCard) {

                    const achievementCards = this.achievementCardStoreService.getAllAchievementCardsSnapshot();
                    const resultAchievementCards = achievementCards.filter(a => a.id != updatedAchievementCard.id);
                    resultAchievementCards.push(updatedAchievementCard);

                    const dataLog = {
                        "updatedAchievementCard": updatedAchievementCard,
                        "achievementCards": achievementCards,
                        "resultAchievementCards": resultAchievementCards,
                    };

                    console.log(JSON.stringify(dataLog));
                    this.achievementCardStoreService.setAchievementCards(resultAchievementCards);
                }
            })
        )
    }

    public updateAchievementCardGroup(achievementCard: AchievementCardModel): Observable<AchievementCardModel> { //TODO: make updating cards group as flag
        return this.achievementCardApiService.updateAchievementCardGroup(achievementCard).pipe(
            tap(async (updatedAchievementCard: AchievementCardModel) => {
                console.log(JSON.stringify({ methodName: "updateClientCard", achievementCard, updatedAchievementCard }));
                if (updatedAchievementCard) {

                    const achievementCards = this.achievementCardStoreService.getAllAchievementCardsSnapshot();
                    const resultAchievementCards = achievementCards.filter(a => a.id != updatedAchievementCard.id);
                    resultAchievementCards.push(updatedAchievementCard);

                    const dataLog = {
                        "updatedAchievementCard": updatedAchievementCard,
                        "achievementCards": achievementCards,
                        "resultAchievementCards": resultAchievementCards,
                    };

                    console.log(JSON.stringify(dataLog));
                    this.achievementCardStoreService.setAchievementCards(resultAchievementCards);
                }
            })
        )
    }

    public deleteAchievementCard(achievementCardId: number): Observable<boolean> {
        return this.achievementCardApiService.deleteAchievementCard(achievementCardId).pipe(
            tap((isDeleted: boolean) => {
                if (isDeleted) {
                    const achievementCards = this.achievementCardStoreService.getAllAchievementCardsSnapshot();
                    const resultAchievementCards = achievementCards.filter(a => a.id != achievementCardId);

                    const dataLog = {
                        "deleteClientCard": isDeleted,
                        "achievementCards": achievementCards,
                        "resultAchievementCards": resultAchievementCards,
                    };

                    console.log(JSON.stringify(dataLog));
                    this.achievementCardStoreService.setAchievementCards(resultAchievementCards);
                }
            })
        );
    }

    public deleteAchievementCardGroup(achievementCardId: number): Observable<boolean> { //TODO: make deleting cards group as flag
        return this.achievementCardApiService.deleteAchievementCardGroup(achievementCardId).pipe(
            tap((isDeleted: boolean) => {
                if (isDeleted) {
                    const achievementCards = this.achievementCardStoreService.getAllAchievementCardsSnapshot();
                    const resultAchievementCards = achievementCards.filter(a => a.id != achievementCardId);

                    const dataLog = {
                        "deleteClientCard": isDeleted,
                        "achievementCards": achievementCards,
                        "resultAchievementCards": resultAchievementCards,
                    };

                    console.log(JSON.stringify(dataLog));
                    this.achievementCardStoreService.setAchievementCards(resultAchievementCards);
                }
            })
        );
    }
}
