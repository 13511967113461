import { UserContextService } from './user-context.service';
import { User } from '../models/UserModel';
import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";
import { HttpRestService } from "./http-client/http-rest-client.service";
import { Credentials } from '../models/login/credentials.model';
import { LoginResult } from '../models/login/login-result.model';
import { GlobalConstants } from '../global-constants';
import { AppRouterService } from './app-router.service';

@Injectable()
export class LoginService extends BaseService {

    constructor(
        private restService: HttpRestService,
        private userContextService: UserContextService,
        private router: AppRouterService) {
        super();
    }

    public async isAuth(): Promise<boolean> {
        const data = await this.handleResponse(this.restService.get({
            url: '/account/isAuth',
        }));

        return data;
    }

    public async refreshTokenImmediately(): Promise<void> {
        const data = await this.handleResponse(this.restService.post({
            url: '/account/refresh',
            data: { refreshToken: localStorage.getItem(GlobalConstants.refreshTokenKey) }
        }));
        localStorage.setItem(GlobalConstants.jwtSessionKey, data);
    }

    public async login(cred: Credentials): Promise<LoginResult> {
        const data = await this.handleResponse(this.restService.post({
            url: '/account/login',
            data: cred
        }));

        return data;
    }

    public async getContext(): Promise<User> {
        const data = await this.handleResponse(this.restService.get({
            url: `/user/getUserData/`
        }));

        return new User(data);
    }

    public async setUserContext(): Promise<void> {
        var context = localStorage.getItem(GlobalConstants.userContextKey);
        if (context) {
            this.setContext(JSON.parse(context));
            return;
        }

        const user = new User(await this.getContext());

        if (user.id) {
            this.setContext(user);
            localStorage.setItem(GlobalConstants.userContextKey, JSON.stringify(user))
            return;
        }

        this.userContextService.resetContext();

        if (!this.router.isPublicLink()) {
            this.router.openLogin();
        }
    }

    private setContext(user: User) {
        this.userContextService.setUser(user);
    }
}
