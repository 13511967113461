import { RequestStatus } from "../enums/RequestStatus";

export class EnumUtil {
	static getRequestStatusViewLabel(requestStatus: RequestStatus) {
		switch (requestStatus) {
			case RequestStatus.Failed: return "Failed";
			case RequestStatus.New: return "New";
			case RequestStatus.InProgress: return "In Progress";
			case RequestStatus.Queued: return "Queued";
			case RequestStatus.Sheduled: return "Sheduled";
			case RequestStatus.Completed: return "Completed";
		}
	}

	static getBulkRequestStatusViewLabel(requestStatus: RequestStatus) {
		switch (requestStatus) {
			case RequestStatus.Failed: return "Failed";
			case RequestStatus.New: return "Not Sent";
			case RequestStatus.InProgress: return "In Progress";
			case RequestStatus.Queued: return "In Queue";
			case RequestStatus.Sheduled: return "On Shedule";
			case RequestStatus.Completed: return "Sent";
		}
	}

	static getEnumName<T>(enumObj: T, enumValue: number): string | undefined {
		const enumName = enumObj[enumValue];
		return enumName ? String(enumName) : undefined;
	}

	static getEnumValue<T>(enumObj: T, enumName: string): number | undefined {
		const enumValue = enumObj[enumName];
		return enumValue ? Number(enumValue) : undefined;
	}
}