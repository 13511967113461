import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef, ViewChildren, QueryList } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { AvatarCardTileComponent } from '../avatar-card-tile/avatar-card-tile.component';
import { Avatar } from 'src/app/core/models/avatar-models/Avatar';
import { AvatarSortingTypes } from 'src/app/core/enums/avatar/AvatarSortingTypes';
import { BaseComponent } from 'src/app/core/base.component';
import { AvatarDealFacadeService } from 'src/app/core/services/avatar-deal/avatar-deal-facade.service';
import { AvatarDealApiService } from 'src/app/core/services/avatar-deal/avatar-deal-api.service';
import { ClientCardFacadeService } from 'src/app/core/services/client-card/client-card-facade.service';

@Component({
  selector: 'pd-avatar-groupped-expansion-panel',
  templateUrl: './avatar-groupped-expansion-panel.component.html',
  styleUrls: ['./avatar-groupped-expansion-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class AvatarGrouppedExpansionPanelComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChildren('tiles') tiles: QueryList<AvatarCardTileComponent>;

  @Input() public avatars: Array<Avatar>;
  @Input() public avatarSortingType: AvatarSortingTypes;
  @Input() public isExportAction: boolean;
  @Input() public isHidden: boolean;
  @Input() public isGlobalCollection: boolean;
  @Input() public isCustomAvatars: boolean;
  @Input() public dealId: number;
  @Input() public isAllowAddEditAvatars: boolean = false;
  @Input() public isAllowAddEditCustomAvatars: boolean = false;
  @Input() public isMasterLeadAccount: boolean = false;
  @Input() public isNeedLoadAvatars: boolean = false;
  @Input() public isLoadAll: boolean = false;

  @Output() public onClickAvatar = new EventEmitter<any>();
  @Output() public onDeleteAvatar = new EventEmitter<any>();
  @Output() public onDownloadAvatar = new EventEmitter<any>();
  @Output() public onAddAvatarToCollection = new EventEmitter<any>();

  private loadCount: number = 8;
  private loadCountStep: number = 8;

  constructor(
    private cdRef: ChangeDetectorRef,
    private avatarDealFacadeService: AvatarDealFacadeService,
    private avatarDealApiService: AvatarDealApiService,
    private clientCardFacadeService: ClientCardFacadeService
  ) {
    super();
  }

  ngOnInit() {
    this.clientCardFacadeService.getClientCards()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.cdRef.detectChanges();
      });

    this.avatarDealFacadeService.getAvatars()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.cdRef.detectChanges();
      });

    this.avatarDealFacadeService.getSeasonalProposalAvatars()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.cdRef.detectChanges();
      });

    this.avatarDealFacadeService.getStylesAvailableAvatars()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.cdRef.detectChanges();
      });

    this.avatarDealFacadeService.getCustomAvatars()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.cdRef.detectChanges();
      });
  }

  public ngOnDestroy() {
    this.cdRef.detach();
    super.ngOnDestroy();
  }

  public clickAvatar(event) {
    this.onClickAvatar.emit(event);
  }

  public deleteAvatar(event) {
    this.onDeleteAvatar.emit(event);
  }

  public downloadAvatar(event: any) {
    this.onDownloadAvatar.emit(event);
  }

  public addAvatarToCollection(event: any) {
    this.onAddAvatarToCollection.emit(event);
  }

  public getAvatarCount() {
    if (this.avatars) {
      return this.avatars.length;
    }
    return 0;
  }

  public getAvatars() {
    if (this.avatars) {
      const sortedAvatars = this.avatars.sort((a, b) => b.id - a.id);

      if (this.isLoadAll) {
        return sortedAvatars;
      }

      const filteredAvatars = sortedAvatars.filter(avatar => avatar.base64);

      const uniqueAvatars = Array.from(new Set(
        sortedAvatars.slice(0, this.loadCount).concat(filteredAvatars)
          .map(avatar => avatar.id)
      )).map(id => this.avatars.find(avatar => avatar.id === id))
        .sort((a, b) => b.id - a.id);

      return uniqueAvatars;
    }
    return [];
  }

  public async loadAllAvatars() {
    this.isLoadAll = true;
    await this.loadAvatarsBase64();
  }

  public async loadMoreAvatars() {
    this.loadCount += this.loadCountStep;
    await this.loadAvatarsBase64();
  }

  public async onOpened() {
    this.isNeedLoadAvatars = true;
    await this.loadAvatarsBase64();
  }

  public async loadAvatarsBase64IfNeed() {
    if (!this.isNeedLoadAvatars) {
      return;
    }
    await this.loadAvatarsBase64();
  }

  public async loadAvatarsBase64() {
    const avatars = this.getAvatars();

    const ids = avatars.filter((avatar: Avatar) => !avatar.base64).map((avatar: Avatar) => avatar.id);
    if (ids && ids.length > 0) {
      const avatarsBase64 = await this.avatarDealApiService.getAvatarsBase64(this.dealId, ids).toPromise();
      if (avatarsBase64 && avatarsBase64.length > 0) {
        for (const avatarBase64 of avatarsBase64) {
          let avatar = avatars.find((a: Avatar) => a.id === avatarBase64.id);
          if (avatar) {
            avatar.base64 = avatarBase64.base64;
          }
        }
      }

      this.tiles.forEach((tile: AvatarCardTileComponent) => {
        tile.detectChanges();
      });
    }

    this.cdRef.detectChanges();
  }

  //////////////////////////////// Events END //////////////////////////////

  //////////////////////////////// Private START //////////////////////////////

  //////////////////////////////// Private END //////////////////////////////

  //////////////////////////////// Helpers START ////////////////////////////////

  //////////////////////////////// Helpers END ////////////////////////////////

  //////////////////////////////// View *ngIf START //////////////////////////////

  //////////////////////////////// View *ngIf END //////////////////////////////
}
