export class DateHelper {
  static formatAsISODateString(date: Date): string {
    const y = date.getFullYear();
    const m = ("0" + (date.getMonth() + 1)).slice(-2);
    const d = ("0" + date.getDate()).slice(-2);
    return y + "-" + m + "-" + d;
  }

  static removeTimeFromDate(date: Date): Date {
    let newDate = new Date(date);
    newDate.setSeconds(0);
    newDate.setMinutes(0);
    newDate.setHours(0);
    newDate.setMilliseconds(0);
    return newDate;
  }

  static utcStringToLocalDate(utcString: string): Date {
    const utcDate = new Date(utcString);
    const offset = new Date().getTimezoneOffset();
    const localDate = new Date(utcDate.getTime() - (offset * 60 * 1000));
    return localDate;
  }

  static get secondToMiliseconds(): number {
    return 1000;
  }

  static get minuteToMiliseconds(): number {
    return 60 * DateHelper.secondToMiliseconds;
  }

  static get hourToMiliseconds(): number {    
    return 60 * DateHelper.minuteToMiliseconds;
  }

  static get dayToMiliseconds(): number {    
    return 24 * DateHelper.hourToMiliseconds;
  }
}