import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ClientRating } from 'src/app/core/enums/ClientRating';
import { EnumUtil } from 'src/app/core/utils/enum.util';
import { TemplateVariableContentTypes } from 'src/app/core/enums/TemplateVariableContentTypes';
import { BulkEmailRequestService } from 'src/app/core/services/bulk-message/bulk-email-request.service';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
import { MessageTemplateViewModel } from 'src/app/core/models/message-template/MessageTemplateViewModel';
import { MessageTemplateVariableDescriptionViewModel } from 'src/app/core/models/message-template/MessageTemplateVariableDescriptionViewModel';
import { TemplateVariableTypes } from 'src/app/core/enums/TemplateVariableTypes';
import { BulkMessageFacadeService } from 'src/app/core/services/bulk-message/bulk-message-facade.service';
import { EmailChipsFieldComponent } from './email-chips-field/email-chips-field.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'pd-bulk-message-email',
  templateUrl: './bulk-message-email.component.html',
  styleUrls: ['./bulk-message-email.component.scss']
})
export class BulkMessageEmailComponent implements OnInit {
  @ViewChild("emailsCcField") public emailsCcField: EmailChipsFieldComponent;
  @ViewChild("emailsBccField") public emailsBccField: EmailChipsFieldComponent;

  @Input() public isTemplatePreview: boolean = false;

  public currentTemplate: MessageTemplateViewModel;
  public TemplateVariableType = TemplateVariableContentTypes;
  public spinner: boolean = false;
  public copied: boolean = false;
  public sent: boolean = false;
  public errorSent: boolean = false;
  public isNeedUnsubscribeHtml: boolean = true;
  EnumUtil = EnumUtil;

  public variableDescriptions = new Array<MessageTemplateVariableDescriptionViewModel>;
  public subjectVariables = new Array<MessageTemplateVariableDescriptionViewModel>;
  public variableTypes = [];
  public previewDealId: number = environment.previewDealId;
  public previewDealInfo: any;

  public emailSubject: string;
  public emailBody: string;

  public messageFromEmail: string = environment.defMessageFromEmail;
  public messageFromName: string = environment.defMessageFromName;
  public previewEmailTo: string;

  public contentPlaceholderInline: string;
  public delta: any = '';

  editorModules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'header': 1 }, { 'header': 2 }],
      [{ 'indent': '-1' }, { 'indent': '+1' }],
      [{ 'align': [] }],
      ['link', 'image'],
      [{ 'color': [] }, { 'background': [] }],
      ['clean']
    ]
  };

  constructor(
    private bulkEmailRequestService: BulkEmailRequestService,
    private bulkMessageFacadeService: BulkMessageFacadeService,
  ) {
    this.variableTypes = Object.keys(TemplateVariableContentTypes)
      .filter(key => !isNaN(Number(TemplateVariableContentTypes[key])))
      .filter(key => key != 'Static Text')
      .map(key => ({
        id: TemplateVariableContentTypes[key],
        name: key
      }));
  }

  public async ngOnInit(): Promise<void> {
    this.spinner = true;
    this.loadSimpleDealInfo();
    this.spinner = false;
  }

  async ngOnChanges(changes: SimpleChanges) {

  }

  public isStaticVariable(variable) {
    return variable.templateVariableContentType == TemplateVariableContentTypes['Static Text'];
  }

  public getClientRatingName(clientRating: ClientRating): string {
    return ClientRating[clientRating];
  }

  public async loadSimpleDealInfo() {
    this.previewDealInfo = await this.bulkEmailRequestService.getSimpleDealInfo(this.previewDealId);
  }

  getPlaceholdersCount(text: string): number {
    const regex = /{{\d+}}/g;
    const matches = text.match(regex);
    return matches ? matches.length : 0;
  }

  generateVariables() {
    if (!this.emailBody) {
      this.variableDescriptions = [];
      return;
    }

    const count = this.getPlaceholdersCount(this.emailBody);
    const newVariables = [];

    for (let i = 0; i < count; i++) {
      const variableNumber = i + 1;
      const existingVariable = this.variableDescriptions.find(variable => variable.variableNumber === variableNumber);

      if (existingVariable) {
        newVariables.push(existingVariable);
      } else {
        newVariables.push({ variableNumber: variableNumber, templateVariableType: TemplateVariableTypes.Body, templateVariableContentType: TemplateVariableContentTypes['Static Text'], exampleData: '' });
      }
    }

    this.variableDescriptions = newVariables;
  }

  generateSubjectVariables() {
    if (!this.emailSubject) {
      this.subjectVariables = [];
      return;
    }

    const count = this.getPlaceholdersCount(this.emailSubject);
    const newVariables = [];

    for (let i = 0; i < count; i++) {
      const variableNumber = i + 1;
      const existingVariable = this.subjectVariables.find(variable => variable.variableNumber === variableNumber);

      if (existingVariable) {
        newVariables.push(existingVariable);
      } else {
        newVariables.push({ variableNumber: variableNumber, templateVariableType: TemplateVariableTypes.Subject, templateVariableContentType: TemplateVariableContentTypes['Static Text'], exampleData: '' });
      }
    }

    this.subjectVariables = newVariables;
  }

  getSubjectWithFilledPleholders() {
    if (!this.emailSubject) {
      return;
    }

    let subject = this.emailSubject;
    this.subjectVariables.forEach(varItem => {
      const ph = `{{${varItem.variableNumber}}}`;
      const regex = new RegExp(this.escapeRegExp(ph), 'g');
      subject = subject.replace(regex, varItem.exampleData);
    });

    return subject;
  }

  getBodyWithFilledPleholders() {
    if (!this.emailBody) {
      return;
    }

    let subject = this.emailBody;
    this.variableDescriptions.forEach(varItem => {
      const ph = `{{${varItem.variableNumber}}}`;
      const regex = new RegExp(this.escapeRegExp(ph), 'g');
      subject = subject.replace(regex, varItem.exampleData);
    });

    return subject;
  }

  escapeRegExp(string: string): string {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  updateExampleData(variable) {
    if (this.previewDealInfo) {
      switch (variable.templateVariableContentType) {
        case TemplateVariableContentTypes['Client First Name']:
          variable.exampleData = this.previewDealInfo.firstName;
          break;
        case TemplateVariableContentTypes['Owner Name']:
          variable.exampleData = this.previewDealInfo.ownerName;
          break;
      }
    }
  }

  onContentChanged(event: any) {
    this.delta = event.content;

    this.generateVariables();
    this.generateInlinePlaceholder();
  }

  generateInlinePlaceholder() {
    let contentPlaceholder = '';

    if (this.delta) {
      const deltaOps = this.delta.ops || this.delta;
      const converter = new QuillDeltaToHtmlConverter(deltaOps, {
        inlineStyles: true,
      });

      contentPlaceholder = converter.convert();
    }

    this.contentPlaceholderInline = contentPlaceholder;
  }

  public async sendPreviewEmail() {
    const data = {
      messageFrom: this.messageFromEmail,
      messageFromName: this.messageFromName,
      to: this.previewEmailTo,
      html: this.getBodyWithFilledPleholders(),
      subject: this.getSubjectWithFilledPleholders(),
      isNeedUnsubscribeHtml: this.isNeedUnsubscribeHtml
    };

    const result = await this.bulkEmailRequestService.sendPreviewEmail(data);

    if (result) {
      this.sent = true;

      setTimeout(() => {
        this.sent = false;
      }, 5000);
    }
    else {
      this.errorSent = true;

      setTimeout(() => {
        this.errorSent = false;
      }, 5000);
    }
  }

  public getData() {
    const allVariables = this.variableDescriptions.concat(this.subjectVariables);
    let body = this.contentPlaceholderInline;

    const data = {
      messageTemplateId: null,
      from: this.messageFromEmail,
      fromName: this.messageFromName,
      emailsCc: this.emailsCcField.getEmails()?.join(';'),
      emailsBcc: this.emailsBccField.getEmails()?.join(';'),
      body: body,
      subject: this.emailSubject,
      variableDescriptions: allVariables,
      isNeedUnsubscribeHtml: this.isNeedUnsubscribeHtml
    };

    return data;
  }

  public setData(data: MessageTemplateViewModel) {
    this.emailSubject = data.subject;
    this.emailBody = data.body;
    this.messageFromEmail = data.from;
    this.messageFromName = data.fromName;
    this.emailsCcField.setEmails(this.parseEmails(data.emailsCc));
    this.emailsBccField.setEmails(this.parseEmails(data.emailsBcc));
    this.subjectVariables = data.variableDescriptions.filter(v => v.templateVariableType === TemplateVariableTypes.Subject);
    this.variableDescriptions = data.variableDescriptions.filter(v => v.templateVariableType === TemplateVariableTypes.Body);
    this.currentTemplate = data;
    this.isNeedUnsubscribeHtml = data.isNeedUnsubscribeHtml;
    this.generateSubjectVariables();
    this.generateInlinePlaceholder();
  }

  public sendPreviewValdation() {
    return this.validation()
      && this.previewDealInfo
      && this.previewEmailTo;
  }

  public validation() {
    return !this.spinner
      && this.emailSubject
      && this.emailBody
      && this.messageFromEmail
      && this.messageFromName
      && this.subjectVariables.every(v => v.exampleData)
      && this.variableDescriptions.every(v => v.exampleData)
      && this.isUniqueCombination()
      && this.arePlaceholdersValid(this.emailSubject)
      && this.arePlaceholdersValid(this.emailBody);
  }

  private isUniqueCombination(): boolean {
    const combinationSet = new Set<string>();
    const variables = this.variableDescriptions.concat(this.subjectVariables);

    for (const variable of variables) {
      const combination = `${variable.variableNumber}-${variable.templateVariableType}`;
      if (combinationSet.has(combination)) {
        return false;
      }
      combinationSet.add(combination);
    }
    return true;
  }

  arePlaceholdersValid(text: string): boolean {
    const placeholderPattern = /\{\{(\d+)\}\}/g;
    const placeholders = Array.from(text.matchAll(placeholderPattern)).map(match => parseInt(match[1], 10));

    for (let i = 0; i < placeholders.length; i++) {
      if (placeholders[i] !== i + 1) {
        return false;
      }
    }

    return true;
  }

  public onCopy() {
    this.copied = true;

    setTimeout(() => {
      this.copied = false;
    }, 2000);
    this.bulkMessageFacadeService.setCopiedTemplateSetting(this.currentTemplate);
  }

  parseEmails(emails: string): string[] {
    if (emails) {
      return emails.split(';').map(e => e.trim());
    }
    return [];
  }
}