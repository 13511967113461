<div class="row wrapper">
  <div class="col-4 title">
    Address
  </div>
  <div class="col-8 work-field">
    @if (!address && !isEdit) {
    <pd-add-value (addValue)="addValue()"></pd-add-value>
    }
    @if (isEdit || address) {
    <div class="md-form" (document:focusout)="changeEdit()">
      <input [(ngModel)]="address" mdbActive type="text">
    </div>
    }
  </div>
</div>