import { UserContextService } from './../../core/services/user-context.service';
import { Component, OnInit, Input, OnDestroy, EventEmitter, Output } from "@angular/core";
import { RolesEnum } from "src/app/core/enums/RolesEnum";
import { DealImageRecord } from "src/app/core/models/DealImageRecord";
import { User } from "src/app/core/models/UserModel";
import { ImageService } from "src/app/core/services/image.service";
import { DealUtil } from 'src/app/core/utils/deal.util';

@Component({
    selector: 'pd-ea-follow-up',
    templateUrl: './ea-follow-up.component.html',
    styleUrls: ['./ea-follow-up.component.scss']
})

export class EaFollowUpComponent implements OnInit, OnDestroy {
    @Output() edit = new EventEmitter<number>();

    images: Array<DealImageRecord>;
    editedImage: DealImageRecord;

    @Input()
    dealId: number;
    rolesEnum = RolesEnum
    isShowAll: boolean = false;
    countItemsToShow: number = 4;

    currentUser: User;

    constructor(private imageService: ImageService, private userContextService: UserContextService) { }

    ngOnInit() {
        this.initializeStore();
        this.loadImages();
    }

    ngOnDestroy() {
        this.editedImage = null;
    }

    private initializeStore() {
        this.currentUser = this.userContextService.user.value;
    }

    isEditRecord(image: DealImageRecord) {
        return this.editedImage && this.editedImage.id == image.id
    }

    public loadImages() {
        this.editedImage = null;
        this.imageService.getEaFollowUpImagesByDealId(this.dealId).then(data => {
            this.images = data;
        });
    }

    editImage(image: DealImageRecord) {
        this.edit.emit(image.id);
    }

    showMore() {
        this.isShowAll = true;
    }

    public getTypeOfFollowUp(stageId: number): string {
        return DealUtil.getEaFollowUpTypeLabel(stageId);
    }

    //////////////////////////////// VIEW *ngIF START //////////////////////////////

    public isAllowEdit(image: DealImageRecord): boolean {
        return (this.currentUser && (this.currentUser.role_id === RolesEnum.MasterLead
            || (this.currentUser.role_id === RolesEnum.EA && this.currentUser.id === image.modifiedBy)));
    }

    //////////////////////////////// VIEW *ngIF END ////////////////////////////////
}