<div mat-dialog-title>
  {{modalTitle}}
  <button type="button" mdbBtn (click)="confirm(false)" class="close-btn waves-light btn-danger btn float-right"
    aria-label="Close" mdbWavesEffect>
    <i class="material-icons">close</i>
  </button>
</div>

<mat-dialog-content>
  <div class="modal-content">

    @if (dealsPerson) {
    <div class="text modal-body">

      @if (isShowStill()) {
      <div>
        <div class="row">
          <div class="col row">
            <div class="label">
              {{stillTitle}}
            </div>
          </div>
        </div>
      </div>
      }

      @if (isAddressModal) {
      <div>
        <div class="row">
          <div class="col-sm-12 col-lg-9 row">
            <div class="label">
              Company Address:
            </div>
            @if (dealsPerson) {
            <div class="col" [bootstrapTooltip]="addressValidationTooltip" tooltipPlacement="top">
              <input type="search" class="address" [(ngModel)]="selectedCompanyAddress" [placeholder]="addressLabel">
            </div>
            }
          </div>
        </div>
        @if (isShowValidationMessages && !companyAddressValidation()) {
        <div class="row alert-row">
          <div class="alert alert-danger">
            {{addressValidationTooltip}}
          </div>
        </div>
        }
        <div class="row">
          <div class="col-sm-12 col-lg-9 row">
            <div class="label">
              Home Address:
            </div>
            @if (dealsPerson) {
            <div class="col" [bootstrapTooltip]="addressValidationTooltip" tooltipPlacement="top">
              <input type="search" class="address" [(ngModel)]="selectedHomeAddress" [placeholder]="addressLabel"
                [disabled]="isSkipHomeAddress">
            </div>
            }
          </div>
          @if (dealsPerson) {
          <div class="col checkbox">
            <mat-checkbox type="button" [(ngModel)]="isSkipHomeAddress">Don't know</mat-checkbox>
          </div>
          }
        </div>

        @if (isShowValidationMessages && !homeAddressValidation()) {
        <div class="row alert-row">
          <div class="alert alert-danger">
            {{addressValidationTooltip}}
          </div>
        </div>
        }
      </div>
      }

      @if (isCompanyModal) {
      <div>
        <div>
          <div class="row">
            <div class="col-12 col-lg-9 row no-padding">
              <div class="label">
                Company:
              </div>
              <div class="col-12 col-sm" style="max-width: 470px;">
                @if (!isCompanyEdit) {
                <pd-custom-select-filter #companyFilter (document:focusout)="changeCompanyValue()"
                  (loadDataEvent)="getCompaniesFilterData($event)"
                  (autoCompleteEvent)="getCompaniesAutocomplete($event)" (isSelectedItem)="onCompanyChanged($event)"
                  [dataLabel]="selectedCompany" [filterType]="filterType.Company" [isMultiple]="false"
                  [selectText]="selectedCompany">
                </pd-custom-select-filter>
                }
                @if (isCompanyEdit) {
                <div>
                  <input #companyInput style="font-size: 22px; margin-bottom: 7px;" type="text"
                    value={{selectedCompany}}>
                </div>
                }
              </div>
            </div>
            <div class="col">
              @if (isShowAddCompanyValue) {
              <pd-add-value (addValue)="addCompanyValue()">
              </pd-add-value>
              }
            </div>
          </div>
        </div>
      </div>
      }

      @if (isLocationModal) {
      <div>
        <div>
          <div class="row">
            <div class="col-12 col-lg-9 row no-padding">
              <div class="label">
                Location:
              </div>
              <div class="col-12 col-sm" style="max-width: 470px;">
                @if (!isLocationEdit) {
                <pd-custom-select-filter #locationFilter (document:focusout)="changeLocationValue()"
                  (loadDataEvent)="getLocationsFilterData($event)"
                  (autoCompleteEvent)="getLocationsAutocomplete($event)" (isSelectedItem)="onLocationsChanged($event)"
                  [dataLabel]="selectedLocation" [filterType]="filterType.Location" [isMultiple]="false"
                  [selectText]="selectedLocation">
                </pd-custom-select-filter>
                }
                @if (isLocationEdit) {
                <div>
                  <input #locationInput style="font-size: 22px; margin-bottom: 7px;" type="text"
                    value={{selectedLocation}}>
                </div>
                }
              </div>
            </div>
            <div class="col">
              @if (isSelectedLocation == false && !isLocationEdit) {
              <pd-add-value (addValue)="addLocationValue()">
              </pd-add-value>
              }
            </div>
          </div>
        </div>
      </div>
      }
    </div>
    }

  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="modal-footer text center">
    <div class="left flex-start">
      @if (initPersonDataType != currentPersonDataType) {
      <button type="button" style="position: absolute;left: 15px;top: 16px;" class="btn btn-light waves-light back-btn"
        (click)="setPrevModalState()">Back</button>
      }
    </div>

    @if (isAddressModal) {
    <div>
      @if (initPersonDataType == confirmPersonDataType.Address) {
      <button type="button" class="btn btn-light waves-light cancel-meeting" (click)="confirm(false)">cancel</button>
      }
      <button type="button" class="btn btn-success waves-light update" (click)="setNextModalState(false)">confirm
        addresses</button>
    </div>
    }

    @if (isCompanyModal && !isCompanyEdit) {
    <div>
      @if (initPersonDataType == confirmPersonDataType.Company) {
      <button type="button" class="btn btn-light waves-light cancel-meeting" (click)="confirm(false)">cancel</button>
      }
      <button type="button" [disabled]="!selectedCompany" class="btn btn-success waves-light update"
        (click)="setNextModalState(false)">confirm
        company</button>
    </div>
    }

    @if (isLocationModal && !isLocationEdit) {
    <div>
      @if (initPersonDataType == confirmPersonDataType.Location) {
      <button type="button" class="btn btn-light waves-light cancel-meeting" (click)="confirm(false)">cancel</button>
      }
      <button type="button" [disabled]="!selectedLocation" class="btn btn-success waves-light update"
        (click)="setNextModalState(false)">confirm
        location</button>
    </div>
    }

    @if (isShowYesNo()) {
    <div>
      <button type="button" class="btn btn-light waves-light cancel-meeting" (click)="onClickNo()">No</button>
      <button type="button" class="btn btn-success waves-light update" (click)="setNextModalState(true)">Yes</button>
    </div>
    }

    @if (isCompanyEdit || isLocationEdit) {
    <div>
      <button class="btn btn-light waves-light cancel-meeting" (click)="cancelEdit()">
        Cancel
      </button>
      <button class="btn btn-success waves-light update" (click)="saveEdit()">
        Save
      </button>
    </div>
    }

    <div class="right">
    </div>

  </div>
</mat-dialog-actions>