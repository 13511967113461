<div class="notes">
  
  <button type="button" class="buttonPoper" (click)="clickPopover();$event.stopPropagation()">
    <img [src]="isNotesExist ? '../../../assets/notes.png' : '../../../assets/empty-notes.png'">
  </button>

  @if (isOpened) {
  <div class="nodrag">
    <div class="pop-div" (click)="$event.stopPropagation()" #popDiv>
    </div>
    <div #popTemplate class="nodrag popover flip" (click)="$event.stopPropagation()">
      <div class="poper nodrag">
        <div class="title nodrag">
          Notes
        </div>
        <hr>
        @if (note) {
        <div class="md-form information nodrag">
          <p style="overflow-wrap: break-word;" class="nodrag"><strong>Notes (Old)</strong> - {{note}}</p>
        </div>
        }
        @if (isLoadTable) {
        <div class="wrapper nodrag">
          <pd-note-table #notesTable [dealId]='deal.id' [isAsModal]='true' class="nodrag" (onLoaded)="onTableLoaded($event)"></pd-note-table>
        </div>
        }
        @if (poperSpinner) {
        <div class="poperSpinner">
          <mat-spinner [diameter]="40"></mat-spinner>
        </div>
        }
        <div class="save nodrag" nodrag style="float: right;">
          <button class="btn btn-danger nodrag" (click)="cancel(); ;$event.stopPropagation()"
            style="margin: 5px;z-index: 9;">Close</button>
        </div>
      </div>
    </div>
  </div>
  }
</div>