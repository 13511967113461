import { ChipsSelectFilterModel, ChipsSelectFilterDataModel } from "../CustomChipsSelectFilterModel";

export class FilterDealModel {
    isSameBuildingFilter: boolean;
    isWithPhoneNumberOnlyFilter: boolean;
    isContactByEmailOnlyFilter: boolean;

    filteredCompanies: Array<ChipsSelectFilterDataModel>;
    filteredPositions: Array<ChipsSelectFilterDataModel>;
    filteredLocations: Array<ChipsSelectFilterDataModel>;

    selectedGenderId: number;
    selectedGroupId: number;
}

export class FilterDealStoreModel {
    isSameBuildingFilter: boolean;
    isWithPhoneNumberOnlyFilter: boolean;
    isContactByEmailOnlyFilter: boolean;

    filteredCompanies: Array<ChipsSelectFilterModel>;
    filteredPositions: Array<ChipsSelectFilterModel>;
    filteredLocations: Array<ChipsSelectFilterModel>;

    selectedGenderId: number;
    selectedGroupId: number;
}
