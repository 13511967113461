import { GlobalConstants } from './../app/core/global-constants';

export const NewProspectsPipeline = { id: 1, name: 'New prospects' };
export const ClientsPipeline = { id: 4, name: 'EA Clients (not for Clothiers)' };
export const OpenOrders = { id: 7, name: 'Open Orders' };
export const ClothierContactClientsPipeline = { id: 12, name: 'Clothier Contact Clients' };
export const ClothierMeetingConfirmPipeline = { id: 15, name: 'Clothier Meeting Confirm' };
export const Pipelines = {
  NewProspects: 1,
  Clients: 4,
  OpenOrders: 7,
  ClothierContactClients: 12,
  ClothierMeetingConfirm: 15
};

export const ALL_PIPELINES = [NewProspectsPipeline, ClientsPipeline, OpenOrders, ClothierContactClientsPipeline, ClothierMeetingConfirmPipeline];

export const MASTER_LEAD_DEALS = [
  {
    'id': 18,
    'order_nr': 2,
    'name': 'Kill',
    'active_flag': true,
    'deal_probability': 100,
    'pipeline_id': 1,
    'rotten_flag': false,
    'rotten_days': null,
    'add_time': '2017-12-17 17:50:53',
    'update_time': '2018-04-09 07:08:30',
    'pipeline_name': 'New Prospects'
  },
  {
    'id': 25,
    'order_nr': 3,
    'name': 'NG',
    'active_flag': true,
    'deal_probability': 5,
    'pipeline_id': 1,
    'rotten_flag': false,
    'rotten_days': 1,
    'add_time': '2017-12-28 14:56:54',
    'update_time': '2018-04-09 07:08:30',
    'pipeline_name': 'New Prospects'
  },
  {
    'id': 26,
    'order_nr': 18,
    'name': 'DQ',
    'active_flag': true,
    'deal_probability': 5,
    'pipeline_id': 1,
    'rotten_flag': true,
    'rotten_days': 1,
    'add_time': '2017-12-28 14:57:07',
    'update_time': '2018-04-09 07:08:30',
    'pipeline_name': 'New Prospects'
  },
  {
    'id': 33,
    'order_nr': 1,
    'name': 'NoPhone#',
    'active_flag': true,
    'deal_probability': 5,
    'pipeline_id': 1,
    'rotten_flag': false,
    'rotten_days': null,
    'add_time': '2018-03-09 19:41:18',
    'update_time': '2018-04-09 07:08:30',
    'pipeline_name': 'New Prospects'
  },
  {
    'id': 34,
    'order_nr': 22,
    'name': 'Cold LOOKUP',
    'active_flag': true,
    'deal_probability': 100,
    'pipeline_id': 1,
    'rotten_flag': false,
    'rotten_days': null,
    'add_time': '2018-04-09 07:08:23',
    'update_time': '2018-04-09 07:08:30',
    'pipeline_name': 'New Prospects'
  },
  {
    'id': 35,
    'order_nr': 23,
    'name': 'Linkedin LOOKUP',
    'active_flag': true,
    'deal_probability': 100,
    'pipeline_id': 1,
    'rotten_flag': false,
    'rotten_days': null,
    'add_time': '2018-04-09 07:08:30',
    'update_time': '2018-04-09 07:08:30',
    'pipeline_name': 'New Prospects'
  },
  {
    'id': 1,
    'order_nr': 24,
    'name': 'Cold',
    'active_flag': true,
    'deal_probability': 5,
    'pipeline_id': 1,
    'rotten_flag': false,
    'rotten_days': null,
    'add_time': '2017-12-15 13:52:06',
    'update_time': '2018-04-09 07:08:30',
    'pipeline_name': 'New Prospects'
  },
  {
    'id': 2,
    'order_nr': 27,
    'name': 'Linkedin',
    'active_flag': true,
    'deal_probability': 20,
    'pipeline_id': 1,
    'rotten_flag': false,
    'rotten_days': null,
    'add_time': '2017-12-15 13:52:06',
    'update_time': '2018-04-09 07:08:30',
    'pipeline_name': 'New Prospects'
  },
  {
    'id': 115,
    'order_nr': 28,
    'name': 'Same Building',
    'active_flag': true,
    'deal_probability': 100,
    'pipeline_id': 1,
    'rotten_flag': false,
    'rotten_days': null,
    'add_time': '2021-05-16 19:42:07',
    'update_time': '2021-05-16 19:42:07',
    'pipeline_name': 'New Prospects'
  }
];

export const STAGES_NEW_PROSPECTS_DICT = [
  {
    'id': 33,
    'order_nr': 1,
    'name': 'NoPh#',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 18,
    'order_nr': 2,
    'name': 'Kill',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 51,
    'order_nr': 3,
    'name': 'To Contact',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 52,
    'order_nr': 4,
    'name': '1st Call',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 53,
    'order_nr': 5,
    'name': '1st Waiting',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 54,
    'order_nr': 6,
    'name': '1st Call Scheduled',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 55,
    'order_nr': 7,
    'name': '2nd Call',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 56,
    'order_nr': 8,
    'name': '2nd Waiting',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 57,
    'order_nr': 9,
    'name': '2nd Call Scheduled',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 58,
    'order_nr': 10,
    'name': '3rd Call',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 59,
    'order_nr': 11,
    'name': '3rd Waiting',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 60,
    'order_nr': 14,
    'name': '3rd Call Scheduled',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 61,
    'order_nr': 15,
    'name': 'Booked',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 62,
    'order_nr': 16,
    'name': 'Repeat Event',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 25,
    'order_nr': 17,
    'name': 'NG',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 26,
    'order_nr': 18,
    'name': 'DQ',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 22,
    'order_nr': 19,
    'name': 'NA',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 39,
    'name': 'NA-Cancel',
    'order_nr': 20,
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 40,
    'name': 'NA-MetNG',
    'order_nr': 21,
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 34,
    'order_nr': 22,
    'name': 'Cold LOOKUP',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 35,
    'order_nr': 23,
    'name': 'Linkedin LOOKUP',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 1,
    'order_nr': 24,
    'name': 'Cold',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 89,
    'order_nr': 25,
    'name': 'No Email Found',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 64,
    'order_nr': 26,
    'name': 'Email Found',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 2,
    'order_nr': 27,
    'name': 'Linkedin',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 115,
    'order_nr': 28,
    'name': 'Same Building',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 20,
    'order_nr': 29,
    'name': 'Email1',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 30,
    'order_nr': 30,
    'name': 'Email2',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 31,
    'order_nr': 31,
    'name': 'Email3',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 48,
    'order_nr': 32,
    'name': 'Add1',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 49,
    'order_nr': 33,
    'name': 'Add2',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 46,
    'order_nr': 34,
    'name': 'DM',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 44,
    'order_nr': 35,
    'name': 'SM',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 45,
    'order_nr': 36,
    'name': 'SM*',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 6,
    'order_nr': 37,
    'name': '1VM',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 23,
    'order_nr': 38,
    'name': '2VM',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 24,
    'order_nr': 39,
    'name': '3VM',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 3,
    'order_nr': 40,
    'name': 'Referral',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 99,
    'order_nr': 41,
    'name': 'RefVM1',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 100,
    'order_nr': 42,
    'name': 'RefVM2',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 101,
    'order_nr': 43,
    'name': 'RefVM3',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 4,
    'order_nr': 44,
    'name': 'Float',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 47,
    'order_nr': 45,
    'name': 'RefAsk',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 17,
    'order_nr': 46,
    'name': 'Resched',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 16,
    'order_nr': 47,
    'name': 'Meeting',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 41,
    'order_nr': 48,
    'name': 'Client Sold',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 37,
    'order_nr': 49,
    'name': 'Cancel',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 36,
    'order_nr': 50,
    'name': 'MetNG',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 73,
    'order_nr': 51,
    'name': 'New Candidates',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 124,
    'order_nr': 52,
    'name': 'Recruiter NG (w/o\u00A0video)',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 125,
    'order_nr': 53,
    'name': 'Video Applications',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 82,
    'order_nr': 54,
    'name': 'Recruiter NG',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 76,
    'order_nr': 55,
    'name': 'Set Interview 1',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 77,
    'order_nr': 56,
    'name': 'Interview 1',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 130,
    'order_nr': 57,
    'name': 'Recruiter NG (1st\u00A0interview)',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 127,
    'order_nr': 58,
    'name': 'Set Interview 2',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 78,
    'order_nr': 59,
    'name': 'Interview 2',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 131,
    'order_nr': 60,
    'name': 'Recruiter NG (2nd\u00A0interview)',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 129,
    'order_nr': 61,
    'name': 'Follow Day',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 80,
    'order_nr': 62,
    'name': 'Offer Sent',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 83,
    'order_nr': 63,
    'name': 'Hired',
    'pipeline_id': Pipelines.NewProspects
  }
];

export const STAGES_CLIENTS_DICT = [
  {
    'id': 8,
    'order_nr': 401,
    'name': 'Contact Client Now',
    'pipeline_id': Pipelines.Clients
  },
  {
    'id': 9,
    'order_nr': 402,
    'name': 'EA VM/Email1',
    'pipeline_id': Pipelines.Clients
  },
  {
    'id': 28,
    'order_nr': 403,
    'name': 'EA VM/Email2',
    'pipeline_id': Pipelines.Clients
  },
  {
    'id': 38,
    'order_nr': 404,
    'name': 'Recently Contacted',
    'pipeline_id': Pipelines.Clients
  },
  {
    'id': 21,
    'order_nr': 405,
    'name': 'EA Current Meeting',
    'pipeline_id': Pipelines.Clients
  },
  {
    'id': 50,
    'order_nr': 406,
    'name': 'Look for Client Contact Info',
    'pipeline_id': Pipelines.Clients
  },
  {
    'id': 14,
    'order_nr': 407,
    'name': 'Killed Record',
    'pipeline_id': Pipelines.Clients
  },
  {
    'id': 117,
    'order_nr': 701,
    'name': 'Meeting Set',
    'pipeline_id': Pipelines.OpenOrders
  },
  {
    'id': 42,
    'order_nr': 702,
    'name': 'Delivery Check',
    'pipeline_id': Pipelines.OpenOrders
  },
  {
    'id': 27,
    'order_nr': 703,
    'name': 'Recently Cashed out',
    'pipeline_id': Pipelines.OpenOrders
  },
  {
    'id': 118,
    'order_nr': 704,
    'name': 'Write Off',
    'pipeline_id': Pipelines.OpenOrders
  },
  {
    'id': 119,
    'order_nr': 705,
    'name': 'Overdue <24 weeks',
    'pipeline_id': Pipelines.OpenOrders
  },
  {
    'id': 120,
    'order_nr': 706,
    'name': 'Overdue <16 weeks',
    'pipeline_id': Pipelines.OpenOrders
  },
  {
    'id': 121,
    'order_nr': 707,
    'name': 'Overdue <12 weeks',
    'pipeline_id': Pipelines.OpenOrders
  },
  {
    'id': 122,
    'order_nr': 708,
    'name': '<9 weeks',
    'pipeline_id': Pipelines.OpenOrders
  },
  {
    'id': 123,
    'order_nr': 709,
    'name': '<6 weeks',
    'pipeline_id': Pipelines.OpenOrders
  },
  {
    'id': 32,
    'order_nr': 710,
    'name': '<3 weeks',
    'pipeline_id': Pipelines.OpenOrders
  },
  {
    'id': 112,
    'order_nr': 711,
    'name': 'New Order',
    'pipeline_id': Pipelines.OpenOrders
  },
];

export const PROSPECTS_MOVE_RULES = {
  [GlobalConstants.ALL_STAGES.RefVM1]: [GlobalConstants.ALL_STAGES.Meeting, GlobalConstants.ALL_STAGES.NoPhone, GlobalConstants.ALL_STAGES.NG, GlobalConstants.ALL_STAGES.NoAns, GlobalConstants.ALL_STAGES.RefVM1, GlobalConstants.ALL_STAGES.RefVM2, GlobalConstants.ALL_STAGES.RefVM3, GlobalConstants.ALL_STAGES.DQ],
  [GlobalConstants.ALL_STAGES.RefVM2]: [GlobalConstants.ALL_STAGES.Meeting, GlobalConstants.ALL_STAGES.NoPhone, GlobalConstants.ALL_STAGES.NG, GlobalConstants.ALL_STAGES.NoAns, GlobalConstants.ALL_STAGES.RefVM1, GlobalConstants.ALL_STAGES.RefVM2, GlobalConstants.ALL_STAGES.RefVM3, GlobalConstants.ALL_STAGES.DQ],
  [GlobalConstants.ALL_STAGES.RefVM3]: [GlobalConstants.ALL_STAGES.Meeting, GlobalConstants.ALL_STAGES.NoPhone, GlobalConstants.ALL_STAGES.NG, GlobalConstants.ALL_STAGES.NoAns, GlobalConstants.ALL_STAGES.RefVM1, GlobalConstants.ALL_STAGES.RefVM2, GlobalConstants.ALL_STAGES.RefVM3, GlobalConstants.ALL_STAGES.DQ],
}

export const CLOTHIERCONTACTCLIENTS_MOVE_RULES = {
  [GlobalConstants.ALL_STAGES.SeasonalCall]: [GlobalConstants.ALL_STAGES.SeasonalCall, GlobalConstants.ALL_STAGES.VMEM1, GlobalConstants.ALL_STAGES.VMEM2, GlobalConstants.ALL_STAGES.TryNextSeason, GlobalConstants.ALL_STAGES.CurrentMeeting, GlobalConstants.ALL_STAGES.LookForContactInfo, GlobalConstants.ALL_STAGES.PassToEaToContact],
  [GlobalConstants.ALL_STAGES.VMEM1]: [GlobalConstants.ALL_STAGES.SeasonalCall, GlobalConstants.ALL_STAGES.VMEM1, GlobalConstants.ALL_STAGES.VMEM2, GlobalConstants.ALL_STAGES.TryNextSeason, GlobalConstants.ALL_STAGES.CurrentMeeting, GlobalConstants.ALL_STAGES.LookForContactInfo, GlobalConstants.ALL_STAGES.PassToEaToContact],
  [GlobalConstants.ALL_STAGES.VMEM2]: [GlobalConstants.ALL_STAGES.SeasonalCall, GlobalConstants.ALL_STAGES.VMEM1, GlobalConstants.ALL_STAGES.VMEM2, GlobalConstants.ALL_STAGES.TryNextSeason, GlobalConstants.ALL_STAGES.CurrentMeeting, GlobalConstants.ALL_STAGES.LookForContactInfo, GlobalConstants.ALL_STAGES.PassToEaToContact],
  [GlobalConstants.ALL_STAGES.TryNextSeason]: [GlobalConstants.ALL_STAGES.TryNextSeason, GlobalConstants.ALL_STAGES.CurrentMeeting],
  [GlobalConstants.ALL_STAGES.Reschedule]: [GlobalConstants.ALL_STAGES.Reschedule, GlobalConstants.ALL_STAGES.VMEM1, GlobalConstants.ALL_STAGES.VMEM2, GlobalConstants.ALL_STAGES.TryNextSeason, GlobalConstants.ALL_STAGES.CurrentMeeting],
  [GlobalConstants.ALL_STAGES.CurrentMeeting]: [GlobalConstants.ALL_STAGES.CurrentMeeting, GlobalConstants.ALL_STAGES.TryNextSeason, GlobalConstants.ALL_STAGES.Reschedule],
  [GlobalConstants.ALL_STAGES.OrderShipped]: [GlobalConstants.ALL_STAGES.OrderShipped, GlobalConstants.ALL_STAGES.DeliveryMeeting],
  [GlobalConstants.ALL_STAGES.DeliveryMeeting]: [GlobalConstants.ALL_STAGES.DeliveryMeeting, GlobalConstants.ALL_STAGES.InAlterations],
  [GlobalConstants.ALL_STAGES.InAlterations]: [GlobalConstants.ALL_STAGES.InAlterations, GlobalConstants.ALL_STAGES.DeliveryMeeting],
  [GlobalConstants.ALL_STAGES.PassToEaToContact]: [GlobalConstants.ALL_STAGES.SeasonalCall, GlobalConstants.ALL_STAGES.VMEM1, GlobalConstants.ALL_STAGES.VMEM2, GlobalConstants.ALL_STAGES.TryNextSeason, GlobalConstants.ALL_STAGES.CurrentMeeting, GlobalConstants.ALL_STAGES.LookForContactInfo, GlobalConstants.ALL_STAGES.PassToEaToContact],
  [GlobalConstants.ALL_STAGES.LookForContactInfo]: [GlobalConstants.ALL_STAGES.SeasonalCall, GlobalConstants.ALL_STAGES.CurrentMeeting, GlobalConstants.ALL_STAGES.LookForContactInfo],
}

export const CLOTHIERCONTACTCLIENTS_MOVE_RULES_MasterLead = {
  [GlobalConstants.ALL_STAGES.SeasonalCall]: [GlobalConstants.ALL_STAGES.SeasonalCall, GlobalConstants.ALL_STAGES.VMEM1, GlobalConstants.ALL_STAGES.VMEM2, GlobalConstants.ALL_STAGES.TryNextSeason,
  GlobalConstants.ALL_STAGES.Reschedule, GlobalConstants.ALL_STAGES.CurrentMeeting, GlobalConstants.ALL_STAGES.OrderShipped, GlobalConstants.ALL_STAGES.DeliveryMeeting, GlobalConstants.ALL_STAGES.InAlterations,
  GlobalConstants.ALL_STAGES.LookForContactInfo, GlobalConstants.ALL_STAGES.PassToEaToContact],
  [GlobalConstants.ALL_STAGES.VMEM1]: [GlobalConstants.ALL_STAGES.SeasonalCall, GlobalConstants.ALL_STAGES.VMEM1, GlobalConstants.ALL_STAGES.VMEM2, GlobalConstants.ALL_STAGES.TryNextSeason,
  GlobalConstants.ALL_STAGES.Reschedule, GlobalConstants.ALL_STAGES.CurrentMeeting, GlobalConstants.ALL_STAGES.OrderShipped, GlobalConstants.ALL_STAGES.DeliveryMeeting, GlobalConstants.ALL_STAGES.InAlterations,
  GlobalConstants.ALL_STAGES.LookForContactInfo, GlobalConstants.ALL_STAGES.PassToEaToContact],
  [GlobalConstants.ALL_STAGES.VMEM2]: [GlobalConstants.ALL_STAGES.SeasonalCall, GlobalConstants.ALL_STAGES.VMEM1, GlobalConstants.ALL_STAGES.VMEM2, GlobalConstants.ALL_STAGES.TryNextSeason,
  GlobalConstants.ALL_STAGES.Reschedule, GlobalConstants.ALL_STAGES.CurrentMeeting, GlobalConstants.ALL_STAGES.OrderShipped, GlobalConstants.ALL_STAGES.DeliveryMeeting, GlobalConstants.ALL_STAGES.InAlterations,
  GlobalConstants.ALL_STAGES.LookForContactInfo, GlobalConstants.ALL_STAGES.PassToEaToContact],
  [GlobalConstants.ALL_STAGES.TryNextSeason]: [GlobalConstants.ALL_STAGES.SeasonalCall, GlobalConstants.ALL_STAGES.VMEM1, GlobalConstants.ALL_STAGES.VMEM2, GlobalConstants.ALL_STAGES.TryNextSeason,
  GlobalConstants.ALL_STAGES.Reschedule, GlobalConstants.ALL_STAGES.CurrentMeeting, GlobalConstants.ALL_STAGES.OrderShipped, GlobalConstants.ALL_STAGES.DeliveryMeeting, GlobalConstants.ALL_STAGES.InAlterations,
  GlobalConstants.ALL_STAGES.LookForContactInfo, GlobalConstants.ALL_STAGES.PassToEaToContact],
  [GlobalConstants.ALL_STAGES.Reschedule]: [GlobalConstants.ALL_STAGES.SeasonalCall, GlobalConstants.ALL_STAGES.VMEM1, GlobalConstants.ALL_STAGES.VMEM2, GlobalConstants.ALL_STAGES.TryNextSeason,
  GlobalConstants.ALL_STAGES.Reschedule, GlobalConstants.ALL_STAGES.CurrentMeeting, GlobalConstants.ALL_STAGES.OrderShipped, GlobalConstants.ALL_STAGES.DeliveryMeeting, GlobalConstants.ALL_STAGES.InAlterations,
  GlobalConstants.ALL_STAGES.LookForContactInfo, GlobalConstants.ALL_STAGES.PassToEaToContact],
  [GlobalConstants.ALL_STAGES.CurrentMeeting]: [GlobalConstants.ALL_STAGES.SeasonalCall, GlobalConstants.ALL_STAGES.VMEM1, GlobalConstants.ALL_STAGES.VMEM2, GlobalConstants.ALL_STAGES.TryNextSeason,
  GlobalConstants.ALL_STAGES.Reschedule, GlobalConstants.ALL_STAGES.CurrentMeeting, GlobalConstants.ALL_STAGES.OrderShipped, GlobalConstants.ALL_STAGES.DeliveryMeeting, GlobalConstants.ALL_STAGES.InAlterations,
  GlobalConstants.ALL_STAGES.LookForContactInfo, GlobalConstants.ALL_STAGES.PassToEaToContact],
  [GlobalConstants.ALL_STAGES.OrderShipped]: [GlobalConstants.ALL_STAGES.SeasonalCall, GlobalConstants.ALL_STAGES.VMEM1, GlobalConstants.ALL_STAGES.VMEM2, GlobalConstants.ALL_STAGES.TryNextSeason,
  GlobalConstants.ALL_STAGES.Reschedule, GlobalConstants.ALL_STAGES.CurrentMeeting, GlobalConstants.ALL_STAGES.OrderShipped, GlobalConstants.ALL_STAGES.DeliveryMeeting, GlobalConstants.ALL_STAGES.InAlterations,
  GlobalConstants.ALL_STAGES.LookForContactInfo, GlobalConstants.ALL_STAGES.PassToEaToContact],
  [GlobalConstants.ALL_STAGES.DeliveryMeeting]: [GlobalConstants.ALL_STAGES.SeasonalCall, GlobalConstants.ALL_STAGES.VMEM1, GlobalConstants.ALL_STAGES.VMEM2, GlobalConstants.ALL_STAGES.TryNextSeason,
  GlobalConstants.ALL_STAGES.Reschedule, GlobalConstants.ALL_STAGES.CurrentMeeting, GlobalConstants.ALL_STAGES.OrderShipped, GlobalConstants.ALL_STAGES.DeliveryMeeting, GlobalConstants.ALL_STAGES.InAlterations,
  GlobalConstants.ALL_STAGES.LookForContactInfo, GlobalConstants.ALL_STAGES.PassToEaToContact],
  [GlobalConstants.ALL_STAGES.InAlterations]: [GlobalConstants.ALL_STAGES.SeasonalCall, GlobalConstants.ALL_STAGES.VMEM1, GlobalConstants.ALL_STAGES.VMEM2, GlobalConstants.ALL_STAGES.TryNextSeason,
  GlobalConstants.ALL_STAGES.Reschedule, GlobalConstants.ALL_STAGES.CurrentMeeting, GlobalConstants.ALL_STAGES.OrderShipped, GlobalConstants.ALL_STAGES.DeliveryMeeting, GlobalConstants.ALL_STAGES.InAlterations,
  GlobalConstants.ALL_STAGES.LookForContactInfo, GlobalConstants.ALL_STAGES.PassToEaToContact],
  [GlobalConstants.ALL_STAGES.PassToEaToContact]: [GlobalConstants.ALL_STAGES.SeasonalCall, GlobalConstants.ALL_STAGES.VMEM1, GlobalConstants.ALL_STAGES.VMEM2, GlobalConstants.ALL_STAGES.TryNextSeason,
  GlobalConstants.ALL_STAGES.Reschedule, GlobalConstants.ALL_STAGES.CurrentMeeting, GlobalConstants.ALL_STAGES.OrderShipped, GlobalConstants.ALL_STAGES.DeliveryMeeting, GlobalConstants.ALL_STAGES.InAlterations,
  GlobalConstants.ALL_STAGES.LookForContactInfo, GlobalConstants.ALL_STAGES.PassToEaToContact],
  [GlobalConstants.ALL_STAGES.LookForContactInfo]: [GlobalConstants.ALL_STAGES.SeasonalCall, GlobalConstants.ALL_STAGES.VMEM1, GlobalConstants.ALL_STAGES.VMEM2, GlobalConstants.ALL_STAGES.TryNextSeason,
  GlobalConstants.ALL_STAGES.Reschedule, GlobalConstants.ALL_STAGES.CurrentMeeting, GlobalConstants.ALL_STAGES.OrderShipped, GlobalConstants.ALL_STAGES.DeliveryMeeting, GlobalConstants.ALL_STAGES.InAlterations,
  GlobalConstants.ALL_STAGES.LookForContactInfo, GlobalConstants.ALL_STAGES.PassToEaToContact],
}

export const CLIENT_MOVE_RULES = {
  [GlobalConstants.ALL_STAGES.NewOrder]: [GlobalConstants.ALL_STAGES.NewOrder],
  [GlobalConstants.ALL_STAGES.Less3Weeks]: [GlobalConstants.ALL_STAGES.Less3Weeks],
  [GlobalConstants.ALL_STAGES.Less6Weeks]: [GlobalConstants.ALL_STAGES.Less6Weeks],
  [GlobalConstants.ALL_STAGES.Less9Weeks]: [GlobalConstants.ALL_STAGES.Less9Weeks],
  [GlobalConstants.ALL_STAGES.OverdueLess12Weeks]: [GlobalConstants.ALL_STAGES.OverdueLess12Weeks],
  [GlobalConstants.ALL_STAGES.OverdueLess16Weeks]: [GlobalConstants.ALL_STAGES.OverdueLess16Weeks],
  [GlobalConstants.ALL_STAGES.OverdueLess24Weeks]: [GlobalConstants.ALL_STAGES.OverdueLess24Weeks],
  [GlobalConstants.ALL_STAGES.WriteOff]: [GlobalConstants.ALL_STAGES.WriteOff],
  [GlobalConstants.ALL_STAGES.CashedOut]: [GlobalConstants.ALL_STAGES.CashedOut, GlobalConstants.ALL_STAGES.MeetingSet],
  [GlobalConstants.ALL_STAGES.DeliveryCheck]: [GlobalConstants.ALL_STAGES.DeliveryCheck, GlobalConstants.ALL_STAGES.RecentlyContacted, GlobalConstants.ALL_STAGES.MeetingSet],
  [GlobalConstants.ALL_STAGES.MeetingSet]: [GlobalConstants.ALL_STAGES.MeetingSet, GlobalConstants.ALL_STAGES.RecentlyContacted],

  [GlobalConstants.ALL_STAGES.ContactClientNow]: [GlobalConstants.ALL_STAGES.ContactClientNow, GlobalConstants.ALL_STAGES.EAVMEmail1, GlobalConstants.ALL_STAGES.RecentlyContacted, GlobalConstants.ALL_STAGES.EACurrentMeeting, GlobalConstants.ALL_STAGES.LookForClientContactInfo],
  [GlobalConstants.ALL_STAGES.EAVMEmail1]: [GlobalConstants.ALL_STAGES.EAVMEmail1, GlobalConstants.ALL_STAGES.EAVMEmail2, GlobalConstants.ALL_STAGES.RecentlyContacted, GlobalConstants.ALL_STAGES.EACurrentMeeting, GlobalConstants.ALL_STAGES.LookForClientContactInfo],
  [GlobalConstants.ALL_STAGES.EAVMEmail2]: [GlobalConstants.ALL_STAGES.EAVMEmail2, GlobalConstants.ALL_STAGES.RecentlyContacted, GlobalConstants.ALL_STAGES.EACurrentMeeting, GlobalConstants.ALL_STAGES.LookForClientContactInfo],
  [GlobalConstants.ALL_STAGES.RecentlyContacted]: [GlobalConstants.ALL_STAGES.RecentlyContacted],
  [GlobalConstants.ALL_STAGES.EACurrentMeeting]: [GlobalConstants.ALL_STAGES.EACurrentMeeting, GlobalConstants.ALL_STAGES.RecentlyContacted],
  [GlobalConstants.ALL_STAGES.LookForClientContactInfo]: [GlobalConstants.ALL_STAGES.LookForClientContactInfo, GlobalConstants.ALL_STAGES.EAVMEmail1, GlobalConstants.ALL_STAGES.RecentlyContacted, GlobalConstants.ALL_STAGES.EACurrentMeeting, GlobalConstants.ALL_STAGES.ContactClientNow],

  [GlobalConstants.ALL_STAGES.KilledRecord]: [GlobalConstants.ALL_STAGES.KilledRecord],
}

export const CLIENT_MOVE_RULES_MasterLead = {
  [GlobalConstants.ALL_STAGES.NewOrder]: [GlobalConstants.ALL_STAGES.NewOrder],
  [GlobalConstants.ALL_STAGES.Less3Weeks]: [GlobalConstants.ALL_STAGES.Less3Weeks],
  [GlobalConstants.ALL_STAGES.Less6Weeks]: [GlobalConstants.ALL_STAGES.Less6Weeks],
  [GlobalConstants.ALL_STAGES.Less9Weeks]: [GlobalConstants.ALL_STAGES.Less9Weeks],
  [GlobalConstants.ALL_STAGES.OverdueLess12Weeks]: [GlobalConstants.ALL_STAGES.OverdueLess12Weeks],
  [GlobalConstants.ALL_STAGES.OverdueLess16Weeks]: [GlobalConstants.ALL_STAGES.OverdueLess16Weeks],
  [GlobalConstants.ALL_STAGES.OverdueLess24Weeks]: [GlobalConstants.ALL_STAGES.OverdueLess24Weeks],
  [GlobalConstants.ALL_STAGES.WriteOff]: [GlobalConstants.ALL_STAGES.WriteOff],
  [GlobalConstants.ALL_STAGES.CashedOut]: [GlobalConstants.ALL_STAGES.CashedOut, GlobalConstants.ALL_STAGES.MeetingSet],
  [GlobalConstants.ALL_STAGES.DeliveryCheck]: [GlobalConstants.ALL_STAGES.DeliveryCheck, GlobalConstants.ALL_STAGES.RecentlyContacted, GlobalConstants.ALL_STAGES.MeetingSet],
  [GlobalConstants.ALL_STAGES.MeetingSet]: [GlobalConstants.ALL_STAGES.MeetingSet, GlobalConstants.ALL_STAGES.RecentlyContacted],

  [GlobalConstants.ALL_STAGES.ContactClientNow]: [
    GlobalConstants.ALL_STAGES.DeliveryCheck, GlobalConstants.ALL_STAGES.ContactClientNow, GlobalConstants.ALL_STAGES.EAVMEmail1, GlobalConstants.ALL_STAGES.EAVMEmail2, GlobalConstants.ALL_STAGES.RecentlyContacted, GlobalConstants.ALL_STAGES.EACurrentMeeting, GlobalConstants.ALL_STAGES.LookForClientContactInfo
    , GlobalConstants.ALL_STAGES.SeasonalCall, GlobalConstants.ALL_STAGES.VMEM1, GlobalConstants.ALL_STAGES.VMEM2, GlobalConstants.ALL_STAGES.TryNextSeason, GlobalConstants.ALL_STAGES.Reschedule, GlobalConstants.ALL_STAGES.CurrentMeeting, GlobalConstants.ALL_STAGES.OrderShipped, GlobalConstants.ALL_STAGES.DeliveryMeeting, GlobalConstants.ALL_STAGES.PassToEaToContact, GlobalConstants.ALL_STAGES.LookForContactInfo],
  [GlobalConstants.ALL_STAGES.EAVMEmail1]: [
    GlobalConstants.ALL_STAGES.DeliveryCheck, GlobalConstants.ALL_STAGES.ContactClientNow, GlobalConstants.ALL_STAGES.EAVMEmail1, GlobalConstants.ALL_STAGES.EAVMEmail2, GlobalConstants.ALL_STAGES.RecentlyContacted, GlobalConstants.ALL_STAGES.EACurrentMeeting, GlobalConstants.ALL_STAGES.LookForClientContactInfo
    , GlobalConstants.ALL_STAGES.SeasonalCall, GlobalConstants.ALL_STAGES.VMEM1, GlobalConstants.ALL_STAGES.VMEM2, GlobalConstants.ALL_STAGES.TryNextSeason, GlobalConstants.ALL_STAGES.Reschedule, GlobalConstants.ALL_STAGES.CurrentMeeting, GlobalConstants.ALL_STAGES.OrderShipped, GlobalConstants.ALL_STAGES.DeliveryMeeting, GlobalConstants.ALL_STAGES.PassToEaToContact, GlobalConstants.ALL_STAGES.LookForContactInfo],
  [GlobalConstants.ALL_STAGES.EAVMEmail2]: [
    GlobalConstants.ALL_STAGES.DeliveryCheck, GlobalConstants.ALL_STAGES.ContactClientNow, GlobalConstants.ALL_STAGES.EAVMEmail1, GlobalConstants.ALL_STAGES.EAVMEmail2, GlobalConstants.ALL_STAGES.RecentlyContacted, GlobalConstants.ALL_STAGES.EACurrentMeeting, GlobalConstants.ALL_STAGES.LookForClientContactInfo
    , GlobalConstants.ALL_STAGES.SeasonalCall, GlobalConstants.ALL_STAGES.VMEM1, GlobalConstants.ALL_STAGES.VMEM2, GlobalConstants.ALL_STAGES.TryNextSeason, GlobalConstants.ALL_STAGES.Reschedule, GlobalConstants.ALL_STAGES.CurrentMeeting, GlobalConstants.ALL_STAGES.OrderShipped, GlobalConstants.ALL_STAGES.DeliveryMeeting, GlobalConstants.ALL_STAGES.PassToEaToContact, GlobalConstants.ALL_STAGES.LookForContactInfo],
  [GlobalConstants.ALL_STAGES.RecentlyContacted]: [
    GlobalConstants.ALL_STAGES.DeliveryCheck, GlobalConstants.ALL_STAGES.ContactClientNow, GlobalConstants.ALL_STAGES.EAVMEmail1, GlobalConstants.ALL_STAGES.EAVMEmail2, GlobalConstants.ALL_STAGES.RecentlyContacted, GlobalConstants.ALL_STAGES.EACurrentMeeting, GlobalConstants.ALL_STAGES.LookForClientContactInfo
    , GlobalConstants.ALL_STAGES.SeasonalCall, GlobalConstants.ALL_STAGES.VMEM1, GlobalConstants.ALL_STAGES.VMEM2, GlobalConstants.ALL_STAGES.TryNextSeason, GlobalConstants.ALL_STAGES.Reschedule, GlobalConstants.ALL_STAGES.CurrentMeeting, GlobalConstants.ALL_STAGES.OrderShipped, GlobalConstants.ALL_STAGES.DeliveryMeeting, GlobalConstants.ALL_STAGES.PassToEaToContact, GlobalConstants.ALL_STAGES.LookForContactInfo],
  [GlobalConstants.ALL_STAGES.EACurrentMeeting]: [
    GlobalConstants.ALL_STAGES.DeliveryCheck, GlobalConstants.ALL_STAGES.ContactClientNow, GlobalConstants.ALL_STAGES.EAVMEmail1, GlobalConstants.ALL_STAGES.EAVMEmail2, GlobalConstants.ALL_STAGES.RecentlyContacted, GlobalConstants.ALL_STAGES.EACurrentMeeting, GlobalConstants.ALL_STAGES.LookForClientContactInfo
    , GlobalConstants.ALL_STAGES.SeasonalCall, GlobalConstants.ALL_STAGES.VMEM1, GlobalConstants.ALL_STAGES.VMEM2, GlobalConstants.ALL_STAGES.TryNextSeason, GlobalConstants.ALL_STAGES.Reschedule, GlobalConstants.ALL_STAGES.CurrentMeeting, GlobalConstants.ALL_STAGES.OrderShipped, GlobalConstants.ALL_STAGES.DeliveryMeeting, GlobalConstants.ALL_STAGES.PassToEaToContact, GlobalConstants.ALL_STAGES.LookForContactInfo],
  [GlobalConstants.ALL_STAGES.LookForClientContactInfo]: [
    GlobalConstants.ALL_STAGES.DeliveryCheck, GlobalConstants.ALL_STAGES.ContactClientNow, GlobalConstants.ALL_STAGES.EAVMEmail1, GlobalConstants.ALL_STAGES.EAVMEmail2, GlobalConstants.ALL_STAGES.RecentlyContacted, GlobalConstants.ALL_STAGES.EACurrentMeeting, GlobalConstants.ALL_STAGES.LookForClientContactInfo
    , GlobalConstants.ALL_STAGES.SeasonalCall, GlobalConstants.ALL_STAGES.VMEM1, GlobalConstants.ALL_STAGES.VMEM2, GlobalConstants.ALL_STAGES.TryNextSeason, GlobalConstants.ALL_STAGES.Reschedule, GlobalConstants.ALL_STAGES.CurrentMeeting, GlobalConstants.ALL_STAGES.OrderShipped, GlobalConstants.ALL_STAGES.DeliveryMeeting, GlobalConstants.ALL_STAGES.PassToEaToContact, GlobalConstants.ALL_STAGES.LookForContactInfo],
  [GlobalConstants.ALL_STAGES.KilledRecord]: [GlobalConstants.ALL_STAGES.KilledRecord]
}

export const MEETING_CONFIRM_MOVE_RULES = {
  [GlobalConstants.ALL_STAGES.MeetingToConfirm]: [GlobalConstants.ALL_STAGES.MeetingToConfirm, GlobalConstants.ALL_STAGES.CMVMEM1, GlobalConstants.ALL_STAGES.CMVMEM2, GlobalConstants.ALL_STAGES.CMVMEM3, GlobalConstants.ALL_STAGES.CMCancel, GlobalConstants.ALL_STAGES.CMReschedule, GlobalConstants.ALL_STAGES.Confirmed],
  [GlobalConstants.ALL_STAGES.CMVMEM1]: [GlobalConstants.ALL_STAGES.MeetingToConfirm, GlobalConstants.ALL_STAGES.CMVMEM1, GlobalConstants.ALL_STAGES.CMVMEM2, GlobalConstants.ALL_STAGES.CMVMEM3, GlobalConstants.ALL_STAGES.CMCancel, GlobalConstants.ALL_STAGES.CMReschedule, GlobalConstants.ALL_STAGES.Confirmed],
  [GlobalConstants.ALL_STAGES.CMVMEM2]: [GlobalConstants.ALL_STAGES.MeetingToConfirm, GlobalConstants.ALL_STAGES.CMVMEM1, GlobalConstants.ALL_STAGES.CMVMEM2, GlobalConstants.ALL_STAGES.CMVMEM3, GlobalConstants.ALL_STAGES.CMCancel, GlobalConstants.ALL_STAGES.CMReschedule, GlobalConstants.ALL_STAGES.Confirmed],
  [GlobalConstants.ALL_STAGES.CMVMEM3]: [GlobalConstants.ALL_STAGES.MeetingToConfirm, GlobalConstants.ALL_STAGES.CMVMEM1, GlobalConstants.ALL_STAGES.CMVMEM2, GlobalConstants.ALL_STAGES.CMVMEM3, GlobalConstants.ALL_STAGES.CMCancel, GlobalConstants.ALL_STAGES.CMReschedule, GlobalConstants.ALL_STAGES.Confirmed],
  [GlobalConstants.ALL_STAGES.CMCancel]: [GlobalConstants.ALL_STAGES.MeetingToConfirm, GlobalConstants.ALL_STAGES.CMVMEM1, GlobalConstants.ALL_STAGES.CMVMEM2, GlobalConstants.ALL_STAGES.CMVMEM3, GlobalConstants.ALL_STAGES.CMCancel, GlobalConstants.ALL_STAGES.CMReschedule, GlobalConstants.ALL_STAGES.Confirmed],
  [GlobalConstants.ALL_STAGES.CMReschedule]: [GlobalConstants.ALL_STAGES.MeetingToConfirm, GlobalConstants.ALL_STAGES.CMVMEM1, GlobalConstants.ALL_STAGES.CMVMEM2, GlobalConstants.ALL_STAGES.CMVMEM3, GlobalConstants.ALL_STAGES.CMCancel, GlobalConstants.ALL_STAGES.CMReschedule, GlobalConstants.ALL_STAGES.CMRescheduleFollowUp, GlobalConstants.ALL_STAGES.Confirmed],
  [GlobalConstants.ALL_STAGES.CMRescheduleFollowUp]: [GlobalConstants.ALL_STAGES.MeetingToConfirm, GlobalConstants.ALL_STAGES.CMVMEM1, GlobalConstants.ALL_STAGES.CMVMEM2, GlobalConstants.ALL_STAGES.CMVMEM3, GlobalConstants.ALL_STAGES.CMCancel, GlobalConstants.ALL_STAGES.CMReschedule, GlobalConstants.ALL_STAGES.CMRescheduleFollowUp, GlobalConstants.ALL_STAGES.Confirmed],
  [GlobalConstants.ALL_STAGES.Confirmed]: [GlobalConstants.ALL_STAGES.MeetingToConfirm, GlobalConstants.ALL_STAGES.CMVMEM1, GlobalConstants.ALL_STAGES.CMVMEM2, GlobalConstants.ALL_STAGES.CMVMEM3, GlobalConstants.ALL_STAGES.CMCancel, GlobalConstants.ALL_STAGES.CMReschedule, GlobalConstants.ALL_STAGES.Confirmed],
  [GlobalConstants.ALL_STAGES.DraftOrder]: [GlobalConstants.ALL_STAGES.DraftOrder, GlobalConstants.ALL_STAGES.DraftConfirmed],
  [GlobalConstants.ALL_STAGES.DraftConfirmed]: [GlobalConstants.ALL_STAGES.DraftConfirmed],
}

export const CAMPAIGN_EXPORT_STAGES: number[] = [GlobalConstants.ALL_STAGES.NoPhone, GlobalConstants.ALL_STAGES.Cold, GlobalConstants.ALL_STAGES.Linkedin,
GlobalConstants.ALL_STAGES.Email1, GlobalConstants.ALL_STAGES.Email2, GlobalConstants.ALL_STAGES.Email3];
export const DIRECTMAIL_EXPORT_STAGES: number[] = [GlobalConstants.ALL_STAGES.Cold];

export const STAGES_COLORS_DICT = {
  'NoPh#': 'yellow',
  'Kill': 'black',
  'NG': 'red',
  'DQ': 'red',
  'NA': 'yellow',
  'Email1': 'limegreen',
  'Email2': 'limegreen',
  'Email3': 'limegreen',
  'DM': 'limegreen',
  'SM': 'limegreen',
  'Referral': 'limegreen',
  'SM*': 'limegreen',
  'Float': 'limegreen',
  '1VM': 'limegreen',
  '2VM': 'limegreen',
  '3VM': 'limegreen',
  'Resched': 'limegreen',
  'Meeting': 'limegreen',
  'Client Sold': 'limegreen',
  'Cancel': 'limegreen',
  'MetNG': 'limegreen',
};

export const STAGES_FOR_ALL_PIPELINES = [
  {
    'id': 33,
    'order_nr': 1,
    'name': 'NoPh#',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 18,
    'order_nr': 2,
    'name': 'Kill',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 51,
    'order_nr': 3,
    'name': 'To Contact',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 52,
    'order_nr': 4,
    'name': '1st Call',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 53,
    'order_nr': 5,
    'name': '1st Waiting',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 54,
    'order_nr': 6,
    'name': '1st Call Scheduled',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 55,
    'order_nr': 7,
    'name': '2nd Call',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 56,
    'order_nr': 8,
    'name': '2nd Waiting',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 57,
    'order_nr': 9,
    'name': '2nd Call Scheduled',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 58,
    'order_nr': 10,
    'name': '3rd Call',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 59,
    'order_nr': 11,
    'name': '3rd Waiting',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 60,
    'order_nr': 14,
    'name': '3rd Call Scheduled',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 61,
    'order_nr': 15,
    'name': 'Booked',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 62,
    'order_nr': 16,
    'name': 'Repeat Event',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 25,
    'order_nr': 17,
    'name': 'NG',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 26,
    'order_nr': 18,
    'name': 'DQ',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 22,
    'order_nr': 19,
    'name': 'NA',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 39,
    'name': 'NA-Cancel',
    'order_nr': 20,
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 40,
    'name': 'NA-MetNG',
    'order_nr': 21,
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 34,
    'order_nr': 22,
    'name': 'Cold LOOKUP',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 35,
    'order_nr': 23,
    'name': 'Linkedin LOOKUP',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 1,
    'order_nr': 24,
    'name': 'Cold',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 89,
    'order_nr': 25,
    'name': 'No Email Found',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 64,
    'order_nr': 26,
    'name': 'Email Found',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 2,
    'order_nr': 27,
    'name': 'Linkedin',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 115,
    'order_nr': 28,
    'name': 'Same Building',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 20,
    'order_nr': 29,
    'name': 'Email1',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 30,
    'order_nr': 30,
    'name': 'Email2',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 31,
    'order_nr': 31,
    'name': 'Email3',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 48,
    'order_nr': 32,
    'name': 'Add1',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 49,
    'order_nr': 33,
    'name': 'Add2',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 46,
    'order_nr': 34,
    'name': 'DM',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 44,
    'order_nr': 35,
    'name': 'SM',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 45,
    'order_nr': 36,
    'name': 'SM*',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 6,
    'order_nr': 37,
    'name': '1VM',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 23,
    'order_nr': 38,
    'name': '2VM',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 24,
    'order_nr': 39,
    'name': '3VM',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 3,
    'order_nr': 40,
    'name': 'Referral',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 99,
    'order_nr': 41,
    'name': 'RefVM1',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 100,
    'order_nr': 42,
    'name': 'RefVM2',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 101,
    'order_nr': 43,
    'name': 'RefVM3',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 4,
    'order_nr': 44,
    'name': 'Float',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 47,
    'order_nr': 45,
    'name': 'RefAsk',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 17,
    'order_nr': 46,
    'name': 'Resched',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 16,
    'order_nr': 47,
    'name': 'Meeting',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 41,
    'order_nr': 48,
    'name': 'Client Sold',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 37,
    'order_nr': 49,
    'name': 'Cancel',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 36,
    'order_nr': 50,
    'name': 'MetNG',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 73,
    'order_nr': 51,
    'name': 'New Candidates',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 124,
    'order_nr': 52,
    'name': 'Recruiter NG (w/o\u00A0video)',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 125,
    'order_nr': 53,
    'name': 'Video Applications',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 82,
    'order_nr': 54,
    'name': 'Recruiter NG',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 76,
    'order_nr': 55,
    'name': 'Set Interview 1',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 77,
    'order_nr': 56,
    'name': 'Interview 1',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 130,
    'order_nr': 57,
    'name': 'Recruiter NG (1st\u00A0interview)',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 127,
    'order_nr': 58,
    'name': 'Set Interview 2',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 78,
    'order_nr': 59,
    'name': 'Interview 2',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 131,
    'order_nr': 60,
    'name': 'Recruiter NG (2nd\u00A0interview)',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 129,
    'order_nr': 61,
    'name': 'Follow Day',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 80,
    'order_nr': 62,
    'name': 'Offer Sent',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 83,
    'order_nr': 63,
    'name': 'Hired',
    'pipeline_id': Pipelines.NewProspects
  },
  {
    'id': 8,
    'order_nr': 401,
    'name': 'Contact Client Now',
    'pipeline_id': Pipelines.Clients
  },
  {
    'id': 9,
    'order_nr': 402,
    'name': 'EA VM/Email1',
    'pipeline_id': Pipelines.Clients
  },
  {
    'id': 28,
    'order_nr': 403,
    'name': 'EA VM/Email2',
    'pipeline_id': Pipelines.Clients
  },
  {
    'id': 38,
    'order_nr': 404,
    'name': 'Recently Contacted',
    'pipeline_id': Pipelines.Clients
  },
  {
    'id': 21,
    'order_nr': 405,
    'name': 'EA Current Meeting',
    'pipeline_id': Pipelines.Clients
  },
  {
    'id': 50,
    'order_nr': 406,
    'name': 'Look for Client Contact Info',
    'pipeline_id': Pipelines.Clients
  },
  {
    'id': 14,
    'order_nr': 407,
    'name': 'Killed Record',
    'pipeline_id': Pipelines.Clients
  },
  {
    'id': 117,
    'order_nr': 701,
    'name': 'Meeting Set',
    'pipeline_id': Pipelines.OpenOrders
  },
  {
    'id': 42,
    'order_nr': 702,
    'name': 'Delivery Check',
    'pipeline_id': Pipelines.OpenOrders
  },
  {
    'id': 27,
    'order_nr': 703,
    'name': 'Recently Cashed out',
    'pipeline_id': Pipelines.OpenOrders
  },
  {
    'id': 118,
    'order_nr': 704,
    'name': 'Write Off',
    'pipeline_id': Pipelines.OpenOrders
  },
  {
    'id': 119,
    'order_nr': 705,
    'name': 'Overdue <24 weeks',
    'pipeline_id': Pipelines.OpenOrders
  },
  {
    'id': 120,
    'order_nr': 706,
    'name': 'Overdue <16 weeks',
    'pipeline_id': Pipelines.OpenOrders
  },
  {
    'id': 121,
    'order_nr': 707,
    'name': 'Overdue <12 weeks',
    'pipeline_id': Pipelines.OpenOrders
  },
  {
    'id': 122,
    'order_nr': 708,
    'name': '<9 weeks',
    'pipeline_id': Pipelines.OpenOrders
  },
  {
    'id': 123,
    'order_nr': 709,
    'name': '<6 weeks',
    'pipeline_id': Pipelines.OpenOrders
  },
  {
    'id': 32,
    'order_nr': 710,
    'name': '<3 weeks',
    'pipeline_id': Pipelines.OpenOrders
  },
  {
    'id': 112,
    'order_nr': 711,
    'name': 'New Order',
    'pipeline_id': Pipelines.OpenOrders
  },
  {
    'id': 66,
    'order_nr': 1201,
    'name': 'Seasonal call',
    'pipeline_id': Pipelines.ClothierContactClients
  },
  {
    'id': 67,
    'order_nr': 1202,
    'name': 'VM/EM1',
    'pipeline_id': Pipelines.ClothierContactClients
  },
  {
    'id': 68,
    'order_nr': 1203,
    'name': 'VM/EM2',
    'pipeline_id': Pipelines.ClothierContactClients
  },
  {
    'id': 70,
    'order_nr': 1204,
    'name': 'Try next season',
    'pipeline_id': Pipelines.ClothierContactClients
  },
  {
    'id': 65,
    'order_nr': 1205,
    'name': 'Reschedule',
    'pipeline_id': Pipelines.ClothierContactClients
  },
  {
    'id': 71,
    'order_nr': 1206,
    'name': 'Current Meeting',
    'pipeline_id': Pipelines.ClothierContactClients
  },
  {
    'id': 104,
    'order_nr': 1207,
    'name': 'Order Shipped',
    'pipeline_id': Pipelines.ClothierContactClients
  },

  {
    'id': 108,
    'order_nr': 1208,
    'name': 'Delivery Meeting',
    'pipeline_id': Pipelines.ClothierContactClients
  },
  {
    'id': 109,
    'order_nr': 1209,
    'name': 'In Alterations',
    'pipeline_id': Pipelines.ClothierContactClients
  },
  {
    'id': 103,
    'order_nr': 1210,
    'name': 'Pass to EA to Contact',
    'pipeline_id': Pipelines.ClothierContactClients
  },
  {
    'id': 86,
    'order_nr': 1211,
    'name': 'Look For Contact Info',
    'pipeline_id': Pipelines.ClothierContactClients
  },
  {
    'id': 92,
    'order_nr': 1501,
    'name': 'Meeting To Confirm',
    'pipeline_id': Pipelines.ClothierMeetingConfirm
  },
  {
    'id': 93,
    'order_nr': 1502,
    'name': 'CM VM/EM1',
    'pipeline_id': Pipelines.ClothierMeetingConfirm
  },
  {
    'id': 94,
    'order_nr': 1503,
    'name': 'CM VM/EM2',
    'pipeline_id': Pipelines.ClothierMeetingConfirm
  },
  {
    'id': 95,
    'order_nr': 1504,
    'name': 'CM VM/EM3',
    'pipeline_id': Pipelines.ClothierMeetingConfirm
  },
  {
    'id': 96,
    'order_nr': 1505,
    'name': 'CM Cancel',
    'pipeline_id': Pipelines.ClothierMeetingConfirm
  },
  {
    'id': 97,
    'order_nr': 1506,
    'name': 'CM Reschedule',
    'pipeline_id': Pipelines.ClothierMeetingConfirm
  },
  {
    'id': 102,
    'order_nr': 1507,
    'name': 'CM Reschedule Follow Up',
    'pipeline_id': Pipelines.ClothierMeetingConfirm
  },
  {
    'id': 98,
    'order_nr': 1508,
    'name': 'Confirmed',
    'pipeline_id': Pipelines.ClothierMeetingConfirm
  },
  {
    'id': 113,
    'order_nr': 1509,
    'name': 'Draft Order',
    'pipeline_id': Pipelines.ClothierMeetingConfirm
  },
  {
    'id': 114,
    'order_nr': 1510,
    'name': 'Draft Confirmed',
    'pipeline_id': Pipelines.ClothierMeetingConfirm
  },
]

export const PROSPECTS_BY_ROLES = {
  'Admin': ['NoPh#', 'Kill', 'NG', 'DQ', 'NA', 'Cold', 'Linkedin', 'Same Building', 'SM', 'Referral', 'RefVM1', 'RefVM2', 'RefVM3', 'SM*', 'Float', '1VM', '2VM', '3VM',
    'Resched', 'Meeting', 'Client Sold', 'Cancel', 'MetNG'],
  'MasterLead': ['NoPh#', 'Kill', 'NG', 'DQ', 'NA', 'Cold', 'Linkedin', 'Same Building', 'DM', 'SM',
    'Referral', 'RefVM1', 'RefVM2', 'RefVM3', 'SM*', 'Float', '1VM', '2VM', '3VM', 'Resched', 'Meeting', 'Client Sold', 'Cancel', 'MetNG'],
  'MissedAccount': ['NoPh#', 'Kill', 'NG', 'DQ', 'NA-Cancel', 'NA-MetNG', '1VM', '2VM', '3VM', 'Meeting', 'Cancel', 'MetNG'],
  'CampaignAccount': ['NoPh#', 'Kill', 'NG', 'Cold', 'Linkedin', 'Same Building', 'Email1', 'Email2', 'Email3', 'Add1', 'Add2', 'SM',
    'SM*', 'RefAsk', 'Meeting'],
  'DirectMailAccount': ['NoPh#', 'Kill', 'NG', 'DQ', 'NA', 'Cold', 'Linkedin', 'Same Building', 'DM', '1VM', '2VM', '3VM', 'Meeting'],
  'SystemAccount': ['NoPh#', 'Kill', 'NG', 'DQ', 'NA', 'Cold', 'Linkedin', 'Same Building', 'SM', 'Referral', 'RefVM1', 'RefVM2', 'RefVM3', 'SM*', 'Float', '1VM', '2VM', '3VM',
    'Resched', 'Meeting', 'Client Sold', 'Cancel', 'MetNG'],
  'User': ['NoPh#', 'Kill', 'NG', 'DQ', 'NA', 'Cold', 'Linkedin', 'Same Building', 'SM', 'Referral', 'SM*', 'Float', '1VM', '2VM', '3VM', 'RefVM1', 'RefVM2', 'RefVM3',
    'Meeting', 'Client Sold', 'Cancel', 'MetNG'],
  'Clothier': ['NoPh#', 'Kill', 'NG', 'DQ', 'NA', 'Cold', 'Linkedin', 'Same Building', 'SM', 'Referral', 'SM*', 'Float', '1VM', '2VM', '3VM', 'RefVM1', 'RefVM2', 'RefVM3',
    'Resched', 'Meeting', 'Client Sold', 'Cancel', 'MetNG'],
  'BB': ['NoPh#', 'Kill', 'NG', 'DQ', 'NA', 'Cold', 'Linkedin', 'Same Building', 'SM', 'Referral', 'SM*', 'Float', '1VM', '2VM', '3VM', 'RefVM1', 'RefVM2', 'RefVM3',
    'Meeting'],
  'Conference': ['To Contact', '1st Call', '1st Waiting', '1st Call Scheduled', '2nd Call', '2nd Waiting',
    '2nd Call Scheduled', '3rd Call', '3rd Waiting', '3rd Call Scheduled', 'Booked', 'Repeat Event', 'NG'],
  'EA': [],
  'EaCaller': [],
  'EmailMarketing': ['NoPh#', 'Kill', 'NG', 'Cold', 'No Email Found', 'Email Found', 'Linkedin', 'Same Building', 'Email1', 'Email2', 'Email3', 'Add1', 'Add2', 'DM', 'SM', 'SM*', 'RefAsk'],
  'EventAccount': ['NoPh#', 'Kill', 'NG', 'DQ', 'NA', 'Cold', 'Linkedin', 'Same Building', 'SM', 'Referral', 'RefVM1', 'RefVM2', 'RefVM3', 'SM*', 'Float', '1VM', '2VM', '3VM',
    'Meeting', 'Client Sold', 'Cancel', 'MetNG'],
  'Recruiter': ['New Candidates', 'Recruiter NG (w/o\u00A0video)', 'Video Applications', 'Recruiter NG', 'Set Interview 1', 'Interview 1', 'Recruiter NG (1st\u00A0interview)', 'Set Interview 2', 'Interview 2', 'Recruiter NG (2nd\u00A0interview)', 'Follow Day', 'Offer Sent', 'Hired']
};

export const CLIENTS_BY_ROLES = {
  'Admin': ['New Order', '<3 weeks', '<6 weeks', '<9 weeks', 'Overdue <12 weeks', 'Overdue <16 weeks', 'Overdue <24 weeks', 'Write Off', 'Recently Cashed out', 'Delivery Check', 'Meeting Set',
    'Contact Client Now', 'EA VM/Email1', 'EA VM/Email2', 'Recently Contacted', 'EA Current Meeting', 'Look for Client Contact Info'],
  'MasterLead': ['New Order', '<3 weeks', '<6 weeks', '<9 weeks', 'Overdue <12 weeks', 'Overdue <16 weeks', 'Overdue <24 weeks', 'Write Off', 'Recently Cashed out', 'Delivery Check', 'Meeting Set',
    'Contact Client Now', 'EA VM/Email1', 'EA VM/Email2', 'Recently Contacted', 'EA Current Meeting', 'Look for Client Contact Info'],
  'MissedAccount': ['New Order', '<3 weeks', '<6 weeks', '<9 weeks', 'Overdue <12 weeks', 'Overdue <16 weeks', 'Overdue <24 weeks', 'Write Off', 'Recently Cashed out', 'Delivery Check', 'Meeting Set',
    'Contact Client Now', 'EA VM/Email1', 'EA VM/Email2', 'Recently Contacted', 'EA Current Meeting', 'Look for Client Contact Info'],
  'CampaignAccount': [],
  'DirectMailAccount': [],
  'SystemAccount': ['New Order', '<3 weeks', '<6 weeks', '<9 weeks', 'Overdue <12 weeks', 'Overdue <16 weeks', 'Overdue <24 weeks', 'Write Off', 'Recently Cashed out', 'Delivery Check', 'Meeting Set',
    'Contact Client Now', 'EA VM/Email1', 'EA VM/Email2', 'Recently Contacted', 'EA Current Meeting', 'Look for Client Contact Info'],
  'User': ['New Order', '<3 weeks', '<6 weeks', '<9 weeks', 'Overdue <12 weeks', 'Overdue <16 weeks', 'Overdue <24 weeks', 'Write Off', 'Recently Cashed out', 'Delivery Check', 'Meeting Set',
    'Contact Client Now', 'EA VM/Email1', 'EA VM/Email2', 'Recently Contacted', 'EA Current Meeting', 'Look for Client Contact Info'],
  'Clothier': ['New Order', '<3 weeks', '<6 weeks', '<9 weeks', 'Overdue <12 weeks', 'Overdue <16 weeks', 'Overdue <24 weeks', 'Write Off', 'Recently Cashed out', 'Delivery Check', 'Meeting Set',
    'Contact Client Now', 'EA VM/Email1', 'EA VM/Email2', 'Recently Contacted', 'EA Current Meeting', 'Look for Client Contact Info'],
  'BB': [],
  'Conference': [],
  'EA': ['New Order', '<3 weeks', '<6 weeks', '<9 weeks', 'Overdue <12 weeks', 'Overdue <16 weeks', 'Overdue <24 weeks', 'Write Off', 'Recently Cashed out', 'Delivery Check', 'Meeting Set'],
  'EaCaller': ['Contact Client Now', 'EA VM/Email1', 'EA VM/Email2', 'Recently Contacted', 'EA Current Meeting', 'Look for Client Contact Info'],
  'EmailMarketing': [],
  'EventAccount': [],
  'Recruiter': [],
};

export const CLOTHIERCONTACT_CLIENTS_BY_ROLES = {
  'Admin': ['Seasonal call', 'VM/EM1', 'VM/EM2', 'Try next season', 'Reschedule', 'Current Meeting', 'Order Shipped', 'Delivery Meeting', 'In Alterations', 'Look For Contact Info', 'Pass to EA to Contact'],
  'MasterLead': ['Seasonal call', 'VM/EM1', 'VM/EM2', 'Try next season', 'Reschedule', 'Current Meeting', 'Order Shipped', 'Delivery Meeting', 'In Alterations', 'Look For Contact Info', 'Pass to EA to Contact'],
  'MissedAccount': [],
  'CampaignAccount': [],
  'DirectMailAccount': [],
  'SystemAccount': ['Seasonal call', 'VM/EM1', 'VM/EM2', 'Try next season', 'Reschedule', 'Current Meeting', 'Order Shipped', 'Delivery Meeting', 'In Alterations', 'Look For Contact Info', 'Pass to EA to Contact'],
  'User': ['Seasonal call', 'VM/EM1', 'VM/EM2', 'Try next season', 'Reschedule', 'Current Meeting', 'Order Shipped', 'Delivery Meeting', 'In Alterations', 'Look For Contact Info', 'Pass to EA to Contact'],
  'Clothier': ['Seasonal call', 'VM/EM1', 'VM/EM2', 'Try next season', 'Reschedule', 'Current Meeting', 'Order Shipped', 'Delivery Meeting', 'In Alterations', 'Look For Contact Info', 'Pass to EA to Contact'],
  'BB': [],
  'Conference': [],
  'EA': [],
  'EaCaller': [],
  'EmailMarketing': [],
  'EventAccount': [],
  'Recruiter': [],
};

export const MEETING_CONFIRM_BY_ROLES = {
  'Admin': ['Meeting To Confirm', 'CM VM/EM1', 'CM VM/EM2', 'CM VM/EM3', 'CM Cancel', 'CM Reschedule', 'CM Reschedule Follow Up', 'Confirmed', 'Draft Order', 'Draft Confirmed'],
  'MasterLead': ['Meeting To Confirm', 'CM VM/EM1', 'CM VM/EM2', 'CM VM/EM3', 'CM Cancel', 'CM Reschedule', 'CM Reschedule Follow Up', 'Confirmed', 'Draft Order', 'Draft Confirmed'],
  'MissedAccount': [],
  'CampaignAccount': [],
  'DirectMailAccount': [],
  'SystemAccount': [],
  'User': [],
  'Clothier': ['Meeting To Confirm', 'CM VM/EM1', 'CM VM/EM2', 'CM VM/EM3', 'CM Cancel', 'CM Reschedule', 'CM Reschedule Follow Up', 'Confirmed', 'Draft Order', 'Draft Confirmed'],
  'BB': [],
  'Conference': [],
  'EA': [],
  'EaCaller': [],
  'EmailMarketing': [],
  'EventAccount': [],
  'Recruiter': [],
};

export const STAGES_BY_ROLES_VISIBILITY = {
  'Admin': [...PROSPECTS_BY_ROLES.Admin, ...CLIENTS_BY_ROLES.Admin, ...CLOTHIERCONTACT_CLIENTS_BY_ROLES.Admin, ...MEETING_CONFIRM_BY_ROLES.Admin],
  'MasterLead': [...PROSPECTS_BY_ROLES.MasterLead, ...CLIENTS_BY_ROLES.MasterLead, ...CLOTHIERCONTACT_CLIENTS_BY_ROLES.MasterLead, ...MEETING_CONFIRM_BY_ROLES.MasterLead],
  'MissedAccount': [...PROSPECTS_BY_ROLES.MissedAccount, ...CLIENTS_BY_ROLES.MissedAccount, ...CLOTHIERCONTACT_CLIENTS_BY_ROLES.MissedAccount, ...MEETING_CONFIRM_BY_ROLES.MissedAccount],
  'CampaignAccount': [...PROSPECTS_BY_ROLES.CampaignAccount, ...CLIENTS_BY_ROLES.CampaignAccount, ...CLOTHIERCONTACT_CLIENTS_BY_ROLES.CampaignAccount, ...MEETING_CONFIRM_BY_ROLES.CampaignAccount],
  'DirectMailAccount': [...PROSPECTS_BY_ROLES.DirectMailAccount, ...CLIENTS_BY_ROLES.DirectMailAccount, ...CLOTHIERCONTACT_CLIENTS_BY_ROLES.DirectMailAccount, ...MEETING_CONFIRM_BY_ROLES.DirectMailAccount],
  'SystemAccount': [...PROSPECTS_BY_ROLES.SystemAccount, ...CLIENTS_BY_ROLES.SystemAccount, ...CLOTHIERCONTACT_CLIENTS_BY_ROLES.SystemAccount, ...MEETING_CONFIRM_BY_ROLES.SystemAccount],
  'User': [...PROSPECTS_BY_ROLES.User, ...CLIENTS_BY_ROLES.User, ...CLOTHIERCONTACT_CLIENTS_BY_ROLES.User, ...MEETING_CONFIRM_BY_ROLES.User],
  'Clothier': [...PROSPECTS_BY_ROLES.Clothier, ...CLIENTS_BY_ROLES.Clothier, ...CLOTHIERCONTACT_CLIENTS_BY_ROLES.Clothier, ...MEETING_CONFIRM_BY_ROLES.Clothier],
  'BB': [...PROSPECTS_BY_ROLES.BB, ...CLIENTS_BY_ROLES.BB, ...CLOTHIERCONTACT_CLIENTS_BY_ROLES.BB, ...MEETING_CONFIRM_BY_ROLES.BB],
  'Conference': [...PROSPECTS_BY_ROLES.Conference, ...CLIENTS_BY_ROLES.Conference, ...CLOTHIERCONTACT_CLIENTS_BY_ROLES.Conference, ...MEETING_CONFIRM_BY_ROLES.Conference],
  'EA': [...PROSPECTS_BY_ROLES.EA, ...CLIENTS_BY_ROLES.EA, ...CLOTHIERCONTACT_CLIENTS_BY_ROLES.EA, ...MEETING_CONFIRM_BY_ROLES.EA],
  'EaCaller': [...PROSPECTS_BY_ROLES.EaCaller, ...CLIENTS_BY_ROLES.EaCaller, ...CLOTHIERCONTACT_CLIENTS_BY_ROLES.EaCaller, ...MEETING_CONFIRM_BY_ROLES.EaCaller],
  'EmailMarketing': [...PROSPECTS_BY_ROLES.EmailMarketing, ...CLIENTS_BY_ROLES.EmailMarketing, ...CLOTHIERCONTACT_CLIENTS_BY_ROLES.EmailMarketing, ...MEETING_CONFIRM_BY_ROLES.EmailMarketing],
  'EventAccount': [...PROSPECTS_BY_ROLES.EventAccount, ...CLIENTS_BY_ROLES.EventAccount, ...CLOTHIERCONTACT_CLIENTS_BY_ROLES.EventAccount, ...MEETING_CONFIRM_BY_ROLES.EventAccount],
  'Recruiter': [...PROSPECTS_BY_ROLES.Recruiter, ...CLIENTS_BY_ROLES.Recruiter, ...CLOTHIERCONTACT_CLIENTS_BY_ROLES.Recruiter, ...MEETING_CONFIRM_BY_ROLES.Recruiter]
}
