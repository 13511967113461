<div class="userListModal" [@moveDealsState]="moveDealsState">
  <div class="owner">
    <span class="title">Move leads (max {{totalCount}})</span>
    @for (transf of multitransfer; track transf; let i = $index) {
    <div class="multi-transfer">
      @if (transferForm[transf.Id]) {
      <div class="select display-delete">
        <mat-form-field class="form-field" [formGroup]="transferForm[transf.Id]">
          <mat-select (selectionChange)="transferToChanged($event.value, transf)" [(value)]="transf.OwnerId"
            formControlName="owner">
            @for (item of (users); track item) {
            <mat-option [value]="item.id">{{item.name}}</mat-option>
            }
          </mat-select>
        </mat-form-field>
        @if (i > 0) {
        <div class="delete" (click)="deleteOwner(i)">
          <i class="material-icons">delete</i>
        </div>
        }
      </div>
      }
      <div class="count-deals">
        <div class="md-form">
          <!-- <input type="number" class="form-control" mdbInputDirective placeholder="Amount of deals"
            [(ngModel)]="transf.Limit" (ngModelChange)="multitransfersDataChanged()" min="1" digitOnly>  TODO: check multitransfersDataChanged-->
          <input type="number" (change)="validate()" class="form-control" mdbInputDirective
            placeholder="Amount of deals" [(ngModel)]="transf.Limit" min="1" digitOnly>
        </div>
      </div>
      @if (transferForm[transf.Id] && currentUser?.is_admin === true) {
      <div class="select">
        <div class="to-stage">To Stage</div>
        <mat-form-field class="form-field" [formGroup]="transferForm[transf.Id]">
          <mat-select [(ngModel)]="transf.StageTo" (click)="openStageSelect(transf.StageTo)" formControlName="stage">
            @for (pipeline of transf.StatusesByPipelines; track pipeline) {
            <mat-optgroup [label]="pipeline.name">
              @for (stageItem of pipeline.stages; track stageItem) {
              <mat-option [value]="stageItem.id" (click)="selectStageToMove(stageItem, transf)">
                {{ stageItem.name }}
              </mat-option>
              }
            </mat-optgroup>
            }
          </mat-select>
        </mat-form-field>
      </div>
      }
    </div>
    }
    @if (multitransfer.length < maxCountOwners) { <div class="add-one-more" (click)="addOneMoreOwner()">
      Add one more owner
  </div>
  }
  <div class="buttons">
    <button type="button" class="btn btn-danger waves-light cancel" (click)="closeModal()">Cancel</button>
    <button type="button" class="btn btn-success waves-light update"
      [disabled]="!currentDisplayedDealsFilter || isTransfersValid == false" (click)="updateOwner()">Save</button>
  </div>
</div>
</div>
<pd-custom-kill-record-modal #killRecordModal [dialogOptions]="killRecordOptions"
  (cancelBtn)="cancelMoveRecord()"></pd-custom-kill-record-modal>