<div class="genders">
    <form [formGroup]="genderForm">
        <div class="location">
            <pd-custom-select-filter #locationFilter (loadDataEvent)="getLocationsFilterData($event)"
                (autoCompleteEvent)="getLocationsAutocomplete($event)" (sendData)="getGenderLocations($event)"
                [isGenderInfo]="isGenderInfo" [dataLabel]="locationsLabel"
                [filterType]="filterTypeLocation"></pd-custom-select-filter>
        </div>
        <div style="padding-right: 5px;">
            <input type="text" [(ngModel)]="genderCount" placeholder="Count of person"
                formControlName="genderCountOfPerson">
            @if (genderForm.get('genderCountOfPerson').invalid && genderForm.get('genderCountOfPerson').touched) {
            <p>
                Count of person is required</p>
            }
        </div>
        <div class="submit">
            <button class="btn btn-success waves-light"
                [disabled]="genderForm.get('genderCountOfPerson').invalid || genderLocation.length === 0 && !isSelectAllLocationsGender"
                (click)="submitGender()">Submit</button>
            <button class="btn btn-danger waves-light" [routerLink]="['/', tasksRoute]">Cancel</button>
        </div>
    </form>
</div>
<div class="update-info">
    {{updateGenderInfo}}
</div>