<div class="header-users">
  <h2 class="header">Users</h2>
  <div class="left">
    @if (allowToUpdateUsers) {
    <button type="button" class="btn btn-success btn-md waves-light users-button" mdbRippleRadius
      (click)="routToNewUser()">
      <div>Add User</div>
    </button>
    }
  </div>
</div>

@if (!isShowError) {
<div class="content-wrapper">
  <div class="content">
    @if (allowToUpdateUsers) {
    <div class="users-table">
      <div class="users-table-header row">
        <div class="col-2 cell">Name</div>
        <div class="col-4 cell">Email</div>
        <div class="col-1 cell">Close access</div>
        <div class="col-2 cell">Change password</div>
        <div class="col-2 cell">Change User</div>
        <div class="col-1 cell">Open CC & SB</div>
      </div>
      @for (user of allUsers; track user) {
      <div class="users-table-content row">
        <div class="col-2 cell">{{user.name}}</div>
        <div class="col-4 cell">{{user.email}}</div>
        <div class="col-1 cell action-cell" [ngClass]="{ 'disabled': !getIsEnabledDeleting(user) }"
          (click)="getIsEnabledDeleting(user) ? closeOutUser(user) : $event.stopPropagation();">
          <i class="material-icons">present_to_all</i>
        </div>
        <div class="col-2 cell action-cell">
          <button type="button" class="btn btn-md users-button" mdbRippleRadius (click)="changeUserPassword(user)">
            <div style="color:black">Change</div>
          </button>
        </div>
        <div class="col-2 cell action-cell">
          <button type="button" class="btn btn-md users-button" mdbRippleRadius (click)="updateUser(user)"
            [disabled]="!allowToUpdateUsers">
            <div style="color:black">Update user</div>
          </button>
        </div>
        <div class="col-1 cell action-cell" (click)="openCCModal(user); $event.stopPropagation();">
          <i class="material-icons"
            [ngStyle]="{ 'color': (calcCCExpired(user.open_cc_date) ? '#27ff00' : 'black') }">present_to_all</i>
        </div>
      </div>
      }
    </div>
    }
  </div>
</div>
}

<div class="usersListModal" [@modalState]="menuState">
  <div class="users">
    <div class="title">
      Close Out Users
    </div>
    <div class="select">
      <span class="label">Where to move leads:</span>
      <mat-form-field>
        <mat-select [(value)]="ownerId">
          @for (item of usersList; track item) {
          <mat-option [value]="item.id">{{item.name}}</mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
    <div class="cancel">
      <button type="button" class="btn btn-danger waves-light cancel" (click)="hideModal()">Cancel</button>
      <button type="button" class="btn btn-success waves-light update" (click)="updateList()"
        [disabled]="!ownerId">Close Out</button>
    </div>
    @if (isShowError) {
    <div class="show-error">Sorry, something went wrong. Please try again later</div>
    }
  </div>
</div>

<div class="changeModal changePasswordModal" [@modalChange]="changePasswordMenuState">
  <form #form="ngForm">
    <div class="users">
      @if (selectedUser) {
      <div class="title">
        Change password
      </div>
      }
      <br>
      <div class="md-form">
        <input class="form-control" [(ngModel)]="password" mdbActive type="password" id="password" name="password"
          required pattern="^(?=.*[a-z])(?=.*[A-Z])(?=\d*)(?=.*[0-9]).{7,}$" #inputPassword="ngModel">
        <label for="password">New password</label>
      </div>
      <div class="md-form">
        <input class="form-control" [(ngModel)]="confirmPassword" mdbActive type="password" id="confirmPassword"
          name="confirmPassword" required pattern="^(?=.*[a-z])(?=.*[A-Z])(?=\d*)(?=.*[0-9]).{7,}$"
          #inputConfirmPassword="ngModel">
        <label for="confirmPassword">Confirm the password</label>
      </div>
      @if ((inputConfirmPassword.invalid && inputConfirmPassword.touched)||(inputPassword.invalid &&
      inputPassword.touched)) {
      <mat-error>
        The password must contain at least one digit and one uppercase
        character and have a length of at least 7 characters</mat-error>
      }
      @if (password != confirmPassword && confirmPassword && password && inputConfirmPassword.touched &&
      inputPassword.touched) {
      <mat-error>
        Password fielsd do not match</mat-error>
      }
      <div class="buttons">
        <button type="button" class="btn btn-danger waves-light cancel"
          (click)="hideChangePasswordModal()">Cancel</button>
        <button type="button" class="btn btn-success waves-light update" (click)="savePassword()" [disabled]="(!password || (password && password.length === 0)) 
          || (!confirmPassword || (confirmPassword && confirmPassword.length === 0)) 
              || (password != confirmPassword && confirmPassword && password) || spinner">Save</button>
      </div>
      @if (isShowError) {
      <div class="show-error">Sorry, something went wrong. Please try again later</div>
      }
    </div>
  </form>
</div>

<div class="changeModal changeUserModal" [ngClass]="{
  'changeUserModal': !(role && role.id === RolesEnum.Admin),
  'changeUserModal--admin': (role && role.id === RolesEnum.Admin)
}" [@modalChange]="updateUserMenuState">
  <form #form="ngForm">
    <div class="form-content">
      @if (selectedUser) {
      <div class="title">
        Edit User
      </div>
      }
      <br>
      @if (selectedUser) {
      <div class="field-full-name">
        <pd-field-full-name (fullNameChange)="getFullName($event)" [fullName]="selectedUser.name"></pd-field-full-name>
      </div>
      }
      @if (selectedUser) {
      <div class="field-email">
        <pd-field-email (emailChange)="getEmail($event)" [email]="selectedUser.email"></pd-field-email>
      </div>
      }
      @if (selectedUser) {
      <div class="field-role">
        <pd-field-role (roleChange)="getRole($event)" [selectedUserId]="selectedUser.id"
          [selectedRoleId]="selectedUser.role_id" [isTasksTab]="selectedUser.isTasksTab"
          [isUsersTab]="selectedUser.isUsersTab" [isAllowedExportLeadDate]="selectedUser.isAllowedExportLeadDate"
          [isAllowedHistoryOfLeads]="selectedUser.isAllowedHistoryOfLeads" [isRepoAccount]="selectedUser.isRepoAccount"
          [isWhatsappChat]="selectedUser.isWhatsappChat" [isGlobalChat]="selectedUser.isGlobalChat"
          [isAvatarEditor]="selectedUser.isAvatarEditor" [isSeeOthersCms]="selectedUser.isSeeOthersCms"
          [isInSmReport]="selectedUser.isInSmReport" [isNotInClothierReport]="selectedUser.isNotInClothierReport"
          [roles]="roles">
        </pd-field-role>
      </div>
      }
      @if (selectedUser) {
      <div class="field-referral-link">
        <pd-field-referral-link (referralLinkChange)="getReferralLink($event)"
          [referralLink]="selectedUser.referralLink"></pd-field-referral-link>
      </div>
      }
      @if (selectedUser && isShowTravelLocal) {

      <div class="field-type-of-clothier">
        <pd-field-type-of-clothier [isLocal]="isLocal" [isTravel]="isTravel" [role]="role"
          (isLocalChange)="getIsLocal($event)" (isTravelChange)="getIsTravel($event)">
        </pd-field-type-of-clothier>
      </div>

      }
      @for (exception of exceptions; track exception) {
      <div class="required-row">
        <p>{{exception}}</p>
      </div>
      }
    </div>
    <div class="buttons">
      <button type="button" class="btn btn-danger waves-light cancel" (click)="hideUpdateUserModal()">Cancel</button>
      <button type="button" class="btn btn-success waves-light update" (click)="saveUpdateUser()" [disabled]="(!fullName.value || (fullName.value && fullName.value.length === 0))
              || (!email.value || (email.value && email.value.length === 0)) 
              || !email.isValid 
              || !fullName.isValid 
              || (isShowTravelLocal && !isTravelLocalIsValid)
              || !role">Save</button>
    </div>
  </form>
</div>

<div class="openCCModal" [@modalState]="openCCMenuState">
  @if (selectedUser) {
  <div class="title">
    Open Common Connection & Same Building
  </div>
  }
  <div style="margin-top: 20px;">
    <span class="label">
      Open common connections and same buildings till:
    </span>
    <mat-form-field (click)="dateCC.open()">
      <input matInput [matDatepicker]="dateCC" placeholder="Choose a date" [(ngModel)]="openCCDate" disabled>
      <mat-datepicker-toggle matSuffix [for]="dateCC">
      </mat-datepicker-toggle>
      <mat-datepicker #dateCC disabled="false"></mat-datepicker>
    </mat-form-field>
  </div>

  <div class="buttons">
    <button type="button" class="btn btn-danger waves-light cancel" (click)="hideCCModal()">Cancel</button>
    <button type="button" class="btn btn-success waves-light update" (click)="saveOpenCC()"
      [disabled]="!openCCDate">Save</button>
  </div>
</div>


<div class="spinner" *ngIf="spinner">
  <mat-spinner></mat-spinner>
</div>
