import { Injectable } from "@angular/core";
import { ClientCardStoreService } from "./client-card-store.service";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { ClientCardApiService } from "./client-card-api.service";
import { ClientCard } from "../../models/ClientCard";
import { AvatarDealStoreService } from "../avatar-deal/avatar-deal-store.service";
import { AvatarDealApiService } from "../avatar-deal/avatar-deal-api.service";
import { ProductTypes } from "../../enums/client-card/ProductTypes";
import { AvatarApiService } from "../avatar/avatar-api.service";
import { AvatarUtil } from "../../utils/avatar.util";

@Injectable()
export class ClientCardEffectsService {

    constructor(
        private clientCardStoreService: ClientCardStoreService
        , private clientCardApiService: ClientCardApiService
        , private avatarDealStoreService: AvatarDealStoreService
        , private avatarDealApiService: AvatarDealApiService
        , private avatarApiService: AvatarApiService
    ) {
    }

    public loadClientCardsByDealId(dealId: number): Observable<ClientCard[]> {
        return this.clientCardApiService.getClientCardsByDealId(dealId).pipe(
            tap((clientCards: ClientCard[]) => {
                const dataLog = {
                    "loadClientCardsByDealId": clientCards,
                };

                console.log(JSON.stringify(dataLog));
                this.clientCardStoreService.setClientCards(clientCards);
            }));
    }

    public createClientCard(clientCard: ClientCard, isFabricUpdated: boolean): Observable<number> {
        return this.clientCardApiService.createClientCard(clientCard).pipe(
            tap(async (clientCardId: number) => {
                console.log(JSON.stringify({ methodName: "updateClientCard", clientCard, isFabricUpdated, clientCardId }));
                if (clientCardId) {
                    const newClientCard = await this.clientCardApiService.getClientCardId(clientCardId).toPromise();
                    const clientCards = this.clientCardStoreService.getAllClientCardsSnapshot();
                    const resultClientCards = clientCards;
                    resultClientCards.push(newClientCard);

                    const dataLog = {
                        "createClientCard": newClientCard,
                        "clientCards": clientCards,
                        "resultClientCards": resultClientCards,
                    };

                    console.log(JSON.stringify(dataLog));
                    this.clientCardStoreService.setClientCards(resultClientCards);

                    if (!this.isNeedAvatarProcessing(clientCard.productTypeId)) {
                        return;
                    }

                    const newAvatar = await this.avatarDealApiService.getAvatarByClientCardId(clientCardId).toPromise();
                    const avatars = this.avatarDealStoreService.getClientCardAvatarsSnapshot();
                    const resultAvatars = avatars;
                    resultAvatars.push(newAvatar);

                    console.log("newAvatar", newAvatar)
                    console.log("avatars", avatars)
                    console.log("resultAvatars", resultAvatars)

                    this.avatarDealStoreService.setClientCardAvatars(resultAvatars);
                }

                if (isFabricUpdated) {
                    await this.resetAvatarsCacheByFabric(clientCard.fabricImgTitle);
                }
            })
        )
    }

    public updateClientCard(clientCard: ClientCard, isFabricUpdated: boolean): Observable<boolean> {
        return this.clientCardApiService.updateClientCard(clientCard).pipe(
            tap(async (isUpdated: boolean) => {
                console.log(JSON.stringify({ methodName: "updateClientCard", clientCard, isFabricUpdated, isUpdated }));
                if (isUpdated) {
                    const updatedClientCard = await this.clientCardApiService.getClientCardId(clientCard.id).toPromise();
                    const clientCards = this.clientCardStoreService.getAllClientCardsSnapshot();
                    const resultClientCards = clientCards.filter(a => a.id != clientCard.id);
                    resultClientCards.push(updatedClientCard);

                    const dataLog = {
                        "updateClientCard": updatedClientCard,
                        "clientCards": clientCards,
                        "resultClientCards": resultClientCards,
                        "isFabricUpdated": isFabricUpdated,
                    };

                    console.log(JSON.stringify(dataLog));
                    this.clientCardStoreService.setClientCards(resultClientCards);

                    if (!this.isNeedAvatarProcessing(clientCard.productTypeId)) {
                        return;
                    }

                    const updateAvatar = await this.avatarDealApiService.getAvatarByClientCardId(clientCard.id).toPromise();
                    const avatars = this.avatarDealStoreService.getClientCardAvatarsSnapshot();
                    const resultAvatars = avatars.filter(a => a.id != updateAvatar.id);
                    resultAvatars.push(updateAvatar);

                    console.log("updateAvatar", updateAvatar)
                    console.log("avatars", avatars)
                    console.log("resultAvatars", resultAvatars)

                    this.avatarDealStoreService.setClientCardAvatars(resultAvatars);
                }

                if (isFabricUpdated) {
                    await this.resetAvatarsCacheByFabric(clientCard.fabricImgTitle);
                }
            })
        )
    }

    public deleteClientCard(cardId: number): Observable<boolean> {
        return this.clientCardApiService.deleteClientCard(cardId).pipe(
            tap((isDeleted: boolean) => {
                if (isDeleted) {
                    const clientCards = this.clientCardStoreService.getAllClientCardsSnapshot();
                    const resultClientCards = clientCards.filter(a => a.id != cardId);

                    const dataLog = {
                        "deleteClientCard": isDeleted,
                        "clientCards": clientCards,
                        "resultClientCards": resultClientCards,
                    };

                    console.log(JSON.stringify(dataLog));
                    this.clientCardStoreService.setClientCards(resultClientCards);

                    const avatars = this.avatarDealStoreService.getClientCardAvatarsSnapshot();
                    const deleteAvatar = avatars.find(a => a.clientCardId == cardId);
                    if (!deleteAvatar) {
                        return;
                    }
                    const resultAvatars = avatars.filter(a => a.id != deleteAvatar.id);

                    console.log("deleteAvatar", deleteAvatar)
                    console.log("avatars", avatars)
                    console.log("resultAvatars", resultAvatars)

                    this.avatarDealStoreService.setClientCardAvatars(resultAvatars);
                }
            })
        );
    }

    public generateDealAvatars(dealId: number, productTypeId: ProductTypes): Observable<boolean> {
        return this.clientCardApiService.generateDealAvatars(dealId, productTypeId);
    }

    private isNeedAvatarProcessing(productTypeId: ProductTypes): boolean {
        return AvatarUtil.productTypeToAvatarProcessing.some(p => p == productTypeId);
    }

    private async resetAvatarsCacheByFabric(fabricTitle: string): Promise<Observable<boolean>> {
        console.log("resetAvatarsCacheByFabric", fabricTitle);
        await this.clientCardApiService.resetAvatarsCacheByFabric(fabricTitle).toPromise();
        const avatars = this.avatarDealStoreService.getClientCardAvatarsSnapshot();
        const avatarsForReset = avatars.filter(a => a.avatarElements.some(o => o.imageLink.includes(fabricTitle)));
        const resultAvatars = avatars.filter(a => !avatarsForReset.some(b => b.id == a.id));

        if (avatarsForReset.length == 0) {
            console.log("No avatars for reset");
            return;
        }

        for (let avatar of avatarsForReset) {
            const resetedAvatar = await this.avatarApiService.getAvatar(avatar.id).toPromise();
            resultAvatars.push(resetedAvatar);
        }

        console.log("avatars", avatars)
        console.log("avatarsForReset", avatarsForReset)
        console.log("resultAvatars", resultAvatars)

        this.avatarDealStoreService.setClientCardAvatars(resultAvatars);
    }
}
