import { Injectable } from "@angular/core";
import { AvatarStoreService } from "./avatar-store.service";
import { AvatarEffectsService } from "./avatar-effects.service.";
import { Observable } from "rxjs";
import { Avatar } from "../../models/avatar-models/Avatar";
import { AvatarCollection } from "../../models/avatar-models/AvatarCollection";
import { Avatar2Collection } from "../../models/avatar-models/Avatar2Collection";
import { AvatarBuilderData } from "../../models/avatar-models/AvatarBuilderData";

@Injectable()
export class AvatarFacadeService {

    constructor(
        private avatarEffectsService: AvatarEffectsService,
        private avatarStoreService: AvatarStoreService,
    ) {
    }

    //Avatar
    public loadAllNonDealIdAvatars(): Promise<Avatar[]> {
        return this.avatarEffectsService.loadAllNonDealIdAvatars().toPromise();
    }

    public getAllNonDealIdAvatars(): Observable<Avatar[]> {
        return this.avatarStoreService.getAllNonDealIdAvatars();
    }

    public createAvatar(avatarBuilderData: AvatarBuilderData): Promise<number> {
        return this.avatarEffectsService.createAvatar(avatarBuilderData).toPromise();
    }

    public createAvatarDuplicate(avatarId: number, dealId: number = null, clientCardId: number = null): Promise<number> {
        return this.avatarEffectsService.createAvatarDuplicate(avatarId, dealId, clientCardId).toPromise();
    }

    public updateAvatar(avatarId: number, avatarBuilderData: AvatarBuilderData): Promise<boolean> {
        return this.avatarEffectsService.updateAvatar(avatarId, avatarBuilderData).toPromise();
    }

    public deleteAvatar(avatarId: number): Promise<boolean> {
        return this.avatarEffectsService.deleteAvatar(avatarId).toPromise();
    }

    public downloadAvatar(avatarId: number): Promise<string> {
        return this.avatarEffectsService.downloadAvatar(avatarId).toPromise();
    }

    public downloadAllNonDealId(): Promise<Blob> {
        return this.avatarEffectsService.downloadAllNonDealId().toPromise();
    }

    //AvatarCollection
    public loadAvatarCollections(): Promise<AvatarCollection[]> {
        return this.avatarEffectsService.loadAvatarCollections().toPromise();
    }

    public getAllAvatarCollections(): Observable<AvatarCollection[]> {
        return this.avatarStoreService.getAllAvatarCollections();
    }

    public addAvatarCollection(collectionName: string): Promise<number> {
        return this.avatarEffectsService.addCollection(collectionName).toPromise();
    }

    public updateAvatarCollection(avatarCollectionId: number, collectionName: string): Promise<boolean> {
        return this.avatarEffectsService.updateAvatarCollection(avatarCollectionId, collectionName).toPromise();
    }

    public setSeasonalProposalCollection(avatarCollectionId: number, isChecked: boolean): Promise<boolean> {
        return this.avatarEffectsService.setSeasonalProposalCollection(avatarCollectionId, isChecked).toPromise();
    }

    public setStylesAvailableCollection(avatarCollectionId: number, isChecked: boolean): Promise<boolean> {
        return this.avatarEffectsService.setStylesAvailableCollection(avatarCollectionId, isChecked).toPromise();
    }

    public setGroupedView(avatarCollectionId: number, isChecked: boolean): Promise<boolean> {
        return this.avatarEffectsService.setGroupedView(avatarCollectionId, isChecked).toPromise();
    }

    public setAvatarCollection(avatarCollectionId: number, collectionName: string): Promise<boolean> {
        return this.avatarEffectsService.updateAvatarCollection(avatarCollectionId, collectionName).toPromise();
    }

    public deleteAvatarCollection(avatarCollectionId: number): Promise<boolean> {
        return this.avatarEffectsService.deleteAvatarCollection(avatarCollectionId).toPromise();
    }

    public downloadCollection(avatarCollectionId: number): Promise<Blob> {
        return this.avatarEffectsService.downloadCollection(avatarCollectionId).toPromise();
    }

    //Avatar2Collection
    public loadAvatar2Collections(): Promise<Avatar2Collection[]> {
        return this.avatarEffectsService.loadAvatar2Collections().toPromise();
    }

    public getAllAvatar2Collections(): Observable<Avatar2Collection[]> {
        return this.avatarStoreService.getAllAvatar2Collections();
    }

    public addAvatarToCollection(avatarId: number, avatarCollectionId: number): Promise<number> {
        return this.avatarEffectsService.addAvatarToCollection(avatarId, avatarCollectionId).toPromise();
    }

    public removeAvatarFromCollection(avatarId: number, avatarCollectionId: number): Promise<boolean> {
        return this.avatarEffectsService.removeAvatarFromCollection(avatarId, avatarCollectionId).toPromise();
    }
}