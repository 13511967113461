<div class="row wrapper">
  <div class="col-4 title">
    <span style="color: red; font-size: 20px">*</span> LinkedIn URL
  </div>
  <div class="col-8 work-field">
    @if (!url && !isEdit) {
    <pd-add-value (addValue)="addValue()"></pd-add-value>
    }
    @if (isEdit || url) {
    <div class="md-form" (document:focusout)="changeEdit()">
      <input [(ngModel)]="url" mdbActive type="text" required #inputUrl="ngModel">
      @if (inputUrl.invalid && inputUrl.touched) {
      <span style="color: red">Enter your LinkedIn URL</span>
      }
      @if (isUrlExists && url.length > 0) {
      <span style="color: red">This URL already exists</span>
      }
    </div>
    }
  </div>
</div>