import { User } from '../models/UserModel';
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { GlobalConstants } from '../global-constants';

@Injectable()
export class UserContextService {
    private static userModel: User;
    private static authorizeStatus: boolean;

    public readonly user = new BehaviorSubject(UserContextService.userModel);
    public readonly isAuthorize = new BehaviorSubject(UserContextService.authorizeStatus);

    public setUser(value: User) {
        UserContextService.userModel = value;
        this.user.next(value);
    }

    public setauthorizeStatus(value: boolean) {
        UserContextService.authorizeStatus = value;
        this.isAuthorize.next(value);
    }

    public resetContext() {
        UserContextService.userModel = null;
        UserContextService.authorizeStatus = false;
        this.user.next(null);
        this.isAuthorize.next(false);
        localStorage.removeItem(GlobalConstants.userContextKey);
        localStorage.removeItem(GlobalConstants.jwtSessionKey);
        localStorage.removeItem(GlobalConstants.refreshTokenKey);
    }
}
