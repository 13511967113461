export class UtilsHelper {
  static addressValidationMessage = "More than 10 letters or numbers, at least 2 spaces, at least 1 digit";

  static addressNormalize(address: string) {
    if (address)
      return address = address.trim().replace(/\s\s+/g, ' ');
    return address;
  }

  static checkAddressIsValid(address: string) {
    if (!address) {
      return false;
    }

    address = UtilsHelper.addressNormalize(address);
    let lengthCheck = (address.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, '').replace(/\s+/g, '').length > 10);
    let spaceCheck = (address.replace(/\s/g, '').length) < (address.length - 1);
    let digitsCheck = (/\d/.test(address));

    return (lengthCheck && spaceCheck && digitsCheck);
  }

  static deepClone(array: Object[]) {
    const newArray: any = [];
    array.forEach(item => {
      newArray.push(Object.assign({}, item));
    });
    return newArray;
  }

  static base64ToArrayBuffer(base64: any): any {
    var binary_string = window.atob(base64)
    var len = binary_string.length
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i)
    }
    return bytes.buffer
  }

  // The HostListener will listen to click events and run the below function, the HostListener supports other standard events such as mouseenter, mouseleave etc.
  static copyText(text: string) {

    // We need to create a dummy textarea with the text to be copied in the DOM
    var textArea = document.createElement("textarea");

    // Hide the textarea from actually showing
    textArea.style.position = 'fixed';
    textArea.style.top = '-999px';
    textArea.style.left = '-999px';
    textArea.style.width = '2em';
    textArea.style.height = '2em';
    textArea.style.padding = '0';
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';
    textArea.style.background = 'transparent';

    // Set the texarea's content to our value defined in our [text-copy] attribute
    textArea.value = text;
    document.body.appendChild(textArea);

    // This will select the textarea
    textArea.select();

    try {
      // Most modern browsers support execCommand('copy'|'cut'|'paste'), if it doesn't it should throw an error
      var successful = document.execCommand('copy');
      var msg = successful ? 'successful' : 'unsuccessful';
      // Let the user know the text has been copied, e.g toast, alert etc.
      console.log(msg);

    } catch (err) {
      // Tell the user copying is not supported and give alternative, e.g alert window with the text to copy
      console.log('unable to copy');
    }

    // Finally we remove the textarea from the DOM
    document.body.removeChild(textArea);
  }

  static arrayOfArraysToArray(arrOfArr) {
    if (arrOfArr) {
      return [].concat.apply([], arrOfArr.filter(f => f.length > 0));
    }
    return null
  }

  static splitArray(array: Array<any>, chunkSize): Array<any> {
    const result = array.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / chunkSize)
      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []
      }
      resultArray[chunkIndex].push(item)
      return resultArray
    }, [])

    return result;
  }
}