import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Inject } from '@angular/core';
import { saveAs } from 'file-saver';
import { ClientCardApiService } from 'src/app/core/services/client-card/client-card-api.service';
import { UtilsHelper } from 'src/app/core/utils/utils.helper';
import { BaseComponent } from 'src/app/core/base.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AchievementCardApiService } from 'src/app/core/services/achievement-card/achievement-card-api.service';

@Component({
  selector: 'pd-custom-qr-code-modal',
  templateUrl: './custom-qr-code-modal.component.html',
  styleUrls: ['./custom-qr-code-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class QrCodeModalComponent extends BaseComponent implements OnInit {
  private title: string;
  
  public qrCodeBase64: string;
  public base64DataImg: string;
  public qrCodeShortLink: string;
  public qrCodeLink: string;
  public isSpinner: boolean = true; 

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any
    , private dialogRef: MatDialogRef<QrCodeModalComponent>
    , private cdRef: ChangeDetectorRef
    , private clientCardService: ClientCardApiService
    , private achievementCardApiService: AchievementCardApiService
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    if (this.data) {
      if (this.data.isClientCard) {
        await this.openClientCardQrCodeModal(this.data.dealId, this.data.title);
      }
      else {
        await this.openAchievementQrCodeModal(this.data.userId, this.data.title)
      }
    }
  }

  public async openClientCardQrCodeModal(dealId: number, title: string) {
    const result = await this.clientCardService.getClientCardQrCodeDataByDealId(dealId);
    this.setModalData(title, result.shortLink, result.link, result.base64);
  }

  public async openAchievementQrCodeModal(userId: number, title: string) {
    const result = await this.achievementCardApiService.getAchievementQrCodeDataByUserId(userId).toPromise();
    this.setModalData(title, result.shortLink, result.link, result.base64);
  }

  public downloadQrCode() {
    const byteArray = UtilsHelper.base64ToArrayBuffer(this.qrCodeBase64)
    const blob: any = new Blob([byteArray], { type: 'image/jpg' })
    saveAs(blob, 'Qr-code_' + this.title + '.jpg', true)
  }

  public cancel() {
    this.dialogRef.close();
  }

  private setModalData(title, shortLink, link, base64) {
    this.title = title;
    this.qrCodeShortLink = shortLink;
    this.qrCodeLink = link;
    this.qrCodeBase64 = base64;
    this.base64DataImg = 'data:image/png;base64,' + this.qrCodeBase64;
    this.isSpinner = false;
    this.cdRef.detectChanges();
  }
}