export class CustomRottenTimeModalOptions {
    public Title: string;
    public MinDate: Date;
    public MaxDate: Date;
    public IsDateOptions: boolean;

    constructor(title: string, minDate: Date, maxDate: Date, isDateOptions: boolean) {
        this.Title = title;
        this.MinDate = minDate;
        this.MaxDate = maxDate;
        this.IsDateOptions = isDateOptions;
	} 
}