import { Stage } from "./Stage";

export class Multitransfer {
	public Id: string;
	public OwnerId: number;
	public Limit: number;
	public StageTo: number;
	public SelectedStage: number;
	public ReasonOfKill: string;
	public StatusesByPipelines: any[];

	constructor()
	constructor(OwnerId: number, Start: number, Limit: number, StageTo: number, ReasonOfKill: string)
	constructor(OwnerId?: number, Start?: number, Limit?: number, StageTo?: number, ReasonOfKill?: string) {
		this.Id =  '_' + Math.random().toString(36).substr(2, 9);
		this.OwnerId = OwnerId;
		this.Limit = Limit;
		this.StageTo = StageTo;
		this.ReasonOfKill = ReasonOfKill;
	}
}