<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title>
      <span class="achievement-panel-title">
        {{achievementGroup.name}}
        @if (getCardCount() > 0) {
        <span>&nbsp;({{getCardCount()}})</span>
        }
      </span>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <mat-panel-description>

    @if (bestMonth) {
    <div class="tile-container" style="justify-content: center;">
      <div class="tile">

        <div class="tile-title">
          <span>
            Best month - {{bestMonth.achievementCardTypeName}}</span>
        </div>

        <div class="tile-body">
          @if (bestMonth) {
          <div>

            <div class="relative">
              <div class="image">
                <img [src]="findImgUrl(bestMonth)" onerror="this.onerror=null; this.src='../../assets/lgfg-logo.png'" />
              </div>
            </div>

            <div class="single-row">
              <div class="monthly-date">{{bestMonth.date | date :'MMMM yyyy'}}</div>
              <div>${{bestMonth.amount | money}} CAD</div>
            </div>

          </div>
          }
        </div>
      </div>
    </div>
    }

    @if (isSplitByTypes) {

    @for (achievementCardType of getAchievementCardTypes(); track achievementCardType) {

    <pd-achievement-card-container [ownerId]='ownerId' [achievementGroup]='achievementGroup'
      [achievementCardType]="achievementCardType" [achievementCardTypes]='achievementCardTypes'
      [isSplitByTypes]='isSplitByTypes' [isAchievementHistory]='isAchievementHistory'
      [isAchievementGroup]="isAchievementGroup">
    </pd-achievement-card-container>

    }

    }

    @if (!isSplitByTypes) {

    <pd-achievement-card-container [ownerId]='ownerId' [achievementGroup]='achievementGroup'
      [isSplitByTypes]='isSplitByTypes' [isAchievementHistory]='isAchievementHistory'
      [isAchievementGroup]="isAchievementGroup" [achievementCardTypes]='achievementCardTypes'>
    </pd-achievement-card-container>

    }

  </mat-panel-description>
</mat-expansion-panel>