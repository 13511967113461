<modal #modal title="Select export type" primaryBtnTextKey="Save" secondaryBtnTextKey="Cancel"
  [displayLaunchButton]="false" (secondaryClick)="confirm(false)" (onCompleteAction)="confirm(false)"
  (primaryClick)="confirm(true)">
  <div class="row block">
    <div class="col-6">
      <mat-radio-group class="tp-radio-export-group" [(ngModel)]="exportType">
        <mat-radio-button class="tp-export-radio-button" value="Referrals" (change)="radioChange($event)">Export
          Referrals
        </mat-radio-button>
        <mat-radio-button class="tp-export-radio-button" value="Clients" (change)="radioChange($event)">Export
          Clients
        </mat-radio-button>
        <mat-radio-button class="tp-export-radio-button" value="ColdLeads" (change)="radioChange($event)">Export
          Cold Leads
        </mat-radio-button>
        <mat-radio-button class="tp-export-radio-button" value="SameBuildingLeads" (change)="radioChange($event)">
          Export
          Same Building Leads
        </mat-radio-button> <mat-radio-button class="tp-export-radio-button" value="CurrentViewLeads"
          (change)="radioChange($event)">
          Export
          Current View
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="col-6" [ngClass]="{'column-reverse': isCurrentViewExport }">
      <div [ngClass]="{'invalid-input': isShowValidationMessages && !stagesValidation()
        , 'visible': !(isSameBuildingExport || isCurrentViewExport)
        , 'hidden': (isSameBuildingExport || isCurrentViewExport)}">
        <div class="stage-multiselect">
          <pd-custom-select-without-lazy #stageFilter (loadDataEvent)="getStagesFilterData()"
            (autoCompleteEvent)="getStagesAutocomplete($event)" dataLabel="Select Stages" startLabel="Select Stages"
            [isMultiple]="true">
          </pd-custom-select-without-lazy>

          @if (isShowValidationMessages && !stagesValidation()) {
          <div class="invalid-message">Please choose stages
          </div>
          }
        </div>
      </div>
      <div
        [ngClass]="{'invalid-input': isShowValidationMessages && !amountValidation(), 'visible': isCurrentViewExport, 'hidden': !isCurrentViewExport }">
        <div class="amount-input-wrapper">
          <input max="10000" mdbinputdirective="" min="1" type="number" [(ngModel)]="exportDealLimit"
            placeholder="Amount (max 10000)">
          @if (isShowValidationMessages && !amountValidation()) {
          <div class="invalid-message">Please enter a valid
            amount
          </div>
          }
        </div>
      </div>
    </div>
  </div>

  @if (currentUser?.role_id === rolesEnum.MasterLead || currentUser?.isAllowedExportLeadDate) {
  <div [ngClass]="{'invalid-input': isShowValidationMessages && !dateFilterValidation() }" class="row block">

    <div class="col">
      <div>
        <mat-checkbox [(ngModel)]="isIncludeUnexportedLeads">Include leads who weren`t exported before
        </mat-checkbox>
        <mat-checkbox [(ngModel)]="isIncludeExportedLeads">Include leads who were exported before
        </mat-checkbox>
      </div>
      @if (isShowValidationMessages && !dateFilterValidation()) {
      <div class="invalid-message">Please choose option
      </div>
      }
    </div>

    <div [ngClass]="{'visible': isIncludeExportedLeads, 'hidden': !isIncludeExportedLeads }" class="row"
      dlass="date-picker-wrapper">
      <div class="date-picker-title">Export from which date (based
        on Last export
        date field in lead)</div>

      <div class="col-6">
        <mat-form-field class="date-picker">
          <input matInput [matDatepicker]="dateFromPicker" placeholder="Date from (optional)" [(ngModel)]="dateFrom"
            [max]="maxDate" [matDatepickerFilter]="filterDateFrom">
          <mat-datepicker-toggle matSuffix [for]="dateFromPicker">
            <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #dateFromPicker (dateChange)="onDate()"></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="col-6">
        <mat-form-field class="date-picker">
          <input matInput [matDatepicker]="dateToPicker" placeholder="Date to (optional)" [(ngModel)]="dateTo"
            [max]="maxDate" [matDatepickerFilter]="filterDateTo">
          <mat-datepicker-toggle matSuffix [for]="dateToPicker">
            <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #dateToPicker (dateChange)="onDate()"></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
  </div>
  }

  <div class="row block">
    <div class="col-12">
      <div class="format-selector-wrapper">
        <div class="label">Please select the format:</div>
        <div class="">
          <mat-radio-group class="tp-radio-group" [(ngModel)]="fileType">
            <mat-radio-button class="tp-radio-button" value="pdf">PDF</mat-radio-button>
            <mat-radio-button class="tp-radio-button" value="csv">CSV</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </div>
  </div>
</modal>