@if (bulkMessageSetting) {
<ng-container>

    @if (!spinner) {

    <div class="col">
        <pd-bulk-message-table [title]="'Selected Setting'" [settings]="[bulkMessageSetting]" [isArchive]="bulkMessageSetting?.isArchived" [isNoFilter]="true" 
            (onCancel)="onCancel()" (onConfigureMessage)="onConfigureMessage($event)"
            (onSelectRequest)="onRequestSelectChange($event)" [isSelectedItem]="true"></pd-bulk-message-table>
    </div>

    @if(isEmailToSend)
    {
    <ng-container>
        <div class="settings__container">
            @if(copiedTemplateInfo$ | async)
            {
            <div class="row" style="padding: 25px; display: flex; justify-content: end;">
                <button style="max-width: 200px;" class="btn btn-success waves-light" (click)="onPaste()">Paste
                    Template</button>
            </div>
            }
            <pd-bulk-message-email #emailRequest></pd-bulk-message-email>

            <div class="row" style="display: flex; justify-content: space-between;">

                <div class="col">
                    @if(!IsNewRequest)
                    {
                    <pd-bulk-message-status-filter (onFilterChange)="onFilterDealsByMessageStatus($event)"
                        [messageTemplateType]="MessageTemplateType.Email"></pd-bulk-message-status-filter>
                    }
                </div>
                <!-- <div class="col-md-6 col-xl-2 filter__item">
                    <div class="col-auto ml-auto datepicker">
                        <mat-form-field>
                            <mat-label>Choose Scheduled Date</mat-label>
                            <input matInput [matDatepicker]="picker" [(ngModel)]="scheduledSendDate">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-auto mr-auto">
                        <timepicker [(ngModel)]="scheduledSendDate" class="timepicker" [showMeridian]=false>
                        </timepicker>
                    </div>
                </div> -->

            </div>

            <div class="row" style="padding: 25px; display: flex; justify-content: end;">
                <button style="max-width: 200px;" class="btn btn-success waves-light" (click)="onSendEmail()"
                    [disabled]="!emailRequest.validation()">Send Emails</button>
            </div>
        </div>
    </ng-container>
    }

    @if(isWhatsappToSend)
    {
    <ng-container>
        <div class="settings__container">
            <pd-bulk-message-whatsapp #whatsappRequest [templates]="getWhatsappTemplates()"
                [currentTemplate]="templates[0]"></pd-bulk-message-whatsapp>

            <div class="row" style="display: flex; justify-content: space-between;">

                <div class="col">
                    @if(!IsNewRequest)
                    {
                    <pd-bulk-message-status-filter (onFilterChange)="onFilterDealsByMessageStatus($event)"
                        [messageTemplateType]="MessageTemplateType.Whatsapp"></pd-bulk-message-status-filter>
                    }
                </div>
                <!-- <div class="col-md-6 col-xl-2 filter__item">
                    <div class="col-auto ml-auto datepicker">
                        <mat-form-field>
                            <mat-label>Choose Scheduled Date</mat-label>
                            <input matInput [matDatepicker]="picker" [(ngModel)]="scheduledSendDate">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-auto mr-auto">
                        <timepicker [(ngModel)]="scheduledSendDate" class="timepicker" [showMeridian]=false>
                        </timepicker>
                    </div>
                </div> -->

            </div>

            <div class="row" style="padding: 25px; display: flex; justify-content: end;">
                <button style="max-width: 200px;" class="btn btn-success waves-light" (click)="onSendWhatsapp()"
                    [disabled]="!whatsappRequest.validation()">Send Messages</button>
            </div>
        </div>
    </ng-container>
    }

    <ng-container>
        <div>
            @if(IsNewRequest)
            {
            <div class="col" style="display: flex; justify-content: center;">
                <div [ngClass]="{'visible': !spinner, 'hidden': spinner }">
                    <div>
                        <div class="requests-table">
                            <div class="requests-table-label">
                                Clients ({{deals.length}})
                            </div>
                            <div class="requests-table-header row">
                                <div class="col cell">Full Name</div>
                                <div class="col cell">Client Rating</div>
                                <div class="col cell">Status</div>
                                <div class="col cell">Location</div>
                                <div class="col cell">Owner</div>
                            </div>
                        </div>
                    </div>
                    <div class="content-wrapper">
                        <div class="content">
                            <div class="requests-table">
                                @for (deal of deals; track deal) {
                                <div class="requests-table-content row">
                                    <div class="col cell"><a [href]="'/deals/' + deal.dealId"
                                            target="_blank">{{deal.title}}</a></div>
                                    <div class="col cell">{{deal.clientRating}}</div>
                                    <div class="col cell">{{deal.stageName}}</div>
                                    <div class="col cell">{{deal.location}}</div>
                                    <div class="col cell">{{deal.userName}}</div>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                @if (spinner) {
                <div class="spinner">
                    <mat-spinner></mat-spinner>
                </div>
                }
            </div>
            }

            @if(!IsNewRequest)
            {
            <div class="col" style="display: flex; justify-content: center;">
                <div [ngClass]="{'visible': !spinner, 'hidden': spinner }">
                    <div>
                        <div class="requests-table">
                            <div class="requests-table-label">
                                WhatsApp/Email Bulk Message Report ({{deals?.length ?? filteredReportRows?.length}})
                            </div>
                            <div class="requests-table-header row">
                                <div class="col cell">Full Name</div>
                                <div class="col cell">Client Rating</div>
                                <div class="col cell">Status</div>
                                <div class="col cell">Location</div>
                                <div class="col cell">Owner</div>
                                <div class="col cell">Message Sending Info</div>
                                <div class="col cell">Message Status</div>
                                <div class="col cell">Is Successful</div>
                            </div>
                        </div>
                    </div>
                    <div class="content-wrapper">
                        <div class="content">
                            <div class="requests-table">
                                @for (reportRow of filteredReportRows; track reportRow) {
                                <div class="requests-table-content row">
                                    <div class="col cell"><a [href]="'/deals/' + reportRow.dealId"
                                            target="_blank">{{reportRow.title}}</a></div>
                                    <div class="col cell">{{getClientRatingName(reportRow.clientRating)}}</div>
                                    <div class="col cell">{{reportRow.stageName}}</div>
                                    <div class="col cell">{{reportRow.location}}</div>
                                    <div class="col cell">{{reportRow.ownerName}}</div>
                                    <div class="col cell">{{reportRow.messageStatusInfo}}</div>
                                    <div class="col cell">{{MessageStatuses[reportRow.status]}}</div>
                                    <div class="col cell">{{(reportRow.isSuccessful) ? "Yes" : "No"}}</div>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                @if (spinner) {
                <div class="spinner">
                    <mat-spinner></mat-spinner>
                </div>
                }
            </div>
            }
        </div>
    </ng-container>
    }
    @if (spinner) {
    <div class="spinner">
        <mat-spinner></mat-spinner>
    </div>
    }
</ng-container>
}