<div class="wrapper">
  <div class="header">
    <h2>Global Search</h2>
  </div>
  <div class="content-wrapper">
    <div class="search">
      <mat-form-field class="search-by-select-labeled-global search-input">
        <mat-label>Search by
        </mat-label>
        <mat-select [(ngModel)]="searchType">
          @for (searchType of searchTypeList; track searchType) {
          <mat-option [value]="searchType.key">{{searchType.value}}
          </mat-option>
          }
        </mat-select>
      </mat-form-field>
      <form [formGroup]="form">
        <mat-form-field class="global-search-select-labeled-global search-input">
          <mat-label>Type here…</mat-label>
          <input matInput [(ngModel)]="searchText" (keyup.enter)="getDealsBySearch()" formControlName="term">
          @if (form.controls.term.hasError('required')) {
          <mat-error>
            Search text is required
          </mat-error>
          }
        </mat-form-field>
      </form>
      <div class="search-input">
        <pd-location-chips-filter #locationFilter (close)="closeSelectFilter()"
          (filterDataChanged)="getLocations($event)" [isAllSelectedDefaultMode]="false"></pd-location-chips-filter>
      </div>
      <button class="search-btn" [disabled]="isLoading" (click)="getDealsBySearch()" [disabled]="form.invalid">
        <i class="material-icons">
          search
        </i>
      </button>
    </div>
    @if (deals && deals.length > 0) {
    <div class="table-wrapper" [ngClass]="{'visible': !isLoading, 'hidden': isLoading }" (scroll)="doSomething($event)"
      #container>
      <div class="table">
        <div class="table-header">
          @for (col of tableTitle; track col) {
          <div class="header-collumn">
            {{col}}
          </div>
          }
        </div>
        <div class="table-content">
          @for (deal of deals; track deal) {
          <div class="content-row">
            <div class="name coll">
              <a [href]="'/deals/' + deal.id" target="_blank">{{deal.title}}</a>
            </div>
            <div class="stage coll">
              {{deal.stage_name}}
            </div>
            <div class="position coll">
              {{deal.position}}
            </div>
            <div class="company coll">
              {{deal.company}}
            </div>
            <div class="location coll">
              {{deal.location}}
            </div>
            <div class="phones coll">
              @for (p of deal.phone; track p) {
              <div class="phone">
                {{p.value}}
              </div>
              }
            </div>
            <div class="owner coll">
              {{deal.user_name}}
              @if ((deal.files_count > 0 && isShowFileBtnStageCondition(deal))) {
              <div>
                <button class="btn btn-primary waves-light hotlist"
                  (click)="openHotlist(deal.id, deal.title)">Hotlist</button>
              </div>
              }
              @if ((deal.files_count_pdf > 0 && isShowFileBtnStageCondition(deal))) {
              <div>
                <button class="btn btn-primary waves-light hotlist"
                  (click)="openHotlistPdf(deal.id);$event.stopPropagation()">Hotlist 2.0</button>
              </div>
              }
              @if (isShowFileBtnStageCondition(deal)) {
              <div class="upload-hotlist-button">
                <button class="btn btn-primary waves-light hotlist" (click)="selectDeal(deal.id);openInput()">Upload
                  Hotlist</button>
              </div>
              }
              @if (isShowFileBtnStageCondition(deal)) {
              <div class="upload-hotlist-button">
                <button class="btn btn-primary waves-light hotlist" (click)="selectDeal(deal.id);openInputPdf()">Upload
                  Hotlist 2.0</button>
              </div>
              }

              @if (isShowFileBtnStageCondition(deal)) {
              <div>
                <button class="btn btn-primary waves-light hotlist"
                  (click)="exportWardrobePlan(deal.id, deal.title);$event.stopPropagation()">@if
                  (deal.wardrobe_image_count > 0) {
                  <span>&#10003;</span>
                  } Wardrobe Plan</button>
              </div>
              }
              @if (isShowFileBtnStageCondition(deal)) {
              <div>
                <button class="btn btn-primary waves-light hotlist"
                  (click)="uploadWardrobePlanModal(deal);$event.stopPropagation()">Upload Wardrobe Plan</button>
              </div>
              }
              @if (isShowFileBtnStageCondition(deal)) {
              <div>
                <button class="btn btn-primary waves-light hotlist"
                  (click)="openMeetingPrepPdf(deal);$event.stopPropagation()">Print Meeting Prep</button>
              </div>
              }
            </div>
            <input #fileInput type="file" class="upload" accept=".csv" (change)="changeListener($event.target.files)"
              style="display: none">

            <input #fileInputPdf type="file" class="upload" accept=".pdf"
              (change)="changeListenerPdf($event.target.files)" style="display: none">
          </div>
          }
        </div>
      </div>
      @if (lazyLoading) {
      <div class="lazyLoading">
        <div class="text-lazy">Loading more deals...</div>
      </div>
      }
    </div>
    }
    @if (isNoResultFound && !isLoading) {
    <div class="no-result">
      <h2>No Result Found</h2>
    </div>
    }
  </div>

  <div class="update-info">
    {{uploadFileInfo}}
  </div>

  @if (isLoading) {
  <div class="spinner">
    <mat-spinner></mat-spinner>
  </div>
  }

  @if (isShowError) {
  <div class="show-error">
    <h3>Something went wrong. We are sorry for the trouble. Please wait for a few moments and try again.</h3>
  </div>
  }

  @if (isHotlistSent) {
  <div class="hotlist-sent">Request Hotlist Sent</div>
  }
  <pd-wardrobe-plan #wardrobePlan></pd-wardrobe-plan>
  <pd-meeting-prep #meetingPrep></pd-meeting-prep>
  <pd-custom-wardrobe-plan-upload-modal #uploadWardrobePlan></pd-custom-wardrobe-plan-upload-modal>