import { UserStoreService } from './../../../core/services/stores/user-store.service';
import { StageFeatureStoreService } from './../../../core/services/stores/stage-feature-store.service';
import { UserContextService } from './../../../core/services/user-context.service';
import { Component, ViewChild, Output, EventEmitter } from '@angular/core';
import { Subject, ReplaySubject } from 'rxjs';
import { CustomSelectFilterWithoutLazyComponent } from '../custom-select-without-lazy/custom-select-without-lazy';
import * as stages from '../../../../assets/stages_new_prospespects';
import { User } from 'src/app/core/models/UserModel';
import { Stage } from 'src/app/core/models/Stage';
import { RolesEnum } from 'src/app/core/enums/RolesEnum';
import { ExportDealsType } from 'src/app/core/enums/ExportDealsType';
import { GlobalConstants } from 'src/app/core/global-constants';
import { ModalComponent } from '../../modal/modal.component';

@Component({
  selector: 'pd-custom-export-modal',
  templateUrl: './custom-export-modal.component.html',
  styleUrls: ['./custom-export-modal.component.scss']
})
export class CustomExportModalComponent {
  @ViewChild('modal') modal: ModalComponent;
  @ViewChild("stageFilter") stageFilter: CustomSelectFilterWithoutLazyComponent;
  @Output() closeModal = new EventEmitter<object>();

  onDestroy$ = new Subject();
  confirmAction: any;
  stagesList = [];
  filteredStages = [];
  filteredStagesMultiSelect: ReplaySubject<Stage[]> = new ReplaySubject<Stage[]>(1);
  public currentUser: User;

  referralStages = new Array<Stage>();
  clientsStages = new Array<Stage>();
  coldStages = new Array<Stage>();
  sameBuildingStages = new Array<Stage>();

  public rolesEnum = RolesEnum;
  public fileType: string;
  public exportType: string;
  public exportDealsRangeOption: string;
  public exportDealLimit?: number;
  public ALL_STAGES = GlobalConstants.ALL_STAGES;

  fileTypes: string[] = ['PDF', 'CSV'];
  exportTypes = ExportDealsType;

  maxDate: Date;
  dateFrom: Date;
  dateTo: Date;

  isDateFromValide: boolean = true;
  isDateToValide: boolean = true;
  isIncludeUnexportedLeads: boolean;
  isIncludeExportedLeads: boolean;
  isSameBuildingExport: boolean;
  isCurrentViewExport: boolean;

  isShowValidationMessages: boolean = false;

  constructor(
    private userContextService: UserContextService,
    private stageFeatureStoreService: StageFeatureStoreService,
    private userStoreService: UserStoreService) { }

  ngOnInit() {
    this.maxDate = new Date();
    this.initializeStore();
    this.filteredStages = this.filteredStages.concat(this.stagesList);
  }

  private initializeStore() {

    this.currentUser = this.userContextService.user.value;
    const allItems = this.stageFeatureStoreService.allStage.value;

    const exportReferralStages = [this.ALL_STAGES.Referral, this.ALL_STAGES.RefVM1, this.ALL_STAGES.RefVM2, this.ALL_STAGES.RefVM3];
    this.referralStages = allItems.filter(x => exportReferralStages.some(xx => xx === x.id));

    const exportColdStages = [this.ALL_STAGES.Cold, this.ALL_STAGES.Linkedin, this.ALL_STAGES.VM1, this.ALL_STAGES.VM2, this.ALL_STAGES.VM3];
    this.coldStages = allItems.filter(x => exportColdStages.some(xx => xx === x.id));

    const stagesForRole = this.selectStageFeatureViewStagesForRole();
    const statusesToExport = stagesForRole.filter(f => f.pipeline_id == stages.ClientsPipeline.id
      || f.pipeline_id == stages.OpenOrders.id
      || f.pipeline_id == stages.ClothierContactClientsPipeline.id);
    if (statusesToExport && statusesToExport.length > 0) {
      this.clientsStages = statusesToExport
    }

    const sameBuildingStatusesToExport = stagesForRole.filter(f => f.id == this.ALL_STAGES.SameBuilding);
    if (sameBuildingStatusesToExport && sameBuildingStatusesToExport.length > 0) {
      this.sameBuildingStages = sameBuildingStatusesToExport
    }
  }

  private selectStageFeatureViewStagesForRole(): Stage[] {
    let allStageFeatureItems = this.stageFeatureStoreService.allStage.value;
    const selectedUsers = this.userStoreService.selectedUsersFilter.value;
    const requiredStages = this.getStageToUserFeatureRequiredStagesForFilterOutsideUsualPermissions(allStageFeatureItems, this.currentUser, selectedUsers);
    if (allStageFeatureItems) {
      allStageFeatureItems = allStageFeatureItems.filter(x => x.pipeline_id !== stages.Pipelines.ClothierMeetingConfirm)
      let role = (selectedUsers && selectedUsers.length > 0 && selectedUsers[0] && selectedUsers[0].role_id && RolesEnum[selectedUsers[0].role_id])
        || this.currentUser && this.currentUser.role_id && RolesEnum[this.currentUser.role_id]
        || null;
      if (role) {
        return allStageFeatureItems.filter(s => ((stages.STAGES_BY_ROLES_VISIBILITY[role].some(st => st === s.name) || (requiredStages.length > 0 && requiredStages.some(ss => s.id === ss.id)))));
      }
      else {// check if case is needed
        return allStageFeatureItems.filter(s => (this.currentUser.is_admin || s.pipeline_id === stages.NewProspectsPipeline.id) && stages.STAGES_BY_ROLES_VISIBILITY[RolesEnum[this.currentUser.role_id]].some(st => st === s.name))
      }
    } else {
      return null;
    }
  }

  private getStageToUserFeatureRequiredStagesForFilterOutsideUsualPermissions(allItems: Stage[], currentUser: User, selectedUsersFilter: User[]): Stage[] {
    if (allItems && currentUser && currentUser.is_admin
      && selectedUsersFilter && selectedUsersFilter.length > 0 && selectedUsersFilter[0] && selectedUsersFilter[0].role_id === RolesEnum.MasterLead)
      return allItems.filter(s => stages.MASTER_LEAD_DEALS.some(ss => ss.id === s.id));
    return [];
  };

  private modalReset() {
    this.dateFrom = null;
    this.dateTo = null;
    this.isIncludeExportedLeads = false;
    this.isIncludeUnexportedLeads = false;
    this.isSameBuildingExport = false;
    this.isCurrentViewExport = false;
    this.isShowValidationMessages = false;
    this.fileType = "csv"; //TODO refactoring
    this.exportType = "Referrals"; //TODO refactoring
    this.exportDealsRangeOption = "RangeOrUnexported"; //TODO refactoring
    this.stagesList = this.referralStages.reverse();
    this.exportDealLimit = null;
    this.getStagesFilterData();
    this.stageFilter.selectAllData();
  }

  private ok() {
    if (this.confirmAction) {
      this.confirmAction(this.fileType);
    }
  }

  private cancel() {
    this.fileType = null;
    this.closeModal.emit();
    this.modal.close();
  }

  getStagesFilterData() {
    if (this.stageFilter) {
      this.filteredStages = [];
      this.filteredStages = this.filteredStages.concat(this.stagesList);
      this.filteredStagesMultiSelect.next(this.stagesList.slice());
      this.stageFilter.filteredData;
      this.stageFilter.resetSelectorAllData();
      this.stageFilter.setData(this.filteredStages);
    }
  }

  getStagesAutocomplete(event) {
    let search = event.searchString;
    if (!search) {
      this.filteredStagesMultiSelect.next(this.stagesList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredStagesMultiSelect.next(
      this.stagesList.filter(loc => loc.name.toLowerCase().indexOf(search) > -1)
    );
    this.filteredStages = [];
    this.filteredStagesMultiSelect.subscribe(x => {
      for (let i = 0; i < x.length; i++) {
        if (this.filteredStages.length == 0) {
          this.filteredStages.push(x[0]);
        } else {
          if (!this.filteredStages.includes(x[i], 0)) {
            this.filteredStages.push(x[i]);
          }
        }
      }
    });
    this.stageFilter.setData(this.filteredStages);
  }

  public openExportModal(confirmCallback) {
    this.modalReset();
    this.confirmAction = confirmCallback;
    this.modal.open();
  }

  public confirm(isConfirm) {
    if (isConfirm) {
      if (!(this.stagesValidation() && this.dateFilterValidation() && this.amountValidation())) {
        this.isShowValidationMessages = true;
        return;
      }
      else {
        this.isShowValidationMessages = false;
        this.ok()
      }
    }
    else {
      this.cancel()
    }
    this.modal.close();
  }

  public stagesValidation() {
    if (this.stageFilter.dataArray && this.stageFilter.dataArray.length > 0) {
      return true;
    }
    return false;
  }

  public dateFilterValidation() {
    return this.currentUser.role_id !== RolesEnum.MasterLead || this.isIncludeUnexportedLeads || this.isIncludeExportedLeads;
  }

  public amountValidation() {
    return !this.isCurrentViewExport || this.exportDealLimit == null || (this.exportDealLimit > 0 && this.exportDealLimit <= 10000);
  }

  public onDate(): void {
    if (this.dateFrom > this.dateTo || this.dateFrom > this.maxDate) {
      this.isDateFromValide = false;
    }
    else if (!this.isDateFromValide) {
      this.isDateFromValide = true;
    }

    if (this.dateTo < this.dateFrom || this.dateTo > this.maxDate) {
      this.isDateToValide = false;
    }
    else if (!this.isDateToValide) {
      this.isDateToValide = true;
    }
  }

  filterDateFrom = (d: Date): boolean => {
    return (this.dateTo && d <= this.dateTo && d <= this.maxDate) || (!this.dateTo && d <= this.maxDate);
  }

  filterDateTo = (d: Date): boolean => {
    return (this.dateFrom && d >= this.dateFrom && d <= this.maxDate) || (!this.dateFrom && d <= this.maxDate);
  }

  radioChange(event) {
    console.log(event)
    this.exportType = event.value;
    this.isSameBuildingExport = this.exportTypes[this.exportType] == this.exportTypes.SameBuildingLeads;
    this.isCurrentViewExport = this.exportTypes[this.exportType] == this.exportTypes.CurrentViewLeads;

    switch (ExportDealsType[this.exportType]) {
      case ExportDealsType.Referrals: this.stagesList = this.referralStages.reverse(); this.getStagesFilterData(); this.stageFilter.selectAllData(); break;
      case ExportDealsType.Clients: this.stagesList = this.clientsStages.reverse(); this.getStagesFilterData(); this.stageFilter.deselectAllData(); break;
      case ExportDealsType.ColdLeads: this.stagesList = this.coldStages.reverse(); this.getStagesFilterData(); this.stageFilter.selectAllData(); break;
      case ExportDealsType.SameBuildingLeads: this.stagesList = this.sameBuildingStages.reverse(); this.getStagesFilterData(); this.stageFilter.selectAllData(); break;
      case ExportDealsType.CurrentViewLeads: break;
    }
  }
}
