import { UserContextService } from './../core/services/user-context.service';
import { Component, OnInit } from '@angular/core';
import { filter } from 'rxjs/operators';
import { ActivationEnd, Router, ActivatedRoute } from '@angular/router';
import { User } from '../core/models/UserModel';
import { RolesEnum } from '../core/enums/RolesEnum';
import { TasksService } from '../core/services/tasks.service';

@Component({
  selector: 'pd-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss']
})
export class TasksComponent implements OnInit {
  public totalWithoutGender: number;
  public totalWithoutNumber: number;
  public spinner: boolean = false;
  public genderTotalSpinner: boolean = false;
  public numberTotalSpinner: boolean = false;

  public isShowError = false;
  public isChildActive = false;

  public currentUser: User;
  public RolesEnum = RolesEnum;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private taskService: TasksService,
    private userContextService: UserContextService) { }

  ngOnInit() {
    this.currentUser = this.userContextService.user.value;
    this.isChildActive = (this.route.snapshot as any)._routerState.url !== '/tasks';
    this.router.events.pipe(
      filter(event => event instanceof ActivationEnd)
    ).subscribe(() => this.isChildActive = (this.route.snapshot as any)._routerState.url !== '/tasks');
  }

  public async getWithoutGenderTotalCount(): Promise<void> {
    this.genderTotalSpinner = true;
    try {
      const result = await this.taskService.getWithoutGenderTotalCount();
      this.totalWithoutGender = result;
      this.isShowError = false;
      this.genderTotalSpinner = false;
    }
    catch {
      this.genderTotalSpinner = false;
    }

  }

  public async getWithoutNumberTotalCount(): Promise<void> {
    this.numberTotalSpinner = true;
    try {
      const result = await this.taskService.getWithoutNumberTotalCount();
      this.totalWithoutNumber = result;
      this.isShowError = false;
      this.numberTotalSpinner = false;
    }
    catch {
      this.numberTotalSpinner = false;
    }
  }
}
