import { UserStoreService } from 'src/app/core/services/stores/user-store.service';
import { UserContextService } from 'src/app/core/services/user-context.service';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { saveAs } from 'file-saver';
import { Router } from '@angular/router';
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';
import { ExportService } from 'src/app/core/services/export.service';
import { User } from 'src/app/core/models/UserModel';

@Component({
  selector: 'pd-export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.scss'],
  animations: [
    trigger('exportFileModal', [
      state('in', style({
        transform: 'translate(0,0)'
      })),
      state('out', style({
        transform: 'translate(100%, 0)'
      })),
      transition('out => in', animate('400ms ease-in-out')),
      transition('in => out', animate('400ms ease-in-out'))
    ])
  ]
})
export class ExportComponent implements OnInit {
  public campaignAccountDealName = "_ExportCampaignLeads";
  public directMailAccountDealName = "_ExportDirectMailLeads";
  public maxCountDealsForExport = 10000;
  public deals = [];
  public spinner = false;
  public exportNullResult = null;
  public campaignFields = ["First name", "Last name", "Linkedin URL", "Connection in Common*", "Connection in Common*", "Connection in Common*"];
  public directMailFields = ["First name", "Last name", "Company", "CompanyAddress"];
  public totalCountDeals;
  @Input() exportFileModal = 'out';
  public countDeals: number;
  private locations = [];
  private companies = [];
  private positions = [];
  private stagesToExport: number[];
  private filterUserIds = [];
  private isSameBuildingFilter = false;
  private isWithPhoneNumberOnlyFilter = false;
  @Output() exportFile = new EventEmitter<object>();
  @Output() closeModal = new EventEmitter<object>();
  private currentUserId: any;
  public currentUserFilter: User[];
  public isCountOfDealsValid = false;

  constructor(
    private exportService: ExportService,
    public router: Router,
    private userContextService: UserContextService,
    private userStoreService: UserStoreService) {
    
  }

  ngOnInit() {
    this.initializeStore();
  }

  private initializeStore() {
    this.currentUserId = this.userContextService.user.value.id;
    this.currentUserFilter = this.userStoreService.selectedUsersFilter.value;
  }

  public exportDeals() {
    this.exportFile.emit(null);
    this.spinner = true;
    if (this.filterUserIds.length === 0) {
      this.filterUserIds.push(this.currentUserId);
    }
    this.exportService.exportDeals(
      this.stagesToExport
      , this.countDeals
      , this.locations
      , this.filterUserIds
      , this.companies
      , this.positions
      , this.isSameBuildingFilter
      , this.isWithPhoneNumberOnlyFilter
    ).then(result => {
      if (result) {
        saveAs(result, 'ExportLeads.csv');
        this.spinner = false;
      }
      else {
        this.spinner = false;
        this.exportNullResult = 'Deals for export not found';
      }
      this.hideExportModal();
      this.closeModal.emit();
    });
  }

  public openExportModal() {
    this.exportFileModal = 'in';
  }

  public hideExportModal() {
    this.spinner = false;
    this.exportFileModal = 'out';
    this.countDeals = null;
    this.exportNullResult = null;
  }

  public setExportData(location, stagesToExport, filterUserIds, companies, positions, isSameBuildingFilter, isWithPhoneNumberOnlyFilter) {
    this.locations = location;
    this.companies = companies;
    this.positions = positions;
    this.stagesToExport = stagesToExport;
    this.filterUserIds = filterUserIds;
    this.isSameBuildingFilter = isSameBuildingFilter;
    this.isWithPhoneNumberOnlyFilter = isWithPhoneNumberOnlyFilter;
  }

  changeCountDealsForExport() {
    this.isCountOfDealsValid = Number.isInteger(this.countDeals) && (this.countDeals > 0) && this.countDeals <= this.maxCountDealsForExport
  }
}
