import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { HttpRestService } from '../http-client/http-rest-client.service';

@Injectable()
export class BulkEmailRequestService extends BaseService {

	constructor(private restService: HttpRestService) {
		super();
	}

	public async getSimpleDealInfo(dealId: number): Promise<number> {
		const url = '/bulkEmailRequest/simple-deal-info/' + dealId;
		return await this.handleResponse(this.restService.get({ url }));
	}

	public async sendPreviewEmail(data): Promise<boolean> {
		const url = '/bulkEmailRequest/send-preview-email';
		return await this.handleResponse(this.restService.post({ url, data }));
	}
}
