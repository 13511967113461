import { Component, Input, Output, EventEmitter, ViewContainerRef, OnInit } from '@angular/core';
import { AlertTypes } from 'src/app/core/enums/AlertTypes';

@Component({
  selector: 'pd-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss']
})
export class AlertModalComponent implements OnInit {
  @Input() public type: AlertTypes;
  @Input() public message: string;
  @Input() public buttonText: string;
  @Output() public onClose: EventEmitter<void> = new EventEmitter<void>();

  public AlertTypes = AlertTypes;
  public icon: string;

  constructor(public viewContainerRef: ViewContainerRef) { }

  ngOnInit(): void {
    switch(this.type) {
      case AlertTypes.Success: this.icon = 'fa-check'; break;
      case AlertTypes.Error: this.icon = 'fa-xmark'; break;
      case AlertTypes.Warning: this.icon = 'fa-exclamation'; break;
    }
  }

  public closeAlert() {
    this.onClose.emit();
  }
}
