<div class="row wrapper">
  <div class="col-4 title">
    <span style="color: red; font-size: 20px">*</span> Name
  </div>
  <div class="col-8 work-field">
    @if (!name && !isEdit) {
    <pd-add-value (addValue)="addValue()"></pd-add-value>
    }
    @if (isEdit || name) {
    <div class="md-form" (document:focusout)="changeEdit()">
      <input [(ngModel)]="name" mdbActive type="text" required #inputName="ngModel">
      @if (inputName.invalid && inputName.touched) {
      <span style="color: red">Enter your name</span>
      }
    </div>
    }
  </div>
</div>