<div class="header">
    <h2>Client Settings</h2>
</div>
<div class="wrapper col">
    <div class="row" [ngClass]="{ 'hidden': isCountResponseWait }">

        <div style="padding-left: 30px;" class="col-md-6 col-xl-3">
            <div class="row">
                <div class="col setting-label">
                    Assign clients by rating
                </div>
            </div>
            <div style="transform: translateX(-10px);" class="row">
                @for (rating of ratings; track rating) {
                <div class="col-12 checkbox">
                    <mat-checkbox [(ngModel)]="rating.checked">{{ rating.label
                        }} ({{rating.count}})</mat-checkbox>
                </div>
                }
            </div>
        </div>

        <div class="col-md-6 col-xl-2">
            <div class="row">
                <div class="col setting-label">
                    To whom
                </div>
            </div>
            <mat-radio-group class="row tp-radio-group" [(ngModel)]="selectedRotation">
                @for (rotation of rotations; track rotation) {
                <mat-radio-button class="col-12 tp-radio-button" (change)="rotationRadioChange()"
                    [value]="rotation.value">{{rotation.label}}
                </mat-radio-button>
                }
            </mat-radio-group>
        </div>

        <div class="col-md-6 col-xl-2">
            <div class="row">
                <div class="setting-label">
                    From what location
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <pd-location-chips-filter #locationFilter (close)="closeSelectFilter()"
                        (filterDataChanged)="getLocations($event)"
                        [isAllSelectedDefaultMode]="true"></pd-location-chips-filter>
                </div>
            </div>
        </div>

        @if (isMasterLeadAccount) {
        <div class="col-md-6 col-xl-2">
            <div class="row">
                <div class="setting-label">
                    User Filter
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <pd-custom-select-without-lazy #userFilter (loadDataEvent)="getUsersFilterData()"
                        (selectedItemEvent)="onSelectedItem($event)" (autoCompleteEvent)="getUsersAutocomplete($event)"
                        [dataLabel]="usersLabel" [startLabel]="usersLabel" [isMultiple]="false">
                    </pd-custom-select-without-lazy>
                </div>
            </div>
        </div>
        }

        <div class="col-1">
            <div class="row">
                <button style="max-width: 100px;" class="btn btn-success waves-light" [disabled]="!(validation())"
                    (click)="onAdd()">Add</button>
            </div>
        </div>
    </div>

    @if (isCountResponseWait) {
    <div class="spinner">
        <mat-spinner></mat-spinner>
    </div>
    }

    @if (!spinner) {
    <div class="row">
        <div>
            <div class="rules-table">
                <div class="rules-table-label">
                    Active rules
                </div>
                <div class="rules-table-header row">
                    <div class="col cell">Assign clients by rating</div>
                    <div class="col cell">To whom</div>
                    <div class="col cell">From location</div>
                    <div class="col-2 cell">Actions</div>
                </div>
            </div>
        </div>
        <div class="content-wrapper">
            <div class="content">
                <div class="rules-table">
                    @for (rule of rules; track rule) {
                    <div class="rules-table-content row">
                        <div class="col cell">
                            @for (rating of rule.ratingList; track rating) {
                            <div>
                                {{getRatingLabel(rating)}}
                            </div>
                            }
                        </div>
                        <div class="col cell">{{getStandardRotationLabel(rule.standardRotation)}}</div>
                        <div class="col cell">
                            @for (location of rule.locations; track location) {
                            <div>
                                {{location.largeLabel}}
                            </div>
                            }
                        </div>
                        <div class="col-2 cell">
                            <div class="row"><button class="btn btn-danger waves-light ml-0"
                                    (click)="onRemove(rule.id)">Remove</button></div>
                        </div>
                    </div>
                    }
                </div>
            </div>
        </div>
    </div>
    }
    @if (spinner) {
    <div class="spinner">
        <mat-spinner></mat-spinner>
    </div>
    }
</div>