<div>
  <form (ngSubmit)="submitImport()" [formGroup]="importForm">
    <div class="import-radio-number">
      <span class="title">Import type:</span>
      <div>
        <mat-radio-group [value]="importTypes.IMPORT_TYPES_DICT[0]">
          @for (type of importTypes.IMPORT_TYPES_DICT; track type) {
          <mat-radio-button [value]="type" (click)="selectImportType(type)">
            {{type.label_name}}
          </mat-radio-button>
          }
        </mat-radio-group>
      </div>
      <div class="message">{{selectedImportType.message}}</div>
      <div>
        <input type="file" (change)="onFileSelected($event)">
      </div>
      @if (uploadFileExtension && uploadFileExtension !== 'csv') {
      <div class="message">Please select another file. Only
        csv files are acceptable for import.</div>
      }
      @if (isGeneratingToken) {
      <div class="generateToken">
        <div>
          <label class="message">You cannot upload this file. Please log in to your Dropbox account and generate access
            key</label>
        </div>
        <button type="button" class="btn btn-primary waves-light"
          (click)="goToLink('https://www.dropbox.com/oauth2/authorize?client_id=v5ldhi1p5x4itxv&response_type=code');$event.stopPropagation()"
          [disabled]="dropboxAccessToken">Generate token</button>
        <mat-form-field>
          <input matInput (change)="onTokenChanged($event)" placeholder="Type generated code here" required>
        </mat-form-field>
      </div>
      }
      @if (isWrongAccessToken) {
      <p style="color: red; font-weight: 400; padding-top: 20px; font-size: 22px">Invalid
        token. Please try again</p>
      }
      @if (uploadFileResult && uploadFileResult.isSuccessful) {
      <p style="color: green; font-weight: 400; padding-top: 20px; font-size: 22px">{{uploadFileResult.message}}</p>
      }
      @if (uploadFileResult && !uploadFileResult.isSuccessful) {
      <p style="color: red; font-weight: 400; padding-top: 20px; font-size: 22px">{{uploadFileResult.message}}</p>
      }
    </div>
    <div class="submit">
      <button class="btn btn-success waves-light" type="submit"
        [disabled]="isUploadingUnavailable || (isGeneratingToken && !dropboxAccessToken)"
        (click)="onUploadFile()">Upload File</button>
      <button class="btn btn-danger waves-light" [routerLink]="['/', tasksRoute]">Cancel</button>
    </div>
  </form>
</div>