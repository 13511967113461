import { Component, OnInit, ViewChild, ElementRef, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Modeler3dCard, Modeler3dCardType } from '../core/models/Modeler3dCardConfig';
import { Modeler3dProductService } from '../core/services/3d-modeler-product.service';
import { ProductTypes } from '../core/enums/client-card/ProductTypes';

@Component({
  selector: 'pd-3d-modeler-product',
  templateUrl: './3d-modeler-product.component.html',
  styleUrls: ['./3d-modeler-product.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class Modeler3dProductComponent implements OnInit {
  @ViewChild('clientExportCanvas') canvas: ElementRef;

  modeler3dCardTypes: Array<Modeler3dCardType>;
  modeler3dCards: Array<Modeler3dCard>;

  fabricCardByProduct: Array<Array<Modeler3dCard>>;

  constructor(private cdRef: ChangeDetectorRef, private serv: Modeler3dProductService) {
  }

  ngOnInit() {
    this.serv.getModeler3dCardConfig().then(config => {
      if (config !== null) {
        this.fabricCardByProduct = new Array<Array<Modeler3dCard>>();

        let modeler3dCardConfig = config;

        this.modeler3dCardTypes = modeler3dCardConfig.modeler3dCardTypes;
        this.modeler3dCards = modeler3dCardConfig.modeler3dCards;

        modeler3dCardConfig.modeler3dCardTypes.forEach(type => {
          this.fabricCardByProduct[type.typeId] = this.filterCards(type.typeId)
        });
      }
      this.cdRef.detectChanges();
    });
  }

  ngOnDestroy() {
  }

  filterCards(productTypeId: ProductTypes): Array<Modeler3dCard> {
    if (this.modeler3dCards) {
      let cards = this.modeler3dCards.filter(c => c.appliesTo === productTypeId);
      return cards;
    }
    return new Array<Modeler3dCard>();
  }
}

