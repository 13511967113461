import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { InputField } from '../../models/InputField';

@Component({
  selector: 'pd-field-password',
  templateUrl: './field-password.component.html',
  styleUrls: ['./field-password.component.scss']
})
export class FieldPasswordComponent {

  public password: string;
  @ViewChild("inputPassword") inputPassword;
  @Output() passwordChange = new EventEmitter<InputField>();
  /**
    * Change edit
    * in user
    * @memberof FieldNameComponent
    */

  public changeEdit() {
    let model = {
      value: this.password,
      isValid: this.inputPassword.valid
    }
    this.passwordChange.emit(model);
  }
}
