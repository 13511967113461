import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'pd-field-intl-tel-input',
  templateUrl: './field-intl-tel-input.component.html',
  styleUrls: ['./field-intl-tel-input.component.scss']
})
export class FieldIntlTelInputComponent implements OnInit, AfterViewInit {
  @ViewChild('inputField') inputField: ElementRef;
  @Input() phone: string;
  @Output() phoneChange = new EventEmitter<Object>();

  private iti: any;
  private utils: any;
  private globals: any;
  private countries: any;
  private errorMap = ["Invalid number", "Invalid country code", "Too short", "Too long", "Is possible local only", "Invalid length"];
  private preferredCountries = ["au", "at", "be", "bg", "ca", "cz", "ee", "fi", "fr", "de", "hk", "it", "nl", "nz", "no", "es", "ch", "ae", "us",];

  public isSpinner = false;
  public isShowValidationMessages = false;
  public validationMessages = "";

  constructor() { }

  ngOnInit() {
    this.isSpinner = true;
    setTimeout(() => {
      this.utils = (<any>window).intlTelInputUtils;
      this.isSpinner = false;
    }, 1000);
    this.globals = (<any>window).intlTelInputGlobals;
    this.countries = this.globals.getCountryData();
  }

  ngAfterViewInit() {
    this.setIntlTelInputConfig();
  }

  public resetValidation() {
    this.isShowValidationMessages = false;
  }

  public checkIsValid() {
    this.resetValidation();
    let phone = this.inputField.nativeElement.value.trim();
    if (this.iti && phone) {
      if (!this.validateLetters(phone)) {
        this.isShowValidationMessages = true;
        this.validationMessages = "Letters are only allowed for phone extensions (ext or x)"
      }
      else
        if (!this.iti.isValidNumber()) {
          var errorCode = this.iti.getValidationError();
          if (this.inputField.nativeElement.value.trim().length === 1) {
            this.validationMessages = "Too short";
          }
          else
            if (errorCode === -99) {
              this.validationMessages = "Invalid number";
            }
            else {
              this.validationMessages = this.errorMap[errorCode];
            }
          this.isShowValidationMessages = true;
        }
    }
    return !this.isShowValidationMessages;
  }

  private setIntlTelInputConfig() {
    let main = this;
    let input = this.inputField.nativeElement;

    setTimeout(() => {
      main.iti = (<any>window).intlTelInput(input, {
        autoPlaceholder: "aggressive",
        placeholderNumberType: "FIXED_LINE_OR_MOBILE",
        dropdownContainer: document.body,
        formatOnDisplay: true,
        nationalMode: true,
        separateDialCode: true,
        preferredCountries: main.preferredCountries,
        utilsScript: "//cdnjs.cloudflare.com/ajax/libs/intl-tel-input/19.2.19/js/utils.js",
        initialCountry: (main.isHasCountry()) ? "" : "auto",
        geoIpLookup: function (success, failure) {
          fetch("https://ipinfo.io/json?token=" + environment.ipInfoToken, {
            method: 'GET',
            mode: 'cors',
          })
            .then(response => response.json())
            .then(data => {
              var countryCode = (data && data.country) ? data.country : "us";
              success(countryCode);
            })
            .catch(error => {
              failure(error);
            });
        },
      });
      setTimeout(() => {
        main.checkIsValid();
      }, 100);
    }, 100);
  }

  private isHasCountry(): boolean {
    const normalizePhone = this.phone.replace(/\s/g, '');
    return this.countries.some(country => normalizePhone.startsWith("+" + country.dialCode));
  }

  private validateLetters(phone) {
    const pattern = /(^[^a-zA-Z]*(((ext|ex|x)(|\.|\:|)(| )\d+)))$|(^[^a-zA-Z]*)$/;
    const regex = new RegExp(pattern, 'i');
    return regex.test(phone);
  }

  public onChange() {
    this.resetValidation();
    let phone = this.phone;
    if (phone && this.checkIsValid()) {
      phone = this.iti.getNumber(this.utils.numberFormat.INTERNATIONAL);
    }
    this.phoneChange.emit({ phone: phone });
  }
}
