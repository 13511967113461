import { UsersService } from './../core/services/users.service';
import { GeolacationService } from './../core/services/geolocation.service';
import { UserContextService } from './../core/services/user-context.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { CustomSelectFilterWithoutLazyComponent } from '../shared/custom/custom-select-without-lazy/custom-select-without-lazy';
import { NearbyCompaniesModel } from '../core/models/NearbyCompaniesModel';
import { RolesEnum } from '../core/enums/RolesEnum';
import { ReplaySubject } from 'rxjs';
import { User } from '../core/models/UserModel';

@Component({
  selector: 'pd-geocode-nearby-deals',
  templateUrl: './geocode-nearby-deals.component.html',
  styleUrls: ['./geocode-nearby-deals.component.scss']
})
export class GeocodeNearbyDealsComponent implements OnInit {
  @ViewChild("userFilter") customSelectUser: CustomSelectFilterWithoutLazyComponent;

  nearbyCompanies = new Array<NearbyCompaniesModel>();
  address = "";
  geocode = "";
  spinner = false;
  latitude = "";
  longitude = "";
  geocodeControl = new FormControl('', [Validators.required, Validators.pattern(/^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/)]);
  addressControl = new FormControl('', [Validators.required]);
  isMapStart = false;
  RolesEnum = RolesEnum;

  usersLabel: string = 'Clothiers'

  filteredUserMultiSelect: ReplaySubject<User[]> = new ReplaySubject<User[]>(1);
  filteredUsers = [];
  filterUserIds = [];

  currentUser: User;

  allFilteredUsers = [];

  constructor(
    private userContextService: UserContextService,
    private geolacationService: GeolacationService,
    private usersService: UsersService) { }

  async ngOnInit(): Promise<void> {
    this.currentUser = this.userContextService.user.value;
    this.allFilteredUsers = (await this.usersService.getUsers()).filter(item => item.role_id === RolesEnum.Admin
      || item.role_id === RolesEnum.Clothier
      || item.role_id === RolesEnum.MasterLead);
    this.filteredUsers = this.allFilteredUsers;
    this.getUsersFilterData();
  }

  public onSearchByGeocode() {
    if (this.customSelectUser) {
      this.filterUserIds = this.customSelectUser.dataArray.map((u) => u.id);
    }

    if (!this.geocodeControl.valid) {
      alert("Invalid input. Please check coordinates/radius and try again. Thank you!");
      return;
    }
    this.isMapStart = false;

    setTimeout(() => {
      this.latitude = this.geocode.split(',')[0];
      this.longitude = this.geocode.split(',')[1];
      this.isMapStart = true;
    }, 500);
  }

  public async onSearchByAddress(): Promise<void> {
    if (this.customSelectUser) {
      this.filterUserIds = this.customSelectUser.dataArray.map((u) => u.id);
    }

    if (!this.addressControl.valid) {
      alert("Invalid input. Please check address and try again. Thank you!");
      return;
    }

    this.isMapStart = false;
    this.spinner = true;
    const result = await this.geolacationService.getGeocodeByAddress(this.address);
    this.spinner = false;
    if (!result || !result.lat || !result.lng) {
      alert("INVALID_REQUEST");
      return;
    }
    this.latitude = result.lat;
    this.longitude = result.lng;
    this.isMapStart = true;
  }

  getUsersFilterData() {
    this.filteredUsers = [];
    this.filteredUsers = this.filteredUsers.concat(this.allFilteredUsers);
    this.filteredUserMultiSelect.next(this.allFilteredUsers.slice());
    if (this.customSelectUser) {
      this.customSelectUser.setData(this.filteredUsers);
      this.customSelectUser.selectItem(this.currentUser, { checked: true });
    }
  }

  getUsersAutocomplete(event) {
    let search = event.searchString;
    if (!search) {
      this.filteredUserMultiSelect.next(this.allFilteredUsers.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredUserMultiSelect.next(
      this.allFilteredUsers.filter(loc => loc.name.toLowerCase().indexOf(search) > -1)
    );
    this.filteredUsers = [];
    this.filteredUserMultiSelect.subscribe(x => {
      for (let i = 0; i < x.length; i++) {
        if (this.filteredUsers.length == 0) {
          this.filteredUsers.push(x[0]);
        } else {
          if (!this.filteredUsers.includes(x[i], 0)) {
            this.filteredUsers.push(x[i]);
          }
        }
      }
    });
    this.customSelectUser.setData(this.filteredUsers);
  }
}