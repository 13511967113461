import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { GlobalConstants } from 'src/app/core/global-constants';
import { PaymentTransactionInfo } from 'src/app/core/models/payment-models/PaymentTransactionInfo';
import { PaymentUtil } from 'src/app/core/utils/payment.util';
import { ModalComponent } from 'src/app/shared/modal/modal.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'pd-stripe-modal',
  templateUrl: './stripe-modal.component.html',
  styleUrls: ['./stripe-modal.component.scss']
})
export class StripeModalComponent implements OnInit {
  @Output() cancelBtn = new EventEmitter<object>();
  @Output() paymentSuccess = new EventEmitter();
  @Output() statusUpdated = new EventEmitter<PaymentTransactionInfo>();

  @ViewChild("modal") modal: ModalComponent;

  private stripe: any;
  private elements = null;
  private transactionId = null;
  private clientSecret = null;

  public isSpinner = false;
  public isError = false;
  public paymentMessage: string;

  ngOnInit() {
    import('@stripe/stripe-js').then(async (stripeModule: any) => {
      this.stripe = await stripeModule.loadStripe(environment.stripePublicKey);
    });
  }

  public openModal(transactionId: string, clientSecret: string) {
    this.paymentMessage = null;
    this.isError = false;
    this.transactionId = transactionId;
    this.clientSecret = clientSecret;
    this.initForm();
    this.modal.open();
  }

  public close() {
    this.transactionId = null;
    this.clientSecret = null;
    this.elements = null;
    this.paymentMessage = null;
    this.isError = false;
    this.modal.close();
    Array.from(document.getElementsByTagName("iframe")).forEach(x => x.remove());
  }

  public onClose() {
    this.cancelBtn.emit();
    this.close();
    event.preventDefault();
  }

  initForm() {
    if (!this.clientSecret) {
      return;
    }

    this.elements = this.stripe.elements({
      appearance: {
        theme: 'stripe',
      }, clientSecret: this.clientSecret
    });

    const paymentElementOptions = {
      layout: 'tabs',
    };

    const paymentElement = this.elements.create('payment', paymentElementOptions);
    paymentElement.mount('#pd-stripe-payment-element');
  }

  async handleSubmit(e: Event) {
    this.paymentMessage = null;
    this.isError = false;
    e.preventDefault();

    this.isSpinner = true;

    const { error } = await this.stripe.confirmPayment({
      elements: this.elements,
      confirmParams: {
        return_url: window.location.origin + '/' + GlobalConstants.ROUTE_NAMES.PaymentResult,
      },
      redirect: 'if_required',
    });

    if (error) {
      this.paymentMessage = PaymentUtil.getStripeErrorMessage(error);
      this.isError = true;
    }
    else {
      await this.checkStatus()
    }

    this.statusUpdated.emit(new PaymentTransactionInfo({ transactionId: this.transactionId, transactionMessage: this.paymentMessage }));

    if (this.paymentMessage === 'Success') {
      this.paymentSuccess.emit();
      this.close();
    }

    this.isSpinner = false;
  }

  async checkStatus() {
    if (!this.clientSecret) {
      return;
    }

    const { paymentIntent } = await this.stripe.retrievePaymentIntent(this.clientSecret);
    this.paymentMessage = PaymentUtil.getStatusMessage(paymentIntent.status);
    this.isError = PaymentUtil.isStatusMessageError(paymentIntent.status);
  };
}