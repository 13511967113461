import { Component, EventEmitter, Output, ViewChild, Input } from '@angular/core';
import { InputField } from '../../models/InputField';

@Component({
  selector: 'pd-field-email',
  templateUrl: './field-email.component.html',
  styleUrls: ['./field-email.component.scss']
})
export class FieldEmailComponent {

  @ViewChild("inputEmail") inputEmail;
  @Input() email: string;
  @Output() emailChange = new EventEmitter<InputField>();

  public changeEdit() {
    let model = {
      value: this.email,
      isValid: this.inputEmail.valid
    }
    this.emailChange.emit(model);
  }
}
