<mat-expansion-panel class="modeler-product-card" #expansionPanel (opened)="isExpandedOnce = true">
  <mat-expansion-panel-header>
    <mat-panel-title>
      <span class="card-label">
        {{cardLine.name}}
        @if (getCardCount() > 0) {
        <span>&nbsp;({{getLoadedCardCount()}}/{{getCardCount()}})</span>
        }
      </span>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <mat-panel-description>
    @if (expansionPanel.expanded || isExpandedOnce) {
    <div>
      <div class="wrapper" [ngStyle]="{'overscroll-behavior': selectLazyLoading ? 'none' : 'auto'}"
        (scroll)="doLazyLoading($event)">
        <div class="expansion-panel-body">
          @for (card of filteredCards; track card) {
          <div>
            <div class="tile">

              <div class="tile-title">
                <span class="float-left">{{card.title.slice(0, 15) + ((card.title.length > 15) ? '...' : '')}}</span>
              </div>

              <div class="tile-body">
                <div>

                  <div class="relative">
                    <div class="image">
                      <img [src]="card.fabricImgUrl"
                        onerror="this.onerror=null; this.src='../../assets/lgfg-logo.png'" />
                    </div>
                  </div>

                </div>
              </div>

            </div>
          </div>
          }
        </div>
      </div>
    </div>
    }
  </mat-panel-description>
</mat-expansion-panel>