import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { EditInProgressComponent } from '../field-landing/edit-in-progress/edit-in-progress.component'
import { CustomSelectFilterComponent } from 'src/app/shared/custom/custom-select-filter/custom-select-filter.component';
import { DealService } from 'src/app/core/services/deals.service';
import { FilterType } from 'src/app/core/enums/FilterType.enum';

@Component({
  selector: 'pd-field-company',
  templateUrl: './field-company.component.html',
  styleUrls: ['./field-company.component.scss']
})
export class FieldCompanyComponent implements OnInit {

  @Output() company: string = "Companies"
  @Output() companyChange = new EventEmitter<string>()
  @Output() open = new EventEmitter<string>();
  public companiesLabel: string = 'Companies'
  @ViewChild("companyFilter") customSelectCompany: CustomSelectFilterComponent
  @ViewChild("companyEdit") companyEdit: EditInProgressComponent
  public isEdit = false
  public isSelectedCompany = false

  constructor(private dealService: DealService) { }

  ngOnInit() {
    this.isSelectedCompany = false
  }

  /**
    * Add value
    * open edit
    * @memberof FieldCompanyComponent
    */

  public addValue() {
    this.isEdit = true;
  }

  public changeValue() {
    this.emitChangeValue(null)
  }

  public closeEdit() {
    this.isEdit = false
  }

  onChanged(isSelectedItem:boolean){
    this.isSelectedCompany = isSelectedItem
    this.emitChangeValue(null)
  }

  emitChangeValue(location: string) {
    if (location === null) {
      if(this.customSelectCompany.localChecked.length > 0) {
        this.companyChange.emit(this.customSelectCompany.localChecked[0])
      } else {
        this.companyChange.emit('')
      }  
    } else {
      this.companyChange.emit(location)
    }
  }

  saveCompany(company:string){
    this.company = company
    this.emitChangeValue(company)
    this.companyEdit.isComplete = true
  }

  cancelNewCompany(){
    this.isEdit = false
  }

  editValue(){
    this.companyEdit.isComplete = false
  }

  getCompaniesAutocomplete(event){
    let isNotleter:number=event.isNotLetter;
    this.dealService.getFilterDataAutocomplete(FilterType.Company, event.searchString, event.skip, event.take).then(response=>{
      if(isNotleter==this.customSelectCompany.isNotLetter){
        this.customSelectCompany.setData(response);
      }
    });    
  }

  getCompaniesFilterData(event){
    let isNotleter:number=event.isNotLetter;
    this.dealService.getFilterData(FilterType.Company, event.skip, event.take).then(response=>{
      if(isNotleter==this.customSelectCompany.isNotLetter){
        this.customSelectCompany.setData(response);
      }
    });    
  }

  public onAnotherSelectOpen() {
      if(this.customSelectCompany) {
          this.customSelectCompany.isOpenSelect = false;
      }
  }
  
  onSelectOpen() {
    this.open.emit();
  }
}
