<modal #modal [title]="title" [minWidth]="480" primaryBtnTextKey="Save" secondaryBtnTextKey="Close"
  [displayLaunchButton]="false" [isPrimaryBtnDisabled]="!selectedOption && isDateOptions"
  (secondaryClick)="confirm(false)" (onCompleteAction)="confirm(false)" (primaryClick)="confirm(true)">
  @if (isDateOptions) {
  <div>
    <mat-radio-group class="tp-radio-group" [(ngModel)]="selectedOption">
      <mat-radio-button class="tp-radio-button" value="3" (change)="radioChange($event)">In 3 months
      </mat-radio-button>
      <mat-radio-button class="tp-radio-button" value="6" (change)="radioChange($event)">In 6 months
      </mat-radio-button>
      <mat-radio-button class="tp-radio-button" value="customDate" (change)="radioChange($event)">Exact date:
      </mat-radio-button>
    </mat-radio-group>
  </div>
  }
  @if (isCustomDate || !isDateOptions) {
  <div class="col-auto ml-auto flex-center">
    <mat-form-field>
      <input matInput [matDatepicker]="picker" [(ngModel)]="selectedDate" placeholder="Date" [min]="minDate"
        [max]="maxDate" readonly>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker disabled="false" startView="year" [startAt]="minDate"
        [opened]="onOpenedDatepicker()"></mat-datepicker>
    </mat-form-field>
  </div>
  }
</modal>