import { PipelineStoreService } from './../../core/services/stores/pipeline-store.service';
import { UserContextService } from './../../core/services/user-context.service';
import { GlobalConstants } from './../../core/global-constants';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FieldStageIdComponent } from '../fields/field-stage-id/field-stage-id.component';
import { FieldUserIdComponent } from '../fields/field-user-id/field-user-id.component';
import { FieldIntlTelComponent } from '../fields/field-intl-tel/field-intl-tel.component';
import { UtilsHelper } from 'src/app/core/utils/utils.helper';
import { DealService } from 'src/app/core/services/deals.service';
import { DateHelper } from 'src/app/core/utils/date.helper';
import { RolesEnum } from 'src/app/core/enums/RolesEnum';
import { FieldEmailsComponent } from '../fields/field-emails/field-emails.component';

@Component({
  selector: 'pd-new-deal',
  templateUrl: './new-deal.component.html',
  styleUrls: ['./new-deal.component.scss']
})

export class NewDealComponent implements OnInit {
  @ViewChild(FieldStageIdComponent) public fieldStage: FieldStageIdComponent;
  @ViewChild(FieldUserIdComponent) public fieldUser: FieldUserIdComponent;
  @ViewChild('fieldIntlTel') public fieldIntlTel: FieldIntlTelComponent;
  @ViewChild('fieldEmails') public fieldEmails: FieldEmailsComponent;

  public ALL_STAGES = GlobalConstants.ALL_STAGES;
  public name: string;
  public phones = [];
  public emails = [];
  public genderId: any;
  public linkedinUrl: string;
  public linkedinLocation: any;
  public position: any;
  public company: any;
  public stageId: number;
  public owner: string;
  public city: string;
  public address: string;
  public homeAddress: string;
  public notes: string;
  public isAdmin = false;
  public isBbAccount = false;
  public exception: string;
  public isNotUseAsCommonConnection = false;
  public spinner = false;
  public pipeplineId: any;
  public whoReferredText: string;
  public meetingDate: Date;
  public addressValidationTooltip = UtilsHelper.addressValidationMessage;

  public newDealId: number;
  public newDealName: string;
  public duplicateDealId: number;
  public isShowSuccessNewDealAlert: boolean = false;
  public isResetForm: boolean = false;

  private timeout: any;

  constructor(
    private dealService: DealService,
    private router: Router,
    private userContextService: UserContextService,
    private pipelineStoreService: PipelineStoreService) { }

  ngOnInit() {
    const currentUser = this.userContextService.user.value;
    this.isAdmin = currentUser.is_admin;
    this.isBbAccount = currentUser.role_id == RolesEnum.BB;

    this.pipeplineId = this.pipelineStoreService.currentPipeline.value ? this.pipelineStoreService.currentPipeline.value.id : 1;
  }

  /**
   * Get name
   * for new deal
   * @param {any} data name of new deal
   * @memberof NewDealComponent
   */

  public getName(data: any): void {
    this.name = data;
  }

  /**
   * Get gender
   * for new deal
   * @param {any} data gender of new deal
   * @memberof NewDealComponent
   */

  public getGender(data: any) {
    this.genderId = data;
  }

  /**
   * Get linkedinUrl
   * for new deal
   * @param {any} data linkedinUrl of new deal
   * @memberof NewDealComponent
   */

  public getLinkedinUrl(data: any) {
    this.linkedinUrl = data;
  }

  /**
   * Get linkedinLocation
   * for new deal
   * @param {any} data linkedinLocation of new deal
   * @memberof NewDealComponent
   */

  public getLinkedinLocation(data: any) {
    this.linkedinLocation = data;
  }

  /**
   * Get company
   * for new deal
   * @param {any} data company of new deal
   * @memberof NewDealComponent
   */

  public getPosition(data: any) {
    this.position = data;
  }

  /**
   * Get company
   * for new deal
   * @param {any} data company of new deal
   * @memberof NewDealComponent
   */

  public getCompany(data: any) {
    this.company = data;
  }

  /**
   * Get stageId
   * for new deal
   * @param {any} data stageId of new deal
   * @memberof NewDealComponent
   */

  public getStageId(data: any) {
    this.stageId = data.id;
    if (data.personWhoReferred != null) {
      this.whoReferredText = data.personWhoReferred;
    }
    if (data.meetingDate != null) {
      this.meetingDate = data.meetingDate;
    }
    if (this.fieldUser !== undefined) {
      this.fieldUser.filterOwnersByStage(this.stageId);
    }
  }

  /**
   * Get userId
   * for new deal
   * @param {any} data userId of new deal
   * @memberof NewDealComponent
   */

  public getUserId(owner: any) {
    this.owner = owner;
    this.fieldStage.filterPipelinesByUser(owner);
  }

  /**
   * Get city
   * for new deal
   * @param {any} data city of new deal
   * @memberof NewDealComponent
   */

  public getCity(data: any) {
    this.city = data;
  }

  /**
   * Get company address
   * for new deal
   * @param {any} data address of new deal
   * @memberof NewDealComponent
   */

  public getCompanyAddress(data: any) {
    this.address = data;
  }

  /**
   * Get home address
   * for new deal
   * @param {any} data address of new deal
   * @memberof NewDealComponent
   */

  public getHomeAddress(data: any) {
    this.homeAddress = data;
  }

  /**
   * Get notes
   * for new deal
   * @param {any} data notes of new deal
   * @memberof NewDealComponent
   */

  public getNotes(data: any) {
    this.notes = data;
  }

  public setPersonWhoReferred(data: string) {
    this.whoReferredText = data;
  }

  /**
   * Save deal
   * @memberof NewDealComponent
   */

  public async saveDeal() {
    this.spinner = true;
    this.exception = null;
    this.duplicateDealId = null;

    const meeting_date = this.meetingDate ? DateHelper.formatAsISODateString(this.meetingDate) : null;
    const meeting_date_utc = this.meetingDate;
    this.phones = this.fieldIntlTel.getPhones();
    this.emails = this.fieldEmails.getEmails();

    const newDeal = {
      name: this.name,
      position: typeof (this.position) != "object" ? this.position : '',
      positionId: typeof (this.position) == "object" ? this.position.id : null,
      company: typeof (this.company) != "object" ? this.company : '',
      companyId: typeof (this.company) == "object" ? this.company.id : null,
      phone: this.phones && this.phones.length > 0 && this.phones.filter(x => x.value) || [],
      notes: this.notes,
      email: this.emails && this.emails.length > 0 && this.emails.filter(x => x.value) || [],
      location: typeof (this.linkedinLocation) != "object" ? this.linkedinLocation : this.linkedinLocation.name,
      locationId: typeof (this.linkedinLocation) == "object" ? this.linkedinLocation.id : null,
      city: this.city,
      linkedin_URL: this.linkedinUrl,
      stage_id: this.stageId,
      address: this.address,
      homeAddress: this.homeAddress,
      genderId: this.genderId,
      user_id: this.owner,
      isNotUseAsCommonConnection: this.isNotUseAsCommonConnection,
      personWhoReferred: this.whoReferredText,
      meeting_date_utc: meeting_date_utc,
      meeting_date: meeting_date
    };

    if (newDeal.name && newDeal.linkedin_URL && (newDeal.location || newDeal.locationId) && (newDeal.company || newDeal.companyId)) {
      try {
        const res = await this.dealService.addDeal(newDeal);
        this.spinner = false;

        this.newDealId = res.dealId;
        this.newDealName = this.name;

        this.showNewDealAlert();
      } catch (error) {
        this.spinner = false;
    
        this.newDealId = null;
        this.newDealName = null;
        this.exception = error.errorMessage;
        this.duplicateDealId = error.duplicateDealId;
      }
    }
  }

  public getDealUrl(dealId: number) {
    return window.location.origin + "/deals/" + dealId;
  }

  public openDealInNewTab(dealId: number) {
    window.open(this.getDealUrl(dealId));
  }
  /**
   * Cancel deal
   * navigate to pipeline
   * @memberof NewDealComponent
   */

  public cancelDeal() {
    this.router.navigate(['/pipeline', this.pipeplineId]);
  }

  isValidLinkedInLocation() {
    if (this.linkedinLocation) {
      if (typeof this.linkedinLocation === "string" && this.linkedinLocation.trim().length === 0) {
        return false
      }
      else if (typeof this.linkedinLocation === "object" && this.linkedinLocation.name === 0 && this.linkedinLocation.name.trim().length === 0) {
        return false
      }
    }
    else if (!this.linkedinLocation) {
      return false
    }
    return true
  }

  isValidCompany() {
    if (this.company) {
      if (typeof this.company === "string" && this.company.trim().length === 0) {
        return false
      }
      else if (typeof this.company === "object" && this.company.name === 0 && this.company.name.trim().length === 0) {
        return false
      }
    }
    else if (!this.company) {
      return false
    }
    return true
  }

  public checkAddressIsValid(address: string) {
    if (!address || address.length == 0) {
      return true;
    }
    return UtilsHelper.checkAddressIsValid(address);
  }

  public checkPhoneIsValid() {
    return !this.fieldIntlTel || this.fieldIntlTel.isAllValidCheck();
  }

  public isCompanyAddressEdit = false;

  public changeCompanyAddressEdit() {
    this.isCompanyAddressEdit = false;
  }

  public addCompanyAddressValue() {
    this.isCompanyAddressEdit = true;
  }

  public isHomeAddressEdit = false;

  public changeHomeAddressEdit() {
    this.isHomeAddressEdit = false;
  }

  public addHomeAddressValue() {
    this.isHomeAddressEdit = true;
  }

  public resetData() {
    this.isResetForm = true;

    this.meetingDate = null;
    this.name = null;
    this.position = null;
    this.company = null;
    this.linkedinLocation = null;
    this.phones = [];
    this.emails = [];
    this.company = null;
    this.genderId = null;
    this.owner = null;
    this.stageId = null;
    this.city = null;
    this.linkedinUrl = null;
    this.isNotUseAsCommonConnection = false;
    this.whoReferredText = null;
    this.address = null;
    this.homeAddress = null;
    this.exception = null;

    setTimeout(() => {
      this.isResetForm = false;
    }, 100);
  }

  private showNewDealAlert() {
    this.isShowSuccessNewDealAlert = true;
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(() => { this.isShowSuccessNewDealAlert = false }, 10000);
  }
}
