<div class="row wrapper">
  <div class="col-4 title">
    Gender
  </div>
  <div class="col-8 work-field">
    @if (genderId == undefined && !isEdit) {
    <pd-add-value (addValue)="addValue()"></pd-add-value>
    }
    @if (isEdit || genderId != undefined) {
    <div class="edit-gender">
      <div class="select">
        <mat-form-field>
          <mat-select>
            @for (item of genderList; track item) {
            <mat-option [value]="item.value" (click)="changeValue(item.key)">{{item.value}}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    }
  </div>
</div>