import { Pipe, PipeTransform } from '@angular/core';
import * as stagesDict from '../../../assets/stages_new_prospespects';
import { Stage } from 'src/app/core/models/Stage';

@Pipe({
    name: 'stagesToPipelines'
})
export class StagesToPipelinesPipe implements PipeTransform {
    transform(stages: Stage[]): any[] {
      if(!stages || stages.length < 1) {
				return [];
			} else {
				let pipelines = stages.map(s => s.pipeline_id);
				let uniquePipelines = pipelines.filter(function(elem, index, self) {
					return index === self.indexOf(elem);
				});
				return uniquePipelines.reduce((result, element) => {
					let pipeline = stagesDict.ALL_PIPELINES.find(s => s.id === element);
					result.push({
						name: pipeline && pipeline.name || '',
						stages: stages.filter(s => s.pipeline_id === element)
					});
					return result;
				}, []);
			}
    }
}