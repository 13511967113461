import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpRestService } from './http-client/http-rest-client.service';

@Injectable()
export class TasksService extends BaseService {

    constructor(private restService: HttpRestService) {
        super();
    }

    public async getWithoutGenderTotalCount(): Promise<any> {
        return await this.handleResponse(this.restService.get({ url: '/deal/getDealsWithoutGenderCount' }));
    }

    public async getWithoutNumberTotalCount(): Promise<any> {
        return await this.handleResponse(this.restService.get({ url: '/deal/getDealsWithoutPhonesCount' }));
    }

    /**
     * Get filtered person without gender
     * by location
     * @param {any} location selected location
     * @returns {Promise<any>} pipedrive deal promise response with deal id
     * @memberof TasksService
     */

    public async RunUpdateGender(locations, count, currentUserId, isSelectAllLocations?: boolean, selectTextLocations?: string): Promise<any> {
        return await this.handleResponse(this.restService.post({
            url: '/test/deals/totalCounts/gender',
            data: {
                Locations: locations,
                IsSelectAllLocations: isSelectAllLocations,
                SelectTextLocations: selectTextLocations,
                Count: count,
                CurrentUserId: currentUserId
            }
        }));
    }

    /**
     * Run update number
     * @param {any} location selected location
     * @param {number} contactType selected location
     * @param {number} gender selected location
     * @param {number} count selected location
     * @param {number} totalCount selected location
     * @returns {Promise<any>} pipedrive deal promise response with deal id
     * @memberof TasksService
     */

    public async RunUpdateNumber(location: any, contactType: number, gender: number, count: number, currentUserId, isSelectAllLocations?: boolean, selectTextLocations?: string): Promise<any> {
        return await this.handleResponse(this.restService.post({
            url: '/test/deals/totalCounts/number',
            data: {
                Locations: location,
                IsSelectAllLocations: isSelectAllLocations,
                SelectTextLocations: selectTextLocations,
                ContactType: contactType,
                GenderId: gender,
                Count: count,
                CurrentUserId: currentUserId
            }
        }));
    }

    public async targetRecall(filterUserIds: any, locations: any, isSelectAllLocations?: boolean, selectTextLocations?: string): Promise<any> {
        return await this.handleResponse(this.restService.post({
            url: '/test/task/targetRecall',
            data: {
                FilterUserIds: filterUserIds,
                Locations: locations,
                IsSelectAllLocations: isSelectAllLocations,
                SelectTextLocations: selectTextLocations
            }
        }));
    }

    public async searchAddresses(filterUserIds: number[], stages: any[], searchAddressCount: number): Promise<any> {
        return await this.handleResponse(this.restService.post({
            url: '/test/task/searchAddresses',
            data: {
                FilterUserIds: filterUserIds,
                Count: searchAddressCount,
                StageIds: stages
            }
        }));
    }

    public async generateAccessToken(userId, accessToken): Promise<any> {
        return await this.handleResponse(this.restService.post({
            url: '/dropbox/generateAccessToken',
            data: {
                userId: userId,
                temporaryToken: accessToken
            }
        }));
    }

    public async getIsGeneratedDropboxKey(currentUserId: number) {
        return await this.handleResponse(this.restService.get({ url: '/dropbox/isDropboxKeyGenerated/?userId=' + currentUserId }));
    }

    public async uploadFileToDropbox(file: any, path: any, currentUserId: any): Promise<any> {
        let formData: FormData = new FormData();
        formData.append('file', file, file.name);
        formData.append('path', path);
        formData.append('currentUserId', currentUserId);
        return await this.handleResponse(this.restService.upload({
            url: '/dropbox/uploadFile',
            data: formData
        }));
    }
}
