<div class="edit-in-progress">
  @for (row of phone; track row; let i = $index) {
  <div class="values display-delete" (document:focusout)="saveValue(row)">
    <div class="single-field">
      <input type="text" #inputField [(ngModel)]="row.value"
        onkeyup="this.value = this.value.replace(/[^0-9-+() ]/gim,'');" value={{row.value}}>
    </div>
    <div class="select">
      <mat-form-field>
        <mat-select style="margin-top: 19px;" [(value)]="row.label">
          @for (item of PhoneLabel; track item) {
          <mat-option [value]="item">{{item}}</mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
    <div class="delete" (click)="deletePhone(i)">
      <i style="margin-top: 22px;" class="material-icons">delete</i>
    </div>
  </div>
  }
  <div class="addOneMore" (click)="addOneMore()">
    <p>+Add one more</p>
  </div>
</div>