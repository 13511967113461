import { Component, Input, Output, EventEmitter, ViewChildren, QueryList } from '@angular/core';
import { Avatar } from '../../core/models/avatar-models/Avatar';
import { AvatarCollection } from '../../core/models/avatar-models/AvatarCollection';
import { BaseComponent } from '../../core/base.component';
import { AvatarSeasonalProposalTileComponent } from '../avatar-seasonal-proposal-tile/avatar-seasonal-proposal-tile.component';

@Component({
  selector: 'pd-avatar-seasonal-proposal-container',
  templateUrl: './avatar-seasonal-proposal-container.component.html',
  styleUrls: ['./avatar-seasonal-proposal-container.component.scss'],
})

export class AvatarSeasonalProposalContainerComponent extends BaseComponent {
  @ViewChildren('tiles') tiles: QueryList<AvatarSeasonalProposalTileComponent>;

  @Output() public onAddAvatar = new EventEmitter<any>();
  @Output() public onEditAvatar = new EventEmitter<any>();
  @Output() public onDownloadAvatar = new EventEmitter<any>();
  @Output() public onDeleteAvatar = new EventEmitter<any>();
  @Output() public onRemoveAvatarFromCollection = new EventEmitter<any>();

  @Input() public avatarCollection: AvatarCollection;
  @Input() public avatars: Avatar[];
  @Input() public isExistingAvatarCollection = false;

  constructor(
  ) {
    super();
  }

  //////////////////////////////// Events START //////////////////////////////

  public addAvatar(event) {
    this.onAddAvatar.emit(event);
  }

  public editAvatar(event) {
    this.onEditAvatar.emit(event);
  }

  public deleteAvatar(event) {
    this.onDeleteAvatar.emit(event);
  }

  public downloadAvatar(event) {
    this.onDownloadAvatar.emit(event);
  }

  public removeAvatarFromCollection(event) {
    this.onRemoveAvatarFromCollection.emit(event);
  }

  public detectChanges() {
    if (this.tiles) {
      this.tiles.forEach(tile => {
        tile.detectChanges();
      });
    }
  }

  //////////////////////////////// Events END //////////////////////////////

  //////////////////////////////// Private START //////////////////////////////

  //////////////////////////////// Private END //////////////////////////////

  //////////////////////////////// Helpers START ////////////////////////////////

  //////////////////////////////// Helpers END ////////////////////////////////

  //////////////////////////////// View *ngIf START //////////////////////////////

  //////////////////////////////// View *ngIf END //////////////////////////////
}
