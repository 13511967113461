import { UserContextService } from './../core/services/user-context.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Printd } from 'printd';
import { ColdImportModel } from 'src/app/core/models/ColdImportModel';
import { HotlistService } from 'src/app/core/services/hotlist.service';
import { UtilsHelper } from '../core/utils/utils.helper';

@Component({
  selector: 'pd-hotlist',
  templateUrl: './hotlist.component.html',
  styleUrls: ['./hotlist.component.scss']
})
export class HotlistComponent implements OnInit {
  public tooltip: boolean = false;
  public clientMessage: string;
  public dealId: number;
  public dealName: string;
  public token: string;
  public tableTitle = ['', 'Name', 'Company', 'Location'];
  public deals: ColdImportModel[] = [];
  public personsHotlist = [];
  public spinner = false;
  public buttonSpinner = false;
  public currentUserId: number;
  public isAccessByToken: boolean = false;
  public generatedHotlistToken: string;

  constructor(
    private hotlistService: HotlistService,
    private route: ActivatedRoute,
    private router: Router,
    private userContextService: UserContextService) {
    this.route.params.subscribe(params => {
      if (params.token) {
        this.isAccessByToken = true;
        this.token = params.token;
      }
      else {
        this.dealId = params.id;
        this.dealName = params.name;
      }
    });
  }

  ngOnInit() {
    this.loadHotlist();
    this.currentUserId = this.userContextService.user.value?.id;
  }

  private loadHotlist() {
    this.spinner = true;
    if (this.isAccessByToken) {
      this.hotlistService.getHotlistByToken(this.token).then(result => {
        this.deals = result;
        this.spinner = false;
      },
        (error) => {
          this.clientMessage = "The link you followed has expired\nPlease contact your clothier to provide you the new link."
        });
    }
    else {
      this.hotlistService.getHotlist(this.dealId).then(result => {
        this.deals = result;
        this.spinner = false;
      });
    }
  }

  public selectUser(user: ColdImportModel) {
    let personIndex = this.personsHotlist.indexOf(user);
    if (personIndex === -1) {
      this.personsHotlist.push(user);
    } else {
      this.personsHotlist.splice(personIndex, 1);
    }
  }

  public saveDeals() {
    this.buttonSpinner = true;
    if (this.isAccessByToken) {
      this.hotlistService.importReferralListByToken(this.token, this.personsHotlist).then(
        (res) => {
          this.clientMessage = "Thank you! I am grateful for your support.\nIn case of any questions don't hesitate to reach me or head office: headoffice@lgfgfashionhouse.com.\nHave a good day!"
          this.buttonSpinner = false;
        },
        (error) => {
          this.buttonSpinner = false;
        }
      );
    }
    else {
      this.hotlistService.importReferralList(this.currentUserId, this.dealId, this.personsHotlist).then(
        (res) => {
          this.router.navigate(['/pipeline', '1']);
        },
        (error) => {
          //   this.csvHelper.saveFile(this.personsHotlist, this.dealName, this.csvFields, this.csvTitles); ToDoo save ready csv file from server
          this.buttonSpinner = false;
        }
      );
    }
  }

  public async print():Promise<void> {
    let cssHotlist = `.container {
      margin-right: 1vw;
      width: 23vw;
  
      margin-bottom: 2vh;
      height: 18vh;
  
      float: left;
      display: flex;
  }
  
  .content-row {
      display: flex;
      flex-wrap: wrap;
  }
  
  .cell {
      margin-right: 1vw;
  }
  
  .bold {
      font-weight: 800;
  }
  
  @media print {
  
      .container,
      .cell {
          page-break-inside: avoid;
          page-break-after: auto;
          page-break-before: auto;
      }
  
      br+br {
          display: none;
      }
  
      .cell {
          word-wrap: break-word;
          font-size: 16px;
      }
  
      .cell div {
          margin-bottom: -5px;
      }
  }`
    const titleString = document.getElementsByTagName('title')[0].textContent;
    const d: Printd = new Printd();
    document.title = this.dealName;
    d.print(document.getElementById('print-pdf-section'), [cssHotlist]);
    // d.print( document.getElementById('print-pdf-section'), cssHotlist ); - if not render with 73 row
    document.title = titleString;
  }

  public generateToken() {
    if (!this.generatedHotlistToken) {
      this.hotlistService.generateHotlistToken(this.dealId).then(result => {
        this.generatedHotlistToken = window.location.origin + "/Hotlist/" + result;
        UtilsHelper.copyText(this.generatedHotlistToken);
        this.tooltip = true;
        setTimeout(() => {
          this.tooltip = false;
        }, 3000);
      });
    }
    else {
      UtilsHelper.copyText(this.generatedHotlistToken);
      this.tooltip = true;
      setTimeout(() => {
        this.tooltip = false;
      }, 3000);
    }
  }
}