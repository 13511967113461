import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpRestService } from './http-client/http-rest-client.service';
import { NoteModel } from '../models/Note';

@Injectable()
export class NoteService extends BaseService {

    constructor(private restService: HttpRestService) {
        super();
    }

    public async AddReasonOfKill(id: any, reasonOfKill: string): Promise<any> {
        return await this.handleResponse(this.restService.post({
            url: '/note/AddReasonOfKill',
            data: {
                DealId: id,
                ReasonOfKill: reasonOfKill
            }
        }));
    }

    public async getNotesByDealId(dealId: number): Promise<Array<NoteModel>> {
        return await this.handleResponse(this.restService.get({
            url: '/note/notesByDealId/' + dealId
        }));
    }

    public async updateNote(note: NoteModel) {
        return await this.handleResponse(this.restService.post({
            url: '/note/updateNote',
            data: note
        }));
    }

    public async deleteNote(noteId: number) {
        return await this.handleResponse(this.restService.delete({
            url: '/note/deleteNote/' + noteId
        }));
    }
}
