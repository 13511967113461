import transform from 'lodash-es/transform';
import cloneDeep from 'lodash-es/cloneDeep';
import isFunction from 'lodash-es/isFunction';
import reduce from 'lodash-es/reduce';

export class ObjectUtil {
    public static asKeyValueArray(object: any, predicate: (key, value) => boolean): Array<{ key: string; value: any }> {
        return transform(object, (result, value, key: string) => {
            if (!predicate || predicate(key, value)) {
                result.push({ value, key });
            }
        }, []);
    }

    public static extend(source, dest, config?: any) {
        source = cloneDeep(source);
        if (config) {
            this.mutateSourceObject(source, config);
        }
        if (source) {
            ObjectUtil.safeExtend(dest, source);
        }
        return dest;
    }

    private static mutateSourceObject(source: any, config: any): any {
        if (isFunction(config)) {
            config.call(null, source);
        } else {
            this.extendSourceObject(source, config);
        }
    }

    private static extendSourceObject(source: any, config: any): any {
        reduce(config, (res, value, key) => {
            res[key] = isFunction(value) ? source && source[key] && value.call(null, source[key], source) : value;
            return res;
        }, source);
    }

    private static safeExtend(dest, source) {
        const sourceKeys = ObjectUtil.getKeys(source);
        sourceKeys.forEach((key) => {
            if (key in dest && !ObjectUtil.propertyEditable(dest, key)) {
                return;
            }
            dest[key] = source[key];
        });
    }

    private static getKeys(obj: any) {
        const keys: string[] = [];
        // eslint-disable-next-line guard-for-in
        for (const key in obj) {
            if (key === 'constructor') {
                continue;
            }
            keys.push(key);
        }
        return keys;
    }

    private static propertyEditable(obj: Record<string, any>, key: string): boolean | undefined {
        if (!(key in obj)) {
            return false;
        }

        let owner = obj;
        while (owner != null && Object.getOwnPropertyDescriptor(owner, key) == null) {
            owner = Object.getPrototypeOf(owner);
        }
        if (owner == null) {
            return false;
        }
        const descriptor = Object.getOwnPropertyDescriptor(owner, key);
        return !!descriptor.set || descriptor.writable;
    }
}
