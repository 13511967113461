import { GlobalConstants } from 'src/app/core/global-constants';
import { AdditionalData } from './../../models/Deal';
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Deal } from "../../models/Deal";
import { Stage } from '../../models/Stage';
import { User } from '../../models/UserModel';

@Injectable()
export class PipelineDealsStoreService {

  public readonly deals = new BehaviorSubject<Deal[]>(null);
  public readonly additionalData = new BehaviorSubject<AdditionalData>(null);
  public readonly listDealsChecked = new BehaviorSubject<Deal[]>([]);

  public reset() {
    console.log('PipelineDealsStoreService reset');
    this.deals.next(null);
    this.additionalData.next(null);
    this.listDealsChecked.next([]);
  }

  public setDeals(value: Deal[]) {
    console.log('PipelineDealsStoreService setDeals', value);
    this.deals.next(value);
  }

  public updateDeal(value: Deal) {
    console.log('PipelineDealsStoreService updateDeal', value);
    const deals = this.deals.value;

    let index = deals.findIndex(p => p.id === value.id);
    if (index !== -1) {
      deals[index] = value;
      this.deals.next(deals);
    }
  }

  public removeDeal(id: number) {
    console.log('PipelineDealsStoreService removeDeal', id);
    const deals = this.deals.value;

    let index = deals.findIndex(p => p.id === id);
    if (index !== -1) {
      deals.splice(index, 1);
      this.deals.next(deals);
    }
  }

  public addDeals(value: Deal[]) {
    console.log('PipelineDealsStoreService addDeals', value);
    const deals = this.deals.value.concat(value);
    this.deals.next(deals);
  }

  public removeDeals() {
    console.log('PipelineDealsStoreService removeDeals');
    this.deals.next([]);
  }

  public selectAllCheckedDeals() {
    console.log('PipelineDealsStoreService selectAllCheckedDeals');
    this.listDealsChecked.next(this.deals.value);
  }

  public removeAllCheckedDeals() {
    console.log('PipelineDealsStoreService removeAllCheckedDeals');
    this.listDealsChecked.next([]);
  }

  public addCheckedDeal(value: Deal) {
    console.log('PipelineDealsStoreService addCheckedDeal', value);
    const listDealsChecked = this.listDealsChecked.value;
    listDealsChecked.push(value);
    this.listDealsChecked.next(listDealsChecked);
  }

  public removeCheckedDeal(value: Deal) {
    console.log('PipelineDealsStoreService removeCheckedDeal', value);
    const listDealsChecked = this.listDealsChecked.value.filter(p => p.id !== value.id);
    this.listDealsChecked.next(listDealsChecked);
  }

  public setAdditionalData(value: AdditionalData) {
    console.log('PipelineDealsStoreService setAdditionalData', value);
    this.additionalData.next(value);
  }

  public removeAdditionalData() {
    console.log('PipelineDealsStoreService removeAdditionalData');
    this.additionalData.next(null);
  }

  public getPipelineDealsByStageId(stageId: number): Deal[] {
    console.log('PipelineDealsStoreService getPipelineDealsByStageId', stageId);
    const pipelineDealsRequest = this.deals.value;

    return pipelineDealsRequest ?
      pipelineDealsRequest.filter(p => p.stage_id === stageId) : [];
  }

  public getPipelineDealsById(id: number): Deal {
    console.log('PipelineDealsStoreService getPipelineDealsById', id);
    const pipelineDealsRequest = this.deals.value;

    return pipelineDealsRequest ? pipelineDealsRequest.find(p => p.id === id) : null;
  }

  public getPipelineDealToStageByStages(availiableStages: Stage[], users: User[] = null): Deal[] {
    console.log('PipelineDealsStoreService getPipelineDealToStageByStages');
    const pipelineDealsRequest = this.deals.value;

    if (!pipelineDealsRequest) {
      return;
    }
    let allItems: Deal[];
    if (users) {
      const userIds = users.map(u => u.id);
      allItems = pipelineDealsRequest.filter(i => userIds.includes(i.user_id));
    }
    else {
      allItems = pipelineDealsRequest;
    }
    const result = allItems.reduce((result, item) => {
      if (result[item.stage_id])
        result[item.stage_id].push(item)
      else
        result[item.stage_id] = [item]
      return result
    }, {});

    availiableStages.forEach(s => {
      if (result[s.id] === undefined)
        result[s.id] = []
    })
    return result as Deal[];
  }

  public selectPipelineDealFeatureDatabaseCountByStage() {
    console.log('PipelineDealsStoreService selectPipelineDealFeatureDatabaseCountByStage');
    const additionalData = this.additionalData.value;

    if (additionalData && additionalData.deals_count_by_stage)
      return additionalData.deals_count_by_stage.reduce((result, current) => {
        if (current.stageId && current.count > 0) {
          if (!result)
            result = 0
          result[current.stageId] = current.count
        }
        return result
      }, {});
    return {}
  }

  public selectPipelineDealFeatureDatabaseLifeTimeSpendByStage() {
    console.log('PipelineDealsStoreService selectPipelineDealFeatureDatabaseLifeTimeSpendByStage');
    const additionalData = this.additionalData.value;

    if (additionalData && additionalData.deals_lifetime_spend_by_stage)
      return additionalData.deals_lifetime_spend_by_stage.reduce((result, current) => {
        if (current.stageId) {
          if (!result)
            result = []
          result[current.stageId] = current.lifeTimeSpendSum
        }
        return result
      }, []);
    return [];
  }
}
