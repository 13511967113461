@if(!isTemplatePreview)
{
<form [formGroup]="emailForm" (ngSubmit)="addEmail()" class="email-form">
    <div class="variable_sample__value">
        <input formControlName="email" placeholder="Enter email" />
    </div>

    <button type="submit" [disabled]="emailForm.invalid" class="btn btn-dark waves-light add-email-btn">Add
        Email</button>

</form>
}

<div *ngFor="let email of emails; let i = index" class="chip">
    {{ email }}

    @if(!isTemplatePreview)
    {
    <span class="closebtn" (click)="removeEmail(i)">&times;</span>
    }
</div>