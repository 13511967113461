<ul>
  <ng-container *ngFor="let node of treeData">
    <li>
      <div [ngStyle]="getStatusColor(node)">
        <div style="padding: 10px;" [ngStyle]="{'border': node.isSelected ? '2px solid black' : '2px solid white'}"
          (click)="requestSelectChange(node)">
          {{ node.messageTemplate ? MessageTemplateType[node.messageTemplate.messageTemplateType] : 'Unknown' }} -
          {{ EnumUtil.getBulkRequestStatusViewLabel(node.statusId) }}
          {{ node.created | date: 'dd-MMM-yyyy h:mm a' }}
        </div>
      </div>
      <ul *ngIf="node.children && node.children.length > 0">
        <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: node.children }"></ng-container>
      </ul>
    </li>
  </ng-container>
</ul>

<ng-template #recursiveList let-nodes>
  <ul>
    <ng-container *ngFor="let node of nodes">
      <li>
        <div [ngStyle]="getStatusColor(node)">
          <div style="padding: 10px;" [ngStyle]="{'border': node.isSelected ? '2px solid black' : '2px solid white'}"
            (click)="requestSelectChange(node)">
            {{ node.messageTemplate ? MessageTemplateType[node.messageTemplate.messageTemplateType] : 'Unknown' }} -
            {{ EnumUtil.getBulkRequestStatusViewLabel(node.statusId) }}

            @if(node.statusId == RequestStatus.Sheduled)
            {
            {{ node.scheduledSendDate | date: 'dd-MMM-yyyy h:mm a' }}
            }
            @if(node.statusId != RequestStatus.Sheduled)
            {
            {{ node.created | date: 'dd-MMM-yyyy h:mm a' }}
            }
          </div>
        </div>
        <ul *ngIf="node.children && node.children.length > 0">
          <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: node.children }"></ng-container>
        </ul>
      </li>
    </ng-container>
  </ul>
</ng-template>