import { GlobalConstants } from 'src/app/core/global-constants';
import { RoleService } from './../../../core/services/role.service';
import { UserContextService } from './../../../core/services/user-context.service';
import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { RolesEnum } from 'src/app/core/enums/RolesEnum';
import { Role } from 'src/app/core/models/Role';
import { User } from 'src/app/core/models/UserModel';
import { StageService } from 'src/app/core/services/stage.service';
import { UsersService } from 'src/app/core/services/users.service';
import { STAGES_BY_ROLES_VISIBILITY, STAGES_FOR_ALL_PIPELINES } from 'src/assets/stages_new_prospespects';

@Component({
  selector: 'pd-field-role',
  templateUrl: './field-role.component.html',
  styleUrls: ['./field-role.component.scss']
})
export class FieldRoleComponent implements OnInit {
  public isEdit = true;
  @Input() selectedRoleId: RolesEnum;
  @Input() selectedUserId: number;
  @Input() isTasksTab: boolean = false;
  @Input() isUsersTab: boolean = false;
  @Input() isAllowedExportLeadDate: boolean = false;
  @Input() isAllowedHistoryOfLeads: boolean = false;
  @Input() isRepoAccount: boolean = false;
  @Input() isWhatsappChat: boolean = false;
  @Input() isGlobalChat: boolean = false;
  @Input() isAvatarEditor: boolean = false;
  @Input() isInSmReport: boolean = false;
  @Input() isNotInClothierReport: boolean = false;
  @Input() isSeeOthersCms: boolean = false;
  @Input() role: Role;
  @Input() roles: Role[];
  @Input() disabled = false;
  @Output() roleChange = new EventEmitter<Object>();
  public roleList: Role[];
  public isEaHasConnections = false;

  constructor(
    public usersService: UsersService,
    private userContextService: UserContextService,
    private stageService: StageService,
    private roleService: RoleService) { }

  async ngOnInit(): Promise<void> {
    if (this.selectedRoleId === RolesEnum.Admin || this.selectedRoleId === RolesEnum.EA || this.selectedRoleId === RolesEnum.EaCaller) {
      const isEaHasConnections = await this.usersService.checkIsEaHasConnections(this.selectedUserId);
      this.isEaHasConnections = isEaHasConnections;
    }
    if (!this.roles) {
      this.roles = await this.roleService.getAll();
    }
    await this.initialiseStoreData();
  }

  private async initialiseStoreData(): Promise<void> {
    this.role = this.roles && this.selectedRoleId && this.roles.find(x => x.id == this.selectedRoleId);
    if (this.selectedRoleId) {
      const allStatusesWithDeals = await this.stageService.getStagesWithExistingDealByUserIdObservable(this.selectedUserId);
      this.roleList = this.getAllowedRolesForUpdateUser(this.roles, allStatusesWithDeals, this.userContextService.user.value);
    }
    else {
      this.roleList = this.roles.filter(x => x.id != RolesEnum.MasterLead);
    }
    this.roleList = this.roleList.sort((a, b) => a.name.localeCompare(b.name));
  }

  private getAllowedRolesForUpdateUser = (allRoles: Role[], allStatusesWithDeals: any[], currentUser: User) => {
    if (!allRoles) return [];

    const filteredStages = STAGES_FOR_ALL_PIPELINES.filter(s => 
      allStatusesWithDeals.some(ds => ds.stage_id === s.id) &&
      ![GlobalConstants.ALL_STAGES.Kill, GlobalConstants.ALL_STAGES.KilledRecord].includes(s.id)
    );
  
    const allowedRoleIds = Object.entries(STAGES_BY_ROLES_VISIBILITY)
      .reduce((allowedRoles, [roleName, stages]) => {
        const roleId = RolesEnum[roleName];
        if (roleId !== RolesEnum.MasterLead && stages?.length > 0 &&
            (currentUser.role_id === roleId || filteredStages.every(stage => stages.includes(stage.name)))) {
          allowedRoles.push(roleId);
        }
        return allowedRoles;
      }, []);
  
    return allRoles.filter(role => allowedRoleIds.includes(role.id));
  }

  /**
    * Add value
    * open edit
    * @memberof FieldRoleComponent
    */

  public addValue() {
    this.isEdit = true;
  }

  /**
    * Cancel edit
    * @memberof FieldRoleComponent
    */

  public cancelEdit() {
    this.isEdit = false;
  }

  /**
    * Go to edit
    * open edit
    * @memberof FieldRoleComponent
    */

  public goToEdit() {
    this.isEdit = true;
  }

  /**
    * Change value
    * @param {any} value value of role
    * @memberof FieldRoleComponent
    */

  public changeValue(value: Role) {
    this.role = value;
    if (!this.role || this.role.id !== RolesEnum.Admin) {
      this.isTasksTab = false;
      this.isUsersTab = false;
      this.isAllowedExportLeadDate = false;
      this.isAllowedHistoryOfLeads = false;
      this.isRepoAccount = false;
      this.isWhatsappChat = false;
      this.isGlobalChat = false;
      this.isAvatarEditor = false;
      this.isInSmReport = false;
      this.isSeeOthersCms = false;
    }
    if (!this.role || (this.role.id !== RolesEnum.Admin && this.role.id !== RolesEnum.Clothier)) {
      this.isNotInClothierReport = false;
    }
    this.isEdit = false;
    this.roleChange.emit({
      role: this.role
      , isTasksTab: this.isTasksTab
      , isUsersTab: this.isUsersTab
      , isAllowedExportLeadDate: this.isAllowedExportLeadDate
      , isAllowedHistoryOfLeads: this.isAllowedHistoryOfLeads
      , isRepoAccount: this.isRepoAccount
      , isWhatsappChat: this.isWhatsappChat
      , isGlobalChat: this.isGlobalChat
      , isAvatarEditor: this.isAvatarEditor
      , isInSmReport: this.isInSmReport
      , isNotInClothierReport: this.isNotInClothierReport
      , isSeeOthersCms: this.isSeeOthersCms
    });
  }

  public changeFlag() {
    if (!this.role || this.role.id !== RolesEnum.Admin) {
      this.isTasksTab = false;
      this.isUsersTab = false;
      this.isAllowedExportLeadDate = false;
      this.isAllowedHistoryOfLeads = false;
      this.isRepoAccount = false;
      this.isWhatsappChat = false;
      this.isGlobalChat = false;
      this.isAvatarEditor = false;
      this.isInSmReport = false;
      this.isSeeOthersCms = false;
    }
    if (!this.role || (this.role.id !== RolesEnum.Admin && this.role.id !== RolesEnum.Clothier)) {
      this.isNotInClothierReport = false;
    }
    this.isEdit = false;
    this.roleChange.emit({
      role: this.role
      , isTasksTab: this.isTasksTab
      , isUsersTab: this.isUsersTab
      , isAllowedExportLeadDate: this.isAllowedExportLeadDate
      , isAllowedHistoryOfLeads: this.isAllowedHistoryOfLeads
      , isRepoAccount: this.isRepoAccount
      , isWhatsappChat: this.isWhatsappChat
      , isGlobalChat: this.isGlobalChat
      , isAvatarEditor: this.isAvatarEditor
      , isInSmReport: this.isInSmReport
      , isNotInClothierReport: this.isNotInClothierReport
      , isSeeOthersCms: this.isSeeOthersCms
    });
  }

  public isRoleDisabled(role: Role): boolean {
    return this.isEaHasConnections
      && (role.id != RolesEnum.Admin
        && role.id != RolesEnum.EA
        && role.id != RolesEnum.EaCaller);
  }

  public get isAllowedFlags(): boolean {
    return this.isAdmin || this.isClothier;
  }

  public get isAdmin(): boolean {
    return this.role && this.role.id === RolesEnum.Admin;
  }

  public get isClothier(): boolean {
    return this.role && this.role.id === RolesEnum.Clothier;
  }
}
