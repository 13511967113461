<div class="wrapper">
  <div class="header">
    <h2>Tasks</h2>
  </div>
  @if (!spinner && !isShowError) {
  <div class="content-wrapper">
    @if (!isChildActive) {
    <div class="content">
      <div class="information">
        <div class="information-container">
          <div class="information-row">
            <div>Count without gender:</div>
            <div class="information-info-container">
              @if (totalWithoutGender) {
              <div>{{totalWithoutGender}}</div>
              }
              @if (!genderTotalSpinner && !totalWithoutGender) {
              <div><button type="button" class="btn btn-primary waves-light show-btn"
                  (click)="getWithoutGenderTotalCount()">Show</button>
              </div>
              }
              @if (genderTotalSpinner) {
              <div>
                <mat-spinner [diameter]="20"></mat-spinner>
              </div>
              }
            </div>
          </div>
          <div class="information-row">
            <div>Count without number: </div>
            <div class="information-info-container">
              @if (totalWithoutNumber) {
              <div>{{totalWithoutNumber}}</div>
              }
              @if (!numberTotalSpinner && !totalWithoutNumber) {
              <div><button type="button" class="btn btn-primary waves-light show-btn"
                  (click)="getWithoutNumberTotalCount()">Show</button>
              </div>
              }
              @if (numberTotalSpinner) {
              <div>
                <mat-spinner [diameter]="20"></mat-spinner>
              </div>
              }
            </div>
          </div>
        </div>
      </div>

      <div class="buttons">
        <button type="button" [routerLink]="['search-numbers-task']" class="btn btn-primary waves-light">Search
          Numbers</button>
        <button type="button" [routerLink]="['search-gender-task']" class="btn btn-success waves-light">Run gender
          task</button>
        <button type="button" [routerLink]="['targeted-recall-task']" class="btn btn-info waves-light">Targeted
          Recall</button>
        <button type="button" [routerLink]="['search-address-task']" class="btn btn-info waves-light">Search
          Addresses</button>
        <button type="button" [routerLink]="['import-deals-task']" class="btn btn-primary waves-light">Import
          Deals</button>
        <button type="button" [routerLink]="['add-fabrics-task']" class="btn btn-info waves-light">Add Fabrics</button>
      </div>
      <div class="buttons">
        @if (currentUser?.role_id === RolesEnum.MasterLead) {
        <a href="/ClothierToEaConnection" target="_blank" type="button" class="btn btn-info waves-light">Clothier To Ea
          Connection</a>
        }
        @if (currentUser?.role_id === RolesEnum.MasterLead) {
        <a href="/NearbyCompanies" target="_blank" type="button" class="btn btn-info waves-light">Nearby Companies</a>
        }
        @if (false && currentUser?.role_id === RolesEnum.MasterLead) {
        <a href="/reports" target="_blank" type="button" class="btn btn-info waves-light">Reports</a>
        }
        @if ((currentUser?.role_id === RolesEnum.MasterLead) || (currentUser?.role_id === RolesEnum.Admin &&
        currentUser?.isGlobalChat)) {
        <a href="/global-chat" target="_blank" type="button" class="btn btn-info waves-light">Global
          Chat</a>
        }
        @if ((currentUser?.role_id === RolesEnum.MasterLead) || currentUser?.isAvatarEditor) {
        <a href="/avatar-seasonal-proposal" target="_blank" type="button" class="btn btn-info waves-light">Avatar -
          Seasonal proposal</a>
        }
        @if (currentUser?.role_id === RolesEnum.MasterLead) {
        <a href="/social-media-ads-tokens" target="_blank" type="button" class="btn btn-info waves-light">Social Media
          Ads Tokens</a>
        }
      </div>
      <div class="buttons">
        @if (currentUser?.role_id === RolesEnum.MasterLead) {
        <a href="/bulk-message-settings" target="_blank" type="button" class="btn btn-info waves-light">WhatsApp/Email Bulk Messages</a>
        }
      </div>
    </div>
    }

    <router-outlet></router-outlet>
  </div>
  }
</div>
@if (spinner) {
<div class="spinner">
  <mat-spinner></mat-spinner>
</div>
}

@if (isShowError) {
<div class="show-error">
  <h2 style="color: red; font-weight: 400; padding: 20px">Sorry, something went wrong. Please try again later</h2>
</div>
}