export enum PersonPropertiesToUpdate {
    Company = 1,
    Position = 2,
    Phone = 3,
    Email = 4,
    LinkedinURL = 5,
    Gender = 6,
    Location = 7,
    CommonConnection = 8,
    ForbidPromotionalEmails = 9,
    SendWhatsapp = 10,
    SendSms = 11,
    FirstName = 12,
    LastName = 13,
    Birthday = 14,
    CompanyAddress = 15,
    HomeAddress = 16,
    NotesOld = 17,
    PersonWhoReferred = 18,
    PartnerFirstName = 19,
    PartnerLastName = 20,
    PartnerEmail = 21,
    ForbidPartnerEmails = 22,
    IsPartnerRepliedToBDayEmail = 23,
    PartnerPhone = 24
}
