import { ObjectUtil } from '../utils/object.util';
import { Dictionary } from './Dictionary';

export class SearchModel {
  value: any;
  skip = 0;
  take = 8;
  result: Dictionary[] = [];

  constructor(data?: any) {
    ObjectUtil.extend(data, this);
  }
}
