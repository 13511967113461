<div class="row wrapper">
  <div class="col-4 title">
    @if (isRequired) {
    <span class="sign-star">*</span>
    }{{fieldTitle}}
  </div>
  <div class="col-8 work-field">
    @if (!fieldValue && !isEdit) {
    <pd-add-value (addValue)="addValue()"></pd-add-value>
    }
    @if (isEdit || fieldValue) {
    <div class="md-form">
      <input (ngModelChange)="changeEdit($event)" [(ngModel)]="fieldValue" type="text" [required]="isRequired" mdbActive
        #inputFieldValue="ngModel">
      @if (fieldInvalid || (isRequired && inputFieldValue.invalid && inputFieldValue.touched)) {
      <span class="value-placeholder">{{fieldPlaceholder}}</span>
      }
    </div>
    }
  </div>
</div>