<div>
    <mat-form-field style="width: 130px;">
        <mat-label>{{label}}</mat-label>
        <mat-select (selectionChange)="setColor()" [(ngModel)]="color">
            @for (presetColor of presetColors; track presetColor) {
            <mat-option [value]="presetColor.color">
                <div [style.background]="presetColor.color">{{presetColor.label}}</div>
            </mat-option>
            }
        </mat-select>
    </mat-form-field>
    <input type="color" style="width: 50px;" [(ngModel)]="color" (input)="setColor()" />
</div>