import { Component, Input, ViewChild, Output, EventEmitter, ElementRef, ViewChildren, QueryList, ChangeDetectorRef, OnInit } from '@angular/core';
import { AvatarCollection } from '../../core/models/avatar-models/AvatarCollection';
import { Avatar } from '../../core/models/avatar-models/Avatar';
import { BaseComponent } from '../../core/base.component';
import { Avatar2Collection } from '../../core/models/avatar-models/Avatar2Collection';
import { AvatarFacadeService } from '../../core/services/avatar/avatar-facade.service';
import { AvatarSortingTypes } from '../../core/enums/avatar/AvatarSortingTypes';
import { AvatarSeasonalProposalGrouppedExpansionPanelComponent } from '../avatar-seasonal-proposal-groupped-expansion-panel/avatar-seasonal-proposal-groupped-expansion-panel.component';
import { ConfirmService } from 'src/app/core/services/confirm.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { EnumUtil } from 'src/app/core/utils/enum.util';

@Component({
  selector: 'pd-avatar-seasonal-proposal-expansion-panel',
  templateUrl: './avatar-seasonal-proposal-expansion-panel.component.html',
  styleUrls: ['./avatar-seasonal-proposal-expansion-panel.component.scss']
})

export class AvatarSeasonalProposalExpansionPanelComponent extends BaseComponent implements OnInit {
  @ViewChild('collectionNameInput') collectionNameInput: ElementRef;
  @ViewChildren('groups') groups: QueryList<AvatarSeasonalProposalGrouppedExpansionPanelComponent>;
  @ViewChildren('nongroup') nongroup: AvatarSeasonalProposalGrouppedExpansionPanelComponent;

  @Output() public isSeasonalProposalCollectionChange = new EventEmitter<any>();
  @Output() public isStylesAvailableCollectionChange = new EventEmitter<any>();
  @Output() public isGroupedViewChange = new EventEmitter<any>();
  @Output() public onAddAvatar = new EventEmitter<any>();
  @Output() public onEditAvatar = new EventEmitter<any>();
  @Output() public onDeleteAvatar = new EventEmitter<any>();
  @Output() public onRemoveAvatarFromCollection = new EventEmitter<any>();
  @Output() public onDeleteCollection = new EventEmitter<any>();
  @Output() public onDownloadAvatar = new EventEmitter<any>();
  @Output() public onDownloadCollection = new EventEmitter<AvatarCollection>();
  @Output() public onLoadAllAvatars = new EventEmitter<any>();

  @Input() public avatarCollection: AvatarCollection;
  @Input() public avatar2Collections: Avatar2Collection[];
  @Input() public avatars: Avatar[];
  @Input() public storedCollectionsExpanding: any;
  @Input() public isExistingAvatarCollection: boolean;

  public isEditing: boolean;
  public isLoadAll: boolean;
  public isLoadingInProgress: boolean;
  public isNeedLoadAvatars: boolean;
  public AvatarSortingTypes = AvatarSortingTypes;

  private prevLabel: string;
  private removeCollectionModalMessage: string = 'Some of the avatars are assigned only to this collection. Would you like to keep avatars in the system (they will be saved in «Existing avatars» section)?';
  private deleteCollectionMessage = "Do you confirm deletion of this collection?"

  constructor(
    private cdRef: ChangeDetectorRef
    , private avatarFacadeService: AvatarFacadeService
    , private confirmService: ConfirmService) {
    super();
  }

  ngOnInit() {
  }

  //////////////////////////////// Events START //////////////////////////////

  public addAvatar() {
    this.onAddAvatar.emit({ avatarCollectionId: this.avatarCollection.id });
  }

  public editAvatar(event) {
    this.onEditAvatar.emit(event);
  }

  public deleteAvatar(event) {
    this.onDeleteAvatar.emit(event);
  }

  public removeAvatarFromCollection(event) {
    this.onRemoveAvatarFromCollection.emit(event);
  }

  public downloadAvatar(event) {
    this.onDownloadAvatar.emit(event);
  }

  public downloadCollection(event: any) {
    event.stopPropagation();
    this.onDownloadCollection.emit(this.avatarCollection);
  }

  public deleteCollection(event: any) {
    event.stopPropagation();

    if (!confirm(this.deleteCollectionMessage)) {
      return;
    }

    if (this.avatars.length == 0 || this.checkIfHasAvatarInOtherCollection()) {
      this.onDeleteCollection.emit({ avatarCollectionId: this.avatarCollection.id, keepAvatars: true });
      return;
    }

    var modalRef = this.confirmService.showDialog(this.removeCollectionModalMessage);
    modalRef.onChose.subscribe(async result => {
      this.onDeleteCollection.emit({ avatarCollectionId: this.avatarCollection.id, keepAvatars: result });
    });
  }

  public onSetSeasonalProposalCollection(event: MatSlideToggleChange) {
    this.isSeasonalProposalCollectionChange.emit({ avatarCollection: this.avatarCollection, isChecked: event.checked });
  }

  public async onSetStylesAvailableCollection(event: MatSlideToggleChange) {
    if (this.avatarCollection.isStylesAvailable) {
      this.isLoadAll = true;
      await this.loadAllAvatarsBase64();
    }

    this.isStylesAvailableCollectionChange.emit({ avatarCollection: this.avatarCollection, isChecked: event.checked });
  }

  public onPanelClose() {
    if (this.isEditing) {
      return;
    }

    if (this.storedCollectionsExpanding && this.storedCollectionsExpanding.indexOf(this.avatarCollection.id) != -1) {
      this.storedCollectionsExpanding.splice(this.storedCollectionsExpanding.indexOf(this.avatarCollection.id), 1);
    }
  }

  public async onPanelOpened() {
    if (this.isEditing) {
      return;
    }

    if (this.storedCollectionsExpanding && this.storedCollectionsExpanding.indexOf(this.avatarCollection.id) == -1) {
      this.storedCollectionsExpanding.push(this.avatarCollection.id);
    }

    if (this.avatarCollection.isStylesAvailable) {
      this.isLoadAll = true;
    }
    this.isNeedLoadAvatars = true;
    await this.loadAvatarsBase64IfNeed();
  }

  public async onChangeGroupedView(event) {
    this.isNeedLoadAvatars = true;
    this.cdRef.detectChanges();
    await this.loadAvatarsBase64IfNeed();
    this.isGroupedViewChange.emit({ avatarCollection: this.avatarCollection, isChecked: event.checked });
  }

  public async loadAllAvatars(event: any) {
    event.stopPropagation();
    this.isLoadAll = true;
    this.isLoadingInProgress = true;
    await this.loadAllAvatarsBase64();
    this.onLoadAllAvatars.emit({ isExistingAvatarCollection: this.isExistingAvatarCollection });
  }

  public async loadAvatarsBase64IfNeed() {
    const groupArr = this.groups.toArray()
    for (const group of groupArr) {
      if (this.avatarCollection.isStylesAvailable) {
        group.isLoadAll = true;
      }
      await group.loadAvatarsBase64IfNeed();
    }

    this.cdRef.detectChanges();
  }

  public editLabel(event) {
    event.stopPropagation();
    if (this.isExistingAvatarCollection) {
      return;
    }

    this.isEditing = true;
    this.prevLabel = this.avatarCollection.collectionName;

    setTimeout(() => {
      this.collectionNameInput.nativeElement.focus();
    });
  }

  public async finishEditing() {
    if (this.avatarCollection.collectionName) {
      await this.avatarFacadeService.updateAvatarCollection(this.avatarCollection.id, this.avatarCollection.collectionName);
    }
    else {
      this.avatarCollection.collectionName = this.prevLabel;
    }
    this.isEditing = false;
  }

  public onSpaceKey(event: KeyboardEvent): void {
    event.stopImmediatePropagation();
  }

  //////////////////////////////// Events END //////////////////////////////

  //////////////////////////////// Private START //////////////////////////////

  private async loadAllAvatarsBase64() {
    const groupsArr = this.groups.toArray()
    for (const group of groupsArr) {
      await group.loadAllAvatars();
    }

    this.isLoadingInProgress = false;
  }

  private checkIfHasAvatarInOtherCollection(): boolean {
    return this.avatars
      .some(avatar => this.avatar2Collections
        .some(x => x.avatarId == avatar.id && x.avatarCollectionId != this.avatarCollection.id));
  }

  //////////////////////////////// Private END //////////////////////////////

  //////////////////////////////// Helpers START ////////////////////////////////

  public getAvatarByGroup(avatarSortingType: AvatarSortingTypes) {
    const avatarSortingTypeValue = EnumUtil.getEnumValue(AvatarSortingTypes, avatarSortingType.toString());
    if (this.avatars && this.avatars.length > 0) {
      return this.avatars.filter(a => a.avatarSortingType == avatarSortingTypeValue);
    }
    return [];
  }

  public getAvatarCount() {
    if (this.avatars) {
      return this.avatars.length;
    }
    return 0;
  }

  //////////////////////////////// Helpers END ////////////////////////////////

  //////////////////////////////// View *ngIf START //////////////////////////////

  public get isExpanded(): boolean {
    return this.storedCollectionsExpanding && this.storedCollectionsExpanding.indexOf(this.avatarCollection.id) != -1;
  }

  public get isShowLoadAll(): boolean {
    return !(this.avatarCollection && this.avatarCollection.isStylesAvailable) && ((this.getAvatarCount() > 0 && !this.isLoadAll) || this.isLoadingInProgress);
  }

  //////////////////////////////// View *ngIf END //////////////////////////////
}