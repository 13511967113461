// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  newCmsIntegration: true,
  // cmsUtilityServiceApi: 'https://testpd.lgfgfashionhouse.com/api',
  // cmsDealFrameUrl: 'http://test.office.lgfgfashionhouse.com/Lists/OrdersSystem/Client.aspx?IsDlg=1',
  // salesChartUrl: 'http://test.office.lgfgfashionhouse.com/SitePages/StartNewOrder.aspx',
  cmsUtilityServiceApi: 'https://testpd.lgfgfashionhouse.com/api',
  cmsDealFrameUrl: 'https://testcms.lgfgfashionhouse.com/#/SubmitPayment/OrderSystem;',
  salesChartUrl: 'https://testcms.lgfgfashionhouse.com/#/',  
  smAdsLeadsUrl: 'https://dev.lgfgfashionhouse.com/en/sm-',
  bingMapApiKey: 'AhPb5F2F0Mdeu8obLsrClxD98cVa49JCqc3eT6scUz_IF1xbXcX6onatvUIur6Vb',
  ipInfoToken: 'd3e725bb96d847',
  revolutMode: 'sandbox',
  apiUrl: "https://testpd.lgfgfashionhouse.com/api",
  redirectUri: "https://testpd.lgfgfashionhouse.com/callback",
  apiUrl3d: "https://test3dm.lgfgfashionhouse.com",
  apiUrl3dFabricEndpoint: "/image/getFabricImgByName/",
  stripePublicKey: "pk_test_51ND5OdALhsowmZAvaKSEsUhBEKeEVnTJYdAgwpqQiHK2ls5dOc0S34kgD5RabpQ3NBgyB28C1ooMkOgQzvIzbY1S00zP4j7KsH",
  previewDealId: 36153,
  defMessageFromEmail: "info@lgfgfashionhouse.com",
  defMessageFromName: "Info",
};

