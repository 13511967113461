import { GlobalConstants } from './../../core/global-constants';
import { Component, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FilterType } from 'src/app/core/enums/FilterType.enum';
import { DealService } from 'src/app/core/services/deals.service';
import { TasksService } from 'src/app/core/services/tasks.service';
import { CustomSelectFilterComponent } from 'src/app/shared/custom/custom-select-filter/custom-select-filter.component';

@Component({
  selector: 'pd-search-gender-task',
  templateUrl: './search-gender-task.component.html',
  styleUrls: ['./search-gender-task.component.scss']
})
export class SearchGenderTaskComponent {
  public genderLocation = [];
  public updateGenderInfo: string;
  public updateNumberInfo: string;
  public genderCount: number;
  public contactType: number;
  public contactTypes = ['Referral', 'LinkedIn', 'Cold Call'];
  public gender: number;
  public genderList = ['All', 'Male', 'Female', 'None'];
  public spinner: boolean = false;
  public genderTotalSpinner: boolean = false;
  public numberTotalSpinner: boolean = false;
  public genderForm: FormGroup;
  public tasksRoute = GlobalConstants.ROUTE_NAMES.Tasks;
  public isSelectedAll = false;
  public genderCountOfPerson = new FormControl('', Validators.required)


  @Output() isGenderInfo = true;

  public currentUserId: string = '';
  public isShowError = false;

  public filteredUserIds = null;

  @Output() locationsLabel: string = 'Locations';
  public isSelectAllLocations: boolean;
  selectTextLocations: string;
  isSelectAllLocationsNumber: boolean;
  selectTextLocationsNumber: string;
  isSelectAllLocationsGender: boolean;
  selectTextLocationsGender: string;
  filterTypeLocation = FilterType.Location;

  @ViewChild("locationFilter") customSelectLocation: CustomSelectFilterComponent;
  constructor(
    private dealService: DealService,
     private taskService: TasksService) {
    this.genderForm = new FormGroup({
      'genderCountOfPerson': this.genderCountOfPerson
    });
  }

  public getGenderLocations(locations) {
    this.genderLocation = locations.data;
    this.isSelectAllLocationsGender = locations.isSelectAll;
    this.selectTextLocationsGender = locations.selectText;
  }

  getLocationsFilterData(event) {
    let isNotleter: number = event.isNotLetter;
    this.dealService.getFilterData(FilterType.Location, event.skip, event.take).then(response => {
      if (isNotleter == this.customSelectLocation.isNotLetter) {
        this.customSelectLocation.setData(response);
      }
    });
  }

  getLocationsAutocomplete(event) {
    let isNotleter: number = event.isNotLetter;
    this.dealService.getFilterDataAutocomplete(FilterType.Location, event.searchString, event.skip, event.take).then(response => {
      if (isNotleter == this.customSelectLocation.isNotLetter) {
        this.customSelectLocation.setData(response);
      }
    });
  }

  public submitGender() {
    let locationsId = this.genderLocation.reduce((acc, current) => {
      acc.push(current.id)
      return acc
    }, [])
    this.taskService.RunUpdateGender(locationsId, +this.genderCount, this.currentUserId, this.isSelectAllLocationsGender, this.selectTextLocationsGender).then(result => {
    });
    this.updateGenderInfo = 'Gender Update Task started';
    this.genderLocation = [];
    this.genderCount = null;
    this.genderForm.reset();
    setTimeout(() => {
      this.updateGenderInfo = null;
    }, 3000);
  }
}
