@if (avatarCollection) {
<mat-expansion-panel class="pd-avatar-seasonal-proposal-expansion-panel" [expanded]="isExpanded"
  (closed)="onPanelClose()" (opened)="onPanelOpened()">
  <mat-expansion-panel-header>
    <mat-panel-title (keydown.space)="onSpaceKey($event)">
      <div class="panel-title">
        <div style="display: flex; align-items: center;">
          @if (!isEditing || isExistingAvatarCollection) {
          <span>
            {{avatarCollection.collectionName}}
          </span>
          }

          @if (isEditing && !isExistingAvatarCollection) {
          <input #collectionNameInput style="color: white;" [(ngModel)]="avatarCollection.collectionName"
            (blur)="finishEditing()" (click)="$event.stopPropagation()" (keyup.enter)="finishEditing()" />
          }

          @if (getAvatarCount() > 0) {
          <span>&nbsp;({{getAvatarCount()}})</span>
          }
        </div>

        <div class="panel-tool">
          @if (avatars && avatars.length > 0) {
          <button class="tool-btn btn btn-outline-primary" (click)="downloadCollection($event)"
            matTooltip="Download collection" matTooltipPosition="above">
            <i class="material-icons">cloud_download</i>
          </button>
          }

          @if (!isExistingAvatarCollection) {
          <button (click)="editLabel($event)" class="tool-btn btn btn-outline-warning min-btn"
            matTooltip="Edit collection" matTooltipPosition="above">
            <i class="material-icons">edit</i>
          </button>
          }
          @if (!isExistingAvatarCollection) {
          <button (click)="deleteCollection($event)" class="tool-btn btn btn-outline-danger min-btn"
            matTooltip="Delete collection" matTooltipPosition="above">
            <i class="material-icons">delete</i>
          </button>
          }
          @if (!isExistingAvatarCollection) {
          <mat-slide-toggle class="toggle" [(ngModel)]="avatarCollection.isSeasonalProposal"
            (change)="onSetSeasonalProposalCollection($event)" (click)="$event.stopPropagation();">Seasonal
            Proposal</mat-slide-toggle>
          }
          @if (!isExistingAvatarCollection) {
          <mat-slide-toggle class="toggle" [(ngModel)]="avatarCollection.isStylesAvailable"
            (change)="onSetStylesAvailableCollection($event)" (click)="$event.stopPropagation();">Styles
            Available</mat-slide-toggle>
          }
          <mat-slide-toggle class="toggle" [(ngModel)]="avatarCollection.isGroupedView"
            (click)="$event.stopPropagation()" (change)="onChangeGroupedView($event)">Categorized
            View</mat-slide-toggle>
          @if (isShowLoadAll) {
          <button (click)="loadAllAvatars($event)" type="button" class="avatar-btn waves-light btn-dark btn"
            placement="bottom" mdbWavesEffect>
            <span class="btn-label">Load All Avatars@if (isLoadingInProgress) {
              <mat-spinner class="spinner" [diameter]="20"></mat-spinner>
              }</span>
          </button>
          }
          <button (click)="addAvatar();$event.stopPropagation()" type="button" matTooltip="Add Avatar"
            class="add-avatar-btn waves-light btn-dark btn" style="float: right;">
            <span data-html="true">Add Avatar</span>
          </button>
        </div>

      </div>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <mat-panel-description>

    @if (!avatarCollection.isGroupedView) {

    <pd-avatar-seasonal-proposal-groupped-expansion-panel #groups [avatarCollection]='avatarCollection'
      [avatars]="avatars" [isNeedLoadAvatars]="true" (onAddAvatar)="addAvatar()" (onEditAvatar)="editAvatar($event)"
      (onDeleteAvatar)="deleteAvatar($event)" (onDownloadAvatar)="downloadAvatar($event)"
      (onRemoveAvatarFromCollection)="removeAvatarFromCollection($event)"
      [isExistingAvatarCollection]="isExistingAvatarCollection">
    </pd-avatar-seasonal-proposal-groupped-expansion-panel>

    }

    @if (avatarCollection.isGroupedView) {

    @for (avatarSortingType of AvatarSortingTypes | enumToArray; track avatarSortingType) {

    <pd-avatar-seasonal-proposal-groupped-expansion-panel #groups [avatarCollection]='avatarCollection'
      [avatarSortingType]="avatarSortingType" [avatars]="getAvatarByGroup(avatarSortingType)"
      (onAddAvatar)="addAvatar()" (onEditAvatar)="editAvatar($event)" (onDeleteAvatar)="deleteAvatar($event)"
      (onDownloadAvatar)="downloadAvatar($event)" (onRemoveAvatarFromCollection)="removeAvatarFromCollection($event)"
      [isExistingAvatarCollection]="isExistingAvatarCollection">
    </pd-avatar-seasonal-proposal-groupped-expansion-panel>

    }

    }

  </mat-panel-description>
</mat-expansion-panel>
}