import { Component, OnInit, Input, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Modeler3dCard, Modeler3dCardLine, Modeler3dCardType } from 'src/app/core/models/Modeler3dCardConfig';
import { User } from 'src/app/core/models/UserModel';
import { UserContextService } from 'src/app/core/services/user-context.service';

@Component({
  selector: 'pd-3d-modeler-product-line',
  templateUrl: './3d-modeler-product-line.component.html',
  styleUrls: ['./3d-modeler-product-line.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class Modeler3dProductLineComponent implements OnInit {
  @ViewChild('expansionPanel') expansionPanel: MatExpansionPanel;

  cardLines: Array<Modeler3dCardLine>;
  @Input() modeler3dCardType: Modeler3dCardType;
  @Input() modeler3dCards: Array<Modeler3dCard>;
  currentUser: User;

  fabricsCardByLine: Array<Array<Modeler3dCard>>;
  isExpandedOnce = false;

  constructor(private cdRef: ChangeDetectorRef, private userContextService: UserContextService, public breakpointObserver: BreakpointObserver) {
  }

  ngOnInit() {
    this.initializeStore();

    this.cardLines = this.modeler3dCards.map(m => m.cardLine).filter(
      (v, i, arr) => arr.findIndex(f => f.lineId === v.lineId) === i
    );

    this.fabricsCardByLine = new Array<Array<Modeler3dCard>>();

    this.cardLines.forEach(line => {
      this.fabricsCardByLine[line.lineId] = this.filterCards(line.lineId)
    });
  }

  ngOnDestroy() {

  }

  ngAfterContentInit() {

  }

  private initializeStore() {
    this.currentUser = this.userContextService.user.value;
  }

  filterCards(lineId: number): Array<Modeler3dCard> {
    if (this.modeler3dCards) {
      let cards = this.modeler3dCards.filter(c => c.cardLine.lineId === lineId);
      return cards;
    }
    return new Array<Modeler3dCard>();
  }

  getCardsCount() {
    if (this.modeler3dCards) {
      let cards = this.modeler3dCards;
      if (cards) {
        return cards.length;
      }
    }
    return 0;
  }
}
