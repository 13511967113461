import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'pd-field-name',
  templateUrl: './field-name.component.html',
  styleUrls: ['./field-name.component.scss']
})
export class FieldNameComponent implements OnInit {

  public name: string;
  @Output() nameChange = new EventEmitter<string>();
  public isEdit = false;

  constructor() { }

  ngOnInit() {
  }

  /**
    * Change edit
    * in deal
    * @memberof FieldNameComponent
    */

  public changeEdit() {
    this.nameChange.emit(this.name);
  }

  /**
    * Add value
    * open edit
    * @memberof FieldNameComponent
    */

  public addValue() {
    this.isEdit = true;
  }

}
