import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpRestService } from './http-client/http-rest-client.service';
import { UserRequestModel } from '../models/UserRequestModel';

@Injectable()
export class UserRequestService extends BaseService {

	constructor(private restService: HttpRestService) {
		super();
	}

	public async getUserRequests(userId?: any): Promise<Array<UserRequestModel>> {
		return await this.handleResponse(this.restService.get({ url: '/userRequest/getUserRequests?userId=' + userId }));
	}

	public async uploadNearbyImport(file: any, requestId: any): Promise<any> {
		let formData: FormData = new FormData();
		formData.append('file', file, file.name);
		formData.append('requestId', requestId);
		return await this.handleResponse(this.restService.upload(
			{
				url: '/userRequest/uploadNearbyImport',
				data: formData
			}));
	}

	public async sendNearbyImportRequest(data: any): Promise<any> {
		return await this.handleResponse(this.restService.post(
			{
				url: '/userRequest/nearbyImportRequest',
				data: data
			}));
	}
}
