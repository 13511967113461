import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'pd-field-linkedin-uuid',
  templateUrl: './field-linkedin-uuid.component.html',
  styleUrls: ['./field-linkedin-uuid.component.scss']
})
export class FieldLinkedinUuidComponent implements OnInit {

  public uuid: string;
  @Output() linkedinUuidChange = new EventEmitter<string>();
  public isEdit = false;
  @Input() isUuidExist;

  constructor() { }

  ngOnInit() {
  }

  /**
    * Change edit
    * in deal
    * @memberof FieldLinkedinUuidComponent
    */

  public changeEdit() {
    this.linkedinUuidChange.emit(this.uuid);
  }

  /**
    * Add value
    * open edit
    * @memberof FieldLinkedinUuidComponent
    */

  public addValue() {
    this.isEdit = true;
  }

}
