import { ObjectUtil } from "../../utils/object.util";

export class PersonContactInfo {
    public id: number;
    public contactTypeId: number;
    public personId: number;
    public label: string;
    public value: string;
    public normalizedValue: string;
    public isPrimary: boolean;

    constructor(data?: any)
    {
        ObjectUtil.extend(data, this);
    }
}