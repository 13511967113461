import { Component, OnInit, ViewChild, HostListener, Input, Output, EventEmitter, ElementRef, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FilterType } from 'src/app/core/enums/FilterType.enum';
import { on } from 'events';

@Component({
  selector: 'pd-custom-select-filter',
  templateUrl: './custom-select-filter.component.html',
  styleUrls: ['./custom-select-filter.component.scss']
})
export class CustomSelectFilterComponent implements OnInit, AfterViewInit {

  @ViewChild('selectContainer', { static: false }) selectContainer: ElementRef;
  @Input() isMultiple = true;
  public selectLazyLoading = false;
  public selectStart = 0;
  public isOpenSelect = false;
  public searchText: string;
  private countPagination = 6;
  private countLettersForPagination = 2;
  @Input() isDashboard: boolean;
  @Input() isNumberInfo: boolean;
  @Input() isGenderInfo: boolean;
  @Input() public dataArray = [];
  @Output() sendData = new EventEmitter<Object>();
  @Output() autoCompleteEvent = new EventEmitter<Object>();
  @Output() loadDataEvent = new EventEmitter<Object>();
  @Output() open = new EventEmitter();
  @Output() isSelectedItem = new EventEmitter<boolean>();
  public filteredData = [];
  isNotLetter: number = 0;
  @Input() selectText: string;

  @Input() dataLabel: string;
  label: string;

  public localChecked = [];

  isDisabled = false;

  @Input() public startLabel: string
  @Input() public isSelectAll: boolean;

  @ViewChild('selectAll') selectAllButton: ElementRef;
  @ViewChild('deselectAll') deselectAllButton: ElementRef;

  @Input() filterType: FilterType;

  isShowSelectAll = true;

  isShowLoading = false;
  isLoad = false;
  timeout: any;

  @Input() isShowExcept = false;
  @Input() isExcept = false;

  constructor(private cdRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.isSelectAll = false;
    this.onSearch();
    if (!this.selectText) {
      this.selectText = "";
    }
    else {
      this.searchText = this.selectText;
    }
  }

  public onSearch() {
    if (this.searchText && this.searchText.length > this.countLettersForPagination) {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.isNotLetter = this.searchText.length;
        this.filterMultiSelect();
        this.filteredData = [];
        this.isShowLoading = true;
        this.isShowSelectAll = true;

      }, 1000);
    } else if (!this.searchText) {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.filteredData = [];
        this.selectLazyLoading = false;
        this.isShowLoading = true;
        this.isShowSelectAll = true;
        this.isNotLetter = 0;
        this.selectStart = 0;
        this.loadDataEvent.emit({ take: this.countPagination, skip: 0, isNotLetter: this.isNotLetter });

      }, 1000);
    }
  }

  ngAfterViewInit(): void {
    if (this.isSelectAll) {
      this.localChecked = [];
      for (let i = 0; i < this.filteredData.length; i++) {
        if (this.selectText) {
          if (this.filterType == FilterType.Location) {
            if (this.filteredData[i].toLowerCase().includes(this.selectText.toLowerCase()) && !this.dataArray.includes(this.filteredData[i])) {
              this.localChecked.push(this.filteredData[i]);
            }
          }
          else if (this.filterType == FilterType.Company) {
            let isMatch = true;
            if (this.filteredData[i].length < this.selectText.length) {
              isMatch = false;
              break;
            }
            else {
              for (let j = 0; j < this.selectText.length; j++) {
                if (j < this.filteredData[i].length && this.selectText[j].toLowerCase() != this.filteredData[i][j].toLowerCase()) {
                  isMatch = false;
                  break;
                }
              }
            }
            if (isMatch) {
              this.localChecked.push(this.filteredData[i]);
            }
          }
        }
        else {
          this.localChecked.push(this.filteredData[i]);
        }
      }


      this.selectAllButton.nativeElement.className = "button background-color-green";
      this.label = "SelectAll";
      if (this.selectText) {
        this.label = "SelectAll (" + this.selectText + ")";
      }
    }
    else {
      this.selectAllButton.nativeElement.className = "button";
      this.label = this.startLabel || this.dataLabel;
      if (this.dataArray && this.dataArray.length > 0 && this.isMultiple) {
        this.label = '';
        for (let i = 0; i < this.dataArray.length; i++) {
          if (this.dataArray.length === 1) {
            this.label += this.dataArray[i].name;
          } else {
            this.label += this.dataArray[i].name + ', ';
          }
        }
        for (let i = 0; i < this.dataArray.length; i++) {
          this.localChecked.push(this.dataArray[i]);
        }
      }
    }
  }

  /**
  * Do lazy loading
  * add lazy loading on custom select
  * @param {any} event scrolling
  * @memberof CustomSelectComponent
  */

  @HostListener('window:scroll', ['$event'])
  doLazyLoading(event) {
    this.cdRef.detectChanges();
    if (this.selectContainer.nativeElement.scrollTop >= this.selectContainer.nativeElement.scrollHeight - this.selectContainer.nativeElement.clientHeight - 100 && !this.selectLazyLoading) {
      this.selectLazyLoading = true;
      this.isDisabled = true;
      let incr = this.selectStart;
      this.selectStart += this.countPagination;
      this.filterMultiSelect();
    }
  }

  except() {
    this.isOpenSelect = true;
    this.sendData.emit({ data: this.dataArray, isSelectAll: this.isSelectAll, selectText: this.selectText, isExcept: this.isExcept });
  }

  /**
   * Open select
   * @memberof CustomSelectComponent
   */

  public openSelect() {
    this.isOpenSelect = !this.isOpenSelect;
    this.open.emit();
  }

  /**
   * Close select
   * @memberof CustomSelectComponent
   */

  public closeCustomSelect() {
    this.isOpenSelect = false;
  }

  /**
   * Select option
   * on custom select
   * @param {string} location selected location
   * @memberof CustomSelectComponent
   */

  public selectOption(location: string, event) {
    this.isOpenSelect = true;
    if (!this.dataArray) {
      this.dataArray = [];
    }
    if (this.selectText) {
      if (this.filterType == FilterType.Location) {
        if (!location.toLowerCase().includes(this.selectText.toLowerCase())) {
          this.selectText = "";
          this.isSelectAll = false;
          this.selectAllButton.nativeElement.className = "button";
        }
      }
      else if (this.filterType == FilterType.Company) {
        let isMatch = true;
        if (location.length < this.selectText.length) {
          isMatch = false;
        }
        else {
          for (let j = 0; j < this.selectText.length; j++) {
            if (j < location.length && this.selectText[j].toLowerCase() != location[j].toLowerCase()) {
              isMatch = false;
              break;
            }
          }
        }
        if (!isMatch) {
          this.selectText = "";
          this.isSelectAll = false;
          this.selectAllButton.nativeElement.className = "button";
        }
      }
    }

    if (this.dataArray.length == 0) {
      this.dataArray.push(location);
    }
    else {
      let isAdd = true;
      for (let i = 0; i < this.dataArray.length; i++) {
        if (this.dataArray[i].toLowerCase() == location.toLowerCase()) {
          this.dataArray.splice(i, 1);
          isAdd = false;
          break;
        }
      }
      if (isAdd) {
        this.dataArray.push(location);
      }
    }
    this.label = '';
    if (this.isSelectAll) {
      this.label = "SelectAll";
      if (this.selectText) {
        this.label = "SelectAll (" + this.selectText + ")";
      }
    }
    else {
      for (let i = 0; i < this.dataArray.length; i++) {
        if (this.dataArray.length === 1) {
          this.label += this.dataArray[i].name;
        } else {
          this.label += this.dataArray[i].name + ', ';
        }
      }
    }


    if (this.dataArray.length === 0 && !this.isSelectAll) {
      this.label = this.dataLabel;
    }

    this.sendData.emit({ data: this.dataArray, isSelectAll: this.isSelectAll, selectText: this.selectText, isExcept: this.isExcept });
  }

  public selectItem(item: any, event: any) {
    this.isOpenSelect = true
    if (!this.dataArray) {
      this.dataArray = [];
    }
    if (this.dataArray.length < 1 && (this.isMultiple || event.checked)) {
      this.dataArray.push(item)
      this.localChecked.push(item)
    }
    else if (this.isMultiple) {
      let idx = this.dataArray.findIndex(arrItem => arrItem.id == item.id)
      if (idx != -1) {
        this.dataArray.splice(idx, 1)
      } else {
        this.dataArray.push(item)
      }
    }

    this.label = '';

    if (this.isMultiple) {
      if (this.isSelectAll) {
        if (!event.checked) {
          let itemsWithoutUnchecked = this.filteredData.filter(value => !this.dataArray.includes(value))
          for (let i = 0; i < itemsWithoutUnchecked.length; i++) {
            this.label += i === itemsWithoutUnchecked.length ? itemsWithoutUnchecked[i].name : itemsWithoutUnchecked[i].name + ', '
          }
        }
        else {
          this.label = "SelectAll";
          if (this.selectText) {
            this.label = "SelectAll (" + this.selectText + ")";
          }
        }
      }
      else {
        for (let i = 0; i < this.dataArray.length; i++) {
          if (this.dataArray.length === 1) {
            this.label += this.dataArray[i].name;
          } else {
            this.label += this.dataArray[i].name + ', ';
          }
        }
      }
    } else {
      if (event.checked) {
        this.dataArray = [item]
        this.localChecked = [item]
        this.label = item.name
      } else {
        this.dataArray = []
        this.localChecked = []
        this.label = this.dataLabel
      }
      this.isSelectedItem.emit(event.checked)
    }
    this.sendData.emit({ data: this.dataArray, isSelectAll: this.isSelectAll, selectText: this.selectText, isExcept: this.isExcept });
    this.deselectAllButton.nativeElement.className = "button";
    this.selectAllButton.nativeElement.className = "button";
    if (!this.isMultiple) {
      this.isOpenSelect = false;
    }
  }
  /**
  * Filter location multi select
  * creating filtered list of data by search
  * @memberof CustomSelectComponent
  */

  private filterMultiSelect() {
    let search = this.searchText;
    if (!search) {
      if (!this.selectLazyLoading) {
        this.selectStart = 0;
      }
      this.loadDataEvent.emit({ take: this.countPagination, skip: this.selectStart, isNotLetter: this.isNotLetter });
      return;
    } else {
      if (!this.selectLazyLoading) {
        this.selectStart = 0;
      }
      search = search.toLowerCase();
      this.autoCompleteEvent.emit({ searchString: search, skip: this.selectStart, take: this.countPagination, isNotLetter: this.isNotLetter });
      return;
    }
  }

  /**
   * Select all data
   * @memberof CustomSelectComponent
   */

  public selectAllData() {
    if (this.searchText) {
      if (this.selectText.toLowerCase() != this.searchText.toLowerCase()) {
        this.selectText = this.searchText;
      }
    }
    let localCheckedBuff = [];
    this.localChecked = [];
    for (let i = 0; i < this.filteredData.length; i++) {
      if (this.selectText) {
        if (this.filterType == FilterType.Location) {
          if (this.filteredData[i].name.toLowerCase().includes(this.selectText.toLowerCase())) {
            localCheckedBuff.push(this.filteredData[i]);
          }
        }
        else if (this.filterType == FilterType.Company) {
          let isMatch = true;
          if (this.filteredData[i].name.length < this.selectText.length) {
            isMatch = false;
            break;
          }
          else {
            for (let j = 0; j < this.selectText.length; j++) {
              if (this.selectText[j].toLowerCase() != this.filteredData[i].name[j].toLowerCase()) {
                isMatch = false;
                break;
              }
            }
          }
          if (isMatch) {
            localCheckedBuff.push(this.filteredData[i]);
          }
        }
      }
      else {
        localCheckedBuff.push(this.filteredData[i]);
      }
    }

    this.dataArray = [];
    this.isSelectAll = true;
    this.sendData.emit({ data: this.dataArray, isSelectAll: this.isSelectAll, selectText: this.selectText, isExcept: this.isExcept });
    this.deselectAllButton.nativeElement.className = "button";
    this.selectAllButton.nativeElement.className = "button background-color-green";
    this.label = "SelectAll";
    if (this.selectText) {
      this.label = "SelectAll (" + this.selectText + ")";
    }
    setTimeout(() => {
      this.localChecked = localCheckedBuff;
    });
  }

  public checkItem(item: any) {
    // if(!this.isMultiple) {
    //   this
    // }
    // if (this.localChecked.some())
    // this.localChecked.push(item) 

    return this.localChecked.some(i => i.id == item)
  }
  /**
   * Deselect all data
   * @memberof CustomSelectComponent
   */

  public deselectAllData() {
    this.selectText = "";
    this.isSelectAll = false;
    this.dataArray = [];
    this.searchText = "";
    for (let i = 0; i < this.filteredData.length; i++) {
      this.dataArray.push(this.filteredData[i]);
    }
    for (let i = 0; i < this.dataArray.length; i++) {
      this.localChecked.push(this.dataArray[i]);
    }
    setTimeout(() => {
      this.localChecked = [];
    });
    this.dataArray = [];
    this.sendData.emit({ data: this.dataArray, isSelectAll: this.isSelectAll, selectText: this.selectText, isExcept: this.isExcept });
    this.label = this.dataLabel;
    this.selectAllButton.nativeElement.className = "button";
    this.deselectAllButton.nativeElement.className = "button background-color-green";
  }


  public setData(data) {
    this.isShowLoading = false;
    if (data) {
      if (this.selectLazyLoading) {
        if (data.length != 0) {
          data.forEach(i => {
            this.filteredData.push(i);
          });
        }
        this.isDisabled = false;
        this.selectLazyLoading = false;
      }
      else {
        if (data.length != 0) {
          this.isShowSelectAll = true;
        }
        else {
          this.isShowSelectAll = false;
        }
        this.filteredData = data;
      }
      if (this.isSelectAll) {
        this.localChecked = [];
        let removeList = [];
        for (let i = 0; i < this.filteredData.length; i++) {
          if (this.selectText) {
            if (this.filterType == FilterType.Location) {
              if (this.filteredData[i] && this.filteredData[i].name.toLowerCase().includes(this.selectText.toLowerCase()) && !this.dataArray.includes(this.filteredData[i])) {
                this.localChecked.push(this.filteredData[i]);
              }
            }
            else if (this.filterType == FilterType.Company) {
              let isMatch = true;
              if (this.filteredData[i].length < this.selectText.length) {
                isMatch = false;
                break;
              }
              else {
                for (let j = 0; j < this.selectText.length; j++) {
                  if (j < this.filteredData[i].length && this.selectText[j].toLowerCase() != this.filteredData[i][j].toLowerCase()) {
                    isMatch = false;
                    break;
                  }
                }
              }
              if (isMatch) {
                this.localChecked.push(this.filteredData[i]);
              }
            }
          }
          else {
            this.localChecked.push(this.filteredData[i]);
          }
        }

        this.label = "SelectAll";
        if (this.selectText) {
          this.label = "SelectAll (" + this.selectText + ")";
        }

      }
      else {
        this.localChecked = [];
        if (this.dataArray) {
          for (let i = 0; i < this.dataArray.length; i++) {
            let isAdd = true;
            for (let j = 0; j < this.localChecked.length; j++) {
              if (this.localChecked[j].name.toLowerCase().indexOf(this.dataArray[i].name.toLowerCase()) != -1) {
                isAdd = false;
              }
            }
            if (isAdd) {
              this.localChecked.push(this.dataArray[i]);
            }
          }
          if (this.dataArray.length > 0) {
            this.label = "";
          }
          for (let i = 0; i < this.dataArray.length; i++) {
            if (this.dataArray.length === 1) {
              this.label += this.dataArray[i].name;
            } else {
              this.label += this.dataArray[i].name + ', ';
            }
          }
        }
      }
    }
  }
}
