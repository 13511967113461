import { Component, ViewChild, Output, EventEmitter } from '@angular/core';
import { ModalComponent } from '../../modal/modal.component';

@Component({
  selector: 'pd-custom-meeting-date',
  templateUrl: './custom-meeting-date.component.html',
  styleUrls: ['./custom-meeting-date.component.scss']
})
export class CustomMeetingDateModalComponent {
  @ViewChild('modal') modal: ModalComponent;

  @Output() closeModal = new EventEmitter<object>();
  @Output() saveDate = new EventEmitter<Date>();

  minDate = new Date(Date.now());
  maxDate = undefined;
  today = new Date();
  yesterDay = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate());
  public meetingDate: Date;
  private confirmAction: any;

  public openMeetingDateModal(confirmCallback, maxDate?) {
    this.maxDate = maxDate;
    this.confirmAction = confirmCallback;
    this.modal.open();
  }

  public confirm(isConfirm) {
    isConfirm ? this.ok() : this.cancel();
    this.modal.close();
  }

  private ok() {
    this.saveDate.emit(this.meetingDate);
    if (this.confirmAction) {
      this.confirmAction();
    }   
  }

  private cancel() {
    this.meetingDate = null;
    this.closeModal.emit();
    this.modal.close();
  }
}