<div class="deal-card nodrag">
    <div class="deal-item" [ngClass]="{'red': isRotting || isAlwaysRedDeal, 'birthday': checkIsBirthday}"
        (click)="openDealDetails();$event.stopPropagation()">
        <div class="deal-information">
            <div class="text-title">
                <div>{{deal.title}}</div>
                @if (isAllowedClientLifetimeSpend) {
                <div>
                    <div class="text-small row lifetime-spend">${{deal.client_lifetime_spend | money}}</div>
                </div>
                }
                <div>
                    <img class="min-icon" src="../../assets/copy_title.png" [text-copy]="deal.title"
                        bootstrapTooltip="Copy Name" placement="bottom" (click)="$event.stopPropagation()">
                    @if (isClient) {
                    <img class="min-icon" src="../../assets/basket.png" bootstrapTooltip="Client Card"
                        placement="bottom" (click)="onBasketClicked.emit(deal);$event.stopPropagation()">
                    }
                </div>
            </div>
            <div class="text-small">
                @if (deal.preferToContactByEmail) {
                <div class="email-only">Email only</div>
                }
                <small>{{deal.company}}</small>
                <small>{{deal.position}}</small>
                @if (deal.personWhoReferred) {
                <div><small><b>Ref: </b>{{deal.personWhoReferred}}</small></div>
                }
                @if (deal.previous_labeled_stage_id) {
                <small><b>{{getLabeledStageName()}}</b></small>
                }
            </div>
            <pd-deal-card-contacts class="text-small" [contacts]="deal.phone"></pd-deal-card-contacts>
            <pd-deal-card-contacts class="text-small" [contacts]="deal.email"></pd-deal-card-contacts>
            <pd-custom-contacts [deal]="deal" (openModal)="onEditContactsClicked.emit(deal);"></pd-custom-contacts>
            @if (deal.linkedin_URL) {
            <div class="text-small row linkedin-url">
                <div class="linkedin-url col-8" (click)="openlinkedIn();$event.stopPropagation()">LinkedIn
                    Profile</div>
            </div>
            }
            @if (deal.stage_id === ALL_STAGES.Less3Weeks
            || deal.stage_id === ALL_STAGES.Less6Weeks
            || deal.stage_id === ALL_STAGES.Less9Weeks
            || deal.stage_id === ALL_STAGES.OverdueLess12Weeks
            || deal.stage_id === ALL_STAGES.OverdueLess16Weeks
            || deal.stage_id === ALL_STAGES.OverdueLess24Weeks
            || deal.stage_id === ALL_STAGES.WriteOff) {
            <button class="orderStatusButton" (click)="goToLink('http://ingot.cloud/');$event.stopPropagation()"
                bootstrapTooltip="Order Status">Order
                Status</button>
            }
            @if (deal.trackingLink) {
            <button class="orderStatusButton" (click)="goToLink(deal.trackingLink);$event.stopPropagation()"
                bootstrapTooltip="Track Order">Track
                Order</button>
            }
            @if (isDocumentBtnShow && deal.files_count > 0) {
            <div>
                <button class="btn btn-primary waves-light hotlist" (click)="openHotlist()">Hotlist</button>
            </div>
            }
            @if (isDocumentBtnShow && deal.files_count_pdf > 0) {
            <div>
                <button class="btn btn-primary waves-light hotlist" (click)="getPdf();$event.stopPropagation()">Hotlist
                    2.0</button>
            </div>
            }
            @if (isDocumentBtnShow) {
            <div>
                <button class="btn btn-primary waves-light wardrobe"
                    (click)="onExportWardrobePlanClicked.emit(deal);$event.stopPropagation()">
                    @if (deal.wardrobe_image_count > 0) {
                    <span>&#10003;</span>
                    } Wardrobe Plan
                </button>
            </div>
            }
            @if (isDocumentBtnShow) {
            <div>
                <button class="btn btn-primary waves-light hotlist"
                    (click)="onUploadWardrobePlanClicked.emit(deal);$event.stopPropagation()">Upload Wardrobe
                    Plan</button>
            </div>
            }
            @if (isDocumentBtnShow) {
            <div>
                <button class="btn btn-primary waves-light hotlist"
                    (click)="onMeetingPrepClicked.emit(deal);$event.stopPropagation()">Print Meeting Prep</button>
            </div>
            }
            @if (deal.stage_id === ALL_STAGES.EACurrentMeeting || deal.stage_id === ALL_STAGES.Meeting || deal.stage_id
            === ALL_STAGES.CurrentMeeting || deal.stage_id === ALL_STAGES.DeliveryMeeting || deal.pipeline_id ===
            PIPELINES.ClothierMeetingConfirm) {
            <div>
                @if (deal.last_request_hotlist_date && deal.last_request_hotlist_date.length > 0) {
                <div class="date-info">
                    {{deal.last_request_hotlist_date.slice(0, 10)}}</div>
                }
            </div>
            }
            @if (isAllowedPostpone) {
            <div (click)="onPostponeClicked.emit(this.deal);$event.stopPropagation()">
                <button class="orderStatusButton"
                    [disabled]="(deal.increasingRottenTimeCounter && deal.maxIncreasingRottenTimeCounter) && deal.increasingRottenTimeCounter >= deal.maxIncreasingRottenTimeCounter">Postpone</button>
            </div>
            }
            @if (deal.stage_id === ALL_STAGES.NewOrder && isDealerAssistant) {
            <div class="row hover called">
                <mat-checkbox (click)="$event.stopPropagation()" class="checkbox-label" [disabled]="deal.called"
                    (change)="onCalledChanged();" [checked]="deal.called">Called
                </mat-checkbox>
            </div>
            }
            @if ((deal.stage_id === ALL_STAGES.Less3Weeks
            || deal.stage_id === ALL_STAGES.Less6Weeks
            || deal.stage_id === ALL_STAGES.Less9Weeks
            || deal.stage_id === ALL_STAGES.OverdueLess12Weeks
            || deal.stage_id === ALL_STAGES.OverdueLess16Weeks) && isDealerAssistant) {
            <div class="row hover called">
                <mat-checkbox (click)="$event.stopPropagation()" class="checkbox-label"
                    [disabled]="deal.isOpenOrderNotified || !deal.is_rotting"
                    (change)="onIsOpenOrderNotifiedChanged.emit(deal);"
                    [(ngModel)]="deal.isOpenOrderNotified">{{DealUtil.getEaFollowUpTypeLabel(deal.stage_id)}}
                </mat-checkbox>
            </div>
            }
            @if (deal.stage_id === ALL_STAGES.DeliveryCheck && isDealerAssistant) {
            <div class="row hover called">
                <mat-checkbox (click)="$event.stopPropagation()" class="checkbox-label"
                    [disabled]="deal.isDeliveryEmailSent" (change)="onIsDeliveryEmailSentChanged.emit(deal)"
                    [(ngModel)]="deal.isDeliveryEmailSent">Email sent</mat-checkbox>
            </div>
            }
            @if ((deal.stage_id === ALL_STAGES.Less3Weeks
            || deal.stage_id === ALL_STAGES.Less6Weeks
            || deal.stage_id === ALL_STAGES.Less9Weeks
            || deal.stage_id === ALL_STAGES.OverdueLess12Weeks
            || deal.stage_id === ALL_STAGES.OverdueLess16Weeks
            || deal.stage_id === ALL_STAGES.OverdueLess24Weeks
            || deal.stage_id === ALL_STAGES.WriteOff)
            && (isMasterLeadAccount
            || isAdminAccount
            || isClothierAccount
            || isEaAccount)) {
            <div class="row hover called">
                <mat-checkbox (click)="$event.stopPropagation()" class="checkbox-label"
                    [(ngModel)]="deal.isOrderShipped" (change)="isOrderShippedChange()"
                    [disabled]="deal.isOrderShipped || isClothierAccount">
                    Order Shipped</mat-checkbox>
            </div>
            }
            @if (deal.stage_id === ALL_STAGES.NewOrder && isDealerAssistant) {
            <div class="row hover called">
                <mat-checkbox (click)="$event.stopPropagation()" class="checkbox-label" [disabled]="!deal.called"
                    (change)="onDraftReadyChanged.emit(deal);">
                    Draft Ready</mat-checkbox>
            </div>
            }
            @if ((deal.stage_id === ALL_STAGES.Reschedule && deal.is_rotting) && isDealer) {
            <div (click)="onReselectRescheduleDateClicked.emit(deal);$event.stopPropagation()">
                <button class="orderStatusButton">Reselect date</button>
            </div>
            }
            @if ((deal.stage_id === ALL_STAGES.InAlterations && deal.is_rotting) && isDealer) {
            <div (click)="onReselectInAlterationDateClicked.emit(deal);$event.stopPropagation()">
                <button class="orderStatusButton">Reselect Delivery date</button>
            </div>
            }
            @if ((deal.stage_id === ALL_STAGES.DeliveryMeeting && deal.is_rotting) && isDealer) {
            <div (click)="onReselectDeliveryMeetingDateClicked.emit(deal);$event.stopPropagation()">
                <button class="orderStatusButton">Reselect Delivery date</button>
            </div>
            }
        </div>
        <div class="deal-right-side">
            @if (!isClient && deal.participants_count > 0) {
            <pd-custom-crown [deal]="deal"></pd-custom-crown>
            }
            @if (deal.same_building_count > 0) {
            <pd-custom-same-building [deal]="deal"></pd-custom-same-building>
            }
            @if (checkIsBirthday) {
            <img src="../../assets/birthday.png" placement="bottom">
            }
            <pd-custom-notes [deal]="deal"></pd-custom-notes>
        </div>
    </div>
</div>