import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'pd-two-rows-in-edit',
  templateUrl: './two-rows-in-edit.component.html',
  styleUrls: ['./two-rows-in-edit.component.scss']
})
export class TwoRowsInEditComponent implements OnInit {

  public label: string;
  public PhoneLabel = ['Work', 'Home', 'Mobile', 'Other'];
  @Input() phone = [
    {value: '', label: ''}];

  constructor() { }

  ngOnInit() {
  }

  @Output() cancel = new EventEmitter<void>();
  cancelEdit() {
    this.cancel.emit();
  }

  @Output() save = new EventEmitter();

  /**
    * Change value
    * label of phone
    * @param {string} label label of phone
    * @memberof TwoRowsInEditComponent
    */

  public changeValue(label: string) {
    this.label = label;
  }

  /**
    * Save value
    * @memberof TwoRowsInEditComponent
    */

  public saveValue(phone) {
    this.save.emit(this.phone);
  }

  /**
    * Add one more
    * element in array
    * @memberof TwoRowsInEditComponent
    */

  public addOneMore() {
    this.phone.push({value: '', label: 'Work'});
  }

  public deletePhone(index) {
    if(this.phone && this.phone.length > 0)
      this.phone.splice(index, 1)
    this.saveValue(this.phone)
  }
  
}
