import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ClientCardEffectsService } from "./client-card.service.";
import { ClientCardStoreService } from "./client-card-store.service";
import { ClientCard } from "../../models/ClientCard";
import { ClientCardApiService } from "./client-card-api.service";
import { ClientCardConfig } from "../../models/ClientCardConfig";
import { ProductTypes } from "../../enums/client-card/ProductTypes";

@Injectable()
export class ClientCardFacadeService {

    constructor(
        private clientCardEffectsService: ClientCardEffectsService,
        private clientCardStoreService: ClientCardStoreService,
        private clientCardApiService: ClientCardApiService,
    ) {
    }

    public loadClientCardsByDealId(dealId: number): Promise<ClientCard[]> {
        return this.clientCardEffectsService.loadClientCardsByDealId(dealId).toPromise();
    }

    public getCardConfig(dealId: number): Promise<ClientCardConfig> {
        return this.clientCardApiService.getCardConfig(dealId);
    }

    public getClientCards(): Observable<ClientCard[]> {
        return this.clientCardStoreService.getClientCards();
    }

    public createClientCard(clientCard: ClientCard, isFabricUpdated: boolean): Promise<number> {
        return this.clientCardEffectsService.createClientCard(clientCard, isFabricUpdated).toPromise();
    }

    public updateClientCard(clientCard: ClientCard, isFabricUpdated: boolean): Promise<boolean> {
        return this.clientCardEffectsService.updateClientCard(clientCard, isFabricUpdated).toPromise();
    }

    public deleteClientCard(cardId: number): Promise<boolean> {
        return this.clientCardEffectsService.deleteClientCard(cardId).toPromise();
    }

    public updateCustomLink(dealId: number, label: string, link: string): Promise<boolean> {
        return this.clientCardApiService.updateCustomLink(dealId, label, link);
    }

    public generateDealAvatars(dealId: number, productTypeId: ProductTypes): Promise<boolean> {
        return this.clientCardEffectsService.generateDealAvatars(dealId, productTypeId).toPromise();
    }
}