<div class="userListModal" [@modalState]="moveContactsModal">

  <div class="wrapper">

    <div class="phone-row">
      <div class="phone-label">
        Phone:
      </div>
      <div>
        <pd-field-intl-tel #fieldIntlTel [isNeedSaveCancelBtn]="false">
        </pd-field-intl-tel>
      </div>
    </div>

    <div class="email-row">
      <div class="email-label">
        Email:
      </div>
      <div>
        <pd-field-emails #fieldEmails [isNeedSaveCancelBtn]="false"></pd-field-emails>
      </div>
    </div>

    <div class="buttons">
      <button class="btn btn-danger cancel" (click)="closingModal();$event.stopPropagation()">Cancel</button>
      <button class="btn btn-success save" [disabled]="!validationCheck()"
        (click)="saveData();$event.stopPropagation()">Save</button>
    </div>
  </div>
</div>