import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { Chat } from "src/app/core/models/chat-models/Chat";
import { DateHelper } from "src/app/core/utils/date.helper";
import { FileUtil } from "src/app/core/utils/file.util";
import { Message } from "src/app/core/models/chat-models/Message";
import { UserContextService } from "src/app/core/services/user-context.service";
import { RolesEnum } from "src/app/core/enums/RolesEnum";
import { User } from "src/app/core/models/UserModel";
import { TwilioHelper } from "src/app/core/utils/twilio.helper";
import { TwilioFacadeService } from "src/app/core/services/twilio/twilio-facade.service";
import { takeUntil } from "rxjs";
import { BaseComponent } from "src/app/core/base.component";
import { ChatConversationTypes } from "src/app/core/enums/ChatConversationTypes";
import { EnumUtil } from "src/app/core/utils/enum.util";

@Component({
  selector: 'pd-chat-selector',
  templateUrl: './chat-selector.component.html',
  styleUrls: ['./chat-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ChatSelectorComponent extends BaseComponent {
  @ViewChild("targetScroll") targetScroll: ElementRef;
  @ViewChild("searchInput") searchInput: ElementRef;

  @Output() public onChangeSearch = new EventEmitter<any>();
  @Output() public onLazyLoading = new EventEmitter<any>();
  @Input() public isGlobalChat: boolean = false;

  public searchText: string = '';
  public dateFormat = 'dd-MMM-yyyy h:mm a';

  public TwilioHelper = TwilioHelper;
  public DateHelper = DateHelper;
  public FileUtil = FileUtil;
  public EnumUtil = EnumUtil;
  public ChatConversationTypes = ChatConversationTypes;

  public chatList: Chat[] = [];
  public activeChat?: Chat;

  public conversationTypeList = [];
  public selectedConversationType: ChatConversationTypes;

  public isSpinner: boolean = false;
  public isRecruiterAccount: boolean = false;

  private currentUser: User;

  constructor(private userContextService: UserContextService,
    private readonly ref: ChangeDetectorRef,
    private twilioFacadeService: TwilioFacadeService) {
    super();

    this.currentUser = this.userContextService.user.value;
    this.isRecruiterAccount = this.currentUser.role_id === RolesEnum.Recruiter;

    if (this.isRecruiterAccount) {
      this.selectedConversationType = ChatConversationTypes.Candidates;
      this.conversationTypeList = [ChatConversationTypes.Candidates];
    }
    
    if (this.currentUser.role_id === RolesEnum.Clothier || this.currentUser.role_id === RolesEnum.Admin) {
      this.conversationTypeList = [ChatConversationTypes.Clients, ChatConversationTypes.Killed];
    }

    if(this.userContextService.user.value.isGlobalChat || this.currentUser.role_id === RolesEnum.MasterLead) {
      this.conversationTypeList = [ChatConversationTypes.Clients, ChatConversationTypes.Candidates, ChatConversationTypes.Killed];
      this.selectedConversationType = ChatConversationTypes.Clients;
    }

    this.twilioFacadeService.getLoaderObservable().pipe(takeUntil(this.destroy$)).subscribe(loader => {
      this.isSpinner = loader;
      this.ref.markForCheck();
    });

    twilioFacadeService.getActiveChatObservable().pipe(takeUntil(this.destroy$)).subscribe(chat => {
      this.activeChat = chat;
      this.ref.markForCheck();
    });

    twilioFacadeService.getChatListObservable().pipe(takeUntil(this.destroy$)).subscribe(chatList => {
      this.chatList = chatList;
      this.ref.markForCheck();
    });
  }

  public async setActiveChat(chat: Chat) {
    this.twilioFacadeService.setLoader(true);
    await this.twilioFacadeService.setActiveChat(chat);
    this.twilioFacadeService.setLoader(false);
  }

  public getErrorMessageTooltip(message: Message) {
    return message.errorMessage;
  }

  public doLazyLoading(event) {
    if (this.isSpinner) {
      return;
    }

    if (event.srcElement.scrollTop + 10 > event.srcElement.scrollHeight - event.srcElement.clientHeight) {
      this.onLazyLoading.emit();
    }
  }

  public doConversationType() {
    if (this.isSpinner) {
      return;
    }
    this.onChangeSearch.emit(this.searchText);
    this.scrollToStart();
  }

  public doSearch() {
    if (this.isSpinner) {
      return;
    }

    if (this.searchText == this.searchInput.nativeElement.value) {
      return;
    }

    this.searchText = this.searchInput.nativeElement.value

    if (!this.searchText || this.searchText.trim().length > 2) {
      this.onChangeSearch.emit(this.searchText);
      this.scrollToStart();
    }
  }

  private scrollToStart() {
    setTimeout(() => {
      this.targetScroll.nativeElement.scrollIntoView({ block: "start" });
    }, 1);
  }
}
