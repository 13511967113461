@if (avatar) {
<div class="tile" [ngClass]="{'tile-tuxedo': AvatarUtil.isTuxedo(avatar) }"
  (click)="editAvatar(avatar);$event.stopPropagation()">
  <div class="tile-title">
    <span class="tile-title_label">{{getAvatarLabel(avatar.avatarTypeId)}}</span>
    <div class="tile-title_tool">
      <button class="btn btn-outline-primary tool-btn" (click)="downloadAvatar(avatar); $event.stopPropagation()"
        matTooltip="Download avatar" matTooltipPosition="above">
        <i class="material-icons">cloud_download</i>
      </button>
      @if (isExistingAvatarCollection) {
      <button (click)="deleteAvatar(avatar); $event.stopPropagation()" matTooltip="Delete avatar"
        matTooltipPosition="above" class="btn btn-outline-danger min-btn tool-btn">
        <i class="material-icons">delete</i>
      </button>
      }
      @if (!isExistingAvatarCollection) {
      <button (click)="removeAvatarFromCollection(avatar); $event.stopPropagation()"
        matTooltip="Remove avatar from collection" matTooltipPosition="above"
        class="btn btn-outline-danger min-btn tool-btn">
        <i class="material-icons">delete</i>
      </button>
      }
    </div>
  </div>
  <div class="tile-body">
    @if (avatar) {
    <div>

      <div class="image">
        @if (avatar.base64) {
        <img [src]="'data:image/png;base64,' + avatar.base64"
          onerror="this.onerror=null; this.src='../../assets/lgfg-logo.png'" />
        }
        @if (!avatar.base64) {
        <div class="spinner">
          <mat-spinner [diameter]="100"></mat-spinner>
        </div>
        }
      </div>

      <div>
        <div class="row">
          <div class="avatar-info-label text">
            <span>Date:</span>
          </div>
          <div class="col">
            <div class="text">
              <span>
                {{avatar.created | date :'dd-MMM-yyyy'}}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="avatar-info-label text">
          <span>Body Type:</span>
        </div>
        <div class="col">
          <div class="text">
            <span>{{getBodyType(avatar.bodyTypeId)}}</span>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="avatar-info-label text">
          <span>Skin Type:</span>
        </div>
        <div class="col">
          <div class="text">
            <span>{{getSkinType(avatar.skinTypeId)}}</span>
          </div>
        </div>
      </div>

      @if (avatarCollections && avatarCollections.length > 0) {
      <div class="row">
        <div class="avatar-info-label text">
          <span>Collections:</span>
        </div>
        <div class="col">
          <div class="text" [matTooltip]="getCollectionTooltip()">
            @for (collection of avatarCollections.slice(0, 2); track collection) {
            <div class="text-ellipsis">
              <span>{{collection.collectionName}}</span>
            </div>
            }
            @if (avatarCollections.length > 2) {

            <span>...</span>

            }
          </div>
        </div>
      </div>
      }

      <div class="row">
        <div class="avatar-info-label text">
          <span>Fabric:</span>
        </div>
        <div class="col">
          <div class="text">
            @for (avatarElement of avatar.avatarElements; track avatarElement) {
            <div class="text-ellipsis">
              @if (avatarElement && avatarElement.elementTypeId != AvatarElementTypes.Head) {

              <span>{{getAvatarElementTypeLabel(avatarElement.elementTypeId)}} - </span>
              @if (!isFabric(avatarElement.imageLink)) {
              <a [href]="avatarElement.imageLink" (click)="$event.stopPropagation()">Link</a>
              }
              @if (isFabric(avatarElement.imageLink)) {
              <span (click)="$event.stopPropagation()">
                {{getFabricTitle(avatarElement.imageLink)}}</span>
              }

              }
            </div>
            }
          </div>
        </div>
      </div>

    </div>
    }
  </div>
</div>
}