export abstract class BaseService {
  protected async handleResponse(thanable: Promise<any>): Promise<any> {
    const resp = await thanable;
    if (resp && resp.error) {
      if (typeof resp.error === 'object') {
        throw resp.error;
      } else {
        throw new Error(resp.error);
      }
    }
    return resp;
  }

  protected validateBlob(blob: any) {
    if (!blob) {
      throw new Error('Stream is empty');
    }
    if (blob.type !== 'application/octet-stream') {
      throw new Error('Invalid stream');
    }
  }
}
