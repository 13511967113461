import { GlobalConstants } from "../global-constants";

export class DealUtil {
  public static getEaFollowUpTypeLabel(stageId: number): string {
    switch (stageId) {
      case GlobalConstants.ALL_STAGES.Less3Weeks: return "2 weeks";
      case GlobalConstants.ALL_STAGES.Less6Weeks: return "5 weeks";
      case GlobalConstants.ALL_STAGES.Less9Weeks: return "Check order status and delivery date";
      case GlobalConstants.ALL_STAGES.OverdueLess12Weeks: return "Check order status and delivery date";
      case GlobalConstants.ALL_STAGES.OverdueLess16Weeks: return "Check order status and delivery date";
      case GlobalConstants.ALL_STAGES.DeliveryCheck: return "Email sent";
    }
  }

  public static getDealUrl(dealId: number) {
    return window.location.origin + "/deals/" + dealId;
  }  
  
  public static nameToPossessiveForm(clientName: string) {
    if (!clientName) {
      return clientName;
    }
    return clientName + ((clientName.slice(-1) == 's') ? `'` : `'s`);
  }
}
