import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { Message } from "../../models/chat-models/Message";
import { Chat } from "../../models/chat-models/Chat";

@Injectable()
export class TwilioStoreService {
    private readonly loader$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    private readonly messages$: BehaviorSubject<Message[]> = new BehaviorSubject<Message[]>(null);
    private readonly activeChat$: BehaviorSubject<Chat> = new BehaviorSubject<Chat>(null);
    private readonly chatList$: BehaviorSubject<Chat[]> = new BehaviorSubject<Chat[]>(null);
    private readonly newMessage$: BehaviorSubject<Message> = new BehaviorSubject<Message>(null);

    public getLoaderObservable(): Observable<boolean> {
        return this.loader$.asObservable().pipe();
    }

    public getLoaderSnapshot(): boolean {
        return this.loader$.getValue();
    }

    public setLoader(loader: boolean): void {
        this.loader$.next(loader);
    }

    public getMessagesObservable(): Observable<Message[]> {
        return this.messages$.asObservable().pipe();
    }

    public getMessagesSnapshot(): Message[] {
        return this.messages$.getValue();
    }

    public setMessages(messages: Message[]): void {
        this.messages$.next(messages);
    }

    public getActiveChatObservable(): Observable<Chat> {
        return this.activeChat$.asObservable().pipe();
    }

    public getActiveChatSnapshot(): Chat {
        return this.activeChat$.getValue();
    }

    public setActiveChat(chat: Chat): void {
        this.activeChat$.next(chat);
    }

    public getChatListObservable(): Observable<Chat[]> {
        return this.chatList$.asObservable().pipe();
    }

    public getChatListSnapshot(): Chat[] {
        return this.chatList$.getValue();
    }

    public setChatList(chat: Chat[]): void {
        this.chatList$.next(chat);
    }

    public getNewMessageObservable(): Observable<Message> {
        return this.newMessage$.asObservable().pipe();
    }

    public getNewMessageSnapshot(): Message {
        return this.newMessage$.getValue();
    }

    public setNewMessage(message: Message): void {
        this.newMessage$.next(message);
    }
}