import { UserContextService } from './../core/services/user-context.service';
import { Component, ViewChild, HostListener, OnInit, ElementRef, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import * as stages from '../../assets/stages_new_prospespects';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { WardrobePlanComponent } from '../shared/custom/wardrobe-plan/wardrobe-plan.component';
import { MeetingPrepComponent } from '../shared/custom/meeting-prep/meeting-prep.component';
import { CustomUploadWardrobePlanModalComponent } from '../shared/custom/custom-wardrobe-plan-upload-modal/custom-wardrobe-plan-upload-modal.component';
import { Deal } from '../core/models/Deal';
import { SearchTypeEnum } from '../core/enums/SearchTypeEnum';
import { ChipsSelectFilterDataModel } from '../core/models/CustomChipsSelectFilterModel';
import { DealService } from '../core/services/deals.service';
import { HotlistService } from '../core/services/hotlist.service';
import printJS from 'print-js';
import { GlobalConstants } from '../core/global-constants';

@Component({
  selector: 'pd-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.scss']
})
export class GlobalSearchComponent implements OnInit {
  @ViewChild('container', { static: false }) container: ElementRef;
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('fileInputPdf') fileInputPdf: ElementRef;
  @ViewChild('wardrobePlan') wardrobePlan: WardrobePlanComponent;
  @ViewChild('meetingPrep') meetingPrep: MeetingPrepComponent;
  @ViewChild('uploadWardrobePlan') uploadWardrobePlan: CustomUploadWardrobePlanModalComponent;

  public searchText: string = '';
  public tableTitle = ["Name", "Stage", "Position", "Company", "Location", "Phone", "Owner"];
  public start = 0;
  public deals: Deal[] = [];
  public more_in_provider = false;
  public isLoading = false;
  public lazyLoading = false;
  public users = [];
  public limit = 20;
  public searchType = SearchTypeEnum.Name;
  public searchTypeList = [{ key: SearchTypeEnum.Name, value: 'Name' }, { key: SearchTypeEnum.Company, value: 'Company' }, { key: SearchTypeEnum.Position, value: 'Position' }];
  public isNoResultFound = false;
  public isHotlistSent = false;
  public meetingDate: Date;
  public uploadFileInfo: string;
  public isShowError: boolean;
  public searchResult: any;
  public form: FormGroup;

  private uploadHotlistDealId: number;
  private uploadHotlistFile: File;
  private currentUserId: number;
  private filteredLocations: ChipsSelectFilterDataModel[];

  constructor(
    private cdRef: ChangeDetectorRef,
    private userContextService: UserContextService,
    private dealService: DealService,
    public router: Router,
    private hotlistService: HotlistService,
    private fb: FormBuilder) {
    this.form = this.fb.group({
      term: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.currentUserId = this.userContextService.user.value.id;
  }

  public getDealsBySearch() {
    if (this.form.invalid) {
      return;
    }

    this.isLoading = true;
    this.isShowError = false;
    this.start = 0;

    const data = {
      Term: this.searchText,
      Start: this.start,
      Limit: this.limit,
      SearchType: this.searchType,
      Locations: this.filteredLocations,
    };

    this.dealService.getDealsBySearch(data).then(result => {
      this.searchResult = result
      this.deals = result.data;
      this.isNoResultFound = this.deals === null || this.deals.length === 0;
      this.isLoading = false;
      this.lazyLoading = false;
      this.cdRef.detectChanges();
      this.scrollToTop()
    },
      err => {
        this.isShowError = true;
        this.isLoading = false;
      }
    );
  }

  /**
   * Do something
   * add lazy loading in search
   * @param {any} event scrolling
   * @memberof GlobalSearchComponent
   */

  @HostListener('window:scroll', ['$event'])
  doSomething(event) {
    if (this.isLoading || this.lazyLoading) {
      return;
    }

    if ((this.container.nativeElement.scrollHeight - this.container.nativeElement.clientHeight) - this.container.nativeElement.scrollTop < 2) {
      this.start = this.searchResult.additional_data ? this.searchResult.additional_data.pagination.next_start : 0;
      this.more_in_provider = this.searchResult.additional_data ? this.searchResult.additional_data.pagination.more_items_in_collection : false;
      if (this.more_in_provider === true) {
        this.lazyLoading = true;

        let data = {
          Term: this.searchText,
          Start: this.start,
          Limit: this.limit,
          SearchType: this.searchType,
          Locations: this.filteredLocations,
        };

        this.dealService.getDealsBySearch(data).then(result => {
          setTimeout(() => {
            if (result.data) {
              result.data.forEach(element => {
                this.deals.push(element);
              });
            }

            this.searchResult = result
            this.more_in_provider = result.additional_data.pagination.more_items_in_collection;
            this.start = result.additional_data.pagination.next_start;
            this.lazyLoading = false;
          }, 300);
        },
          err => { }
        );
      } else {
        this.lazyLoading = false;
      }
    }
  }

  public openHotlist(id, title) {
    this.router.navigate(['/Hotlist', id, title]);
  }

  public openHotlistPdf(id) {
    this.hotlistService.getHotlistPdf(id).then((result) => {
      if (result)
        printJS({ printable: result, type: 'pdf', base64: true })
    })
  }

  public selectDeal(dealId: number) {
    this.uploadHotlistDealId = dealId;
  }

  public openInput() {
    this.fileInput.nativeElement.click();
  }

  public changeListener(files: FileList) {
    if (files && files.length > 0) {
      var splitArray = files[0].name.split('.');
      var fileType = splitArray[splitArray.length - 1];
      if (fileType && fileType.toLowerCase() !== "csv") {
        alert("Wrong file format. Please upload CSV file.");
      }
      else {
        this.uploadHotlistFile = files[0];
        this.uploadHotlist();
      }
      this.resetFileUpload();
    }
  }

  public uploadHotlist() {
    this.hotlistService.uploadHotlist(this.uploadHotlistFile, this.uploadHotlistDealId, this.currentUserId);
    this.uploadFileInfo = 'Uploading file task was started';
    setTimeout(() => {
      this.uploadFileInfo = null;
    }, 3000);
  }

  public openInputPdf() {
    this.fileInputPdf.nativeElement.click();
  }

  public changeListenerPdf(files: FileList) {
    if (files && files.length > 0) {
      var splitArray = files[0].name.split('.');
      var fileType = splitArray[splitArray.length - 1];
      if (fileType && fileType.toLowerCase() !== "pdf") {
        alert("Wrong file format. Please upload PDF file.");
      }
      else {
        this.uploadHotlistFile = files[0];
        this.uploadHotlistPdf();
      }
      this.resetFileUpload();
    }
  }

  public uploadHotlistPdf() {
    this.hotlistService.uploadHotlistPdf(this.uploadHotlistFile, this.uploadHotlistDealId, this.currentUserId);
    this.uploadFileInfo = 'Uploading file task was started';
    setTimeout(() => {
      this.uploadFileInfo = null;
    }, 3000);
  }

  public exportWardrobePlan(id, title) {
    this.wardrobePlan.printPdf(id, title);
  }

  public uploadWardrobePlanModal(deal) {
    this.uploadWardrobePlan.openUploadModal(deal);
  }

  public openMeetingPrepPdf(deal) {
    this.meetingPrep.openMeetingPrepPdf(deal);
  }

  resetFileUpload() {
    this.fileInput.nativeElement.value = "";
    this.fileInputPdf.nativeElement.value = "";
    this.uploadHotlistFile = null;
  }

  public isShowFileBtnStageCondition(deal: Deal): boolean {
    return (deal.stage_id === GlobalConstants.ALL_STAGES.Meeting
      || deal.pipeline_id === stages.Pipelines.Clients
      || deal.pipeline_id === stages.Pipelines.OpenOrders
      || deal.pipeline_id === stages.Pipelines.ClothierContactClients
      || deal.pipeline_id === stages.Pipelines.ClothierMeetingConfirm)
  }

  public closeSelectFilter() {
  }

  public getLocations(data: ChipsSelectFilterDataModel[]) {
    this.filteredLocations = data;
  }

  private scrollToTop() {
    this.container.nativeElement.scrollTo({
      top: 0,
      behavior: 'auto'
    });
  }
}
