import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { EditInProgressComponent } from '../field-landing/edit-in-progress/edit-in-progress.component';
import { CustomSelectFilterComponent } from 'src/app/shared/custom/custom-select-filter/custom-select-filter.component';
import { DealService } from 'src/app/core/services/deals.service';
import { FilterType } from 'src/app/core/enums/FilterType.enum';

@Component({
  selector: 'pd-field-position',
  templateUrl: './field-position.component.html',
  styleUrls: ['./field-position.component.scss']
})
export class FieldPositionComponent implements OnInit {
  @Output() position: string = "Positions"
  @Output() positionChange = new EventEmitter<string>();
  @Output() open = new EventEmitter<string>();
  public positionsLabel: string = 'Positions';
  @ViewChild("positionFilter") customSelectPosition: CustomSelectFilterComponent;
  @ViewChild("positionEdit") positionEdit: EditInProgressComponent;
  public isEdit = false;
  public isSelectedPosition = false

  constructor(private dealService: DealService) { }

  ngOnInit() {
    this.isSelectedPosition = false
  }

  /**
    * Add value
    * open edit
    * @memberof FieldLinkedinLocationComponent
    */

  public addValue() {
    this.isEdit = true;
  }

  public changeValue() {
    this.emitChangeValue(null)
  }

  public closeEdit() {
    this.isEdit = false
  }

  onChanged(isSelectedItem: boolean) {
    this.isSelectedPosition = isSelectedItem
    this.emitChangeValue(null)
  }

  emitChangeValue(position: string) {
    if (position === null) {
      if (this.customSelectPosition.localChecked.length > 0) {
        this.positionChange.emit(this.customSelectPosition.localChecked[0])
      } else {
        this.positionChange.emit('')
      }
    } else {
      this.positionChange.emit(position)
    }
  }

  savePosition(position: string) {
    this.position = position
    this.emitChangeValue(position)
    this.positionEdit.isComplete = true
  }

  cancelNewPosition() {
    this.isEdit = false
  }

  editValue() {
    this.positionEdit.isComplete = false
  }

  getPositionsFilterData(event) {
    let isNotleter: number = event.isNotLetter;
    this.dealService.getFilterData(FilterType.Position, event.skip, event.take).then(response => {
      if (isNotleter == this.customSelectPosition.isNotLetter) {
        this.customSelectPosition.setData(response);
      }
    });
  }

  getPositionsAutocomplete(event) {
    let isNotleter: number = event.isNotLetter;
    this.dealService.getFilterDataAutocomplete(FilterType.Position, event.searchString, event.skip, event.take).then(response => {
      if (isNotleter == this.customSelectPosition.isNotLetter) {
        this.customSelectPosition.setData(response);
      }
    });
  }

  public onAnotherSelectOpen() {
    if (this.customSelectPosition) {
      this.customSelectPosition.isOpenSelect = false;
    }
  }

  onSelectOpen() {
    this.open.emit();
  }
}
