import { UserContextService } from 'src/app/core/services/user-context.service';
import { Component, OnInit, Output, ViewChild, Input, EventEmitter } from '@angular/core';
import { KillRecordOptions } from '../modal-options/kill-record.options';
import { User } from 'src/app/core/models/UserModel';
import { ModalComponent } from '../../modal/modal.component';

@Component({
  selector: 'pd-custom-kill-record-modal',
  templateUrl: './custom-kill-record-modal.component.html',
  styleUrls: ['./custom-kill-record-modal.component.scss']
})
export class CustomKillRecordModalComponent implements OnInit {

  @Output() cancelBtn = new EventEmitter<object>();
  @Output() confirmBtn = new EventEmitter<object>();
  @ViewChild('modal') modal: ModalComponent;
  @Input() dialogOptions: KillRecordOptions;
  @Input() isInputDisplayed = true;
  @Input() inputPlaceholder = "Reason";
  @Input() isUrl = false;

  private confirmAction: any;
  public inputData: string = "";
  public currentUser: User;

  constructor(private userContextService: UserContextService) { }

  ngOnInit() { 
    this.currentUser = this.userContextService.user.value;
  }

  public openModal(confirmCallback) {
    this.inputData = "";
    this.confirmAction = confirmCallback;
    this.modal.open();
  }

  public confirm(isConfirm) {
    isConfirm ? this.ok() : this.cancel();
    this.modal.close();
  }

  private ok() {
    this.confirmAction != null ? this.confirmAction() : this.confirmBtn.emit();
  }

  private cancel() {
    this.cancelBtn.emit();
  }
}
