import { Component, OnInit, Input, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Modeler3dCard, Modeler3dCardLine } from 'src/app/core/models/Modeler3dCardConfig';
import { Modeler3dProductService } from 'src/app/core/services/3d-modeler-product.service';
import { UserContextService } from 'src/app/core/services/user-context.service';
import { User } from 'src/app/core/models/UserModel';

@Component({
  selector: 'pd-3d-modeler-product-card',
  templateUrl: './3d-modeler-product-card.component.html',
  styleUrls: ['./3d-modeler-product-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class Modeler3dProductCardComponent implements OnInit {
  @ViewChild('expansionPanel') expansionPanel: MatExpansionPanel;

  @Input() modeler3dCards: Array<Modeler3dCard>;
  @Input() cardLine: Modeler3dCardLine;

  currentUser: User;
  filteredCards: Array<Modeler3dCard>;
  selectLazyLoading = false;
  selectStart = 0;
  countPagination = 60;
  isExpandedOnce = false;

  constructor(private cdRef: ChangeDetectorRef, private userContextService: UserContextService, private serv: Modeler3dProductService) { }

  ngOnInit() {
    this.initializeStore();
    this.filteredCards = new Array<Modeler3dCard>();

    for (let i = 0; i < this.countPagination; i++) {
      this.findFabricImg(this.modeler3dCards[i]);
      this.filteredCards.push(this.modeler3dCards[i]);
    }
    this.selectStart = this.countPagination;
  }

  private initializeStore() {
    this.currentUser = this.userContextService.user.value;
  }

  doLazyLoading(event) {
    if (event.srcElement.scrollTop + 10 > event.srcElement.scrollHeight - event.srcElement.clientHeight) {
      if (this.modeler3dCards.length - this.filteredCards.length > 0) {
        this.selectLazyLoading = true;
        setTimeout(() => {
          let incr = this.selectStart;
          if (this.selectStart <= this.modeler3dCards.length - this.countPagination) {
            this.selectStart += this.countPagination;
          } else {
            this.selectStart += this.modeler3dCards.length - this.selectStart;
          }

          for (let i = incr; i < this.selectStart; i++) {
            this.findFabricImg(this.modeler3dCards[i]);
            this.filteredCards.push(this.modeler3dCards[i]);
          }
          this.selectLazyLoading = false;
          this.cdRef.detectChanges();
        }, 300);
      } else {
        this.selectLazyLoading = false;
      }
    }
  }

  findFabricImg(card: Modeler3dCard) {
    const data = this.serv.getModeler3dCardImgUrl(card.title);
    if (data !== null) {
      card.fabricImgUrl = data;
    }
  }

  getCardCount() {
    if (this.modeler3dCards) {
      let cards = this.modeler3dCards;
      if (cards) {
        return cards.length;
      }
    }
    return 0;
  }

  getLoadedCardCount() {
    if (this.filteredCards) {
      let cards = this.filteredCards;
      if (cards) {
        return cards.length;
      }
    }
    return 0;
  }
}
