import { ProductTypes } from "../enums/client-card/ProductTypes";

export class ClientCard {
  public id: number;
  public dealId: number;

  public fabricImgTitle: string;
  public fabricImgUrl: string;

  public productTypeId: ProductTypes;
  public productTypeName: string;

  public lineId: number;
  public lineName: string;

  public fabricConfigId: number;
  public fabricConfigTitle: string;

  public typeOfSuitId: number;
  public typeOfSuitName: string;

  public dateOfOrder: Date;

  public order: number;

  public filesCount: number;

  constructor(dealId: number, fabricImgTitle: string, productTypeId: ProductTypes, lineId: number, fabricConfigId: number, fabricConfigTitle: string, typeOfSuitId: number, dateOfOrder: Date, order: number, filesCount: number) {
    this.dealId = dealId;
    this.fabricImgTitle = fabricImgTitle;
    this.productTypeId = productTypeId;
    this.lineId = lineId;
    this.fabricConfigId = fabricConfigId;
    this.fabricConfigTitle = fabricConfigTitle;
    this.typeOfSuitId = typeOfSuitId;
    this.dateOfOrder = dateOfOrder;
    this.order = order;
    this.filesCount = filesCount;
  }
}