import { Component, Input, Output, EventEmitter, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from 'src/app/core/base.component';
import { AvatarBodyTypes } from 'src/app/core/enums/avatar/AvatarBodyTypes';
import { AvatarElementTypes } from 'src/app/core/enums/avatar/AvatarElementTypes';
import { AvatarSkinTypes } from 'src/app/core/enums/avatar/AvatarSkinTypes';
import { AvatarTypes } from 'src/app/core/enums/avatar/AvatarTypes';
import { ProductTypes } from 'src/app/core/enums/client-card/ProductTypes';
import { ClientCard } from 'src/app/core/models/ClientCard';
import { Avatar } from 'src/app/core/models/avatar-models/Avatar';
import { ClientCardFacadeService } from 'src/app/core/services/client-card/client-card-facade.service';
import { AvatarUtil } from 'src/app/core/utils/avatar.util';
import { EnumUtil } from 'src/app/core/utils/enum.util';
import { ImageUtil } from 'src/app/core/utils/image.util';

@Component({
  selector: 'pd-avatar-card-tile',
  templateUrl: './avatar-card-tile.component.html',
  styleUrls: ['./avatar-card-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class AvatarCardTileComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() public avatar: Avatar;
  @Input() public clientCard: ClientCard;
  @Input() public dealId: number;
  @Input() public isExportAction: boolean = false;
  @Input() public isGlobalCollection: boolean = false;
  @Input() public isCustomAvatars: boolean = false;
  @Input() public isAllowAddEditAvatars: boolean = false;
  @Input() public isMasterLeadAccount: boolean = false;
  @Input() public isAllowAddEditCustomAvatars: boolean = false;

  @Output() public onClickAvatar = new EventEmitter<any>();
  @Output() public onDeleteAvatar = new EventEmitter<any>();
  @Output() public onDownloadAvatar = new EventEmitter<any>();
  @Output() public onAddAvatarToCollection = new EventEmitter<any>();

  public AvatarElementTypes = AvatarElementTypes;
  public AvatarUtil = AvatarUtil;

  constructor(
    private clientCardFacadeService: ClientCardFacadeService,
    private cdRef: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit() {
    if (this.avatar) {
      this.avatar.avatarElements = AvatarUtil.getSortedAvatarElements(this.avatar.avatarElements);
    }

    if (this.avatar && this.avatar.clientCardId) {
      this.clientCardFacadeService.getClientCards()
        .pipe(takeUntil(this.destroy$))
        .subscribe((clientCards: ClientCard[]) => {
          this.clientCard = clientCards.find((clientCard: ClientCard) => clientCard.id === this.avatar.clientCardId);
          this.cdRef.detectChanges();
        });
    }
  }

  ngOnDestroy() {
    this.cdRef.detach();
    super.ngOnDestroy();
  }

  public detectChanges() {
    this.cdRef.detectChanges();
  }

  public getAvatarLabel(avatarTypeId: number) {
    if (this.avatar && this.avatar.customTitle) return this.avatar.customTitle;

    if (this.clientCard && (this.clientCard.productTypeId === ProductTypes.Blazers || this.clientCard.productTypeId === ProductTypes.Pants)) {
      if (this.clientCard.productTypeId === ProductTypes.Blazers) return 'Blazer';
      if (this.clientCard.productTypeId === ProductTypes.Pants) return 'Pants';
    }

    return EnumUtil.getEnumName(AvatarTypes, avatarTypeId);
  }

  public getBodyType(bodyTypeId: number) {
    return EnumUtil.getEnumName(AvatarBodyTypes, bodyTypeId);
  }

  public getSkinType(skinTypeId: number) {
    return EnumUtil.getEnumName(AvatarSkinTypes, skinTypeId);
  }

  public getAvatarElementTypeLabel(avatarElementType: number) {
    let label = EnumUtil.getEnumName(AvatarElementTypes, avatarElementType);
    label = label.split('_')[0];
    return label;
  }

  public getFabricTitle(imageLink: string) {
    return ImageUtil.urlToFabric(imageLink);
  }

  //////////////////////////////// Events START //////////////////////////////

  public clickAvatar(avatar: Avatar) {
    this.onClickAvatar.emit({ avatar: avatar, isGlobalCollection: this.isGlobalCollection, isCustomAvatars: this.isCustomAvatars });
  }

  public deleteAvatar(avatar: Avatar) {
    this.onDeleteAvatar.emit({ avatarId: avatar.id, isGlobalCollection: this.isGlobalCollection, isCustomAvatars: this.isCustomAvatars });
  }

  public downloadAvatar(avatar: Avatar) {
    this.onDownloadAvatar.emit({ avatarId: avatar.id });
  }

  public addAvatarToCollection(avatar: Avatar) {
    this.onAddAvatarToCollection.emit({ avatar: avatar });
  }

  //////////////////////////////// Events END //////////////////////////////

  //////////////////////////////// Private START //////////////////////////////

  //////////////////////////////// Private END //////////////////////////////

  //////////////////////////////// Helpers START ////////////////////////////////

  public isFabric(imageLink: string): boolean {
    return ImageUtil.isFabric(imageLink);
  }

  //////////////////////////////// Helpers END ////////////////////////////////

  //////////////////////////////// View *ngIf START //////////////////////////////

  public get isShowFabrics(): boolean {
    return this.isAllowAddEditAvatars || !this.isGlobalCollection
  }

  public get isFabricsOnly(): boolean {
    return !this.isAllowAddEditAvatars && !this.isGlobalCollection
  }

  //////////////////////////////// View *ngIf END //////////////////////////////
}
