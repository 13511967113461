import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { BaseService } from './base.service';
import { HttpRestService } from './http-client/http-rest-client.service';
import { CmsClientModel } from '../models/cms-client.model';

@Injectable()
export class CmsService extends BaseService {

    constructor(private restService: HttpRestService) {
        super();
    }

    async getClientById(id: number): Promise<CmsClientModel> {
        return await this.handleResponse(this.restService.get({ directUrl: `${environment.cmsUtilityServiceApi}/deal/client/${id}` }));
    }
}
