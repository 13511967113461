import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BulkMessageEffectsService } from "./bulk-message-effects.service.";
import { BulkMessageStoreService } from "./bulk-message-store.service";
import { MessageTemplateViewModel } from "../../models/message-template/MessageTemplateViewModel";

@Injectable()
export class BulkMessageFacadeService {

    constructor(
        private bulkMessageEffectsService: BulkMessageEffectsService,
        private bulkMessageStoreService: BulkMessageStoreService,
    ) {
    }

    public getCopiedTemplateSetting(): Observable<MessageTemplateViewModel> {
        return this.bulkMessageStoreService.getCopiedTemplateSetting();
    }

    public getCopiedTemplateSettingSnapshot(): MessageTemplateViewModel {
        return this.bulkMessageStoreService.getCopiedTemplateSettingSnapshot();
    }

    public setCopiedTemplateSetting(copiedTemplateSetting: MessageTemplateViewModel): void {
        this.bulkMessageStoreService.setCopiedTemplateSetting(copiedTemplateSetting);
    }
}