import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { PaymentService } from '../core/services/payment.service';
import { PaymentTransactionInfo } from '../core/models/payment-models/PaymentTransactionInfo';
import { PaymentUtil } from '../core/utils/payment.util';

@Component({
  selector: 'pd-payment-result',
  templateUrl: './payment-result.component.html',
  styleUrls: ['./payment-result.component.scss']
})
export class PaymentResultComponent implements OnInit {
  stripe = (<any>window).Stripe(environment.stripePublicKey);
  paymentResponse: PaymentTransactionInfo;

  constructor(private paymentService: PaymentService) {
  }

  async ngOnInit() {
    await this.checkStatus();
  }

  async checkStatus() {
    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret'
    );

    if (!clientSecret) {
      return;
    }

    const result = await this.stripe.retrievePaymentIntent(clientSecret);
    const paymentIntent = result.paymentIntent;
    this.paymentResponse = new PaymentTransactionInfo({ transactionId: paymentIntent.id, transactionMessage: PaymentUtil.getStatusMessage(paymentIntent.status) });
    await this.paymentService.sentStripePaymentResult(this.paymentResponse.transactionId, this.paymentResponse.transactionMessage);
  }
}